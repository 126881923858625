import { AssessmentItem, AssessmentTest } from '@app/models'
import { JSZipObject } from 'jszip'
import { Dispatch, InputHTMLAttributes, SetStateAction } from 'react'
import { TEST_ATTRIBUTE_ID } from '@app/constants'
import { UserCredential } from 'firebase/auth'
import { UserAssessment } from '@app/firebase/api'
import { DragEndEvent, DragOverEvent, DragStartEvent, SensorDescriptor, SensorOptions } from '@dnd-kit/core'

export * from './services'
export * from './user'

export enum InteractionType {
  choice = 'choice',
  textEntry = 'textEntry',
  extendedText = 'extendedText',
  match = 'match',
  associate = 'associate',
  order = 'order',
  inlineChoice = 'inline',
  hotText = 'hotText',
  hotspot = 'hotspot',
  gapMatch = 'gapMatch',
  graphicGapMatch = 'graphicGapMatch',
  graphicOrder = 'graphicOrder',
  graphicAssociate = 'graphicAssociate',
  selectPoint = 'selectPoint',
  positionObject = 'positionObject',
  numberLine = 'numberLine',
  gridIn = 'gridIn',
  graphing = 'graphing',
  equationEditor = 'equationEditor',
}

export enum ResourceType {
  item = 'imsqti_item_xmlv2p2',
  test = 'imsqti_test_xmlv2p2',
  stimulus = 'stimulus',
  stylesheet = 'stylesheet',
  image = 'image',
}

export enum Cardinality {
  multiple = 'multiple',
  single = 'single',
  ordered = 'ordered',
  record = 'record',
}

export enum FeedbackIdentifiers {
  general = 'general',
  correct = 'correct',
  incorrect = 'incorrect',
}

export enum FeedbackTypes {
  modal = 'modal',
  inline = 'inline',
  test = 'test',
  block = 'block',
}

export enum NavigationMode {
  linear = 'linear',
  nonlinear = 'nonlinear',
}

export enum SubmissionMode {
  individual = 'individual',
  simultaneous = 'simultaneous',
}

export enum TestFeedbackAccess {
  atEnd = 'atEnd',
  during = 'during',
}

export enum InteractionOrientation {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

export enum ShowHide {
  show = 'show',
  hide = 'hide',
}

export enum BaseType {
  boolean = 'boolean',
  directedPair = 'directedPair',
  duration = 'duration',
  file = 'file',
  float = 'float',
  identifier = 'identifier',
  integer = 'integer',
  pair = 'pair',
  point = 'point',
  string = 'string',
  uri = 'uri',
}

export enum Shape {
  circle = 'circle',
  default = 'default',
  ellipse = 'ellipse',
  poly = 'poly',
  rect = 'rect',
}

export enum AlertStatus {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export enum TimerStatus {
  notStarted = 'notStarted',
  ongoing = 'ongoing',
  paused = 'paused',
  ended = 'ended',
}

export enum TimerActions {
  start = 'start',
  resume = 'resume',
  pause = 'pause',
  reset = 'reset',
  tick = 'tick',
}

export enum SyncIntervalActions {
  changeIntervalStep = 'changeStep',
  start = 'start',
  reset = 'reset',
  sync = 'emitSyncEvent',
}

export type ManifestResource = {
  resourceId: string
  type: ResourceType
  url: string
}

export type ExtractedManifestResource = {
  resourceId: string
  type: ResourceType
  zipData: JSZipObject
}

export type TimeLimits = {
  minTime: number | null
  maxTime: number | null
  allowLateSubmission: boolean
}

export type DataRef = {
  id: string
  url: string
  required?: boolean
}

export type ItemSessionControl = {
  maxAttemps: number
  showFeedback: boolean
  allowReview: boolean
  showSolution: boolean
  allowComment: boolean
  allowSkipping: boolean
  validateResponses: boolean
}

export type AssessmentStylesheetRef = {
  url: string
  type: string
  media: string
  title: string
}

export type AssessmentStylesheet = {
  resourceId: string
  path: string
  content: string
}

export type Mapping = {
  lowerBound?: number
  upperBound?: number
  defaultValue?: number
  entries?: MapEntry[]
}

export type AreaMapping = {
  coords: string
  mappedValue: string
  shape: string
}

export type AssessmentItemValue = {
  interpretation?: string
  value: string
}

export type Feedback = {
  id: string
  type: FeedbackTypes
  outcomeId?: string
  showHide?: ShowHide
  title?: string
  access?: TestFeedbackAccess
  stylesheet?: AssessmentStylesheetRef[]
  data?: string
}

export type ZipResource = {
  id: string
  zip: JSZipObject
}

export type ParsedManifestResources = {
  tests: AssessmentTest[]
  items: AssessmentItem[]
}

export type SimpleChoice = FlowStaticGroup & {
  id: string
  fixed: boolean
  templateId?: string
  showHide: ShowHide
  data: string
}

export type FlowStaticGroup = {
  feedbackInline?: Feedback
  feedbackBlock?: Feedback
  templateInline?: Feedback
  templateBlock?: Feedback
}

export type InfoControl = {
  title?: string
  data?: string
}

export type MapEntry = {
  key: string
  value: string
  caseSensitive?: boolean
}

export type SelectableChoiceConstraints = {
  minChoices?: number
  maxChoices?: number
}

export type CountingVarinatsState = {
  [key: string]: CountingVarinat
}

export type CountingVarinat = {
  matchMax: number
  matchMin?: number
  matchCount: number
}

export type DropContainerState = Record<string, string>

export type DropContainerScoring = Record<
  string,
  {
    correct: boolean
    correctAnswer: string
  }
>

export interface UserClaims {
  aud: string
  firstName: string
  lastName: string
  role: string
  school: string
  schoolDistrict: string
  state: string
  schoolYear: string
}

export interface UserAuthData extends UserCredential {
  claims: UserClaims
}

export type InitialUserAssessmentPayload = Pick<
  UserAssessment,
  'assessmentId' | 'assessmentSettings' | 'userId' | 'schoolYear' | 'attempt' | 'hideInfoBoxes'
>

export type IUseDragDropMatch = [
  string,
  DropContainerState,
  boolean,
  boolean,
  (e: DragStartEvent) => void,
  (e: DragEndEvent) => void,
  (e: DragOverEvent) => void,
  (itemId: string, containerId: string) => () => void,
  (id: string) => boolean,
  SensorDescriptor<SensorOptions>[],
  IClickMovingState,
  () => void,
]

export type AssociationConstraints = {
  minAssociations?: number
  maxAssociations?: number
}

export enum ChangeCountingVariantsType {
  add = 'add',
  sub = 'sub',
}

export enum SetContainerDropElementType {
  add = 'add',
  reset = 'reset',
}

export type IUseCountingVariants = [
  CountingVarinatsState,
  (type: ChangeCountingVariantsType) => (variantId: string[] | null) => void,
]

export interface CheckboxAttrProps extends InputHTMLAttributes<HTMLInputElement> {
  [TEST_ATTRIBUTE_ID]?: string
}

export enum TextFormatType {
  plain = 'plain',
  preformatted = 'preformatted',
  xhtml = 'xhtml',
}

export type IClickMovingState = {
  activeBlockId: string
  activeGapId: string
  activeGapBlockId: string
}

export type ExtractedImage = {
  resourceIds: string[]
  imageData: string
  path: string
}

export enum TIMER_STATUS {
  initial = 'initial',
  expiring = 'expiring',
  low = 'low',
}

export type TimerStatusType = TIMER_STATUS.initial | TIMER_STATUS.expiring | TIMER_STATUS.low

export type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'

export interface IUseClickMoving {
  clickMovingState: IClickMovingState
  setClickMovingState: Dispatch<SetStateAction<IClickMovingState>>
  resetClickMovingState: () => void
}

export type UseUserAssessmentFetchType = {
  userAssessment: UserAssessment
  loading: boolean
}

export type FeedbackItemMessage = {
  key: string
  percentage?: number
  content: string
}

export type FeedbackItem = {
  key: string
  correct: boolean
  messages?: FeedbackItemMessage[]
}

export type PercentagesByInteraction = {
  correct: number[]
  incorrect: number[]
}

export type PercentagesByCorrect = {
  correct: number[][]
  incorrect: number[][]
}

export type FeedbackPercentages = {
  correct: number
  incorrect: number
  noAnswer: number
}
