export const FREE_VARIANTS_CONTAINER_ID = 'FREE_VARIANTS'
export const DESKTOP_BUTTON_ID = 'desktop-button'
export const MOBILE_BUTTON_ID = 'mobile-button'

export const PAGINATION = 'pagination'

export const ITEMS_MODAL_WIDTH = 511
export const GRIP_BUTTON_SIZE = 30
export const DEFAULT_TEXT_LENGTH = 450

export const CustomInteractionIds = {
  gridIn: 'gridIn',
  equationEditor: 'equationEditor',
  graphing: 'graphing',
  numberLine: 'numberLine',
}

export const DATA_CLICK_AWAY_ID = 'data-clickid'
export const DATA_CLICK_AWAY_PROPS = { [DATA_CLICK_AWAY_ID]: 'clickId' }
export const NAVIGATION_DESCRIBEDBY_ID = 'navigationDescription'
export const PASSAGE_DESCRIBEDBY_ID = 'passageDescription'
