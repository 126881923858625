import React from 'react'
import { Tooltip } from '@mui/material'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo'
import { ItemFeedbackGroup, ItemFeedbackGroupProps } from '@app/components/feedback/ItemFeedbackGroup'
import { StyledIcon, StyledIconWrapper, StyledTooltipBase } from './styles'

export type ReviewIconProps = React.HTMLAttributes<HTMLSpanElement> & {
  correct?: boolean
  groupMode?: boolean
  items?: ItemFeedbackGroupProps['items']
}

const POPPER_PROPS = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [8, -8],
      },
    },
  ],
}

const SLOTS = { popper: StyledTooltipBase }

const ReviewIcon = React.forwardRef<HTMLSpanElement, ReviewIconProps>(
  ({ groupMode = false, items, correct, ...rest }, ref) => {
    if (groupMode) {
      return (
        <Tooltip
          title={<ItemFeedbackGroup size='sm' items={items} />}
          slots={SLOTS}
          placement='right-end'
          PopperProps={POPPER_PROPS}
        >
          <StyledIconWrapper ref={ref} groupMode={groupMode} tabIndex={0} {...rest}>
            <StyledIcon icon={faInfo} />
          </StyledIconWrapper>
        </Tooltip>
      )
    }

    return (
      <StyledIconWrapper ref={ref} correct={correct} {...rest}>
        <StyledIcon icon={correct ? faCheck : faTimes} />
      </StyledIconWrapper>
    )
  },
)

export default ReviewIcon
