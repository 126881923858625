import { useCallback, useEffect, useRef, useState } from 'react'
import { subscribe, useSnapshot } from 'valtio'
import {
  AssessmentActions,
  AssessmentEventType,
  AssessmentStatus,
  createAssessmentEvent,
  createUserAssessment,
  getAssessmentById,
  getUserAssessmentById,
  updateUserAssessment,
  UserAssessment,
  getUserAssessmentScoring,
} from '@app/firebase/api'
import {
  CurrentTestState,
  CurrentUserAssessmentState,
  CurrentTimerState,
  pauseTimer,
  resumeTimer,
  IdleDetectorState,
  setItemsResponses,
  setCurrentItemResponse,
  setUserAssessmentData,
  setUserAssessmentStatus,
  setStartedTimestamp,
  setScoring,
  setHideConstraints,
  ScoringState,
  setGroupTestScoring,
  setProgram,
  setSubject,
} from '@app/storage'
import { AssessmentItemResponse } from '@app/models'
import { Program, TimerStatus, UseUserAssessmentFetchType, UserAuthData } from '@app/types'
import { useDefineTimeLimit, useTestPartFinishedCheck, useUpdatingRef } from '../hooks'
import { FirebaseError } from 'firebase/app'
import { FirebaseErrorCodes, GROUP_REVIEW_USER, ROUTES, StorageKeys } from '@app/constants'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Timestamp } from 'firebase/firestore'
import { generateGroupUserAssessmentPayload, generateInitialUserAssessmentPayload } from '@app/firebase/utils'
import { LocalStorageService } from '@app/services'
import { getAnswerKeyItemsByPracticeTest, getResponsesByPracticeTest } from '@app/services/groupReview'
import { generateRefScope } from '../refScope'

export const usePassedTimeRef = () => {
  const maxTime = useDefineTimeLimit()
  const { remainedTime } = useSnapshot(CurrentTimerState)

  const addedTime = CurrentUserAssessmentState?.addedTime ?? 0
  const totalTime = maxTime + addedTime

  const passedTimeRef = useUpdatingRef<number>(totalTime - (remainedTime ?? totalTime))

  return passedTimeRef
}

/**
 * Fetch user-assessment data from firestore.
 */
export const useUserAssessmentFetch = (): UseUserAssessmentFetchType => {
  const { userAssessmentId, userId } = useSnapshot(CurrentUserAssessmentState)
  const { groupMode } = useSnapshot(ScoringState)
  const [fetchedAssessment, setFetchedAssessment] = useState<UserAssessment>(null)
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  useEffect(() => {
    if (!userAssessmentId) {
      return
    }

    const hideInfoBoxes = searchParams.get('noinfoboxes') === 'true'

    const createAssessmentForUser = async (userId: string) => {
      const assessmentId = searchParams.get('id')
      const schoolYear = searchParams.get('schoolYear')
      const attempt = searchParams.get('attempt')

      const assessment = await getAssessmentById(assessmentId)

      try {
        await createUserAssessment(
          generateInitialUserAssessmentPayload({
            schoolYear,
            userId,
            attempt: Number(attempt) ?? 1,
            assessmentId,
            hideInfoBoxes,
            assessmentSettings: {
              timeLimits: assessment.settings.timeLimit,
              isResumable: assessment.settings.isResumable,
            },
          }),
        )
      } catch {
        navigate(ROUTES.error500)
      }
    }

    const fetchUserAssessment = async () => {
      setLoading(true)

      try {
        const userAssessment = await getUserAssessmentById(userAssessmentId)
        setItemsResponses(userAssessment.itemResponses)
        setFetchedAssessment(userAssessment)
        setUserAssessmentData(userAssessment)
        setProgram(searchParams.get('program'))
        setSubject(searchParams.get('subject'))

        if (hideInfoBoxes && !userAssessment.hideInfoBoxes) {
          setHideConstraints(hideInfoBoxes)
        }

        if (userAssessment.status === AssessmentStatus.completed) {
          // Fetch scoring data, if student completed the test.
          const scoring = await getUserAssessmentScoring(userAssessmentId)
          setScoring(scoring)
        }

        setLoading(false)
      } catch (error) {
        if (error instanceof FirebaseError) {
          if (error.code === FirebaseErrorCodes.NotFound) {
            const authData = LocalStorageService.getValue<UserAuthData>(StorageKeys.AuthData)

            if (!searchParams || !authData) {
              navigate(ROUTES.notFound)
              return
            }

            await createAssessmentForUser(authData.user.uid)

            try {
              const userAssessment = await getUserAssessmentById(userAssessmentId)

              setItemsResponses(userAssessment.itemResponses)
              setFetchedAssessment(userAssessment)
              setUserAssessmentData(userAssessment)
              setProgram(searchParams.get('program'))
              setSubject(searchParams.get('subject'))
            } catch {
              navigate(ROUTES.error500)
            } finally {
              setLoading(false)
            }
          }

          setLoading(false)
          if (error.code === FirebaseErrorCodes.PermissionDenied) navigate(ROUTES.permissionDenied)
        } else {
          navigate(ROUTES.error500)
        }
      }
    }

    const fetchGroupScoring = async () => {
      const program = searchParams.get('program') as Program
      const subject = searchParams.get('subject')
      const contentId = searchParams.get('contentId')
      const teacherId = searchParams.get('teacherId')
      const classId = searchParams.get('classId')
      const schoolId = searchParams.get('schoolId')
      const districtId = searchParams.get('districtId')

      try {
        const data = await getResponsesByPracticeTest(
          program,
          subject,
          generateRefScope({ classId, teacherId, schoolId, districtId }),
          contentId,
        )
        const correctAnswers = await getAnswerKeyItemsByPracticeTest(program, subject, contentId)
        setGroupTestScoring(data, correctAnswers)
      } catch (error) {
        console.warn(error)
      }

      const userAssessment = generateGroupUserAssessmentPayload(searchParams.get('id'))
      setItemsResponses(userAssessment.itemResponses)
      setFetchedAssessment(userAssessment)
      setUserAssessmentData(userAssessment)
      setProgram(program)
      setSubject(subject)
      setHideConstraints(true)
    }

    if (groupMode && userId === GROUP_REVIEW_USER) {
      fetchGroupScoring()
      return
    }

    fetchUserAssessment()
  }, [navigate, userAssessmentId, userId, groupMode, searchParams])

  return { userAssessment: fetchedAssessment, loading }
}

/**
 * Start new user-assessment.
 */
export const useUserAssessmentStart = (userAssessment: UserAssessment): boolean => {
  const { currentTest, currentTestPart, isStarted } = useSnapshot(CurrentTestState)
  const { assessmentSettings } = useSnapshot(CurrentUserAssessmentState)
  const { isIdle } = useSnapshot(IdleDetectorState)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!currentTest || !currentTestPart || !userAssessment || !assessmentSettings) return

    if (!isStarted) {
      if (userAssessment.status === AssessmentStatus.completed) {
        setLoading(false)
      }

      return
    }

    if (isIdle) return

    const isResumable = CurrentUserAssessmentState.assessmentSettings.isResumable

    if (
      CurrentUserAssessmentState.status === AssessmentStatus.new ||
      (isResumable && CurrentUserAssessmentState.status === AssessmentStatus.paused)
    ) {
      console.log('[useUserAssessmentStart] starting assessment...')
      const startedTimestamp = Timestamp.fromDate(new Date())

      updateUserAssessment(CurrentUserAssessmentState.userAssessmentId, {
        status: AssessmentStatus.started,
        startedTimestamp,
      }).then(() => {
        setUserAssessmentStatus(AssessmentStatus.started)
        setStartedTimestamp(startedTimestamp)
        setLoading(false)
        createAssessmentEvent({
          action: AssessmentActions.started,
          type: AssessmentEventType.assessmentEvent,
          passedTime: 0,
          data: null,
        })
      })
      return
    }

    const itemResponses = userAssessment.itemResponses
    const lastViewedItemId = userAssessment.lastViewedItemId

    if (!lastViewedItemId || !itemResponses.length) {
      setLoading(false)
      return
    }

    const lastViewedItemResponse = itemResponses.find(itemRes => itemRes.id === lastViewedItemId)

    if (!lastViewedItemResponse) {
      setLoading(false)
      return
    }

    setCurrentItemResponse(lastViewedItemResponse as AssessmentItemResponse)
    setLoading(false)
  }, [currentTest, currentTestPart, userAssessment, assessmentSettings, isIdle, isStarted])

  return loading
}

/**
 * Save updated items responses and passed time to the firestore.
 */
export const useUserAssessmentResponseUpdater = (): void => {
  const { itemsResponses, currentItemResponse } = useSnapshot(CurrentTestState)
  const { userAssessmentId, assessmentId, status, assessmentSettings } =
    useSnapshot(CurrentUserAssessmentState)
  const settingsTimeLimits = assessmentSettings?.timeLimits

  const previousItemId = useRef('')

  const passedTimeRef = usePassedTimeRef()

  useEffect(() => {
    if (!assessmentId) return

    const unsubscribe = subscribe(CurrentTestState.itemsResponses, ops => {
      if (status === AssessmentStatus.new) return
      if (
        status === AssessmentStatus.started &&
        CurrentTestState.itemsResponses.length &&
        // If no time limits or if time passed, track changes
        (passedTimeRef.current > 0 || settingsTimeLimits === 0)
      ) {
        console.log(
          '[useUserAssessmentTimeTracker] tracking assessment time...',
          userAssessmentId,
          status,
          ops,
          passedTimeRef.current,
        )
        updateUserAssessment(userAssessmentId, {
          passedTime: passedTimeRef.current,
          itemResponses: CurrentTestState.itemsResponses,
        })
      }
    })

    return unsubscribe
  }, [assessmentId, userAssessmentId, status, settingsTimeLimits, passedTimeRef, itemsResponses])

  useEffect(() => {
    if (!userAssessmentId || !currentItemResponse?.id) {
      return
    }

    const lastViewedItemId = CurrentTestState?.currentItemResponse?.id

    if (!lastViewedItemId || previousItemId.current === lastViewedItemId) {
      return
    }

    if (!previousItemId.current) {
      previousItemId.current = lastViewedItemId
      return
    }

    if (CurrentUserAssessmentState.status === AssessmentStatus.started) {
      console.log(
        '[useUserAssessmentTimeTracker] saving last viewed item...',
        userAssessmentId,
        lastViewedItemId,
      )
      updateUserAssessment(userAssessmentId, { lastViewedItemId }).then(() => {
        previousItemId.current = lastViewedItemId
      })
    }
  }, [userAssessmentId, currentItemResponse?.id])
}

/**
 * When user-assessment report is generated, save it to the firestore.
 */
export const useUserAssessmentReporter = (): void => {
  const { currentTestPartResponse } = useSnapshot(CurrentTestState)
  const { assessmentId, userAssessmentId } = useSnapshot(CurrentUserAssessmentState)

  const xmlReport = currentTestPartResponse[0]

  useEffect(() => {
    if (!assessmentId || !userAssessmentId) return

    if (xmlReport && CurrentUserAssessmentState.status === AssessmentStatus.started) {
      console.log('[useUserAssessmentSubmit] submitting assessment report...')
      updateUserAssessment(userAssessmentId, { responseXml: xmlReport })
    }
  }, [assessmentId, xmlReport, userAssessmentId])
}

/**
 * When current test part becomes finished, mark user-assessment as "completed".
 */
export const useUserAssessmentSubmitter = (): void => {
  const passedTimeRef = usePassedTimeRef()

  const isTestPartFinished = useTestPartFinishedCheck()
  const { userAssessmentId, assessmentId } = useSnapshot(CurrentUserAssessmentState)

  useEffect(() => {
    if (!userAssessmentId || !assessmentId) return

    const submitAssessment = async () => {
      if (CurrentUserAssessmentState.status === AssessmentStatus.started && isTestPartFinished) {
        console.log('[useUserAssessmentSubmit] completing assessment...')
        pauseTimer()
        await updateUserAssessment(userAssessmentId, {
          status: AssessmentStatus.completed,
          passedTime: passedTimeRef.current,
        })
        createAssessmentEvent({
          action: AssessmentActions.completed,
          passedTime: passedTimeRef.current,
          type: AssessmentEventType.assessmentEvent,
          data: null,
        })
        setUserAssessmentStatus(AssessmentStatus.completed)
      }
    }

    submitAssessment()
  }, [isTestPartFinished, passedTimeRef, userAssessmentId, assessmentId])
}

/**
 * Pause both timer and resumable user-assessment.
 * @returns Callback to pause timer.
 */
export const useUserAssessentPauseCallback = (): (() => void) => {
  const passedTimeRef = usePassedTimeRef()

  const { userAssessmentId, assessmentId } = useSnapshot(CurrentUserAssessmentState)

  const onPause = useCallback(async () => {
    if (assessmentId && !CurrentUserAssessmentState.assessmentSettings?.isResumable) {
      return
    }

    if (CurrentUserAssessmentState.status === AssessmentStatus.started) {
      console.log('[useUserAssessentPauseCallback] pausing assessment...', passedTimeRef.current)
      pauseTimer()
      createAssessmentEvent({
        action: AssessmentActions.paused,
        passedTime: passedTimeRef.current,
        type: AssessmentEventType.assessmentEvent,
        data: null,
      })
      await updateUserAssessment(userAssessmentId, {
        status: AssessmentStatus.paused,
        passedTime: passedTimeRef.current,
      })
      setUserAssessmentStatus(AssessmentStatus.paused)
    }
  }, [assessmentId, userAssessmentId, passedTimeRef])

  return onPause
}

/**
 * Resume both timer and user-assessment.
 * @returns Callback to resume timer.
 */
export const useUserAssessentResumeCallback = (): (() => void) => {
  const { userAssessmentId } = useSnapshot(CurrentUserAssessmentState)
  const { timerStatus } = useSnapshot(CurrentTimerState)
  const passedTimeRef = usePassedTimeRef()

  const onResume = useCallback(async () => {
    // Check timer status before resuming.
    if (timerStatus === TimerStatus.ongoing) return

    console.log('[useUserAssessentResumeCallback] resuming assessment...')
    resumeTimer()
    await updateUserAssessment(userAssessmentId, { status: AssessmentStatus.started })
    setUserAssessmentStatus(AssessmentStatus.started)
    createAssessmentEvent({
      action: AssessmentActions.resumed,
      passedTime: passedTimeRef.current,
      type: AssessmentEventType.assessmentEvent,
      data: null,
    })
  }, [timerStatus, userAssessmentId, passedTimeRef])

  return onResume
}

/**
 * Track user-assessment updates and save them to the firestore.
 */
export const useUserAssessentUpdater = (): void => {
  useUserAssessmentReporter()
  useUserAssessmentResponseUpdater()
  useUserAssessmentSubmitter()
}
