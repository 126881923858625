import { AppEnvironment } from "./environment"

// Ready Firebase config
export const developmentEnv: AppEnvironment = {
  REACT_APP_API_KEY: 'AIzaSyA-oUqi_Ma3MON5PCTCl6McZhvEvEtQQn4',
  REACT_APP_AUTH_DOMAIN: 'mp-ready-staging.firebaseapp.com',
  REACT_APP_PROJECT_ID: 'mp-ready-staging',
  REACT_APP_BUCKET_URL: 'mp-ready-staging.appspot.com',
  REACT_APP_MESSAGING_SENDER_ID: '247577420662',
  REACT_APP_APP_ID: '1:247577420662:web:a7d7211e36d58bc8cf9057',
  REACT_APP_MEASUREMENTID: 'G-9JSVG4NSKN',
}
