import { db } from "@app/firebase";
import { generateAssessmentEventPayload } from "@app/firebase/utils";
import { doc, setDoc } from "firebase/firestore";
import { AssessmentEvent, FirestoreCollections } from "../types";

/**
 * Create firestore document with assessment event data.
 * @param { AssessmentEvent } payload assessment event data.
 */
export const createAssessmentEvent = async (
  payload: Pick<AssessmentEvent, 'action' | 'type' | 'data' | 'passedTime'>
) => {
  const eventData = generateAssessmentEventPayload(payload)

  try {
    await setDoc(doc(
      db, FirestoreCollections.assessmentEvents,
      `${eventData.userId}_${eventData.assessmentId}_${eventData.id}`
    ), {
      ...eventData
    })
  } catch (e){
    console.error(e)
  }
}