import { Popper, badgeClasses, styled, tooltipClasses } from '@mui/material'
import { ReviewBadge } from '@app/components/ui/badges'
import { ReviewIcon } from '@app/components/ui/icons'
import { focusStyle } from '@app/theme'

export const StyledReviewBadge = styled(ReviewBadge)(() => ({
  [`& .${badgeClasses.badge}`]: {
    padding: 0,
  },
}))

export const StyledTooltipBase = styled(Popper)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    margin: `0 !important`,
    padding: 0,
    borderRadius: '3px',
    boxShadow: theme.shadows[6],
    maxWidth: '250px',
  },
}))

export const StyledFocusableReviewIcon = styled(ReviewIcon)(({ theme }) => ({
  '&:focus': {
    ...focusStyle(theme).shadow,
  },
}))
