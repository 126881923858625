import { forwardRef } from 'react'
import { Box, badgeClasses, styled } from '@mui/material'
import {
  SelectComponentBase,
  SelectComponentProps,
} from '@app/components/ui/inputs/SelectComponent'
import { TooltipFeedbackProps } from '@app/components/feedback/TooltipFeedback'
import { TooltipFeedbackGroupProps } from '@app/components/feedback/TooltipFeedbackGroup'

// Otherwise there are problems with styled engine.
export const StyledReviewBadge = styled(Box)<
  TooltipFeedbackProps | TooltipFeedbackGroupProps
>(({ theme }) => ({
  margin: theme.spacing(1, 1),

  [theme.breakpoints.down('mobile')]: {
    margin: theme.spacing(0.5, 1),
  },

  [`& ${StyledSelect}`]: {
    margin: 0,
  },

  [`& .${badgeClasses.anchorOriginTopLeftRectangular}`]: {
    [theme.breakpoints.down('mobile')]: {
      left: '3px',
      top: '3px',
    },
  },
}))

// Otherwise there are problems with styled engine.
export const StyledSelect = styled(
  forwardRef<unknown, SelectComponentProps>((props, ref) => <SelectComponentBase ref={ref} {...props} />)
)(
  ({ theme }) => `
    min-width: 150px;

    & svg {
      transform: none !important;
      color: ${theme.palette.additionalColors['bg_500']};
    }

    & .MuiSelect-nativeInput {
      visibility: hidden;
    }
    & .MuiSelect-select:after {
      content: '';
      width: 0.5rem;
    }
    & .MuiInputBase-input {
      color: ${theme.palette.grey[800]};
      display: flex;
      align-items: center;
    }

    & fieldset {
      transition: ${theme.transitions.create(['border-color'])};
      border-width: 1px !important;
      border-color: ${theme.palette.grey[200]} !important;
    }

    &:hover {
      background-color: ${theme.palette.grey[50] + '80'};
    }

    & .MuiPopover-paper {
      margin-top: 3px;
    }

    &.Mui-focused {
      background-color: transparent;
      outline: 3px solid ${theme.palette.blue[200] + '33'};
      & fieldset {
        border-color: ${theme.palette.blue[300]} !important;
      }
    }

    & .MuiMenu-list {
      padding-top: ${theme.spacing(1)};
      padding-bottom: ${theme.spacing(1)};
      box-shadow: ${theme.shadows[6]};
    }

    ${[theme.breakpoints.down('mobile')]} {
      height: 30px;
    }
  `,
)
