import { CssBaseline, PaletteMode } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { FC, useMemo, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { AppUpdater } from './components/common'
import { Home, Test, CustomLogin, Error } from './screens'
import { ROUTES } from './constant'
import { getTheme } from './theme'
import { AuthGuard } from './components'

interface AppProps {}

const App: FC<AppProps> = () => {
  const [mode] = useState<PaletteMode>('light')

  const theme = useMemo(() => getTheme(mode), [mode])

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>MasteryPrep</title>
      </Helmet>
      <AppUpdater />
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.home} element={<Home />} />
          <Route
            path={ROUTES.test}
            element={
              <AuthGuard>
                <Test />
              </AuthGuard>
            }
          />
          <Route path={ROUTES.customLogin} element={<CustomLogin />} />
          <Route path={ROUTES.permissionDenied} element={<Error code={403} />} />
          <Route path={ROUTES.validationFailed} element={<Error code={401} />} />
          <Route path={ROUTES.error500} element={<Error code={500} />} />
          <Route path={ROUTES.anyPage} element={<Error code={404} />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
