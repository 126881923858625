import { TIMER_STATUS, TimerStatusType } from '@app/types'
import { useEffect, useMemo, useState } from 'react'


const TimePercentage = {
  [TIMER_STATUS.expiring]: 5,
  [TIMER_STATUS.low]: 15,
}

export const useTimerStatus = (duration: number, maxTime: number): TimerStatusType => {
  const [timerStatus, setTimerStatus] = useState<TimerStatusType>(TIMER_STATUS.initial)
  const expiring = useMemo(() => (maxTime * TimePercentage[TIMER_STATUS.expiring]) / 100, [maxTime])
  const low = useMemo(() => (maxTime * TimePercentage[TIMER_STATUS.low]) / 100, [maxTime])

  useEffect(() => {
    if (duration <= low && duration > expiring) {
      setTimerStatus(TIMER_STATUS.low)
    } else if (duration <= expiring) {
      setTimerStatus(TIMER_STATUS.expiring)
    } else {
      setTimerStatus(TIMER_STATUS.initial)
    }
  }, [duration, expiring, low])

  return timerStatus
}
