import React, { useCallback } from 'react'
import { styled } from '@mui/material'
import {
  getFeedbackItemsFromPercentages,
  getPercentagesPadLength,
  percentageFormatter,
  useItemResponsePercentages,
} from '@app/helpers'
import { ItemFeedbackGroup } from './ItemFeedbackGroup'

const StyledFeedback = styled(ItemFeedbackGroup)(({ theme }) => ({
  marginTop: theme.spacing(4),
}))

type Props = {
  itemId: string
}

export const QuestionGroupFeedback: React.FC<Props> = ({ itemId }) => {
  const percentages = useItemResponsePercentages(itemId)

  const padLength = getPercentagesPadLength(percentages)

  const feedbackPercentageFormatter = useCallback(
    (percentage: number) => percentageFormatter.format(percentage).padStart(padLength, ' '),
    [padLength],
  )

  if (!percentages) {
    return null
  }

  const feedbackItems = getFeedbackItemsFromPercentages(percentages)

  return <StyledFeedback items={feedbackItems} percentageFormatter={feedbackPercentageFormatter} />
}
