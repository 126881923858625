import { proxy } from 'valtio';

interface HeaderTitleType {
  title: string;
}

const initialState: HeaderTitleType = {
  title: 'MasteryPrep'
};

export const HeaderTitleState = proxy<HeaderTitleType>(initialState);

export const setHeaderTitle = (title: string) => {
  HeaderTitleState.title = title
}

export const resetHeaderTitleState = () => {
  HeaderTitleState.title = initialState.title
}