import React from 'react'
import { SelectChangeEvent, SelectProps } from '@mui/material'
import { SelectComponentItem } from '@app/components/ui/inputs'
import { REVIEW_BADGE_ORIGIN } from '@app/components/ui/badges'
import { onKeyboardSubmit } from '@app/helpers'
import { SELECT_ITEM_ATTRIBUTES } from '@app/constants'
import { StyledReviewBadge, StyledSelect } from './styles'
import {
  TooltipFeedbackGroup,
  TooltipFeedbackGroupProps,
} from '@app/components/feedback/TooltipFeedbackGroup'
import { TooltipFeedback } from '@app/components/feedback'

export type InlineChoiceProps = Omit<SelectProps, 'onChange'> & {
  onChange: (value: string, event?: SelectChangeEvent<any>) => void
  correct?: boolean
  correctAnswer?: string
  groupMode?: boolean
  feedbackItems?: TooltipFeedbackGroupProps['items']
}

const renderSelectValue =
  (choiceElements: React.ReactElement[]) =>
  (value: unknown): React.ReactNode => {
    const currentElement = choiceElements.find(inlineChoice => inlineChoice.props.identifier === value)
    return currentElement ?? <>{value}</>
  }

export const InlineChoice: React.FC<InlineChoiceProps> = props => {
  const {
    children,
    value,
    onChange,
    correct,
    correctAnswer,
    groupMode = false,
    feedbackItems,
    ...selectProps
  } = props

  const handleChange = (event: SelectChangeEvent<any>) => {
    onChange(event.target.value, event)
  }

  const handleKeyDown = (value: string) => () => onChange(value)

  const childrenArr = React.Children.toArray(children).filter(
    (child: any) => child.props,
  ) as React.ReactElement[]
  const transformedChildren = childrenArr.map(
    child =>
      child?.props?.identifier && (
        <SelectComponentItem
          key={child.props.identifier}
          value={child.props.identifier}
          onKeyDown={onKeyboardSubmit(handleKeyDown(child.props.identifier))}
          {...SELECT_ITEM_ATTRIBUTES}
          {...child.props?.accessibilityAttr}
        >
          {child.props.children}
        </SelectComponentItem>
      ),
  )

  const correctAnswerLabel = transformedChildren.find(child => child.props.value === correctAnswer)?.props
    ?.children

  return (
    <StyledReviewBadge
      as={groupMode ? TooltipFeedbackGroup : TooltipFeedback}
      correct={correct}
      correctAnswer={correctAnswerLabel}
      groupMode={groupMode}
      items={feedbackItems}
      anchorOrigin={REVIEW_BADGE_ORIGIN.topLeft}
    >
      <StyledSelect
        inline
        value={value}
        onChange={handleChange}
        {...selectProps}
        renderValue={renderSelectValue(childrenArr)}
      >
        {transformedChildren}
      </StyledSelect>
    </StyledReviewBadge>
  )
}
