import { useState, FC, useCallback, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { faMinusSquare } from '@fortawesome/pro-solid-svg-icons/faMinusSquare'
import { faPlusSquare } from '@fortawesome/pro-solid-svg-icons/faPlusSquare'
import { faStopwatch } from '@fortawesome/pro-solid-svg-icons/faStopwatch'
import {
  completeTest,
  CurrentTimerState,
  SyncIntervalState,
  CurrentUserAssessmentState,
} from '@app/storage'
import {
  useTimerSetup,
  useTimeoutAction,
  usePassedTimeRef,
} from '@app/helpers'
import {
  TimeLimitsWrapper,
  StyledTitle,
  StyledButton,
  TimeSwitchViewIcon,
  Timer,
  StyledTooltip,
  IdleDetectorModal,
} from './components'
import { timeConverter } from './helpers'
import { useTimerStatus } from './hooks'
import { AssessmentStatus, updateUserAssessment } from '@app/firebase/api'

interface TimeLimitsContainerProps {
  resourceId: string
  maxTime: number | null
  allowLateSubmission: boolean
  onTimeOut: () => void
}

const TimeLimitsContainer: FC<TimeLimitsContainerProps> = ({ allowLateSubmission, maxTime, onTimeOut }) => {
  const { remainedTime } = useSnapshot(CurrentTimerState)
  const { status, userAssessmentId } = useSnapshot(CurrentUserAssessmentState)
  const { emittedSyncEvents } = useSnapshot(SyncIntervalState)
  const timerStatus = useTimerStatus(remainedTime, maxTime)
  const passedTime = usePassedTimeRef()

  const [showTimer, setShowTimer] = useState<boolean>(true)

  const handleFinishTest = useCallback(() => {
    completeTest()
    onTimeOut()
  }, [onTimeOut])

  useTimerSetup()
  useTimeoutAction(allowLateSubmission, handleFinishTest)

  useEffect(() => {
    if (emittedSyncEvents && userAssessmentId) {
      updateUserAssessment(userAssessmentId, { passedTime: passedTime.current })
    }
    // eslint-disable-next-line
  }, [emittedSyncEvents, userAssessmentId])

  return (
    <>
      { remainedTime && status !== AssessmentStatus.completed &&
        <TimeLimitsWrapper showTimer={showTimer}>
          {showTimer ? (
            <StyledTitle
              variant={'h2'}
              timerStatus={timerStatus}
              role="timer"
              aria-label="Remained time"
            >
              {timeConverter(remainedTime)}
            </StyledTitle>
          ) : (
            <Timer icon={faStopwatch} />
          )}
          <StyledTooltip title={(showTimer ? 'Collapse' : 'Expand') + ' Timer'} placement='bottom'>
            <StyledButton
              aria-pressed={showTimer}
              aria-label={'Timer visibility'}
              onClick={() => setShowTimer(current => !current)}
            >
              {showTimer ? (
                <TimeSwitchViewIcon icon={faMinusSquare} />
              ) : (
                <TimeSwitchViewIcon icon={faPlusSquare} />
              )}
            </StyledButton>
          </StyledTooltip>
        </TimeLimitsWrapper>
      }

      <IdleDetectorModal/>
    </>
  )
}

export default TimeLimitsContainer
