import { FirebaseErrorCodes, FirebaseErrorMessages } from '@app/constants'
import { db } from '@app/firebase'
import { FirebaseError } from 'firebase/app'
import { doc, getDoc, onSnapshot, setDoc, Unsubscribe, updateDoc } from 'firebase/firestore'
import { FirestoreCollections, UserAssessment } from '../types'

export const getUserAssessmentId = (
  userId: string,
  assessmentId: string,
  schoolYear: string,
  attempt?: number,
): string => [userId, assessmentId, schoolYear, attempt].filter(item => item).join('_')

/**
 * Create document in Firestore with initial values.
 * @param { UserAssessment } payload user assessment initial object.
 */
export const createUserAssessment = async (payload: UserAssessment) => {
  try {
    await setDoc(
      doc(
        db,
        FirestoreCollections.studentAssessments,
        `${payload.userId}_${payload.assessmentId}_${payload.schoolYear}_${payload.attempt}`,
      ),
      {
        ...payload,
      },
    )
  } catch (e) {
    console.error(e)
  }
}

/**
 * Save user assessment responses to Firestore.
 * @param { UserAssessment } payload user assessment data.
 */
export const updateUserAssessment = async (id: string, payload: Partial<UserAssessment>) => {
  try {
    await updateDoc(doc(db, FirestoreCollections.studentAssessments, id), {
      ...payload,
      updatedTimestamp: new Date(),
    })
  } catch (e) {
    console.error(e)
  }
}

/**
 * Get user assessment from Firestore by assessment id.
 * @param { String } id assessment identifier.
 * @returns { Promise<UserAssessment> } user assessment Promise object.
 */
export const getUserAssessmentById = async (id: string): Promise<UserAssessment> => {
  const docRef = doc(db, FirestoreCollections.studentAssessments, id)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data() as UserAssessment
  }
  throw new FirebaseError(FirebaseErrorCodes.NotFound, FirebaseErrorMessages.NotFound)
}

export const subscribeToUserAssessmentChanges = (
  id: string,
  onChange: (userAssessment: UserAssessment) => void,
): Unsubscribe => {
  const docRef = doc(db, FirestoreCollections.studentAssessments, id)
  const unsubscribe = onSnapshot(docRef, doc => {
    onChange(doc.data() as UserAssessment)
  })

  return unsubscribe
}
