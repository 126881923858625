import { forwardRef } from 'react'
import { styled } from '@mui/material'
import { ObjectComponent, ObjectComponentProps } from '@app/components/info'
import { RemoveButton } from '@app/components/ui'
import { focusStyle } from '@app/theme'

export interface PositionObjectProps extends ObjectComponentProps {
  isDragging?: boolean
  disabled?: boolean
  removable?: boolean
  onRemove?: () => void
  selected?: boolean
}

const PositionObjectBase = forwardRef((props: PositionObjectProps, ref) => {
  const { isDragging, removable, onRemove, disabled, ...objectProps } = props

  if (removable) {
    return (
      <RemoveButton
        onRemove={(e: React.MouseEvent) => {
          e.stopPropagation()
          onRemove()
        }}
        selected={props.selected}
      >
        <ObjectComponent ref={ref} tabIndex={disabled ? undefined : 0} {...objectProps} />
      </RemoveButton>
    )
  }

  return <ObjectComponent ref={ref} {...objectProps} />
})

export const PositionObject = styled(PositionObjectBase)(
  ({ theme, isDragging, width, height, selected, disabled }) => ({
    width: `${width}px`,
    height: `${height}px`,
    border: '2px solid transparent',
    cursor: 'pointer',

    '&:hover': {
      border: !selected ? `2px solid ${theme.palette.blue[500]}` : '',
    },
    '&:focus-visible': {
      ...focusStyle(theme, 2).border,
    },

    '&:disabled, &[disabled]': {
      pointerEvents: 'none',
      opacity: 0.5,
    },

    ...(disabled && {
      pointerEvents: 'none',
      opacity: 0.5,
    }),

    ...(selected && {
      border: `2px solid ${theme.palette.blue[500]}`,
    }),

    ...(isDragging && {
      '&:hover, &:focus': {
        borderColor: 'transparent',
        outline: 'none',
      },
    }),
  }),
)
