import { Children, FC } from 'react'
import { styled } from '@mui/material'
import { Hotspot, HotspotChoice, HotspotChoiceProps } from '@app/components'
import { PositionObject } from './PositionObject'
import { usePositionSetup } from '../hooks'

export type PositionedHotspotProps = HotspotChoiceProps & {
  point?: string
  children?: JSX.Element | JSX.Element[]
}

const getCoords = (center: string, width: number, height: number): string => {
  const [cx, cy] = center.split(' ').map(value => parseFloat(value))
  const x1 = cx - width / 2
  const y1 = cy - height / 2
  const x2 = cx + width / 2
  const y2 = cy + height / 2
  const coords = [x1, y1, x2, y2].join(',')
  return coords
}

export const PositionedHotspot: FC<PositionedHotspotProps> = props => {
  const { children, point = '', onToggle, ...hotspotProps } = props

  const positionObject = Children.only(children)
  const coords = getCoords(point, positionObject.props.width, positionObject.props.height)

  const [hotspotRef, boxRef] = usePositionSetup()

  return (
    <StyledGroup onClick={onToggle}>
      <HotspotChoice ref={hotspotRef} coords={coords} {...hotspotProps} />
      <ObjectWrapper ref={boxRef}>
        <positionObject.type {...positionObject.props} />
      </ObjectWrapper>
    </StyledGroup>
  )
}

const StyledGroup = styled('g')(() => ({
  cursor: 'pointer',

  [`${Hotspot}`]: {
    stroke: 'none',
  },
}))

const ObjectWrapper = styled('foreignObject')(() => ({
  overflow: 'visible',

  [`${PositionObject}`]: {},
}))
