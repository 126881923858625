import { FC } from 'react'
import { ScreenComponent } from '@app/components'
import { Box, Button, Typography, styled } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons/faLockKeyhole'
import { faCircleMinus } from '@fortawesome/pro-solid-svg-icons/faCircleMinus'
import { faHexagonXmark } from '@fortawesome/pro-solid-svg-icons/faHexagonXmark'
import { useTestCallback } from '@app/helpers'

const initialAttributes: {
  fontSize: 'small' | 'inherit' | 'medium' | 'large'
} = {
  fontSize: 'large',
}

const StyledIcon = styled(FontAwesomeIcon)(() => ({
  fontSize: '1.875rem',
}))

const iconsVariation = {
  401: <StyledIcon icon={faLockKeyhole} {...initialAttributes} />,
  403: <StyledIcon icon={faLockKeyhole} {...initialAttributes} />,
  404: <StyledIcon icon={faCircleMinus} {...initialAttributes} />,
  500: <StyledIcon icon={faHexagonXmark} {...initialAttributes} />,
}

const errorMessages = {
  401: 'Authentication failed',
  403: 'Permission denied',
  404: 'Page not found',
  500: 'Something went wrong',
}

type ErrorCodes = 401 | 403 | 404 | 500

type ErrorPageProps = {
  code: ErrorCodes
}

const ErrorPage: FC<ErrorPageProps> = ({ code }) => {
  const onTestCallback = useTestCallback()

  return (
    <Wrapper>
      <ScreenComponent>
        <ContentWrapper>
          <Label>
            <CodeContainer>
              {iconsVariation[code]}
              <ErrorCode>{code}</ErrorCode>
            </CodeContainer>
            <VerticalLine />
            <ErrorMessage>{errorMessages[code]}</ErrorMessage>
          </Label>
          <Button variant='outlined' onClick={onTestCallback}>
            Return to Study Plan
          </Button>
        </ContentWrapper>
      </ScreenComponent>
    </Wrapper>
  )
}

const Wrapper = styled(Box)(() => ({
  height: '100vh',
  overflowY: 'hidden',
}))

export const CodeContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  textAlign: 'center',
}))

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  height: '100%',
  alignItems: 'center',
  gap: theme.spacing(4),
}))

const Label = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}))

const ErrorCode = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  margin: '0 20px 0 10px',
  fontSize: '42px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const VerticalLine = styled(Box)(({ theme }) => ({
  width: '1px',
  height: '100%',
  background: theme.palette.grey[800],
}))

const ErrorMessage = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  margin: '0 0 0 14px',
  fontSize: '32px',
  fontWeight: 100,
}))

export default ErrorPage
