import { OrientationEnum, OrientationType } from '@app/components/interactions/OrderInteraction/types'
import { useDroppable } from '@dnd-kit/core'
import { Box, styled } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'

interface BlockDropContainerComponentProps {
  id: string
  orientation?: OrientationType
  children?: JSX.Element | JSX.Element[]
}

export const BlockDropContainerComponent: FC<BlockDropContainerComponentProps> = props => {
  const { id, orientation = OrientationEnum.vertical, children, ...rest } = props
  const containerRef = useRef(null)
  const { setNodeRef } = useDroppable({ id })
  const [minContainerHeight, setMinContainerHeight] = useState(185)

  setNodeRef(containerRef.current)

  useEffect(() => {
    const { offsetHeight = 0 } = containerRef?.current || {}
    setMinContainerHeight(prevValue => (prevValue > offsetHeight ? prevValue : offsetHeight))
  }, [containerRef?.current?.offsetHeight])

  return (
    <StyledBlockDropContainer
      ref={containerRef}
      orientation={orientation}
      minHeight={minContainerHeight}
      {...rest}
    >
      {children}
    </StyledBlockDropContainer>
  )
}

interface StyledBlockDropContainerProps {
  orientation: OrientationType
  minHeight?: number
}

const StyledBlockDropContainer = styled(Box)<StyledBlockDropContainerProps>(
  ({ theme, orientation, minHeight }) => ({
    width: orientation === OrientationEnum.vertical ? '200px' : '100%',
    minWidth: '191px',
    minHeight: orientation === OrientationEnum.vertical ? minHeight : '55px',
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'scroll',
    ...(orientation === OrientationEnum.vertical
      ? {
          display: 'flex',
          flexDirection: 'column',
        }
      : {
          display: 'grid',
          gridGap: theme.spacing(2),
          gridTemplateColumns: 'repeat(auto-fill, 150px)',
          '& > div': {
            margin: 0,
            '&:not(:last-child)': {
              margin: 0,
            },
          },

          [theme.breakpoints.down('mobile')]: {
            columnGap: theme.spacing(1),
            rowGap: theme.spacing(2),
          },
        }),
  }),
)
