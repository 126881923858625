import { useDraggable } from '@dnd-kit/core'
import { Box, Tooltip } from '@mui/material'
import { FC, forwardRef, MutableRefObject, useEffect, KeyboardEvent, useCallback } from 'react'
import {
  DND_ELEMENT_ATTRIBUTES,
  REMOVE_ITEM_ATTRIBUTES,
  TEST_ATTRIBUTE_ID,
  TEST_ELEMENT_IDS,
} from '@app/constants'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { generateAttrWithValue } from '@app/helpers'
import { REVIEW_BADGE_ORIGIN } from '../../badges'
import {
  BasicDragContainerWrapper,
  IndexWrapper,
  StyledReviewBadge,
  StyledBasicDragContainer,
  ContentWrapper,
  RemoveWrapper,
  RemoveButton,
  TimesCircleIcon,
} from './styles'
// import {
//   TooltipFeedbackGroup,
//   TooltipFeedbackGroupProps,
// } from '@app/components/feedback/TooltipFeedbackGroup'
import { TooltipFeedback } from '@app/components/feedback'

export interface BasicDragContainerComponentProps {
  disabled?: boolean
  drag?: boolean
  assigned?: boolean
  placehold?: boolean
  selected?: boolean
  correct?: boolean
  correctAnswer?: React.ReactNode
  groupMode?: boolean
  // feedbackItems?: TooltipFeedbackGroupProps['items']
  fluid?: boolean
  index?: number | null
  style?: any
  onClick?: () => void
  keyDownSubscriber?: (e: KeyboardEvent<HTMLDivElement>) => void
  handleRemove?: () => void
  children: JSX.Element | JSX.Element[]
  node?: MutableRefObject<HTMLElement>
  id?: string
  variantIndex?: number
  roleAttr?: string
  accessibility?: { [key: string]: string }
  className?: string
}

export const BasicDragContainerComponent = forwardRef<HTMLSpanElement, BasicDragContainerComponentProps>(
  (
    {
      index,
      assigned,
      disabled,
      selected,
      drag,
      correct,
      correctAnswer,
      groupMode = false,
      // feedbackItems,
      fluid = false,
      handleRemove,
      keyDownSubscriber,
      id,
      variantIndex,
      node,
      roleAttr,
      accessibility,
      className,
      ...props
    },
    ref,
  ) => {
    const testAttributes =
      variantIndex || variantIndex === 0
        ? generateAttrWithValue(TEST_ATTRIBUTE_ID, TEST_ELEMENT_IDS.dragAndDropElement, variantIndex)
        : DND_ELEMENT_ATTRIBUTES

    useEffect(() => {
      if (selected && !disabled && !drag && node && node.current) {
        const containerBase = node.current.lastChild as HTMLElement
        containerBase && containerBase?.focus()
      }
    }, [id, node, selected, disabled, drag])

    const keyboardRemove = useCallback(
      (event: KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab') return

        event.preventDefault()
        event.stopPropagation()

        if (event.key === ' ' || event.key === 'Enter') {
          handleRemove()
        }
      },
      [handleRemove],
    )

    const removeBtn = (
      <RemoveButton
        role='navigation'
        aria-label='Remove button'
        selected={selected && assigned}
        onClick={handleRemove}
        onKeyDown={keyboardRemove}
        disabled={!assigned}
        {...REMOVE_ITEM_ATTRIBUTES}
      >
        <TimesCircleIcon icon={faTimesCircle} />
      </RemoveButton>
    )

    return (
      <BasicDragContainerWrapper
        onKeyDown={keyDownSubscriber}
        ref={ref}
        className={className}
        {...accessibility}
        {...(roleAttr ? { role: roleAttr } : {})}
      >
        {!!index && <IndexWrapper>{index}.</IndexWrapper>}
        <StyledReviewBadge
          // as={groupMode ? TooltipFeedbackGroup : TooltipFeedback}
          as={groupMode ? Box : TooltipFeedback}
          correct={correct}
          correctAnswer={correctAnswer}
          // items={feedbackItems}
          anchorOrigin={REVIEW_BADGE_ORIGIN.topLeft}
        >
          <StyledBasicDragContainer
            data-identifier={id}
            component='span'
            assigned={assigned}
            disabled={disabled}
            selected={selected}
            drag={drag}
            fluid={fluid}
            {...props}
            {...testAttributes}
            tabIndex={disabled ? -1 : 0}
          >
            <ContentWrapper component='span' assigned={assigned}>
              {props.children}
            </ContentWrapper>
            <RemoveWrapper aria-hidden role='tooltip'>
              {assigned ? (
                <Tooltip
                  title='Remove'
                  placement='top'
                  componentsProps={{
                    tooltip: {
                      sx: {
                        marginBottom: '0 !important',
                      },
                    },
                  }}
                >
                  {removeBtn}
                </Tooltip>
              ) : (
                removeBtn
              )}
            </RemoveWrapper>
          </StyledBasicDragContainer>
        </StyledReviewBadge>
      </BasicDragContainerWrapper>
    )
  },
)

interface DragContainerComponentProps extends BasicDragContainerComponentProps {
  id: string
  resetDragState?: () => void
  containerId?: string
}

export const DragContainerComponent: FC<DragContainerComponentProps> = ({
  id,
  containerId,
  disabled,
  placehold,
  ...props
}) => {
  const { setNodeRef, attributes, listeners, node } = useDraggable({
    id,
    disabled,
    data: { containerId },
  })

  return (
    <BasicDragContainerComponent
      id={id}
      disabled={disabled}
      ref={setNodeRef}
      node={node}
      accessibility={{
        'aria-label': placehold ? ' ' : 'unselected',
      }}
      {...listeners}
      {...attributes}
      {...props}
    />
  )
}
