import { forwardRef } from 'react'
import { REVIEW_BADGE_ORIGIN } from '@app/components/ui/badges'
import { StyledGapImg, StyledReviewBadge } from './styles'
import { GapImgProps } from './types'

export const GapImg = forwardRef<any, GapImgProps>(({ correct, ...props }, ref) => {
  if (correct === undefined) {
    return <StyledGapImg ref={ref} {...props} />
  }

  return (
    <StyledReviewBadge correct={correct} anchorOrigin={REVIEW_BADGE_ORIGIN.topLeft}>
      <StyledGapImg ref={ref} {...props} />
    </StyledReviewBadge>
  )
})
