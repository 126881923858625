import { BuilderOptions } from "xml2js"
import { XMLTools } from ".."

/**
 * HTML builder.
 * @param { Object | String } content content that is suppose to be either object with HTML keys or plain text.
 * @returns { String } content either in HTML format or a simple string.
 */
 export const buildHtml = (content: any, options?: BuilderOptions): string => {
  if (typeof content === 'string') {
    return content
  }
  if (content?.data && typeof content?.data === 'string') {
    return content?.data
  }

  const builderOptions: BuilderOptions = {
    headless: true,
    rootName: 'div',
    renderOpts: {
      pretty: true
    }
  }

  return XMLTools.build(content, options || builderOptions)
}