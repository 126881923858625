import React from 'react'
import { Link, Stack, Typography } from '@mui/material'
import { LOGOUT_BTN_ATTRIBUTES } from '@app/constants'
import { User } from '@app/types'

type Props = {
  user: User
  onLogout: () => void
}

const UserGreeting: React.FC<Props> = ({ user, onLogout }) => {
  return (
    <Stack gap={2} alignItems='center' mb={4}>
      <Typography variant='body1'>
        Hello,{' '}
        <Typography variant='body1' fontWeight={500} component='span'>
          {user.firstName} {user.lastName}
        </Typography>
      </Typography>
      <Typography variant='body1'>
        Not you?{' '}
        <Link href='#' onClick={onLogout} {...LOGOUT_BTN_ATTRIBUTES}>
          Log Out
        </Link>
      </Typography>
    </Stack>
  )
}

export default UserGreeting
