import { AssessmentItemComponent } from '@app/components'
import { ItemsNavigator, QuestionContextMenuProvider } from '@app/components/common'
import { TestPart } from '@app/models'
import { CurrentTestState, moveToSelectedItem } from '@app/storage'
import { Box, styled } from '@mui/material'
import { FC } from 'react'
import { useSnapshot } from 'valtio'

interface TestPartProps {
  testPart: TestPart
}

const TestPartComponent: FC<TestPartProps> = ({ testPart }) => {
  const { itemsResponses, currentItemResponse, testPartItems } = useSnapshot(CurrentTestState)

  return (
    <>
      {itemsResponses.length > 0 && currentItemResponse.id && testPartItems.length && (
        <QuestionContextMenuProvider itemId={currentItemResponse.itemId}>
          <AssessmentItemComponent />
          <TestFooterWrapper>
            <ItemsNavigator onChange={moveToSelectedItem} />
          </TestFooterWrapper>
        </QuestionContextMenuProvider>
      )}
    </>
  )
}

export const TestFooterWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(4, 8),
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[6],

  [theme.breakpoints.down('mobile')]: {
    padding: theme.spacing(0),
  },
}))

export default TestPartComponent
