import { useCallback, useState } from 'react'

export type UseModal = {
  open: boolean
  onOpen: () => void
  onClose: () => void
  onToggle: () => void
}

export const useModal = (defaultOpen: boolean = false): UseModal => {
  const [open, setOpen] = useState<boolean>(defaultOpen)

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onToggle = useCallback(() => {
    setOpen(current => !current)
  }, [])

  return { open, onOpen, onClose, onToggle }
}
