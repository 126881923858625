import { FC, memo } from 'react'
import { useSnapshot } from 'valtio'
import { alpha, Box, GlobalStyles, styled } from '@mui/material'
import { ContentRenderer } from '@app/components/common'
import { QuestionFeedback, QuestionGroupFeedback } from '@app/components/feedback'
import { InfoControlLayout, InteractionLayout } from '@app/components/ui/layouts'
import { useAssessmentItem, useAssessmentItemStyles } from '@app/helpers'
import { CurrentTestState, CurrentUserAssessmentState, ScoringState } from '@app/storage'
import { AssessmentStatus } from '@app/firebase/api'

interface AssessmentItemProps {}

const AssessmentItemComponent: FC<AssessmentItemProps> = memo(() => {
  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)
  const { status } = useSnapshot(CurrentUserAssessmentState)
  const { groupMode } = useSnapshot(ScoringState)

  const { currentItem, interaction, infoControl, hasInlineInteractions } = useAssessmentItem()

  const styles = useAssessmentItemStyles(currentItem)

  const isCompleted = status === AssessmentStatus.completed

  return (
    <AssessmentItemWrapper component='main' aria-label='Question area'>
      {styles && <GlobalStyles styles={styles} />}

      {currentItem && (
        <>
          {infoControl.trim() && (
            <InfoControlLayout>
              <ContentRenderer
                itemId={currentItem?.id}
                content={infoControl}
                resourceId={currentItem?.resourceId}
              />
            </InfoControlLayout>
          )}
          {currentItemResponse?.id && interaction && (
            <InteractionLayout
              itemId={currentItemResponse?.itemId}
              large={currentTestPart?.twoColumnLayout}
              reviewMode={isCompleted}
            >
              <ContentRenderer
                itemId={currentItem?.id}
                content={interaction}
                resourceId={currentItem?.resourceId}
              />
              {hasInlineInteractions && (
                <>
                  {groupMode ? (
                    <QuestionGroupFeedback itemId={currentItem?.id} />
                  ) : (
                    <QuestionFeedback itemId={currentItem?.id} />
                  )}
                </>
              )}
            </InteractionLayout>
          )}
        </>
      )}
    </AssessmentItemWrapper>
  )
})

export const AssessmentItemWrapper = styled(Box)(
  ({ theme }) => `
  position: relative;
  padding: ${theme.spacing(4, 8)};
  display: flex;
  justify-content: center;
  gap: ${theme.spacing(6)};
  overflow: hidden;
  height: 100%;
  background-color: ${alpha(theme.palette.grey[50], 0.4)};

  &:focus {
    outline: none;
  }

  ${[theme.breakpoints.down('mobile')]} {
    padding: ${theme.spacing(3)};
    flex-direction: column;
    gap: ${theme.spacing(3)};
  }
`,
)

export default AssessmentItemComponent
