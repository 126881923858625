import { Children, FC, useMemo, useRef } from 'react'
import { alpha, IconButton, PopperProps, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { useFocusControl } from './hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { REMOVE_ITEM_ATTRIBUTES } from '@app/constants'
import { Placement } from '@app/types'

export interface RemoveButtonProps extends Partial<TooltipProps> {
  children: JSX.Element
  wrapper?: React.ComponentType
  wrapperProps?: Partial<React.HTMLProps<HTMLDivElement>>
  onRemove?: (e?: React.MouseEvent) => void
  placement?: Placement
  selected?: boolean
  disableTooltipAnimation?: boolean
}

export const RemoveButton: FC<RemoveButtonProps> = props => {
  const {
    children,
    onRemove,
    wrapper: Wrapper = 'div',
    wrapperProps,
    placement = 'right',
    selected,
    disableTooltipAnimation,
    ...tooltipProps
  } = props

  const childElement = Children.only(children)

  const childRef = useRef<any>()
  const buttonRef = useRef<any>()
  const popperRef = useRef<any>()

  const callbacks = useMemo(
    () => ({
      handleTargetBlur: childElement.props.onBlur,
      handleTargetKeyDown: childElement.props.onKeyDown,
    }),
    [childElement.props.onBlur, childElement.props.onKeyDown],
  )
  const { onTargetBlur, onTargetKeyDown, onTooltipContentKeyDown } = useFocusControl(
    childRef,
    buttonRef,
    callbacks,
  )

  const popperProps: Partial<PopperProps> = useMemo(() => {
    const additionalProps = { ...(selected ? { open: true } : {}) }
    const popperProps: Partial<PopperProps> = {
      popperRef,
      keepMounted: true,
      ...additionalProps,
    }

    return popperProps
  }, [selected])

  return (
    <StyledTooltip
      placement={placement}
      PopperProps={popperProps}
      leaveDelay={200}
      disableTooltipAnimation={disableTooltipAnimation}
      title={
        <StyledButtonTooltip placement='top' title='Remove'>
          <StyledButton
            ref={buttonRef}
            color='primary'
            aria-label='Remove'
            onClick={onRemove}
            onKeyDown={onTooltipContentKeyDown}
            {...REMOVE_ITEM_ATTRIBUTES}
          >
            <TimesCircleIcon icon={faTimesCircle} />
          </StyledButton>
        </StyledButtonTooltip>
      }
      {...tooltipProps}
    >
      <Wrapper ref={childRef} {...wrapperProps} style={{ height: 'inherit' }}>
        <childElement.type {...childElement.props} onKeyDown={onTargetKeyDown} onBlur={onTargetBlur} />
      </Wrapper>
    </StyledTooltip>
  )
}

export const StyledTooltipBase = ({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
)

export const StyledTooltip = styled(StyledTooltipBase)<{
  disableTooltipAnimation?: boolean
}>(({ disableTooltipAnimation }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    margin: '0 !important',
    padding: 0,
    fontSize: '14px',
    ...(disableTooltipAnimation ? { transition: 'none !important' } : {}),
  },
}))

export const StyledButtonTooltip = styled(StyledTooltipBase)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    margin: '0 !important',
    backgroundColor: alpha(theme.palette.grey[900], 0.65),
    padding: theme.spacing(1, 1.5),
    fontSize: theme.typography.caption.fontSize,
    lineHeight: '13px',
    borderRadius: theme.shape.borderRadius * 1.5,
  },
}))

export const StyledButton = styled(IconButton)(({ theme }) => ({
  transformOrigin: 'top left',
  color: theme.palette.blue[500],

  ':hover, :focus': {
    backgroundColor: theme.palette.blue[50],
    color: theme.palette.blue[600],
  },
}))

const TimesCircleIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: '14px',
}))
