import { forwardRef, useState, useRef } from 'react'
import { TransformWrapper } from 'react-zoom-pan-pinch'
import { ZOOM_STEP } from '@app/constant'
import { ZoomableImageContent } from '@app/components/common/imageMagnifier/components/ZoomableImageContent'

type ZoomImageContainerProps = {
  src?: string
  alt?: string
  className?: string
  tabIndex?: number
  children?: string | JSX.Element | JSX.Element[]
  isFullscreen: boolean
  setOpenFullImage: (status: boolean) => void
}

export const ZoomImageContainer = forwardRef<any, ZoomImageContainerProps>(
  ({ isFullscreen, setOpenFullImage, ...props }, ref) => {
    const [imageScale, setImageScale] = useState<number>(1)
    const containerRef = useRef(null)

    const handleChangeFullscreen = (status: boolean) => {
      setOpenFullImage(status)
    }

    const handleZoomInOut =
      (decrease = false) =>
      () => {
        if (containerRef.current) {
          const { setTransform, instance } = containerRef.current
          const { scale, positionY, positionX } = instance.transformState
          const newScale = Math.round((decrease ? scale - ZOOM_STEP : scale + ZOOM_STEP) * 10) / 10
          setImageScale(newScale)
          setTransform(positionX, positionY, newScale)
        }
      }

    return (
      <TransformWrapper
        initialScale={1}
        maxScale={2}
        minScale={1}
        doubleClick={{ disabled: true }}
        wheel={{ disabled: true }}
        minPositionX={0}
        minPositionY={0}
        centerOnInit={true}
        ref={containerRef}
      >
        <ZoomableImageContent
          ref={ref}
          onFullscreenChange={handleChangeFullscreen}
          isFullscreen={isFullscreen}
          imageScale={imageScale}
          handleZoomIn={handleZoomInOut(false)}
          handleZoomOut={handleZoomInOut(true)}
          {...props}
        />
      </TransformWrapper>
    )
  },
)
