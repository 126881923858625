import { CoordinatesPropType } from 'victory'
import { exponentialFn, linearFn, quadraticFn } from './math'
import { ChartDomain, GraphProps, GraphState, GraphType, LineStyle, UniqueDOMPoint } from '../types'
import { KeyboardCoordinateGetter } from '@dnd-kit/core'
import { COORDINATES_SEPARATOR, POINTS_SEPARATOR } from '../constants'

export const getTicks = (min: number, max: number, increment: number = 1): number[] => {
  return Array((max - min) / increment + 1)
    .fill(null)
    .reduce((arr, _, i) => {
      return [...arr, min + i * increment]
    }, [])
}

export const getFunction = (type: GraphType, points: DOMPoint[], asymptote?: number) => {
  const [point1, point2] = points

  if (type === GraphType.LINEAR) {
    return (p: CoordinatesPropType) => linearFn(point1, point2)(p?.x)
  }

  if (type === GraphType.QUADRATIC) {
    return (p: CoordinatesPropType) => quadraticFn(point1, point2)(p?.x)
  }

  if (type === GraphType.EXPONENTIAL) {
    return (p: CoordinatesPropType) => exponentialFn(point1, point2, asymptote)(p?.x)
  }

  return (p: CoordinatesPropType) => p.x
}

export const getInitialPoints = (graphType: GraphType): UniqueDOMPoint[] => {
  if (graphType === GraphType.LINEAR) return [new UniqueDOMPoint(0, 0), new UniqueDOMPoint(1, 2)]
  if (graphType === GraphType.QUADRATIC) return [new UniqueDOMPoint(0, 0), new UniqueDOMPoint(2, 2)]
  if (graphType === GraphType.EXPONENTIAL) return [new UniqueDOMPoint(0, 1), new UniqueDOMPoint(2, 2)]

  return []
}

export const getInitialGraphs = (graphsData: Partial<GraphProps>[], graphType: GraphType): GraphState[] => {
  // If there are any graphs, initial data should be empty.
  // If there are no graphs, there will be 1 graph. Initialize points based on active graph type.
  if (graphsData.length) {
    return Array(graphsData.length)
      .fill({ isValid: true, points: [], lineStyle: LineStyle.SOLID })
      .map((graphItem, i) => ({ ...graphItem, id: graphsData[i].id }))
  }

  return [
    {
      isValid: true,
      points: getInitialPoints(graphType),
      lineStyle: LineStyle.SOLID,
    },
  ]
}

export const mergedGraphsStates = (source: GraphState[], target: GraphState[]): GraphState[] => {
  if (!target) return source

  return source.map(sourceGraph => {
    const existingGraph = target.find(targetGraph => targetGraph.id === sourceGraph.id)
    return existingGraph ?? sourceGraph
  })
}

export const isWithinDomain = (point: DOMPoint, domain: ChartDomain): boolean => {
  if (point.x < domain.x[0] || point.x > domain.x[1] || point.y < domain.y[0] || point.y > domain.y[1]) {
    return false
  }

  return true
}

export const isAsymptoteWithinDomain = (asymptote: number, domain: ChartDomain): boolean => {
  if (asymptote < domain.y[0] || asymptote > domain.y[1]) {
    return false
  }

  return true
}

export const getSelectedAreaPoints = (area: string): DOMPoint[] =>
  area
    .trim()
    // Split to vector commands
    .split(POINTS_SEPARATOR)
    // Remove last "z" command
    .slice(0, -1)
    .map(pathAction => {
      // Remove command letter and get [x,y] values
      const [x, y] = pathAction.slice(1).split(COORDINATES_SEPARATOR)
      return new DOMPoint(Math.round(parseFloat(x)), Math.round(parseFloat(y)))
    })
    // Remove the last point as it's always the same as first.
    .slice(0, -1)

export const getAreaFromPoints = (points: DOMPoint[]): string => {
  return [...points, points[0]]
    .map((point, i) => (i === 0 ? 'M' : 'L') + [point.x, point.y].join(COORDINATES_SEPARATOR))
    .concat('z')
    .join(POINTS_SEPARATOR)
}

export const getNextCoordinates =
  (deltaX: number, deltaY: number): KeyboardCoordinateGetter =>
  (event, args) => {
    const { currentCoordinates } = args

    switch (event.key) {
      case 'ArrowRight':
        return {
          ...currentCoordinates,
          x: currentCoordinates.x + deltaX,
        }
      case 'ArrowLeft':
        return {
          ...currentCoordinates,
          x: currentCoordinates.x - deltaX,
        }
      case 'ArrowDown':
        return {
          ...currentCoordinates,
          y: currentCoordinates.y + deltaY,
        }
      case 'ArrowUp':
        return {
          ...currentCoordinates,
          y: currentCoordinates.y - deltaY,
        }
      default:
        return currentCoordinates
    }
  }
