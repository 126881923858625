export const Roles = {
  Super_Administrator: 'Super Administrator',
  District_Admin: 'District Admin',
  School_Admin: 'School Admin',
  Instructor: 'Instructor',
  Teacher: 'Teacher',
  Student: 'Student',
  Sales_Rep: 'Sales Rep',
  Course_Author: 'Course Author',
}
