import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { FC } from "react";

interface ConfirmationModalProps {
  open: boolean;
  title: string;
  description: string;
  confirmText?: string;
  cancelText?: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmationModal:FC<ConfirmationModalProps> = (props) => {
  const {
    open,
    title,
    description,
    onClose,
    onConfirm,
    confirmText,
    cancelText
  } = props

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">
        { title }
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          { description }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          { cancelText || 'Cancel' }
        </Button>
        <Button onClick={onConfirm} autoFocus>
          { confirmText || 'Confirm' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}
