import { FC, ReactNode } from 'react'

type ScreenReaderInfoProps = {
  id?: string
  ariaLive?: 'off' | 'assertive' | 'polite'
  ariaBusy?: boolean
  ariaAtomic?: boolean
  role?: string
  children?: ReactNode
}

export const ScreenReaderInfo: FC<ScreenReaderInfoProps> = props => {
  const { id, children, ariaLive, ariaAtomic, ariaBusy, role } = props
  const ariaAttributes = {
    ...(ariaLive && { 'aria-live': ariaLive }),
  }
  return (
    <div
      id={id}
      {...ariaAttributes}
      aria-atomic={ariaAtomic}
      aria-busy={ariaBusy}
      role={role}
      className='sr-only'
    >
      {children}
    </div>
  )
}
