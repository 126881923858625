import { FC, useEffect } from 'react'

export const RootAriaHiddenController: FC<{ rootSelector: string }> = ({ rootSelector }) => {
  const root = document.querySelector(rootSelector)

  useEffect(() => {
    if (!root) {
      return
    }

    const observer = new MutationObserver(() => {
      if (root.getAttribute('aria-hidden')) {
        root.removeAttribute('aria-hidden')
      }
    })

    observer.observe(root, {
      attributeFilter: ['aria-hidden'],
    })

    return () => {
      observer.disconnect()
    }
  }, [root])

  return null
}
