import { Dialog, styled } from '@mui/material'
import { FC, ReactNode } from 'react'

type FullscreenImageModalProps = {
  opened: boolean
  onClose: () => void
  children?: ReactNode
}

export const FullscreenImageModal: FC<FullscreenImageModalProps> = props => {
  const { opened, onClose, children } = props
  return (
    <StyledDialog open={opened} onClose={onClose}>
      {children}
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    maxWidth: '70vw',
    width: '100%',
  },
}))
