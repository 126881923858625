import { forwardRef } from 'react'
import { styled } from '@mui/material'
import { CROSSHAIR_PATH } from './constants'
import { focusStyle } from '@app/theme'

export interface CrosshairProps extends React.SVGProps<SVGSVGElement>, CrosshairPathProps {
  width?: string | number
  height?: string | number
  disabled?: boolean
  onClick?: () => void
}

export const Crosshair = forwardRef<SVGSVGElement, CrosshairProps>(
  ({ width = 30, height = 30, onClick, selected, assigned, disabled, ...rest }, ref) => (
    <CrosshairContainer
      ref={ref}
      width={width}
      height={height}
      viewBox='0 0 30 30'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      onClick={onClick}
      selected={selected}
      disabled={disabled}
      {...rest}
    >
      <StyledCrosshair
        selected={selected}
        assigned={assigned}
        disabled={disabled}
        d={CROSSHAIR_PATH}
      ></StyledCrosshair>
    </CrosshairContainer>
  ),
)

const CrosshairContainer = styled('svg')<{ selected?: boolean; disabled?: boolean }>(
  ({ theme, selected, disabled }) => ({
    cursor: disabled ? 'initial' : 'pointer',

    ':focus': {
      outline: 'none',
    },

    ':focus-visible': {
      ...focusStyle(theme).shadow,

      [`& > ${StyledCrosshair}`]: {
        outline: 'none',
        // didn't find focus state for select point
        fill: theme.palette.blue[700],
      },
    },

    [`& > ${StyledCrosshair}`]: {
      ...(selected ? { fill: theme.palette.blue[500] } : {}),
    },

    ':hover': {
      [theme.breakpoints.up('tablet')]: {
        [`& > ${StyledCrosshair}`]: {
          ...(disabled ? {} : { fill: theme.palette.blue[700] }),
        },
      },
    },
  }),
)

export interface CrosshairPathProps {
  selected?: boolean
  assigned?: boolean
  disabled?: boolean
}

export const StyledCrosshair = styled(
  ({ assigned, disabled, ...props }: CrosshairPathProps & React.SVGProps<SVGPathElement>) => (
    <path {...props} />
  ),
)(({ theme, assigned, disabled }) => ({
  fill: assigned ? theme.palette.green[600] : theme.palette.blue[300],
  opacity: disabled ? 0.5 : 1,
}))
