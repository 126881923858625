import React from 'react'
import { useSnapshot } from 'valtio'
import { Box } from '@mui/material'
import { ConstraintsAlertContainer } from '@app/components/ui'
import { HotTextScoringContext } from './HotTextScoringContext'
import { DomNodes } from '@app/constants'
import {
  getChildrenWithInjectedProps,
  transformChoicesConstraints,
  useAssessmentItemResponseScoring,
  useSelectableChoice,
} from '@app/helpers'
import { CurrentTestState } from '@app/storage'
import { InteractionType } from '@app/types'

interface HotTextInteractionProps {
  itemId: string
  responseidentifier: string
  minchoices: string
  maxchoices: string
  children?: JSX.Element[]
  accessibilityAttr?: { [key: string]: string }
}

export const HotTextInteractionComponent: React.FC<HotTextInteractionProps> = props => {
  const { currentItemResponse } = useSnapshot(CurrentTestState)
  const {
    responseidentifier: responseDeclarationId,
    maxchoices,
    minchoices,
    children,
    accessibilityAttr,
  } = props
  const [minChoices, maxChoices] = transformChoicesConstraints(minchoices, maxchoices)

  const {
    selected: selectedChoices,
    onSelect,
    responseDeclaration,
    isValid,
  } = useSelectableChoice(responseDeclarationId, InteractionType.hotText, { minChoices, maxChoices })
  const { groupMode, correctResponse } = useAssessmentItemResponseScoring(
    currentItemResponse?.itemId,
    responseDeclarationId,
  )

  const childrenWithProps = getChildrenWithInjectedProps(DomNodes.hotText, children, {
    minChoices,
    maxChoices,
    responseDeclaration,
    onSelect,
    selectedChoices: selectedChoices || [],
    isValid,
  })

  return (
    <>
      <ConstraintsAlertContainer
        minChoices={minChoices}
        maxChoices={maxChoices}
        selectedCount={selectedChoices?.length}
      />
      {currentItemResponse && responseDeclaration && (
        <Box {...accessibilityAttr}>
          <HotTextScoringContext.Provider value={{ correctResponses: correctResponse, groupMode }}>
            {childrenWithProps}
          </HotTextScoringContext.Provider>
        </Box>
      )}
    </>
  )
}
