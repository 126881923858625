import { ApolloClient, DocumentNode, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { auth } from '@app/firebase'

import axios from 'axios'
import { errorParse } from './gqlErrorParse'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_STUDYPLAN_URI,
})

let token: any = null

export async function createAndSetEmulatedAuthToken(email: string, password?: string) {
  if (process.env.REACT_APP_EMULATE_AUTH === 'true') {
    //axios.get("http://google.com/")
    const customToken = (
      await axios.post('https://us-central1-mp-ready-staging.cloudfunctions.net/authGroup-authUser', {
        email: email,
        password: password ?? email,
      })
    )?.data?.customToken
    if (!customToken) {
      console.log('custom token not found')
      return ''
    }

    const idToken = (
      await axios.post(
        'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyCustomToken?key=' +
          process.env.REACT_APP_API_KEY,
        {
          returnSecureToken: true,
          token: customToken,
        },
      )
    )?.data?.idToken
    token = idToken
    // console.log("auth response:" + JSON.stringify(idToken))
    return idToken
  } else {
    console.log('REACT_APP_EMULATE_AUTH disabled')
    return ''
  }
}

async function getToken() {
  return token
}

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists //
  let token = null
  if (process.env.REACT_APP_USE_GQL_FIREBASE === 'true') {
    if (process.env.REACT_APP_EMULATE_AUTH === 'true') {
      token = await getToken()
    } else {
      token = await auth.currentUser?.getIdToken(false)
    }
  }
  // console.log("token: " + JSON.stringify(token))

  // console.log(
  //   "httpLink: " + JSON.stringify(process.env.REACT_APP_GRAPHQL_STUDYPLAN_URI)
  // )

  if (!token) {
    //console.log(" token is not ready for apollo header, yet")
    return {
      headers, // no change
    }
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'auth-provider': 'firebase',
    },
  }
})

// start client
export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

// util function to retry 5x, needed for cube but not really needed for other connections which won't time out
export async function retry<T>(fn: () => Promise<T>, maxRetries = 5, minElapse = 5000): Promise<T> {
  const startTime = Date.now()

  try {
    return await fn()
  } catch (error) {
    const elapsedTime = Date.now() - startTime

    if (maxRetries <= 0) {
      throw error
    }

    if (elapsedTime < minElapse) {
      await new Promise(resolve => setTimeout(resolve, minElapse - elapsedTime))
    }

    return retry(fn, maxRetries - 1, minElapse)
  }
}

export async function executeQuerySideload(gqlQuery: DocumentNode, parameterList: Record<string, any>) {
  // const result = await apolloCubeClient.query({
  //   query: gqlQuery,
  //   variables: parameterList,
  //   fetchPolicy: "no-cache" // temporary addition, cache mess up activate plan then refetch
  // })
  // return result?.data

  return (
    apolloClient
      .query({
        query: gqlQuery,
        variables: parameterList,
        fetchPolicy: 'no-cache', // temporary addition, cache mess up activate plan then refetch
      })
      .then(result => {
        return result?.data
      })
      //// only disable to test auth
      .catch(err => {
        throw errorParse(err)
      })
  )
}

// executeMutationSideload is defined here. Its exported, BUT must only be invoked by Mutation Resolvers.
export async function executeMutationSideload(gqlMutation: DocumentNode, parameterList: any) {
  return (
    apolloClient
      .mutate({
        mutation: gqlMutation,
        variables: parameterList,
      })
      .then(result => {
        return result?.data
      })
      //// disable catch to test auth
      .catch(err => {
        throw errorParse(err)
      })
  )
}
