import React from 'react'
import { Stack, Typography } from '@mui/material'
import { BasicModal } from "../BasicModal"
import {
  CANCEL_BTN_ATTRIBUTES,
  SUBMIT_MODAL_ATTRIBUTES,
  SUBMIT_TEST_BTN_ATTRIBUTES,
} from "@app/constants"

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

export const LeaveTestModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
}) => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      size="sm"
      title="Test in Progress"
      submitText="Leave"
      modalProps={SUBMIT_MODAL_ATTRIBUTES}
      submitButtonProps={SUBMIT_TEST_BTN_ATTRIBUTES}
      cancelButtonProps={CANCEL_BTN_ATTRIBUTES}
    >
      <Stack gap={6}>
        <Typography variant='variation_subtitle1'>
          Are you sure you want to quit?
        </Typography>
      </Stack>
    </BasicModal>
  )
}
