import React, { useState } from 'react'
import { Alert, Button, Stack, TextField, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird'
import {
  LOGIN_BTN_ATTRIBUTES,
  LOGIN_EMAIL_INPUT_ATTRIBUTES,
  LOGIN_PASSWORD_INPUT_ATTRIBUTES,
} from '@app/constants'

export type LoginData = {
  email: string
  password: string
}

type Props = {
  loading: boolean
  error: string
  onLogin: (data: LoginData) => void
  onResetError: () => void
}

const FORM_ID = 'login-form'

const LoginForm: React.FC<Props> = ({ onLogin, loading, error, onResetError }) => {
  const [login, setLogin] = useState<LoginData>({ email: '', password: '' })

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLogin(prev => ({ ...prev, [e.target.name]: e.target.value }))
    onResetError()
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    onLogin(login)
  }

  return (
    <Stack component='form' alignItems='center' id={FORM_ID} gap={4} onSubmit={onSubmit}>
      <Typography variant='h2'>Login</Typography>
      <TextField
        required
        label='Email'
        name='email'
        type='email'
        value={login.email}
        onChange={onChange}
        inputProps={{ ...LOGIN_EMAIL_INPUT_ATTRIBUTES }}
      />
      <TextField
        required
        label='Password'
        name='password'
        type='password'
        value={login.password}
        onChange={onChange}
        inputProps={{ ...LOGIN_PASSWORD_INPUT_ATTRIBUTES }}
      />
      <Button
        variant='contained'
        color='primary'
        type='submit'
        form={FORM_ID}
        disabled={loading}
        fullWidth
        {...LOGIN_BTN_ATTRIBUTES}
      >
        {loading ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : 'Login'}
      </Button>
      {error && <Alert severity='error'>{error}</Alert>}
    </Stack>
  )
}

export default LoginForm
