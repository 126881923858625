import { FC } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { combineListeners } from '@app/helpers'
import { OrderItemProps, OrderItem } from './OrderItem'

export const DraggableOrderItem: FC<OrderItemProps> = props => {
  const { id, disabled, ...rest } = props

  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({ id, disabled })

  return (
    <OrderItem
      ref={setNodeRef}
      id={id}
      disabled={disabled}
      dragging={isDragging}
      {...attributes}
      {...listeners}
      {...rest}
      {...combineListeners(listeners, rest)}
    />
  )
}
