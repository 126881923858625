import { FC, MouseEvent } from 'react'
import { styled } from '@mui/material'
import { RemoveButton } from '@app/components/ui'
import { DraggablePoint, DraggablePointProps } from './DraggablePoint'

export interface RemovablePointProps extends DraggablePointProps {
  removable?: boolean
  onRemove?: (id: string) => void
}

export const RemovablePoint: FC<RemovablePointProps> = ({ removable, onRemove, ...props }) => {
  if (removable) {
    const selected = props.selected || props.activeDraggable?.id === props.datum?.id

    const handleRemove = (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      props.onSelect && props.onSelect(null)
      onRemove(props.datum?.id)
    }

    return (
      <RemoveButton
        key={props.datum?.id}
        selected={selected}
        wrapper={Wrapper}
        onRemove={handleRemove}
        disableHoverListener
        disableFocusListener
        disableTooltipAnimation
      >
        <DraggablePoint {...props} />
      </RemoveButton>
    )
  }

  return <DraggablePoint {...props} />
}

const Wrapper = styled('g')(() => ({}))
