import { FC, useState } from 'react'

import { TabsUnstyled, TabPanelUnstyled } from '@mui/base'
import { Pills } from '@app/components/ui'
import { GraphingInteraction } from './GraphingInteraction'
import { Feedback } from './components'

import { useAssessmentItemResponseScoring } from '@app/helpers'
import { getAreaFromPoints, parseChildrenToProps, transformResponseToState } from './helpers'
import { useGraphing } from './hooks'
import {
  GraphingContext,
  SelectableAreasContext,
  INITIAL_GRAPHING_CONTEXT,
  INITIAL_SELECTABLE_AREAS_CONTEXT,
} from './context'
import { GraphingInteractionContainerProps } from './types'
import { HEIGHT, WIDTH } from './constants'

enum ReviewModeTabs {
  YourAnswer = 'your-answer',
  CorrectAnswer = 'correct-answer',
}

const REVIEW_MODE_NAV_ITEMS = [
  { value: ReviewModeTabs.YourAnswer, label: 'Your Answer' },
  { value: ReviewModeTabs.CorrectAnswer, label: 'Correct Answer' },
]

export const GraphingInteractionContainer: FC<Partial<GraphingInteractionContainerProps>> = props => {
  const { itemId, children, responseidentifier: responseId } = props

  const width = props.width ? parseInt(props.width) : WIDTH
  const height = props.height ? parseInt(props.height) : HEIGHT

  const {
    xAxis,
    yAxis,
    graphTypeSelect,
    staticPoints,
    graphsData,
    solutionSetEnabled,
    // solutionSetButtonText,
  } = parseChildrenToProps(children)

  const scoring = useAssessmentItemResponseScoring(itemId, responseId)

  const [currentTab, setCurrentTab] = useState<string>(
    scoring.groupMode ? ReviewModeTabs.CorrectAnswer : ReviewModeTabs.YourAnswer,
  )

  const onChangeTab = (_event: React.SyntheticEvent<Element, Event>, value: string | number | boolean) =>
    setCurrentTab(value.toString())

  const onViewCorrectAnswer = () => setCurrentTab(ReviewModeTabs.CorrectAnswer)

  const { graphs, selectableAreas, disabled } = useGraphing(
    responseId,
    width,
    height,
    graphsData,
    solutionSetEnabled,
  )
  const isReviewMode = !!scoring.scoring || scoring.groupMode
  const correctResponse = transformResponseToState(scoring.correctResponse ?? [])
  const graphingReviewContext = {
    ...INITIAL_GRAPHING_CONTEXT,
    graphs: correctResponse.graphs ?? INITIAL_GRAPHING_CONTEXT.graphs,
    asymptote: correctResponse.asymptote ?? INITIAL_GRAPHING_CONTEXT.asymptote,
    activeGraphType: correctResponse.graphType ?? INITIAL_GRAPHING_CONTEXT.activeGraphType,
  }
  const selectableAreasReviewContext = {
    ...INITIAL_SELECTABLE_AREAS_CONTEXT,
    areas:
      correctResponse.solutionAreas?.map(points => ({
        d: getAreaFromPoints(points),
        selected: true,
      })) ?? INITIAL_SELECTABLE_AREAS_CONTEXT.areas,
  }

  return (
    <TabsUnstyled
      defaultValue={scoring.groupMode ? ReviewModeTabs.CorrectAnswer : ReviewModeTabs.YourAnswer}
      value={currentTab}
      onChange={onChangeTab}
    >
      {isReviewMode && !scoring.correct && !scoring.groupMode && (
        <Pills size='sm' variant='filled' items={REVIEW_MODE_NAV_ITEMS} />
      )}

      {!scoring.groupMode && (
        <TabPanelUnstyled value={ReviewModeTabs.YourAnswer}>
          <GraphingContext.Provider value={graphs}>
            <SelectableAreasContext.Provider value={selectableAreas}>
              <GraphingInteraction
                key={itemId}
                width={width}
                height={height}
                xAxis={xAxis}
                yAxis={yAxis}
                graphTypeSelect={graphTypeSelect}
                staticPoints={staticPoints}
                graphsData={graphsData}
                solutionSetEnabled={solutionSetEnabled && !isReviewMode}
                disabled={disabled}
              />
            </SelectableAreasContext.Provider>
          </GraphingContext.Provider>
        </TabPanelUnstyled>
      )}

      {isReviewMode && (!scoring.correct || scoring.groupMode) && (
        <TabPanelUnstyled value={ReviewModeTabs.CorrectAnswer}>
          <GraphingContext.Provider value={graphingReviewContext}>
            <SelectableAreasContext.Provider value={selectableAreasReviewContext}>
              <GraphingInteraction
                key={`${itemId}-answer`}
                width={width}
                height={height}
                xAxis={xAxis}
                yAxis={yAxis}
                graphTypeSelect={graphTypeSelect}
                staticPoints={staticPoints}
                graphsData={graphsData}
                solutionSetEnabled={false}
                disabled
              />
            </SelectableAreasContext.Provider>
          </GraphingContext.Provider>
        </TabPanelUnstyled>
      )}

      {isReviewMode && (
        <Feedback itemId={itemId} responseId={responseId} onViewCorrectAnswer={onViewCorrectAnswer} />
      )}
    </TabsUnstyled>
  )
}
