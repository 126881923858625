import { FC } from 'react'
import { Box, styled } from '@mui/material'
import { useDroppable } from '@dnd-kit/core'

export interface DroppableAreaProps {
  id: string
  disabled?: boolean
}

export const DroppableArea: FC<DroppableAreaProps> = (props) => {
  const { id, disabled } = props

  const { setNodeRef } = useDroppable({ id, disabled })

  return <Overlay ref={setNodeRef}></Overlay>
}

const Overlay = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`
