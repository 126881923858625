import { DomNodes } from '@app/constants'
import { useFindNodes as findNodes } from '@app/helpers'
import { faDeleteLeft } from '@fortawesome/pro-solid-svg-icons/faDeleteLeft'
import { faLeft } from '@fortawesome/pro-solid-svg-icons/faLeft'
import { faRedo } from '@fortawesome/pro-solid-svg-icons/faRedo'
import { faRight } from '@fortawesome/pro-solid-svg-icons/faRight'
import { faUndo } from '@fortawesome/pro-solid-svg-icons/faUndo'
import { MutableRefObject } from 'react'
import { MathField } from 'react18-mathquill'
import { EquationOperatorsTypes, EquationSectionType, IMathFieldControls, SectionType } from './types'

export const setNumbersQueue = (numbersArr: string[]): string[] => {
  const zeroIndex = numbersArr.findIndex(number => number === '0')
  const numbersWithoutZero = numbersArr.filter(number => number !== '0')

  if (zeroIndex < 0 || numbersWithoutZero.length % 3 !== 0) return numbersArr

  return [...numbersArr.slice(0, zeroIndex), null, ...numbersArr.slice(zeroIndex), null]
}

export const getChunksNumbersQueue = (numbersArr: string[], chunks: number = 3) => {
  const length = numbersArr == null ? 0 : numbersArr.length
  if (!length || chunks < 1) {
    return []
  }
  let index = 0
  let resIndex = 0
  const result = new Array(Math.ceil(length / chunks))

  while (index < length) {
    result[resIndex++] = numbersArr.slice(index, (index += chunks))
  }

  return result
}

const extractOperatorsFromSection = (section: JSX.Element[], sectionType: SectionType) =>
  section.map(section => {
    const [equationOperators] = findNodes(section.props.children, [DomNodes.equationOperators])
    return equationOperators.reduce((res: EquationSectionType[], operators) => {
      const [equationOperator] = findNodes(operators.props.children, [DomNodes.equationOperator])
      const operatorsArr = equationOperator.map(operator => operator.props.tex)
      const chunks = sectionType === SectionType.numbersSection ? 3 : 5

      return [
        ...res,
        ...getChunksNumbersQueue(
          operators.props.type === EquationOperatorsTypes.numbers
            ? setNumbersQueue(operatorsArr)
            : operatorsArr,
          chunks,
        ).map(operatorsChunk => ({
          type: operators.props.type || EquationOperatorsTypes.actions,
          operators: operatorsChunk,
        })),
      ]
    }, [])
  })

export const getOperatorsStructure = (
  children: JSX.Element | JSX.Element[],
): [EquationSectionType[][], EquationSectionType[][], JSX.Element[]] => {
  const [numbersSection, actionsSection, prompt] = findNodes(children, [
    DomNodes.equationNumbersSection,
    DomNodes.equationActionsSection,
    DomNodes.prompt,
  ])

  return [
    extractOperatorsFromSection(numbersSection, SectionType.numbersSection),
    extractOperatorsFromSection(actionsSection, SectionType.actionsSections),
    prompt,
  ]
}

// export const getOperatorsTypes = (
//   numbersSection: EquationSectionType[][],
//   actionsSection: EquationSectionType[][],
// ) => {
//   return [...numbersSection, ...actionsSection]
//     .reduce((acc, s) => [...s, ...acc], [])
//     .reduce((acc: IOperatorsTypes, s) => {
//       const type = s.type
//       const operators = s.operators
//       const restOperators = acc[type] ? acc[type] : []
//       return {
//         ...acc,
//         [type]: [...operators, ...restOperators],
//       }
//     }, {})
// }

export const getBackSlashActions = (uniqOperators: string[]) => {
  return uniqOperators
    .map(action => (action === '*' ? '\\cdot' : action))
    .filter(action => action.includes('\\'))
    .map(action => action.replaceAll('\\', ''))
}

export const getUniqOperators = (
  numbersSection: EquationSectionType[][],
  actionsSection: EquationSectionType[][],
) => {
  return [...numbersSection, ...actionsSection]
    .reduce((res, s) => [...s, ...res], [])
    .reduce((res: string[], s) => {
      const operators: string[] = s.operators.filter(op => op)
      return Array.from(new Set([...res, ...operators]))
    }, [])
}

export const getMathFieldControls = (
  mathField: MathField,
  undo: (steps?: number) => void,
  redo: (steps?: number) => void,
): IMathFieldControls[] => {
  const focusCommandWrapper = (command: () => void) => {
    command()
  }
  return [
    {
      icon: faLeft,
      command: () => focusCommandWrapper(() => mathField?.keystroke('Left')),
      label: 'Move cursor left',
    },
    {
      icon: faRight,
      command: () => focusCommandWrapper(() => mathField?.keystroke('Right')),
      label: 'Move cursor right',
    },
    {
      icon: faUndo,
      command: () => undo(),
      label: 'Undo',
    },
    {
      icon: faRedo,
      command: () => redo(),
      label: 'Redo',
    },
    {
      icon: faDeleteLeft,
      command: () => focusCommandWrapper(() => mathField?.keystroke('Backspace')),
      label: 'Backspace',
    },
  ]
}

export const getUniqKey = (value?: string) => `${value || ''}/${Math.random()}`

export const isElementVisible = (el: Element) => {
  const rect = el.getBoundingClientRect(),
    vWidth = window.innerWidth || document.documentElement.clientWidth,
    vHeight = window.innerHeight || document.documentElement.clientHeight,
    efp = function (x: any, y: any) {
      return document.elementFromPoint(x, y)
    }

  if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) return false

  return (
    el.contains(efp(rect.left, rect.top)) ||
    el.contains(efp(rect.right, rect.top)) ||
    el.contains(efp(rect.right, rect.bottom)) ||
    el.contains(efp(rect.left, rect.bottom))
  )
}

export const scrollIntoView = (mathField: MathField) => {
  const cursor = document.querySelector('.mq-cursor')

  if (cursor != null && !isElementVisible(cursor)) {
    document.querySelector('.mq-cursor').scrollIntoView()
  }
}

export const checkInsertionOfSup = (action: string, mathField: MutableRefObject<MathField>): boolean => {
  if (action === '^' || action === '_') {
    const prevSymbol = (mathField as any).current.__controller.cursor[-1].ctrlSeq

    return !prevSymbol
  }

  return false
}

export const findNotIncludingSymbols = (
  latex: string,
  uniqOperators: string[],
  backSlashActions?: string[],
): boolean => {
  const _backSlashActions = uniqOperators
    .reduce((acc, action) => {
      if (action === '*') return ['cdot', ...acc]
      if (action === '(') return ['left(', 'right)', ...acc]
      if (action === '/') return ['frac', ...acc]
      return acc
    }, backSlashActions)
    .map(action => `\\${action}`)
  const _uniqOperators = uniqOperators.filter(action => !action.includes('\\'))

  const withoutBackslashActions = _backSlashActions.reduce((acc, action) => acc.replaceAll(action, ''), latex)

  return !![..._uniqOperators, '{', '}']
    .reduce((acc, action) => acc.replaceAll(`${action}`, ''), withoutBackslashActions)
    .trim()
}
