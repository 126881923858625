import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useCurrentUser } from '@app/helpers'

export const AuthGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const { loggedIn } = useCurrentUser()

  if (!loggedIn) {
    return (
      <Navigate
        replace
        to={`/custom_login${location.search}`}
        state={{ from: location.pathname }}
      />
    )
  }

  return <>{children}</>
}
