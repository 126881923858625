import { FC } from 'react'
import { DragOverlay, useDraggable } from '@dnd-kit/core'
import { Box, styled } from '@mui/material'
import { Crosshair, StyledCrosshair } from '@app/components/ui/svg/Crosshair'
import { focusStyle } from '@app/theme'

export const DRAGGABLE_CROSSHAIR_ID = 'draggable-crosshair'

const DraggableCrosshair: FC<any> = props => {
  const { disabled } = props

  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id: DRAGGABLE_CROSSHAIR_ID,
    disabled,
  })

  return (
    <StyledCrosshairWrapper
      ref={(el: any) => setNodeRef(el)}
      {...attributes}
      {...listeners}
      aria-label={isDragging ? '' : 'crosshair pointer'}
      aria-hidden={isDragging}
    >
      <Crosshair selected={isDragging} />
    </StyledCrosshairWrapper>
  )
}

export const SelectPointRow: FC<any> = props => {
  const { isDragging, maxSelected, className } = props

  return (
    <>
      <DragOverlay dropAnimation={null}>{isDragging && <Crosshair />}</DragOverlay>

      <FlexRow className={className} role='group' aria-label='Draggable select point'>
        {!maxSelected ? (
          <DraggableCrosshair />
        ) : (
          <Crosshair
            disabled
            role='button'
            aria-label='crosshair pointer'
            aria-disabled
            aria-roledescription='draggable'
          />
        )}
      </FlexRow>
    </>
  )
}

const StyledCrosshairWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',

  ':focus': {
    ...focusStyle(theme, 2).shadow,
  },

  [`${theme.breakpoints.up('mobile')} and (hover: hover)`]: {
    ':hover': {
      outline: `2px solid ${theme.palette.blue[500]}`,

      [`& ${StyledCrosshair}`]: {
        fill: theme.palette.blue[300],
      },
    },
  },
}))

const FlexRow = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}))
