import { BasicDragContainerComponent } from '@app/components/ui/containers'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { FC } from 'react'

interface SortableContainerComponentProps {
  id: string
  index: number | null
  selected: boolean
  disabled?: boolean
  drag?: boolean
  onClick?: () => void
  handleRemove?: () => void
  children?: JSX.Element | JSX.Element[]
}

export const SortableContainerComponent: FC<SortableContainerComponentProps> = ({
  id,
  index,
  children,
  ...props
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <BasicDragContainerComponent
      ref={setNodeRef}
      index={index}
      assigned={true}
      style={style}
      accessibility={{
        'aria-label': props.drag ? "" : "unselected",
      }}
      {...attributes}
      {...listeners}
      {...props}
    >
      {children}
    </BasicDragContainerComponent>
  )
}
