import { FC } from 'react'
import { DomNodes } from '@app/constants'

interface SimpleChoiceProps {
  componentNode: DomNodes
  letterIndex: string
  itemId: string
  identifier?: string
  fixed?: boolean
  children?: JSX.Element
}

export const DraggableSimpleChoiceComponent: FC<SimpleChoiceProps> = ({ children }) => {
  return <>{children}</>
}
