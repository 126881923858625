import { BasicModal } from '@app/components'
import { IDLE_DETECTOR_MODAL_ATTRIBUTES, RESET_IDLE_STATE_BTN_ATTRIBUTES } from '@app/constants'
import { useUserAssessentPauseCallback, useUserAssessentResumeCallback } from '@app/helpers'
import { closeIdleModal, IdleDetectorState, openIdleModal, resetIdleDetectorState, resumeSyncInterval, startIdleModalTimer } from '@app/storage'
import { FC, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { timeConverter } from '../helpers'

type IdleDetectorModalProps = {}

export const IdleDetectorModal:FC<IdleDetectorModalProps> = () => {
  const { isIdle, countdown, modalOpened } = useSnapshot(IdleDetectorState)
  const handlePauseAssessment = useUserAssessentPauseCallback()
  const handleResumeAssessment = useUserAssessentResumeCallback()

  useEffect(() => {
    if (isIdle) {
      openIdleModal()
    }
  }, [isIdle])

  useEffect(() => {
    if (modalOpened) {
      startIdleModalTimer()
      return
    }

    resetIdleDetectorState()
  }, [modalOpened])

  useEffect(() => {
    if (countdown === 0) {
      handlePauseAssessment()
    }
  }, [countdown, handlePauseAssessment])

  const handleResetIdle = () => {
    closeIdleModal()
    handleResumeAssessment()
    resumeSyncInterval()
    resetIdleDetectorState()
  }

  return (
    <BasicModal
      title='You are idle'
      open={modalOpened}
      onSubmit={handleResetIdle}
      submitText={`I'm still here!`}
      backdropDisabled
      noCancel
      modalProps={IDLE_DETECTOR_MODAL_ATTRIBUTES}
      submitButtonProps={RESET_IDLE_STATE_BTN_ATTRIBUTES}
    >
      {countdown > 0 ? (
        <>
          <p>Test will be paused automatically in</p>
          <span>{timeConverter(countdown)}</span>
        </>
      ) : (
        <span>Test has been paused</span>
      )}
  </BasicModal>
  )
}