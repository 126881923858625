import { Palette } from "@mui/material";
import { ExperienceRating } from "./types";

export const getIconColor = (isSelected: boolean, iconName: ExperienceRating, palette: Palette) => {
  if (!isSelected) return palette.blue[500]

  switch(iconName) {
    case 'pouting': {
      return palette.red[500]
    }
    case 'frown': {
      return palette.orange[600]
    }
    case 'meh': {
      return palette.amber[700]
    }
    case 'smile': {
      return palette.lime[600]
    }
    case 'grin': {
      return palette.green[500]
    }
    default: return palette.blue[500]
  }
}