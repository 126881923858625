import React, { forwardRef } from 'react'
import { styled } from '@mui/material'

export interface AssociationLineProps extends Omit<React.SVGProps<SVGGElement>, 'ref'> {
  jointSize?: string | number
  x1: string | number
  x2: string | number
  y1: string | number
  y2: string | number
}

export const AssociationLine = forwardRef<SVGGElement, AssociationLineProps>(
  ({ jointSize = 5, x1, x2, y1, y2, onClick, ...props }, ref) => {
    return (
      <StyledAssociationLine ref={ref} onClick={onClick} {...props}>
        {/* Thick invisible line to expand touch area. */}
        <line x1={x1} y1={y1} x2={x2} y2={y2} stroke='transparent' strokeWidth={Number(jointSize) * 3} />
        <StyledMarker cx={x1} cy={y1} r={jointSize} />
        <StyledMarker cx={x2} cy={y2} r={jointSize} />
        <StyledLine x1={x1} y1={y1} x2={x2} y2={y2} />
      </StyledAssociationLine>
    )
  },
)

const StyledAssociationLine = styled('g')<{ focused?: boolean }>(({ theme, focused }) => ({
  cursor: 'pointer',
  '-webkit-tap-highlight-color': 'transparent',
  touchAction: 'none',

  [`${StyledLine}`]: {
    outline: 'none',
    strokeWidth: '2px',
    stroke: focused ? theme.palette.blue[700] : theme.palette.green[600],

    [theme.breakpoints.down('mobile')]: {
      strokeWidth: 4,
    },
  },

  [`${StyledMarker}`]: {
    fill: focused ? theme.palette.blue[700] : theme.palette.green[600],
  },

  [`${theme.breakpoints.up('mobile')} and (hover: hover)`]: {
    ':hover': {
      [`${StyledLine}`]: {
        stroke: theme.palette.blue[700],
      },
      [`${StyledMarker}`]: {
        fill: theme.palette.blue[700],
      },
    },
  },

  ':focus-visible, :focus-within': {
    outline: 'none',

    [`${StyledLine}`]: {
      stroke: theme.palette.blue[700],
    },
    [`${StyledMarker}`]: {
      fill: theme.palette.blue[700],
    },
  },
}))

export const StyledTouchWrapperLine = styled('line')(() => ({}))

export const StyledMarker = styled('circle')(({ theme }) => ({
  fill: theme.palette.green[600],
}))

export const StyledLine = styled('line')(({ theme }) => ({
  outline: 'none',
  strokeWidth: '2px',
  stroke: theme.palette.green[600],
}))
