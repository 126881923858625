import { PropsWithChildren, forwardRef } from 'react'
import { Box, styled } from '@mui/material'

const StyledOrderItemsRow = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: flex-start;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};

  border: 1px solid ${theme.palette.grey[200]};
  border-radius: ${theme.shape.borderRadius}px;
  padding: ${theme.spacing(2)};
`,
)

export const OrderItemsRow = forwardRef<any, PropsWithChildren>((props, ref) => (
  <StyledOrderItemsRow ref={ref} {...props} />
))
