import { FC } from 'react'
import { styled } from '@mui/material'
import { RemoveButton, Crosshair, CrosshairPathProps } from '@app/components/ui/svg'
import { POINT_SEPARATOR } from '../hooks'
import { DATA_CLICK_AWAY_PROPS } from '@app/constants'

export interface SelectPointProps extends CrosshairPathProps {
  point: string
  removable?: boolean
  size?: number | string
  onRemove: () => void
  onClick: () => void
}

const DEFAULT_SIZE = 30

export const SelectPoint: FC<SelectPointProps> = props => {
  const { point, removable, size = DEFAULT_SIZE, onRemove, ...rest } = props

  const [x, y] = point.split(POINT_SEPARATOR)
  const cx = Number(x) - Number(size) / 2
  const cy = Number(y) - Number(size) / 2

  const crosshair = <Crosshair tabIndex={0} width={size} height={size} {...rest} aria-label='crosshair' />

  return (
    <SelectPointContainer x={cx} y={cy} width={size} height={size} {...DATA_CLICK_AWAY_PROPS}>
      {removable ? (
        <RemoveButton key={point} onRemove={onRemove} selected={props.selected}>
          {crosshair}
        </RemoveButton>
      ) : (
        crosshair
      )}
    </SelectPointContainer>
  )
}

const SelectPointContainer = styled('foreignObject')(() => ({
  overflow: 'visible',
}))
