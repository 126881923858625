import { OrgFilter, RefScope, Scope } from '@app/types'

export const getScopeFromFilters = (filters: OrgFilter): Scope => {
  if (filters.studentId) return 'STUDENT'
  if (filters.classId) return 'CLASS'
  if (filters.teacherId) return 'TEACHER'
  if (filters.schoolId) return 'SCHOOL'
  if (filters.districtId) return 'DISTRICT'
  return 'SYSTEM'
}

export const generateRefScope = (filters: OrgFilter): RefScope => {
  return {
    scope: getScopeFromFilters(filters),
    student_id: filters.studentId,
    class_id: filters.classId,
    teacher_id: filters.teacherId,
    school_id: filters.schoolId,
    district_id: filters.districtId,
  }
}
