import { alpha, createTheme, PaletteColorOptions, Theme } from '@mui/material/styles'
import { Color, colors, PaletteMode } from '@mui/material'
import { generateAdaptiveTypography } from '@app/helpers/themeAdaptive'

export const focusStyle = (theme: Theme, borderWidth = 1, outlineWidth = 3) => ({
  colors: {
    border: theme.palette.cyan.A700,
    outline: alpha(theme.palette.cyan.A700, 0.2),
  },
  border: {
    border: `${borderWidth}px solid ${theme.palette.cyan.A700}`,
    outline: `${outlineWidth}px solid ${alpha(theme.palette.cyan.A700, 0.2)}`,
  },
  shadow: {
    outline: 'none',
    boxShadow: `
      0px 0px 0px ${borderWidth}px ${theme.palette.cyan.A700},
      0px 0px 0px ${outlineWidth}px ${alpha(theme.palette.cyan.A700, 0.2)}
    `,
  },
})

export const getTheme = (mode: PaletteMode) => {
  const theme = createTheme({
    palette: {
      mode,
      ...(mode === 'light' && {
        primary: {
          main: '#0F7EA6',
        },
        secondary: {
          main: colors.teal[500],
        },
        error: {
          main: colors.red['600'],
        },
        success: {
          main: colors.lightGreen['900'],
        },
        warning: {
          main: colors.orange['900'],
        },
        info: {
          main: colors.lightBlue['600'],
        },
        blue: {
          50: '#E0F7FA',
          100: '#b8e3ee',
          200: '#8fcfe2',
          300: '#67bbd6',
          400: '#3ea7ca',
          500: '#1693BE',
          600: '#107ba0',
          700: '#0b6483',
          800: '#064d65',
          900: '#003547',
          A100: '#80d8ff',
          A200: '#40c4ff',
          A400: '#00b0ff',
          A700: '#009EFF',
        },
        teal: {
          50: '#E0F2F1',
          100: '#b3dcd9',
          200: '#86C6C1',
          300: '#5ab1a8',
          400: '#2d9b90',
          500: '#008578',
          600: '#007165',
          700: '#005C51',
          800: '#00483e',
          900: '#00332A',
          A100: '#A7FFEB',
          A200: '#64FFDA',
          A400: '#1DE9B6',
          A700: '#00BFA5',
          contrastText: '#000',
        },
        indigo: {
          50: '#e3e8fc',
          100: '#c7ccf0',
          200: '#abb1e3',
          300: '#9095d7',
          400: '#747aca',
          500: '#585EBE',
          600: '#4b50ab',
          700: '#3f4298',
          800: '#323385',
          900: '#252572',
          A100: '#8C9EFF',
          A200: '#536DFE',
          A400: '#3D5AFE',
          A700: '#154bf4',
          contrastText: '#000',
        },
        red: {
          50: '#ffe6e6',
          100: '#f4c4c4',
          200: '#e9a2a2',
          300: '#de8080',
          400: '#d35e5e',
          500: '#C83C3C',
          600: '#AF3333',
          700: '#962B2B',
          800: '#7C2222',
          900: '#631919',
          A100: '#FF8A80',
          A200: '#FF5252',
          A400: '#FF1744',
          A700: '#D50000',
          contrastText: '#000',
        },
        orange: {
          50: '#FFF3E0',
          100: '#f8dbb8',
          200: '#F1C391',
          300: '#e9aa69',
          400: '#e29242',
          500: '#db7a1a',
          600: '#be6b19',
          700: '#A25B17',
          800: '#854c16',
          900: '#683c15',
          950: '#7D4704',
          A100: '#FFD180',
          A200: '#FFAB40',
          A400: '#FF9100',
          A700: '#FF6D00',
          contrastText: '#000',
        },
        grey: { ...colors.blueGrey, main: colors.blueGrey[600] } as PaletteColorOptions & Partial<Color>,
        green: {
          50: '#e6fce8',
          100: '#bce7c1',
          200: '#93D39A',
          300: '#69be72',
          400: '#3fa94b',
          500: '#379442',
          600: '#2f7f38',
          700: '#28692f',
          800: '#205425',
          900: '#133417',
          A100: '#b9f6ca',
          A200: '#69f0ae',
          A400: '#00e676',
          A700: '#00c853',
        },
        purple: {
          50: '#FFE9FD',
          100: '#ECC6E9',
          200: '#D8A4D4',
          300: '#C581C0',
          400: '#B15FAB',
          500: '#9E3C97',
          600: '#8D3386',
          700: '#7B2A75',
          800: '#6A2264',
          900: '#471042',
          A100: '#EA80FC',
          A200: '#E150FF',
          A400: '#BE03E5',
          A700: '#A11692',
        },
        amber: {
          50: '#f9f0e1',
          100: '#f7e2bb',
          200: '#f5d495',
          300: '#F4C765',
          400: '#f1b948',
          500: '#efab22',
          600: '#cb9221',
          700: '#A6781F',
          800: '#825e1e',
          900: '#594321',
          A100: '#ffe57f',
          A200: '#ffd740',
          A400: '#ffc400',
          A700: '#FFAB00',
        },
        lime: {
          50: '#F8F9E4',
          100: '#EAEEB9',
          200: '#DBE38D',
          300: '#CDD762',
          400: '#BECC36',
          500: '#A7B22F',
          600: '#909929',
          700: '#797F22',
          800: '#62661C',
          900: '#42411A',
          A100: '#F4FF81',
          A200: '#DDFF41',
          A400: '#C6FF00',
          A700: '#AEEA00',
        },
        pink: {
          50: '#ffe9ea',
          100: '#f8ccd1',
          200: '#F1AEB8',
          300: '#e9919e',
          400: '#E27385',
          500: '#DB566C',
          600: '#BE475D',
          700: '#A2394D',
          800: '#852A3E',
          900: '#681B2E',
          A100: '#FF80AB',
          A200: '#FF4081',
          A400: '#F50057',
          A700: '#CE0054',
        },
        deepPurple: {
          50: '#f2e6fc',
          100: '#dac6ee',
          200: '#c2a6df',
          300: '#aa86d1',
          400: '#9266c2',
          500: '#7A46B4',
          600: '#6C3C9E',
          700: '#5E3288',
          800: '#4f2872',
          900: '#411e5c',
          A100: '#b388ff',
          A200: '#7c4dff',
          A400: '#651fff',
          A700: '#4f1fe0',
        },
        cyan: {
          50: '#dcf5f9',
          100: '#bbe2e8',
          200: '#99ced8',
          300: '#78bbc7',
          400: '#56a7b7',
          500: '#3594A6',
          600: '#2C7C8A',
          700: '#23646E',
          800: '#1a4c51',
          900: '#113435',
          A100: '#84ffff',
          A200: '#18FFFF',
          A400: '#00e5ff',
          A700: '#00b8d4',
        },
        yellow: {
          50: '#F7F1DA',
          100: '#F0E8B5',
          200: '#EADF90',
          300: '#E3D66B',
          400: '#DDCD46',
          500: '#D6C421',
          600: '#B5A51F',
          700: '#95861C',
          800: '#75661A',
          900: '#544718',
          A100: '#FFFF8D',
          A200: '#FFF447',
          A400: '#FFEA00',
          A700: '#FFD600',
        },
        deepOrange: {
          50: '#f9e2d7',
          100: '#f2c6b3',
          200: '#eca990',
          300: '#e58d6c',
          400: '#df7049',
          500: '#d85425',
          600: '#ba4922',
          700: '#9b3e1f',
          800: '#7d321b',
          900: '#5E2718',
          A100: '#FF9E80',
          A200: '#FF6E40',
          A400: '#FF3D00',
          A700: '#DD2C00',
        },
        brown: {
          50: '#f9e5e1',
          100: '#e5c9c3',
          200: '#D1ADA5',
          300: '#bc9288',
          400: '#a8766a',
          500: '#945a4c',
          600: '#804e42',
          700: '#6b4238',
          800: '#57362e',
          900: '#422a24',
          A100: '#d7ccc8',
          A200: '#b29d96',
          A400: '#8d6e63',
          A700: '#5d4037',
        },
        additionalColors: {
          bg_500: '#5E7A88',
          hl_yellow: '#ff0',
        },
      }),
    },
    spacing: 4,
    shape: {
      borderRadius: 2,
    },
    typography: {
      fontFamily: '"Effra", sans-serif',
      allVariants: {
        color: colors.blueGrey[800],
      },
      h1: {
        fontSize: '1.5rem', // 24px
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '1.813rem', // 29px
        marginBottom: '20px',
      },
      h2: {
        fontSize: '1.313rem', // 21px
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '1.625rem', // 26px
        marginBottom: '16px',
      },
      h3: {
        fontSize: '1.25rem', // 20px
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '1.375rem', // 22px
      },
      h4: {
        fontSize: '0.938rem', // 15px
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '1.25rem', // 20px
      },
      h5: {
        fontSize: '0.875rem', // 14px
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '1rem', // 16px
      },
      h6: {
        fontSize: '0.813rem', // 13px
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '1.063rem', // 17px
      },
      body1: {
        fontSize: '1rem', // 16px
        letterSpacing: 0,
        lineHeight: '1.25rem', // 20px
      },
      body2: {
        fontSize: '0.875rem', // 14px
        letterSpacing: 0,
        lineHeight: '1.125rem', // 18px
      },
      subtitle1: {
        fontSize: '0.75rem', // 12px
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '0.875rem', // 14px
      },
      subtitle2: {
        fontSize: '0.688rem', // 11px
        letterSpacing: 0,
        fontWeight: 'normal',
        lineHeight: '0.875rem', // 14px
      },
      caption: {
        fontSize: '0.75rem', // 12px
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '0.875rem', // 14px
      },
      button: {
        fontWeight: 500,
      },
      variation_subtitle1: {
        fontSize: '1.125rem', // 18px
        lineHeight: '1.5rem', // 24px
      },
    },
    breakpoints: {
      values: {
        mobile: 600,
        tablet: 960,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            // Disable text-transform for all buttons.
            textTransform: 'none',
            borderRadius: '3px',

            '&:focus': {
              ...focusStyle(theme)[ownerState.variant === 'outlined' ? 'border' : 'shadow'],
            },
          }),
          iconSizeSmall: {
            fontSize: '0.875rem',
            lineHeight: '1rem',
          },
          iconSizeMedium: {
            fontSize: '1rem',
            lineHeight: '1.063rem',
          },
        },
        variants: [
          {
            props: { size: 'xsmall' },
            style: () => ({
              fontSize: '0.75rem',
              lineHeight: '0.875rem',
              padding: theme.spacing(1.5, 3),
            }),
          },
          {
            props: { size: 'small' },
            style: ({ theme }) => ({
              fontSize: '0.875rem',
              lineHeight: '1.188rem',
              padding: theme.spacing(2, 4),
            }),
          },
          {
            props: { size: 'medium' },
            style: ({ theme }) => ({
              fontSize: '1rem',
              lineHeight: '1.063rem',
              padding: theme.spacing(3, 5),
            }),
          },
          {
            props: { color: 'grey', variant: 'contained' },
            style: ({ theme }) => ({
              color: theme.palette.common.white,
              backgroundColor: theme.palette.grey[500],

              '&:hover': {
                backgroundColor: theme.palette.grey[700],
              },
              '&:disabled': {
                backgroundColor: theme.palette.grey[200],
              },
            }),
          },
        ],
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            '&:focus': {
              ...focusStyle(theme).shadow,
            },
          }),
        },
      },
    },
  })

  generateAdaptiveTypography(theme)

  return theme
}

interface IAdditionalColors {
  bg_500: string
  hl_yellow: string
}

declare module '@mui/material/styles' {
  interface Palette {
    blue: Partial<Color>
    green: Partial<Color>
    teal: Partial<Color>
    indigo: Partial<Color>
    red: Partial<Color>
    orange: Partial<Color>
    purple: Partial<Color>
    amber: Partial<Color>
    lime: Partial<Color>
    pink: Partial<Color>
    deepPurple: Partial<Color>
    cyan: Partial<Color>
    yellow: Partial<Color>
    deepOrange: Partial<Color>
    brown: Partial<Color>
    additionalColors: Partial<IAdditionalColors>
  }

  interface BreakpointOverrides {
    xs: false
    sm: false
    md: false
    lg: false
    xl: false
    mobile: true
    tablet: true
    laptop: false
    desktop: false
  }

  interface TypographyVariants {
    variation_subtitle1: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    variation_subtitle1?: React.CSSProperties
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true
    blue: true
    green: true
    teal: true
    indigo: true
    red: true
    orange: true
    cyan: true
    additionalColors: true
  }

  interface ButtonPropsSizeOverrides {
    xsmall: true
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    variation_subtitle1: true
  }
}
