import {
  DATA_CLICK_AWAY_ID,
  DATA_CLICK_AWAY_PROPS,
  DomNodes,
  INITIAL_DROP_ATTRIBUTES,
  SORTABLE_DROP_ATTRIBUTES,
} from '@app/constants'
import { DndContext } from '@dnd-kit/core'
import { rectSortingStrategy, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Box, styled } from '@mui/material'
import { FC, useCallback, useMemo, useState } from 'react'
import {
  generateAnnouncement,
  generateHumanReadableIds,
  useFindNodes,
  useOutsideClick,
  useTestPartFinishedCheck,
} from '@app/helpers'
import { BlockDropContainerComponent } from '@app/components/ui/containers/BlockDropContainer'
import {
  BasicDragContainerComponent,
  DragContainerComponent,
  DragOverlayContainerComponent,
} from '@app/components/ui/containers'
import { SortableContainerComponent, SortingButtons, TransferButton } from './components'
import {
  ContainersId,
  ManualSortEnum,
  ManualSortType,
  MoveChoiceEnum,
  MoveChoiceType,
  OrientationEnum,
  OrientationType,
} from './types'
import { useContainersStateControl, useDragDropChoiceControl } from './hooks'
import { getChoiceIndex, getListChoicesIds } from './utils'
import { scrollBarMixin } from '@app/constants'

interface OrderInteractionProps {
  itemId: string
  responseidentifier: string
  shuffle?: string
  minchoices?: string
  maxchoices?: string
  orientation?: OrientationType
  children: JSX.Element
  accessibilityAttr?: { [key: string]: string }
}

export const OrderInteractionComponent: FC<OrderInteractionProps> = props => {
  const {
    responseidentifier: responseIdentifier,
    shuffle,
    orientation,
    minchoices: minChoices,
    maxchoices: maxChoices,
    children,
    accessibilityAttr,
  } = props

  const [[prompt], simpleChoices] = useFindNodes(children, [DomNodes.prompt, DomNodes.simpleChoice])

  const [initialChoices, sortableChoices, setSortableChoices, moveChoice] = useContainersStateControl(
    responseIdentifier,
    simpleChoices,
    shuffle,
    Number(minChoices),
    Number(maxChoices),
  )
  const [
    activeId,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    sortingChoices,
    sensors,
    customCollisionDetectionAlgorithm,
    handleDragMove,
    handleCancelDrag,
  ] = useDragDropChoiceControl(initialChoices, sortableChoices, setSortableChoices, moveChoice)

  const [selectedChoiceId, setSelectedChoiceId] = useState<string | null>()

  const isFinished = useTestPartFinishedCheck()

  const selectItem = (id: string) => () => setSelectedChoiceId(state => (state === id ? null : id))

  const manualSort = (direction: ManualSortType) => {
    if (isFinished || !sortableChoices) return

    const dragIndex = getChoiceIndex(getListChoicesIds(sortableChoices), selectedChoiceId)
    return () => {
      const dropIndex = dragIndex - direction
      if (dropIndex < 0 || dropIndex > sortableChoices.length - 1) {
        return
      }
      const dropId = sortableChoices[dragIndex - direction].id
      sortingChoices(selectedChoiceId, dropId)
    }
  }

  const transferChoiceDirection: MoveChoiceType = useMemo(() => {
    if (!initialChoices || !sortableChoices) return
    const initialChoiceIds = getListChoicesIds(initialChoices)
    const sortableChoiceIds = getListChoicesIds(sortableChoices)

    return (
      (initialChoiceIds.includes(selectedChoiceId) && MoveChoiceEnum.fromInit) ||
      (sortableChoiceIds.includes(selectedChoiceId) && MoveChoiceEnum.toInit)
    )
  }, [initialChoices, sortableChoices, selectedChoiceId])

  const clickTransferButtonHandler = useCallback(() => {
    moveChoice(transferChoiceDirection)(selectedChoiceId)
  }, [transferChoiceDirection, selectedChoiceId, moveChoice])

  const handleRemove = (id: string) => () => {
    moveChoice(MoveChoiceEnum.toInit)(id)
    setSelectedChoiceId(null)
  }

  const announcements = generateAnnouncement(generateHumanReadableIds(simpleChoices), false, false, [
    [ContainersId.INITIAL_DROP, 'Initial container'],
    [ContainersId.SORTABLE_DROP, 'Sortable container'],
  ])

  const clickOutsideCallback = useCallback(() => setSelectedChoiceId(null), [])

  useOutsideClick(`[${DATA_CLICK_AWAY_ID}]`, clickOutsideCallback)

  return (
    <OrderInteractionWrapper orientation={orientation} {...accessibilityAttr}>
      {prompt}
      <DndContext
        sensors={sensors}
        onDragMove={handleDragMove}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragCancel={handleCancelDrag}
        collisionDetection={customCollisionDetectionAlgorithm}
        accessibility={{ announcements }}
      >
        <BlockDropContainerComponent
          id={ContainersId.INITIAL_DROP}
          orientation={orientation}
          {...INITIAL_DROP_ATTRIBUTES}
        >
          {initialChoices &&
            initialChoices.map(choice => {
              const id = choice?.id
              return (
                <DragContainerComponent
                  key={id}
                  id={id}
                  {...DATA_CLICK_AWAY_PROPS}
                  drag={activeId === id}
                  placehold={activeId === id}
                  selected={selectedChoiceId === id}
                  onClick={selectItem(id)}
                  disabled={isFinished}
                >
                  {choice?.element.props.children}
                </DragContainerComponent>
              )
            })}
        </BlockDropContainerComponent>

        <TransferButton
          disabled={!!!selectedChoiceId}
          orientation={orientation}
          direction={transferChoiceDirection}
          clickHandler={clickTransferButtonHandler}
        />

        <BlockDropContainerComponent
          id={ContainersId.SORTABLE_DROP}
          orientation={orientation}
          {...SORTABLE_DROP_ATTRIBUTES}
        >
          {sortableChoices && (
            <SortableContext
              items={sortableChoices}
              strategy={
                orientation === OrientationEnum.vertical ? verticalListSortingStrategy : rectSortingStrategy
              }
            >
              {sortableChoices.map((choice, i) => {
                const id = choice?.id
                return (
                  <SortableContainerComponent
                    key={id}
                    id={id}
                    {...DATA_CLICK_AWAY_PROPS}
                    selected={selectedChoiceId === id}
                    drag={activeId === id}
                    onClick={selectItem(id)}
                    handleRemove={handleRemove(id)}
                    index={orientation === OrientationEnum.vertical ? i + 1 : null}
                    disabled={isFinished}
                  >
                    {choice?.element.props.children}
                  </SortableContainerComponent>
                )
              })}
            </SortableContext>
          )}
        </BlockDropContainerComponent>

        <SortingButtons
          disabled={
            !sortableChoices?.some(choice => choice.id === selectedChoiceId) || sortableChoices.length < 2
          }
          sortableChoices={sortableChoices}
          selectedChoiceId={selectedChoiceId}
          orientation={orientation}
          clickHandlerUp={manualSort(ManualSortEnum.up)}
          clickHandlerDown={manualSort(ManualSortEnum.down)}
        />

        <DragOverlayContainerComponent dropAnimation={null}>
          {activeId && (
            <BasicDragContainerComponent placehold>
              {simpleChoices.find(choice => choice.props.identifier === activeId).props.children}
            </BasicDragContainerComponent>
          )}
        </DragOverlayContainerComponent>
      </DndContext>
    </OrderInteractionWrapper>
  )
}

interface OrderInteractionWrapperProps {
  orientation: OrientationType
}

const OrderInteractionWrapper = styled(Box)<OrderInteractionWrapperProps>(
  ({ theme, orientation }) => `
  display: flex;
  flex-direction: ${orientation === OrientationEnum.vertical ? 'row' : 'column'};
  margin: ${theme.spacing(4, 0, 2)};
  padding-bottom: ${theme.spacing(3.5)};
  max-width: 570px;
  width: 100%;
  ${
    orientation === OrientationEnum.vertical
      ? `
    overflow-x: auto;
    ${scrollBarMixin(theme, 'horizontal')};
  `
      : ''
  }
`,
)
