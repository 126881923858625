import { FC, useEffect, useRef, useState } from 'react'
import { useDroppable } from '@dnd-kit/core'
import { alpha, styled } from '@mui/material'
import { DropTarget } from '@app/components/ui/dragDrop'
import { Portal } from '@app/components/ui/containers/PortalContainer'
import { AssociableHotspot, AssociableHotspotProps } from '../AssociableHotspot'
import { FIELD_STATE_ATTRIBUTES } from '@app/constants'

const getPosition = (element: SVGGraphicsElement): DOMRect => {
  if (!element) return new DOMRect(0, 0, 0, 0)

  const { width, height } = element.getBoundingClientRect()
  const { x, y } = element.getBBox()
  const point = new DOMPoint(x, y)
  const position = point.matrixTransform(element.getCTM())

  return new DOMRect(position.x, position.y, width, height)
}

export interface DroppableHotspotProps extends AssociableHotspotProps {
  id: string
  wrapperProps?: Partial<React.HTMLAttributes<HTMLDivElement>>
  portalContainer?: Element
}

export const DroppableHotspot: FC<DroppableHotspotProps> = props => {
  const { id, children, disabled, selected, className, wrapperProps, portalContainer, ...hotspotProps } =
    props

  const { setNodeRef, isOver } = useDroppable({ id, disabled })

  const hotspotRef = useRef<SVGGraphicsElement>()
  const [boxPosition, setPosition] = useState(new DOMRect(0, 0, 0, 0))

  useEffect(() => {
    const updatePosition = () => {
      setPosition(getPosition(hotspotRef.current))
    }

    if (portalContainer && hotspotRef.current) {
      window.addEventListener('resize', updatePosition)
      updatePosition()
    }

    return () => window.removeEventListener('resize', updatePosition)
  }, [portalContainer])

  return (
    <DroppableHotspotWrapper className={className} {...wrapperProps} {...FIELD_STATE_ATTRIBUTES}>
      <DropTarget
        ref={setNodeRef}
        key={id}
        style={{
          position: 'absolute',
          width: boxPosition.width >= 26 ? boxPosition.width : 26,
          height: boxPosition.height >= 26 ? boxPosition.height : 26,
          left: boxPosition.x,
          top: boxPosition.y,
        }}
      >
        {portalContainer && (
          <Portal container={portalContainer}>
            <StyledDroppableHotspot
              ref={hotspotRef}
              isOver={isOver}
              disabled={disabled}
              selected={selected}
              focusable={false}
              {...hotspotProps}
            />
          </Portal>
        )}
        {children}
      </DropTarget>
    </DroppableHotspotWrapper>
  )
}

export const StyledDroppableHotspot = styled(AssociableHotspot)(
  ({ theme, selected, disabled, isOver }) => `
  stroke: ${theme.palette.additionalColors.bg_500};
  stroke-linejoin: round;
  stroke-dasharray: 8px 2px;
  stroke-width: 3px;
  fill: ${alpha(theme.palette.grey[300], 0.5)};

  ${
    disabled
      ? `
    stroke: ${theme.palette.grey[100]};
    fill: transparent;
  `
      : ''
  }

  ${
    selected
      ? `
    stroke: ${theme.palette.grey[100]};
    fill: ${theme.palette.blue[600]};
  `
      : ''
  }

  ${
    isOver
      ? `
    fill: ${alpha(theme.palette.blue[100], 0.5)};
  `
      : ''
  }
`,
)

const DroppableHotspotWrapper = styled('div')(() => ({
  cursor: 'pointer',
}))
