import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { signOut, signInWithCustomToken, User as FBUser } from 'firebase/auth'

import { auth } from '../../firebase'
import { LocalStorageService } from '@app/services'
import { ROUTES, StorageKeys } from '@app/constants'
import { setGroupMode, setUser } from '@app/storage'
import { User } from '@app/types'


type State = {
  validating: boolean
  error: string | null
  redirectUrl: string
}

const initialState: State = {
  validating: false,
  error: null,
  redirectUrl: '',
}

export const useCustomTokenAuth = () => {
  const [state, setState] = useState(initialState)
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const from = location.state?.from

  useEffect(() => {
    const validateAuthentication = async () => {
      const id = searchParams.get('id')
      const userId = searchParams.get('userId')
      const schoolYear = searchParams.get('schoolYear')
      const attempt = searchParams.get('attempt')
      const classId = searchParams.get('classId')
      const schoolId = searchParams.get('schoolId')
      const districtId = searchParams.get('districtId')

      let customToken =
        searchParams.get('customToken') ?? LocalStorageService.getValue(StorageKeys.CustomToken)

      if (customToken) {
        LocalStorageService.setValue(StorageKeys.CustomToken, customToken)
      }

      const callbackUrl = searchParams.get('callbackUrl')

      if (callbackUrl) {
        LocalStorageService.setValue(StorageKeys.CallbackUrl, callbackUrl)
      }

      try {
        if (customToken && id && schoolYear && attempt) {
          setState(prevState => ({
            ...prevState,
            validating: true,
          }))

          // If user is already logged in, log them out and reload page.
          if (auth.currentUser) {
            await signOut(auth)
            window.location.reload()
          }

          const authData = await signInWithCustomToken(auth, customToken)

          const user: User = await auth.currentUser.getIdTokenResult().then(token => {
            return {
              id: auth.currentUser.uid,
              role: token.claims?.role ?? 'Student',
              firstName: token.claims?.first_name ?? "",
              lastName: token.claims?.last_name ?? "",
              isTeacher: token.claims?.isTeacher ?? false
            }
          })

          setUser(user)

          const groupMode = user.role !== 'Student' && !!(classId || schoolId || districtId) && !userId
          setGroupMode(groupMode)

          LocalStorageService.setValue(StorageKeys.AuthData, authData)

          searchParams.delete('customToken')
          searchParams.delete('callbackUrl')

          setState(prevState => ({
            ...prevState,
            validating: false,
            redirectUrl: `${from ?? '/test'}?${searchParams.toString()}`,
          }))
        } else {
          setState(prevState => ({
            ...prevState,
            validating: false,
            error: 'Something went wrong...',
          }))
        }
      } catch (error: any) {
        console.log('[CustomLogin.validateAuthentication]', error)
        setState(prevState => ({
          ...prevState,
          validating: false,
          redirectUrl: ROUTES.validationFailed,
        }))
      }
    }

    validateAuthentication()
  }, [searchParams, from])

  return state
}

const getLoginState = (prevUser: FBUser, user: FBUser): boolean => {
  if (!user) return false
  if (prevUser && user.uid !== prevUser.uid) return false
  return true
}

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<FBUser>(auth.currentUser)
  const [loggedIn, setLoggedIn] = useState<boolean>(!!auth.currentUser)

  useEffect(() => {
    setCurrentUser(auth.currentUser)
    setLoggedIn(!!auth.currentUser)

    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(prevUser => {
        // If user already exists and got changed, there probably was a login in different tab.
        // We need to reset previous login state.
        setLoggedIn(getLoginState(prevUser, user))
        return user
      })
    })

    return unsubscribe
  }, [])

  return { currentUser, loggedIn }
}
