import { styled, typographyClasses } from '@mui/material'
import { FC, useEffect, useRef } from 'react'
import { HIGHLIGHT_CONTAINER_PASSAGE, scrollBarMixinObj } from '@app/constants'
import { useSnapshot } from 'valtio/esm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHighlighterLine } from '@fortawesome/pro-solid-svg-icons/faHighlighterLine'
import { faArrowLeftRotate } from '@fortawesome/pro-solid-svg-icons/faArrowLeftRotate'
import { CurrentTestState } from '@app/storage'
import { useScopedHighlighter } from '@app/helpers'
import CustomContextMenu from '@app/components/common/CustomContextMenu'
import { focusStyle } from '@app/theme'

interface InfoControlProps {
  title?: string
  children?: JSX.Element
  accessibilityAttr?: { [key: string]: string }
}

export const InfoControl: FC<InfoControlProps> = ({ title, children, accessibilityAttr }) => {
  const { currentItemResponse } = useSnapshot(CurrentTestState)
  const infoControlRef = useRef(null)

  useEffect(() => {
    if (infoControlRef?.current) {
      infoControlRef.current.scrollTo(0, 0)
    }
  }, [currentItemResponse?.id])

  useEffect(() => {
    const firstHightLightedText =
      document.getElementsByClassName('hl')[0] || document.getElementsByClassName('hl-u')[0]
    if (firstHightLightedText) {
      firstHightLightedText.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
    }
  }, [children])

  const { hasHighlights, onHighlightSelection, onResetHighlight, onRemoveHighlight, isSelectionHighlighted } =
    useScopedHighlighter('p', currentItemResponse.passageId)

  const getContextMenuOptions = (_event: React.MouseEvent<Element, MouseEvent>, selectedText: string) => {
    const isHighlightedElement = isSelectionHighlighted()

    const options = [
      ...(selectedText
        ? [
            {
              label: `${isHighlightedElement ? 'Unhighlight' : 'Highlight'} Selection`,
              icon: <FontAwesomeIcon icon={faHighlighterLine} />,
              action: isHighlightedElement ? onRemoveHighlight : onHighlightSelection,
            },
          ]
        : []),
    ]

    if (hasHighlights) {
      options.push({
        label: 'Reset Highlight',
        icon: <FontAwesomeIcon icon={faArrowLeftRotate} />,
        action: onResetHighlight,
      })
    }

    return options
  }

  return (
    <InfoControlContainer {...accessibilityAttr}>
      <InfoControlContent
        ref={infoControlRef}
        id={HIGHLIGHT_CONTAINER_PASSAGE}
        getContextMenuOptions={getContextMenuOptions}
        tabIndex={0}
      >
        {children}
      </InfoControlContent>
    </InfoControlContainer>
  )
}

const InfoControlContainer = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
}))

const InfoControlContent = styled(CustomContextMenu)(({ theme }) => ({
  padding: theme.spacing(6, 4),
  margin: theme.spacing(2),
  fontSize: theme.typography.body2.fontSize,
  lineHeight: theme.typography.body2.lineHeight,
  color: theme.palette.grey[800],
  wordWrap: 'break-word',
  flexGrow: 1,
  overflow: 'auto',

  [`& p.${typographyClasses.root}`]: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('mobile')]: {
      marginBottom: theme.spacing(3),
    },
  },

  ...scrollBarMixinObj(theme),

  [theme.breakpoints.down('mobile')]: {
    padding: theme.spacing(4, 2),
  },

  [theme.breakpoints.up('tablet')]: {
    '&:focus': {
      ...focusStyle(theme).shadow,
    },
  },
}))
