import React from 'react'
import { REVIEW_BADGE_ORIGIN, ReviewBadge, ReviewMarkBadge } from '../../badges'

type Props = React.PropsWithChildren<{
  correct?: boolean
  markedForReview?: boolean
  groupMode?: boolean
}>

const NavigationBubbleBadge: React.FC<Props> = ({
  groupMode = false,
  correct,
  markedForReview,
  children,
}) => {
  if (groupMode) {
    return <>{children}</>
  }

  if (correct !== undefined) {
    return (
      <ReviewBadge
        correct={correct}
        overlap='circular'
        anchorOrigin={REVIEW_BADGE_ORIGIN.bottomRight}
      >
        {children}
      </ReviewBadge>
    )
  }

  if (markedForReview) {
    return (
      <ReviewMarkBadge>
        {children}
      </ReviewMarkBadge>
    )
  }

  return (
    <>{children}</>
  )
}

export default NavigationBubbleBadge
