import React, { useCallback, useRef } from 'react'
import { faGripHorizontal } from '@fortawesome/pro-solid-svg-icons/faGripHorizontal'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import {
  ArrowIconButton,
  GripHorizontalIcon,
  GripIconButton,
  GripButtonWrapper,
  ItemList,
  NavigationWrapper,
  QuestionsCarouselWrapper,
  QuestionsListWrapper,
  QuestionTitleContainer,
  ScrollIcon,
  TypographyTitle,
} from '@app/components'
import {
  PAGINATION,
  SHOW_QUESTIONS_BTN_ATTRIBUTES,
  SCROLL_RIGHT_BTN_ATTRIBUTES,
  SCROLL_LEFT_BTN_ATTRIBUTES,
  NAVIGATION_DESCRIBEDBY_ID,
} from '@app/constants'

type Props = {
  openQuestionsModal?: boolean
  onShowQuestionsModal: () => void
  openSubmitModal?: boolean
  onSelect: (id: string) => () => void
  isLinearMode?: boolean
  itemsScoring?: boolean[]
  modalButtonRef: React.MutableRefObject<HTMLButtonElement>
}

export const TestReviewNavigator: React.FC<Props> = ({
  openQuestionsModal = false,
  onShowQuestionsModal,
  openSubmitModal = false,
  onSelect,
  isLinearMode = false,
  itemsScoring,
  modalButtonRef,
}) => {
  const questionsListRef = useRef(null)

  const handleArrowClick = (forward: boolean) => {
    const { offsetWidth } = questionsListRef.current

    if (forward) {
      questionsListRef.current.scrollLeft += offsetWidth
    } else {
      questionsListRef.current.scrollLeft -= offsetWidth
    }
  }

  const scrollToViewport = useCallback((el: HTMLElement, currIndex: number) => {
    const elementRect = el.parentElement.getBoundingClientRect()
    questionsListRef.current.scrollLeft = currIndex * elementRect.width
  }, [])

  return (
    <QuestionsCarouselWrapper aria-label='Questions navigation' role='group'>
      <div id={NAVIGATION_DESCRIBEDBY_ID} style={{ display: 'none' }}>
        Use the right and left arrows to move between questions in navigation
      </div>
      <QuestionTitleContainer>
        <TypographyTitle variant='h3' component='p'>
          Questions
        </TypographyTitle>
      </QuestionTitleContainer>
      <NavigationWrapper role='navigation'>
        <ArrowIconButton
          aria-label='Scroll Left'
          role='button'
          onClick={() => handleArrowClick(false)}
          {...SCROLL_LEFT_BTN_ATTRIBUTES}
        >
          <ScrollIcon icon={faChevronLeft} />
        </ArrowIconButton>
        <QuestionsListWrapper ref={questionsListRef} data-id={PAGINATION}>
          <ItemList
            updateScrollPosition={scrollToViewport}
            onClick={onSelect}
            openSubmitModal={openSubmitModal}
            isLinearMode={isLinearMode}
            itemsScoring={itemsScoring}
          />
        </QuestionsListWrapper>
        <ArrowIconButton
          aria-label='Scroll Right'
          role='button'
          onClick={() => handleArrowClick(true)}
          {...SCROLL_RIGHT_BTN_ATTRIBUTES}
        >
          <ScrollIcon icon={faChevronRight} />
        </ArrowIconButton>
      </NavigationWrapper>
      <GripButtonWrapper>
        <GripIconButton
          ref={modalButtonRef}
          onClick={onShowQuestionsModal}
          opened={openQuestionsModal}
          {...SHOW_QUESTIONS_BTN_ATTRIBUTES}
          aria-label='All Questions'
          role='button'
        >
          <GripHorizontalIcon icon={faGripHorizontal} />
        </GripIconButton>
      </GripButtonWrapper>
    </QuestionsCarouselWrapper>
  )
}
