import { CurrentTestState } from '@app/storage'
import { Box, styled } from '@mui/material'
import { FC } from 'react'
import PassageHeader from './components/PassageHeader'

interface InfoControlLayoutProps {
  children?: JSX.Element | JSX.Element[]
}

const InfoControlLayout: FC<InfoControlLayoutProps> = ({ children }) => {
  const { passages } = CurrentTestState

  return (
    <Container aria-label='Question additional information block'>
      {passages.length ? <PassageHeader /> : null}
      <InfoControlLayoutWrapper>{children}</InfoControlLayoutWrapper>
    </Container>
  )
}

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  maxWidth: '550px',
  flex: '1 1 50%',
  overflow: 'auto',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius * 1.5,
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.common.white,
}))

const InfoControlLayoutWrapper = styled(Box)({
  flex: '1 1 auto',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
})

export default InfoControlLayout
