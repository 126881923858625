import { v4 as uuid } from 'uuid'
import { parseResultsReport, getSelectedItemsKey, isEqualArray } from '@app/helpers'
import {
  AssesmentSectionSelection,
  AssessmentItem,
  AssessmentItemResponse,
  AssessmentSection,
  AssessmentSectionOrdering,
  AssessmentSectionRubricBlock,
  AssessmentTest,
  ResponseDeclaration,
  ResultsReport,
  TestPart,
} from '@app/models'
import { Cardinality, InteractionType, NavigationMode, SubmissionMode } from '@app/types'
import { proxy } from 'valtio'
import { devtools } from 'valtio/utils'

export interface CurrentTestType {
  testPartItems: AssessmentItem[]
  currentTestPart: TestPart
  currentPassage: string
  passages: string[]
  currentTest: AssessmentTest
  itemsResponses: AssessmentItemResponse[]
  currentItemResponse: AssessmentItemResponse
  currentTestPartResponse: string[]
  isMinTimePassed: boolean
  rubricBlock?: AssessmentSectionRubricBlock
  isStarted?: boolean
}

const initialState: CurrentTestType = {
  testPartItems: [],
  currentTest: null,
  currentTestPart: null,
  currentPassage: '',
  passages: [],
  itemsResponses: [],
  currentItemResponse: {
    id: '',
    itemId: '',
    responses: [],
    isResponseValid: true,
    answered: false,
    viewed: false,
    datestamp: null,
    passageId: '',
  },
  currentTestPartResponse: [],
  isMinTimePassed: true,
  rubricBlock: null,
  isStarted: false,
}

export const CurrentTestState = proxy<CurrentTestType>(initialState)

export const resetCurrentTestState = () => {
  CurrentTestState.testPartItems = initialState.testPartItems
  CurrentTestState.currentTest = initialState.currentTest
  CurrentTestState.currentTestPart = initialState.currentTestPart
  CurrentTestState.currentPassage = initialState.currentPassage
  CurrentTestState.passages = initialState.passages
  CurrentTestState.itemsResponses = initialState.itemsResponses
  CurrentTestState.currentItemResponse = initialState.currentItemResponse
  CurrentTestState.isMinTimePassed = initialState.isMinTimePassed
  CurrentTestState.rubricBlock = initialState.rubricBlock
  CurrentTestState.isStarted = initialState.isStarted
}

export const setIsStarted = (status: boolean) => {
  CurrentTestState.isStarted = status
}

export const setCurrentTestPart = (testPart: TestPart) => {
  CurrentTestState.currentTestPart = testPart

  const { currentTest } = CurrentTestState

  // If test part is finished, search for selected items in storage.
  if (CurrentTestState.currentTestPart.isFinished) {
    try {
      const selectedItemsIds: string[] = JSON.parse(
        sessionStorage.getItem(getSelectedItemsKey(currentTest.id)),
      )
      const testPartItems = extractItemsFromSections(
        CurrentTestState.currentTestPart.assessmentSections,
        false,
      )
      // Find corresponding test items for each selected record.
      const selectedItems = selectedItemsIds.map(itemId => {
        const item = testPartItems.find(item => item.id === itemId)
        return item
      })
      setCurrentTestPartItems(selectedItems)
    } catch (error) {
      // No item in storage, show all test part items.
      console.log(error)
      setCurrentTestPartItems(
        extractItemsFromSections(CurrentTestState.currentTestPart.assessmentSections, false),
      )
    }

    return
  }

  // Otherwise, select items and record selection in storage.
  const selectedItems = extractItemsFromSections(CurrentTestState.currentTestPart.assessmentSections)
  sessionStorage.setItem(
    getSelectedItemsKey(currentTest.id),
    JSON.stringify(selectedItems.map(item => item.id)),
  )
  setCurrentTestPartItems(selectedItems)
}

export const setCurrentTest = (test: AssessmentTest) => {
  CurrentTestState.rubricBlock = test?.testPart[0]?.assessmentSections[0]?.rubricBlock
  CurrentTestState.currentTest = test
}

/**
 * Set current passage and move to the first question from the passage if no rules specified.
 * @param { String } passageId id of the passage.
 * @param { Boolean } moveToQuestion if true move to the first item from the passage.
 */
export const setCurrentPassage = (passageId: string, moveToQuestion: boolean = true) => {
  CurrentTestState.currentPassage = passageId
  if (!moveToQuestion) {
    return
  }

  const passageFirstItemResponse = CurrentTestState.itemsResponses.find(res => res.passageId === passageId)
  moveToSelectedItem(passageFirstItemResponse.id)
}

export const setIsMinTimePassed = (value: boolean) => {
  CurrentTestState.isMinTimePassed = value
}

export const setCurrentTestPartItems = (items: AssessmentItem[]) => {
  CurrentTestState.testPartItems = items

  const itemResponsesCopy = [...CurrentTestState.itemsResponses]

  CurrentTestState.itemsResponses = items.map(item => {
    const itemResponseIndex = itemResponsesCopy.findIndex(itemResponse => itemResponse.itemId === item.id)
    const [itemResponse] = itemResponsesCopy.splice(itemResponseIndex, 1)

    // If itemResponse for that question already exists, keep it.
    // Otherwise create empty itemResponse entry.
    return {
      id: itemResponse?.id || uuid(),
      itemId: item.id,
      responses: item.responseDeclaration.map(declaration => {
        const itemResponseDeclaration = itemResponse?.responses.find(
          response => response.responseDeclarationId === declaration.id,
        )

        return {
          responseDeclarationId: declaration.id,
          response: itemResponseDeclaration ? itemResponseDeclaration.response : [],
          isResponseValid: itemResponseDeclaration ? itemResponseDeclaration.isResponseValid : true,
          cardinality: itemResponseDeclaration ? itemResponseDeclaration.cardinality : null,
          interactionType: itemResponseDeclaration ? itemResponseDeclaration.interactionType : null,
        }
      }),
      isResponseValid: itemResponse ? itemResponse.isResponseValid : true,
      answered: itemResponse?.answered || false,
      viewed: itemResponse?.viewed || false,
      datestamp: itemResponse?.datestamp || null,
      passageId: item.passageId || null,
    }
  })

  if (!CurrentTestState.currentItemResponse.id) {
    setCurrentItemResponse(CurrentTestState.itemsResponses[0])
  }
}

export const setCurrentItemResponse = (itemResponse: AssessmentItemResponse) => {
  const { itemsResponses } = CurrentTestState

  if (!itemResponse.viewed) {
    const index = getCurrentResponseItemIndexById(itemResponse.id)
    itemsResponses[index].viewed = true
  }

  CurrentTestState.currentItemResponse = itemResponse
}

export const setItemsResponses = (itemResponses: AssessmentItemResponse[]) => {
  CurrentTestState.itemsResponses = itemResponses
}

export const resetItemResponses = () => {
  CurrentTestState.itemsResponses = initialState.itemsResponses
  CurrentTestState.currentItemResponse = initialState.currentItemResponse
}

export const setItemResponseValidity = (id: string, isResponseValid: boolean): void => {
  const { itemsResponses } = CurrentTestState
  const itemResponse = itemsResponses.find(item => item.id === id)

  if (!itemResponse) return

  itemResponse.isResponseValid = isResponseValid
}

export const setItemAnswered = (id: string): void => {
  const { itemsResponses } = CurrentTestState
  const itemResponse = itemsResponses.find(item => item.id === id)

  if (!itemResponse) return
  itemResponse.datestamp = new Date().toISOString()
  itemResponse.answered =
    itemResponse.responses?.every(res => res.response.length > 0) && itemResponse.isResponseValid
}

export const setItemResponse = (
  id: string,
  responseDeclarationId: string,
  response: string[],
  isResponseValid: boolean = true,
  interactionType: InteractionType,
  cardinality: Cardinality,
) => {
  const { itemsResponses } = CurrentTestState
  const itemResponse = itemsResponses.find(item => item.id === id)

  if (!itemResponse) return

  const responseIndex = itemResponse.responses.findIndex(
    item => item.responseDeclarationId === responseDeclarationId,
  )

  if (!itemResponse.responses[responseIndex]) return

  if (isEqualArray(itemResponse.responses[responseIndex].response, response)) return

  itemResponse.responses[responseIndex].response = response
  itemResponse.responses[responseIndex].isResponseValid = isResponseValid
  itemResponse.responses[responseIndex].cardinality = cardinality
  itemResponse.responses[responseIndex].interactionType = interactionType

  setItemResponseValidity(id, !itemResponse.responses.some(res => !res.isResponseValid))
  // This part for future combination individual-nonlinear
  // if (
  //   (currentTestPart.submissionMode === SubmissionMode.individual &&
  //     currentTestPart.navigationMode !== NavigationMode.nonlinear) ||
  //   currentTestPart.submissionMode === SubmissionMode.simultaneous
  // ) {
  setItemAnswered(id)
  //}

  console.log('UPDATED', itemResponse.responses)
}

// method for test result reporting with individual submission mode
export const individualResultReport = (index: number) => {
  const { currentTestPart, currentTestPartResponse } = CurrentTestState

  if (
    currentTestPart.submissionMode === SubmissionMode.individual &&
    currentTestPart.navigationMode === NavigationMode.linear
  ) {
    currentTestPartResponse[index] = parseResultsReport(extractResultsFromTestPart(index))
  }
  console.log('REPORT', CurrentTestState.currentTestPartResponse)
}

export const moveToNextItem = () => {
  const { itemsResponses, currentItemResponse } = CurrentTestState
  const currentItemIndex = getCurrentResponseItemIndexById(currentItemResponse?.id)
  const nextIndex = currentItemIndex + 1

  if (itemsResponses.length > nextIndex) {
    //individualResultReport(currentItemIndex)

    const nextItemResponse = CurrentTestState.itemsResponses[nextIndex]
    setCurrentItemResponse(nextItemResponse)
  }
}

export const moveToPreviousItem = () => {
  const { itemsResponses, currentItemResponse } = CurrentTestState
  const currentItemIndex = getCurrentResponseItemIndexById(currentItemResponse?.id)

  if (currentItemIndex !== 0) {
    //individualResultReport(currentItemIndex)

    const previousIndex = currentItemIndex - 1
    const nextItemResponse = itemsResponses[previousIndex]
    setCurrentItemResponse(nextItemResponse)
  }
}

export const moveToSelectedItem = (id: string) => {
  const { itemsResponses } = CurrentTestState
  const index = getCurrentResponseItemIndexById(id)

  if (index >= 0 && index < itemsResponses.length) {
    //individualResultReport(index)

    const nextItemResponse = itemsResponses[index]
    setCurrentItemResponse(nextItemResponse)
  }
}

export const getCurrentResponseItemIndexById = (id: string) => {
  return CurrentTestState.itemsResponses.findIndex(item => item.id === id)
}

export const finishResultReport = () => {
  //const { currentTestPart, itemsResponses, currentItemResponse } = CurrentTestState

  //if (currentTestPart.submissionMode === SubmissionMode.simultaneous) {
  CurrentTestState.currentTestPartResponse = [parseResultsReport(extractResultsFromTestPart())]
  console.log('REPORT', CurrentTestState.currentTestPartResponse[0])
  //return
  //}

  //const currentItemIndex = itemsResponses.findIndex(item => item.id === currentItemResponse?.id)
  //individualResultReport(currentItemIndex)
}

export const completeTest = () => {
  const { currentTest, currentTestPart } = CurrentTestState

  finishResultReport()

  currentTestPart.isFinished = true
  const testPartIndex = currentTest.testPart.findIndex(part => part.id === currentTestPart.id)
  currentTest.testPart[testPartIndex].isFinished = true
  currentTest.isFinished = true

  setCurrentItemResponse(CurrentTestState.itemsResponses[0])
}

const getItemData = (itemId: string): AssessmentItem => {
  const { testPartItems } = CurrentTestState

  return testPartItems.find(item => item.id === itemId)
}

const getResponseDeclarationData = (itemId: string, responseDeclarationId: string): ResponseDeclaration =>
  getItemData(itemId).responseDeclaration.find(res => res.id === responseDeclarationId)

export const applySelection = (
  items: Array<AssessmentItem | AssessmentSection>,
  selection: AssesmentSectionSelection,
): Array<AssessmentItem | AssessmentSection> => {
  // Using indices here and not items themselves,
  // because initial order should be preserved at this step.
  const requiredIndices = items.reduce<number[]>(
    (arr, item, index) => (item.required ? [...arr, index] : arr),
    [],
  )

  const selectedIndices: number[] = [...requiredIndices]
  const restIndicesCount = selection.select - requiredIndices.length

  for (let i = 0; i < restIndicesCount; i += 1) {
    let randomIndex: number

    do {
      randomIndex = Math.floor(Math.random() * items.length)
    } while (!selection.withReplacement && selectedIndices.includes(randomIndex))

    selectedIndices.push(randomIndex)
  }

  selectedIndices.sort()

  return selectedIndices.map(index => items[index])
}

export const applyOrdering = (
  items: Array<AssessmentItem>,
  ordering: AssessmentSectionOrdering,
  passageId: string = '',
) => {
  if (ordering && ordering?.shuffle) {
    for (let currentIndex = items.length - 1; currentIndex > 0; currentIndex--) {
      const randomIndex: number = Math.floor(Math.random() * (currentIndex + 1))
      ;[items[currentIndex], items[randomIndex]] = [items[randomIndex], items[currentIndex]]
    }
  }

  return passageId
    ? items.map(item => ({
        ...item,
        passageId,
      }))
    : items
}

export const extractItemsFromSections = (
  sections: (AssessmentItem | AssessmentSection)[],
  useSelection: boolean = true,
  passageId: string = '',
): AssessmentItem[] => {
  if (!sections) return []

  if (CurrentTestState.passages.length) {
    CurrentTestState.passages = []
  }

  return sections.reduce<AssessmentItem[]>((items, section) => {
    // If not section, just add to array.
    if (!('items' in section) && !('subsections' in section)) {
      return [...items, section as AssessmentItem]
    }

    if (section.visible && !CurrentTestState.passages.includes(section.id) && section.items?.length) {
      CurrentTestState.passages = [...CurrentTestState.passages, section.id]
      if (!CurrentTestState.currentPassage) CurrentTestState.currentPassage = section.id
    }

    if (section.selection && useSelection) {
      // Subsections always count as 1 item, regardless of how many child elements they have.
      return [
        ...items,
        // After selection there can be both items and sections.
        ...extractItemsFromSections(
          // Need to apply selection rule for each subsection as well.
          applySelection(
            [
              ...(applyOrdering(
                section.items,
                section.ordering,
                passageId || (section.visible ? section.id : ''),
              ) || []),
              ...(section.subsections || []),
            ],
            section.selection,
          ),
          useSelection,
          passageId || (section.visible && section.id),
        ),
      ]
    }

    return [
      ...items,
      ...applyOrdering(section.items, section.ordering, passageId || (section.visible && section.id)),
      ...extractItemsFromSections(section.subsections, useSelection),
    ]
  }, [])
}

const extractResultsFromTestPart = (currentIndex?: number): ResultsReport => {
  const { itemsResponses } = CurrentTestState

  return {
    context: {
      sourcedId: 'id',
      sessionIdentifier: {
        sourceId: 'sourceId',
        identifier: 'id',
      },
    },
    itemResult: (currentIndex !== undefined ? [itemsResponses[currentIndex]] : itemsResponses).map(
      (item, index) => ({
        identifier: item.itemId,
        datestamp: item.datestamp,
        sequenceIndex: (currentIndex || index) + 1,
        responseVariable: item.responses?.map(res => ({
          identifier: res.responseDeclarationId,
          baseType: getResponseDeclarationData(item.itemId, res.responseDeclarationId)?.type,
          cardinality: getResponseDeclarationData(item.itemId, res.responseDeclarationId)?.cardinality,
          candidateResponse: res.response,
        })),
      }),
    ),
  }
}

process.env.NODE_ENV === 'development' && devtools(CurrentTestState, { enabled: true })
