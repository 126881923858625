import { FC } from 'react'
import { FeedbackBlockWrapper, FeedbackBlockTitle } from './styles'

export interface FeedbackBlockProps {
  outcomeidentifier?: string
  identifier?: string
  showhide?: string
  correct?: boolean
  title?: string
  groupMode?: boolean
  className?: string
  children?: React.ReactNode
  accessibilityAttr?: Record<string, string>
}

export const FeedbackBlock: FC<FeedbackBlockProps> = props => {
  const { title, groupMode = false, correct, className, children, accessibilityAttr = {} } = props

  return (
    <FeedbackBlockWrapper
      className={className}
      groupMode={groupMode}
      correct={correct}
      {...accessibilityAttr}
    >
      <FeedbackBlockTitle groupMode={groupMode} correct={correct}>
        {title ?? (correct ? 'Correct' : 'Incorrect')}
      </FeedbackBlockTitle>
      {children}
    </FeedbackBlockWrapper>
  )
}
