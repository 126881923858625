import { useState, useLayoutEffect } from 'react'
import { useSnapshot } from 'valtio'
import { CurrentQtiPackageState } from '@app/storage'
import { AssessmentStylesheet } from '@app/types'
import { AssessmentItem } from '@app/models'

export const useAssessmentItemStyles = (assessmentItem: AssessmentItem) => {
  const { stylesheets } = useSnapshot(CurrentQtiPackageState)

  const [styles, setStyles] = useState('')

  useLayoutEffect(() => {
    if (assessmentItem?.stylesheetRef?.length) {
      const linkedStylesheets: AssessmentStylesheet[] = CurrentQtiPackageState.stylesheets.filter(
        el => el.resourceId === assessmentItem?.resourceId,
      )

      const combinedStyles = assessmentItem?.stylesheetRef.reduce((content, ref) => {
        const stylesheet = linkedStylesheets.find(el => el.path.includes(ref.url))
        return stylesheet ? content.concat(` ${stylesheet.content}`) : content
      }, '')

      setStyles(combinedStyles)
    }
  }, [assessmentItem?.id, stylesheets, assessmentItem?.resourceId, assessmentItem?.stylesheetRef])

  return styles
}
