import { Box, Chip, IconButton, styled } from '@mui/material'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { TransformComponent } from 'react-zoom-pan-pinch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlassPlus } from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlassPlus'
import { faMagnifyingGlassMinus } from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlassMinus'
import { faMaximize } from '@fortawesome/pro-solid-svg-icons/faMaximize'
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark'
import { focusStyle } from '@app/theme'

type ZoomableImageContentProps = {
  imageScale: number
  handleZoomIn: () => void
  handleZoomOut: () => void
  src?: string
  alt?: string
  className?: string
  tabIndex?: number
  isFullscreen?: boolean
  onFullscreenChange?: (status: boolean) => void
  children?: string | JSX.Element | JSX.Element[]
}

export const ZoomableImageContent = forwardRef<any, ZoomableImageContentProps>((props, ref) => {
  const {
    src,
    alt,
    onFullscreenChange,
    isFullscreen,
    children,
    imageScale,
    handleZoomIn,
    handleZoomOut,
    ...imageProps
  } = props

  const [hovered, setHovered] = useState(false)
  const [imageHeight, setImageHeight] = useState(0)
  const wrapperRef = useRef(null)

  useEffect(() => {
    if (imageScale === 2) {
      wrapperRef.current.focus()
    }
  }, [imageScale])

  const handleImageHover = (hoverStatus: boolean) => {
    if (isFullscreen) {
      return
    }

    setHovered(hoverStatus)
  }

  useEffect(() => {
    const height = wrapperRef.current?.querySelector('img')?.height || 0
    setImageHeight(height)
  }, [wrapperRef])

  const imageWidthPercentage = imageProps?.className?.includes('w-')
    ? imageProps?.className
        .split(' ')
        .find(item => item.includes('w-'))
        ?.split('-')[1] || '100'
    : '100'

  return (
    <StyledBox
      ref={wrapperRef}
      onMouseOver={() => handleImageHover(true)}
      onMouseLeave={() => handleImageHover(false)}
      tabIndex={0}
      aria-label='Zoomable image area'
      component={'div'}
      scale={imageScale}
    >
      <ZoomPanel hovered={hovered || isFullscreen} onClick={e => e.preventDefault()}>
        {isFullscreen && (
          <Box flexGrow={1}>
            <CloseModalButton onClick={() => onFullscreenChange(false)} size='large'>
              <StyledIcon icon={faXmark} />
            </CloseModalButton>
          </Box>
        )}
        <IconButton aria-label='Zoom out' disabled={imageScale === 1} onClick={handleZoomOut}>
          <StyledIcon icon={faMagnifyingGlassMinus} />
        </IconButton>
        <Chip label={`${Math.trunc(100 * imageScale)}%`} />
        <IconButton aria-label='Zoom in' onClick={handleZoomIn} disabled={imageScale === 2}>
          <StyledIcon icon={faMagnifyingGlassPlus} />
        </IconButton>
        {!isFullscreen && (
          <IconButton aria-label='use fullscreen' onClick={() => onFullscreenChange(true)}>
            <StyledIcon icon={faMaximize} />
          </IconButton>
        )}
      </ZoomPanel>
      <StyledImageWrapper imgWidth={`${imageWidthPercentage}%`} onDoubleClick={handleZoomIn}>
        <TransformComponent
          wrapperStyle={{ width: '100%', ...(isFullscreen && { height: `max(75vh, ${imageHeight}px)` }) }}
          contentStyle={{ width: '100%', justifyContent: 'center' }}
        >
          {children ? (
            <StyledSVGContainer isFullscreen={isFullscreen}>{children}</StyledSVGContainer>
          ) : (
            <StyledImage isFullscreen={isFullscreen} src={src} alt={alt} ref={ref} {...imageProps} />
          )}
        </TransformComponent>
      </StyledImageWrapper>
    </StyledBox>
  )
})

const StyledImageWrapper = styled(Box)<{ imgWidth?: string }>(({ imgWidth }) => ({
  '& .react-transform-component img.lat': {
    width: `${imgWidth} !important`,
  },
}))

const StyledBox = styled(Box)<{ scale: number }>(({ theme, scale }) => ({
  userSelect: 'none',
  width: '100%',
  ':hover': {
    cursor: scale > 1 ? 'grab' : 'zoom-in',
  },
  ':active': {
    cursor: scale > 1 && 'grabbing',
  },
  ':focus-visible': {
    ...focusStyle(theme).shadow,
  },
  ':focus-within': {
    '*': {
      visibility: 'visible',
    },
  },
}))

const CloseModalButton = styled(IconButton)(({ theme }) => ({
  alignSelf: 'flex-start',
}))

const ZoomPanel = styled(Box)<{ hovered: boolean }>(({ theme, hovered }) => ({
  alignItems: 'center',
  visibility: hovered ? 'visible' : 'hidden',
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginBottom: theme.spacing(0.25),
}))

const StyledImage = styled('img')<{ isFullscreen: boolean }>(({ theme, isFullscreen }) => ({
  height: 'auto',
  width: isFullscreen ? '70vw' : undefined,
  maxHeight: 'calc(100vh - 120px)',
}))

const StyledSVGContainer = styled('div')<{ isFullscreen: boolean }>(({ theme, isFullscreen }) => ({
  width: '100%',
  '& svg': {
    height: 'auto',
    width: isFullscreen ? '70vw' : undefined,
    maxHeight: 'calc(100vh - 120px)',
  },
}))

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.spacing(6),
  width: theme.spacing(6),
  height: theme.spacing(6),
}))
