import { DomNodes } from '@app/constants'
import {
  // getFeedbackItems,
  getInitialCountingVariants,
  useAssessmentItemResponseScoring,
  useInteractionDivide,
  useItemResponse,
} from '@app/helpers'
import { FC, useMemo } from 'react'
import { MatchTableComponent } from '@app/components/ui/tables'
import { useMatchStatesControl } from './hooks'
import { useSnapshot } from 'valtio'
import { CurrentTestState } from '@app/storage'
import { ConstraintsAlertContainer } from '@app/components/ui'

interface MatchInteractionComponentProps {
  itemId: string
  responseidentifier: string
  shuffle: string
  maxassociations: string
  minassociations: string
  children: JSX.Element[]
  accessibilityAttr?: { [key: string]: string }
}

export const MatchInteractionComponent: FC<MatchInteractionComponentProps> = props => {
  const {
    itemId,
    responseidentifier: responseIdentifier,
    minassociations: minAssociations = '0',
    maxassociations: maxAssociations,
    children,
    accessibilityAttr,
  } = props

  const { currentItemResponse } = useSnapshot(CurrentTestState)
  const response = useItemResponse(currentItemResponse?.id, responseIdentifier)
  const { scoring } = useAssessmentItemResponseScoring(currentItemResponse?.itemId, responseIdentifier)

  const [[simpleMatchRows, simpleMatchColumns], prompt] = useInteractionDivide(
    DomNodes.simpleMatchSet,
    DomNodes.prompt,
    children,
  )

  const rows = useMemo(
    () => simpleMatchRows.props.children.filter((el: JSX.Element | string) => typeof el !== 'string'),
    [simpleMatchRows],
  )
  const columns = useMemo(
    () => simpleMatchColumns.props.children.filter((el: JSX.Element | string) => typeof el !== 'string'),
    [simpleMatchColumns],
  )

  const initialCountingVariants = useMemo(
    () => getInitialCountingVariants([...rows, ...columns], response),
    [rows, columns, response],
  )

  const [changeMatch, isChecked, isDisabled, isFinished, selectMatches] = useMatchStatesControl(
    itemId,
    responseIdentifier,
    initialCountingVariants,
    Number(maxAssociations),
    Number(minAssociations),
  )

  const isCorrect = (matchId: string) => scoring?.response?.includes(matchId)

  // const getColumnFeedbackItems = (columnId: string) =>
  //   getFeedbackItems(rows, groupStatistics, correctResponse, (correctResponse, rowId) =>
  //     correctResponse?.includes(`${rowId} ${columnId}`),
  //   )

  return (
    <div {...accessibilityAttr}>
      {prompt}
      <ConstraintsAlertContainer
        minChoices={Number(minAssociations)}
        maxChoices={Number(maxAssociations)}
        selectedCount={selectMatches.length}
      />
      <MatchTableComponent
        rows={rows}
        columns={columns}
        // groupMode={groupMode}
        // getFeedbackItems={getColumnFeedbackItems}
        isChecked={isChecked}
        isDisabled={isDisabled}
        isCorrect={isCorrect}
        isFinished={isFinished}
        onChange={changeMatch}
      />
    </div>
  )
}
