import { AnswerBubble } from '@app/components/ui'
import { createArray } from '@app/helpers'
import { Box, Popper, styled } from '@mui/material'
import { FC, useState } from 'react'
import { useInputsRef } from '../hooks'
import { IGridInSymbols } from '../types'
import { getUniqSymbols, selectBubble } from '../utils'
import { GridInInputComponent } from './GridInInput'
import { FIELD_CHOICE_CHECKED_ATTRIBUTES } from '@app/constants'

interface GridInMobileProps {
  columnCount: number
  symbols: string[][]
  symbolsStructure: IGridInSymbols
  inputValues: string[]
  setColumnInputValue: (columnIndex: number, value: string) => void
}

export const GridInMobile: FC<GridInMobileProps> = ({
  columnCount,
  symbols,
  symbolsStructure,
  inputValues,
  setColumnInputValue,
}) => {
  const { inputsRef, onChange, onKeyDown, changeFocus } = useInputsRef(
    columnCount,
    symbols,
    inputValues,
    setColumnInputValue,
  )
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [focusInputIndex, setFocusInputIndex] = useState<number | null>(null)

  const openPopper = Boolean(anchorEl)
  const id = openPopper ? `gridIn_popper` : undefined

  const focusHandler = (index: number) => () => {
    setFocusInputIndex(index)
    setAnchorEl(inputsRef.current[index].parentElement.parentElement)
  }

  const clickHandler = (symbol: string) => () => {
    selectBubble(focusInputIndex, symbol, inputValues, setColumnInputValue)
    changeFocus(focusInputIndex, symbol)
  }

  const blurHandler = () => {
    setFocusInputIndex(null)
    setAnchorEl(null)
  }

  return (
    <GridInMobileWrapper>
      {createArray(columnCount).map(index => (
        <GridInMobileInputWrapper key={index} aria-describedby={id}>
          <GridInInputComponent
            ref={inputsRef}
            index={index}
            inputValue={inputValues[index]}
            changeHandler={onChange}
            keyDownHandler={onKeyDown}
            onFocus={focusHandler(index)}
            onBlur={blurHandler}
            ariaLabel={`Input ${index + 1} column`}
          />
        </GridInMobileInputWrapper>
      ))}
      <Popper open={openPopper} id={id} anchorEl={anchorEl} placement='bottom-start'>
        <GridInMobileSymbols>
          {Object.values(getUniqSymbols(symbolsStructure)).map((rowSymbols, index) => (
            <AnswerBubbleWrapper key={index}>
              {(rowSymbols as string[]).map((symbol, i) => (
                <AnswerBubble
                  key={`${symbol}_${i}`}
                  symbol={symbol}
                  selected={inputValues[focusInputIndex] === symbol}
                  {...(inputValues[focusInputIndex] === symbol ? FIELD_CHOICE_CHECKED_ATTRIBUTES : {})}
                  disabled={!symbols[focusInputIndex]?.includes(symbol)}
                  size='medium'
                  onMouseDown={clickHandler(symbol)}
                  ariaLabel={`'${symbol === '.' ? 'point' : symbol}' symbol`}
                  focusable={true}
                />
              ))}
            </AnswerBubbleWrapper>
          ))}
        </GridInMobileSymbols>
      </Popper>
    </GridInMobileWrapper>
  )
}

const GridInMobileWrapper = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('mobile')]: {
    display: 'flex',
  },
}))

const GridInMobileInputWrapper = styled(Box)(({ theme }) => ({
  width: '54px',
  height: '54px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey[200]}`,

  ':first-child': {
    borderRadius: `${theme.shape.borderRadius * 1.5}px 0 0 ${theme.shape.borderRadius * 1.5}px`,
  },
  ':last-child': {
    borderRadius: `0 ${theme.shape.borderRadius * 1.5}px ${theme.shape.borderRadius * 1.5}px 0`,
  },

  ':focus-within': {
    borderColor: theme.palette.grey[400],
  },
}))

const AnswerBubbleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),

  ':not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}))

const GridInMobileSymbols = styled(Box)(({ theme }) => ({
  maxWidth: '190px',
  width: '100%',
  padding: theme.spacing(2.5),
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}))
