import { FC, forwardRef, KeyboardEvent, useContext, useRef, useMemo } from 'react'
import {
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryContainerProps,
  VictoryLine,
  VictoryScatter,
} from 'victory'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { Coordinates } from '@dnd-kit/utilities'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import { ScreenReaderInfo } from '@app/components'
import { useSVGScale } from '@app/helpers'
import { scrollBarMixinObj } from '@app/constants'
import { DndCustomMonitor, ColorOptions } from '../GraphingInteraction/components'
import {
  POINT_DRAG_DETECT_DISTANCE,
  POINT_DRAG_OUT_THRESHOLD,
  POINT_SIZE,
} from '../GraphingInteraction/constants'
import {
  FocusKeyboardSensor,
  getNextCoordinates,
  getTicks,
  restrictToContainerElement
} from '../GraphingInteraction/helpers'
import { IntervalType, NumberLineProps } from './types'
import {
  DraggableIntervalPoint,
  IntervalCurve,
  IntervalSelect,
  IntervalTypeSelect,
  Tick,
  TickLabel,
  Axis,
} from './components'
import {
  getIntervalData,
  getIntervalDomain,
  omitFloatTicks,
  snapHorizontalCenterToCursor,
  accessibility,
  announcements,
} from './helpers'
import { useDraggableIntervals } from './hooks'
import { IntervalsContext } from './context'
import { MOCK_AXIS_STYLE } from './constants'

export const NumberLine: FC<NumberLineProps> = props => {
  const {
    width,
    height,
    axis: { min, max, increment },
    intervalTypes,
    disabled,
  } = props

  const svgChildRef = useRef(null)
  const chartContainerRef = useRef(null)

  const svgScale = useSVGScale(svgChildRef)

  const chartDomain: { x: [number, number]; y: [number, number] } = { x: [min, max], y: [-1, 1] }
  const xTicks = getTicks(min, max, increment)
  const gridX = width / (xTicks.length - 1)
  const gridSize: Coordinates = { x: gridX * svgScale[0], y: 0 }

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: POINT_DRAG_DETECT_DISTANCE } }),
    useSensor(FocusKeyboardSensor, { coordinateGetter: getNextCoordinates(gridSize.x, gridSize.y) }),
  )

  const {
    intervals,
    current,
    onAddInterval,
    onRemoveInterval,
    onSelectInterval,
    onMovePoint,
    announcement: intervalAnnouncement,
  } = useContext(IntervalsContext)

  const currentInterval = intervals.find(interval => interval.id === current)
  const restIntervals = intervals.filter(interval => interval.id !== current)

  const { onDragEnd } = useDraggableIntervals(
    current,
    intervals,
    gridSize,
    increment,
    chartDomain,
    onMovePoint,
  )

  const pointKeyDownHandler = (e: KeyboardEvent<SVGGElement>) =>
    e.key === 'Backspace' && onRemoveInterval(currentInterval.id)

  const theme = useTheme()
  const isTouchDevice = useMediaQuery(`(max-width: ${theme.breakpoints.values.mobile}px), (hover: none)`)

  const handleContainerClick = (event: any) => {
    if (!current) return

    const domPoint = new DOMPoint(event.clientX, 0)
    const coords = domPoint.matrixTransform(
      chartContainerRef.current?.containerRef?.firstChild.getScreenCTM().inverse(),
    )
    const chartPoint = new DOMPoint(chartDomain.x[0] + Math.round(coords.x / gridSize.x) * increment)
    // console.log(domPoint, coords, chartPoint)

    onMovePoint(chartPoint)
  }

  const colorOptions = useMemo(
    (): ColorOptions => ({
      hueValue: 700,
      focusFill: true,
      hoverOpacity: 0.3,
    }),
    [],
  )

  return (
    <StyledWrapper>
      <DndContext
        onDragEnd={onDragEnd}
        modifiers={[
          restrictToContainerElement(
            chartContainerRef.current?.containerRef?.firstChild,
            POINT_DRAG_OUT_THRESHOLD,
          ),
          restrictToHorizontalAxis,
          snapHorizontalCenterToCursor,
        ]}
        sensors={sensors}
        accessibility={accessibility}
      >
        <DndCustomMonitor announcements={announcements} />

        <StyledChartContainerWrapper onClick={isTouchDevice ? handleContainerClick : undefined}>
          <VictoryChart
            containerComponent={
              <ChartContainer
                className='svg-container'
                ref={chartContainerRef}
                width={width}
                height={height}
                role='group'
                aria-label='number line'
              />
            }
            width={width}
            height={height}
            domain={chartDomain}
            padding={0}
            // domainPadding={gridX}
          >
            <g role='presentation' ref={svgChildRef} />

            <VictoryAxis
              crossAxis={false}
              // "tickCount" produces wrong number of ticks sometimes, so have to use "tickValues" instead.
              tickValues={xTicks}
              axisComponent={<Axis />}
              tickComponent={<Tick />}
              tickLabelComponent={<TickLabel dy={-55} />}
              tickFormat={omitFloatTicks}
              style={MOCK_AXIS_STYLE}
            />

            {intervals.map((interval, i) => {
              const domain = getIntervalDomain(chartDomain, interval)
              const data = getIntervalData(chartDomain, interval)

              return (
                <VictoryLine
                  key={i}
                  dataComponent={
                    <IntervalCurve
                      direction={interval.direction}
                      className='graph'
                      selected={current === interval.id}
                    />
                  }
                  samples={2}
                  data={data}
                  domain={domain}
                />
              )
            })}
            {restIntervals.map((interval, i) => (
              <VictoryScatter
                key={interval.id}
                size={POINT_SIZE}
                data={[interval.point]}
                dataComponent={
                  <DraggableIntervalPoint
                    colorOptions={colorOptions}
                    colorIndex={-1}
                    className='point'
                    disabled={disabled || true}
                    filled={interval.type === IntervalType.CLOSED}
                    draggableData={{ interval }}
                  />
                }
              />
            ))}
            {currentInterval ? (
              <VictoryScatter
                size={POINT_SIZE}
                data={[currentInterval.point]}
                dataComponent={
                  <DraggableIntervalPoint
                    colorOptions={colorOptions}
                    colorIndex={-1}
                    className='point'
                    disabled={disabled}
                    selected={isTouchDevice}
                    filled={currentInterval.type === IntervalType.CLOSED}
                    keyDownHandler={pointKeyDownHandler}
                    draggableData={{ interval: currentInterval }}
                  />
                }
              />
            ) : null}
          </VictoryChart>
        </StyledChartContainerWrapper>
      </DndContext>

      <ScreenReaderInfo ariaAtomic ariaLive='polite'>
        {intervalAnnouncement}
      </ScreenReaderInfo>

      <StyledIntervalActions>
        <StyledIntervalTypeSelect
          options={intervalTypes}
          onSelect={onAddInterval}
          disabled={intervals.length >= 2 || disabled}
        />
        <StyledIntervalSelect
          value={current}
          options={intervals}
          onChange={onSelectInterval}
          onRemove={onRemoveInterval}
          disabled={disabled}
        />
      </StyledIntervalActions>
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledIntervalActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginTop: theme.spacing(10),
  gap: theme.spacing(6),

  [theme.breakpoints.up('mobile')]: {
    justifyContent: 'flex-start',
  },
}))

const StyledIntervalTypeSelect = styled(IntervalTypeSelect)(({ theme }) => ({}))

const StyledIntervalSelect = styled(IntervalSelect)(({ theme }) => ({
  flexDirection: 'column',

  [theme.breakpoints.up('mobile')]: {
    flexDirection: 'row',
  },
}))

const StyledChartContainer = styled(
  forwardRef<any, VictoryContainerProps>((props, ref) => <VictoryContainer ref={ref} {...props} />),
)(({ theme, width, height }) => ({
  '& > svg': {
    overflow: 'visible',
  },

  [`${theme.breakpoints.down('mobile')}, (hover: none)`]: {
    touchAction: 'initial !important',
    minWidth: width,
    minHeight: height,

    '& > svg': {
      margin: theme.spacing(0, 3),
    },
  },
}))

const ChartContainer = forwardRef<any, VictoryContainerProps>(({ theme, ...props }, ref) => {
  return <StyledChartContainer ref={ref} {...props} />
})

const StyledChartContainerWrapper = styled('div')(({ theme }) => ({
  position: 'relative',

  [`${theme.breakpoints.down('mobile')}, (hover: none)`]: {
    overflowX: 'auto',
    overflowY: 'hidden',
    ...scrollBarMixinObj(theme, 'horizontal'),
  },
}))
