import React from 'react'
import { Button, buttonClasses, styled } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripHorizontal } from '@fortawesome/pro-solid-svg-icons/faGripHorizontal'
import { SHOW_QUESTIONS_BTN_ATTRIBUTES } from '@app/constants'

type Props = {
  modalButtonRef: React.MutableRefObject<HTMLButtonElement>
  onShowQuestionsModal: () => void
}

export const TestTakingNavigator: React.FC<Props> = ({ modalButtonRef, onShowQuestionsModal }) => {
  return (
    <StyledButton
      ref={modalButtonRef}
      onClick={onShowQuestionsModal}
      startIcon={<FontAwesomeIcon icon={faGripHorizontal} />}
      variant='outlined'
      color='grey'
      size='small'
      {...SHOW_QUESTIONS_BTN_ATTRIBUTES}
      aria-label='All Questions'
      role='button'
    >
      Question Navigation
    </StyledButton>
  )
}

const StyledButton = styled(Button)(({ theme }) => ({
  [`& .${buttonClasses.startIcon} > *:nth-of-type(1)`]: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
  },
}))
