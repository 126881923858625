import React from 'react'
import { Stack, Typography } from '@mui/material'
import {
  AlertContainerComponent,
  AlertContainerComponentProps,
} from '@app/components/ui/containers/AlertContainer'
import ReviewIcon from '@app/components/ui/icons/ReviewIcon'

export type ItemFeedbackProps = {
  correct?: boolean
  correctAnswer?: React.ReactNode
  raw?: number
  possible?: number
  forceMultiscore?: boolean
  multiScoreFormatter?: (raw: number, possible: number) => string
  correctAnswerFormatter?: (correctAnswer: React.ReactNode) => React.ReactNode
  size?: AlertContainerComponentProps['size']
  showScoreLabel?: boolean
  showIcon?: boolean
  className?: string
  accessibilityAttr?: Record<string, string>
}

const defaultMultiScoreFormatter = (raw: number, possible: number): string => {
  return `${raw} of ${possible} Answers Correct`
}

const defaultCorrectAnswerFormatter = (correctAnswer: React.ReactNode) => {
  return (
    <Typography variant='body2' color="inherit" component="span">
      Correct Answer: {correctAnswer}
    </Typography>
  )
}

export const ItemFeedback: React.FC<ItemFeedbackProps> = ({
  correct,
  raw,
  possible,
  forceMultiscore = false,
  multiScoreFormatter = defaultMultiScoreFormatter,
  correctAnswerFormatter = defaultCorrectAnswerFormatter,
  className,
  correctAnswer,
  size,
  showScoreLabel = true,
  showIcon = true,
  accessibilityAttr = {},
}) => {
  const isMultiScore = forceMultiscore || possible > 1

  return (
    <AlertContainerComponent
      severity={correct ? 'success' : 'warning'}
      variant='outlined'
      size={size}
      className={className}
      icon={showIcon && <ReviewIcon correct={correct} />}
      {...accessibilityAttr}
    >
      <Stack gap={1}>
        {showScoreLabel && (
          <Typography variant='body2' color="inherit" component="span">
            {isMultiScore
              ? multiScoreFormatter(raw, possible)
              : correct ? 'Correct' : 'Incorrect'
            }
          </Typography>
        )}
        {!correct && correctAnswer && correctAnswerFormatter(correctAnswer)}
      </Stack>
    </AlertContainerComponent>
  )
}
