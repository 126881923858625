import { MutableRefObject, useEffect, useState } from 'react'

export const usePopperOriginPoint = (
  sourceRef: MutableRefObject<SVGGElement>,
  buttonRef: MutableRefObject<HTMLButtonElement>,
) => {
  const [popperPoint, setPopperPoint] = useState({ x: 0, y: 0, width: 0, height: 0 })

  useEffect(() => {
    if (sourceRef.current && buttonRef.current) {
      const sourceBBox = sourceRef.current.getBBox()
      setPopperPoint({
        x: sourceBBox.x + sourceBBox.width / 2 - buttonRef.current.offsetWidth / 2,
        y: sourceBBox.y + sourceBBox.height / 2 - buttonRef.current.offsetHeight / 2,
        width: buttonRef.current.offsetWidth,
        height: buttonRef.current.offsetWidth,
      })
    }
  }, [sourceRef, buttonRef])

  return popperPoint
}
