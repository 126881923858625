/* eslint-disable no-restricted-globals */
const timerWebWorker = () => {
  let interval: any = null
  self.onmessage = (event: MessageEvent<string> ) => {
    switch (event.data) {
      case 'resume':
      case 'start': {
        interval = setInterval(() => {
          self.postMessage('tick')
        }, 1000)
        break
      }
      case 'reset':
      case 'pause': {
        clearInterval(interval)
        break
      }
    }
  }
}


let code = timerWebWorker.toString()
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))

const blob = new Blob([code], { type: "application/javascript" })
const timerWebWorkerScript = URL.createObjectURL(blob)

export default timerWebWorkerScript
