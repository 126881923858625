import { v4 as uuid } from 'uuid'

export class UniqueDOMPoint extends DOMPoint {
  id: string

  constructor(x?: number, y?: number, z?: number, w?: number) {
    super(x, y, z, w)

    this.id = uuid()
  }
}

export interface AxisProps {
  identifier: string
  min: number
  max: number
  increment: number // tick increment
  label: string
}

export interface StaticPointsProps {
  showLine: boolean // connect points or not
  points: Coordinates[]
}

export interface Coordinates {
  x: number
  y: number
}

export interface GraphProps {
  id: string
  graphType: GraphType
  lineStyle: LineStyle
  lineStyleToggle: boolean
  panelTitle: string
  maxPoints: number
  minPoints: number
}

export type GraphChangeCallback = (
  graphType: GraphType,
  graphs: GraphState[],
  asymptote: number,
  solutionAreas?: DOMPoint[][],
) => void

export interface GraphTypeSelectOption {
  value: string
  label?: string
}

export interface GraphingInteractionProps {
  // common
  width: number
  height: number
  xAxis: Partial<AxisProps>
  yAxis: Partial<AxisProps>

  graphTypeSelect?: GraphTypeSelectOption[]

  // non-interactive points props
  staticPoints: Partial<StaticPointsProps>

  // "set solution" mode
  solutionSetEnabled: boolean
  solutionSetButtonText: string // ???

  // draw several graphs and their settings
  graphsData: Partial<GraphProps>[]

  disabled?: boolean
}

export interface GraphState {
  id?: string
  isValid: boolean
  points: UniqueDOMPoint[]
  lineStyle: LineStyle
}

export enum GraphType {
  LINEAR = 'linear',
  QUADRATIC = 'quadratic',
  EXPONENTIAL = 'exponential',
}

export enum LineStyle {
  SOLID = 'solid',
  DASHED = 'dashed',
}

export enum DraggableElement {
  POINT = 'point',
  ASYMPTOTE = 'asymptote',
}

export interface ChartDomain {
  x: [number, number]
  y: [number, number]
}

export enum GraphingNodes {
  axis = 'axis',
  staticPoints = 'staticpoints',
  graph = 'graph',
  solution = 'solutionset',
  point = 'point',
  graphTypeSelect = 'graphtypeselect',
  graphType = 'graphtype',
}

export interface GraphingInteractionContainerProps {
  itemId: string
  responseidentifier: string
  children: JSX.Element | JSX.Element[]
  width: string
  height: string
}

export interface GraphingState {
  graphType: GraphType
  graphs: GraphState[]
  asymptote: number
  solutionAreas: DOMPoint[][]
}

export interface GraphData {
  _x: number
  _y: number
  x: number
  y: number
}

export type DraggableItem = {
  id: string
  type: DraggableElement
}

export interface DraggableData {
  id: string
  _x?: number
  _y?: number
  type: DraggableElement
}
