import { Quill } from 'react-quill'
import { styled } from '@mui/material'
import { renderToString } from 'react-dom/server'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBold } from '@fortawesome/pro-solid-svg-icons/faBold'
import { faItalic } from '@fortawesome/pro-solid-svg-icons/faItalic'
import { faUnderline } from '@fortawesome/pro-solid-svg-icons/faUnderline'
import { faTextSlash } from '@fortawesome/pro-solid-svg-icons/faTextSlash'
import { faListOl } from '@fortawesome/pro-solid-svg-icons/faListOl'
import { faListUl } from '@fortawesome/pro-solid-svg-icons/faListUl'
import { faOutdent } from '@fortawesome/pro-solid-svg-icons/faOutdent'
import { faIndent } from '@fortawesome/pro-solid-svg-icons/faIndent'
import { faScissors } from '@fortawesome/pro-solid-svg-icons/faScissors'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faPaste } from '@fortawesome/pro-solid-svg-icons/faPaste'
import { faOmega } from '@fortawesome/pro-solid-svg-icons/faOmega'
import { faUndo } from '@fortawesome/pro-solid-svg-icons/faUndo'
import { faRedo } from '@fortawesome/pro-solid-svg-icons/faRedo'
import { FC } from 'react'

const Icon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}))

const icons = Quill.import('ui/icons')
icons['bold'] = renderToString(<Icon icon={faBold} />)
icons['italic'] = renderToString(<Icon icon={faItalic} />)
icons['underline'] = renderToString(<Icon icon={faUnderline} />)
icons['clean'] = renderToString(<Icon icon={faTextSlash} />)
icons['list'].bullet = renderToString(<Icon icon={faListUl} />)
icons['list'].ordered = renderToString(<Icon icon={faListOl} />)
icons['indent']['+1'] = renderToString(<Icon icon={faIndent} />)
icons['indent']['-1'] = renderToString(<Icon icon={faOutdent} />)
icons['redo'] = renderToString(<Icon icon={faRedo} />)
icons['undo'] = renderToString(<Icon icon={faUndo} />)
icons['cut'] = renderToString(<Icon icon={faScissors} />)
icons['copy'] = renderToString(<Icon icon={faCopy} />)
icons['paste'] = renderToString(<Icon icon={faPaste} />)
icons['formula'] = renderToString(<Icon icon={faOmega} />)

interface CustomEditorToolbarProps {
  disabled?: boolean
  handleOpenCharactersModal: () => void
}

export const CustomEditorToolbar: FC<CustomEditorToolbarProps> = ({
  disabled,
  handleOpenCharactersModal,
}) => (
  <div id='toolbar'>
    <span className='ql-formats'>
      <button disabled={disabled} className='ql-bold' aria-label='Bold' />
      <button disabled={disabled} className='ql-italic' aria-label='Italic' />
      <button disabled={disabled} className='ql-underline' aria-label='Underline' />
      <button disabled={disabled} className='ql-clean' aria-label='Clean All Formatting' />
    </span>
    <span className='ql-formats'>
      <button disabled={disabled} className='ql-list' value='ordered' aria-label='Ordered list' />
      <button disabled={disabled} className='ql-list' value='bullet' aria-label='Bullet list' />
      <button disabled={disabled} className='ql-indent' value='+1' aria-label='Indent' />
      <button disabled={disabled} className='ql-indent' value='-1' aria-label='Outdent' />
    </span>
    <span className='ql-formats'>
      <button disabled={disabled} className='ql-cut' aria-label='Cut' />
      <button disabled={disabled} className='ql-copy' aria-label='Copy' />
      <button disabled={disabled} className='ql-paste' aria-label='Paste' />
      <button disabled={disabled} className='ql-undo' aria-label='Undo' />
      <button disabled={disabled} className='ql-redo' aria-label='Redo' />
    </span>
    <span className='ql-formats'>
      <button
        disabled={disabled}
        className='ql-formula'
        aria-label='Formula'
        onClick={handleOpenCharactersModal}
      />
    </span>
  </div>
)
