import { forwardRef } from 'react'
import { styled } from '@mui/material'
import { focusStyle } from '@app/theme'

export interface CoordinatePlaneProps {
  id: string
  width: number
  height: number
  allowAdd: boolean
}

export const CoordinatePlane = forwardRef<SVGGElement, CoordinatePlaneProps>(
  ({ id, width, height, allowAdd }, ref) => (
    <g ref={ref} role='presentation'>
      <StyledCoordinatePlane
        id={id}
        stroke='none'
        fill='none'
        x={0}
        y={0}
        width={width}
        height={height}
        role='button'
        aria-label='Coordinate plane'
        tabIndex={allowAdd ? 0 : undefined}
      />
    </g>
  ),
)

const StyledCoordinatePlane = styled('rect')(({ theme }) => ({
  ':focus': {
    outline: 'none',
  },
  ':focus-visible': {
    strokeWidth: '1px',
    stroke: focusStyle(theme).colors.border,
    outline: focusStyle(theme).border.outline,
  },
}))
