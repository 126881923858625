import { forwardRef } from 'react'
import { Popper, PopperProps, styled } from '@mui/material'

export interface ForeignObjectPopperProps extends PopperProps {
  x?: number | string
  y?: number | string
  width?: number | string
  height?: number | string
  style?: React.CSSProperties
  onOpen?: () => void
}

const POPPER_STYLE = { display: 'block' }

export const ForeignObjectPopper = forwardRef<HTMLDivElement, ForeignObjectPopperProps>(
  ({ x, y, width, height, style, onOpen, ...props }, ref) => {
    return (
      <StyledForeignObject x={x} y={y} width={width} height={height} style={style} onClick={onOpen}>
        <PopperWrapper>
          <Popper ref={ref} style={POPPER_STYLE} {...props} />
        </PopperWrapper>
      </StyledForeignObject>
    )
  },
)

const StyledForeignObject = styled('foreignObject')(() => ({
  overflow: 'visible',
  transformOrigin: 'center',
  transformBox: 'fill-box',
}))

const PopperWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
}))
