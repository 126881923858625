import { DomNodes } from "@app/constants"
import { Children } from "react"
import { getChildrenDeep, removeChildren } from "react-nanny"

export const useInteractionDivide = (
  firstElement: DomNodes,
  secondElement: DomNodes,
  children: JSX.Element | JSX.Element[],
): [JSX.Element[], JSX.Element[]] => {
  const first: JSX.Element[] = []
  const second: JSX.Element[] = []

  Children.forEach(children, child => {
    if (child.props?.componentNode === firstElement) {
      first.push(child)
    }
    if (child.props?.componentNode === secondElement) {
      second.push(child)
    }
  })
  return [first, second]
}


/**
 * Function for finding certain nodes of children.
 * @param children React Children elements.
 * @param nodes Array of certain node names.
 * @returns Array of found nodes.
 */
export const useFindNodes = (children: JSX.Element | JSX.Element[], nodes: string[]): JSX.Element[][] =>
  nodes.map(node =>
    getChildrenDeep(children, (child: JSX.Element) => (child.props?.componentNode || child.type) === node),
  )

export const getRestChildren = (children: JSX.Element | JSX.Element[], nodes: string[]): JSX.Element[] => {
  return removeChildren(children, (child: JSX.Element) => nodes.includes(child.props?.componentNode)).filter(
    node => (typeof node === 'string' ? (node as string).trim() : true),
  )
}