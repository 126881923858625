import React from 'react'
import { Stack, Typography, alpha, styled } from '@mui/material'
import ExperienceRatingScale from '../ExperienceRatingScale'
import { ExperienceRating } from '../ExperienceRatingScale/types'

interface Props {
  selectedRate: ExperienceRating
  feedbackText?: string
  onChangeFeedbackRating: (value: ExperienceRating) => void
  disabled?: boolean
}

const StudentFeedbackView: React.FC<Props> = ({
  selectedRate,
  feedbackText,
  onChangeFeedbackRating,
  disabled = false,
}) => {
  return (
    <FeedbackContainer alignItems='center' spacing={3}>
      {feedbackText && <FeedbackTitle variant='body1'>{feedbackText}</FeedbackTitle>}
      <Stack direction='row' gap={2}>
        <ExperienceRatingScale
          disabled={disabled}
          selectedRate={selectedRate}
          onChange={onChangeFeedbackRating}
        />
      </Stack>
    </FeedbackContainer>
  )
}

const FeedbackContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6, 19, 8),
  backgroundColor: alpha(theme.palette.grey[50], 0.5),
}))

const FeedbackTitle = styled(Typography)(() => ({
  textAlign: 'center',
}))

export default StudentFeedbackView
