import { FC, useCallback } from 'react'
import { Box } from '@mui/material'
import { DomNodes } from '@app/constants'
import { ConstraintsAlertContainer, HotspotArea, ScreenReaderInfo } from '@app/components'
import {
  useFindNodes,
  useOutsideClick,
  transformChoicesConstraints,
  useTestPartFinishedCheck,
} from '@app/helpers'
import { AssociableHotspot, HOTSPOT_TYPE_ASSOCIABLE } from '../AssociableHotspot'
import { useAssociations, useCurrentHotspot, useAssociationLines } from './hooks'
import { RemovableAssociationLine } from './components'

const ASSOCIABLE_HOTSPOT_SELECTOR = `[data-hotspot-type="${HOTSPOT_TYPE_ASSOCIABLE}"]`

interface GraphicAssociateInteractionProps {
  itemId: string
  responseidentifier: string
  minassociations?: string
  maxassociations?: string
  className?: string
  children?: JSX.Element | JSX.Element[]
  accessibilityAttr?: { [key: string]: string }
}

export const GraphicAssociateInteraction: FC<GraphicAssociateInteractionProps> = props => {
  const { itemId, responseidentifier, className, children, accessibilityAttr } = props

  const [minAssociations, maxAssociations] = transformChoicesConstraints(
    props.minassociations,
    props.maxassociations,
  )
  const [[prompt], [image], associableHotspots] = useFindNodes(children, [
    DomNodes.prompt,
    DomNodes.object,
    DomNodes.associableHotspot,
  ])

  const { associations, onToggleAssociation, onClearAssociation, announcement } = useAssociations(
    responseidentifier,
    {
      minAssociations,
      maxAssociations,
    },
  )

  const [currentHotspot, onClickHotspot, setCurrentHotspot] = useCurrentHotspot(
    ASSOCIABLE_HOTSPOT_SELECTOR,
    onToggleAssociation,
  )
  const [associationLines] = useAssociationLines(associations)

  const handleOutsideClick = useCallback(() => {
    setCurrentHotspot('')
  }, [setCurrentHotspot])

  useOutsideClick(ASSOCIABLE_HOTSPOT_SELECTOR, handleOutsideClick)
  const isFinished = useTestPartFinishedCheck()

  return (
    <Box className={className} {...accessibilityAttr}>
      {prompt}
      <ConstraintsAlertContainer
        minChoices={minAssociations}
        maxChoices={maxAssociations}
        selectedCount={associations.length}
      />
      <HotspotArea image={image}>
        {associableHotspots.map(hotspot => {
          const hotspotId = hotspot.props.identifier
          const matchCount = associations.filter(association => association.includes(hotspotId)).length
          return (
            <AssociableHotspot
              key={hotspotId}
              itemId={itemId}
              active={currentHotspot === hotspotId}
              selected={matchCount > 0}
              matchCount={matchCount}
              onToggle={onClickHotspot(hotspotId)}
              {...hotspot.props}
            />
          )
        })}

        {associationLines.map(associationLine => (
          <RemovableAssociationLine
            {...associationLine}
            id={associationLine.key}
            onRemove={onClearAssociation(associationLine.key)}
            removable={!isFinished}
          />
        ))}
      </HotspotArea>

      <ScreenReaderInfo ariaAtomic ariaLive='polite'>
        {announcement}
      </ScreenReaderInfo>
    </Box>
  )
}
