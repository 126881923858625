import { AssessmentItemResponse } from '@app/models'
import { Timestamp } from 'firebase/firestore'

export enum FirestoreCollections {
  assessments = 'assessments',
  studentAssessments = 'studentAssessments',
  assessmentEvents = 'assessmentEvents',
  scoring = 'scoringByItem'
}

export enum AssessmentEventType {
  assessmentEvent = 'AssessmentEvent',
  assessmentItemEvent = 'AssessmentItemEvent',
}

export enum AssessmentActions {
  started = 'Started',
  paused = 'Paused',
  resumed = 'Resumed',
  restarted = 'Restarted',
  reset = 'Reset',
  submitted = 'Submitted',
  skipped = 'Skipped',
  completed = 'Completed',
  timeAdded = 'TimeAdded',
  sync = 'Sync',
}

export enum AssessmentStatus {
  completed = 'COMPLETED',
  started = 'STARTED',
  new = 'NEW',
  interrupted = 'INTERRUPTED',
  paused = 'PAUSED',
}

export type AssessmentSettings = {
  timeLimit: number
  isResumable: boolean
  maxIdletime: number
  syncInterval: number
}

export type FirestoreAssessment = {
  id: string
  path: string
  deleted: boolean
  createdTimestamp: Date
  updatedTimestamp: Date
  settings: AssessmentSettings
}

export type UserAssessmentSettings = {
  timeLimits: number
  isResumable: boolean
}

export type UserAssessment = {
  userId: string
  assessmentId: string
  version: number
  itemResponses: AssessmentItemResponse[]
  lastViewedItemId: string
  createdTimestamp: Timestamp
  updatedTimestamp: Timestamp
  startedTimestamp: Timestamp
  attempt: number
  status: AssessmentStatus
  hideInfoBoxes: boolean
  deleted: boolean
  schoolYear: string
  assessmentSettings: UserAssessmentSettings
  passedTime: number
  addedTime: number
  responseXml: string
  //responseHash: string
}

export type AssessmentEvent = {
  id: string
  deleted: boolean
  userId: string
  createdTimestamp: Date
  updatedTimestamp: Date
  assessmentId: string
  assessmentItemId: string
  type: AssessmentEventType
  eventTimestamp: Date
  action: AssessmentActions
  attempt: number
  passedTime: number // seconds
  data: any /* Object with custom data (added time etc.) */
}
