import { FC, useCallback, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'
import { Box } from '@mui/material'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { DATA_CLICK_AWAY_ID, DATA_CLICK_AWAY_PROPS, DomNodes } from '@app/constants'
import { CurrentTestState } from '@app/storage'
import {
  useFindNodes,
  transformChoicesConstraints,
  viewportRectIntersection,
  useTestPartFinishedCheck,
  generateHumanReadableIds,
  generateAnnouncement,
  useOutsideClick,
  useAssessmentItemResponseScoring,
} from '@app/helpers'
import {
  DroppableHotspot,
  GapImgComponent,
  HotspotArea,
  ScreenReaderInfo,
  StyledHotspotAreaOverlay,
} from '@app/components'
import { MATCH_SEPARATOR, transformResponseToGapMatches, useGapMatches } from './hooks'
import { GapImagesRow, GapImageWrapper, GraphicGapMatchImage } from './components'

const DROPPABLE_AREA_ID = 'gap-images-row'

interface GraphicGapMatchInteractionProps {
  itemId: string
  responseidentifier: string
  minassociations?: string
  maxassociations?: string
  className?: string
  children?: JSX.Element | JSX.Element[]
  accessibilityAttr?: { [key: string]: string }
}

export const GraphicGapMatchInteraction: FC<GraphicGapMatchInteractionProps> = props => {
  const { responseidentifier, className, children, minassociations, maxassociations, accessibilityAttr } =
    props
  const [minAssociations, maxAssociations] = transformChoicesConstraints(minassociations, maxassociations)

  const [[prompt], [image], gapImages, hotspotChoices] = useFindNodes(children, [
    DomNodes.prompt,
    DomNodes.object,
    DomNodes.gapImg,
    DomNodes.associableHotspot,
  ])

  const { gapMatches, onMatch, announcement } = useGapMatches(responseidentifier, {
    minAssociations,
    maxAssociations,
  })
  const { currentItemResponse } = useSnapshot(CurrentTestState)
  const { scoring, groupScoring, correctResponse, groupMode } = useAssessmentItemResponseScoring(
    currentItemResponse?.itemId,
    responseidentifier,
  )
  const isReviewMode = scoring || groupScoring

  const targetGapMatches =
    isReviewMode && groupMode ? transformResponseToGapMatches(correctResponse) : gapMatches

  const [dragging, setDragging] = useState(null)
  const [activeImageId, setActiveImageId] = useState(null)

  const handleClickOnImageElement = useCallback(
    (id: string, isExistImageInGap = false) =>
      () => {
        if (isExistImageInGap && activeImageId) return
        setActiveImageId(id)
      },
    [activeImageId],
  )

  const handleClickOnGapElement = useCallback(
    (id: string) => () => {
      if (activeImageId && id) {
        onMatch(id, activeImageId)
      }
    },
    [activeImageId, onMatch],
  )

  const onRemove = useCallback(
    (matchId: string) => (event: React.MouseEvent) => {
      event.stopPropagation()
      onMatch(null, matchId)
      setActiveImageId(null)
    },
    [onMatch],
  )

  const draggingGapImage = gapImages.find(gapImage => gapImage.props.identifier === dragging)

  const isFinished = useTestPartFinishedCheck()

  const handleDragStart = (event: DragStartEvent) => {
    setActiveImageId(null)
    setDragging(event.active.id)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    setDragging(null)
    setActiveImageId(event.active.id.toString())

    if (event.over && event.over.id) {
      const gap = event.over.id === DROPPABLE_AREA_ID ? null : event.over.id.toString()
      onMatch(gap, event.active.id.toString())
    }
  }

  const svgRef = useRef<SVGSVGElement>(null)

  const announcements = generateAnnouncement(
    generateHumanReadableIds(gapImages),
    generateHumanReadableIds(hotspotChoices),
  )

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 15 } }),
    useSensor(KeyboardSensor),
  )

  useOutsideClick(`[${DATA_CLICK_AWAY_ID}]`, handleClickOnImageElement(null))

  return (
    <Box className={className} {...accessibilityAttr}>
      {prompt}

      <DndContext
        collisionDetection={viewportRectIntersection}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        sensors={sensors}
        accessibility={{ announcements }}
      >
        <HotspotArea
          image={image}
          svgRef={svgRef}
          overlay={
            <StyledHotspotAreaOverlay>
              {hotspotChoices.map((hotspotChoice: any) => {
                const hotspotId = hotspotChoice.props.identifier
                const gapMatch = targetGapMatches.find(gapMatch => gapMatch.gap === hotspotId)
                const gapMatchId = gapMatch && [gapMatch.match, gapMatch.gap].join(MATCH_SEPARATOR)
                const gapImage = gapImages.find(gapImage => gapImage.props.identifier === gapMatch?.match)
                const matchId = gapImage?.props?.identifier
                const matchCount = gapMatches.filter(gapMatch => gapMatch.gap === hotspotId).length

                return (
                  <DroppableHotspot
                    key={hotspotId}
                    id={hotspotId}
                    matchCount={matchCount}
                    selected={!!gapImage}
                    {...hotspotChoice.props}
                    portalContainer={svgRef.current}
                    wrapperProps={{ onClick: handleClickOnGapElement(hotspotId) }}
                    onClick={handleClickOnGapElement(hotspotId)}
                  >
                    {gapImage && (
                      <GraphicGapMatchImage
                        id={matchId}
                        placed
                        {...gapImage.props}
                        correct={scoring?.response?.includes(gapMatchId)}
                        removable={!isFinished}
                        onRemove={onRemove(matchId)}
                        {...DATA_CLICK_AWAY_PROPS}
                        onClick={handleClickOnImageElement(matchId, true)}
                        selected={activeImageId === matchId}
                        zIndex={1}
                      />
                    )}
                  </DroppableHotspot>
                )
              })}
            </StyledHotspotAreaOverlay>
          }
        />

        <GapImagesRow id={DROPPABLE_AREA_ID}>
          {gapImages.map(gapImage => {
            const gapImgId = gapImage.props.identifier
            const matchCount = gapMatches.filter(gapMatch => gapMatch.match === gapImgId).length
            const hasMaxMatches = gapImage.props.matchmax && Number(gapImage.props.matchmax) === matchCount

            return (
              <GapImageWrapper
                key={gapImgId}
                id={gapImgId}
                disabled={hasMaxMatches || isReviewMode}
                gapImage={gapImage}
                matchCount={matchCount}
                selected={activeImageId === gapImgId}
                onClick={handleClickOnImageElement(gapImgId)}
                {...DATA_CLICK_AWAY_PROPS}
              />
            )
          })}
        </GapImagesRow>

        <DragOverlay>
          {!!draggingGapImage && (
            <GapImgComponent
              overlay
              placedOverlay={gapMatches.some(item => item.match === draggingGapImage.props.identifier)}
              {...draggingGapImage.props}
            />
          )}
        </DragOverlay>
      </DndContext>

      <ScreenReaderInfo ariaAtomic ariaLive='polite'>
        {announcement}
      </ScreenReaderInfo>
    </Box>
  )
}
