import { Button, styled, ButtonProps, alpha } from '@mui/material'
import { forwardRef } from 'react'
import { EquationOperatorsTypes } from '../types'
import { focusStyle } from '@app/theme'

export interface EquationEditorButtonProps extends ButtonProps {
  operatorType: EquationOperatorsTypes
  ariaLabel: string
}

export const EquationEditorButton = forwardRef<any, EquationEditorButtonProps>(
  ({ children, operatorType, ariaLabel, ...props }, ref) => {
    return (
      <StyledButton
        ref={ref}
        aria-label={ariaLabel}
        size='small'
        disableRipple
        operatortype={operatorType}
        {...props}
      >
        {children}
      </StyledButton>
    )
  },
)

interface StyledButtonProps {
  operatortype: EquationOperatorsTypes
}

const StyledButton = styled(Button)<StyledButtonProps>(({ theme, operatortype }) => ({
  minWidth: '50px',
  height: '35px',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: `${theme.shape.borderRadius * 1.5}px`,
  color: theme.palette.grey[600],
  fontSize: theme.typography.body2.fontSize,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(0.5),
  textTransform: 'lowercase',
  ...(operatortype === EquationOperatorsTypes.symbols ? { fontStyle: 'italic' } : {}),

  ':hover:not(:focus)': {
    boxShadow: theme.shadows[2],
    backgroundColor: 'transparent',
  },
  ':active': {
    boxShadow: 'none',
    backgroundColor: alpha(theme.palette.grey[50], 0.5),
    borderColor: theme.palette.grey[200],
  },
  ':focus': {
    ...focusStyle(theme).border,
    boxShadow: 'initial',
  },
  ':disabled': {
    borderColor: theme.palette.grey[100],
  },
}))
