import { AlertColor, Theme } from '@mui/material'
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare'
import { faInfoSquare } from '@fortawesome/pro-solid-svg-icons/faInfoSquare'
import { faSquareExclamation } from '@fortawesome/pro-solid-svg-icons/faSquareExclamation'
import { AlertStatus } from '@app/types'

export const colorPalette = (
  theme: Theme,
  color: AlertColor,
): {
  backgroundColor: string
  color: string
} => {
  switch (color) {
    case AlertStatus.error: {
      return {
        backgroundColor: theme.palette.red[100],
        color: theme.palette.red[800],
      }
    }
    case AlertStatus.info:
      return {
        backgroundColor: theme.palette.blue[100],
        color: theme.palette.blue[800],
      }
    case AlertStatus.warning:
      return {
        backgroundColor: theme.palette.orange[100],
        color: '#7D4704',
      }
    default:
      return {
        backgroundColor: theme.palette.green[100],
        color: theme.palette.green[900],
      }
  }
}

export const getBorderColor = (theme: Theme, color: AlertColor) => {
  switch (color) {
    case AlertStatus.success:
      return theme.palette.green[600]
    case AlertStatus.info:
      return theme.palette.blue[600]
    case AlertStatus.warning:
      return theme.palette.orange[600]
    case AlertStatus.error:
      return theme.palette.red[600]
    default:
      return theme.palette.blue[600]
  }
}

export const getIconColor = (theme: Theme, color: AlertColor) => {
  switch (color) {
    case AlertStatus.success:
      return theme.palette.green[700]
    case AlertStatus.info:
      return theme.palette.blue[600]
    case AlertStatus.warning:
      return theme.palette.orange[700]
    case AlertStatus.error:
      return theme.palette.red[700]
    default:
      return theme.palette.blue[600]
  }
}

export const getIcon = (severity: AlertColor) => {
  switch (severity) {
    case AlertStatus.success:
      return faCheckSquare
    case AlertStatus.info:
      return faInfoSquare
    case AlertStatus.warning:
      return faSquareExclamation
    case AlertStatus.error:
      return faSquareExclamation
  }
}
