export interface Point {
  x: number
  y: number
}

export const getElementCenter = (elementId: string): Point => {
  const element = document.querySelector(`[data-identifier="${elementId}"]`) as SVGGraphicsElement

  if (!element) {
    return { x: 0, y: 0 }
  }

  const elementBox = element.getBBox()

  return {
    x: elementBox.x + elementBox.width / 2,
    y: elementBox.y + elementBox.height / 2,
  }
}
