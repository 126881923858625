import { styled } from '@mui/material'
import { FC } from 'react'

interface CustomSpanProps {
  children: string | JSX.Element | JSX.Element[]
  class?: string
  className?: string
  itemId?: string
}

export const CustomSpan: FC<CustomSpanProps> = ({ children, className, class: spanClass, ...props }) => {
  const spanClassName = className + spanClass ? spanClass : ''

  return (
    <StyledSpan {...props} className={spanClassName}>
      {children}
    </StyledSpan>
  )
}

const StyledSpan = styled('span')(
  ({ theme }) => `
    &.hl, &.hl-u {
      background-color: ${theme.palette.additionalColors['hl_yellow']};
      font-weight: 600;
    }
  
    &.hl-u {
      text-decoration: underline;
    }
  `,
)
