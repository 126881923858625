import { Box, styled } from '@mui/material'
import { FC } from 'react'
import { createPortal } from 'react-dom'
import { useSnapshot } from 'valtio'
import { CurrentTestState } from '@app/storage'
import { PROMPT_CONTAINER_ID } from '@app/constants'

interface PromptProps {
  id?: string
  accessibilityAttr: { [key: string]: string }
}

export const Prompt: FC<React.PropsWithChildren<PromptProps>> = ({ id, children, accessibilityAttr }) => {
  const { currentTestPart } = useSnapshot(CurrentTestState)

  if (currentTestPart?.twoColumnLayout) {
    return createPortal(
      <PromptContainer id={id} {...accessibilityAttr}>{children}</PromptContainer>,
      document.getElementById(PROMPT_CONTAINER_ID),
    )
  }

  return <PromptContainer id={id} {...accessibilityAttr}>{children}</PromptContainer>
}

const PromptContainer = styled(Box)(
  ({ theme }) => `
  color: ${theme.palette.grey[800]};
  margin-bottom: ${theme.spacing(2.5)};
`,
)
