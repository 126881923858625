import React, { useCallback, useState } from 'react'
import { useSnapshot } from 'valtio'
import { DATA_CLICK_AWAY_ID, DomNodes } from '@app/constants'
import {
  getChildrenWithInjectedProps,
  transformChoicesConstraints,
  useAssessmentItemResponseScoring,
  useFindNodes,
  useOutsideClick,
  useSelectableChoice,
  percentageFormatter,
} from '@app/helpers'
import { CurrentTestState } from '@app/storage'
import { Cardinality, InteractionType } from '@app/types'
import { Box, Stack, styled } from '@mui/material'
import { ConstraintsAlertContainer, SimpleChoiceComponent } from '@app/components'

interface ChoiceInteractionProps {
  itemId: string
  componentNode: DomNodes
  responseidentifier?: string
  shuffle?: string
  maxchoices?: string
  minchoices?: string
  startLetter?: string
  orientation?: string
  children?: JSX.Element[]
  accessibilityAttr?: { [key: string]: string }
}

export const ChoiceInteractionComponent: React.FC<ChoiceInteractionProps> = props => {
  const { currentItemResponse } = useSnapshot(CurrentTestState)
  const {
    children,
    maxchoices,
    minchoices,
    startLetter,
    responseidentifier: responseDeclarationId,
    accessibilityAttr,
  } = props

  const [minChoices, maxChoices] = transformChoicesConstraints(minchoices, maxchoices)

  const [focusedChoice, setFocusedChoice] = useState<string>(null)

  const clickOutsideCallback = useCallback(() => setFocusedChoice(null), [])

  useOutsideClick(`[${DATA_CLICK_AWAY_ID}]`, clickOutsideCallback)

  const {
    selected: selectedChoices,
    onSelect,
    responseDeclaration,
    isValid,
    disabled,
  } = useSelectableChoice(responseDeclarationId, InteractionType.choice, { minChoices, maxChoices })
  const { correctResponse, groupMode, groupStatistics } = useAssessmentItemResponseScoring(
    currentItemResponse?.itemId,
    responseDeclarationId,
  )

  const allowMultiple = responseDeclaration?.cardinality === Cardinality.multiple
  const withLetterIndex = !allowMultiple || !!startLetter

  const childrenWithProps = getChildrenWithInjectedProps(
    DomNodes.simpleChoice,
    children,
    {
      minChoices,
      maxChoices,
      responseDeclaration,
      onSelect,
      selectedChoices: selectedChoices || [],
      allowMultiple,
      isValid,
      disabled,
    },
    { withLetterIndex, startLetter },
  )

  const [[prompt], simpleChoice] = useFindNodes(childrenWithProps as JSX.Element[], [
    DomNodes.prompt,
    DomNodes.simpleChoice,
  ])

  const padLength = Math.max(...Object.values(groupStatistics)) === 1 ? 4 : 3

  const feedbackPercentageFormatter = useCallback(
    (percentage: number) => percentageFormatter.format(percentage).padEnd(padLength, ' '),
    [padLength],
  )

  return (
    <>
      {currentItemResponse && responseDeclaration && (
        <Wrapper {...accessibilityAttr}>
          {prompt}
          {allowMultiple && maxChoices > 1 && (
            <ConstraintsAlertContainer
              minChoices={minChoices}
              maxChoices={maxChoices}
              selectedCount={selectedChoices?.length}
            />
          )}
          <CheckboxesWrapper role='group' aria-label='List of answers'>
            {simpleChoice.map(({ props, key }) => (
              <SimpleChoiceComponent
                setFocusedChoice={setFocusedChoice}
                isFocused={props.identifier === focusedChoice}
                isCorrect={correctResponse?.includes(props.identifier)}
                groupMode={groupMode}
                feedbackPercentage={groupStatistics[props.identifier] ?? 0}
                feedbackFormatter={feedbackPercentageFormatter}
                {...props}
                key={key}
              >
                {props.children}
              </SimpleChoiceComponent>
            ))}
            {groupMode && (
              <SimpleChoiceComponent
                itemId={props.itemId}
                blankBubble
                groupMode
                feedbackPercentage={groupStatistics['none']}
                feedbackFormatter={feedbackPercentageFormatter}
              >
                <>No Answer Selected</>
              </SimpleChoiceComponent>
            )}
          </CheckboxesWrapper>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled(Box)(
  () => `
  & hr {
    display: none;
  }
`,
)

const CheckboxesWrapper = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  gap: theme.spacing(1),
}))
