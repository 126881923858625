import { FC } from 'react'
import { Box, BoxProps, styled } from '@mui/material'
import { useDroppable } from '@dnd-kit/core'
import { GapImgComponent, GapImgComponentProps } from '../../GapImg'
import { DraggableGapImg } from '../../DraggableGapImg'

interface GapImagesRowProps extends BoxProps {
  id: string
  rowItems?: { desktop: number, mobile: number }
}
export const GapImagesRow: FC<GapImagesRowProps> = ({ id, children, ...props }) => {
  const { setNodeRef } = useDroppable({ id })
  return (
    <GapImagesRowStyled ref={setNodeRef} {...props}>
      {children}
    </GapImagesRowStyled>
  )
}

const GapImagesRowStyled = styled(({ gap, rowItems, ...props }: BoxProps & { rowItems?: { desktop: number, mobile: number } }) => (
  <Box {...props} />
))(
  ({ theme, gap = 2, rowItems = { desktop: 5, mobile: 3 } }) => `
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.spacing()};
  padding: ${theme.spacing(2)};
  border: 1px solid ${theme.palette.grey[200]};
  border-radius: ${theme.shape.borderRadius}px;
  gap: ${theme.spacing(Number(gap))};

  & ${StyledGapImageWrapper} {
    ${[theme.breakpoints.down('mobile')]} {
      width: calc((100% - ${theme.spacing(Number(gap))} * (${rowItems.mobile - 1})) / ${rowItems.mobile});
    }
    width: calc((100% - ${theme.spacing(Number(gap))} * (${rowItems.desktop - 1})) / ${rowItems.desktop});
  }
`,
)

export const GapImageWrapper: FC<any> = props => {
  const { id, disabled, matchCount, selected, onClick, gapImage, ...rest } = props

  return (
    <StyledGapImageWrapper>
      <BackgroundGapImage disabled placeholder={disabled} {...gapImage.props} />
      {!disabled && (
        <DraggableGapImg
          id={id}
          matchCount={matchCount}
          selected={selected}
          onClick={onClick}
          {...rest}
          {...gapImage.props}
        />
      )}
    </StyledGapImageWrapper>
  )
}

const BackgroundGapImage = styled(({ ...props }: GapImgComponentProps & { placeholder?: boolean }) => (
  <GapImgComponent {...props} />
))(({ placeholder }) => ({
  position: placeholder ? 'static' : 'absolute',
  width: '100%',
}))

const StyledGapImageWrapper = styled(Box)(() => ({
  position: 'relative',
}))
