import { FREE_VARIANTS_CONTAINER_ID, initialClickMovingState } from '@app/constants'
import { CurrentTestState, ScoringState } from '@app/storage'
import {
  ChangeCountingVariantsType,
  DropContainerScoring,
  DropContainerState,
  IClickMovingState,
  IUseClickMoving,
  InteractionType,
  SetContainerDropElementType,
} from '@app/types'
import { Announcements, DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSnapshot } from 'valtio'
import {
  cutUniqPartId,
  getInitialContainers,
  getInitialCountingVariants,
  getScoredContainers,
  logicTransferingVariantToContainer,
} from '../utils'
import { useCountingVariants, useUpdatingRef } from './common'
import { useReactiveItemResponse, useResponseUpdate, useResponseValidityUpdate } from './itemResponses'
import { useAssessmentItemResponseScoring } from './assessmentScoring'

export type IUseDragDropMatch = {
  activeId: string
  containers: DropContainerState
  scoredContainers: DropContainerScoring
  isFinished: boolean
  isMaxMatches: boolean
  clickMovingState: IClickMovingState
  handleDragStart: (e: DragStartEvent) => void
  handleDragEnd: (e: DragEndEvent) => void
  handleDragOver: (e: DragOverEvent) => void
  handleRemove: (itemId: string, containerId: string) => () => void
  isDisabledVariant: (id: string) => boolean
  resetDrag: () => void
  groupMode: boolean
  groupStatistics: Record<string, number>
}

/**
 * Hook for control drag&drop and containers states.
 * @param itemId Current item id.
 * @param initialCountingVariants JSX.Element array with answer variants.
 * @param dropContainers String array with ids of drop containers.
 * @param minAssociations Minimal associations.
 * @param maxAssociations Maximum associations.
 * @param interactionType Type of interaction.
 * @returns array with controls drag&drop and states of containers.
 */

export const useDragDropMatch = (
  responseIdentifier: string,
  variants: JSX.Element[],
  dropContainers: string[],
  minAssociations: number,
  maxAssociations: number,
  interactionType: InteractionType,
  containersToResponse?: (response: DropContainerState) => string[],
  responseToContainers?: (response: string[]) => string[],
): IUseDragDropMatch => {
  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)
  const { groupMode } = useSnapshot(ScoringState)
  const itemResponse = useReactiveItemResponse(responseIdentifier)
  const scoring = useAssessmentItemResponseScoring(currentItemResponse?.itemId, responseIdentifier)

  const [initialCountingVariants, setInitialCountingVariants] = useState(null)

  // Drag variants and drop containers states
  const [activeId, setActiveId] = useState(null)
  const [startContainer, setStartContainer] = useState<string>(null)
  const [countingVariants, changeCountingVariants] = useCountingVariants(
    currentItemResponse?.id,
    initialCountingVariants,
  )

  const [containers, setContainers] = useState<DropContainerState>(null)
  const isValid = useResponseValidityUpdate(
    currentItemResponse?.id,
    containersToResponse(containers)?.length || 0,
    minAssociations,
    maxAssociations,
  )

  useResponseUpdate(
    currentItemResponse?.id,
    responseIdentifier,
    containers,
    interactionType,
    containersToResponse,
    isValid,
  )

  useEffect(() => {
    if (!itemResponse) return
    setInitialCountingVariants(getInitialCountingVariants(variants, itemResponse))
    setContainers(
      getInitialContainers(
        dropContainers,
        responseToContainers ? responseToContainers(itemResponse) : itemResponse,
      ),
    )
    // eslint-disable-next-line
  }, [itemResponse])

  // Function for change states drag variants and drop containers
  const setContainerDropElement =
    (type: SetContainerDropElementType) => (containerId: string | null, variantId?: string | null) => {
      setContainers(state =>
        containerId
          ? {
              ...state,
              [containerId]: type === SetContainerDropElementType.add ? variantId : null,
            }
          : state,
      )
    }

  const filterResponse = (response: string[]): string[] => {
    return response.filter(res => res && res.split(' ').every(item => item.trim()))
  }

  const isDisabledVariant = (id: string) => {
    if (!countingVariants) return false

    return (
      (!countingVariants[id]?.matchCount && !!countingVariants[id]?.matchMax) || currentTestPart.isFinished
    )
  }

  const { clickMovingState, setClickMovingState, resetClickMovingState } = useClickMoving(
    setContainerDropElement,
    changeCountingVariants,
    containers,
    currentTestPart.isFinished,
    isDisabledVariant,
    activeId,
  )

  const isMaxMatches = useMemo(() => {
    return (
      maxAssociations !== 0 && maxAssociations === filterResponse(containersToResponse(containers)).length
    )
  }, [maxAssociations, containersToResponse, containers])

  // Reset state on response ID change
  const dropContainersRef = useUpdatingRef(dropContainers)
  const responseRef = useUpdatingRef(itemResponse)

  useEffect(() => {
    setActiveId(null)
    setStartContainer(null)
    setContainers(
      getInitialContainers(
        dropContainersRef.current,
        responseToContainers ? responseToContainers(responseRef.current) : responseRef.current,
      ),
    )
  }, [currentItemResponse?.id, dropContainersRef, responseRef, responseToContainers])

  // Handles for drag&drop control
  const handleDragStart = (e: DragStartEvent) => {
    // Setup start container on drag start.
    setStartContainer(e.active.data.current?.containerId ?? FREE_VARIANTS_CONTAINER_ID)
    setClickMovingState({
      activeBlockId: cutUniqPartId(e.active.id as string),
      activeGapId: null,
      activeGapBlockId: null,
    })
    setActiveId(e.active.id)
  }

  const handleDragEnd = (e: DragEndEvent) => {
    const dropConatainerId = e.over && `${e.over.id}`
    const draggbleVariantId = e.active && cutUniqPartId(`${e.active.id}`)

    logicTransferingVariantToContainer(
      dropConatainerId,
      draggbleVariantId,
      startContainer,
      containers,
      changeCountingVariants,
      setContainerDropElement,
      setClickMovingState,
    )

    setActiveId(null)
    setStartContainer(null)
  }

  const resetDrag = useCallback(() => {
    setActiveId(null)
    resetClickMovingState()
  }, [resetClickMovingState])

  const handleDragOver = (e: DragOverEvent) => {}

  const handleRemove = (itemId: string, containerId: string) => () => {
    changeCountingVariants(ChangeCountingVariantsType.add)([itemId])
    setContainerDropElement(SetContainerDropElementType.reset)(containerId)
  }

  useEffect(() => {
    window.addEventListener('resize', resetDrag)
    return () => window.removeEventListener('resize', resetDrag)
  }, [resetDrag])

  const scoredContainers = getScoredContainers(containers, groupMode, scoring.correctResponse)

  return {
    activeId,
    containers,
    scoredContainers,
    isFinished: currentTestPart.isFinished,
    isMaxMatches,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleRemove,
    isDisabledVariant,
    clickMovingState,
    resetDrag,
    groupMode,
    groupStatistics: scoring.groupStatistics,
  }
}

export const useClickMoving = (
  setContainerDropElement?: (
    type: SetContainerDropElementType,
  ) => (containerId: string | null, variantId?: string | null) => void,
  changeCountingVariants?: (type: ChangeCountingVariantsType) => (variantId: string[] | null) => void,
  containers?: { [p: string]: string },
  disabled?: boolean,
  iDisabledVariant?: (id: string) => boolean,
  activeId?: string,
): IUseClickMoving => {
  const [clickMovingState, setClickMovingState] = useState<IClickMovingState>(initialClickMovingState)

  const resetClickMovingState = useCallback(() => {
    setClickMovingState(initialClickMovingState)
  }, [])

  const updateVariants = useCallback(
    (dropConatainerId: string, draggbleVariantId: string, startContainer: string) =>
      logicTransferingVariantToContainer(
        dropConatainerId,
        draggbleVariantId,
        startContainer,
        containers,
        changeCountingVariants,
        setContainerDropElement,
        setClickMovingState,
      ),
    [changeCountingVariants, containers, setContainerDropElement],
  )

  const handleClick = useCallback(
    ({ target }: any) => {
      // Do nothing if test is finished.
      if (disabled) return

      const variantsContainer = target && target.closest('[data-variants]')
      const variantTarget = target && target?.closest('[data-identifier]')
      const gapTarget = target && target?.closest('[data-gap]')
      const gapHasChildren = gapTarget && Boolean(gapTarget.children.length)

      if (activeId) return

      if (
        variantsContainer &&
        variantTarget &&
        iDisabledVariant(cutUniqPartId(variantTarget.dataset.identifier))
      ) {
        setClickMovingState(initialClickMovingState)
        return
      }
      if (variantTarget && variantsContainer && !gapTarget) {
        setClickMovingState({
          activeBlockId: cutUniqPartId(variantTarget.dataset.identifier),
          activeGapId: null,
          activeGapBlockId: null,
        })
      } else if (clickMovingState.activeBlockId && gapTarget && !gapHasChildren) {
        // click to EMPTY gap from INITIAL container
        updateVariants(gapTarget.dataset.gap, clickMovingState.activeBlockId, FREE_VARIANTS_CONTAINER_ID)
      } else if (clickMovingState.activeGapBlockId && gapTarget && !gapHasChildren) {
        // click to EMPTY gap from FILL gap container
        updateVariants(gapTarget.dataset.gap, clickMovingState.activeGapBlockId, clickMovingState.activeGapId)
      } else if (gapTarget && gapHasChildren && clickMovingState.activeBlockId) {
        // click to FILL gap from INITIAL container
        updateVariants(gapTarget.dataset.gap, clickMovingState.activeBlockId, FREE_VARIANTS_CONTAINER_ID)
      } else if (clickMovingState.activeGapBlockId && gapTarget && gapHasChildren) {
        // vice verse FILL gaps
        updateVariants(gapTarget.dataset.gap, clickMovingState.activeGapBlockId, clickMovingState.activeGapId)
      } else if (
        gapTarget &&
        gapHasChildren &&
        !clickMovingState.activeBlockId &&
        !clickMovingState.activeGapBlockId
      ) {
        // select FILL gap block if INITIAL or other block not selected
        setClickMovingState(prevState => ({
          ...prevState,
          activeGapBlockId: cutUniqPartId(variantTarget.dataset.identifier),
          activeBlockId: null,
          activeGapId: gapTarget.dataset.gap,
        }))
      } else {
        setClickMovingState(initialClickMovingState)
      }
    },
    [
      clickMovingState.activeBlockId,
      clickMovingState.activeGapId,
      clickMovingState.activeGapBlockId,
      iDisabledVariant,
      updateVariants,
      activeId,
      disabled,
    ],
  )

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [handleClick])

  return { clickMovingState, setClickMovingState, resetClickMovingState }
}

type AnnouncementOptions = {
  activeBlocks?: { [key: string]: string }
  overBlocks?: { [key: string]: string }
  ordering?: boolean
  specificNaming?: Array<[string, string]>
  maxDrops?: number
  minDrops?: number
  currentDrops?: number
}

export const useDragDropAnnouncement = (props: AnnouncementOptions) => {
  const {
    activeBlocks,
    overBlocks,
    ordering = false,
    specificNaming,
    maxDrops = null,
    minDrops = null,
    currentDrops = 0,
  } = props
  const [announcements, setAnnouncements] = useState<Announcements>(null)

  const getTextForDrag = useCallback(
    (id: string, blocks: { [key: string]: string }, ordering = false) => {
      if (ordering && id) return Number(id) + 1
      if (specificNaming?.length) {
        const nameArray = specificNaming.find(item => item[0] === id)
        if (nameArray) {
          return nameArray[1]
        }
      }
      const elementId = cutUniqPartId(id)
      if (blocks) {
        return blocks[elementId] ? blocks[elementId] : elementId
      }
      return elementId
    },
    [specificNaming],
  )

  const getContaintsAnnouncement = useCallback(() => {
    if (minDrops && currentDrops + 1 < minDrops) {
      return `You have to drop at least ${minDrops} ${
        minDrops === 1 ? 'object' : 'objects'
      }. Currently you have ${currentDrops + 1}`
    }

    if (maxDrops === currentDrops + 1) {
      return `You have dropped ${currentDrops + 1} of ${maxDrops} possible objects.`
    }
  }, [maxDrops, minDrops, currentDrops])

  const getDragDropAnnouncements = useCallback((): Announcements => {
    return {
      onDragStart({ active }) {
        return `Picked up draggable item ${getTextForDrag(active.id.toString(), activeBlocks, ordering)}.`
      },
      onDragOver({ active, over }) {
        if (over) {
          return `Draggable item ${getTextForDrag(
            active.id.toString(),
            activeBlocks,
            ordering,
          )} was moved over droppable area ${getTextForDrag(over['id'].toString(), overBlocks)}.`
        }

        return `Draggable item ${getTextForDrag(
          active.id.toString(),
          activeBlocks,
          ordering,
        )} is no longer over a droppable area.`
      },
      onDragEnd({ active, over }) {
        if (over) {
          return `Draggable item ${getTextForDrag(
            active.id.toString(),
            activeBlocks,
            ordering,
          )} was dropped over droppable area ${getTextForDrag(over['id'].toString(), overBlocks)}. ${
            getContaintsAnnouncement() || ''
          }`
        }

        return `
          Draggable item ${getTextForDrag(active.id.toString(), activeBlocks, ordering)} was dropped. ${
          getContaintsAnnouncement() || ''
        }
        `
      },
      onDragCancel({ active }) {
        return `Dragging was cancelled. Draggable item ${getTextForDrag(
          active.id.toString(),
          activeBlocks,
          ordering,
        )} was dropped.`
      },
    }
  }, [getTextForDrag, getContaintsAnnouncement, activeBlocks, ordering, overBlocks])

  useEffect(() => {
    setAnnouncements(getDragDropAnnouncements())
  }, [getDragDropAnnouncements])

  return announcements
}
