import { FC } from 'react'
import { Box, BoxProps, styled } from '@mui/material'
import { GraphType, GraphTypeSelectOption } from '../types'
import { onKeyboardSubmit, upperFirst } from '@app/helpers'
import { focusStyle } from '@app/theme'

export interface GraphTypeSelectProps {
  type: GraphType
  options: GraphTypeSelectOption[]
  onSelect: (type: GraphType) => void
  className?: string
}

export const GraphTypeSelect: FC<GraphTypeSelectProps> = props => {
  const { type, options, onSelect, className } = props

  return (
    <Select className={className} aria-label='Graph type select'>
      {options.map(({ value, label }) => {
        return (
          <SelectItem
            key={value}
            onClick={() => onSelect(value as GraphType)}
            selected={value === type}
            aria-label={label || value}
          >
            {label || upperFirst(value)}
          </SelectItem>
        )
      })}
    </Select>
  )
}

const Select = styled((props: BoxProps) => <Box role='radiogroup' {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  [theme.breakpoints.up('mobile')]: {
    flexDirection: 'column',
    width: theme.spacing(30),
    flexShrink: 0,
  },
}))

const SelectItemBase = ({ selected, onClick, ...props }: BoxProps & { selected?: boolean }) => {
  return (
    <Box
      {...props}
      onClick={onClick}
      onKeyDown={onKeyboardSubmit(onClick)}
      role='radio'
      aria-checked={selected}
      tabIndex={0}
    />
  )
}

const SelectItem = styled(SelectItemBase)(({ theme, selected }) => ({
  cursor: 'pointer',
  textAlign: 'center',
  border: `1px solid ${theme.palette.blue[400]}`,
  lineHeight: '1.063rem',
  borderWidth: selected ? '2px' : '1px',
  fontSize: theme.typography.caption.fontSize,
  fontWeight: 500,
  padding: theme.spacing(2),
  backgroundColor: selected ? theme.palette.blue[100] : 'inherit',
  color: selected ? theme.palette.blue[900] : theme.palette.grey[700],
  marginLeft: '-1px',
  wordWrap: 'break-word',
  hyphens: 'auto',

  ':first-child': {
    borderTopLeftRadius: theme.shape.borderRadius * 1.5 + 'px',
    borderBottomLeftRadius: theme.shape.borderRadius * 1.5 + 'px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginLeft: 0,
  },
  ':last-child': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: theme.shape.borderRadius * 1.5 + 'px',
    borderBottomRightRadius: theme.shape.borderRadius * 1.5 + 'px',
  },

  ':hover': {
    backgroundColor: theme.palette.blue[50],
  },
  ':focus': {
    ...focusStyle(theme, selected ? 2 : 1).border,
  },

  [theme.breakpoints.up('mobile')]: {
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(3),
    margin: 0,
    marginTop: '-1px',

    ':first-child': {
      borderTopLeftRadius: theme.shape.borderRadius * 1.5 + 'px',
      borderTopRightRadius: theme.shape.borderRadius * 1.5 + 'px',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      marginTop: 0,
    },
    ':last-child': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: theme.shape.borderRadius * 1.5 + 'px',
      borderBottomRightRadius: theme.shape.borderRadius * 1.5 + 'px',
    },
  },
}))
