export const quadraticFn =
  (vertex: DOMPoint, point: DOMPoint) =>
  (x: number): number => {
    if (!vertex || !point) return x

    const a = getQuadraticCoefficient(vertex, point)
    return a * Math.pow(x - vertex.x, 2) + vertex.y
  }

export const getQuadraticCoefficient = (vertex: DOMPoint, point: DOMPoint): number => {
  if (!vertex || !point) return 1

  return (point.y - vertex.y) / Math.pow(point.x - vertex.x, 2)
}

export const getLinearCoefficient = (point1: DOMPoint, point2: DOMPoint): number =>
  (point2.y - point1.y) / (point2.x - point1.x)

export const linearFn =
  (point1: DOMPoint, point2: DOMPoint) =>
  (x: number): number => {
    if (!point1 || !point2) return x

    const k = (point2.y - point1.y) / (point2.x - point1.x)

    // It means that "x" is constant and "y" does not depend on "x".
    if (k === Infinity || k === -Infinity) {
      return Infinity
    }

    const b = point1.y - k * point1.x
    return k * x + b
  }

export const exponentialFn =
  (point1: DOMPoint, point2: DOMPoint, asymptote: number = 0) =>
  (x: number): number => {
    if (!point1 || !point2) return x

    const b = Math.pow((point2.y - asymptote) / (point1.y - asymptote), 1 / (point2.x - point1.x))
    const a = (point1.y - asymptote) / Math.pow(b, point1.x)
    return a * Math.pow(b, x) + asymptote
  }

export const constantFn = (point: DOMPoint, axis: 'x' | 'y') => (): number => point[axis]
