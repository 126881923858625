import { FC, useMemo, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'
import { Box, styled } from '@mui/material'
import { DndContext, KeyboardSensor, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { CurrentTestState } from '@app/storage'
import { DATA_CLICK_AWAY_ID, DomNodes } from '@app/constants'
import {
  useFindNodes,
  transformChoicesConstraints,
  useTestPartFinishedCheck,
  useOutsideClick,
  useResponseValidityUpdate,
  useDragDropAnnouncement,
} from '@app/helpers'
import { ConstraintsAlertContainer, ScreenReaderInfo } from '@app/components'
import { HotspotArea, StyledHotspotAreaOverlay } from '../HotspotArea'
import { DroppableArea } from '../PositionObjectStage/components'
import { POINT_SEPARATOR, useDropPoint, usePointSize, useSelectPoints } from './hooks'
import { DRAGGABLE_CROSSHAIR_ID, SelectPoint, SelectPointRow } from './components'

interface SelectPointInteractionProps {
  itemId: string
  responseidentifier?: string
  children: JSX.Element | JSX.Element[]
  minchoices?: string
  maxchoices?: string
  accessibilityAttr?: { [key: string]: string }
}

const SVG_PROPS = { id: 'select_area' }
const DROPPABLE_ID = 'droppable'
const ACTIVE_BLOCKS_ANNOUNCEMENTS = { [DRAGGABLE_CROSSHAIR_ID]: 'crosshair' }

export const SelectPointInteraction: FC<SelectPointInteractionProps> = props => {
  const { responseidentifier, children, minchoices, maxchoices, accessibilityAttr } = props
  const [activePoint, setActivePoint] = useState<string>(null)

  const [minChoices, maxChoices] = transformChoicesConstraints(minchoices, maxchoices)
  const [[prompt], [image]] = useFindNodes(children, [DomNodes.prompt, DomNodes.object])

  const { currentItemResponse } = useSnapshot(CurrentTestState)
  const { points, onSetPoint, onRemovePoint, announcement } = useSelectPoints(responseidentifier, {
    minChoices,
    maxChoices,
  })
  const allSelected = points.length === maxChoices
  const isValid = useResponseValidityUpdate(currentItemResponse?.id, points.length, minChoices, maxChoices)

  const pointSize = usePointSize(responseidentifier)
  const isFinished = useTestPartFinishedCheck()

  const overBlocksAnnouncements = useMemo(
    () => ({
      [DROPPABLE_ID]: image?.props?.accessibilityAttr
        ? image.props.accessibilityAttr['aria-label']
        : 'droppable area',
    }),
    [image?.props?.accessibilityAttr],
  )

  const announcements = useDragDropAnnouncement({
    activeBlocks: ACTIVE_BLOCKS_ANNOUNCEMENTS,
    overBlocks: overBlocksAnnouncements,
    maxDrops: maxChoices,
    minDrops: minChoices,
    currentDrops: points.length,
  })

  useOutsideClick(`[${DATA_CLICK_AWAY_ID}]`, () => setActivePoint(null))

  const containerRef = useRef<HTMLDivElement>(null)
  const svgRef = useRef<any>(null)

  const { dragging, snapModifier, onDragStart, onDragEnd, onDragCancel } = useDropPoint(
    containerRef,
    svgRef,
    DROPPABLE_ID,
    onSetPoint,
  )

  const onSelectPoint: React.MouseEventHandler<SVGSVGElement> = event => {
    if (isFinished) return
    if (dragging) return

    const target = event.target as SVGGraphicsElement

    if (target.id !== SVG_PROPS.id) {
      return
    }

    const domPoint = new DOMPoint(event.clientX, event.clientY)
    const coords = domPoint.matrixTransform(target.getScreenCTM().inverse())
    const point = [coords.x.toFixed(0), coords.y.toFixed(0)].join(POINT_SEPARATOR)

    onSetPoint(point)
  }

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 15 } }),
    useSensor(KeyboardSensor),
  )

  return (
    <Box {...accessibilityAttr}>
      {prompt}

      <ConstraintsAlertContainer
        minChoices={minChoices}
        maxChoices={maxChoices}
        selectedCount={points.length}
        noAnnouncement
      />

      <DndContext
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragCancel={onDragCancel}
        modifiers={[snapModifier]}
        sensors={sensors}
        accessibility={{ announcements }}
      >
        <Container ref={containerRef}>
          <HotspotArea
            image={image}
            onClick={onSelectPoint}
            svgProps={SVG_PROPS}
            svgRef={svgRef}
            overlay={
              <StyledHotspotAreaOverlay>
                <DroppableArea id={DROPPABLE_ID} />
              </StyledHotspotAreaOverlay>
            }
          >
            {points.map(point => {
              return (
                <SelectPoint
                  key={point}
                  selected={activePoint === point}
                  onClick={() => setActivePoint(point)}
                  assigned={allSelected}
                  point={point}
                  size={pointSize}
                  removable={!isFinished}
                  onRemove={onRemovePoint(point)}
                />
              )
            })}
          </HotspotArea>

          <StyledSelectPointRow isValid={isValid} maxSelected={allSelected} isDragging={dragging} />
        </Container>

        <ScreenReaderInfo ariaAtomic ariaLive='polite'>
          {announcement}
        </ScreenReaderInfo>
      </DndContext>
    </Box>
  )
}

const Container = styled(Box)(() => ({
  flexGrow: 1,
  position: 'relative',
}))

const StyledSelectPointRow = styled(SelectPointRow)(({ theme }) => ({
  marginTop: theme.spacing(2),

  /* hide on touchscreens or small devices. */
  [`${theme.breakpoints.down('mobile')}, (hover: none)`]: {
    display: 'none',
  },
}))
