import { AssessmentSettings, getAssessmentById, getPackageByPath } from '@app/firebase/api'
import {
  CurrentTimerState,
  CurrentUserAssessmentState,
  setCurrentQtiPackageItems,
  setCurrentQtiPackageSettings,
  setCurrentQtiPackageStylesheets,
  setCurrentQtiPackageTests,
  setExtractedImages,
  setUserAssessmentPassedTime,
} from '@app/storage'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { extractQtiPackageData } from '../builders'
import { initQTIPackagesDB, QtiPackageDBActions, QtiPackageDBType } from '../indexedDB'
import { usePassedTimeRef } from './userAssessment'

export const useSetCurrentPackage = (): boolean => {
  const { remainedTime } = useSnapshot(CurrentTimerState)
  const { status, assessmentId, version, userAssessmentId } = useSnapshot(CurrentUserAssessmentState)
  const [qtiDBActions, setQtiDBActions] = useState<QtiPackageDBActions>(null)
  const [existingPackage, setExistingPackage] = useState<QtiPackageDBType>(null)
  const [indexedDBPackageId, setIndexedDBPackageId] = useState('')
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(true)

  const passedTimeRef = usePassedTimeRef()

  useEffect(() => {
    const initDBActions = async () => {
      const iDBPackageId = userAssessmentId.slice(0, userAssessmentId.lastIndexOf('_'))
      const packagesDBActions = await initQTIPackagesDB()
      const existingPackage = await packagesDBActions.get(iDBPackageId)

      setIndexedDBPackageId(iDBPackageId)
      setQtiDBActions(packagesDBActions)
      if (existingPackage) {
        setExistingPackage(existingPackage)
      }
    }

    if (!userAssessmentId) {
      return
    }

    initDBActions()
  }, [userAssessmentId])

  useEffect(() => {
    const setCurrentPackage = async () => {
      if (!CurrentUserAssessmentState.assessmentId) {
        return
      }

      const extractPackage = async () => {
        const { file: assessment, settings } = await getPackageWithSettings(assessmentId)

        const { extractedImages, extractedItems, extractedTests, extractedStylesheets } =
          await extractQtiPackageData(assessment)

        await qtiDBActions.put(indexedDBPackageId, {
          version,
          id: indexedDBPackageId,
          tests: extractedTests,
          items: extractedItems,
          stylesheets: extractedStylesheets,
          images: extractedImages,
          passedTime: 0,
          settings,
          status: CurrentUserAssessmentState.status,
        })

        setSaved(true)
        setLoading(false)
      }

      if (!existingPackage) {
        extractPackage()
        return
      }

      if (version && existingPackage?.version !== version) {
        extractPackage()
        return
      }

      setExtractedImages(existingPackage.images)
      setCurrentQtiPackageItems(existingPackage.items)
      setCurrentQtiPackageTests(existingPackage.tests)
      setCurrentQtiPackageStylesheets(existingPackage.stylesheets)
      setCurrentQtiPackageSettings(existingPackage.settings)
      if (existingPackage.settings.isResumable) {
        setUserAssessmentPassedTime(existingPackage.passedTime)
      }

      setLoading(false)
      setSaved(true)
    }

    if (!qtiDBActions || !indexedDBPackageId) {
      return
    }

    setCurrentPackage()
  }, [version, assessmentId, qtiDBActions, indexedDBPackageId, existingPackage])

  useEffect(() => {
    if (!remainedTime || !passedTimeRef || !qtiDBActions || !saved) {
      return
    }

    const updatePackage = async () => {
      qtiDBActions.patch(indexedDBPackageId, {
        passedTime: passedTimeRef.current,
        status,
      })
    }

    updatePackage()
  }, [remainedTime, qtiDBActions, passedTimeRef, indexedDBPackageId, status, saved])

  const getPackageWithSettings = async (
    id: string,
  ): Promise<{ file: File; settings: AssessmentSettings }> => {
    const assessment = await getAssessmentById(id)
    const file = await getPackageByPath(assessment.path, assessment.path.split('/').pop())

    setCurrentQtiPackageSettings(assessment.settings)

    return { file, settings: assessment.settings }
  }

  return loading
}
