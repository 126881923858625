import { FC, useMemo } from 'react'
import { AlertColor, styled } from '@mui/material'
import { AlertContainerComponent } from '../AlertContainer'
import { generateAlertContentAndColor } from '@app/helpers'
import { ScreenReaderInfo } from '@app/components'
import { useConstraintsAnnouncement } from './hooks'
import { useSnapshot } from 'valtio'
import { CurrentUserAssessmentState } from '@app/storage'
import { AssessmentStatus } from '@app/firebase/api'

export interface ConstraintsAlertContainerProps {
  minChoices?: number
  maxChoices?: number
  selectedCount: number
  noAnnouncement?: boolean
}

export const ConstraintsAlertContainer: FC<ConstraintsAlertContainerProps> = props => {
  const { minChoices, maxChoices, selectedCount, noAnnouncement } = props

  const { hideInfoBoxes, status } = useSnapshot(CurrentUserAssessmentState)
  const isCompleted = status === AssessmentStatus.completed
  const announcement = useConstraintsAnnouncement(selectedCount, maxChoices, minChoices)

  const [content, severity] = useMemo(
    (): [string, AlertColor] =>
      generateAlertContentAndColor(selectedCount, Number(minChoices), Number(maxChoices)),
    [selectedCount, minChoices, maxChoices],
  )

  return (
    <>
      {!hideInfoBoxes && !isCompleted && (
        <>
          <StyledAlert severity={severity}>{content}</StyledAlert>
          {!noAnnouncement && (
            <ScreenReaderInfo ariaLive={'polite'} ariaAtomic={true} ariaBusy={!announcement}>
              {announcement}
            </ScreenReaderInfo>
          )}
        </>
      )}
    </>
  )
}

const StyledAlert = styled(AlertContainerComponent)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))
