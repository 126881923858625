import React from 'react'
import { AlertContainerComponentProps } from '../AlertContainer'
import { StyledAlert, StyledAlertGroup } from './styles'

export type AlertGroupItem = Pick<AlertContainerComponentProps, 'severity'|'icon'> & {
  key: string
  message?: React.ReactNode
}

export type AlertGroupProps = Pick<AlertContainerComponentProps, 'severity'|'size'|'variant'>
  & React.PropsWithChildren<{
    items?: AlertGroupItem[]
    className?: string
  }>

export const AlertGroup: React.FC<AlertGroupProps> = ({
  severity,
  size,
  variant,
  items = [],
  children,
  className,
}) => {
  return (
    <StyledAlertGroup severity={severity} variant={variant} className={className}>
      {items.map(({ key, severity: itemSeverity, icon, message }) => (
         <StyledAlert
          key={key}
          variant={variant}
          size={size}
          severity={itemSeverity}
          icon={icon}
         >
          {message}
        </StyledAlert>
      ))}
      {children}
    </StyledAlertGroup>
  )
}

export default AlertGroup
