import { User } from '@app/types/user'
import { proxy } from 'valtio'
import { devtools } from 'valtio/utils'

export type UserStateType = {
  user: User
}

const initialState: UserStateType = {
  user: null,
}

export const UserState = proxy<UserStateType>(initialState)

export const setUser = (user: User) => {
  UserState.user = user
}

export const resetUser = () => {
  UserState.user = initialState.user
}

process.env.NODE_ENV === 'development' && devtools(UserState, { enabled: true, name: 'UserState' })
