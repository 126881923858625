import { proxy } from 'valtio'
import { devtools } from 'valtio/utils'
import { ScoringByItem } from '@app/models/scoringByItem'
import { PracticeTestAssessmentKeyItem, ResponsesByPracticeTest } from '@app/types'

export type ReviewStateType = {
  testScoring: ScoringByItem
  groupMode: boolean
  groupTestScoring: ResponsesByPracticeTest
  groupCorrectAnswers: PracticeTestAssessmentKeyItem[]
}

const initialState: ReviewStateType = {
  testScoring: null,
  groupMode: false,
  groupTestScoring: null,
  groupCorrectAnswers: [],
}

export const ScoringState = proxy<ReviewStateType>(initialState)

export const setScoring = (data: ScoringByItem) => {
  ScoringState.testScoring = data
}

export const setGroupMode = (groupMode: boolean) => {
  ScoringState.groupMode = groupMode
}

export const setGroupTestScoring = (
  groupTestScoring: ResponsesByPracticeTest,
  groupCorrectAnswers: PracticeTestAssessmentKeyItem[],
) => {
  ScoringState.groupTestScoring = groupTestScoring
  ScoringState.groupCorrectAnswers = groupCorrectAnswers
}

export const resetReviewState = () => {
  ScoringState.testScoring = initialState.testScoring
  ScoringState.groupMode = initialState.groupMode
  ScoringState.groupTestScoring = initialState.groupTestScoring
  ScoringState.groupCorrectAnswers = initialState.groupCorrectAnswers
}

process.env.NODE_ENV === 'development' && devtools(ScoringState, { enabled: true, name: 'ScoringState' })
