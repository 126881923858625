import { FC, useContext, useEffect, useState } from 'react'
import { AssessmentItemResponse } from '@app/models'
import { useKeyboardNavigation } from '@app/helpers'
import { NavigationBubble, NavigationBubbleProps, QuestionContextMenuContext } from '@app/components'
import { useSnapshot } from 'valtio'
import { CurrentTestState, ScoringState, getCurrentResponseItemIndexById } from '@app/storage'
import { NAVIGATION_DESCRIBEDBY_ID } from '@app/constants'

interface ItemListProps {
  onClick: (id: string) => () => void
  updateScrollPosition?: (el: HTMLElement, index: number) => void
  openSubmitModal: boolean
  isLinearMode: boolean
  isOpenModal?: boolean
  closeModal?: () => void
  navigationBubbleProps?: Partial<Pick<NavigationBubbleProps, 'size' | 'variant' | 'dense'>>
  itemsScoring?: boolean[]
}

const ItemList: FC<ItemListProps> = props => {
  const {
    onClick,
    openSubmitModal,
    isLinearMode,
    isOpenModal,
    closeModal,
    updateScrollPosition,
    navigationBubbleProps = {},
    itemsScoring,
  } = props
  const [selectedIndex, setSelectedIndex] = useState<number>(null)
  const { currentItemResponse, itemsResponses } = useSnapshot(CurrentTestState)
  const { groupMode } = useSnapshot(ScoringState)

  const { arrowKeysNavigation, currentIndex, bubblesRef } = useKeyboardNavigation(
    itemsResponses as AssessmentItemResponse[],
    selectedIndex,
    isOpenModal,
  )

  const { markedItems } = useContext(QuestionContextMenuContext)

  useEffect(() => {
    if (!currentItemResponse?.id) {
      return
    }

    setSelectedIndex(getCurrentResponseItemIndexById(currentItemResponse?.id))
  }, [currentItemResponse?.id])

  useEffect(() => {
    if (!updateScrollPosition || currentIndex === null) {
      return
    }

    updateScrollPosition(bubblesRef.current[currentIndex], currentIndex)
  }, [currentIndex, updateScrollPosition, bubblesRef])

  return (
    <>
      {currentItemResponse.id &&
        itemsResponses &&
        itemsResponses.map((item, index) => (
          <NavigationBubble
            number={index + 1}
            key={item.id}
            arrowKeysNavigation={arrowKeysNavigation}
            ref={ref => (bubblesRef.current[index] = ref)}
            focused={currentIndex === index}
            aria-label={'Question ' + (index + 1)}
            onClick={onClick(item.id)}
            aria-describedby={NAVIGATION_DESCRIBEDBY_ID}
            selected={currentItemResponse.id === item.id && !openSubmitModal}
            disabled={isLinearMode && !(currentItemResponse.id === item.id)}
            answered={groupMode ? false : item.answered}
            viewed={groupMode ? false : item.viewed}
            groupMode={groupMode}
            closeModal={closeModal}
            markedForReview={markedItems.includes(item.itemId)}
            correct={itemsScoring?.[index]}
            {...navigationBubbleProps}
          />
        ))}
    </>
  )
}

export default ItemList
