import { Box, styled, Typography } from '@mui/material'
import { FC } from 'react'

interface CustomEditorFooterProps {
  textLength: number
  maxTextLength: number
}

export const CustomEditorFooter: FC<CustomEditorFooterProps> = ({ textLength, maxTextLength }) => (
  <EditorFooter>
    <StyledParagraph
      component={'p'}
      variant={'subtitle2'}
    >{`# of Characters: ${textLength}/${maxTextLength}`}</StyledParagraph>
  </EditorFooter>
)

const EditorFooter = styled(Box)(
  ({ theme }) => `
    border-radius: 0 0 ${theme.shape.borderRadius * 1.5}px ${theme.shape.borderRadius * 1.5}px;
    border: 1px solid ${theme.palette.grey[200]};
    border-top: none;
    padding: ${theme.spacing(1)} ${theme.spacing(3)};
`,
)

const StyledParagraph = styled(Typography)<{ component: string }>(
  ({ theme }) => `
    margin: 0 !important;
    text-align: right;
  `,
)
