import { TabsListUnstyled, TabsListUnstyledProps, TabUnstyled, TabUnstyledProps } from '@mui/base'
import { alpha, styled, tabClasses } from '@mui/material'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { PillSize, PillVariant } from './types'
import { focusStyle } from '@app/theme'

const PILL_STYLE_PROPS = ['size', 'variant', 'startIcon', 'endIcon']

const omitPillStyleProps = (prop: string) => !PILL_STYLE_PROPS.includes(prop)

type PillContainerStyleProps = {
  size?: PillSize
  variant?: PillVariant
}

export const PillsContainer = styled(TabsListUnstyled, {
  shouldForwardProp: omitPillStyleProps,
})<TabsListUnstyledProps & PillContainerStyleProps>(({ theme, size = 'md', variant = 'text' }) => ({
  display: 'flex',

  ...(variant !== 'text' && {
    ...(size === 'lg' && {
      gap: theme.spacing(4),
    }),
    ...(size === 'md' && {
      gap: theme.spacing(3),
    }),
    ...(size === 'sm' && {
      gap: theme.spacing(2),
    }),
  }),
}))

export const PillIcon = styled(FontAwesomeIcon)<FontAwesomeIconProps>(() => ({}))

type PillStyleProps = {
  size?: PillSize
  variant?: PillVariant
  startIcon?: boolean
  endIcon?: boolean
}

export const Pill = styled(TabUnstyled, {
  shouldForwardProp: omitPillStyleProps,
})<TabUnstyledProps & PillStyleProps>(
  ({ theme, size = 'md', variant = 'text', startIcon = false, endIcon = false }) => ({
    borderRadius: '500px',
    backgroundColor: 'transparent',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    color: theme.palette.grey[700],
    border: '1px solid transparent',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),

    [`&:not([disabled]):not(.${tabClasses.selected}):hover`]: {
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[50]}`,

      ...(variant === 'filled' && {
        borderColor: theme.palette.grey[100],
        backgroundColor: theme.palette.grey[100],
      }),
    },

    '&:focus': {
      ...focusStyle(theme).border,
    },

    [`&.${tabClasses.disabled}`]: {
      color: alpha(theme.palette.grey[50], 0.7),

      ...(variant === 'outlined' && {
        borderColor: alpha(theme.palette.grey[50], 0.7),
      }),
      ...(variant === 'filled' && {
        color: theme.palette.common.white,
        backgroundColor: alpha(theme.palette.grey[50], 0.7),
      }),
    },

    [`&.${tabClasses.selected}`]: {
      backgroundColor: theme.palette.blue[50],
      border: `1px solid ${theme.palette.blue[50]}`,
      color: theme.palette.blue[900],

      [`${PillIcon}`]: {
        color: theme.palette.blue[500],
      },

      '&:focus': {
        ...focusStyle(theme).border,
      },
    },

    [`${PillIcon}`]: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(13),
      color: theme.palette.grey[300],

      ...(size === 'sm' && {
        fontSize: theme.typography.pxToRem(11),
        lineHeight: theme.typography.pxToRem(12),
      }),
    },

    ...(variant === 'outlined' && {
      borderColor: theme.palette.grey[100],
    }),
    ...(variant === 'filled' && {
      backgroundColor: theme.palette.grey[50],
    }),

    ...(size === 'lg' && {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(19),
      padding: theme.spacing(3, 7),
    }),
    ...(size === 'md' && {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(17),
      padding: theme.spacing(2.5, 6),
    }),
    ...(size === 'sm' && {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      padding: theme.spacing(2, 4),
    }),

    ...(startIcon && {
      ...(size === 'lg' && {
        padding: theme.spacing(3, 5, 3, 4),
      }),
      ...(size === 'md' && {
        padding: theme.spacing(2.5, 4),
      }),
      ...(size === 'sm' && {
        padding: theme.spacing(2, 4, 2, 3),
      }),
    }),

    ...(endIcon && {
      ...(size === 'lg' && {
        padding: theme.spacing(3, 4, 3, 5),
      }),
      ...(size === 'md' && {
        padding: theme.spacing(2.5, 4),
      }),
      ...(size === 'sm' && {
        padding: theme.spacing(2, 3, 2, 4),
      }),
    }),
  }),
)
