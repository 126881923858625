import { gql } from "@apollo/client"

export const GET_FEEDBACK_PROMPT = gql`
  query getFeedbackPrompt($data: GetFeedbackPrompt!) {
    feedback {
      getFeedbackPrompt(data: $data) {
        showPrompt
        promptType
        promptText
        responseId
      }
    }
  }
`
