import { FC } from 'react'
import { styled, Theme } from '@mui/material'
import { Curve, CurveProps } from 'victory'
import { ChartDomain, GraphType, LineStyle } from '../types'
import { getGraphColor, omitOutOfRangeData } from '../helpers'

export interface SafeCurveProps extends CurveProps {
  lineStyle?: LineStyle
  colorIndex?: number
  // Have to omit default theme.
  theme?: Theme
  domain?: ChartDomain
  graphType?: GraphType
}

export const SafeCurve: FC<SafeCurveProps> = props => {
  const { theme, domain, graphType, ...rest } = props

  const id = props.id instanceof Function ? props.id() : props.id
  const isInvalid = props.data.some((datum: any) => isNaN(datum._y))

  let transformedData = props.data

  // Applicable to exponential fn only.
  // Cut off values that are too far out of range.
  if (graphType === GraphType.EXPONENTIAL && domain) {
    transformedData = omitOutOfRangeData(props.data, domain)
  }

  if (isInvalid) return null

  return <StyledCurve {...rest} data={transformedData} pathComponent={<path id={id} />} disableInlineStyles />
}

const StyledCurve = styled(({ lineStyle, colorIndex, ...props }: SafeCurveProps) => <Curve {...props} />)(
  ({ theme, lineStyle, colorIndex = 0 }) => ({
    stroke: getGraphColor(theme, 'A700', colorIndex),
    strokeWidth: '3px',
    strokeDasharray: lineStyle === LineStyle.DASHED ? '6,6' : 'none',
    fill: 'none',
  }),
)
