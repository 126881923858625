export enum MpQtiDataAttributes {
  startLetter = 'data-mp-qti-start-letter',
  twoColumnLayout = 'data-mp-qti-2-column',
}

export interface MpQtiData {
  startLetter?: string
  twoColumnLayout?: string
}

export const getMpQtiDataAttributes = (attributes: Record<string, any>): MpQtiData => {
  if (!attributes) return {}

  return Object.keys(attributes).reduce<MpQtiData>((mpQtiData, attributeName) => {
    const [mpQtiAttribute] =
      Object.entries(MpQtiDataAttributes).find(entry => entry[1] === attributeName) ?? []

    if (mpQtiAttribute) {
      mpQtiData[mpQtiAttribute as keyof MpQtiData] = attributes[attributeName]
    }

    return mpQtiData
  }, {})
}
