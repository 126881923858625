import { useContext, useMemo } from 'react'
import { alpha, Box, styled } from '@mui/material'
import { ResponseDeclaration } from '@app/models'
import { FIELD_CHOICE_ATTRIBUTES, FIELD_CHOICE_CHECKED_ATTRIBUTES } from '@app/constants'
import { onKeyboardSubmit } from '@app/helpers'
import { ReviewIcon } from '@app/components/ui/icons'
import { HotTextScoringContext } from '../HotTextInteraction/HotTextScoringContext'
import { focusStyle } from '@app/theme'
// import { TooltipFeedbackGroupProps } from '@app/components/feedback/TooltipFeedbackGroup'

export interface HotTextComponentProps {
  identifier: string
  templateidentifier?: string
  minChoices?: number
  maxChoices?: number
  responseDeclaration?: ResponseDeclaration
  onSelect: (id: string) => void
  selectedChoices: string[]
  isValid: boolean
  accessibilityAttr?: { [key: string]: string }
}

export const HotTextComponent: React.FC<React.PropsWithChildren<HotTextComponentProps>> = props => {
  const { identifier, children, selectedChoices, onSelect, accessibilityAttr } = props
  const selected = useMemo(
    () => selectedChoices && selectedChoices?.includes(identifier),
    [identifier, selectedChoices],
  )
  const { correctResponses, groupMode } = useContext(HotTextScoringContext)
  const correct = correctResponses?.includes(identifier)
  // const feedbackItems = groupMode
  //   ? getElementFeedbackItems(
  //       createElement('span', { identifier, children }),
  //       groupStatistics,
  //       correctResponses,
  //     )
  //   : undefined

  return (
    <HotText
      identifier={identifier}
      selected={groupMode ? correctResponses?.includes(identifier) : selected}
      onSelect={onSelect}
      correct={correct}
      groupMode={groupMode}
      // feedbackItems={feedbackItems}
      accessibilityAttr={accessibilityAttr}
    >
      {children}
    </HotText>
  )
}

export interface HotTextProps {
  identifier: string
  onSelect: (id: string) => void
  selected?: boolean
  correct?: boolean
  groupMode?: boolean
  // feedbackItems?: TooltipFeedbackGroupProps['items']
  accessibilityAttr?: Record<string, string>
}

export const HotText: React.FC<React.PropsWithChildren<HotTextProps>> = ({
  identifier,
  selected = false,
  onSelect,
  correct,
  groupMode = false,
  // feedbackItems,
  accessibilityAttr,
  children,
}) => {
  return (
    <StyledBox
      role='checkbox'
      tabindex='0'
      aria-checked={selected}
      aria-label={`hot text area "${identifier}"`}
      component={'span'}
      selected={selected}
      onClick={() => onSelect(identifier)}
      onKeyDown={onKeyboardSubmit(() => onSelect(identifier))}
      {...FIELD_CHOICE_ATTRIBUTES}
      {...(selected ? FIELD_CHOICE_CHECKED_ATTRIBUTES : {})}
      {...accessibilityAttr}
    >
      {/* {(correct !== undefined || groupMode) && (
        <StyledIconContainer>
          <ReviewIcon correct={correct} groupMode={groupMode} items={feedbackItems} />
        </StyledIconContainer>
      )} */}
      {correct !== undefined && !groupMode && (
        <StyledIconContainer>
          <ReviewIcon correct={correct} />
        </StyledIconContainer>
      )}
      {children}
    </StyledBox>
  )
}

const StyledIconContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0.5),
  verticalAlign: 'top',
  display: 'inline-flex',
  alignItems: 'center',
}))

interface StyledBoxProps {
  selected: boolean
  tabindex: string
}

const StyledBox = styled(Box)<StyledBoxProps>(({ theme, selected }) => ({
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.blue[400]}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.blue[50], 0.5),
  cursor: 'pointer',

  '&:hover': {
    [theme.breakpoints.up('mobile')]: {
      color: theme.palette.grey[800],
      border: `1px solid ${theme.palette.blue[300]}`,
      backgroundColor: theme.palette.blue[100],
    },
  },

  '&:focus': {
    [theme.breakpoints.up('mobile')]: {
      ...focusStyle(theme).border,
    },
  },

  ...(selected && {
    backgroundColor: theme.palette.blue[600],
    color: theme.palette.common.white,
    borderColor: 'transparent',
  }),
}))
