import { extractHtmlContentFromTag } from "@app/helpers";
import { Feedback } from "@app/types";
import { FC, useMemo } from "react";
import { ContentRenderer } from "../common";

interface TestFeedbackProps {
  feedback: Feedback
}

export const TestFeedbackComponent:FC<TestFeedbackProps> = ({ feedback }) => {
  const testFeedbackContent = useMemo(() => {
    return extractHtmlContentFromTag(feedback.data, 'feedback')
  }, [feedback.data])

  return (
    <ContentRenderer content={testFeedbackContent}/>
  )
}