import React, { cloneElement, ReactElement, useCallback } from "react"
import { Box, Menu, MenuItem, Stack, Typography, menuClasses, paperClasses, styled } from "@mui/material"

interface DropdownMenuOptions {
  label: string
  icon?: ReactElement
  action: () => void
}

export type { DropdownMenuOptions }

interface Props {
  id: string
  anchor: ReactElement
  options?: DropdownMenuOptions[]
  disablePortal?: boolean
  anchorReference?: "anchorEl" | "anchorPosition" | "none"
  anchorPosition?: { left: number; top: number }
  anchorOrigin?: {
    vertical: "bottom" | "center" | "top" | number
    horizontal: "center" | "left" | "right" | number
  }
  transformOrigin?: {
    vertical: "bottom" | "center" | "top" | number
    horizontal: "center" | "left" | "right" | number
  }
  className?: string
  wrapperClassName?: string
  onClick?: () => void
  onClose?: () => void
}

export const DropdownMenu: React.FC<React.PropsWithChildren<Props>> = props => {
  const {
    id,
    anchor,
    options = [],
    className,
    wrapperClassName,
    anchorPosition,
    disablePortal = false,
    anchorReference = "anchorEl",
    anchorOrigin = {
      vertical: "bottom",
      horizontal: "right"
    },
    transformOrigin = {
      vertical: "top",
      horizontal: "right"
    },
    onClick,
    onClose,
    children
  } = props

  const [anchorId, setAnchorId] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorId)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorId(event.currentTarget)
      onClick?.()
    },
    [onClick]
  )

  const handleClose = useCallback(() => {
    setAnchorId(null)
    onClose?.()
  }, [onClose])

  const handleActionAndClose = useCallback(
    (action: () => void) => () => {
      action?.()
      handleClose()
    },
    [handleClose]
  )

  const anchorElement =
    anchor &&
    cloneElement(anchor, {
      id: `${id}-button`,
      "aria-controls": open ? `${id}-menu` : undefined,
      "aria-haspopup": "true",
      "aria-expanded": open ? "true" : undefined,
      onClick: handleClick
    })

  return (
    <Box className={wrapperClassName}>
      {anchorElement}
      <StyledMenu
        disablePortal={disablePortal}
        id={`${id}-menu`}
        className={className}
        open={open}
        anchorEl={anchorId}
        onClose={handleClose}
        anchorPosition={anchorPosition}
        anchorReference={anchorReference}
        anchorOrigin={anchorOrigin as any}
        transformOrigin={transformOrigin as any}
        MenuListProps={{
          "aria-labelledby": `${id}-button`
        }}
      >
        {options.map(({ label, icon, action }, i) => (
          <StyledMenuItem
            key={i}
            disableRipple
            onClick={handleActionAndClose(action)}
          >
            <Stack direction="row" spacing={3} alignItems="center">
              {icon}
              <Typography variant="body2">{label}</Typography>
            </Stack>
          </StyledMenuItem>
        ))}
        {children}
      </StyledMenu>
    </Box>
  )
}

const StyledMenu = styled(Menu)(({ theme: { shadows, spacing }}) => ({
    [`& .${paperClasses.root}`]: {
      minWidth: "160px",
      borderRadius: "2px",
      boxShadow: shadows[6]
    },
    [`& .${menuClasses.list}`]: {
      paddingTop: spacing(1),
      paddingBottom: spacing(1)
    }
}))

const StyledMenuItem = styled(MenuItem)(({ theme: { spacing, palette } }) => ({
  padding: spacing(2, 4),

  "&:hover": {
    backgroundColor: palette.grey[50]
  },

  "& .material-icons": {
    color: palette.grey[500]
  }
}))
