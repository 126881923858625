import { ScreenComponent } from '@app/components/common'
import { FC, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { Helmet } from 'react-helmet'
import {
  CurrentQtiPackageState,
  CurrentTestState,
  CurrentUserAssessmentState,
  resetCurrentQtiPackage,
  resetCurrentTestState,
  resetIdleDetectorState,
  resetSyncIntervalState,
  resetTimerState,
  resetUserAssessmentState,
} from '@app/storage'
import { IconButton, styled, Tooltip } from '@mui/material'
import { AssessmentTest } from '@app/models'
import { AssessmentComponent, LeaveTestModal, Loader } from '@app/components'
import { HOME_PAGE_BTN_ATTRIBUTES } from '@app/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
import {
  useUserAssessentPauseCallback,
  useUserAssessentUpdater,
  useUserAssessmentFetch,
} from '@app/helpers/hooks/userAssessment'
import {
  resetCalculatorData,
  useCurrentAssessmentSetup,
  useIdleDetection,
  useModal,
  useTestCallback,
  useUserAssessmentIdSetup,
} from '@app/helpers'
import { FontSizeControlProvider } from '@app/components'

interface TestScreenProps {}

const TestScreen: FC<TestScreenProps> = () => {
  const { currentTest } = useSnapshot(CurrentTestState)
  const { items, tests } = useSnapshot(CurrentQtiPackageState)
  const { program, subject } = useSnapshot(CurrentUserAssessmentState)

  const pauseUserAssessment = useUserAssessentPauseCallback()
  const handleTestCallback = useTestCallback()

  useUserAssessmentIdSetup()

  const { userAssessment, loading: fetchLoading } = useUserAssessmentFetch()

  const {
    open: openTestLeaveModal,
    onClose: onCloseTestLeaveModal,
    onOpen: onOpenTestLeaveModal,
  } = useModal()

  const packageLoading = useCurrentAssessmentSetup(!userAssessment)
  const loading = fetchLoading || packageLoading

  useUserAssessentUpdater()
  useIdleDetection()

  useEffect(() => {
    return () => {
      resetSyncIntervalState()
      resetIdleDetectorState()
      resetTimerState()
      resetCurrentTestState()
      resetCurrentQtiPackage()
      resetUserAssessmentState()
    }
  }, [])

  const onBack = () => {
    resetCalculatorData()
    pauseUserAssessment()
    handleTestCallback()
  }

  const handleLeaveAssessment = () => {
    if (CurrentTestState.currentTest?.isFinished) {
      handleTestCallback()
      return
    }

    onOpenTestLeaveModal()
  }

  const icon = (
    <Tooltip arrow title='Leave Test'>
      <StyleIconButton
        role='button'
        aria-label='Leave Test'
        onClick={handleLeaveAssessment}
        {...HOME_PAGE_BTN_ATTRIBUTES}
      >
        <StyledArrowLeft icon={faArrowLeft} />
      </StyleIconButton>
    </Tooltip>
  )

  return (
    <FontSizeControlProvider>
      <Helmet>
        <title>
          {[currentTest?.title, subject, program?.toUpperCase(), 'MasteryPrep']
            .filter(value => value)
            .join(' - ')}
        </title>
      </Helmet>
      <ScreenComponent headerIcon={icon} test={currentTest as AssessmentTest}>
        {loading ? (
          <Loader />
        ) : (
          <>
            {currentTest && items.length > 0 && tests.length > 0 && (
              <AssessmentComponent userAssessment={userAssessment} />
            )}
          </>
        )}

        {currentTest && (
          <LeaveTestModal open={openTestLeaveModal} onClose={onCloseTestLeaveModal} onSubmit={onBack} />
        )}
      </ScreenComponent>
    </FontSizeControlProvider>
  )
}

export default TestScreen

const StyledArrowLeft = styled(FontAwesomeIcon)(
  ({ theme }) => `
    font-size: 1.125rem;
    color: ${theme.palette.grey[100]};
  `,
)

const StyleIconButton = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(12.5),
  height: theme.spacing(12.5),

  [theme.breakpoints.down('mobile')]: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}))
