import { ChangeEventHandler, FC, KeyboardEventHandler, LabelHTMLAttributes, MouseEventHandler } from 'react'
import { Box, Button, styled } from '@mui/material'
import { IntervalIcon } from './IntervalIcon'
import { Interval, IntervalDirection, IntervalType } from '../types'
import { onKeyboardSubmit } from '@app/helpers'
import { getIntervalLabel } from '../helpers'
import { focusStyle } from '@app/theme'

export interface IntervalSelectProps {
  value: string
  options: Interval[]
  onChange: (id: string) => void
  onRemove: (id: string) => void
  className?: string
  disabled?: boolean
}

export const IntervalSelect: FC<IntervalSelectProps> = props => {
  const { value, options, onChange, onRemove, className, disabled } = props

  const onItemClick: ChangeEventHandler<HTMLInputElement> = event => onChange(event.target.value)
  const onItemKeydown =
    (id: string): KeyboardEventHandler<HTMLInputElement> =>
    event => {
      event.preventDefault()
      onChange(id)
    }

  const onRemoveClick =
    (id: string): MouseEventHandler<HTMLButtonElement> =>
    event => {
      event.preventDefault()
      onRemove(id)
    }

  return (
    <StyledIntervalSelect className={className} role='radiogroup' aria-label='Select current interval'>
      {options.map((option, i) => (
        <IntervalSelectItem
          key={option.id}
          id={option.id}
          selected={value === option.id}
          disabled={disabled}
          onChange={onItemClick}
          onKeyDown={onKeyboardSubmit(onItemKeydown(option.id))}
          type={option.type}
          direction={option.direction}
          onRemove={onRemoveClick(option.id)}
        />
      ))}
    </StyledIntervalSelect>
  )
}

export interface IntervalSelectItemProps {
  id: string
  selected?: boolean
  disabled?: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  onKeyDown: KeyboardEventHandler<HTMLInputElement>
  type: IntervalType
  direction: IntervalDirection
  onRemove: MouseEventHandler<HTMLButtonElement>
}

export const IntervalSelectItem: FC<IntervalSelectItemProps> = props => {
  const { id, selected, disabled, onChange, onKeyDown, type, direction, onRemove } = props

  const intervalLabel = getIntervalLabel(type, direction)

  return (
    <StyledIntervalSelectItem>
      <input
        type='checkbox'
        id={id}
        name={id}
        disabled={disabled}
        checked={selected}
        aria-checked={selected}
        value={id}
        onChange={onChange}
        onKeyDown={onKeyDown}
        aria-label={intervalLabel}
      />
      <StyledIntervalSelectLabel htmlFor={id} selected={selected}>
        <StyledIntervalIcon type={type} direction={direction} />
        <StyledButton
          variant='contained'
          size='xsmall'
          onClick={onRemove}
          disabled={disabled}
          aria-label={`Remove ${intervalLabel} interval`}
        >
          Remove
        </StyledButton>
      </StyledIntervalSelectLabel>
    </StyledIntervalSelectItem>
  )
}

const StyledIntervalSelect = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}))

const StyledIntervalSelectItem = styled(Box)(({ theme }) => ({
  display: 'flex',

  '& > input': {
    position: 'absolute',
    opacity: 0,
    appearance: 'none',
  },

  [`& > input:disabled + ${StyledIntervalSelectLabel}`]: {
    cursor: 'default',
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.grey[50],
  },
  [`& > input:focus + ${StyledIntervalSelectLabel}`]: {
    ...focusStyle(theme).border,
  },
}))

const StyledIntervalSelectLabel = styled(
  ({
    selected,
    ...props
  }: LabelHTMLAttributes<HTMLLabelElement> & { selected?: boolean; disabled?: boolean }) => (
    <label {...props} />
  ),
)(({ theme, selected }) => ({
  cursor: 'pointer',
  border: `1px solid ${theme.palette.blue[400]}`,
  borderWidth: selected ? '2px' : '1px',
  borderRadius: theme.shape.borderRadius * 1.5,
  padding: theme.spacing(3.5, 4, 4),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: selected ? theme.palette.blue[100] : 'unset',

  [`${theme.breakpoints.up('mobile')} and (hover: hover)`]: {
    ':hover': {
      backgroundColor: theme.palette.blue[50],
    },
  },
}))

const StyledIntervalIcon = styled(IntervalIcon)(() => ({
  margin: 'auto',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(6),
  textTransform: 'none',
  fontSize: '0.75rem',
  minHeight: '26px',
  borderRadius: theme.shape.borderRadius * 1.5,
  lineHeight: '0.875rem',

  ':hover': {
    backgroundColor: theme.palette.blue[800],
    boxShadow: theme.shadows[2],
  },
}))
