import { useState, useEffect, useRef } from 'react'
import { useSnapshot } from 'valtio'
import { AssessmentStatus } from '@app/firebase/api'
import { CurrentUserAssessmentState, CurrentTestState, setIsStarted } from '@app/storage'

export const useRubricModal = (): {
  open?: boolean
  onStart: () => void
  onClose: () => void
} => {
  const { status } = useSnapshot(CurrentUserAssessmentState)
  const { currentTest, testPartItems, rubricBlock } = useSnapshot(CurrentTestState)

  const isCompleted = status === AssessmentStatus.completed

  // If there is rubric block, show modal.
  const [open, setOpen] = useState<boolean>(!!(status && rubricBlock) && !isCompleted)

  const onceShownRef = useRef<boolean>(false)

  useEffect(() => {
    if (
      !CurrentTestState.currentTest ||
      !CurrentTestState.testPartItems ||
      !CurrentUserAssessmentState.status
    )
      return

    // Don't start test automatically.
    // If no rubricBlock or it's not for candidate, show "StartTestModal".
    if (
      CurrentUserAssessmentState.status !== AssessmentStatus.new &&
      CurrentUserAssessmentState.status !== AssessmentStatus.paused
    ) {
      if (CurrentUserAssessmentState.status === AssessmentStatus.started && !CurrentTestState.isStarted) {
        setIsStarted(true)
      }
      return
    }

    if (onceShownRef.current) return

    setOpen(true)
    // Use ref to show modal only once, even if status changes.
    onceShownRef.current = true
  }, [currentTest, testPartItems, status])

  const onClose = () => setOpen(false)
  const onStart = () => {
    setIsStarted(true)
    onClose()
  }

  return { open, onClose, onStart }
}
