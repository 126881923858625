import timerWebWorkerScript from '@app/helpers/web-workers/assessmentTimerWorker'
import { TimerActions, TimerStatus } from '@app/types'
import { proxy } from 'valtio'
import { devtools } from 'valtio/utils'

export type CurrentTimerType = {
  timerStatus: TimerStatus
  remainedTime: number
}

const initialState: CurrentTimerType = {
  timerStatus: TimerStatus.notStarted,
  remainedTime: null,
}

const webWorkerTimer = new Worker(timerWebWorkerScript)

export const CurrentTimerState = proxy<CurrentTimerType>(initialState)

export const setRemainedTime = (remainedTime: number): void => {
  CurrentTimerState.remainedTime = remainedTime
}

export const startTimer = () => {
  webWorkerTimer.postMessage(TimerActions.start)
  CurrentTimerState.timerStatus = TimerStatus.ongoing
  webWorkerTimer.onmessage = event => {
    if (event.data === TimerActions.tick) {
      CurrentTimerState.remainedTime--
    }
  }
}

export const pauseTimer = () => {
  CurrentTimerState.timerStatus = TimerStatus.paused
  webWorkerTimer.postMessage(TimerActions.pause)
}

export const endTimer = () => {
  CurrentTimerState.timerStatus = TimerStatus.ended
  webWorkerTimer.postMessage(TimerActions.reset)
}

export const resumeTimer = () => {
  CurrentTimerState.timerStatus = TimerStatus.ongoing
  webWorkerTimer.postMessage(TimerActions.resume)
}

export const resetTimerState = (): void => {
  CurrentTimerState.timerStatus = initialState.timerStatus
  CurrentTimerState.remainedTime = initialState.remainedTime
  webWorkerTimer.postMessage(TimerActions.reset)
}

process.env.NODE_ENV === 'development' &&
  devtools(CurrentTimerState, { enabled: true, name: 'CurrentTimerState' })
