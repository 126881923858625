import { CheckboxComponent } from '@app/components/ui'
import { Table, TableBody, TableHead, TableRow, Typography } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import { TEST_ATTRIBUTE_ID, TEST_ELEMENT_IDS } from '@app/constants'
import { CheckboxAttrProps } from '@app/types'
import { generateAttrWithValue, onKeyboardSubmit } from '@app/helpers'
// import { TooltipFeedbackGroupProps } from '@app/components/feedback/TooltipFeedbackGroup'
// import { REVIEW_BADGE_ORIGIN } from '../../badges'
import {
  StyledTableContainer,
  HeaderTableCell,
  StyledTableRow,
  BodyTableCell,
  CheckboxWrapper,
  StyledTableCell,
  // StyledTooltipFeedbackGroup,
} from './styles'

export interface MatchTableComponentProps {
  columns: JSX.Element[]
  rows: JSX.Element[]
  groupMode?: boolean
  // getFeedbackItems?: (columnId: string) => TooltipFeedbackGroupProps['items']
  isFinished: boolean
  isDisabled: (rowId: string, columnId: string) => boolean
  isChecked: (matchId: string) => boolean
  isCorrect?: (matchId: string) => boolean
  onChange: (id: string, checked: boolean) => void
}

export const MatchTableComponent: FC<MatchTableComponentProps> = props => {
  const {
    columns,
    rows,
    // groupMode = false,
    // getFeedbackItems,
    isFinished,
    isDisabled,
    isChecked,
    isCorrect,
    onChange,
  } = props

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.name, event.target.checked)

  const handleKeydown = (id: string, checked: boolean) => () => {
    onChange(id, checked)
  }

  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <HeaderTableCell sx={{ width: '40%' }}></HeaderTableCell>
            {columns.map((column: JSX.Element) => (
              <HeaderTableCell key={column.key}>
                <Typography component='p' variant='subtitle1'>
                  {column}
                </Typography>
              </HeaderTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: JSX.Element, rowIndex) => (
            <StyledTableRow key={row.key}>
              <BodyTableCell sx={{ width: '40%' }} component='th' scope='row'>
                <Typography component='p' variant='body2'>
                  {row}
                </Typography>
              </BodyTableCell>
              {columns.map((column: JSX.Element, columnIndex) => {
                const columnAriaLabel = column.props.accessibilityAttr?.['aria-label']
                const rowAriaLabel = row.props.accessibilityAttr?.['aria-label']
                const ariaLabel = `Row ${rowAriaLabel ? rowAriaLabel : row.props.children}, Column ${
                  columnAriaLabel ? columnAriaLabel : column.props.children
                }`
                const matchId = `${row.props.identifier} ${column.props.identifier}`

                const checkboxElement = (
                  <CheckboxWrapper
                    disabled={isFinished || isDisabled(row.props.identifier, column.props.identifier)}
                    checked={isChecked(matchId)}
                  >
                    <CheckboxComponent
                      disabled={isFinished || isDisabled(row.props.identifier, column.props.identifier)}
                      checked={isChecked(matchId)}
                      correct={isCorrect?.(matchId)}
                      onChange={handleChange}
                      onKeyDown={onKeyboardSubmit(handleKeydown(matchId, !isChecked(matchId)))}
                      name={matchId}
                      inputProps={
                        {
                          ...generateAttrWithValue(
                            TEST_ATTRIBUTE_ID,
                            TEST_ELEMENT_IDS.checkbox,
                            rowIndex * columns.length + columnIndex + 1,
                          ),
                          'aria-label': ariaLabel,
                        } as CheckboxAttrProps
                      }
                    />
                  </CheckboxWrapper>
                )

                // if (groupMode && rowIndex === rows.length - 1) {
                //   return (
                //     <StyledTableCell key={column.key} align='center'>
                //       <StyledTooltipFeedbackGroup
                //         items={getFeedbackItems(column.props.identifier)}
                //         anchorOrigin={REVIEW_BADGE_ORIGIN.bottomRight}
                //       >
                //         {checkboxElement}
                //       </StyledTooltipFeedbackGroup>
                //     </StyledTableCell>
                //   )
                // }

                return (
                  <StyledTableCell key={column.key} align='center'>
                    {checkboxElement}
                  </StyledTableCell>
                )
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  )
}
