import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export type QuestionContextMenuContextType = {
  markedItems: string[]
  stikethroughAnswers: string[]
  setMarkForReview: () => void
  setStikethrough: (answerId: string) => () => void
}

export const QuestionContextMenuContext = React.createContext<QuestionContextMenuContextType | null>(null)

interface Props {
  itemId: string
  children?: React.ReactNode
}

const MARK_FOR_REVIEW = 'MARK_FOR_REVIEW'
const STRIKETHROUGH = 'STRIKETHROUGH'

const setMarkForReviewValue = (value: string) => {
  localStorage.setItem(MARK_FOR_REVIEW, value)
}

const setStikethroughValue = (value: string) => {
  localStorage.setItem(STRIKETHROUGH, value)
}

const createKey = (ids: string[]): string => ids.join('/')

export const QuestionContextMenuProvider: React.FC<Props> = ({ itemId, children }) => {
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const userId = searchParams.get('userId')
  const testId = searchParams.get('id')

  const [markedItems, setMarkedItems] = useState<string[]>([])

  const [stikethroughAnswers, setStikethroughAnswers] = useState<string[]>([])

  const setMarkForReview = useCallback(() => {
    const value = JSON.parse(localStorage.getItem(MARK_FOR_REVIEW))

    const key = createKey([userId, testId])

    const markedItems: string[] = value?.[key] || []

    const newMarkedItems = markedItems?.includes(itemId)
      ? markedItems.filter(item => item !== itemId)
      : [...markedItems, itemId]

    setMarkForReviewValue(
      JSON.stringify({
        ...value,
        [key]: newMarkedItems,
      }),
    )

    setMarkedItems(newMarkedItems)
  }, [userId, testId, itemId])

  const setStikethrough = useCallback(
    (answerId: string) => () => {
      if (!answerId) return

      const value = JSON.parse(localStorage.getItem(STRIKETHROUGH))

      const key = createKey([userId, testId, itemId])

      const stikethroughAnswers: string[] = value?.[key] || []

      const newStikethroughAnswers = stikethroughAnswers?.includes(answerId)
        ? stikethroughAnswers.filter(answer => answer !== answerId)
        : [...stikethroughAnswers, answerId]

      setStikethroughValue(
        JSON.stringify({
          ...value,
          [key]: newStikethroughAnswers,
        }),
      )

      setStikethroughAnswers(newStikethroughAnswers)
    },
    [userId, testId, itemId],
  )

  useEffect(() => {
    const markedItems = JSON.parse(localStorage.getItem(MARK_FOR_REVIEW))
    const stikethroughAnswers = JSON.parse(localStorage.getItem(STRIKETHROUGH))

    const markedItemsKey = createKey([userId, testId])
    const stikethroughAnswersKey = createKey([userId, testId, itemId])

    setMarkedItems(markedItems?.[markedItemsKey] || [])
    setStikethroughAnswers(stikethroughAnswers?.[stikethroughAnswersKey] || [])
  }, [userId, testId, itemId])

  return (
    <QuestionContextMenuContext.Provider
      value={{
        markedItems,
        stikethroughAnswers,
        setMarkForReview,
        setStikethrough,
      }}
    >
      {children}
    </QuestionContextMenuContext.Provider>
  )
}
