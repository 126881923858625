import { AssessmentManifest } from "@app/models"
import { ManifestResource, ResourceType } from "@app/types"

/**
 * Assessment test manifest parse function.
 * @param { Object } data parsed xml data.
 * @returns { AssessmentManifest } manifest main data.
 */
export const parseManifest = (data: any): AssessmentManifest => {
  const manifest = data.manifest
  //const metadata: ManifestMetadata = parseMetadata(manifest.metadata)

  return {
    id: manifest.attributes.identifier,
    metadata: manifest.metadata,
    resources: parseAllResources(manifest.resources.resource)
  }
}

/**
 * Resources parser.
 * @param { Array<any> } resources array of test resources (questions).
 * @returns { ManifestResource[] } array of objects containing id and resource url.
 */
export const parseAllResources = (resources: any): ManifestResource[] => {
  if (Array.isArray(resources)) {
    return resources.reduce<ManifestResource[]>((result, resource) => {
      const parsedResource = parseResource(resource)
      return [ ...result, ...parsedResource ]
    }, [])
  }

  return parseResource(resources)
}

const parseResource = (resource: any): ManifestResource[] => {
  const { file: files } = resource
  const { href: resourceHref, type: resourceType } = resource.attributes
  if (Array.isArray(files)) {
    return files.map(file => ({
      resourceId: resource.attributes.identifier,
      type: getResourceFileType(resourceHref, file.attributes.href, resourceType),
      url: file.attributes.href
    }))
  }

  return [{
    resourceId: resource.attributes.identifier,
    type: getResourceFileType(resourceHref, files.attributes.href, resourceType),
    url: files.attributes.href
  }]
}

const getResourceFileType = (resourceHref: string, fileHref: string, resourceType: string): ResourceType => {
  if (resourceHref === fileHref) {
    switch(resourceType) {
      case ResourceType.item: {
        return ResourceType.item
      }
      case ResourceType.test: {
        return ResourceType.test
      }
    }
  }

  if (fileHref.includes('.css')) {
    return ResourceType.stylesheet
  }

  if (fileHref.match(/\.(jpeg|jpg|gif|png|svg)$/) != null) {
    return ResourceType.image
  }
}