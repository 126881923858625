import { FC, forwardRef } from 'react'
import { styled } from '@mui/material'
import {
  LineSegment,
  LineSegmentProps,
  TSpan,
  Text,
  TextProps,
  VictoryCommonPrimitiveProps,
  VictoryLabel,
  VictoryLabelProps,
} from 'victory'
import { Marker } from './Marker'
import { DEFAULT_MARKER_HEIGHT, DEFAULT_MARKER_WIDTH } from '../constants'

export interface AxisLineProps extends React.SVGProps<SVGLineElement> {
  width?: number
  height?: number
}
export const AxisLine = forwardRef<SVGLineElement, AxisLineProps>((props, ref) => {
  const { width = DEFAULT_MARKER_WIDTH, height = DEFAULT_MARKER_HEIGHT, ...rest } = props

  const hMiddle = height / 2
  const startArrowPoints = `${width} 0, ${width} ${height}, 0 ${hMiddle}`
  const endArrowPoints = `0 0, ${width} ${hMiddle}, 0 ${height}`

  return (
    <>
      <defs>
        <Marker id='startarrow' width={width} height={height}>
          <StyledAxisPointer points={startArrowPoints} />
        </Marker>
        <Marker id='endarrow' width={width} height={height}>
          <StyledAxisPointer points={endArrowPoints} />
        </Marker>
      </defs>
      <StyledAxisLine
        {...rest}
        ref={ref}
        role='presentation'
        vectorEffect='non-scaling-stroke'
        markerEnd='url(#endarrow)'
        markerStart='url(#startarrow)'
      />
    </>
  )
})

interface AxisGridProps extends LineSegmentProps {
  ticks?: number[]
  index?: number
  width: number
  height: number
  dependentAxis?: boolean
  crossAxis?: boolean
}
export const AxisGrid: FC<AxisGridProps> = props => {
  const { index, ticks, width, height, dependentAxis, crossAxis } = props

  if (index === 0 || index === ticks?.length - 1) return null
  if (crossAxis && ticks[index] === 0) return null

  const gridSize = dependentAxis ? height / (ticks.length - 1) : width / (ticks.length - 1)
  const line = index * gridSize
  const coordinates = dependentAxis
    ? {
        x1: 0,
        x2: width,
        y1: line,
        y2: line,
      }
    : {
        x1: line,
        x2: line,
        y1: 0,
        y2: height,
      }

  return <StyledGridLine key={ticks[index]} {...coordinates} disableInlineStyles />
}

export const AxisBase: FC<
  LineSegmentProps & {
    ticks: number[]
    gridComponent: JSX.Element
  }
> = props => {
  const { ticks, gridComponent, ...rest } = props

  return (
    <>
      {ticks.map((tick, i) => (
        <gridComponent.type key={tick} index={i} ticks={ticks} {...gridComponent.props} />
      ))}
      <LineSegment {...rest} disableInlineStyles lineComponent={<AxisLine />} />
    </>
  )
}

export const Tick: FC<any> = () => {
  return null
}

export const TickLabel: FC<VictoryLabelProps> = props => {
  return (
    <VictoryLabel
      {...props}
      disableInlineStyles
      textComponent={<LabelText />}
      tspanComponent={<LabelSpan />}
    />
  )
}

const LabelText: FC<TextProps> = ({ style, ...props }) => <Text role='presentation' aria-hidden {...props} />

export const LabelSpan: FC<VictoryCommonPrimitiveProps> = ({ style, ...props }) => (
  <StyledLabelSpan {...props} role='presentation' aria-hidden />
)

export const Grid: FC<{}> = () => {
  return null
}

export const AxisLabel: FC<VictoryLabelProps> = props => {
  return (
    <VictoryLabel
      {...props}
      disableInlineStyles
      textComponent={<LabelText />}
      tspanComponent={<LabelSpan />}
    />
  )
}

const StyledAxisPointer = styled('polygon')(({ theme }) => ({
  fill: theme.palette.red.A400,
}))

const StyledAxisLine = styled('line')(({ theme }) => ({
  stroke: theme.palette.red.A400,
  strokeWidth: '2px',
}))

const StyledLabelSpan = styled(TSpan)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: theme.typography.subtitle2.lineHeight,
}))

const StyledGridLine = styled(LineSegment)(({ theme }) => ({
  stroke: theme.palette.grey[100],
  strokeDasharray: 'none',
}))
