import { extractHtmlContentFromTag, getInteractionAndInfoControl, XMLTools } from '@app/helpers'
import { parseAssessmentItem, parseAssessmentTest, parseManifest } from '@app/helpers/parsers'
import { AssessmentItem, AssessmentManifest, AssessmentTest } from '@app/models'
import {
  setCurrentQtiPackageItems,
  setCurrentQtiPackageStylesheets,
  setCurrentQtiPackageTests,
} from '@app/storage'
import { setExtractedImages } from '@app/storage/extractedImages'
import {
  AssessmentStylesheet,
  ManifestResource,
  ExtractedManifestResource,
  ResourceType,
  ExtractedImage,
} from '@app/types'
import JSZip from 'jszip'

interface SplittedResources {
  tests: ExtractedManifestResource[]
  items: ExtractedManifestResource[]
  stylesheets: ExtractedManifestResource[]
  images: ExtractedManifestResource[]
}

const unzip = async (file: File): Promise<JSZip> => {
  const zipData = await JSZip.loadAsync(file)
  return zipData
}

/**
 * Get QTI package manifest.
 * @param { JSZip } zipData zip file data.
 * @returns { Promise<AssessmentManifest> } parsed assessment manifest.
 */
const getManifest = async (zipData: JSZip): Promise<AssessmentManifest> => {
  const manifestRawData = await zipData.file('imsmanifest.xml')?.async('string')
  const manifest = await XMLTools.parse(manifestRawData as string)

  return parseManifest(manifest)
}

/**
 * Extract stylesheet data and save it to current QTI package storage.
 * @param { ExtractedManifestResource[] } stylesheets stylesheet file objects from zip.
 * @returns { Promise<AssessmentStylesheet[]> } extracted and parsed stylesheets.
 */
const getExtractedStylesheets = async (
  stylesheets: ExtractedManifestResource[],
): Promise<AssessmentStylesheet[]> => {
  return await Promise.all(
    stylesheets.map(async stylesheet => {
      const path = stylesheet.zipData.name
      const stylesheetContent = await stylesheet.zipData.async('string')
      return {
        resourceId: stylesheet.resourceId,
        path,
        content: stylesheetContent,
      }
    }),
  )
}

/**
 * Extract assessment items data and save it to current QTI package storage.
 * @param { ExtractedManifestResource[] } items manifest resource with zip data.
 * @returns { Promise<AssessmentItem[]> } extracted and parsed assessment items.
 */
const getExtractedTestItems = async (items: ExtractedManifestResource[]): Promise<AssessmentItem[]> => {
  return await Promise.all(
    items.map(async item => {
      const itemRawData = (await item.zipData.async('string')) as string
      const parsedXml = await XMLTools.parse(itemRawData)
      const itemBody = extractHtmlContentFromTag(itemRawData, 'itemBody')

      return parseAssessmentItem(parsedXml, item.resourceId, item.zipData.name, itemBody)
    }),
  )
}

/**
 * Extract assessment tests data and save it to current QTI package storage.
 * @param { ExtractedManifestResource[] } tests manifest resource with zip data.
 * @returns { Promise<AssessmentTest[]> } extracted and parsed assessment tests.
 */
const getExtractedTests = async (tests: ExtractedManifestResource[]): Promise<AssessmentTest[]> => {
  return await Promise.all(
    tests.map(async test => {
      const testRawData = await test.zipData.async('string')
      const parsedXml = await XMLTools.parse(testRawData as string)
      const [rubricBlockContent] = getInteractionAndInfoControl(testRawData, 'rubricBlock')

      return parseAssessmentTest(parsedXml, test.resourceId, rubricBlockContent)
    }),
  )
}

/**
 * Save images to valtio storage.
 * - In case of svg extracts svg markup.
 * - In case of images extracts base64 string.
 * - If one image is shared across multiple resources, resource will be added to array.
 * @param { ExtractedManifestResource[] } images manifest resource with zip data.
 * @returns { Promise<ExtractedImage[]> } extracted images.
 */
const getExtractedImages = async (images: ExtractedManifestResource[]): Promise<ExtractedImage[]> => {
  const extractedImages: ExtractedImage[] = []
  for (const image of images) {
    const index = extractedImages.findIndex(extractedImage => extractedImage.path === image.zipData.name)

    if (index !== -1) {
      extractedImages[index].resourceIds.push(image.resourceId)
      continue
    }

    let imageData = ''
    if (image.zipData.name.includes('svg')) {
      imageData = await image.zipData.async('string')
    } else {
      imageData = await image.zipData.async('base64')
    }

    extractedImages.push({
      resourceIds: [image.resourceId],
      path: image.zipData.name,
      imageData,
    })
  }

  return extractedImages
}

/**
 * Split resources by type.
 * @param { JSZip } zipData zip file data.
 * @param { ManifestResource[] } resources extracted manifest resources.
 * @returns { SplittedResources } resources splitted by type.
 */
const getSplittedExtractedResourcesByType = (
  zipData: JSZip,
  resources: ManifestResource[],
): SplittedResources => {
  const initialValue: SplittedResources = {
    tests: [],
    items: [],
    stylesheets: [],
    images: [],
  }
  return resources.reduce<SplittedResources>((result, resource) => {
    switch (resource.type) {
      case ResourceType.test: {
        result.tests = [
          ...result.tests,
          {
            resourceId: resource.resourceId,
            type: resource.type,
            zipData: zipData.file(resource.url),
          },
        ]
        break
      }
      case ResourceType.item: {
        result.items = [
          ...result.items,
          {
            resourceId: resource.resourceId,
            type: resource.type,
            zipData: zipData.file(resource.url),
          },
        ]
        break
      }
      case ResourceType.stylesheet: {
        result.stylesheets = [
          ...result.stylesheets,
          {
            resourceId: resource.resourceId,
            type: resource.type,
            zipData: zipData.file(resource.url),
          },
        ]
        break
      }
      case ResourceType.image: {
        result.images = [
          ...result.images,
          {
            resourceId: resource.resourceId,
            type: resource.type,
            zipData: zipData.file(resource.url),
          },
        ]
      }
    }

    return result
  }, initialValue)
}

/**
 * QTI package zip data extractor.
 * @param { File } file QTI package zip file.
 */
export const extractQtiPackageData = async (file: File) => {
  const zipData = await unzip(file)
  const manifest = await getManifest(zipData)

  const resources = getSplittedExtractedResourcesByType(zipData, manifest.resources)
  const extractedImages = await getExtractedImages(resources.images)
  setExtractedImages(extractedImages)
  const extractedItems = await getExtractedTestItems(resources.items)
  setCurrentQtiPackageItems(extractedItems)
  const extractedTests = await getExtractedTests(resources.tests)
  setCurrentQtiPackageTests(extractedTests)
  const extractedStylesheets = await getExtractedStylesheets(resources.stylesheets)
  setCurrentQtiPackageStylesheets(extractedStylesheets)

  return { extractedImages, extractedItems, extractedTests, extractedStylesheets }
}
