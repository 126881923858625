import React from 'react'
import { Link, styled } from '@mui/material'
import { WithFeedback } from '@app/components'

type Props = {
  itemId: string
  responseId: string
  onViewCorrectAnswer: () => void
}

const StyledFeedback = styled(WithFeedback)(() => ({
  margin: 0,
}))

export const Feedback: React.FC<Props> = ({ itemId, responseId, onViewCorrectAnswer }) => {
  return (
    <StyledFeedback
      itemId={itemId}
      responseDeclarationId={responseId}
      showCorrectAnswer
      multiScoreFormatter={(raw, possible) => (raw === possible ? 'Correct' : 'Incorrect')}
      correctAnswerFormatter={() => (
        <Link
          onClick={onViewCorrectAnswer}
          underline='hover'
          component='button'
          textAlign='start'
          variant='body2'
        >
          View Correct Answer
        </Link>
      )}
    />
  )
}
