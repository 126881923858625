import { forwardRef } from 'react'
import { Box, BoxProps, styled } from '@mui/material'
import { focusStyle } from '@app/theme'

export interface OrderItemProps extends BoxProps {
  disabled?: boolean
  dragging?: boolean
  overlay?: boolean
  placed?: boolean
  selected?: boolean
}

export const OrderItem = forwardRef<any, OrderItemProps>((props, ref) => {
  return <StyledOrderItem ref={ref} {...props} />
})

const StyledOrderItem = styled(
  forwardRef<any, OrderItemProps>(
    ({ disabled, dragging, overlay, placed, selected, ...rest }: OrderItemProps, ref) => (
      <Box ref={ref} {...rest} />
    ),
  ),
)(({ theme, disabled, dragging, overlay, placed, selected }) => ({
  cursor: 'pointer',
  background: theme.palette.background.paper,
  width: '26px',
  height: '26px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.blue[400]}`,
  borderRadius: theme.shape.borderRadius * 1.5,
  boxShadow: theme.shadows[2],
  userSelect: 'none',

  '&:hover': {
    [theme.breakpoints.up('mobile')]: {
      backgroundColor: theme.palette.blue[50],
    },
  },

  '&:focus-visible': {
    ...(!dragging && { ...focusStyle(theme).border }),
  },

  ...((disabled || dragging) && {
    backgroundColor: theme.palette.grey[50],
    border: 'none',
    boxShadow: 'none',

    '&:focus': {
      outline: 'none',
      borderColor: 'transparent',
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  }),

  ...(disabled && { color: theme.palette.grey[400] }),
  ...(dragging && { color: theme.palette.grey[800] }),

  ...((overlay || (selected && !placed && !dragging)) && {
    border: `2px solid ${theme.palette.blue[400]}`,
    boxShadow: selected ? theme.shadows[2] : theme.shadows[6],

    '&, &:hover': {
      backgroundColor: theme.palette.blue[100],
    },
  }),

  ...(placed && {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.blue[600],
    color: theme.palette.background.paper,
    boxShadow: 'none',
    border: `2px dashed ${theme.palette.grey[100]}`,
    backgroundClip: 'padding-box',

    '&:hover': {
      backgroundColor: theme.palette.blue[500],
    },
    '&:focus-visible': {
      outline: focusStyle(theme, 0, 2).border.outline,
    },
  }),

  ...(placed &&
    selected && {
      border: focusStyle(theme, 0, 2).border.outline,
      backgroundColor: theme.palette.blue[600],
    }),
}))
