import { ShowHide } from '@app/types'
import { FC } from 'react'

interface GapTextComponentProps {
  identifier: string
  templateidentifier: string
  showhide: ShowHide
  matchgroup: string
  matchmax: string
  matchmin: string
  children: JSX.Element | JSX.Element[]
  accessibilityAttr?: { [key: string]: string }
}

export const GapTextComponent: FC<GapTextComponentProps> = ({ children, accessibilityAttr, ...props }) => {
  return <div {...accessibilityAttr}>{children}</div>
}
