import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, IconButtonProps, styled } from '@mui/material'
import { forwardRef } from 'react'

interface EquationEditorControlButtonProps extends IconButtonProps {
  icon: IconProp
  ariaLabel: string
}

export const EquationEditorControlButton = forwardRef<any, EquationEditorControlButtonProps>(
  ({ icon, ariaLabel, ...props }, ref) => {
    return (
      <StyledIconButton aria-label={ariaLabel} disableRipple {...props} ref={ref}>
        <StyledFontAwesomeIcon icon={icon} />
      </StyledIconButton>
    )
  },
)

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '30px',
  height: '30px',

  ':hover': {
    backgroundColor: theme.palette.grey[50],
  },
  ':active': {
    backgroundColor: theme.palette.grey[100],
    outline: `1px solid ${theme.palette.grey[100]}`,
  },
  ':nth-child(2n)': {
    marginRight: theme.spacing(1.5),
  },
}))

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.grey[500],
}))
