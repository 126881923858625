import React from 'react'
import { Tooltip } from '@mui/material'
import { StyledReviewBadge, StyledFocusableReviewIcon, StyledTooltipBase } from '../TooltipFeedback/styles'
import { ItemFeedbackGroup, ItemFeedbackGroupProps } from '../ItemFeedbackGroup'
import { ReviewBadgeProps } from '@app/components/ui/badges/ReviewBadge'

export type TooltipFeedbackGroupProps = ReviewBadgeProps & {
  items: ItemFeedbackGroupProps['items']
}

const POPPER_PROPS = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [8, -8],
      },
    },
  ],
}

const SLOTS = { popper: StyledTooltipBase }

export const TooltipFeedbackGroup: React.FC<React.PropsWithChildren<TooltipFeedbackGroupProps>> = ({
  children,
  items,
  ...rest
}) => {
  return (
    <StyledReviewBadge
      groupMode
      badgeContent={
        <Tooltip
          title={<ItemFeedbackGroup size='sm' items={items} />}
          slots={SLOTS}
          placement='right-end'
          PopperProps={POPPER_PROPS}
        >
          <StyledFocusableReviewIcon tabIndex={0} groupMode />
        </Tooltip>
      }
      {...rest}
    >
      {children}
    </StyledReviewBadge>
  )
}
