import { CurrentTestState, setItemResponse, setItemResponseValidity } from '@app/storage'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { useResponseDeclaration, useUpdatingRef } from '../hooks'
import { InteractionType } from '@app/types'

/**
 * Reset selected items on question change.
 * @param id Current item response ID.
 * @param setState SetState action.
 */
export const useStateReset = (
  id: string,
  responseDeclarationId: string,
  setState: React.Dispatch<React.SetStateAction<any>>,
  transformResponse?: (response: string[]) => any,
): void => {
  const response = useItemResponse(id, responseDeclarationId)

  // We should watch current response, but don't reset selected items when it changes.
  const currentResponseRef = useUpdatingRef(response)

  useEffect(() => {
    setState(transformResponse ? transformResponse(currentResponseRef.current) : currentResponseRef.current)
  }, [currentResponseRef, id, setState, transformResponse])
}

/**
 * Update response validity flag on selected items change.
 * @param id Current item response ID.
 * @param selectedLength Selected items count.
 * @param minLength Min response length.
 * @param maxLength Max response length.
 * @returns Response validity flag.
 */
export const useResponseValidityUpdate = (
  id: string,
  selectedLength: number,
  minLength?: number,
  maxLength?: number,
): boolean => {
  // We should watch itemId, but don't trigger response update when it changes.
  const { currentTestPart } = useSnapshot(CurrentTestState)
  const idRef = useUpdatingRef(id)

  const [isValid, setValid] = useState(true)

  useEffect(() => {
    if (!idRef.current) return
    if (currentTestPart?.isFinished) return

    let isResponseValid = true

    // Response is invalid if selected items count is out of boundaries.
    if (minLength && selectedLength < minLength) {
      isResponseValid = false
    }
    if (maxLength && selectedLength > maxLength) {
      isResponseValid = false
    }

    setItemResponseValidity(idRef.current, isResponseValid)
    setValid(isResponseValid)
  }, [currentTestPart?.isFinished, idRef, selectedLength, minLength, maxLength])

  return isValid
}

/**
 * Update question response on selected items change.
 * @param id Current item response ID.
 * @param responseDeclarationId Response declaration id.
 * @param selected Array of selected items.
 * @param interactionType Type of interaction
 * @param transformState Selected array transformer function.
 * @param isValid Validity flag.
 */
export const useResponseUpdate = (
  id: string,
  responseDeclarationId: string,
  selected: any,
  interactionType: InteractionType,
  transformState?: (state: any) => string[],
  isValid = true,
): void => {
  const { currentTestPart } = useSnapshot(CurrentTestState)
  // We should watch itemId, but don't trigger response update when it changes.
  const idRef = useUpdatingRef(id)
  const responseIdRef = useUpdatingRef(responseDeclarationId)
  const responseDeclaration = useResponseDeclaration(id, responseDeclarationId)

  useEffect(() => {
    if (!idRef.current) return
    if (currentTestPart?.isFinished) return
    if (selected === null || !responseDeclaration || !interactionType) return

    setItemResponse(
      idRef.current,
      responseIdRef.current,
      transformState ? transformState(selected) : selected,
      isValid,
      interactionType,
      responseDeclaration.cardinality,
    )

    //prevId.current = id
  }, [
    currentTestPart?.isFinished,
    idRef,
    selected,
    responseIdRef,
    transformState,
    isValid,
    responseDeclaration,
    interactionType,
  ])
}

export const useFindUnAnsweredItems = () => {
  const { itemsResponses } = useSnapshot(CurrentTestState)

  return itemsResponses.filter(res => !res.answered).map(res => res.id)
}

export const useFindRequiredUnAnsweredItems = () => {
  const { itemsResponses } = useSnapshot(CurrentTestState)

  return itemsResponses.filter(res => !res.answered && !res.isResponseValid).map(res => res.id)
}

export const useAnsweredItems = () => {
  const { itemsResponses } = useSnapshot(CurrentTestState)

  const [answeredItems, setAnsweredItems] = useState<string[]>([])

  useEffect(() => {
    setAnsweredItems(CurrentTestState.itemsResponses.filter(item => item.answered).map(item => item.id))
  }, [itemsResponses])

  return answeredItems
}

export const useItemResponse = (id: string, responseDeclarationId: string): string[] => {
  const { itemsResponses } = useSnapshot(CurrentTestState)
  const currentItemResponse = itemsResponses.find(item => item.id === id)

  if (!currentItemResponse) {
    return []
  }

  const response =
    currentItemResponse.responses.find(res => res.responseDeclarationId === responseDeclarationId)
      ?.response || []

  return response as string[]
}

export const useReactiveItemResponse = (responseDeclarationId: string): string[] => {
  const { currentItemResponse } = useSnapshot(CurrentTestState)
  const [response, setResponse] = useState<string[]>(null)

  useEffect(() => {
    if (!currentItemResponse.id) return

    const currRes = CurrentTestState.itemsResponses.find(item => item.id === currentItemResponse.id)

    if (!currRes) {
      setResponse([])
      return
    }

    const res =
      CurrentTestState.currentItemResponse.responses.find(
        res => res.responseDeclarationId === responseDeclarationId,
      )?.response || []

    setResponse(res)
  }, [responseDeclarationId, currentItemResponse?.id])

  return response
}
