import { v4 as uuid } from 'uuid'
import { DraggableData, UniqueDOMPoint } from '../../GraphingInteraction/types'
import { RESPONSE_SEPARATOR, UNKNOWN_INTERVAL_LABEL, UNKNOWN_POSITION_LABEL } from '../constants'
import { Interval, IntervalType, IntervalDirection } from '../types'

export const omitFloatTicks = (tick: number, i: number, arr: number[]): number => (tick % 1 ? null : tick)

export const transformStateToResponse = (state: Interval[]): string[] =>
  state.map(interval => [interval.point.x, interval.type, interval.direction].join(RESPONSE_SEPARATOR))

export const transformResponseToState = (response: string[]): Interval[] =>
  response.map(responseItem => {
    const responseItemArr = responseItem.split(RESPONSE_SEPARATOR)

    return {
      id: uuid(),
      point: new UniqueDOMPoint(parseFloat(responseItemArr[0])),
      type: responseItemArr[1] as IntervalType,
      direction: responseItemArr[2] as IntervalDirection,
    }
  })

export const getIntervalLabel = (type: IntervalType, direction: IntervalDirection): string => {
  if (!type || !direction) return UNKNOWN_INTERVAL_LABEL

  return `${type}-${direction}`
}

export const getPointLabel = (data: DraggableData | DOMPoint): string => {
  if (!data) return UNKNOWN_POSITION_LABEL

  return data instanceof DOMPoint ? `(${data.x.toString()})` : `(${data._x.toString()})`
}
