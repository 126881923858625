import React from 'react'
import { BadgeProps } from '@mui/material'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo'
import { BadgeIcon, StyledBadge } from './styles'

export interface ReviewBadgeProps extends BadgeProps {
  correct?: boolean
  groupMode?: boolean
}

export type ReviewBadgeOrigin = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'

export const REVIEW_BADGE_ORIGIN: Record<ReviewBadgeOrigin, BadgeProps['anchorOrigin']> = {
  topLeft: { vertical: 'top', horizontal: 'left' },
  topRight: { vertical: 'top', horizontal: 'right' },
  bottomLeft: { vertical: 'bottom', horizontal: 'left' },
  bottomRight: { vertical: 'bottom', horizontal: 'right' },
}

export const ReviewBadge = React.forwardRef<HTMLSpanElement, ReviewBadgeProps>(
  ({ correct, groupMode = false, ...props }, ref) => {
    if (groupMode) {
      return <StyledBadge ref={ref} badgeContent={<BadgeIcon icon={faInfo} />} color='info' {...props} />
    }

    return (
      <StyledBadge
        ref={ref}
        invisible={correct === undefined}
        badgeContent={<BadgeIcon icon={correct ? faCheck : faTimes} />}
        color={correct ? 'success' : 'warning'}
        {...props}
      />
    )
  },
)
