import { CurrentTestState } from '@app/storage'
import { Box, styled } from '@mui/material'
import { FC, KeyboardEvent, memo } from 'react'
import { useSnapshot } from 'valtio'
import { useOperatorsKeyboardNav } from '../hooks'
import { EquationSectionType, SectionType } from '../types'
import { getUniqKey } from '../utils'
import { EquationEditorButton } from './EquationEditorButton'
import { getOperatorIcon } from './OperatorIcon'

interface EquationEditorSectionProps {
  isMobile: boolean
  sectionType: SectionType
  sectionOperators: EquationSectionType[]
  insertCommand: (operator: string) => () => void
}

export const EquationEditorSection: FC<EquationEditorSectionProps> = memo(
  ({ sectionType, sectionOperators, insertCommand, isMobile }) => {
    const { currentTestPart } = useSnapshot(CurrentTestState)
    const { currentRow, currentColumn, buttonsRefs, onKeyDown, onFocus } =
      useOperatorsKeyboardNav(sectionOperators)

    const getSubsectionOperators = (subSection: EquationSectionType, rowIndex?: number) =>
      subSection.operators.map((operator, colIndex) => {
        const [icon, label] = getOperatorIcon(operator)
        return (
          <EquationEditorButton
            ref={ref => {
              if (!buttonsRefs.current.length) {
                return
              }

              if (buttonsRefs.current?.[rowIndex]?.[colIndex]) {
                buttonsRefs.current[rowIndex][colIndex].ref = ref
              }
            }}
            key={getUniqKey(label)}
            onClick={() => {
              insertCommand(operator)()
              onFocus(rowIndex, colIndex)
            }}
            disabled={currentTestPart?.isFinished || !operator}
            operatorType={subSection.type}
            ariaLabel={label}
            onKeyDown={(e: KeyboardEvent) => {
              onKeyDown(e, rowIndex)

              if (e.code === 'Space' || e.code === 'Enter') {
                onFocus(rowIndex, colIndex)
              }
            }}
            tabIndex={rowIndex === currentRow && colIndex === currentColumn ? 0 : -1}
            aria-describedby={'eqEditorNavigationDescription'}
          >
            {operator && icon}
          </EquationEditorButton>
        )
      })

    return (
      <>
        <Box id='eqEditorNavigationDescription' sx={{ display: 'none' }}>
          To move through the operators of Equation Editor, use the keyboard arrows
        </Box>
        <EquationEditorSectionWrapper
          aria-label={`${sectionType === SectionType.actionsSections ? 'Actions' : 'Numbers'} section`}
        >
          {sectionType === SectionType.numbersSection ? (
            <>
              {sectionOperators.map((subSection, index) => (
                <EquationEditorButtonsWrapper key={getUniqKey(subSection.type)}>
                  {getSubsectionOperators(subSection, index)}
                </EquationEditorButtonsWrapper>
              ))}
            </>
          ) : (
            <>
              {isMobile ? (
                <EquationEditorMobileActionsWrapper>
                  {sectionOperators.map((subSection, index) => getSubsectionOperators(subSection, index))}
                </EquationEditorMobileActionsWrapper>
              ) : (
                <>
                  {sectionOperators.map((subSection, index) => (
                    <EquationEditorActionButtonsWrapper key={getUniqKey(subSection.type)}>
                      {getSubsectionOperators(subSection, index)}
                    </EquationEditorActionButtonsWrapper>
                  ))}
                </>
              )}
            </>
          )}
        </EquationEditorSectionWrapper>
      </>
    )
  },
)

const EquationEditorSectionWrapper = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.grey[100]}`,
  padding: theme.spacing(0, 1, 0, 1),

  ':first-child': {
    padding: theme.spacing(0, 1, 0, 0),
  },

  ':last-child': {
    borderRight: 'none',
    padding: theme.spacing(0, 0, 0, 1),
  },

  [theme.breakpoints.down('mobile')]: {
    borderRight: `none`,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing(1, 0, 1, 0),

    ':first-child': {
      padding: theme.spacing(0, 0, 1, 0),
    },

    ':last-child': {
      padding: theme.spacing(1, 0, 0),
      borderBottom: 'none',
    },
  },
}))

const EquationEditorButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
}))

const EquationEditorActionButtonsWrapper = styled(EquationEditorButtonsWrapper)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  [theme.breakpoints.down('mobile')]: {
    display: 'none',
  },
}))

const EquationEditorMobileActionsWrapper = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('mobile')]: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))
