import React, { useCallback } from 'react'
import { Box, styled } from '@mui/material'
import {
  getFeedbackItemsFromPercentages,
  getPercentagesPadLength,
  percentageFormatter,
  useAssessmentItemResponseScoring,
  useResponsePercentages,
} from '@app/helpers'
import { ItemFeedback, ItemFeedbackProps } from './ItemFeedback'
import { ItemFeedbackGroup, ItemFeedbackGroupProps } from './ItemFeedbackGroup'
import VideoFeedback from './VideoFeedback'

type WithFeedbackProps = {
  itemId: string
  responseDeclarationId: string
  useByAnswerFeedback?: boolean
  showCorrectAnswer?: boolean
  forceMultiscore?: ItemFeedbackProps['forceMultiscore']
  multiScoreFormatter?: ItemFeedbackProps['multiScoreFormatter']
  correctAnswerFormatter?: ItemFeedbackProps['correctAnswerFormatter']
  className?: string
}

export const WithGroupFeedback: React.FC<React.PropsWithChildren<WithFeedbackProps>> = ({
  itemId,
  responseDeclarationId,
  children,
  className,
}) => {
  const percentages = useResponsePercentages(itemId, responseDeclarationId)

  const padLength = getPercentagesPadLength(percentages)

  const feedbackPercentageFormatter = useCallback(
    (percentage: number) => percentageFormatter.format(percentage).padStart(padLength, ' '),
    [padLength],
  )

  if (!percentages) {
    return <>{children}</>
  }

  const feedbackItems = getFeedbackItemsFromPercentages(percentages)

  return (
    <>
      {children}
      <StyledFeedback
        as={ItemFeedbackGroup}
        items={feedbackItems}
        percentageFormatter={feedbackPercentageFormatter}
        className={className}
      />
    </>
  )
}

export const WithFeedback: React.FC<React.PropsWithChildren<WithFeedbackProps>> = ({
  itemId,
  responseDeclarationId,
  useByAnswerFeedback = false,
  showCorrectAnswer = false,
  forceMultiscore,
  multiScoreFormatter,
  correctAnswerFormatter,
  children,
  className,
}) => {
  const scoring = useAssessmentItemResponseScoring(itemId, responseDeclarationId)

  if (scoring.groupMode) {
    return (
      <WithGroupFeedback itemId={itemId} responseDeclarationId={responseDeclarationId} className={className}>
        {children}
      </WithGroupFeedback>
    )
  }

  // If no scoring, render interaction only.
  if (!scoring.scoring) {
    return <>{children}</>
  }

  let raw = scoring.raw
  let possible = scoring.possible

  // "useByAnswerFeedback" mode relies on correct/total answer items within the question.
  // "possible" can still be 1 in that case.
  if (useByAnswerFeedback) {
    raw = scoring.itemResponse?.response?.filter(response =>
      scoring.scoring?.response?.includes(response),
    )?.length
    possible = scoring.scoring?.response?.length
  }

  // Otherwise render feedback right after interaction.
  return (
    <>
      {children}
      <StyledFeedback
        as={ItemFeedback}
        correct={scoring.correct}
        correctAnswer={showCorrectAnswer && scoring.scoring.response[0]}
        raw={raw}
        possible={possible}
        forceMultiscore={forceMultiscore}
        multiScoreFormatter={multiScoreFormatter}
        correctAnswerFormatter={correctAnswerFormatter}
        className={className}
      />

      <VideoFeedback videoSrc='' />
    </>
  )
}

const StyledFeedback = styled(Box)<ItemFeedbackProps | ItemFeedbackGroupProps>(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}))
