import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import { CustomEditorFooter, CustomEditorToolbar, StyledBox } from './components'
import { DEFAULT_TEXT_LENGTH, TEXT_EDITOR_ATTRIBUTES } from '@app/constants'
import { copyText, cutText, editorFormats, moveFocusToNextElement, pasteText, reDo, unDo } from './utils'
import { SpecialCharactersModal } from '../../modals'

interface TextEditorComponentProps {
  value: string
  changeHandler: (text: string, xhtml: string, lines: number) => void
  placeholder: string
  isDisabled: boolean
  expectedLength?: number
}

export const TextEditorComponent: FC<TextEditorComponentProps> = props => {
  const {
    value,
    changeHandler,
    placeholder,
    isDisabled,
    expectedLength = DEFAULT_TEXT_LENGTH,
    ...accessibilityAttr
  } = props
  const [openCharactersModal, setOpenCharactersModal] = useState<boolean>(false)
  const quillRef = useRef<ReactQuill>()

  const editorModules = useMemo(
    () => ({
      toolbar: {
        container: '#toolbar',
        handlers: {
          cut: () => cutText(quillRef.current?.getEditor()),
          copy: () => copyText(quillRef.current?.getEditor()),
          paste: () => pasteText(quillRef.current?.getEditor()),
          undo: () => unDo(quillRef.current?.getEditor()),
          redo: () => reDo(quillRef.current?.getEditor()),
        },
      },
      keyboard: {
        bindings: {
          tab: {
            key: 9,
            handler: () => moveFocusToNextElement(quillRef.current?.getEditor()),
          },
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
      },
    }),
    [],
  )

  const handleOpenCharactersModal = () => {
    setOpenCharactersModal(true)
  }

  const handleCloseCharactersModal = () => {
    setOpenCharactersModal(false)
  }

  const insertSpecialCharacter = useCallback((character: string) => {
    const editor: any = quillRef.current?.getEditor()
    const cursorIndex = editor?.selection?.savedRange?.index
    editor.insertText(cursorIndex || 0, character)
  }, [])

  useEffect(() => {
    quillRef.current?.getEditor().enable(!isDisabled)
  }, [isDisabled])

  useEffect(() => {
    quillRef.current?.getEditor().keyboard.addBinding(
      {
        key: 'V',
        shortKey: true,
      },
      () => pasteText(quillRef.current?.getEditor()),
    )
    quillRef.current?.getEditor().keyboard.addBinding(
      {
        key: 'C',
        shortKey: true,
      },
      () => copyText(quillRef.current?.getEditor()),
    )
  }, [])

  return (
    <>
      <StyledBox className='text-editor' {...TEXT_EDITOR_ATTRIBUTES} {...accessibilityAttr}>
        <CustomEditorToolbar disabled={isDisabled} handleOpenCharactersModal={handleOpenCharactersModal} />
        <ReactQuill
          theme='snow'
          modules={editorModules}
          formats={editorFormats}
          value={value}
          onChange={(_value, _delta, _source, editor) => {
            if (editor.getLength() >= expectedLength) {
              quillRef.current?.getEditor().deleteText(expectedLength, editor.getLength())
            }
            const text = editor.getText(0, editor.getLength())
            const html = text.trim() ? editor.getHTML() : ''
            changeHandler(text, html, text.split('\n').length - 1)
          }}
          placeholder={placeholder}
          ref={quillRef}
        />
        <CustomEditorFooter
          textLength={quillRef.current?.getEditor().getLength() - 1 || 0}
          maxTextLength={expectedLength}
        />
      </StyledBox>

      <SpecialCharactersModal
        open={openCharactersModal}
        onClose={handleCloseCharactersModal}
        insertSpecialCharacter={insertSpecialCharacter}
      />
    </>
  )
}
