import { KeyboardSensor, KeyboardSensorProps } from '@dnd-kit/core'

export class FocusKeyboardSensor extends KeyboardSensor {
  focusProps: KeyboardSensorProps
  focusListeners: {
    target: EventTarget
    listeners: any[]
  }

  constructor(props: KeyboardSensorProps) {
    super({...props})

    const {
      event: { target },
    } = props
    this.focusProps = props
    this.focusListeners = {
      target,
      listeners: [],
    }
    this.handleBlur = this.handleBlur.bind(this)
    this.attachFocusHandlers()
  }

  attachFocusHandlers() {
    setTimeout(() => {
      this.focusProps.event.target.addEventListener('blur', this.handleBlur)
      this.focusListeners.listeners.push(['blur', this.handleBlur])
    })
  }

  handleBlur(event: any) {
    const { onCancel } = this.focusProps
    event.preventDefault()
    this.detachFocusListeners()
    this['detach']()
    onCancel()
  }

  private detachFocusListeners() {
    this.focusListeners.listeners.forEach(listener => {
      this.focusListeners.target.removeEventListener(listener[0], listener[1])
    })
    this.focusListeners.listeners = []
    this.focusListeners.target = null
  }
}

