import { FC } from 'react'
import { Box, styled } from '@mui/material'
import { RemoveButton } from '@app/components'
import { DraggableGapImg, DraggableGapImgProps } from '../../DraggableGapImg'

export interface GraphicGapMatchImageProps extends DraggableGapImgProps {
  removable?: boolean
  onRemove: (e?: React.MouseEvent) => void
}

export const GraphicGapMatchImage: FC<GraphicGapMatchImageProps> = props => {
  const { onRemove, removable, id, ...gapImageProps } = props

  const gapImage = <StyledDraggableGapImg id={id} placed {...gapImageProps} />

  if (!removable) {
    return gapImage
  }

  return (
    <RemoveButton
      onRemove={onRemove}
      wrapper={StyledWrapper}
      placement='right-start'
      selected={props.selected}
    >
      {gapImage}
    </RemoveButton>
  )
}

const StyledWrapper = styled(Box)(() => ({}))

const StyledDraggableGapImg = styled(DraggableGapImg)(({ selected }) => ({
  zIndex: selected ? 100 : 1,
}))
