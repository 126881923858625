import { useEffect, useMemo, useState } from 'react'
import { useSnapshot } from 'valtio'
import sum from 'lodash/sum'
import { CurrentTestState, ScoringState } from '@app/storage'
import { ItemResponse, ItemScoring, ScoringResponse } from '@app/models'
import { isEqualArray } from '../utils'
import { ResponsesByPracticeTestItem, ResponsesByPracticeTestItemResponseDeclaration } from '@app/types'

export const useAssessmentItemScoring = (itemId: string): ItemScoring => {
  const { testScoring } = useSnapshot(ScoringState)

  const [itemScoring, setItemScoring] = useState<ItemScoring>(null)

  useEffect(() => {
    const itemScoring = testScoring?.byItem[itemId] as ItemScoring
    setItemScoring(itemScoring ?? null)
  }, [testScoring, itemId])

  return itemScoring
}

export const useAssessmentItemGroupScoring = (itemId: string): ResponsesByPracticeTestItem => {
  const { groupTestScoring } = useSnapshot(ScoringState)

  const [itemGroupScoring, setItemGroupScoring] = useState<ResponsesByPracticeTestItem>(null)

  useEffect(() => {
    const itemGroupScoring = groupTestScoring?.items?.find(
      item => item.itemId === itemId,
    ) as ResponsesByPracticeTestItem
    setItemGroupScoring(itemGroupScoring ?? null)
  }, [groupTestScoring, itemId])

  return itemGroupScoring
}

export type UseAssessmentItemResponseScoring = {
  scoring: ScoringResponse | null
  correct?: boolean
  raw?: number
  possible?: number
  itemResponse?: ItemResponse
  groupMode: boolean
  groupScoring?: ResponsesByPracticeTestItemResponseDeclaration | null
  groupStatistics?: Record<string, number>
  correctResponse?: string[]
}

export const useAssessmentItemResponseScoring = (
  itemId: string,
  responseDeclarationId: string,
): UseAssessmentItemResponseScoring => {
  const { itemsResponses } = useSnapshot(CurrentTestState)
  const { groupMode, groupTestScoring, groupCorrectAnswers } = useSnapshot(ScoringState)
  const itemResponse = itemsResponses
    ?.find(itemResponse => itemResponse.itemId === itemId)
    ?.responses?.find(response => response.responseDeclarationId === responseDeclarationId) as ItemResponse

  const itemScoring = useAssessmentItemScoring(itemId)
  const scoring =
    itemScoring?.correctResponses?.find(
      response => response.responseDeclarationId === responseDeclarationId,
    ) ?? null

  // If there is scoring data, validate answer.
  // It's correct if given answer matches correct or if raw score equals possible
  // (e.g. for ExtendedText interaction).
  let correct

  if (scoring?.response && itemResponse?.response) {
    if (scoring.interactionType === 'extendedText') {
      correct = itemScoring.raw === itemScoring.possible
    } else {
      correct =
        isEqualArray(scoring.response as string[], itemResponse.response as string[]) ||
        itemScoring.raw === itemScoring.possible
    }
  }

  const itemGroupScoring = useAssessmentItemGroupScoring(itemId)
  const groupScoring =
    itemGroupScoring?.responseDeclarations?.find(
      responseDeclaration => responseDeclaration.responseDeclarationId === responseDeclarationId,
    ) ?? null

  let correctResponse = scoring?.response

  if (groupMode) {
    const correctItem = groupCorrectAnswers?.find(item => item.itemId === itemId)
    correctResponse = correctItem?.responses?.find(
      response => response.responseDeclarationId === responseDeclarationId,
    )?.response
  }

  const groupStatistics = useMemo(() => {
    const groupStatistics: Record<string, number> =
      groupScoring?.responses?.reduce((statistics, response) => {
        const count = 'seqCounts' in response ? response.seqCounts[0].count : response.count
        const responseValue = 'response' in response ? response.response : response.points

        return {
          ...statistics,
          [responseValue]: count / groupTestScoring?.studentCount,
        }
      }, {}) ?? {}

    let maxPercentage = correctResponse?.length ?? 1

    if (!groupScoring?.responses) {
      maxPercentage = 1
    }

    groupStatistics['none'] = maxPercentage - sum(Object.values(groupStatistics))

    return groupStatistics
  }, [groupScoring?.responses, groupTestScoring?.studentCount, correctResponse?.length])

  return {
    scoring,
    correct,
    raw: itemScoring?.raw,
    possible: itemScoring?.possible,
    itemResponse,
    groupMode,
    groupScoring,
    groupStatistics,
    correctResponse,
  }
}
