import { Theme } from '@mui/material'

export const scrollBarMixin = (theme: Theme, orientation: 'vertical' | 'horizontal' = 'vertical') => `

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    ${orientation === 'vertical' ? `margin: ${theme.spacing(3, 0)}` : ''};
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${theme.palette.grey[100]};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${theme.palette.grey[300]};
  }
`

export const scrollBarMixinObj = (theme: Theme, orientation: 'vertical' | 'horizontal' = 'vertical') => ({
  '&::-webkit-scrollbar': {
    width: orientation === 'vertical' ? '10px' : undefined,
    height: orientation === 'horizontal' ? '10px' : undefined,
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
    margin: orientation === 'vertical' ? theme.spacing(3, 0) : undefined,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey[100],
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.grey[300],
  },
})
