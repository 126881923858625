import { FC } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  styled,
  inputClasses,
  inputBaseClasses,
  FormControlProps,
  InputLabelProps,
} from '@mui/material'
import { focusStyle } from '@app/theme'

export const SelectComponentItem = styled(MenuItem)(
  ({ theme }) => `
    font-size: ${theme.typography.body2.fontSize};
    line-height: ${theme.typography.body2.lineHeight};
    min-height: 34px;
    padding: ${theme.spacing(2, 3)};
    
    &:hover {
      background-color: ${theme.palette.grey[50]};
    }
    
    &.Mui-selected {
      background-color: ${theme.palette.blue[50]};
    }

    & .MathJax, & .katex {
      padding-left: ${theme.spacing(1)};
      padding-right: ${theme.spacing(1)};
    }
  `,
)

export type SelectComponentProps = SelectProps & { inline?: boolean }

export const SelectComponentBase = styled(({ inline, ...props }: SelectComponentProps) => (
  <Select {...props} />
))(
  ({ theme, inline, label }) => `
    min-height: ${theme.spacing(inline ? 9 : 15)};
    outline: none;
    color: ${theme.palette.grey[800]};
    border-radius: ${theme.shape.borderRadius * 1.5}px;
    background-color: ${theme.palette.background.paper};
    transition: ${theme.transitions.create(['background-color'])};
    ${inline ? `margin: ${theme.spacing(0.5, 2)};` : ''}

    &.${inputBaseClasses.sizeSmall} {
      min-height: ${theme.spacing(inline ? 7.5 : 13)};
    }
    
    & svg {
      transform: none !important;
      color: ${theme.palette.additionalColors['bg_500']};
    }
    
    & ${inputBaseClasses.input} {
      color: ${theme.palette.grey[800]};
    }
    
    & > .MuiSelect-select {
      padding-top: ${theme.spacing(2.5)} !important;
      padding-bottom: ${theme.spacing(2.5)} !important;
      padding-left: ${theme.spacing(inline ? 3 : 4)} !important;
      ${label ? 'margin-top: 1rem;' : ''}
      font-size: ${theme.typography[inline ? 'body2' : 'body1'].fontSize};
      ${
        inline
          ? `
      line-height: 1rem;
      box-sizing: border-box;
      min-height: ${theme.spacing(9)};
      `
          : ''
      }

      &.${inputClasses.inputSizeSmall} {
        padding-top: ${theme.spacing(inline ? 2 : 1)} !important;
        padding-bottom: ${theme.spacing(inline ? 2 : 1)} !important;
        padding-left: ${theme.spacing(inline ? 3 : 4)} !important;
        font-size: ${theme.typography.body2.fontSize};
        ${label ? `margin-top: 1rem;` : ''}
        min-height: ${inline ? theme.spacing(7.5) : ''};

      }
    }
    
    & fieldset {
      transition: ${theme.transitions.create(['border-color'])};
      border-width: 1px !important;
      border-color: ${theme.palette.grey[200]} !important;
      top: 0;

      & > legend {
        display: none;
      }
    }
    
    
    &.Mui-focused {
      background-color: ${theme.palette.background.paper};
      outline: ${focusStyle(theme).border.outline};
      & fieldset {
        border-color: ${focusStyle(theme).colors.border} !important;
      }
    }
    
    & .MuiMenu-list {
      padding-top: ${theme.spacing(1)};
      padding-bottom: ${theme.spacing(1)};
      box-shadow: ${theme.shadows[6]};
    }

    & .MathJax, & .katex {
      padding-left: ${theme.spacing(1)};
      padding-right: ${theme.spacing(1)};
    }
    
    ${[theme.breakpoints.down('mobile')]} {
      height: 30px;
      ${inline ? `margin: ${theme.spacing(0.5, 1)};` : ''}

      & > .MuiSelect-select {
        padding-top: ${theme.spacing(1.75)} !important;
        padding-bottom: ${theme.spacing(1.75)} !important;
      }
    }
  `,
)

const StyledLabel = styled(({ inline, ...props }: InputLabelProps & { inline?: boolean }) => (
  <InputLabel {...props} />
))(({ theme, inline }) => ({
  transform: 'none',
  color: theme.palette.grey[500],
  fontSize: theme.typography.caption.fontSize,
  left: theme.spacing(inline ? 3 : 4),
  top: theme.spacing(2.5),
  lineHeight: theme.typography.caption.lineHeight,
  '&.Mui-focused': {
    color: theme.palette.grey[500],
  },
}))

const StyledFormControl = styled(({ inline, ...props }: FormControlProps & { inline?: boolean }) => (
  <FormControl {...props} />
))(({ inline }) => ({
  display: inline ? 'inline-flex' : 'flex',

  [`${SelectComponentBase}`]: {
    margin: 0,
  },
}))

export const SelectComponent: FC<SelectComponentProps> = ({ id, className, style, label, ...props }) => {
  return (
    <StyledFormControl className={className} style={style} inline={props.inline}>
      {label && (
        <StyledLabel id={props.labelId} inline={props.inline} shrink>
          {label}
        </StyledLabel>
      )}
      <SelectComponentBase label={label} {...props} />
    </StyledFormControl>
  )
}
