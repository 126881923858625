import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, FormControlLabel, FormGroup, Stack, Switch, TextField, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird'
import {
  GENERATE_LINK_BTN_ATTRIBUTES,
  START_TEST_BTN_ATTRIBUTES,
  UPLOAD_BTN_ATTRIBUTES,
} from '@app/constants'
import { AssessmentSettings } from '@app/firebase/api'
import { useAssessmentSetup } from '@app/helpers'
import {
  resetCurrentTestState,
  resetCurrentQtiPackage,
  resetUserAssessmentState,
  stopSyncInterval,
} from '@app/storage'

export const SettingsForm: React.FC<{}> = () => {
  const navigate = useNavigate()

  const [file, setFile] = useState<File>(null)
  const [isResumable, setResumable] = useState(true)
  const [timeLimit, setTimeLimit] = useState<number>(20)
  const [maxIdletime, setMaxIdleTime] = useState<number>(5)
  const [syncInterval, setSyncInterval] = useState<number>(3)
  const [settings, setSettings] = useState<AssessmentSettings>()

  const { link: assessmentLink, runFirebaseSetup, loading } = useAssessmentSetup(file, settings)

  useEffect(() => {
    if (timeLimit >= 0 && maxIdletime > 0 && syncInterval > 0) {
      setSettings({
        timeLimit: timeLimit * 60,
        maxIdletime,
        isResumable,
        syncInterval,
      })
    }
  }, [file, timeLimit, maxIdletime, syncInterval, isResumable])

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length) {
      resetCurrentTestState()
      resetCurrentQtiPackage()
      resetUserAssessmentState()
      stopSyncInterval()
      sessionStorage.clear()
      setFile(event.target.files[0])
    }
  }

  return (
    <Stack gap={4}>
      <Typography variant='h2'>Assessment settings</Typography>
      <TextField
        required
        type='number'
        id='time-limits'
        label='Time limits (minutes)'
        value={timeLimit.toString()}
        onChange={e => setTimeLimit(Number(e.target.value))}
        inputProps={{ min: 0, step: 0.1 }}
      />
      <TextField
        required
        type='number'
        id='idle-time'
        label='Maximum idle time (minutes)'
        value={maxIdletime.toString()}
        onChange={e => setMaxIdleTime(Number(e.target.value))}
        inputProps={{ min: 0, step: 0.1 }}
      />
      <TextField
        required
        type='number'
        id='idle-time'
        label='Events sync interval (minutes)'
        value={syncInterval.toString()}
        onChange={e => setSyncInterval(Number(e.target.value))}
        inputProps={{ min: 0, step: 0.1 }}
      />
      <FormGroup>
        <FormControlLabel
          control={<Switch defaultChecked value={isResumable} onChange={() => setResumable(val => !val)} />}
          label='Resumable?'
        />
      </FormGroup>
      <Button
        variant='contained'
        component='label'
        color='secondary'
        startIcon={<FontAwesomeIcon icon={faUpload} />}
        fullWidth
      >
        Upload QTI package
        <input hidden type='file' onChange={e => handleFile(e)} {...UPLOAD_BTN_ATTRIBUTES} />
      </Button>

      <Button
        disabled={!file || !syncInterval || !maxIdletime || loading}
        variant='contained'
        color='secondary'
        onClick={() => runFirebaseSetup()}
        fullWidth
        {...GENERATE_LINK_BTN_ATTRIBUTES}
      >
        {loading ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : 'Generate assessment link'}
      </Button>
      {assessmentLink && (
        <Button
          variant='contained'
          color='primary'
          onClick={() => navigate(assessmentLink)}
          fullWidth
          {...START_TEST_BTN_ATTRIBUTES}
        >
          Start test
        </Button>
      )}
    </Stack>
  )
}

export default SettingsForm
