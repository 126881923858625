import { ChartDomain, Coordinates } from '../../GraphingInteraction/types'
import { Interval, IntervalDirection } from '../types'

export const getIntervalDomain = (chartDomain: ChartDomain, interval: Interval): Pick<ChartDomain, 'x'> => {
  return {
    x: [
      interval.direction === IntervalDirection.LEFT ? chartDomain.x[0] : interval.point.x,
      interval.direction === IntervalDirection.RIGHT ? chartDomain.x[1] : interval.point.x,
    ],
  }
}

export const getIntervalData = (chartDomain: ChartDomain, interval: Interval): Coordinates[] => {
  return [
    interval.direction === IntervalDirection.LEFT ? { x: chartDomain.x[0], y: 0 } : undefined,
    { x: interval.point.x, y: 0 },
    interval.direction === IntervalDirection.RIGHT ? { x: chartDomain.x[1], y: 0 } : undefined,
  ].filter(i => i)
}
