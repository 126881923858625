import { badgeClasses, Box, BoxProps, IconButton, styled } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TooltipFeedbackProps } from '@app/components/feedback/TooltipFeedback'
import { TooltipFeedbackGroupProps } from '@app/components/feedback/TooltipFeedbackGroup'
import { focusStyle } from '@app/theme'

export const BasicDragContainerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  ':not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}))

export const IndexWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[800],
  fontSize: theme.typography.body2.fontSize,
  marginRight: theme.spacing(2),
}))

interface ContentWrapperProps {
  assigned: boolean
}

export const ContentWrapper = styled(Box)<ContentWrapperProps>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  letterSpacing: 0,
  lineHeight: theme.typography.body1.lineHeight,

  [theme.breakpoints.down('mobile')]: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },
}))

export const RemoveWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-end',
  width: '30px',
  margin: theme.spacing(-1, -2, -1, 0),
}))

interface RemoveButtonProps {
  selected: boolean
}

export const RemoveButton = styled(IconButton)<RemoveButtonProps>(({ theme, selected }) => ({
  opacity: selected ? 1 : 0,

  ':focus-visible': {
    opacity: 1,
    color: 'transparent',
    backgroundColor: `${theme.palette.blue[500] + ' !important'}`,
  },

  ':hover': {
    backgroundColor: theme.palette.blue[500],
  },

  [`${theme.breakpoints.down('mobile')}, (hover: none)`]: {
    visibility: selected ? 'visible' : 'hidden',
  },
}))

interface StyledDragContainerProps extends BoxProps {
  disabled?: boolean
  drag?: boolean
  assigned?: boolean
  placehold?: boolean
  selected?: boolean
  fluid?: boolean
}
const STYLED_DRAG_CONTAINER_PROPS = ['disabled', 'drag', 'assigned', 'placehold', 'selected', 'fluid']

export const StyledBasicDragContainer = styled(Box, {
  shouldForwardProp: (prop: string) => !STYLED_DRAG_CONTAINER_PROPS.includes(prop),
})<StyledDragContainerProps>(({ theme, disabled, drag, assigned, placehold, selected, fluid = false }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  wordWrap: 'break-word',
  hyphens: 'auto',
  padding: theme.spacing(2, 3),
  borderRadius: theme.shape.borderRadius * 1.5,
  boxShadow: theme.shadows[2],
  border: '1px solid transparent',
  outline: '3px solid transparent',
  cursor: 'pointer',
  userSelect: 'none',
  position: 'relative',

  ...(disabled && {
    pointerEvents: 'none',
    boxShadow: 'none',
  }),

  '&:focus-visible': {
    ...((!disabled || !(selected && drag)) && {
      ...focusStyle(theme).border,

      ...(drag && {
        outline: '3px solid transparent',
        border: '1px solid transparent',
      }),

      '& button': {
        opacity: 1,
      },
    }),
  },

  ...(!assigned
    ? {
        opacity: drag && placehold ? 0 : 1,
        color: disabled ? theme.palette.grey[400] : theme.palette.grey[800],
        border: `${(selected || placehold) && !disabled ? '2px' : '1px'} solid ${
          placehold ? 'transparent' : theme.palette.blue[300]
        }`,
        borderColor: disabled || drag ? theme.palette.grey[50] : theme.palette.blue[300],
        backgroundColor: 'white',

        ...((selected || placehold) && {
          backgroundColor: theme.palette.blue[100],
        }),

        ...((disabled || drag) && {
          backgroundColor: theme.palette.grey[50],
        }),

        ...(drag && !disabled && { boxShadow: 'none' }),

        ...(placehold &&
          !disabled && {
            boxShadow: theme.shadows[6],
          }),

        ...(!disabled &&
          !selected &&
          !drag &&
          !placehold && {
            '&:hover': {
              [theme.breakpoints.up('mobile')]: {
                backgroundColor: theme.palette.blue[50],
              },
            },
          }),
      }
    : {
        color: drag ? 'transparent' : 'white',
        backgroundColor: drag ? theme.palette.blue[50] : theme.palette.blue[600],
        border:
          selected && !drag
            ? `2px solid ${theme.palette.blue[200]}`
            : `2px dashed ${drag ? theme.palette.grey[500] : theme.palette.grey[100]}`,
        boxShadow: selected && !drag ? theme.shadows[6] : 'none',
        backgroundClip: 'padding-box',

        '&:hover': {
          [theme.breakpoints.up('mobile')]: {
            backgroundColor: theme.palette.blue[500],

            button: {
              opacity: 1,
            },
          },
        },
      }),

  ...(!fluid && { width: '150px' }),
}))

export const TimesCircleIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.common.white,
}))

export const StyledReviewBadge = styled(Box)<TooltipFeedbackProps | TooltipFeedbackGroupProps>(
  ({ theme }) => ({
    [`& .${badgeClasses.anchorOriginTopLeftRectangular}`]: {
      [theme.breakpoints.down('mobile')]: {
        left: '2px',
        top: '4px',
      },
    },
  }),
)
