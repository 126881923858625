import {
  AssesmentSectionSelection,
  AssessmentItem,
  AssessmentSection,
  AssessmentSectionOrdering,
  AssessmentSectionRubricBlock,
  AssessmentTest,
  TestPart,
  View,
} from '@app/models'
import { CurrentQtiPackageState } from '@app/storage'
import { FeedbackTypes, ItemSessionControl } from '@app/types'
import { getMpQtiDataAttributes } from '../converters'
import { parseDataRef, parseFeedback, parseStyleSheet, parseTimeLimits } from './genericParsers'

/**
 * Assessment test object parser.
 * @param { Object } data raw assessment test data object.
 * @returns { AssessmentTest } parsed assessment test object.
 */
export const parseAssessmentTest = (
  data: any,
  resourceId: string,
  rubricBlockContent?: string,
): AssessmentTest => {
  const test = data.assessmentTest

  return {
    id: test.attributes.identifier,
    resourceId,
    title: test.attributes.title,
    testPart: parseTestParts(test.testPart, rubricBlockContent),
    ...(test.timeLimits && { timeLimits: parseTimeLimits(test.timeLimits) }),
    ...(test.testFeedback && { testFeedback: parseFeedback(test.testFeedback, FeedbackTypes.test) }),
    ...(test.stylesheet && { stylesheet: parseStyleSheet(test.stylesheet) }),
  }
}

/**
 * Test part parser.
 * @param { Object } testPart raw test part data object.
 * @returns { TestPart } parsed test part.
 */
export const parseTestPart = (testPart: any, rubricBlockContent?: string): TestPart => {
  return {
    id: testPart.attributes.identifier,
    navigationMode: testPart.attributes.navigationMode,
    submissionMode: testPart.attributes.submissionMode,
    timeLimits: parseTimeLimits(testPart.timeLimits),
    itemSessionControl: parseItemSessionControl(testPart.itemSessionControl),
    assessmentSections: parseAssessmentSections(testPart.assessmentSection, rubricBlockContent),
    twoColumnLayout: getMpQtiDataAttributes(testPart.attributes).twoColumnLayout === 'true',
  }
}

/**
 * Test parts parser.
 * @param { Object } testParts raw test parts data object.
 * @returns { Array<TestPart> } array of parsed test parts.
 */
export const parseTestParts = (testParts: any, rubricBlockContent?: string): TestPart[] => {
  if (Array.isArray(testParts)) {
    return testParts.map(testPart => parseTestPart(testPart, rubricBlockContent))
  }

  return [parseTestPart(testParts, rubricBlockContent)]
}

/**
 * Item session control parser.
 * @param { Object } data item session control raw data.
 * @returns { ItemSessionControl } parsed item session control object.
 */
export const parseItemSessionControl = (data: any): ItemSessionControl => {
  return {
    maxAttemps: Number(data.attributes.maxAttemps) || 0,
    showFeedback: data?.attributes?.showFeedback === 'true',
    allowReview: data?.attributes?.allowReview === 'true' || true,
    showSolution: data?.attributes?.showSolution === 'true',
    allowComment: data?.attributes?.allowComment === 'true',
    allowSkipping: data?.attributes?.allowSkipping === 'true' || true,
    validateResponses: data?.attributes?.validateResponses === 'true',
  }
}

/**
 * Assessment section recursive parser.
 * @param { Object | Array<Object> } data assessment section raw data.
 * @returns { Array<AssessmentSection> } array of parsed sections.
 */
export const parseAssessmentSections = (data: any, rubricBlockContent?: string): AssessmentSection[] => {
  if (Array.isArray(data)) {
    return data.map<AssessmentSection>(section => ({
      id: section.attributes?.identifier,
      required: section.attributes?.required === 'true',
      title: section.attributes?.title,
      fixed: section.attributes?.fixed === 'true',
      visible: section.attributes?.visible === 'true',
      keepTogether: section.attributes.keepTogether === 'true',
      items: section?.assessmentItemRef
        ? getItemsByRefs(section?.assessmentItemRef, section?.attributes?.identifier)
        : [],
      ...(section?.itemSessionControl && {
        itemSessionControl: parseItemSessionControl(section?.itemSessionControl),
      }),
      ...(section?.timeLimits && { timeLimits: parseTimeLimits(section?.timeLimits) }),
      ...(section?.assessmentSectionRef && { sectionRef: parseDataRef(section?.assessmentSectionRef) }),
      ...(section?.assessmentSection && { subsections: parseAssessmentSections(section?.assessmentSection) }),
      selection: section.selection && parseAssesmentSectionSelection(section.selection),
      ordering: section.ordering && parseAssessmentSectionOrdering(section.ordering),
      rubricBlock:
        section.rubricBlock && parseAssessmentSectionRubric(section.rubricBlock, rubricBlockContent),
    }))
  }

  return [
    {
      id: data.attributes?.identifier,
      required: data.attributes?.required === 'true' || false,
      title: data.attributes?.title,
      fixed: data.attributes?.fixed === 'true' || false,
      visible: data.attributes?.visible === 'true' || false,
      keepTogether: data.attributes?.keepTogether === 'true',
      items: data?.assessmentItemRef
        ? getItemsByRefs(data?.assessmentItemRef, data?.attributes?.identifier)
        : [],
      ...(data?.itemSessionControl && {
        itemSessionControl: parseItemSessionControl(data?.itemSessionControl),
      }),
      ...(data?.timeLimits && { timeLimits: parseTimeLimits(data?.timeLimits) }),
      ...(data?.assessmentSectionRef && { sectionRef: parseDataRef(data?.assessmentSectionRef) }),
      ...(data.assessmentSection && { subsections: parseAssessmentSections(data.assessmentSection) }),
      selection: data.selection && parseAssesmentSectionSelection(data.selection),
      ordering: data.ordering && parseAssessmentSectionOrdering(data.ordering),
      rubricBlock: data.rubricBlock && parseAssessmentSectionRubric(data.rubricBlock, rubricBlockContent),
    },
  ]
}

const parseAssessmentSectionRubric = (
  rubricBlockData: any,
  rubricBlockContent?: string,
): AssessmentSectionRubricBlock => {
  const { attributes } = rubricBlockData

  return {
    use: attributes.use,
    view: attributes.view as View,
    content: rubricBlockContent,
    // TODO: handle "stylesheet" and "apipAccessibility"
  }
}

/**
 * Get assessment items by their references in section.
 * @param { Object<any> } refs
 * @returns { Array<AssessmentItem> } assessment section items.
 */
const getItemsByRefs = (refs: any, sectionId: string): AssessmentItem[] => {
  const items = JSON.parse(JSON.stringify(CurrentQtiPackageState.items))
  const parsedRefs = parseDataRef(refs)

  return parsedRefs.map<AssessmentItem>(ref => {
    const absolutePath = ref.url.includes('../') ? ref.url.replaceAll('../', '') : ref.url
    const item = items.find((item: any) => item.id === ref.id || item.path.includes(absolutePath))

    return {
      ...item,
      required: ref.required,
      sectionId: sectionId,
    }
  })
}

const parseAssesmentSectionSelection = (data: any): AssesmentSectionSelection => {
  return {
    select: data?.attributes?.select && Number(data.attributes.select),
    withReplacement: data?.attributes?.withReplacement === 'true',
    extension: data?.attributes?.extension,
  }
}

const parseAssessmentSectionOrdering = (data: any): AssessmentSectionOrdering => {
  return {
    shuffle: data?.attributes?.shuffle === 'true',
    extension: data?.attributes?.extension,
  }
}
