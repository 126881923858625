export enum DomNodes {
  span = 'span',
  div = 'div',
  image = 'img',
  math = 'math',
  typography = 'p',
  typographyH1 = 'h1',
  typographyH2 = 'h2',
  typographyH3 = 'h3',
  typographyH4 = 'h4',
  typographyH5 = 'h5',
  typographyH6 = 'h6',
  textEntryInteraction = 'textentryinteraction',
  infoControl = 'infocontrol',
  choiceInteraction = 'choiceinteraction',
  simpleChoice = 'simplechoice',
  prompt = 'prompt',
  feedbackInline = 'feedbackinline',
  feedbackBlock = 'feedbackblock',
  extendedTextInteraction = 'extendedtextinteraction',
  associateInteraction = 'associateinteraction',
  simpleAssociableChoice = 'simpleassociablechoice',
  orderInteraction = 'orderinteraction',
  hotspotInteraction = 'hotspotinteraction',
  hotspotChoice = 'hotspotchoice',
  object = 'object',
  matchInteraction = 'matchinteraction',
  simpleMatchSet = 'simplematchset',
  inlineChoiceInteraction = 'inlinechoiceinteraction',
  inlineChoice = 'inlinechoice',
  gapMatchInteraction = 'gapmatchinteraction',
  gapText = 'gaptext',
  gapImg = 'gapimg',
  gap = 'gap',
  blockQuote = 'blockquote',
  graphicOrderInteraction = 'graphicorderinteraction',
  graphicAssociateInteraction = 'graphicassociateinteraction',
  associableHotspot = 'associablehotspot',
  selectPointInteraction = 'selectpointinteraction',
  hotTextInteraction = 'hottextinteraction',
  hotText = 'hottext',
  graphicGapMatchInteraction = 'graphicgapmatchinteraction',
  positionObjectStage = 'positionobjectstage',
  positionObjectInteraction = 'positionobjectinteraction',
  rubricBlock = 'rubricblock',
  customInteraction = 'custominteraction',
  gridInColumn = 'gridincolumn',
  gridInRow = 'gridinrow',
  gridInSymbols = 'gridinsymbols',
  gridInSymbol = 'gridinsymbol',
  equationNumbersSection = 'equationnumberssection',
  equationActionsSection = 'equationactionssection',
  equationOperators = 'equationoperators',
  equationOperator = 'equationoperator',
}
