import { useCallback } from 'react'
import { LocalStorageService } from '@app/services'
import { StorageKeys } from '@app/constants'

export const useTestCallback = () => {
  const onTestCallback = useCallback(() => {
    const callbackUrl: string = LocalStorageService.getValue(StorageKeys.CallbackUrl)
    window.open(callbackUrl ?? process.env.REACT_APP_TEST_CALLBACK_URL, '_self')
  }, [])

  return onTestCallback
}
