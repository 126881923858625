import { useSearchParams } from "react-router-dom"
import { getHighlighterKey } from "../utils"
import { useHighlighter } from "./highlighter"
import {
  HIGHLIGHT_CLASSNAME,
  HIGHLIGHT_CONTAINER_PASSAGE,
  HIGHLIGHT_CONTAINER_QUESTION,
} from "@app/constants"

type HighlighterScope = 'q'|'p'

export const useScopedHighlighter = (scope: HighlighterScope, entityId: string) => {
  const [searchParams] = useSearchParams()
  const highlighterKey = getHighlighterKey(
    scope,
    searchParams.get('userId'),
    searchParams.get('id'),
    entityId,
  )
  const containerId = scope === 'q' ? HIGHLIGHT_CONTAINER_QUESTION : HIGHLIGHT_CONTAINER_PASSAGE
  const highlighter = useHighlighter(HIGHLIGHT_CLASSNAME, highlighterKey, containerId)

  return highlighter
}