import { forwardRef, SVGProps } from 'react'
import { Color } from '@mui/material'

export interface ColorIndexGroupProps extends SVGProps<SVGGElement> {
  colorIndex: number
  selected?: boolean
  disabled?: boolean
  colorOptions?: ColorOptions
}

export interface ColorOptions {
  hueValue?: keyof Color
  focusFill?: boolean
  hoverOpacity?: number
}

export const ColorIndexGroup = forwardRef<SVGGElement, ColorIndexGroupProps>(
  ({ colorIndex, selected, colorOptions, ...props }, ref) => {
    return <g ref={ref} {...props} />
  },
)
