import React, { PropsWithChildren, useState } from 'react'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Dialog, IconButton, Paper, PaperProps, styled, Typography } from '@mui/material'
import { useDndMonitor, useDraggable } from '@dnd-kit/core'
import { Coordinates } from '@dnd-kit/utilities'
import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { focusStyle } from '@app/theme'

export type Props = PropsWithChildren<{
  id: string
  open: boolean
  title: string
  customDefaultCoordinates?: Coordinates
  onClose: () => void
}>

const PaperComponent = (props: PaperProps) => {
  return <StyledPaper {...props} />
}

const defaultCoordinates = {
  x: 10,
  y: 10,
}

const BasicDraggableDialog: React.FC<Props> = ({
  id,
  open,
  title,
  customDefaultCoordinates,
  onClose,
  children,
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  })

  const [{ x, y }, setCoordinates] = useState<Coordinates>(customDefaultCoordinates || defaultCoordinates)

  useDndMonitor({
    onDragEnd: ({ delta, active }) => {
      if (active?.id !== id) return

      setCoordinates(({ x, y }) => {
        return {
          x: x + delta.x,
          y: y + delta.y,
        }
      })
    },
  })

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined

  return (
    <StyledDialog
      ref={setNodeRef}
      open={open}
      onClose={onClose}
      disableEnforceFocus
      hideBackdrop
      PaperComponent={PaperComponent}
      TransitionProps={{
        onExited: () => setCoordinates(customDefaultCoordinates || defaultCoordinates),
      }}
      style={{ ...style, top: y, left: x }}
      {...attributes}
    >
      <StyledDialogHeader tabIndex={0} {...listeners}>
        <StyledIconButton onClick={onClose}>
          <StyledFaTimesIcon icon={faTimes} />
        </StyledIconButton>
        <Typography variant='h3' ml={4}>
          {title}
        </Typography>
      </StyledDialogHeader>
      {children}
    </StyledDialog>
  )
}

const DraggableDialog: React.FC<Props> = props => {
  const activationConstraint = {
    distance: 5,
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor),
  )
  return (
    <DndContext sensors={sensors} modifiers={[restrictToWindowEdges]}>
      <BasicDraggableDialog {...props} />
    </DndContext>
  )
}

const StyledDialog = styled(Dialog)(() => ({
  height: 'fit-content',
  width: 'fit-content',
}))

const StyledDialogHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  cursor: 'grab',
  border: '1px solid transparent',

  ':focus-visible': {
    ...focusStyle(theme).border,
  },
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(0),
  maxHeight: '100%',
  borderRadius: '3px',
  zIndex: 1000,
  boxShadow: theme.shadows[4],
}))

const StyledIconButton = styled(IconButton)(() => ({
  width: '1.875rem',
  height: '1.875rem',
  cursor: 'pointer',
}))

const StyledFaTimesIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.additionalColors.bg_500,
  fontSize: '1.125rem',
}))

export default DraggableDialog
