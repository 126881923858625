import { ResponseDeclaration } from '@app/models'
import { CurrentTestState } from '@app/storage'
import { Cardinality, InteractionType, SelectableChoiceConstraints } from '@app/types'
import { useEffect, useMemo, useState } from 'react'
import { useSnapshot } from 'valtio'
import { useResponseUpdate, useResponseValidityUpdate, useStateReset } from '../hooks'
import { useResponseDeclaration } from './common'

type SelectableChoiceType = {
  selected: string[]
  onSelect: (id: string) => void
  responseDeclaration: ResponseDeclaration
  isValid: boolean
  disabled: boolean
}

/**
 * Selection and response saving hook.
 * @param { String } responseIdentifier response declaration id.
 * @param { InteractionType } interactionType type of interaction.
 * @param { SelectableChoiceConstraints } constraints min and max choices.
 * @param { Boolean } allowSingleReselect automatically reselect item for single cardinality ("true" by default).
 * @returns { SelectableChoiceType }
 */
export const useSelectableChoice = (
  responseIdentifier: string,
  interactionType: InteractionType,
  constraints: SelectableChoiceConstraints = {},
  allowSingleReselect: boolean = true,
): SelectableChoiceType => {
  const { minChoices, maxChoices } = constraints
  const { currentTestPart, currentItemResponse } = useSnapshot(CurrentTestState)
  const [selected, setSelected] = useState<string[]>([])
  const [id, setId] = useState('')
  const responseDeclaration = useResponseDeclaration(id, responseIdentifier)

  useEffect(() => {
    if (!currentItemResponse?.id) {
      return
    }

    setId(CurrentTestState.currentItemResponse.id)
  }, [currentItemResponse?.id, currentItemResponse])

  // Update response on selected items change.
  useResponseUpdate(id, responseIdentifier, selected, interactionType)
  const isValid = useResponseValidityUpdate(id, selected.length, minChoices, maxChoices)
  const cardinality =
    maxChoices === 1 ? Cardinality.single : responseDeclaration?.cardinality || Cardinality.single
  const disabled = useMemo(
    () => maxChoices && selected.length === maxChoices && cardinality !== Cardinality.single,
    [selected.length, maxChoices, cardinality],
  )

  // Reset selected items when quiestion changes
  useStateReset(id, responseIdentifier, setSelected)

  const onSelect = (id: string) => {
    // If test is finished, don't change selection.
    if (currentTestPart?.isFinished) {
      return
    }

    setSelected(current => {
      if (current.includes(id)) {
        return current.filter(item => item !== id)
      }

      if (disabled && cardinality !== Cardinality.single) {
        return current
      }

      if (cardinality === Cardinality.single) {
        return allowSingleReselect || !current.length ? [id] : current
      }

      // REVIEW: no need to handle "ordered" crdinality anymore?
      if (cardinality === Cardinality.multiple || cardinality === Cardinality.ordered) {
        return [...current, id]
      }

      return current
    })
  }

  return { selected, onSelect, responseDeclaration, isValid, disabled }
}
