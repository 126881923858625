import { getChildrenDeep } from 'react-nanny'
import { GraphingInteractionProps, GraphingNodes } from '../types'

export const getElements = (children: JSX.Element | JSX.Element[], type: string): JSX.Element[] =>
  getChildrenDeep(children, (child: JSX.Element) => child.type === type)

export const parseChildrenToProps = (
  children: JSX.Element | JSX.Element[],
): Omit<GraphingInteractionProps, 'width' | 'height' | 'onChange'> => {
  const [graphTypeSelectElement] = getElements(children, GraphingNodes.graphTypeSelect)
  const graphTypeSelect = graphTypeSelectElement
    ? getElements(graphTypeSelectElement.props.children, GraphingNodes.graphType).map(element => ({
        value: element.props.identifier,
        label: element.props.children,
      }))
    : null

  const [xAxisElement, yAxisElement] = getElements(children, GraphingNodes.axis)
  const xAxis = {
    identifier: xAxisElement?.props?.identifier,
    label: xAxisElement?.props?.label,
    min: xAxisElement?.props?.min ? parseInt(xAxisElement.props.min) : 10,
    max: xAxisElement?.props?.max ? parseInt(xAxisElement.props.max) : 10,
    increment: xAxisElement?.props?.increment ? parseInt(xAxisElement.props.increment) : 10,
  }
  const yAxis = {
    identifier: yAxisElement?.props?.identifier,
    label: yAxisElement?.props?.label,
    min: yAxisElement?.props?.min ? parseInt(yAxisElement.props.min) : 10,
    max: yAxisElement?.props?.max ? parseInt(yAxisElement.props.max) : 10,
    increment: yAxisElement?.props?.increment ? parseInt(yAxisElement.props.increment) : 10,
  }

  const [staticPointsElement] = getElements(children, GraphingNodes.staticPoints)
  const pointsElements = getElements(staticPointsElement?.props?.children, GraphingNodes.point)
  const staticPoints = {
    points: pointsElements.map(pointElement => ({
      x: parseInt(pointElement.props.x),
      y: parseInt(pointElement.props.y),
    })),
    showLine: staticPointsElement?.props?.showline === 'true',
  }

  const graphsElements = getElements(children, GraphingNodes.graph)
  const graphsData = graphsElements.map(graphElement => ({
    id: graphElement.props.identifier,
    graphType: graphElement.props.graphtype,
    lineStyle: graphElement.props.linestyle,
    lineStyleToggle: graphElement.props.linestyletoggle,
    panelTitle: graphElement.props.paneltitle,
    minPoints: graphElement.props.minpoints,
    maxPoints: graphElement.props.maxpoints,
  }))

  const [solutionElement] = getElements(children, GraphingNodes.solution)
  const solutionSetEnabled = !!solutionElement
  const solutionSetButtonText = solutionElement?.props?.children

  return {
    xAxis,
    yAxis,
    graphTypeSelect,
    staticPoints,
    graphsData,
    solutionSetEnabled,
    solutionSetButtonText,
  }
}
