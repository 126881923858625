import React from 'react'
import { Navigate } from 'react-router-dom'
import { Box, Typography, CircularProgress } from '@mui/material'

import { useCustomTokenAuth } from '@app/helpers/hooks/auth'

const CustomLogin: React.FC = () => {
  const state = useCustomTokenAuth()

  if (state.redirectUrl) {
    return (
      <Navigate to={state.redirectUrl} />
    )
  }

  return (
    <Box
      padding={10}
      display='flex'
      flexDirection='column'
      justifyContent='center'
      textAlign='center'
      height='90vh'
      alignItems='center'
    >
      {state.validating && (
        <>
          <Box color='text.secondary' fontSize={'h3.fontSize'}>
            <CircularProgress />
          </Box>
          <Typography color='textSecondary' variant={'h6'}>
            Validating User...
          </Typography>
        </>
      )}
      {state.error && (
        <Typography color='textSecondary' variant={'h6'}>
          User validation failed. Error {state.error}
        </Typography>
      )}
    </Box>
  )
}

export default CustomLogin
