export function errorParse(error: any) {
  // console.log("error: " + JSON.stringify(error))

  if (error?.name === 'ApolloError') {
    if (error?.graphQLErrors?.length > 0) {
      const extension = error.graphQLErrors.map((e: any) => e.extensions)
      // console.log(
      //   "errorParsed Result: " +
      //     JSON.stringify({
      //       message: error.graphQLErrors.map(e => e.message).join("\n"),
      //       extension
      //     })
      // )

      return {
        message: error.graphQLErrors.map((e: any) => e.message).join('\n'),
        extension,
      }
    }
    if (error?.protocolErrors?.length > 0) {
      return { message: 'Protocol error: ' + error.protocolErrors.join('\n') }
    }
    if (error?.clientErrors?.length > 0) {
      return { message: 'Client error: ' + error.clientErrors.join('\n') }
    }
    if (error?.networkError != null) {
      return { message: 'network error occured' }
    }
  }
  /* Keep console log so we can see error always */
  console.log('Unknown error: ' + JSON.stringify(error, null, 2))
  return { message: 'Something went wrong' }
}
