import { FC } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogContentProps,
  DialogTitle,
  DialogTitleProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material'
import { scrollBarMixinObj } from '@app/constants'

export interface ConfirmModalProps {
  open?: boolean
  onCancel?: () => void
  onConfirm?: () => void
  title?: string
  content?: string
  submitText?: string
  cancelText?: string
  centerContent?: boolean
}

export const ConfirmModal: FC<ConfirmModalProps> = props => {
  const {
    open,
    onCancel,
    onConfirm,
    title,
    content,
    submitText = 'Submit',
    cancelText = 'Cancel',
    centerContent,
  } = props

  return (
    <StyledDialog open={open} onClose={onCancel} scroll='paper'>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <StyledContent alignCenter={centerContent}>{content}</StyledContent>
      <StyledActions>
        <StyledActionButton color='secondary' variant='contained' onClick={onConfirm}>
          {submitText}
        </StyledActionButton>
        <StyledActionButton color='grey' variant='text' size='small' onClick={onCancel}>
          {cancelText}
        </StyledActionButton>
      </StyledActions>
    </StyledDialog>
  )
}

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`.${dialogClasses.container}`]: {
    [`& > .${dialogClasses.paper}`]: {
      borderRadius: theme.shape.borderRadius * 1.5,
      maxWidth: '500px',
      width: '100%',
      padding: theme.spacing(12, 4, 8),

      [theme.breakpoints.down('tablet')]: {
        margin: theme.spacing(0),
      },
    },
  },
}))

const TitleTypography: FC<TypographyProps> = props => <Typography variant='h1' {...props} />
const DialogTitleTypography: FC<DialogTitleProps> = props => (
  <DialogTitle component={TitleTypography} {...props} />
)
export const StyledDialogTitle = styled(DialogTitleTypography)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  padding: 0,
  textAlign: 'center',
}))

const ContentBase: FC<DialogContentProps & { alignCenter?: boolean }> = ({ alignCenter, ...props }) => (
  <DialogContent {...props} />
)
export const StyledContent = styled(ContentBase)(({ alignCenter, theme }) => ({
  padding: theme.spacing(0, 4),
  textAlign: alignCenter ? 'center' : undefined,
  ...scrollBarMixinObj(theme, 'vertical'),
}))

export const StyledActions = styled(DialogActions)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(12),
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing(4),
}))

export const StyledActionButton = styled(Button)(() => ({
  margin: 0,

  ':not(:first-of-type)': { margin: 0 },
}))
