/* eslint-disable no-restricted-globals */
const idleModalTimerWorker = () => {
  let interval: any = null
  self.onmessage = (event: MessageEvent<string> ) => {
    switch (event.data) {
      case 'start': {
        interval = setInterval(() => {
          self.postMessage('tick')
        }, 1000)
        break
      }
      case 'reset': {
        clearInterval(interval)
        break
      }
    }
  }
}


let code = idleModalTimerWorker.toString()
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))

const blob = new Blob([code], { type: "application/javascript" })
const idleModalTimerWorkerScript = URL.createObjectURL(blob)

export default idleModalTimerWorkerScript
