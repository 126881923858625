import { alpha, Button, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TIMER_STATUS, TimerStatusType } from '@app/types'

export const TimeLimitsWrapper = styled('div')<{ showTimer: boolean }>(
  ({ theme, showTimer }) => `
    background-color: ${theme.palette.grey[900]};
    border-radius: ${theme.shape.borderRadius * 2.5}px;
    height: 47px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacing(7.5)};
    padding: ${theme.spacing(1, 1, 1, 4)};
    ${!showTimer ? `gap: ${theme.spacing(3)}` : ''};

    ${[theme.breakpoints.down('mobile')]} {
      height: 42px;
      gap: ${theme.spacing(3)};
      padding: ${theme.spacing(1, 1, 1, 3.5)};
    }
  `,
)

export const StyledTitle = styled(Typography)<{ timerStatus: TimerStatusType }>(
  ({ theme, timerStatus = TIMER_STATUS.initial }) => `
    margin-bottom: 0;
    color: ${
      timerStatus === TIMER_STATUS.low
        ? theme.palette.orange[400]
        : timerStatus === TIMER_STATUS.expiring
        ? theme.palette.red[400]
        : theme.palette.common.white
    };
    ${[theme.breakpoints.down('mobile')]} {
      font-size: ${theme.typography.h3.fontSize};
    }
  `,
)

export const StyledButton = styled(Button)(
  ({ theme }) => `
    height: 40px;
    width: 40px;
    min-width: unset;
    color: ${theme.palette.grey[100]};
    border-radius: 50%;

    &:hover {
      background: ${theme.palette.grey[700]};
      color: ${theme.palette.grey[50]};
    }

    ${[theme.breakpoints.down('mobile')]} {
      height: 32px;
      width: 32px;
    }
  `,
)

export const TimeSwitchViewIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,

  [theme.breakpoints.down('mobile')]: {
    fontSize: theme.typography.body2.fontSize,
  },
}))

export const Timer = styled(FontAwesomeIcon)(
  ({ theme }) => `
    font-size: 1.313rem;
    letter-spacing: 0;
    line-height: 1.375rem;
    color: ${theme.palette.grey[200]};

    ${[theme.breakpoints.down('mobile')]} {
      font-size: 1.125rem;
      line-height: 1.188rem;
    }
  `,
)

const StyledTooltipBase = ({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
)

export const StyledTooltip = styled(StyledTooltipBase)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: `${theme.spacing(1, 1.5)} !important`,
    margin: '0 !important',
    backgroundColor: alpha(theme.palette.grey[900], 0.65),
    fontSize: theme.typography.caption.fontSize,
    lineHeight: '0.813rem',
    borderRadius: theme.shape.borderRadius * 1.5,
  },
}))
