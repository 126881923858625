import { Box, styled, Typography } from '@mui/material'
import { forwardRef, KeyboardEvent, MouseEvent } from 'react'
import { CHECKBOX_ATTRIBUTES } from '@app/constants'
import { REVIEW_BADGE_ORIGIN, ReviewBadge } from '../../badges'
import { focusStyle } from '@app/theme'

export interface AnswerBubbleProps {
  symbol: string
  selected?: boolean
  correct?: boolean
  disabled?: boolean
  size?: string
  onClick?: (e: MouseEvent) => void
  onMouseDown?: (e: MouseEvent) => void
  onKeyDown?: (e: KeyboardEvent<HTMLElement>) => void
  tabIndex?: number
  identifier?: string
  accessibilityAttr?: { [key: string]: string }
  ariaLabel?: JSX.Element | string
  ariaDescribedby?: string
  focusable?: boolean
}

const AnswerBubble = forwardRef<any, AnswerBubbleProps>((props, bubbleRef) => {
  const {
    symbol,
    correct,
    disabled,
    selected = false,
    size = 'large',
    identifier,
    accessibilityAttr,
    ariaLabel,
    ariaDescribedby,
    tabIndex = 0,
    focusable,
    ...rest
  } = props
  const controls = !disabled ? rest : {}

  return (
    <AnswerBubbleWrapper selected={selected} {...controls}>
      <ReviewBadge correct={correct} overlap='circular' anchorOrigin={REVIEW_BADGE_ORIGIN.bottomRight}>
        <SymbolWrapper
          ref={bubbleRef}
          size={size}
          selected={selected}
          answered={correct !== undefined}
          disabled={disabled}
          {...CHECKBOX_ATTRIBUTES}
          tabIndex={disabled ? undefined : tabIndex}
          role='checkbox'
          aria-checked={selected}
          focusable={focusable}
          {...(identifier && !accessibilityAttr?.['aria-label'] && { 'aria-describedby': identifier })}
          {...(ariaLabel && { 'aria-label': ariaLabel.toString() })}
          {...(ariaDescribedby && { 'aria-describedby': ariaDescribedby })}
          {...accessibilityAttr}
        >
          <Symbol size={size} selected={selected} answered={correct !== undefined} disabled={disabled}>
            {symbol}
          </Symbol>
        </SymbolWrapper>
      </ReviewBadge>
    </AnswerBubbleWrapper>
  )
})

interface AnswerBubbleWrapperProps {
  selected: boolean
}

const AnswerBubbleWrapper = styled(Box)<AnswerBubbleWrapperProps>(({ theme }) => ({
  // ':focus': {
  //   outline: 'none',
  //   '& > span > div': {
  //     backgroundColor: theme.palette.blue[50],
  //     borderColor: theme.palette.blue[100],
  //     '& > p': {
  //       color: theme.palette.blue[600],
  //     },
  //   },
  // },
}))

interface StyledBoxProps {
  size: string
  selected: boolean
  answered: boolean
  disabled: boolean
  focusable?: boolean
}

const SymbolWrapper = styled(Box)<StyledBoxProps>(
  ({ theme, size, selected, answered, disabled, focusable }) => ({
    width: size === 'large' ? '32px' : '27px',
    height: size === 'large' ? '32px' : '27px',
    backgroundColor: selected ? theme.palette.grey[700] : theme.palette.common.white,
    border: `2px solid ${
      selected
        ? theme.palette.grey[700]
        : disabled
        ? theme.palette.blue[50]
        : answered
        ? theme.palette.grey[100]
        : theme.palette.blue[100]
    }`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:focus': {
      ...(focusable ? { ...focusStyle(theme, 2).border } : { outline: 'none' }),
    },

    ...(!disabled
      ? {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: !selected && theme.palette.blue[50],
          },
        }
      : { cursor: 'not-allowed' }),
  }),
)

interface StyledTypographyProps {
  size: string
  selected: boolean
  answered: boolean
  disabled: boolean
}

const Symbol = styled(Typography)<StyledTypographyProps>(
  ({ theme, size, selected, answered, disabled }) => `
  margin: 0 !important;
  font-weight: bold;
  color: ${
    selected
      ? theme.palette.common.white
      : disabled
      ? theme.palette.blue[200]
      : answered
      ? theme.palette.grey[800]
      : theme.palette.blue[600]
  };
  font-size: ${
    size === 'large'
      ? theme.typography.body1.fontSize
      : size === 'medium'
      ? theme.typography.body2.fontSize
      : theme.typography.caption.fontSize
  };
  line-height: 1.313rem;
  text-transform: uppercase;
`,
)

export default AnswerBubble
