import { Box, Checkbox, CheckboxProps, styled } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { FC } from 'react'
import { CHECKBOX_ATTRIBUTES } from '@app/constants'
import { CheckboxAttrProps } from '@app/types'
import { REVIEW_BADGE_ORIGIN, ReviewBadge } from '../../badges'

interface CheckboxComponentProps extends CheckboxProps {
  identifier?: string
  ariaLabel?: string
  accessibilityAttr?: { [key: string]: string }
  correct?: boolean
}

const CheckboxComponent: FC<CheckboxComponentProps> = props => {
  const { inputProps = null, ariaLabel, identifier, accessibilityAttr, correct, ...restProps } = props
  const propsForInput = {
    ...(inputProps ? inputProps : CHECKBOX_ATTRIBUTES),
    ...(ariaLabel && { 'aria-label': ariaLabel }),
    ...(identifier && { 'aria-describedby': identifier }),
    ...accessibilityAttr,
  } as CheckboxAttrProps

  const checkboxElement = (
    <StyledCheckbox
      disableRipple
      checkedIcon={<CheckedIcon />}
      icon={<UnCheckedIcon />}
      inputProps={propsForInput}
      {...restProps}
    />
  )

  if (correct !== undefined) {
    return (
      <ReviewBadge
        correct={correct}
        anchorOrigin={REVIEW_BADGE_ORIGIN.bottomRight}
      >
        {checkboxElement}
      </ReviewBadge>
    )
  }

  return <>{checkboxElement}</>
}

const UnCheckedIcon = styled(Box)(({ theme }) => ({
  width: '30px',
  height: '30px',
  backgroundColor: 'white',
  border: `2px solid ${theme.palette.blue[100]}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  transition: theme.transitions.create(['background-color', 'border-color']),

  'input:disabled ~ &': {
    backgroundColor: 'transparent',
    borderColor: `${theme.palette.grey[100]} !important`,
  },
  'input:disabled:checked ~ &': {
    backgroundColor: theme.palette.grey[400],
    borderColor: theme.palette.grey[100],
  },

  [theme.breakpoints.down('mobile')]: {
    width: '25px',
    height: '25px',
  },
}))

const CheckedIcon = () => {
  return (
    <CheckedIconWrapper>
      <FontAwesomeIcon icon={faCheck} />
    </CheckedIconWrapper>
  )
}

const CheckedIconWrapper = styled(UnCheckedIcon)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[700],
  borderColor: theme.palette.grey[700],
  transition: theme.transitions.create(['background-color', 'border-color', 'fill']),

  '& > svg': {
    fill: theme.palette.background.paper,
    color: theme.palette.background.paper,
  },
}))

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`

export default CheckboxComponent
