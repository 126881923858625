import { FC, KeyboardEvent, useCallback, useContext, useEffect, useRef } from 'react'
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { Box, BoxProps, styled, useMediaQuery, useTheme } from '@mui/material'
import { VictoryChart, VictoryAxis, VictoryLine, VictoryScatter, VictoryContainer } from 'victory'
import { useSVGScale } from '@app/helpers'
import {
  ChartDomain,
  Coordinates,
  DraggableElement,
  GraphingInteractionProps,
  GraphState,
  GraphType,
} from './types'
import {
  SafeCurve,
  RemovablePoint,
  DraggableAsymptote,
  GraphTypeSelect,
  AxisBase,
  AxisLabel,
  Grid,
  Tick,
  TickLabel,
  GraphSelect,
  SelectableArea,
  CoordinatePlane,
  TouchWrapper,
  DndCustomMonitor,
  AxisGrid,
} from './components'
import {
  getTicks,
  getFunction,
  restrictToContainerElement,
  constantFn,
  omitSideTicks,
  snapPointCenterToCursor,
  snapAsymptoteToVerticalAxis,
  getNextCoordinates,
  accessibility,
  announcements,
  FocusKeyboardSensor,
} from './helpers'
import { useChartEvents, useDraggableGraphs } from './hooks'
import { GraphingContext, SelectableAreasContext } from './context'
import {
  POINT_SIZE,
  HEIGHT,
  WIDTH,
  AXIS_STYLE_PLACEHOLDER,
  STATIC_DATA_STYLE,
  POINT_DRAG_DETECT_DISTANCE,
  POINT_DRAG_OUT_THRESHOLD,
} from './constants'
import { ScreenReaderInfo } from '@app/components'

export const GraphingInteraction: FC<Partial<GraphingInteractionProps>> = props => {
  const {
    width = WIDTH,
    height = HEIGHT,
    xAxis: { min: xMin = -10, max: xMax = 10, increment: xIncrement = 1, label: xLabel },
    yAxis: { min: yMin = -10, max: yMax = 10, increment: yIncrement = 1, label: yLabel },
    graphTypeSelect,
    staticPoints,
    graphsData = [],
    solutionSetEnabled,
    disabled,
    // onChange,
  } = props

  const theme = useTheme()
  const isTouchDevice = useMediaQuery(`(max-width: ${theme.breakpoints.values.mobile}px), (hover: none)`)

  const svgChildRef = useRef()
  const svgScale = useSVGScale(svgChildRef)

  const xTicks = getTicks(xMin, xMax, xIncrement)
  const yTicks = getTicks(yMin, yMax, yIncrement)
  const gridX = width / (xTicks.length - 1)
  const gridY = height / (yTicks.length - 1)
  // Grid actual size also depends on svg scale.
  const gridSize: Coordinates = { x: gridX * svgScale[0], y: gridY * svgScale[1] }
  const chartDomain: ChartDomain = { x: [xMin, xMax], y: [yMin, yMax] }
  const increment: Coordinates = { x: xIncrement, y: yIncrement }

  const {
    activeGraph,
    activeGraphType,
    graphs,
    asymptote,
    isSolutionSetMode,
    onSelectGraphType,
    onSelectGraph,
    onRemovePoint,
    onMovePoint,
    onMoveAsymptote,
    onSelectLineStyle,
    onEnableSolutionMode,
    announcement: pointAnnouncement,
  } = useContext(GraphingContext)

  const graphSelectData = graphsData.map((graphData, i) => ({
    ...graphData,
    lineStyle: graphs[i]?.lineStyle,
  }))

  const { activeDraggable, onSelectDraggable, onDragStart, onDragEnd } = useDraggableGraphs(
    activeGraph,
    graphs,
    asymptote,
    gridSize,
    chartDomain,
    increment,
    onMovePoint,
    onMoveAsymptote,
  )

  const { areas, onToggleArea } = useContext(SelectableAreasContext)
  // For each graph there is 1 area that satisfies inequality.
  const maxAreasCount = graphs.length
  const selectedAreasCount = areas.filter(area => area.selected).length
  const maxAreasSelected = selectedAreasCount === maxAreasCount

  const { setRef: chartContainerRef, events: chartEvents } = useChartEvents(
    disabled,
    activeDraggable,
    onSelectDraggable,
    {
      allowAdd: !isSolutionSetMode && !graphTypeSelect,
      allowRemove: !isSolutionSetMode && !graphTypeSelect && !isTouchDevice,
      allowMove: isTouchDevice,
    },
  )

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: POINT_DRAG_DETECT_DISTANCE } }),
    useSensor(FocusKeyboardSensor, { coordinateGetter: getNextCoordinates(gridSize.x, gridSize.y) }),
  )

  const pointKeyDownHandler = (graph: GraphState) => {
    return (e: KeyboardEvent<SVGGElement>, pointId: string) =>
      !graphTypeSelect && e.key === 'Backspace' && onRemovePoint(pointId)
  }

  const onSelectDraggableOfType = (graphIndex: number, type: DraggableElement) => (id: string) => {
    if (graphIndex !== activeGraph) return

    onSelectDraggable(id ? { id, type } : null)
  }

  const scrollHandler = useCallback(
    () => activeDraggable && onSelectDraggable(null),
    [activeDraggable, onSelectDraggable],
  )

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, true)
    return window.removeEventListener('scroll', scrollHandler)
  }, [scrollHandler])

  return (
    <GraphingInteractionWrapper hasSelectedAreas={selectedAreasCount > 0}>
      {graphsData.length > 0 && (
        <StyledGraphSelect
          data={graphSelectData}
          active={activeGraph}
          onSelect={onSelectGraph}
          onSelectLineStyle={onSelectLineStyle}
          onSelectSolution={onEnableSolutionMode}
          solutionSetEnabled={solutionSetEnabled}
          solutionMode={isSolutionSetMode}
          disabled={disabled}
        />
      )}

      {graphTypeSelect && (
        <StyledGraphTypeSelect
          type={activeGraphType}
          options={graphTypeSelect}
          onSelect={onSelectGraphType}
        />
      )}

      <DndContext
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        modifiers={[
          restrictToContainerElement(
            chartContainerRef.current?.containerRef?.firstChild,
            POINT_DRAG_OUT_THRESHOLD,
          ),
          snapAsymptoteToVerticalAxis,
          snapPointCenterToCursor,
        ]}
        accessibility={accessibility}
        sensors={sensors}
      >
        <DndCustomMonitor announcements={announcements} />

        <TouchWrapper width={width} height={height}>
          <VictoryChart
            containerComponent={
              <VictoryContainer className='svg-container' role='group' ref={chartContainerRef} />
            }
            width={width}
            height={height}
            domain={chartDomain}
            padding={0}
            events={chartEvents}
          >
            <CoordinatePlane
              ref={svgChildRef}
              id='coordinate-plane'
              width={width}
              height={height}
              allowAdd={!isSolutionSetMode && !graphTypeSelect}
            />

            <VictoryAxis
              dependentAxis
              crossAxis
              offsetX={width / 2}
              label={yLabel}
              tickCount={yTicks.length}
              axisComponent={
                <AxisBase
                  ticks={yTicks}
                  gridComponent={<AxisGrid width={width} height={height} dependentAxis crossAxis />}
                />
              }
              axisLabelComponent={<AxisLabel dy={-(height + height / 10) / 2} dx={25} angle={0} />}
              gridComponent={<Grid />}
              tickComponent={<Tick />}
              tickLabelComponent={<TickLabel dx={5} />}
              tickFormat={omitSideTicks}
              style={AXIS_STYLE_PLACEHOLDER}
            />
            <VictoryAxis
              crossAxis
              offsetY={height / 2}
              label={xLabel}
              tickCount={xTicks.length}
              axisComponent={
                <AxisBase
                  ticks={xTicks}
                  gridComponent={<AxisGrid width={width} height={height} crossAxis />}
                />
              }
              axisLabelComponent={<AxisLabel dy={-35} dx={(width + width / 10) / 2} angle={0} />}
              gridComponent={<Grid />}
              tickComponent={<Tick />}
              tickLabelComponent={<TickLabel dy={-5} />}
              tickFormat={omitSideTicks}
              style={AXIS_STYLE_PLACEHOLDER}
            />

            {staticPoints.points.length ? (
              <VictoryScatter size={POINT_SIZE} data={staticPoints.points} style={STATIC_DATA_STYLE.points} />
            ) : null}
            {staticPoints.points.length && staticPoints.showLine ? (
              <VictoryLine data={staticPoints.points} style={STATIC_DATA_STYLE.line} />
            ) : null}

            {areas.map((area, i) => (
              <SelectableArea
                key={i}
                d={area.d}
                interactive={!disabled && isSolutionSetMode && (area.selected || !maxAreasSelected)}
                selected={area.selected}
                onSelect={() => onToggleArea(i)}
              />
            ))}

            {graphs.map((graph, i) => {
              if (graph.points.length < 2 || !graph.isValid) return null

              const fn = getFunction(activeGraphType, graph.points, asymptote)
              const [y0, y1] = [fn(graph.points[0]), fn(graph.points[1])]

              if (isNaN(y0) || isNaN(y1)) {
                return null
              }

              // If x is constant, need to use other function.
              const isFiniteY = isFinite(y0) && isFinite(y1)
              const fnX =
                activeGraphType === GraphType.LINEAR
                  ? isFiniteY
                    ? undefined
                    : constantFn(graph.points[0], 'x')
                  : undefined
              const fnY = activeGraphType === GraphType.LINEAR ? (isFiniteY ? fn : undefined) : fn

              return (
                <VictoryLine
                  key={i}
                  domain={{ y: chartDomain.y }}
                  dataComponent={
                    <SafeCurve
                      className='graph'
                      colorIndex={i}
                      lineStyle={graph.lineStyle}
                      domain={chartDomain}
                      graphType={activeGraphType}
                    />
                  }
                  samples={activeGraphType !== GraphType.LINEAR ? 100 : 2}
                  x={fnX}
                  y={fnY}
                />
              )
            })}

            {graphs.map((graph, i) => (
              <VictoryScatter
                key={i}
                size={POINT_SIZE}
                data={graph.points}
                dataComponent={
                  <RemovablePoint
                    colorIndex={i}
                    className='point'
                    disabled={activeGraph !== i}
                    activeDraggable={activeDraggable}
                    keyDownHandler={pointKeyDownHandler(graph)}
                    onSelect={isTouchDevice && onSelectDraggableOfType(i, DraggableElement.POINT)}
                    removable={!graphTypeSelect && isTouchDevice}
                    onRemove={onRemovePoint}
                  />
                }
              />
            ))}

            {activeGraphType === GraphType.EXPONENTIAL && (
              <VictoryLine
                dataComponent={
                  <DraggableAsymptote
                    className='asymptote'
                    onSelect={
                      isTouchDevice && onSelectDraggableOfType(activeGraph, DraggableElement.ASYMPTOTE)
                    }
                  />
                }
                samples={1}
                y={() => asymptote}
              />
            )}
          </VictoryChart>
        </TouchWrapper>
      </DndContext>

      <ScreenReaderInfo ariaAtomic ariaLive='assertive'>
        {pointAnnouncement}
      </ScreenReaderInfo>
    </GraphingInteractionWrapper>
  )
}

const GraphingInteractionWrapper = styled(
  ({ hasSelectedAreas, ...props }: BoxProps & { hasSelectedAreas?: boolean }) => <Box {...props} />,
)(({ theme, hasSelectedAreas }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(4),

  [theme.breakpoints.up('mobile')]: {
    flexDirection: 'row',
  },

  '& .svg-container': {
    display: 'flex',
    padding: theme.spacing(6),

    '& > svg': {
      overflow: 'visible',
      border: '2px solid',
      borderColor: hasSelectedAreas ? theme.palette.blue['A700'] : theme.palette.grey[600],
    },
  },
}))

const StyledGraphTypeSelect = styled(GraphTypeSelect)(({ theme }) => ({
  marginBottom: theme.spacing(3),

  [theme.breakpoints.up('mobile')]: {
    marginBottom: 0,
    marginRight: theme.spacing(3),
  },
}))
const StyledGraphSelect = styled(GraphSelect)(({ theme }) => ({
  marginBottom: theme.spacing(3),

  [theme.breakpoints.up('mobile')]: {
    marginBottom: 0,
    marginRight: theme.spacing(3),
  },
}))
