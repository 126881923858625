import { Box, Typography, styled } from '@mui/material'
import { orange } from '@mui/material/colors'

const OMITTED_PROPS = ['groupMode', 'correct']

interface FeedbackBlockWrapperProps {
  groupMode?: boolean
  correct?: boolean
}

export const FeedbackBlockWrapper = styled(Box, {
  shouldForwardProp: prop => !OMITTED_PROPS.includes(prop as string),
})<FeedbackBlockWrapperProps>(
  ({ theme, groupMode = false, correct }) => ({
  padding: theme.spacing(3, 4),
  fontSize: '0.75rem',
  borderRadius: `${theme.shape.borderRadius * 1.5}px`,

  ...(groupMode ? {
    color: theme.palette.blue[800],
    backgroundColor: theme.palette.blue[100],
  } : {
    color: correct ? theme.palette.green[900] : '#7D4704',
    backgroundColor: correct ? theme.palette.green[100] : orange[100],
  }),
}))

interface FeedbackBlockTitleProps {
  groupMode?: boolean
  correct?: boolean
}

export const FeedbackBlockTitle = styled(Typography, {
  shouldForwardProp: prop => !OMITTED_PROPS.includes(prop as string),
})<FeedbackBlockTitleProps>(
  ({ theme, groupMode = false, correct }) => ({
  fontSize: '0.75rem',
  fontWeight: 600,
  lineHeight: '1rem',
  marginBottom: `${theme.spacing(2)} !important`,

  ...(groupMode ? {
    color: theme.palette.blue[800],
  } : {
    color: correct ? theme.palette.green[900] : '#7D4704',
  }),
}))
