import { useEffect, useState } from 'react'
import axios from 'axios'
import { signInWithCustomToken, signOut } from 'firebase/auth'
import { StorageKeys } from '@app/constants'
import { auth } from '@app/firebase'
import { LocalStorageService } from '@app/services'
import { LoginData } from './LoginForm'

export const useLogin = () => {
  const [user, setUser] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    auth.onAuthStateChanged(async user => {
      if (!user) {
        setUser(null)
        return
      }

      const {
        claims: { sub: id, firstName, lastName, role, school, schoolDistrict, state },
      } = await user.getIdTokenResult()
      setUser({
        id,
        firstName,
        lastName,
        role,
        school,
        schoolDistrict,
        state,
      })
    })
  }, [])

  const onLogout = async () => {
    await signOut(auth)
    setError('')
  }

  const onLogin = async (data: LoginData) => {
    setLoading(true)
    setError('')

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CLOUD_FUNCTION_ROOT}/authGroup-authUser?`,
        data,
      )
      const { customToken, user, error } = response.data

      if (error) {
        setError(error)
        return
      }

      LocalStorageService.setValue(StorageKeys.CustomToken, customToken)

      await signInWithCustomToken(auth, customToken)

      setUser(user)
    } catch (error: any) {
      console.warn(error)
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const onResetError = () => {
    setError('')
  }

  return { user, onLogin, onLogout, loading, error, onResetError }
}
