import React from 'react'
import { PillsContainer, Pill, PillIcon } from './styles'
import { PillSize, PillVariant } from './types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type PillProps = {
  value: string
  label: React.ReactNode
  disabled?: boolean
  startIcon?: IconProp
  endIcon?: IconProp
}

export type PillsProps = {
  size?: PillSize
  items: PillProps[]
  variant?: PillVariant
}

export const Pills: React.FC<PillsProps> = ({ size = 'md', variant = 'text', items = [] }) => {
  return (
    <PillsContainer size={size} variant={variant}>
      {items.map(({ label, value, disabled, startIcon, endIcon }) => (
        <Pill
          size={size}
          variant={variant}
          key={value}
          value={value}
          disabled={disabled}
          startIcon={!!startIcon}
          endIcon={!!endIcon}
        >
          {startIcon && <PillIcon icon={startIcon} />}
          {label}
          {endIcon && <PillIcon icon={endIcon} />}
        </Pill>
      ))}
    </PillsContainer>
  )
}
