import React from 'react'
import {
  CANCEL_BTN_ATTRIBUTES,
  SUBMIT_MODAL_ATTRIBUTES,
  SUBMIT_TEST_BTN_ATTRIBUTES,
} from "@app/constants"
import ItemList from "../../containers/ItemListContatainer"
import { BasicModal } from "../BasicModal"
import { SubmitModalLayout } from "./SubmitModalLayout"

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  hasUnansweredItems?: boolean
  onSelectItem: (id: string) => () => void
  isLinearMode?: boolean
}

export const SubmitTestModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  hasUnansweredItems = false,
  onSelectItem,
  isLinearMode = false,
}) => {
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      size="md"
      title={'Are you ready to submit your test?'}
      submitText={'Submit Answers'}
      modalProps={SUBMIT_MODAL_ATTRIBUTES}
      submitButtonProps={SUBMIT_TEST_BTN_ATTRIBUTES}
      cancelButtonProps={CANCEL_BTN_ATTRIBUTES}
    >
      <SubmitModalLayout>
        {hasUnansweredItems ? (
          <ItemList
            onClick={onSelectItem}
            openSubmitModal={open}
            isLinearMode={isLinearMode}
            navigationBubbleProps={{
              variant: 'outlined',
              dense: true,
            }}
          />
        ) : null}
      </SubmitModalLayout>
    </BasicModal>
  )
}
