import { ScreenReaderInfo } from '@app/components'
import { AlertContainerComponent } from '@app/components/ui'
import { Box, styled, useMediaQuery, useTheme } from '@mui/material'
import {
  FC,
  KeyboardEvent,
  MutableRefObject,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { addStyles, EditableMathField, MathField, MathFieldConfig } from 'react18-mathquill'
import { EquationEditorControls, EquationEditorSection } from './components'
import { useControlsKeyboardNav, useEquationEditorAnnouncement } from './hooks'
import { SectionType } from './types'
import {
  checkInsertionOfSup,
  findNotIncludingSymbols,
  getBackSlashActions,
  getMathFieldControls,
  getOperatorsStructure,
  getUniqOperators,
  scrollIntoView,
} from './utils'
import { lazy } from '@loadable/component'
import { Loader } from '@app/components/ui/common'
import { focusStyle } from '@app/theme'

const LoadableMathView = lazy.lib(() => import('react-math-view'))

export type EquationEditorProps = {
  children?: JSX.Element | JSX.Element[]
  disabled?: boolean
  value: string
  onChange: (value: string) => void
  onUndo: (steps?: number) => void
  onRedo: (steps?: number) => void
}

addStyles()

const mathFieldConfig: MathFieldConfig = {
  maxDepth: 10,
}

const UNIDENTIFIED_KEY = 'Unidentified'

export const EquationEditor: FC<EquationEditorProps> = ({
  children,
  disabled = false,
  value,
  onChange,
  onUndo,
  onRedo,
}) => {
  const [numbersSections, actionsSections, prompt] = useMemo(
    () => getOperatorsStructure(children),
    [children],
  )
  const uniqOperators = useMemo(
    () => getUniqOperators(numbersSections, actionsSections),
    [numbersSections, actionsSections],
  )
  const backSlashActions = useMemo(() => getBackSlashActions(uniqOperators), [uniqOperators])

  //ref for math-quill
  const mathFieldRef = useRef<MathField>()
  //ref for mathlive with method getValue for decoding latex to spoken text
  const controlsRef = useRef<HTMLButtonElement[]>([])
  const eqEditorWrapperRef = useRef<HTMLElement>()

  const [mathViewRef, setMathViewRef] = useState(null)

  const mathViewRefCallback = useCallback((ref: any) => {
    if (!ref) return
    setMathViewRef(ref)
  }, [])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  const {
    currentControl,
    onKeyDown: controlsKeyDownHandler,
    selectControl,
  } = useControlsKeyboardNav(controlsRef as MutableRefObject<HTMLButtonElement[]>)

  const [inputSymbols, setInputSymbols] = useState<string>('')
  const [decodingLatex, setDecodingLatex] = useState('')
  const [noneKeyFlag, setNoneKeyFlag] = useState(false)

  const announcement = useEquationEditorAnnouncement(decodingLatex)

  const insertCommand = useCallback(
    (value: string) => () => {
      try {
        if (checkInsertionOfSup(value, mathFieldRef)) return

        mathFieldRef.current.cmd(value)
        //scrollIntoView(mathFieldRef.current)
      } catch (err) {
        console.log(err)
      }
    },
    [],
  )

  const isErrorInput = useMemo(
    () => noneKeyFlag && findNotIncludingSymbols(value, uniqOperators, backSlashActions),
    [noneKeyFlag, value, uniqOperators, backSlashActions],
  )

  const mathFieldControls = useMemo(
    () => getMathFieldControls(mathFieldRef.current, onUndo, onRedo),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mathFieldRef.current, onUndo, onRedo],
  )

  const mathFieldKeyDownHandler = (e: KeyboardEvent<HTMLSpanElement>) => {
    e.preventDefault()

    const key = e.key

    if (key === UNIDENTIFIED_KEY) {
      setNoneKeyFlag(true)
      return
    }

    if (checkInsertionOfSup(key, mathFieldRef)) return

    if (key === 'Tab') {
      e.shiftKey ? eqEditorWrapperRef.current?.focus() : controlsRef.current[currentControl]?.focus()
      return
    }

    if (key === ' ' || uniqOperators.some(op => op.includes(key))) {
      setInputSymbols(state => `${state}${key}`)
    }
    if (key === ' ' || uniqOperators.includes(key)) {
      mathFieldRef.current?.cmd(key)
    }

    scrollIntoView(mathFieldRef.current)
    noneKeyFlag && setNoneKeyFlag(false)
  }

  useEffect(() => {
    const findCoincidence = backSlashActions.find(action => inputSymbols.includes(action))
    if (findCoincidence) {
      mathFieldRef.current?.cmd(findCoincidence)
      setInputSymbols('')
    }
  }, [inputSymbols, backSlashActions])

  useEffect(() => {
    const textarea = (mathFieldRef as any).current?.__controller.textareaSpan[0].firstChild

    if (!textarea) return

    textarea.ariaLabel = decodingLatex
  }, [decodingLatex])

  useEffect(() => {
    if (!mathViewRef) return

    setDecodingLatex(mathViewRef?.getValue('spoken'))
  }, [mathViewRef, value])

  useEffect(() => {
    const deleteAction = (e: any) => {
      if (e.code !== 'Backspace') return

      mathFieldRef.current?.keystroke('Backspace')
    }

    document.addEventListener('keydown', deleteAction)

    return () => document.removeEventListener('keydown', deleteAction)
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      {prompt}
      <ScreenReaderInfo ariaLive='polite' ariaAtomic ariaBusy={!announcement}>
        {announcement}
      </ScreenReaderInfo>

      <LoadableMathView>
        {({ default: MathView }) => (
          <MathView
            hidden
            ref={ref => mathViewRefCallback(ref)}
            value={value}
            contentEditable={false}
            tabIndex={-1}
          />
        )}
      </LoadableMathView>

      <EquationEditorInteractionWrapper ref={eqEditorWrapperRef} tabIndex={0} aria-label='Equation Editor'>
        {isErrorInput && (
          <AlertContainerComponent
            title='Input Error'
            severity='error'
          >{`Input has incorrect symbols or actions!!!`}</AlertContainerComponent>
        )}
        <StyledEditableMathField
          latex={value}
          onChange={mathField => onChange(mathField?.latex())}
          mathquillDidMount={mathField => (mathFieldRef.current = mathField)}
          config={mathFieldConfig}
          onKeyDown={mathFieldKeyDownHandler}
          disabled={disabled}
        />

        <EquationEditorWrapper>
          <EquationEditorHeader>
            <EquationEditorControls
              ref={controlsRef}
              currentControl={currentControl}
              onKeyDown={controlsKeyDownHandler}
              selectControl={selectControl}
              mathFieldControls={mathFieldControls}
              disabled={disabled}
            />
          </EquationEditorHeader>
          <EquationEditorBody>
            {numbersSections.map((section, i) => (
              <EquationEditorSection
                isMobile={isMobile}
                key={`Numbers${i}`}
                sectionType={SectionType.numbersSection}
                sectionOperators={section}
                insertCommand={insertCommand}
              />
            ))}
            {actionsSections.map((section, i) => (
              <EquationEditorSection
                isMobile={isMobile}
                key={`Actions${i}`}
                sectionType={SectionType.actionsSections}
                sectionOperators={section}
                insertCommand={insertCommand}
              />
            ))}
          </EquationEditorBody>
        </EquationEditorWrapper>
      </EquationEditorInteractionWrapper>
    </Suspense>
  )
}

const EquationEditorInteractionWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('mobile')]: {
    maxWidth: '325px',
  },
}))

interface StyledEditableMathFieldProps {
  disabled: boolean
}

const StyledEditableMathField = styled(EditableMathField)<StyledEditableMathFieldProps>(
  ({ theme, disabled }) => ({
    display: 'flex !important',
    alignItems: 'center',
    width: '100%',
    minHeight: '37px',
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[200]} !important`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey[600],
    boxShadow: 'none !important',

    '& .mq-empty': {
      backgroundColor: 'transparent !important',
    },

    ':focus-within': {
      ...(!disabled
        ? {
            borderColor: `${focusStyle(theme).colors.border} !important`,
            outline: focusStyle(theme).border.outline,
          }
        : {}),
    },
  }),
)

const EquationEditorWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: `${theme.shape.borderRadius * 1.5}px`,
}))

const EquationEditorHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}))

const EquationEditorBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0.5),
  width: '100%',
  overflow: 'auto',

  [theme.breakpoints.down('mobile')]: {
    display: 'block',
  },
}))
