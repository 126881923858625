import { styled } from '@mui/material'
import { FC, HTMLProps } from 'react'
import { PackagedImage } from '@app/components'
import { scrollBarMixinObj } from '@app/constants'

interface HotspotAreaProps {
  image: JSX.Element
  overlay?: JSX.Element
  children?: React.ReactNode
  svgRef?: React.ForwardedRef<any>
  className?: string
  onClick?: React.MouseEventHandler<SVGSVGElement>
  svgProps?: React.SVGProps<SVGSVGElement>
}

export const HotspotArea: FC<HotspotAreaProps> = props => {
  const { image, overlay, children, svgRef, svgProps, onClick, className } = props

  const width = image?.props?.width || 0
  const height = image?.props?.height || 0
  const viewBox = `0 0 ${width} ${height}`

  return (
    <StyledHotspotArea className={className} width={width} height={height}>
      {image}
      {overlay}
      <StyledHotspotChoices
        ref={svgRef}
        onClick={onClick}
        viewBox={viewBox}
        width={width}
        height={height}
        tabIndex={-1}
        role='presentation'
        {...svgProps}
      >
        {children}
      </StyledHotspotChoices>
    </StyledHotspotArea>
  )
}

const StyledHotspotArea = styled(({ width, height, ...props }: HTMLProps<HTMLDivElement>) => (
  <div {...props} />
))(({ theme, width }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.grey[200]}`,
  display: 'flex',

  '& > svg': {
    height: 'auto',
  },

  [theme.breakpoints.down('mobile')]: {
    overflow: 'auto',
    ...scrollBarMixinObj(theme, 'horizontal'),
    [`& > ${PackagedImage}, & > ${StyledHotspotChoices}, & > ${StyledHotspotAreaOverlay}`]: {
      width: `max(100%, ${width}px)`,
      maxWidth: 'none',
    },
  },
}))

export const StyledHotspotAreaOverlay = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
}))

const StyledHotspotChoices = styled('svg')(({ width, height, theme }) => ({
  position: 'absolute',
  width: '100%',
  height: 'auto',
  overflow: 'visible',
  left: 0,
  top: 0,
}))
