import { focusStyle } from '@app/theme'
import { Box, styled } from '@mui/material'

export type BubbleSize = 'large' | 'medium' | 'small'
export type BubbleVariant = 'filled' | 'outlined'

export interface BubbleProps {
  size?: BubbleSize
  selected?: boolean
  answered?: boolean
  viewed?: boolean
  disabled?: boolean
  children?: React.ReactNode
  focused?: boolean
  variant?: BubbleVariant
}

const OMITTED_PROPS = ['size', 'selected', 'answered', 'viewed', 'correct', 'focused', 'variant']

export const Bubble = styled(Box, {
  shouldForwardProp: prop => !OMITTED_PROPS.includes(prop as string),
})<BubbleProps>(({ theme, disabled, answered, selected, variant = 'filled' }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '25px',
  height: '25px',
  fontSize: theme.typography.caption.fontSize,
  fontWeight: 'bold',
  color: theme.palette.grey[800],
  backgroundColor: theme.palette.grey[50],
  border: `2px solid ${theme.palette.grey[50]}`,
  borderRadius: '50%',
  cursor: 'pointer',

  '&:focus-visible': {
    ...focusStyle(theme, 2).border,
  },

  [theme.breakpoints.down('tablet')]: {
    fontSize: theme.typography.body2.fontSize,
  },

  [theme.breakpoints.down('mobile')]: {
    width: '30px',
    height: '30px',
    fontSize: theme.typography.body1.fontSize,
  },

  ...(variant === 'outlined' && {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.grey[200],
  }),

  ...(disabled && {
    pointerEvents: 'none',
    opacity: 0.6,
  }),

  ...(answered && {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.common.white,
    borderColor: theme.palette.grey[700],
  }),

  ...(selected && {
    backgroundColor: theme.palette.blue[500],
    color: theme.palette.common.white,
    borderColor: theme.palette.blue[500],
  }),
}))
