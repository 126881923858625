import React from 'react'
import { Box, Button, Dialog, DialogProps, Stack, styled, Typography } from '@mui/material'
import { CANCEL_BTN_ATTRIBUTES } from '@app/constants'

export type BasicModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

interface BasicModalProps {
  open: boolean
  size?: BasicModalSize
  title?: string
  submitText?: string
  cancelText?: string
  noCancel?: boolean
  backdropDisabled?: boolean
  modalProps?: { [key: string]: string }
  submitButtonProps?: { [key: string]: any }
  cancelButtonProps?: { [key: string]: any }
  onClose?: () => void
  onSubmit?: () => void
  actions?: React.ReactNode
  footer?: React.ReactNode
}

export const BasicModal: React.FC<React.PropsWithChildren<BasicModalProps>> = props => {
  const {
    open,
    onClose,
    onSubmit,
    size = 'md',
    title,
    noCancel,
    backdropDisabled,
    submitText,
    cancelText,
    modalProps,
    submitButtonProps,
    cancelButtonProps = CANCEL_BTN_ATTRIBUTES,
    actions,
    footer,
    children,
  } = props

  const handleClose = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (backdropDisabled && reason) {
      return
    }

    onClose && onClose()
  }

  return (
    <StyledDialog open={open} onClose={handleClose} size={size}>
      <ModalWrapper {...modalProps}>
        {title && <ModalTitle variant='h1'>{title}</ModalTitle>}
        {children}
        {actions ?? (
          <StyledButtonGroup>
            <Button color='secondary' variant='contained' onClick={onSubmit} {...submitButtonProps}>
              {submitText || 'Submit'}
            </Button>
            {!noCancel && (
              <Button color='grey' variant='text' size='small' onClick={onClose} {...cancelButtonProps}>
                {cancelText || 'Cancel'}
              </Button>
            )}
          </StyledButtonGroup>
        )}
      </ModalWrapper>
      {footer}
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog, {
  shouldForwardProp: prop => prop !== 'size',
})<DialogProps & { size?: BasicModalSize }>(({ theme, size = 'md' }) => ({
  zIndex: 2500,
  '.MuiDialog-container': {
    [theme.breakpoints.down('tablet')]: {
      height: 'auto',
    },
  },

  '.MuiPaper-root': {
    borderRadius: theme.shape.borderRadius * 1.5,
    maxWidth: '350px',
    width: '100%',

    ...(size === 'sm' && { maxWidth: '500px' }),
    ...(size === 'md' && { maxWidth: '700px' }),
    ...(size === 'lg' && { maxWidth: '950px' }),
    ...(size === 'xl' && { maxWidth: '1300px' }),

    [theme.breakpoints.down('tablet')]: {
      height: '100vh',
      margin: theme.spacing(0),
    },
  },
}))

const ModalWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(12, 12, 8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}))

const ModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))

const StyledButtonGroup = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(8),
  gap: theme.spacing(4),
  alignItems: 'center',
}))
