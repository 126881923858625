import { StorageKeys } from '@app/constants'

export class LocalStorageService {

  static setValue(key: StorageKeys, value: any) {
    return localStorage.setItem(key, JSON.stringify(value))
  }

  static getValue<TValue>(key: StorageKeys): TValue | null {
    const value = localStorage.getItem(key)
    if (value) return JSON.parse(value)
    return value as null
  }

  static removeValue(key: StorageKeys) {
    return localStorage.removeItem(key)
  }

}
