import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-quill/dist/quill.snow.css'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { RootAriaHiddenController } from './components/common/RootAriaHiddenController'
//import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(container)

root.render(
  <>
    <App />
    {/* TODO: Remove when https://github.com/mui/material-ui/issues/19450 is fixed. */}
    <RootAriaHiddenController rootSelector='#root' />
  </>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()
