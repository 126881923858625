import React from 'react'
import { styled } from '@mui/material'
import { useAssessmentItemScoring } from '@app/helpers'
import { ItemFeedback } from './ItemFeedback'
import VideoFeedback from './VideoFeedback'

const StyledFeedback = styled(ItemFeedback)(({ theme }) => ({
  marginTop: theme.spacing(4),
}))

type Props = {
  itemId: string
}

export const QuestionFeedback: React.FC<Props> = ({ itemId }) => {
  const itemScoring = useAssessmentItemScoring(itemId)

  if (!itemScoring) {
    return null
  }

  return (
    <>
      <StyledFeedback
        correct={itemScoring.possible === itemScoring.raw}
        raw={itemScoring.raw}
        possible={itemScoring.possible}
      />

      <VideoFeedback videoSrc='' />
    </>
  )
}
