import { forwardRef } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { Box, styled } from '@mui/material'
import { PositionObject, PositionObjectProps } from './PositionObject'
import { ARIA_LABELS } from '../constants'
import { focusStyle } from '@app/theme'

export interface DraggableObjectProps extends PositionObjectProps {
  id: string
  disabled?: boolean
}

export const DraggableObject = forwardRef((props: DraggableObjectProps, ref) => {
  const { id, disabled, ...rest } = props

  const { setNodeRef, attributes, listeners } = useDraggable({ id, disabled })

  return (
    <DraggableObjectWrapper
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      aria-label={ARIA_LABELS.POSITION_OBJECT}
    >
      <PositionObject ref={ref} {...rest} disabled={disabled} />
    </DraggableObjectWrapper>
  )
})

const DraggableObjectWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  touchAction: 'none',

  '&:focus': {
    outline: 'none',

    [`& > ${PositionObject}`]: {
      ...focusStyle(theme, 2).border,
    },
  },
}))
