import { FC, forwardRef } from 'react'
import { HotspotChoice, HotspotChoiceProps } from '../HotspotChoice'
import { transformChoicesConstraints, useResponseValidityUpdate } from '@app/helpers'
import { styled } from '@mui/material'
import { useSnapshot } from 'valtio'
import { CurrentTestState } from '@app/storage'

export interface AssociableHotspotProps extends HotspotChoiceProps {
  itemId: string
  matchCount?: number
  active?: boolean
  matchmin?: string
  matchmax?: string
  accessibilityAttr?: { [key: string]: string | boolean }
}

export const HOTSPOT_TYPE_ASSOCIABLE = 'associable-hotspot'

export const AssociableHotspot: FC<AssociableHotspotProps> = forwardRef((props, ref) => {
  const { itemId, matchCount, active, selected, accessibilityAttr, ...hotspotProps } = props

  const { currentItemResponse } = useSnapshot(CurrentTestState)
  const [matchMin, matchMax] = transformChoicesConstraints(props.matchmin, props.matchmax)

  // If hotspot matches count is out of boundaries, mark response as invalid.
  const isValid = useResponseValidityUpdate(currentItemResponse?.id, matchCount, matchMin, matchMax)

  return (
    <StyledAssociableHotspot
      ref={ref}
      hotspotType={HOTSPOT_TYPE_ASSOCIABLE}
      itemId={itemId}
      error={!isValid}
      role='button'
      accessibilityAttr={{
        ...accessibilityAttr,
        'aria-pressed': active,
      }}
      selected={active || selected}
      {...hotspotProps}
    />
  )
})

const StyledAssociableHotspot = styled(HotspotChoice)(({ theme, selected }) => ({
  stroke: selected ? theme.palette.green[600] : theme.palette.grey[400],
  fill: selected ? 'rgba(197,225,165,0.5)' : 'rgba(175,227,241,0.5)',
  strokeDasharray: 0,

  ':hover': {
    stroke: theme.palette.blue[400],
    fill: 'rgba(175,227,241,0.6)',
  },
}))
