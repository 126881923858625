import { db } from "@app/firebase"
import { doc, getDoc, setDoc } from "firebase/firestore"
import { FirestoreAssessment, FirestoreCollections } from "../types"

export const getAssessmentById = async (id: string): Promise<FirestoreAssessment> => {
  try {
    const docRef = doc(db, FirestoreCollections.assessments, id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists) {
      return docSnap.data() as FirestoreAssessment
    }

    return null
  } catch (e) {
    console.error('Cannot get firestore data: ', e)
  }
}

export const createAssessment = async (id: string, payload: FirestoreAssessment) => {
  try {
    const docRef = await setDoc(
      doc(
        db,
        FirestoreCollections.assessments,
        id,
      ),
      {
        ...payload,
      }
    )
    console.log('CREATED DOC', docRef)
  } catch (e) {
    console.error('ERROR ON ASSESSMENT DOC CREATION: ', e)
  }
}