import {
  AssessmentStylesheetRef,
  DataRef,
  Feedback,
  ItemSessionControl,
  NavigationMode,
  SubmissionMode,
  TimeLimits,
} from '@app/types'
import { AssessmentItem } from './assessmentItem'

export type AssessmentTest = {
  id: string
  resourceId: string
  title: string
  testPart: TestPart[]
  stylesheet?: AssessmentStylesheetRef[]
  timeLimits: TimeLimits
  testFeedback?: Feedback[]
  isFinished?: boolean
}

export type TestPart = {
  id: string
  isFinished?: boolean
  navigationMode: NavigationMode
  submissionMode: SubmissionMode
  timeLimits: TimeLimits
  itemSessionControl?: ItemSessionControl
  branchRule?: string[]
  assessmentSections?: AssessmentSection[]
  testFeedback?: Feedback[]
  twoColumnLayout?: boolean
}

export type AssessmentSection = {
  id: string
  required?: boolean
  fixed: boolean
  title: string
  visible: boolean
  keepTogether: boolean
  itemSessionControl: ItemSessionControl
  timeLimits: TimeLimits
  sectionRef?: DataRef[]
  itemRef?: DataRef[]
  items?: AssessmentItem[]
  subsections?: AssessmentSection[]
  selection?: AssesmentSectionSelection
  ordering?: AssessmentSectionOrdering
  rubricBlock?: AssessmentSectionRubricBlock
}

export interface AssesmentSectionSelection {
  select: number
  withReplacement?: boolean
  extension?: string
  extensions?: any[]
}

export interface AssessmentSectionOrdering {
  shuffle: boolean
  extension?: string
  extensions?: any[]
}

export interface AssessmentSectionRubricBlock {
  use?: string
  view?: View
  content?: string
  stylesheet?: any
  apipAccessibility?: any
}

export enum View {
  AUTHOR = 'author',
  CANDIDATE = 'candidate',
  PROCTOR = 'proctor',
  SCORER = 'scorer',
  TEST_CONSTRUCTOR = 'testConstructor',
  TUTOR = 'tutor',
}
