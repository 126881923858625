import { createArray } from '@app/helpers'
import { styled, TableCell, tableCellClasses, TableHead, TableRow } from '@mui/material'
import { forwardRef, memo, MutableRefObject } from 'react'
import { useInputsRef } from '../hooks'
import { ICurrentIndex, ISymbolsWithIndex } from '../types'
import { GridInInputComponent } from './GridInInput'
import { GRID_IN_ATTRIBUTES } from '@app/constants'

interface GridInDesktopHeaderProps {
  columnCount: number
  symbols: string[][]
  inputValues: string[]
  currentIndex: ICurrentIndex
  onBackspace: (index: number) => void
  onInputAction: () => void
  setColumnInputValue: (columnIndex: number, value: string) => void
  chooseCell: (columnIndex: number, rowIndex: number) => void
}

export const GridInDesktopHeader = memo(forwardRef<ISymbolsWithIndex[][], GridInDesktopHeaderProps>((
  props,
  symbolsRef,
) => {
  const {
    columnCount,
    symbols,
    inputValues,
    setColumnInputValue,
    currentIndex,
    chooseCell,
    onBackspace,
    onInputAction
  } = props

  const { inputsRef, onChange, onKeyDown } = useInputsRef(
    columnCount,
    symbols,
    inputValues,
    setColumnInputValue,
    onInputAction,
    onBackspace,
  )

  inputsRef.current?.forEach(
    (inputRef, columnIndex) =>
      ((symbolsRef as MutableRefObject<ISymbolsWithIndex[][]>).current[columnIndex][0].ref = inputRef),
  )

  const setTabIndex = (inputIndex: number) =>
    `${currentIndex.rowIndex}${currentIndex.columnIndex}` === `0${inputIndex}` ? 0 : -1

  return (
    <TableHead>
      <StyledTableRow>
        {createArray(columnCount).map(index => (
          <StyledTableCell
            key={index}
            onClick={() => {
              inputsRef?.current[index].focus()
              chooseCell(index, 0)
            }}
          >
            <GridInInputComponent
              ref={inputsRef}
              index={index}
              inputValue={inputValues[index]}
              tabIndex={setTabIndex(index)}
              changeHandler={onChange}
              keyDownHandler={onKeyDown}
              ariaLabel={`Input of ${index + 1} column`}
              aria-describedby={'gridInNavigationDescription'}
              {...GRID_IN_ATTRIBUTES}
            />
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  )
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    position: 'relative',
    padding: theme.spacing(2, 0),
    cursor: 'pointer',
    border: `1px solid transparent`,
    borderColor: `transparent ${theme.palette.grey[100]} ${theme.palette.grey[100]} ${theme.palette.grey[100]}`,

    ':first-child': {
      borderLeftColor: 'transparent',
    },
    ':last-child': {
      borderRightColor: 'transparent',
    },

    '&:focus-within::before': {
      content: '""',
      position: 'absolute',
      border: `1px solid ${theme.palette.grey[400]}`,
      top: '-1px',
      bottom: '-1px',
      right: '-1px',
      left: '-1px',
    },
  },
}))
