import { FC, SVGProps } from 'react'
import { alpha, styled } from '@mui/material'
import { onKeyboardSubmit } from '@app/helpers'

export interface SelectableAreaProps extends SVGProps<SVGPathElement> {
  selected?: boolean
  interactive?: boolean
  onSelect?: () => void
  theme?: any
}

const SelectableAreaBase: FC<SelectableAreaProps> = ({ d, interactive, onSelect, className }) => {
  const listeners = interactive
    ? {
        onKeyDown: onKeyboardSubmit(onSelect),
        onClick: onSelect,
      }
    : {}
  const attributes = interactive
    ? {
        tabIndex: interactive ? 0 : undefined,
      }
    : {}
  return <path vectorEffect='non-scaling-stroke' d={d} className={className} {...listeners} {...attributes} />
}

const StyledSelectableArea = styled(SelectableAreaBase)(({ theme, selected, interactive = true }) => ({
  cursor: interactive ? 'pointer' : 'default',
  stroke: 'none',
  fill: selected ? alpha(theme.palette.blue[500], 0.4) : 'none',

  ':hover': interactive
    ? {
        fill: alpha(theme.palette.blue[500], selected ? 0.4 : 0.2),
      }
    : undefined,
  ':focus': {
    outline: 'none',
  },
  ':focus-visible': interactive
    ? {
        outline: 'none',
        fill: alpha(theme.palette.blue[500], selected ? 0.4 : 0.2),
        stroke: theme.palette.blue[600],
        strokeWidth: 3,
      }
    : undefined,
}))

export const SelectableArea: FC<SelectableAreaProps> = ({ theme, ...props }) => {
  return <StyledSelectableArea {...props} />
}
