import { Box, styled } from '@mui/material'
import { ChangeEvent, forwardRef, KeyboardEvent, MutableRefObject } from 'react'
import { GRID_IN_ATTRIBUTES } from '@app/constants'
import { ScreenReaderInfo } from '@app/components'
import { useGridInAnnouncement } from '../hooks'
import { useSnapshot } from 'valtio'
import { CurrentTestState } from '@app/storage'

interface GridInInputProps {
  index: number
  inputValue: string
  changeHandler: (inputIndex: number) => (e: ChangeEvent<HTMLInputElement>) => void
  keyDownHandler: (inputIndex: number) => (e: KeyboardEvent<HTMLInputElement>) => void
  onFocus?: () => void
  onBlur?: () => void
  tabIndex?: number
  ariaLabel?: string
}

export const GridInInputComponent = forwardRef<any, GridInInputProps>((props, inputsRef) => {
  const { index, inputValue, changeHandler, keyDownHandler, ariaLabel, ...rest } = props
  const { currentTestPart } = useSnapshot(CurrentTestState)
  const announcement = useGridInAnnouncement(inputValue, index)

  return (
    <GridInInputWrapper>
      <ScreenReaderInfo ariaLive={'polite'} ariaAtomic ariaBusy={!announcement}>
        { announcement }
      </ScreenReaderInfo>
      <GridInInput
        disabled={currentTestPart.isFinished}
        ref={(ref: HTMLInputElement) =>
          ((inputsRef as MutableRefObject<HTMLInputElement[]>).current[index] = ref)
        }
        value={inputValue}
        onChange={changeHandler(index)}
        onKeyDown={keyDownHandler(index)}
        aria-hidden='true'
        {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
        {...GRID_IN_ATTRIBUTES}
        {...rest}
      />
    </GridInInputWrapper>
  )
})

const GridInInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const GridInInput = styled('input')(({ theme }) => ({
  width: '15px',
  padding: '0',
  fontFamily: 'Effra',
  fontSize: theme.typography.body1.fontSize,
  fontWeight: '500',
  color: theme.palette.grey[700],
  border: 'none',
  outline: 'none',

  [theme.breakpoints.down('mobile')]: {
    fontSize: '1.125rem',
  },
}))
