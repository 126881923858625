import { proxy } from 'valtio'
import { DEFAULT_IDLE_TIMEOUT } from '@app/constants'
import idleModalTimerWorkerScript from '@app/helpers/web-workers/idleModalTimerWorker'
import { TimerActions, TimerStatus } from '@app/types'

export type IdleDetectorType = {
  isIdle: boolean,
  countdown: number,
  timerStatus: TimerStatus
  modalOpened: boolean
}

const initialState: IdleDetectorType = {
  isIdle: false,
  countdown: DEFAULT_IDLE_TIMEOUT,
  timerStatus: TimerStatus.notStarted,
  modalOpened: false
}

const idleModalTimerWorker = new Worker(idleModalTimerWorkerScript)

export const IdleDetectorState = proxy<IdleDetectorType>(initialState)

export const switchIdleStatus = (): void => {
  IdleDetectorState.isIdle = !IdleDetectorState.isIdle
}

export const setIdleStatus = (isIdle: boolean): void => {
  IdleDetectorState.isIdle = isIdle
}

export const openIdleModal = () => IdleDetectorState.modalOpened = true
export const closeIdleModal = () => IdleDetectorState.modalOpened = false

export const startIdleModalTimer = () => {
  if (IdleDetectorState.timerStatus === TimerStatus.notStarted) {
    idleModalTimerWorker.postMessage(TimerActions.start)
    idleModalTimerWorker.onmessage = (event) => {
      if (event.data === TimerActions.tick) {
        IdleDetectorState.countdown > 0
          ? IdleDetectorState.countdown--
          : idleModalTimerWorker.postMessage(TimerActions.reset)
      }
    }
    return
  }
}

export const resetIdleDetectorState = (): void => {
  idleModalTimerWorker.postMessage(TimerActions.reset)
  IdleDetectorState.isIdle = initialState.isIdle
  IdleDetectorState.countdown = initialState.countdown
  IdleDetectorState.timerStatus = initialState.timerStatus
}
