import { CurrentTestState } from '@app/storage'
import { FC, useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import {
  checkValidityString,
  getResponseSingleItem,
  useDebouncing,
  useReactiveItemResponse,
  useResponseUpdate,
  useStateReset,
} from '@app/helpers'
import { TextEditorComponent } from '@app/components/ui'
import { InteractionType, TextFormatType } from '@app/types'

interface ExtendedTextInteractionProps {
  itemId: string
  responseidentifier: string
  expectedlength?: number
  base?: number
  stringidentifier?: string
  patternmask?: string
  placeholdertext?: string
  format?: TextFormatType
  maxstrings?: number
  minstrings?: number
  expectedLines: number
  children?: JSX.Element
  accessibilityAttr?: { [key: string]: string }
}

interface ITextEditorValue {
  text: string
  xhtml: string
}

const getLinesCount = (response: string[]) => (response[0] ? response[0].split('\n').length - 1 : 0)

const getFormatText = (value: ITextEditorValue, format: TextFormatType) => {
  switch (format) {
    case TextFormatType.plain:
      return value.text
    case TextFormatType.preformatted:
      return `<pre>${value.text}</pre>`
    case TextFormatType.xhtml:
      return value.xhtml
    default:
      return value.xhtml
  }
}

const xhtmlToText = (xhtml: string) =>
  xhtml
    .replace(/( |<([^>]+)>)/gi, '')
    .replace('\n', ' ')
    .trim()

const transformResponse = (value: string) => [value]

export const ExtendedTextInteraction: FC<ExtendedTextInteractionProps> = props => {
  const {
    responseidentifier: responseIdentifier,
    placeholdertext: placeholder,
    maxstrings: maxStrings = '0',
    minstrings: minStrings = '0',
    patternmask: patternMask,
    expectedlength: expectedLength,
    format,
    children,
    accessibilityAttr,
  } = props
  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)
  const itemResponse = useReactiveItemResponse(responseIdentifier)

  const [text, setText] = useState('')
  const [xhtml, setXhtml] = useState('')
  const [lines, setLines] = useState<number>(null)
  const [isValid, setValid] = useState(true)
  const debouncedValue = useDebouncing(getFormatText({ text, xhtml }, format))

  useResponseUpdate(
    currentItemResponse?.id,
    responseIdentifier,
    debouncedValue?.trim() || null,
    InteractionType.extendedText,
    transformResponse,
    isValid,
  )

  useEffect(() => {
    if (lines >= Number(minStrings) || lines <= Number(maxStrings)) {
      setValid(checkValidityString(patternMask, xhtmlToText(xhtml)))
    }
  }, [lines, xhtml, minStrings, maxStrings, patternMask])

  useEffect(() => {
    if (!itemResponse) return
    setText(xhtmlToText(getResponseSingleItem(itemResponse)))
    setXhtml(getResponseSingleItem(itemResponse))
    setLines(getLinesCount(itemResponse))
  }, [itemResponse])

  useStateReset(currentItemResponse?.id, responseIdentifier, setXhtml, getResponseSingleItem)
  useStateReset(currentItemResponse?.id, responseIdentifier, setLines, getLinesCount)

  const changeHandler = (text: string, xhtml: string, countLines: number) => {
    setText(text)
    setXhtml(xhtml)
    setLines(countLines)
  }

  return (
    <>
      {children}
      {currentItemResponse.id && (
        <TextEditorComponent
          value={xhtml}
          {...accessibilityAttr}
          changeHandler={changeHandler}
          placeholder={placeholder}
          isDisabled={currentTestPart.isFinished}
          expectedLength={expectedLength}
        />
      )}
    </>
  )
}
