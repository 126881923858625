import { useState } from 'react'
import { MAX_POINTS, MIN_POINTS } from '../constants'
import { getInitialGraphs, getPointLabel, validateGraphs } from '../helpers'
import { GraphState, GraphType, LineStyle, GraphProps, UniqueDOMPoint } from '../types'

export type UseGraphs = {
  graphs: GraphState[]
  asymptote: number
  activeGraph: number
  activeGraphType: GraphType
  isSolutionSetMode: boolean
  onSelectGraphType: (graphType: GraphType) => void
  onSelectGraph: (index: number) => void
  onAddPoint: (point: DOMPoint) => void
  onRemovePoint: (id: string) => void
  onMovePoint: (id: string, position: DOMPoint) => void
  onMoveAsymptote: (asymptote: number) => void
  onSelectLineStyle: (index: number, lineStyle: LineStyle) => void
  onEnableSolutionMode: () => void
  setActiveGraph: React.Dispatch<React.SetStateAction<number>>
  setActiveGraphType: React.Dispatch<React.SetStateAction<GraphType>>
  setGraphs: React.Dispatch<React.SetStateAction<GraphState[]>>
  setAsymptote: React.Dispatch<React.SetStateAction<number>>
  setSolutionSetMode: React.Dispatch<React.SetStateAction<boolean>>
  announcement: string
}

export const useGraphs = (
  graphsData: Partial<GraphProps>[],
  solutionSetEnabled: boolean,
  disabled?: boolean,
): UseGraphs => {
  // If graphs count > 1 or solution set mode is present, none should be selected by default.
  const [activeGraph, setActiveGraph] = useState<number>(
    graphsData.length > 1 || solutionSetEnabled ? null : 0,
  )
  const [activeGraphType, setActiveGraphType] = useState<GraphType>(null)
  const [graphs, setGraphs] = useState<GraphState[]>(getInitialGraphs(graphsData, activeGraphType))
  const [asymptote, setAsymptote] = useState<number>(0)
  const [isSolutionSetMode, setSolutionSetMode] = useState<boolean>(false)
  const [announcement, setAnnouncement] = useState<string>('')

  const onSelectGraphType = (graphType: GraphType) => {
    if (disabled) return

    setActiveGraphType(graphType)
    setGraphs(getInitialGraphs(graphsData, graphType))
    setAsymptote(0)
  }

  const onSelectGraph = (graphIndex: number) => {
    if (disabled) return

    setActiveGraph(graphIndex)
    setSolutionSetMode(false)
  }

  const onAddPoint = (point: DOMPoint) => {
    if (disabled) return

    setGraphs(current =>
      current.map((graph, i) => {
        if (i === activeGraph) {
          const newPoint = new UniqueDOMPoint(point.x, point.y)
          const newPoints = [...graph.points, newPoint]
          const maxPoints = graphsData[activeGraph]?.maxPoints || MAX_POINTS

          if (newPoints.length > maxPoints) {
            return graph
          }

          setAnnouncement(`Added point at ${getPointLabel(newPoint)}`)
          return { ...graph, points: newPoints }
        }

        return graph
      }),
    )
  }

  const onRemovePoint = (id: string) => {
    if (disabled) return

    setGraphs(current =>
      current.map((graph, i) => {
        if (i === activeGraph) {
          const pointToRemove = graph.points.find(item => item.id === id)
          const newPoints = graph.points.filter(item => item.id !== id)
          const minPoints = graphsData[activeGraph]?.minPoints || MIN_POINTS

          if (newPoints.length < minPoints) {
            return graph
          }

          setAnnouncement(`Removed point at ${getPointLabel(pointToRemove)}`)
          return {
            ...graph,
            points: newPoints,
          }
        }
        return graph
      }),
    )
  }

  const onMoveAsymptote = (newAsymptote: number) => {
    if (disabled) return

    setGraphs(current => validateGraphs(activeGraph, activeGraphType, current, newAsymptote))
    setAsymptote(newAsymptote)
  }

  const onMovePoint = (pointId: string, newPosition: DOMPoint) => {
    if (disabled) return

    setGraphs(current => {
      if (!current[activeGraph]) return current

      const pointIndex = current[activeGraph].points.findIndex(point => point.id === pointId)

      if (pointIndex < 0) return current

      current[activeGraph].points[pointIndex].x = newPosition.x
      current[activeGraph].points[pointIndex].y = newPosition.y
      return validateGraphs(activeGraph, activeGraphType, current, asymptote)
    })
  }

  const onSelectLineStyle = (graphIndex: number, lineStyle: LineStyle) => {
    if (disabled) return

    setGraphs(current =>
      current.map((graph, i) => {
        if (i === graphIndex) {
          return { ...graph, lineStyle }
        }

        return graph
      }),
    )
  }

  const onEnableSolutionMode = () => {
    if (disabled) return

    setSolutionSetMode(true)
    setActiveGraph(null)
  }

  return {
    graphs,
    asymptote,
    activeGraph,
    activeGraphType,
    isSolutionSetMode,
    onSelectGraphType,
    onSelectGraph,
    onAddPoint,
    onRemovePoint,
    onMovePoint,
    onMoveAsymptote,
    onSelectLineStyle,
    onEnableSolutionMode,
    setActiveGraph,
    setActiveGraphType,
    setGraphs,
    setAsymptote,
    setSolutionSetMode,
    announcement,
  }
}
