import { Children, FC } from 'react'
import { Marker, MarkerProps } from '../../GraphingInteraction/components'
import { DEFAULT_MARKER_WIDTH, DEFAULT_MARKER_HEIGHT } from '../constants'

export interface LineWithMarkersProps extends MarkerProps {
  useStartMarker?: boolean
  useEndMarker?: boolean
  Pointer: React.ComponentType<any>
}

export const LineWithMarkers: FC<LineWithMarkersProps> = props => {
  const {
    id,
    width = DEFAULT_MARKER_WIDTH,
    height = DEFAULT_MARKER_HEIGHT,
    useStartMarker,
    useEndMarker,
    Pointer,
    children,
    ...rest
  } = props

  const hMiddle = height / 2
  const startArrowPoints = `${width} 0, ${width} ${height}, 0 ${hMiddle}`
  const endArrowPoints = `0 0, ${width} ${hMiddle}, 0 ${height}`

  const startMarkerId = `${id}-startMarker`
  const endMarkerId = `${id}-endMarker`
  const line = Children.only(children)

  return (
    <>
      <defs>
        {useStartMarker && (
          <Marker id={startMarkerId} width={width} height={height} markerUnits='userSpaceOnUse' {...rest}>
            <Pointer points={startArrowPoints} />
          </Marker>
        )}
        {useEndMarker && (
          <Marker id={endMarkerId} width={width} height={height} markerUnits='userSpaceOnUse' {...rest}>
            <Pointer points={endArrowPoints} />
          </Marker>
        )}
      </defs>
      <line.type
        {...line.props}
        markerStart={useStartMarker ? `url(#${startMarkerId})` : undefined}
        markerEnd={useEndMarker ? `url(#${endMarkerId})` : undefined}
      />
    </>
  )
}
