import { Alert, AlertProps, alertClasses, alertTitleClasses, styled } from '@mui/material'
import { colorPalette, getBorderColor, getIconColor } from '@app/components/ui/containers/AlertContainer/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type StyledAlertProps = AlertProps & {
  size?: 'md'|'sm'
}

export const StyledAlert = styled(Alert)<StyledAlertProps>(({ theme, severity, variant, size = 'md' }) => ({
  display: 'flex',
  alignItems: 'start',
  fontSize: theme.typography.body2.fontSize,
  borderRadius: theme.shape.borderRadius * 1.5,
  padding: theme.spacing(3, 4),
  margin: theme.spacing(0, 0, 3),
  ...colorPalette(theme, severity),

  ...(size === 'sm' && {
    padding: theme.spacing(2, 3),
    fontSize: '0.75rem',
    lineHeight: '1rem',
  }),

  ...(variant === 'outlined' && {
    backgroundColor: theme.palette.common.white,
    borderColor: getBorderColor(theme, severity),
  }),

  [theme.breakpoints.down('mobile')]: {
    padding: theme.spacing(2.25, 2.125),
  },

  [`& .${alertTitleClasses.root}`]: {
    color: colorPalette(theme, severity).color,
  },

  [`& .${alertClasses.message}`]: {
    lineHeight: theme.typography.h4.fontSize,
    letterSpacing: '0',

    [theme.breakpoints.down('mobile')]: {
      lineHeight: theme.typography.h5.fontSize,
    },
  },

  [`& .${alertClasses.icon}`]: {
    marginRight: theme.spacing(3.375),
    opacity: 1,
    color: getIconColor(theme, severity),

    [theme.breakpoints.down('mobile')]: {
      marginRight: theme.spacing(2.125),
    },
  },

  [`& .${alertClasses.icon}, & .${alertClasses.message}`]: {
    padding: theme.spacing(0.25, 0),
  },
}))

export const StyledFontAwesome = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}))
