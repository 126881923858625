import { FC } from 'react'
import { styled, Typography, TypographyTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { StyledInput } from '../inputs/InputComponent'
import { SelectComponentBase } from '../inputs/SelectComponent'

export type ParagraphProps = {
  children?: string | JSX.Element | JSX.Element[]
  className?: string
  style?: React.CSSProperties
}

export const Paragraph: FC<ParagraphProps> = ({ children, className, style }) => {
  return (
    <StyledTypography className={className} style={style}>
      {children}
    </StyledTypography>
  )
}

const StyledTypography = styled<OverridableComponent<TypographyTypeMap<{}, 'p'>>>(Typography)(
  ({ theme }) => ({
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('mobile')]: {
      marginBottom: theme.spacing(3),
    },

    [`:has(${StyledInput}), :has(${SelectComponentBase})`]: {
      lineHeight: '2.5rem',

      [theme.breakpoints.down('mobile')]: {
        lineHeight: '2.125rem',
      },
    },
  }),
)
