import { Modifier } from '@dnd-kit/core'
import { getEventCoordinates } from '@dnd-kit/utilities'

export const snapHorizontalCenterToCursor: Modifier = args => {
  let { activatorEvent, draggingNodeRect, transform } = args

  if (draggingNodeRect && activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent)

    if (!activatorCoordinates) {
      return transform
    }

    const offsetX = activatorCoordinates.x - draggingNodeRect.left

    return {
      ...transform,
      x: transform.x + offsetX - draggingNodeRect.width / 2,
    }
  }

  return transform
}
