import {
  Active,
  CollisionDetection,
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
  DroppableContainer,
  SensorDescriptor,
  SensorOptions,
} from '@dnd-kit/core'
import { RectMap } from '@dnd-kit/core/dist/store'
import { Coordinates } from '@dnd-kit/utilities'
import { Dispatch } from 'react'

export enum OrientationEnum {
  horizontal = 'horizontal',
  vertical = 'vertical',
}
export type OrientationType = OrientationEnum.horizontal | OrientationEnum.vertical

export interface DraggableListChoices {
  id: string
  element: JSX.Element
}

export enum MoveChoiceEnum {
  toInit = 'toInit',
  fromInit = 'fromInit',
}
export type MoveChoiceType = 'toInit' | 'fromInit'

export enum ManualSortEnum {
  up = 1,
  down = -1,
}
export type ManualSortType = ManualSortEnum.up | ManualSortEnum.down

export type UseContainersStateControlType = [
  DraggableListChoices[],
  DraggableListChoices[],
  Dispatch<React.SetStateAction<DraggableListChoices[]>>,
  (type: MoveChoiceType) => (choiceId: string) => void,
]

export type CollisionDetectionAlgorithmArgs = {
  active: Active
  collisionRect: ClientRect
  droppableRects: RectMap
  droppableContainers: DroppableContainer[]
  pointerCoordinates: Coordinates | null
}

export type UseDragDropChoiceControlType = [
  string,
  (e: DragStartEvent) => void,
  () => void,
  (e: DragOverEvent) => void,
  (dragChoiceId: string, dropId: string) => void,
  SensorDescriptor<SensorOptions>[],
  CollisionDetection,
  (e: DragMoveEvent) => void,
  () => void,
]

export enum ContainersId {
  INITIAL_DROP = 'INITIAL_DROP',
  SORTABLE_DROP = 'SORTABLE_DROP',
}

export enum DragEvents {
  MOUSE_DOWN = 'mousedown',
  KEY_DOWN = 'keydown',
}
