import { AppUpdateState, resetAppUpdateState } from '@app/storage'
import { FC, useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import AppUpdateAlert from './AppUpdateAlert'

interface AppUpdaterProps {}

const AppUpdater: FC<AppUpdaterProps> = () => {
  const { updateFound } = useSnapshot(AppUpdateState)
  const [registration, setRegistration] = useState<ServiceWorkerRegistration>(null)

  useEffect(() => {
    if (registration) {
      if (registration.waiting) {
        console.log('NEW VERSION DETECTED')
        AppUpdateState.updateFound = true
      }
      return
    }

    setSWRegistration()
  }, [registration])

  const updateServiceWorker = () => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      resetAppUpdateState()
    }
  }

  const setSWRegistration = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then(registration => {
        setRegistration(registration)
      })
    }
  }

  return <>{updateFound && <AppUpdateAlert onUpdate={updateServiceWorker} />}</>
}

export default AppUpdater
