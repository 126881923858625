import { Modifier, ClientRect } from '@dnd-kit/core'
import { createSnapModifier, restrictToVerticalAxis, snapCenterToCursor } from '@dnd-kit/modifiers'
import { Transform } from '@dnd-kit/utilities'
import { DraggableElement } from '../types'

export function restrictToBoundingRect(
  transform: Transform,
  rect: ClientRect,
  boundingRect: ClientRect,
  threshold: number,
): Transform {
  const value = {
    ...transform,
  }
  const tX = rect.width * threshold
  const tY = rect.height * threshold

  if (rect.top + transform.y <= boundingRect.top - tY) {
    value.y = boundingRect.top - rect.top
  } else if (rect.bottom + transform.y >= boundingRect.top + boundingRect.height + tY) {
    value.y = boundingRect.top + boundingRect.height - rect.bottom
  }

  if (rect.left + transform.x <= boundingRect.left - tX) {
    value.x = boundingRect.left - rect.left
  } else if (rect.right + transform.x >= boundingRect.left + boundingRect.width + tX) {
    value.x = boundingRect.left + boundingRect.width - rect.right
  }

  return value
}

export const restrictToContainerElement =
  (containerNode: Element, threshold: number): Modifier =>
  args => {
    const { draggingNodeRect, transform } = args

    if (!containerNode) return transform

    const containerNodeRect = containerNode.getBoundingClientRect()

    if (!draggingNodeRect || !containerNodeRect) {
      return transform
    }

    return restrictToBoundingRect(transform, draggingNodeRect, containerNodeRect, threshold)
  }

export const graphingModifier = (gridSize: number): Modifier => {
  const gridSnapModifier = createSnapModifier(gridSize)

  return args => {
    const { transform, active } = args

    if (active?.data?.current?.type === 'asymptote') {
      return restrictToVerticalAxis({
        ...args,
        transform: gridSnapModifier(args),
      })
    }

    if (active?.data?.current?.type === 'point') {
      return gridSnapModifier(args)
    }

    return transform
  }
}

export const snapAsymptoteToVerticalAxis: Modifier = args => {
  const { transform, active } = args

  if (active?.data?.current?.type === DraggableElement.ASYMPTOTE) {
    return restrictToVerticalAxis(args)
  }

  return transform
}

export const snapPointCenterToCursor: Modifier = args => {
  const { transform, active } = args

  if (active?.data?.current?.type === DraggableElement.POINT) {
    return snapCenterToCursor(args)
  }

  return transform
}
