import { Box, BoxProps, Typography, TypographyProps, alertClasses, styled } from '@mui/material'
import { AlertGroup } from '@app/components/ui/containers/AlertGroup'

export const StyledAlertGroup = styled(AlertGroup)(({ theme }) => ({
  [`& .${alertClasses.message}`]: {
    display: 'grid',
    gridTemplateColumns: '16px auto 1fr',
    gridColumnGap: theme.spacing(2),
  },
}))

export const StyledAlertIcon = styled(Box, {
  shouldForwardProp: prop => prop !== 'messagesCount',
})<BoxProps & { messagesCount?: number }>(({ messagesCount = 1 }) => ({
  [`&.${alertClasses.icon}`]: {
    marginRight: 0,
    gridRow: `1 / ${messagesCount + 1}`,
  },
}))

// "component" prop comes from MUI common interface, but gets lost after wrapping with "styled".
export const MessageLabel = styled(Typography)<TypographyProps & { component?: React.ElementType }>(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: 'inherit',
  }),
)

export const ContentLabel = styled(MessageLabel)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}))

export const PercentageLabel = styled(MessageLabel)(() => ({
  fontWeight: 500,
  textAlign: 'right',
}))
