import { CurrentTestState, CurrentUserAssessmentState } from '@app/storage'
import { AssessmentEvent, AssessmentStatus, FirestoreAssessment, UserAssessment } from '../api/types'
import { v4 as uuidv4 } from 'uuid'
import { InitialUserAssessmentPayload } from '@app/types'
import { Timestamp } from 'firebase/firestore'
import { AssessmentItemResponse } from '@app/models'
import { GROUP_REVIEW_USER } from '@app/constants'

export const generateInitialUserAssessmentPayload = (
  initialData: InitialUserAssessmentPayload,
): UserAssessment => ({
  userId: initialData.userId,
  assessmentId: initialData.assessmentId,
  version: 1,
  itemResponses: [],
  lastViewedItemId: '',
  createdTimestamp: Timestamp.fromDate(new Date()),
  updatedTimestamp: Timestamp.fromDate(new Date()),
  startedTimestamp: null,
  attempt: initialData.attempt,
  status: AssessmentStatus.new,
  deleted: false,
  hideInfoBoxes: initialData.hideInfoBoxes,
  schoolYear: initialData.schoolYear,
  assessmentSettings: initialData.assessmentSettings,
  passedTime: 0,
  addedTime: 0,
  responseXml: '',
})

export const generateGroupUserAssessmentPayload = (
  assessmentId: string,
  correctResponses: AssessmentItemResponse[] = [],
): UserAssessment => ({
  userId: GROUP_REVIEW_USER,
  assessmentId,
  version: 0,
  itemResponses: correctResponses,
  lastViewedItemId: '',
  createdTimestamp: Timestamp.fromDate(new Date()),
  updatedTimestamp: Timestamp.fromDate(new Date()),
  startedTimestamp: Timestamp.fromDate(new Date()),
  attempt: 1,
  status: AssessmentStatus.completed,
  hideInfoBoxes: true,
  deleted: false,
  schoolYear: new Date().getFullYear().toString(),
  assessmentSettings: {
    timeLimits: 0,
    isResumable: true,
  },
  passedTime: 0,
  addedTime: 0,
  responseXml: '',
})

export const generateUserAssessmentPayload = (
  initialData: Partial<UserAssessment>,
): Partial<UserAssessment> => {
  const { itemsResponses, currentTest, currentTestPartResponse, currentItemResponse } = CurrentTestState

  return {
    ...initialData,
    itemResponses: itemsResponses,
    lastViewedItemId: currentItemResponse.itemId,
    updatedTimestamp: Timestamp.fromDate(new Date()),
    status: AssessmentStatus.completed,
    passedTime: currentTest.timeLimits.maxTime, //TODO: update on rehydration stage.
    responseXml: currentTestPartResponse[0],
  }
}

export const generateAssessmentEventPayload = (
  initialData: Pick<AssessmentEvent, 'action' | 'type' | 'data' | 'passedTime'>,
): AssessmentEvent => {
  const { currentTest, currentItemResponse } = CurrentTestState
  const { userId } = CurrentUserAssessmentState

  return {
    id: uuidv4(),
    deleted: false,
    userId,
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
    assessmentId: currentTest.id,
    assessmentItemId: currentItemResponse.itemId,
    type: initialData.type,
    eventTimestamp: new Date(),
    action: initialData.action,
    attempt: 1,
    passedTime: initialData.passedTime,
    data: initialData.data,
  }
}

export const generateFirestoreAssessmentPayload = (
  initialData: Pick<FirestoreAssessment, 'id' | 'path' | 'settings'>,
): FirestoreAssessment => ({
  id: initialData.id,
  path: initialData.path,
  deleted: false,
  createdTimestamp: new Date(),
  updatedTimestamp: new Date(),
  settings: initialData.settings,
})
