import { DragContainerComponent, DropContainerComponent } from '@app/components/ui/containers'
import { addUniqPartId } from '@app/helpers'
import { DropContainerState, IClickMovingState } from '@app/types'
import { Box, styled } from '@mui/material'
import { FC, useCallback, useMemo } from 'react'

interface AssociatePairDropBoxProps {
  pairId: string[]
  containers: DropContainerState
  variants: JSX.Element[]
  isFinished: boolean
  isMaxMatches: boolean
  handleRemove: (itemId: string, containerId: string) => () => void
  clickMovingState: IClickMovingState
  dropContainers?: string[]
  variantContainers?: string[]
}

export const AssociatePairDropBox: FC<AssociatePairDropBoxProps> = props => {
  const {
    pairId,
    containers,
    variants,
    isFinished,
    isMaxMatches,
    handleRemove,
    clickMovingState: { activeGapBlockId, activeGapId },
    dropContainers,
    variantContainers,
  } = props

  const [firstDrop, secondDrop] = useMemo(
    () => [
      variants.find(variant => variant.props.identifier === containers[pairId[0]]),
      variants.find(variant => variant.props.identifier === containers[pairId[1]]),
    ],
    [containers, variants, pairId],
  )

  const getContainerIndex = useCallback(
    (identifier: string) => Boolean(dropContainers.length) && dropContainers.indexOf(identifier),
    [dropContainers],
  )

  const getVariantIndex = useCallback(
    (identifier: string) => Boolean(variantContainers.length) && variantContainers.indexOf(identifier),
    [variantContainers],
  )

  return (
    <AssociatePairDropBoxWrapper>
      <AssociatePairDropBoxItem data-gap={pairId[0]}>
        <StyledDropContainer
          id={pairId[0]}
          assigned={!!firstDrop}
          disabled={isFinished || (isMaxMatches && !!!firstDrop)}
          containerIndex={getContainerIndex(pairId[0])}
        />
        {firstDrop && (
          <DragContainerComponent
            id={addUniqPartId(firstDrop.props.identifier)}
            containerId={pairId[0]}
            assigned={!!firstDrop}
            disabled={isFinished}
            handleRemove={handleRemove(firstDrop.props.identifier, pairId[0])}
            selected={activeGapBlockId === firstDrop.props.identifier && activeGapId === pairId[0]}
            variantIndex={getVariantIndex(firstDrop.props.identifier)}
          >
            {firstDrop}
          </DragContainerComponent>
        )}
      </AssociatePairDropBoxItem>

      <AssociatePairDropBoxConnector />

      <AssociatePairDropBoxItem data-gap={pairId[1]}>
        <StyledDropContainer
          id={pairId[1]}
          assigned={!!secondDrop}
          disabled={isFinished || (isMaxMatches && !!!secondDrop)}
          containerIndex={getContainerIndex(pairId[1])}
        />
        {secondDrop && (
          <DragContainerComponent
            id={addUniqPartId(secondDrop.props.identifier)}
            containerId={pairId[1]}
            assigned={!!secondDrop}
            disabled={isFinished}
            handleRemove={handleRemove(secondDrop.props.identifier, pairId[1])}
            selected={activeGapBlockId === secondDrop.props.identifier && activeGapId === pairId[1]}
            variantIndex={getVariantIndex(secondDrop.props.identifier)}
          >
            {secondDrop}
          </DragContainerComponent>
        )}
      </AssociatePairDropBoxItem>
    </AssociatePairDropBoxWrapper>
  )
}

const AssociatePairDropBoxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),

  [theme.breakpoints.down('mobile')]: {
    padding: 0,
  },
}))

const AssociatePairDropBoxItem = styled(Box)(() => ({
  position: 'relative',
}))

const AssociatePairDropBoxConnector = styled(Box)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.grey[200]}`,
  maxWidth: '50px',
  width: '100%',
  height: 0,

  [theme.breakpoints.down('mobile')]: {
    maxWidth: 'unset',
  },
}))

const StyledDropContainer = styled(DropContainerComponent)(({ assigned }) => ({
  ...(assigned && {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
  }),
}))
