import { NEXT_BTN_ATTRIBUTES, PREVIOUS_BTN_ATTRIBUTES } from '@app/constants'
import { CurrentTestState, moveToNextItem, moveToPreviousItem } from '@app/storage'
import { NavigationMode } from '@app/types'
import { Box, Button, styled } from '@mui/material'
import { useMemo, useRef } from 'react'
import { useSnapshot } from 'valtio'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
import { ScreenReaderInfo } from '@app/components'

const InteractionLayoutFooter = () => {
  const { currentItemResponse, currentTestPart, itemsResponses } = useSnapshot(CurrentTestState)
  const currentItemIndex = itemsResponses.findIndex(item => item.id === currentItemResponse?.id)
  const itemChangedRef = useRef<boolean>(false)
  const navigationMode = currentTestPart?.navigationMode
  const isTestFinished = currentTestPart?.isFinished

  const disableControls = useMemo(() => {
    return (
      !currentItemResponse?.isResponseValid && navigationMode === NavigationMode.linear && !isTestFinished
    )
  }, [currentItemResponse, isTestFinished, navigationMode])

  const disablePrevious = useMemo(
    () => navigationMode !== NavigationMode.linear || isTestFinished,
    [isTestFinished, navigationMode],
  )

  const handlePreviousButtonClick = () => {
    if (!itemChangedRef.current) itemChangedRef.current = true
    moveToPreviousItem()
  }

  const handleNextButtonClick = () => {
    if (!itemChangedRef.current) itemChangedRef.current = true
    moveToNextItem()
  }

  return (
    <FooterWrapper>
      <ScreenReaderInfo ariaLive={'assertive'} ariaAtomic ariaBusy={!itemChangedRef.current}>
        {`You moved to question ${currentItemIndex + 1}.`}
      </ScreenReaderInfo>
      <ButtonWrapper disablePrevious={disablePrevious} aria-label={'Questions navigation controls'}>
        {disablePrevious && (
          <Button
            disabled={disableControls || currentItemIndex === 0}
            aria-label='Previous question'
            variant='outlined'
            color='secondary'
            size="small"
            startIcon={<NavigationArrow icon={faArrowLeft} />}
            onClick={handlePreviousButtonClick}
            {...PREVIOUS_BTN_ATTRIBUTES}
          >
            {'Previous'}
          </Button>
        )}

        <Button
          disabled={disableControls || currentItemIndex === itemsResponses.length - 1}
          aria-label='Next question'
          variant='outlined'
          color='secondary'
          size="small"
          endIcon={<NavigationArrow icon={faArrowRight} />}
          onClick={handleNextButtonClick}
          {...NEXT_BTN_ATTRIBUTES}
        >
          {'Next'}
        </Button>
      </ButtonWrapper>
    </FooterWrapper>
  )
}

const FooterWrapper = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(2, 4)};
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.grey[100]};

  ${[theme.breakpoints.down('mobile')]} {
    justify-content: unset;
    padding: ${theme.spacing(2)};
  }
`,
)

const ButtonWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'disablePrevious',
})<{ disablePrevious: boolean }>(
  ({ theme, disablePrevious }) => `
    display: flex;
    gap: ${theme.spacing(4)};

    ${[theme.breakpoints.down('mobile')]} {
      width: 100%;
      justify-content: ${disablePrevious ? 'space-between' : 'flex-end'}
    }
  `,
)

const NavigationArrow = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize + ' !important',
}))

export default InteractionLayoutFooter
