import { FC, useMemo, KeyboardEvent } from 'react'
import { DndContext, DragOverlay, KeyboardSensor, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { AssociatePairDropBox, AssociatePairsWrapper } from './components'
import {
  generateAnnouncement,
  generateHumanReadableIds,
  useDragDropMatch,
  useInteractionDivide,
} from '@app/helpers'
import { DomNodes, FREE_VARIANTS_CONTAINER_ID } from '@app/constants'
import {
  BasicDragContainerComponent,
  ConstraintsAlertContainer,
  DragContainerComponent,
  VariantsContainerComponent,
} from '@app/components/ui/containers'
import { addUniqPartId, cutUniqPartId } from '@app/helpers/utils'
import { containersToResponse, responseToContainers } from './utils'
import { InteractionType } from '@app/types'

interface AssociateInteractionComponentProps {
  itemId: string
  shuffle: string
  maxassociations: string
  minassociations: string
  responseidentifier: string
  children: JSX.Element | JSX.Element[]
  accessibilityAttr?: { [key: string]: string }
}

export const AssociateInteractionComponent: FC<AssociateInteractionComponentProps> = props => {
  const {
    minassociations: minAssociations = '0',
    maxassociations: maxAssociations = '0',
    responseidentifier: responseIdentifier,
    children,
    accessibilityAttr,
  } = props

  const [variants, prompt] = useInteractionDivide(DomNodes.simpleAssociableChoice, DomNodes.prompt, children)

  const activationConstraint = {
    distance: 15,
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor),
  )

  const pairs = useMemo(
    () =>
      Array.from(
        Array(
          Number(maxAssociations) || (variants.length % 2 === 0 ? variants.length : variants.length - 1) / 2,
        ).keys(),
      ).map(i => [`0${i}`, `1${i}`]),
    [maxAssociations, variants],
  )

  const dropContainers = useMemo(() => pairs.reduce((arr, i) => [...arr, ...i], []), [pairs])
  const variantContainers = useMemo(
    () => Object.values(variants).map(item => item.props.identifier),
    [variants],
  )

  const {
    activeId,
    containers,
    isFinished,
    isMaxMatches,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleRemove,
    isDisabledVariant,
    clickMovingState,
    resetDrag,
  } = useDragDropMatch(
    responseIdentifier,
    variants,
    dropContainers,
    Number(minAssociations),
    Number(maxAssociations),
    InteractionType.associate,
    containersToResponse,
    responseToContainers,
  )

  const associations = containers ? pairs.filter(pair => containers[pair[0]] && containers[pair[1]]) : []

  const announcements = generateAnnouncement(generateHumanReadableIds(variants), false, false, [
    [FREE_VARIANTS_CONTAINER_ID, 'Variants container'],
  ])

  const dragContainerKeyDownHandler = (event: KeyboardEvent<HTMLDivElement>) =>
    event.key === 'Escape' && resetDrag()

  return (
    <div {...accessibilityAttr}>
      {prompt}
      <ConstraintsAlertContainer
        minChoices={Number(minAssociations)}
        maxChoices={Number(maxAssociations)}
        selectedCount={associations.length}
      />
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        accessibility={{ announcements }}
      >
        <VariantsContainerComponent>
          {variants.map((variant, idx) => {
            const id = variant.props.identifier
            return (
              <DragContainerComponent
                id={addUniqPartId(id)}
                containerId={FREE_VARIANTS_CONTAINER_ID}
                keyDownSubscriber={dragContainerKeyDownHandler}
                drag={id === cutUniqPartId(activeId)}
                disabled={isDisabledVariant(id)}
                selected={clickMovingState.activeBlockId === id && !clickMovingState.activeGapId}
                variantIndex={idx}
                roleAttr='listitem'
                fluid
              >
                {variant.props.children}
              </DragContainerComponent>
            )
          })}
        </VariantsContainerComponent>
        <AssociatePairsWrapper>
          {containers &&
            pairs.map((pairId, i) => (
              <AssociatePairDropBox
                key={i}
                pairId={pairId}
                containers={containers}
                variants={variants}
                isFinished={isFinished}
                isMaxMatches={isMaxMatches}
                handleRemove={handleRemove}
                clickMovingState={clickMovingState}
                dropContainers={dropContainers}
                variantContainers={variantContainers}
              />
            ))}
        </AssociatePairsWrapper>

        <DragOverlay dropAnimation={null}>
          {activeId && (
            <BasicDragContainerComponent placehold fluid>
              <>{variants.find(v => v.props.identifier === cutUniqPartId(activeId))}</>
            </BasicDragContainerComponent>
          )}
        </DragOverlay>
      </DndContext>
    </div>
  )
}
