import React from 'react'
import { Box, Stack, styled, Typography } from '@mui/material'

interface SubmitModalContentProps {
  children: JSX.Element
}

export const SubmitModalLayout: React.FC<SubmitModalContentProps> = ({ children }) => {
  return (
    <>
      {children ? (
        <Stack gap={6}>
          <Stack gap={4}>
            <Typography variant="variation_subtitle1">
              You will not be able to attempt any more questions once you submit.
            </Typography>
            <Typography variant="variation_subtitle1">
              Unanswered questions are not shaded below.<br />Click any bubble to return to that question.
            </Typography>
          </Stack>
          <ItemsListBlock>
            <ItemsListTitle variant='h5'>Questions</ItemsListTitle>
            <ItemsListWrapper>{children}</ItemsListWrapper>
          </ItemsListBlock>
        </Stack>
      ) : (
        <Typography variant="variation_subtitle1">
          <b>You still have time left.</b> You could take this time to review your answers.
        </Typography>
      )}
    </>
  )
}

const ItemsListBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 6, 6),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '3px',
}))

const ItemsListTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  marginBottom: theme.spacing(4),
}))

const ItemsListWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}))
