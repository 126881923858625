import { cloudStorage } from "@app/firebase"
import { ref, uploadBytes, UploadResult } from "@firebase/storage"
import { getBlob } from "firebase/storage"

export const getPackageByPath = async (path: string, testName: string): Promise<File> => {
  const fileRef = ref(cloudStorage, path)
  const fileBlob = await getBlob(fileRef)
  const file = new File([fileBlob], testName)

  return file
}

export const uploadPackageToCloudStorage = async (file: File): Promise<UploadResult> => {
  const storageRef = ref(cloudStorage, `assessments/${file.name}`)
  const result = await uploadBytes(storageRef, file)
  console.log(result)

  return result
}
