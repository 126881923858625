import { forwardRef, ForwardedRef, SyntheticEvent, useRef, useState, useLayoutEffect } from 'react'
import { Box, alpha, badgeClasses, styled } from '@mui/material'
import { Shape } from '@app/types'
import { getSVGScaleTransform, onKeyboardSubmit, useSVGScale } from '@app/helpers'
import { splitToPairs } from './utils'
import { BTN_CHOICE_ATTRIBUTES, DATA_CLICK_AWAY_PROPS, FIELD_CHOICE_CHECKED_ATTRIBUTES } from '@app/constants'
import { REVIEW_BADGE_ORIGIN, ReviewBadge, ReviewBadgeProps } from '@app/components/ui/badges'
import {
  // TooltipFeedbackGroup,
  TooltipFeedbackGroupProps,
} from '@app/components/feedback/TooltipFeedbackGroup'
import { focusStyle } from '@app/theme'

export interface HotspotChoiceProps {
  ref?: ForwardedRef<any>
  itemId: string
  identifier: string
  shape: Shape
  coords?: string
  hotspotLabel?: string
  children?: JSX.Element | JSX.Element[]
  selected?: boolean
  isOver?: boolean
  error?: boolean
  correct?: boolean
  groupMode?: boolean
  // feedbackItems?: TooltipFeedbackGroupProps['items']
  disabled?: boolean
  className?: string
  hotspotType?: string
  focusable?: boolean
  onToggle?: (event: SyntheticEvent) => void
  accessibilityAttr?: { [key: string]: string | boolean }
  role?: string
}

const getShapeElement = (shape: Shape, coords?: string): JSX.Element => {
  const coordsArr = coords ? coords.split(',').map(coord => parseInt(coord, 10)) : []

  switch (shape) {
    // ellipse is deprecated and should be implemented as circle or polygon,
    // see: https://www.imsglobal.org/question/qtiv2p2p4/QTIv2p2p4-ASI-InformationModelv1p0/imsqtiv2p2p4_asi_v1p0_InfoModelv1p0.html#Enumerated_Shape
    case Shape.circle:
    case Shape.ellipse: {
      return <circle cx={coordsArr[0]} cy={coordsArr[1]} r={coordsArr[2]} />
    }
    case Shape.rect: {
      const width = coordsArr[2] - coordsArr[0]
      const height = coordsArr[3] - coordsArr[1]
      return <rect x={coordsArr[0]} y={coordsArr[1]} width={width} height={height} />
    }
    case Shape.poly: {
      const points = splitToPairs(coordsArr)
        .map(pair => pair.join(','))
        .join(' ')
      return <polygon points={points} />
    }
    default: {
      return <rect x='0' y='0' width='100%' height='100%' />
    }
  }
}

const HotspotChoiceBase = forwardRef<SVGGraphicsElement, HotspotChoiceProps>((props, ref: any) => {
  const {
    shape,
    coords,
    onToggle,
    hotspotType,
    className,
    identifier,
    focusable = true,
    accessibilityAttr,
    selected,
    disabled,
    correct,
    groupMode = false,
    // feedbackItems,
    role = 'button',
  } = props
  const shapeElement = getShapeElement(shape, coords)

  const hotspotRef = useRef(null)
  const [hotspotBox, setHotspotBox] = useState({ x: 0, y: 0, width: '100%', height: '100%' })
  const svgScale = useSVGScale(hotspotRef)
  const scaleTransform = getSVGScaleTransform(svgScale)

  useLayoutEffect(() => {
    if (hotspotRef.current) {
      setHotspotBox(hotspotRef.current.getBBox())
    }
  }, [])

  const interactiveProps = disabled
    ? {}
    : {
        tabIndex: focusable ? 0 : undefined,
        onClick: onToggle,
        onKeyDown: onKeyboardSubmit(onToggle),
      }

  return (
    <HotspotWrapper {...BTN_CHOICE_ATTRIBUTES} {...DATA_CLICK_AWAY_PROPS}>
      <Hotspot
        as={shapeElement.type}
        ref={node => {
          if (ref) ref.current = node
          hotspotRef.current = node
        }}
        aria-label={`${identifier} - hotspot area`}
        {...shapeElement.props}
        {...interactiveProps}
        className={className}
        data-identifier={identifier}
        data-hotspot-type={hotspotType}
        {...(selected ? FIELD_CHOICE_CHECKED_ATTRIBUTES : {})}
        role={role}
        {...(role === 'checkbox' ? { 'aria-checked': selected } : {})}
        {...accessibilityAttr}
      />
      {(correct !== undefined || groupMode) && (
        <ReviewContainer
          x={hotspotBox.x}
          y={hotspotBox.y}
          width={hotspotBox.width}
          height={hotspotBox.height}
        >
          <StyledReviewBadge
            // as={groupMode ? TooltipFeedbackGroup : ReviewBadge}
            as={groupMode ? Box : ReviewBadge}
            correct={correct}
            // items={feedbackItems}
            anchorOrigin={REVIEW_BADGE_ORIGIN.topLeft}
            overlap={shape === 'circle' ? 'circular' : 'rectangular'}
            scaleTransform={scaleTransform}
          >
            <ReviewPlaceholder />
          </StyledReviewBadge>
        </ReviewContainer>
      )}
    </HotspotWrapper>
  )
})

const ReviewContainer = styled('foreignObject')({
  overflow: 'visible',
})

const StyledReviewBadge = styled(Box, {
  shouldForwardProp: prop => prop !== 'scaleTransform',
})<(ReviewBadgeProps | TooltipFeedbackGroupProps) & { scaleTransform: string }>(({ scaleTransform }) => ({
  width: '100%',
  height: '100%',
  display: 'block',

  [`& .${badgeClasses.badge}`]: {
    transform: `${scaleTransform} translate(-50%, -50%)`,
  },
}))

const ReviewPlaceholder = styled(Box)({
  width: '100%',
  height: '100%',
})

const HotspotWrapper = styled('g')(({ theme }) => ({
  '&:focus-within': {
    outline: 'none',
    [theme.breakpoints.up('mobile')]: {
      outline: focusStyle(theme, 0, 4).border.outline,
    },
  },
}))

export const Hotspot = styled('g')(() => ({}))

export const HotspotChoice = styled(HotspotChoiceBase)(({ theme, selected, disabled, error }) =>
  disabled
    ? {
        stroke: theme.palette.grey[100],
        strokeWidth: '2px',
        fill: 'transparent',
      }
    : {
        cursor: 'pointer',
        fill: selected ? alpha(theme.palette.green[200], 0.5) : 'transparent',
        stroke: selected ? theme.palette.green[600] : theme.palette.blue[200],
        strokeWidth: '2px',

        '&:hover': {
          [theme.breakpoints.up('mobile')]: {
            stroke: theme.palette.blue[500],
          },
        },

        '&:focus': {
          outline: 'none',
          [theme.breakpoints.up('mobile')]: {
            outline: focusStyle(theme, 2, 0).border.border,
          },
        },

        ...(error ? { stroke: theme.palette.error.main } : {}),
      },
)
