import { useCallback, useEffect, useMemo, useState } from 'react'
import Flatten from '@flatten-js/core'
import { GraphState } from '../types'
import { transformGraphsToResponse } from '../helpers'

export interface SelectableAreaState {
  d: string
  selected: boolean
}

export interface UseSelectableAreas {
  areas: SelectableAreaState[]
  onToggleArea: (index: number) => void
  setAreas: React.Dispatch<React.SetStateAction<SelectableAreaState[]>>
}

export const useSelectableAreas = (
  graphs: GraphState[],
  width: number,
  height: number,
  disabled?: boolean,
): UseSelectableAreas => {
  const bgRect = useMemo(() => new DOMRect(0, 0, width, height), [height, width])

  const [areas, setAreas] = useState<SelectableAreaState[]>([])

  // Used to compare graphs points only and don't affect line type change.
  const graphsStr = transformGraphsToResponse(graphs, false)

  useEffect(() => {
    if (disabled) return
    if (!graphsStr) return

    const pathElements: NodeListOf<SVGPathElement> = document.querySelectorAll('.graph')
    const graphsPaths: SVGPathElement[] = Array.from(pathElements)

    if (!graphsPaths.length) return

    const fBgPolygon = new Flatten.Polygon([
      [bgRect.left, bgRect.top],
      [bgRect.right, bgRect.top],
      [bgRect.right, bgRect.bottom],
      [bgRect.left, bgRect.bottom],
    ])

    const fAreas = graphsPaths.reduce(
      (areas, path) => {
        const pointStart = path.getPointAtLength(0)
        const pointEnd = path.getPointAtLength(path.getTotalLength())
        const fLine = new Flatten.Line(
          new Flatten.Point(pointStart.x, pointStart.y),
          new Flatten.Point(pointEnd.x, pointEnd.y),
        )

        return areas
          .map(area => {
            const points = area.intersect(fLine)
            const pointsSortes = fLine.sortPoints(points)
            const fMultiline = new Flatten.Multiline([fLine]).split(pointsSortes)
            return area.cut(fMultiline)
          })
          .flat()
      },
      [fBgPolygon],
    )

    const parser = new DOMParser()
    const areas = fAreas.flat().map(fArea => {
      const approxVertices = fArea.vertices.map<[number, number]>(vertex => [
        Math.round(vertex.x),
        Math.round(vertex.y),
      ])
      const approxArea = new Flatten.Polygon(approxVertices)
      return {
        d: parser.parseFromString(approxArea.svg(), 'text/xml').firstElementChild.getAttribute('d').trim(),
        selected: false,
      }
    })

    setAreas(current => {
      const existingSelectedAreas = current.filter(
        currentItem => currentItem.selected && areas.find(area => area.d === currentItem.d),
      )
      const newAreas = areas.filter(
        area => !existingSelectedAreas.find(selectedArea => selectedArea.d === area.d),
      )
      return [...existingSelectedAreas, ...newAreas]
    })
  }, [graphsStr, bgRect, disabled])

  const onToggleArea = useCallback(
    (index: number): void => {
      if (disabled) return

      setAreas(current => {
        current[index].selected = !current[index].selected
        return [...current]
      })
    },
    [disabled],
  )

  return { areas, onToggleArea, setAreas }
}
