import { useEffect, useRef, useState } from "react"


/**
 * Interactions constraints accessibility announcement generator.
 * @param selectedCount number of selected options.
 * @param maxChoices maximum choices.
 * @param minChoices minimum choices.
 * @returns screen reader announcement text.
 */
export const useConstraintsAnnouncement = (selectedCount: number, maxChoices: number, minChoices: number) => {
  const [announcement, setAnnouncement] = useState('')
  const previousSelectedCount = useRef<number>(null)

  useEffect(() => {
    if (!selectedCount && !previousSelectedCount.current) {
      return
    }

    previousSelectedCount.current = selectedCount

    if (selectedCount >= minChoices && selectedCount < maxChoices) {
      setAnnouncement(curr => curr ? 'You have selected the required number of options' : '')
      return
    }

    if (minChoices && selectedCount < minChoices) {
      setAnnouncement(`You have to select at least ${minChoices} ${minChoices === 1 ? 'option' : 'options'}. You have selected ${selectedCount}.`)
      return
    }

    if (maxChoices === selectedCount) {
      setAnnouncement(`You have selected ${selectedCount} of ${maxChoices} possible options.`)
      return
    }

  }, [selectedCount, maxChoices, minChoices])

  return announcement
}