import { AssessmentSettings } from '@app/firebase/api/types'
import { AssessmentItem, AssessmentTest } from '@app/models'
import { AssessmentStylesheet } from '@app/types'
import { proxy } from 'valtio'

export type CurrentQtiPackageType = {
  assessmentSettings: AssessmentSettings
  tests: AssessmentTest[]
  items: AssessmentItem[]
  stylesheets: AssessmentStylesheet[]
}

const initialState: CurrentQtiPackageType = {
  assessmentSettings: null,
  tests: [],
  items: [],
  stylesheets: [],
}

export const CurrentQtiPackageState = proxy<CurrentQtiPackageType>(initialState)

export const resetCurrentQtiPackage = () => {
  CurrentQtiPackageState.items = []
  CurrentQtiPackageState.stylesheets = []
  CurrentQtiPackageState.tests = []
  CurrentQtiPackageState.assessmentSettings = null
}

export const setCurrentQtiPackageTests = (tests: AssessmentTest[]) => {
  console.log('TESTS', tests)
  CurrentQtiPackageState.tests = tests
}

export const setCurrentQtiPackageSettings = (settings: AssessmentSettings) => {
  CurrentQtiPackageState.assessmentSettings = settings
}

export const setCurrentQtiPackageStylesheets = (stylesheets: AssessmentStylesheet[]) => {
  CurrentQtiPackageState.stylesheets = stylesheets
}

export const setCurrentQtiPackageItems = (items: AssessmentItem[]) => {
  CurrentQtiPackageState.items = items
}
