import { Popper, styled, tooltipClasses } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { focusStyle } from '@app/theme'

type IconWrapperStyleProps = {
  correct?: boolean
  groupMode?: boolean
}

export const StyledIconWrapper = styled('span', {
  shouldForwardProp: prop => !['correct', 'groupMode'].includes(prop as string),
})<IconWrapperStyleProps>(({ theme, correct, groupMode = false }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  width: '1rem',
  height: '1rem',
  backgroundColor: correct ? theme.palette.green[700] : theme.palette.orange[700],

  ...(groupMode && {
    backgroundColor: theme.palette.blue[600],
  }),

  '&:focus': {
    ...focusStyle(theme).shadow,
  },
}))

export const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: '0.5rem',
  color: theme.palette.common.white,
}))

// It's basically duplicate of StyledTooltipBase from TooltipFeedback/styles,
// but importing existing one breaks styling engine.
export const StyledTooltipBase = styled(Popper)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    margin: `0 !important`,
    padding: 0,
    borderRadius: '3px',
    boxShadow: theme.shadows[6],
    maxWidth: '250px',
  },
}))
