import { FC, SVGProps } from 'react'
import { styled } from '@mui/material'
import { Curve, CurveProps, VictoryThemeDefinition } from 'victory'
import { LineWithMarkers } from './LineWithMarkers'
import { IntervalDirection } from '../types'

export interface IntervalCurveProps extends CurveProps {
  selected?: boolean
  direction?: IntervalDirection
  theme?: VictoryThemeDefinition
}
export const IntervalCurve: FC<IntervalCurveProps> = ({ selected, theme, direction, ...props }) => (
  <Curve
    {...props}
    pathComponent={<IntervalLine selected={selected} direction={direction} />}
    disableInlineStyles
  />
)

export interface IntervalLineProps {
  selected?: boolean
  direction?: IntervalDirection
}
export const IntervalLine: FC<IntervalLineProps> = ({ direction, selected, ...props }) => {
  return (
    <LineWithMarkers
      id='intervalLine'
      Pointer={StyledAxisPointer}
      useStartMarker={direction === IntervalDirection.LEFT}
      useEndMarker={direction === IntervalDirection.RIGHT}
    >
      <StyledLine {...props} selected={selected} vectorEffect='non-scaling-stroke' />
    </LineWithMarkers>
  )
}

const StyledAxisPointer = styled('polygon')(({ theme }) => ({
  fill: theme.palette.grey[800],
}))

const StyledLine = styled(({ selected, ...props }: SVGProps<SVGPathElement> & { selected?: boolean }) => (
  <path {...props} />
))(({ selected = false, theme }) => ({
  stroke: selected ? theme.palette.additionalColors['bg_500'] : theme.palette.grey[400],
  strokeWidth: selected ? 10 : 6,
}))
