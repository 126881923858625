import { ReactNode, FC } from 'react'
import { createPortal } from 'react-dom'

export interface PortalProps {
  container: Element
  children: ReactNode
}

export const Portal: FC<PortalProps> = props => {
  const { container, children } = props
  return createPortal(children, container)
}
