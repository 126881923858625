import {
  ChoiceInteractionComponent,
  InfoControl,
  TextEntryInteraction,
  Prompt,
  FeedbackInline,
  ExtendedTextInteraction,
  FeedbackBlock,
  AssociateInteractionComponent,
  OrderInteractionComponent,
  SimpleChoiceComponent,
  HotspotInteraction,
  HotspotChoice,
  ObjectComponent,
  SimpleAssociableChoiceComponent,
  MatchInteractionComponent,
  SimpleMatchSetComponent,
  InlineChoiceInteractionComponent,
  InlineChoiceComponent,
  GapMatchInteractionComponent,
  GapTextComponent,
  GapImgComponent,
  GapComponent,
  GraphicOrderInteraction,
  BlockQuoteComponent,
  AssociableHotspot,
  GraphicAssociateInteraction,
  SelectPointInteraction,
  HotTextInteractionComponent,
  HotTextComponent,
  GraphicGapMatchInteraction,
  PositionObjectStage,
  PositionObjectInteraction,
  RubricBlockComponent,
  GridInInteractionComponent,
  GridInSymbolComponent,
  GridInSymbolsComponent,
  CustomSpan,
  EquationEditorComponent,
  GraphingInteractionContainer,
  NumberLineComponent,
  MathMLComponent,
  Paragraph,
  WithFeedback,
} from '@app/components'
import { CustomInteractionIds, DomNodes } from '@app/constants'
import { Typography } from '@mui/material'
import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser'
import { FC, memo, useCallback, useMemo } from 'react'
import { getAccessibilityAttributes, getMpQtiDataAttributes, getStylesObjectFromString } from '@app/helpers'
import { PackagedImage } from './PackagedImage'

interface ContentRendererProps {
  content: string
  itemId?: string
  resourceId?: string
}

const ContentRenderer: FC<ContentRendererProps> = memo(({ content, itemId, resourceId }) => {
  const options: HTMLReactParserOptions = useMemo(
    () => ({
      replace: domNode => renderComponent(domNode),
    }),
    // eslint-disable-next-line
    [itemId],
  )

  const repairSelfClosingTags = (content: string) => {
    const selfClosingReg = /<(\w+)([^>]*)\/\s*>/g

    return content.replace(selfClosingReg, (source, tagName, content) => {
      // Don't replace "<br/>" tags, because it doubles breaks.
      // See https://gitlab.com/masteryprep/masteryprep-platform/qti-pwa/-/issues/13
      if (tagName === 'br') return source

      return `<${tagName}${content}></${tagName}>`
    })
  }

  const transformStringToSolid = (content: string) => {
    return content.split('\n').join('').trim()
  }

  const getCustomInteractionComponent = useCallback(
    (props: any, children: React.ReactNode, responseDeclarationId: string) => {
      const { id, ...rest } = props

      switch (id) {
        case CustomInteractionIds.gridIn: {
          return (
            <WithFeedback
              itemId={props.itemId}
              responseDeclarationId={responseDeclarationId}
              showCorrectAnswer
            >
              <GridInInteractionComponent {...rest}>{children}</GridInInteractionComponent>
            </WithFeedback>
          )
        }
        case CustomInteractionIds.equationEditor: {
          return (
            <WithFeedback
              itemId={props.itemId}
              responseDeclarationId={responseDeclarationId}
              showCorrectAnswer
            >
              <EquationEditorComponent {...rest}>{children}</EquationEditorComponent>
            </WithFeedback>
          )
        }
        case CustomInteractionIds.graphing: {
          return (
            <GraphingInteractionContainer key={props.itemId} {...rest}>
              {children}
            </GraphingInteractionContainer>
          )
        }
        case CustomInteractionIds.numberLine: {
          return (
            <NumberLineComponent key={props.itemId} {...rest}>
              {children}
            </NumberLineComponent>
          )
        }
      }
    },
    [],
  )

  const renderComponent = useCallback(
    (domNode: any) => {
      const props = domNode.attribs && {
        ...(itemId && { itemId: itemId }),
        componentNode: domNode.name,
        className: domNode.attribs.class,
        ...getAccessibilityAttributes(domNode.attribs),
        ...getStylesObjectFromString(domNode.attribs),
      }
      const children = domNode.children && domToReact(domNode.children, options)

      switch (domNode.name) {
        case DomNodes.span: {
          return <CustomSpan {...props}>{children}</CustomSpan>
        }
        case DomNodes.div: {
          const { itemId, componentNode, ...restProps } = props
          return <div {...restProps}>{children}</div>
        }
        case DomNodes.typography: {
          const { itemId, componentNode, ...restProps } = props
          return <Paragraph {...restProps}>{children}</Paragraph>
        }
        case DomNodes.typographyH1:
        case DomNodes.typographyH2:
        case DomNodes.typographyH3:
        case DomNodes.typographyH4:
        case DomNodes.typographyH5:
        case DomNodes.typographyH6: {
          return (
            <Typography variant={domNode.name} className={props.className}>
              {children}
            </Typography>
          )
        }
        case DomNodes.image: {
          const { class: _class, ...rest } = props
          return <PackagedImage {...rest} resourceId={resourceId} />
        }
        case DomNodes.math: {
          return <MathMLComponent {...props}>{children}</MathMLComponent>
        }
        case DomNodes.textEntryInteraction: {
          return <TextEntryInteraction {...props}>{children}</TextEntryInteraction>
        }
        case DomNodes.infoControl: {
          return <InfoControl {...props}>{children}</InfoControl>
        }
        case DomNodes.choiceInteraction: {
          const { startLetter } = getMpQtiDataAttributes(domNode.attribs)

          return (
            <WithFeedback
              itemId={itemId}
              responseDeclarationId={domNode.attribs.responseidentifier}
              useByAnswerFeedback
            >
              <ChoiceInteractionComponent {...props} startLetter={startLetter}>
                {children}
              </ChoiceInteractionComponent>
            </WithFeedback>
          )
        }
        case DomNodes.simpleChoice: {
          return <SimpleChoiceComponent {...props}>{children}</SimpleChoiceComponent>
        }
        case DomNodes.prompt: {
          return <Prompt {...props}>{children}</Prompt>
        }
        case DomNodes.feedbackInline: {
          return <FeedbackInline {...props}>{children}</FeedbackInline>
        }
        case DomNodes.feedbackBlock: {
          return <FeedbackBlock {...props}>{children}</FeedbackBlock>
        }
        case DomNodes.extendedTextInteraction: {
          return (
            <WithFeedback
              itemId={itemId}
              responseDeclarationId={domNode.attribs.responseidentifier}
              forceMultiscore
              multiScoreFormatter={(raw, possible) => `${raw} of ${possible} Points Received`}
            >
              <ExtendedTextInteraction {...props}>{children}</ExtendedTextInteraction>
            </WithFeedback>
          )
        }
        case DomNodes.associateInteraction: {
          return (
            <WithFeedback itemId={itemId} responseDeclarationId={domNode.attribs.responseidentifier}>
              <AssociateInteractionComponent {...props}>{children}</AssociateInteractionComponent>
            </WithFeedback>
          )
        }
        case DomNodes.simpleAssociableChoice: {
          const parentNodeName = domNode?.parent?.name
          return (
            <SimpleAssociableChoiceComponent parentNodeName={parentNodeName} {...props}>
              {children}
            </SimpleAssociableChoiceComponent>
          )
        }
        case DomNodes.orderInteraction: {
          return (
            <WithFeedback itemId={itemId} responseDeclarationId={domNode.attribs.responseidentifier}>
              <OrderInteractionComponent {...props}>{children}</OrderInteractionComponent>
            </WithFeedback>
          )
        }
        case DomNodes.hotspotInteraction: {
          return (
            <WithFeedback
              itemId={itemId}
              responseDeclarationId={domNode.attribs.responseidentifier}
              useByAnswerFeedback
            >
              <HotspotInteraction {...props}>{children}</HotspotInteraction>
            </WithFeedback>
          )
        }
        case DomNodes.hotspotChoice: {
          return <HotspotChoice {...props}>{children}</HotspotChoice>
        }
        case DomNodes.object: {
          return (
            <ObjectComponent {...props} resourceId={resourceId}>
              {children}
            </ObjectComponent>
          )
        }
        case DomNodes.matchInteraction: {
          return (
            <WithFeedback
              itemId={itemId}
              responseDeclarationId={domNode.attribs.responseidentifier}
              useByAnswerFeedback
            >
              <MatchInteractionComponent {...props}>{children}</MatchInteractionComponent>
            </WithFeedback>
          )
        }
        case DomNodes.simpleMatchSet: {
          return <SimpleMatchSetComponent {...props}>{children}</SimpleMatchSetComponent>
        }
        case DomNodes.inlineChoiceInteraction: {
          return <InlineChoiceInteractionComponent {...props}>{children}</InlineChoiceInteractionComponent>
        }
        case DomNodes.inlineChoice: {
          return <InlineChoiceComponent {...props}>{children}</InlineChoiceComponent>
        }
        case DomNodes.gapMatchInteraction: {
          return (
            <WithFeedback
              itemId={itemId}
              responseDeclarationId={domNode.attribs.responseidentifier}
              useByAnswerFeedback
            >
              <GapMatchInteractionComponent {...props}>{children}</GapMatchInteractionComponent>
            </WithFeedback>
          )
        }
        case DomNodes.gapText: {
          return <GapTextComponent {...props}>{children}</GapTextComponent>
        }
        case DomNodes.gapImg: {
          return <GapImgComponent {...props}>{children}</GapImgComponent>
        }
        case DomNodes.gap: {
          return <GapComponent {...props}>{children}</GapComponent>
        }
        case DomNodes.blockQuote: {
          return <BlockQuoteComponent {...props}>{children}</BlockQuoteComponent>
        }
        case DomNodes.graphicOrderInteraction: {
          return (
            <WithFeedback itemId={itemId} responseDeclarationId={domNode.attribs.responseidentifier}>
              <GraphicOrderInteraction {...props}>{children}</GraphicOrderInteraction>
            </WithFeedback>
          )
        }
        case DomNodes.associableHotspot: {
          return <AssociableHotspot {...props}>{children}</AssociableHotspot>
        }
        case DomNodes.graphicAssociateInteraction: {
          return (
            <WithFeedback itemId={itemId} responseDeclarationId={domNode.attribs.responseidentifier}>
              <GraphicAssociateInteraction {...props}>{children}</GraphicAssociateInteraction>
            </WithFeedback>
          )
        }
        case DomNodes.selectPointInteraction: {
          return (
            <WithFeedback itemId={itemId} responseDeclarationId={domNode.attribs.responseidentifier}>
              <SelectPointInteraction {...props}>{children}</SelectPointInteraction>
            </WithFeedback>
          )
        }
        case DomNodes.hotTextInteraction: {
          return (
            <WithFeedback
              itemId={itemId}
              responseDeclarationId={domNode.attribs.responseidentifier}
              useByAnswerFeedback
            >
              <HotTextInteractionComponent {...props}>{children}</HotTextInteractionComponent>
            </WithFeedback>
          )
        }
        case DomNodes.hotText: {
          return <HotTextComponent {...props}>{children}</HotTextComponent>
        }
        case DomNodes.graphicGapMatchInteraction: {
          return (
            <WithFeedback itemId={itemId} responseDeclarationId={domNode.attribs.responseidentifier}>
              <GraphicGapMatchInteraction key={props.itemId} {...props}>
                {children}
              </GraphicGapMatchInteraction>
            </WithFeedback>
          )
        }
        case DomNodes.positionObjectStage: {
          return <PositionObjectStage {...props}>{children}</PositionObjectStage>
        }
        case DomNodes.positionObjectInteraction: {
          return (
            <WithFeedback itemId={itemId} responseDeclarationId={domNode.attribs.responseidentifier}>
              <PositionObjectInteraction {...props}>{children}</PositionObjectInteraction>
            </WithFeedback>
          )
        }
        case DomNodes.rubricBlock: {
          return <RubricBlockComponent {...props}>{children}</RubricBlockComponent>
        }
        case DomNodes.customInteraction: {
          return getCustomInteractionComponent(props, children, domNode.attribs.responseidentifier)
        }
        case DomNodes.gridInSymbols: {
          return <GridInSymbolsComponent {...props}>{children}</GridInSymbolsComponent>
        }
        case DomNodes.gridInSymbol: {
          return <GridInSymbolComponent {...props}>{children}</GridInSymbolComponent>
        }
      }
    },
    [itemId, options, resourceId, getCustomInteractionComponent],
  )

  return <>{content && parse(repairSelfClosingTags(transformStringToSolid(content)), options)}</>
})

export default ContentRenderer
