import { useState, useCallback } from 'react'
import { getSVGScaleTransform, useSVGScale } from '@app/helpers'

export const useSVGScaleTransform = (childRef: React.MutableRefObject<Element>) => {
  const svgScale = useSVGScale(childRef)

  return getSVGScaleTransform(svgScale)
}

export interface FocusControlCallbacks {
  handleTargetBlur?: (event: React.FocusEvent) => void
  handleTargetKeyDown?: (event: React.KeyboardEvent) => void
  handleTooltipContentKeyDown?: (event: React.KeyboardEvent) => void
}

export interface UseFocusControl {
  onTargetBlur: (event: React.FocusEvent) => void
  onTargetKeyDown: (event: React.KeyboardEvent) => void
  onTooltipContentKeyDown: (event: React.KeyboardEvent) => void
}

export const useFocusControl = (
  targetRef: React.MutableRefObject<any>,
  tooltipContentRef: React.MutableRefObject<any>,
  callbacks: FocusControlCallbacks = {},
) => {
  const {
    handleTargetBlur,
    handleTargetKeyDown,
    handleTooltipContentKeyDown,
  } = callbacks

  const [focusFlow, setFocusFlow] = useState<boolean>(null)
  const [nextFocusTarget, setNextFocusTarget] = useState<HTMLElement>(null)

  const onTooltipContentKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      handleTooltipContentKeyDown && handleTooltipContentKeyDown(event)

      if (event.key !== 'Tab') return

      event.preventDefault()
      event.stopPropagation()

      // If focus goes backward, go to child element
      if (event.shiftKey) {
        targetRef.current && targetRef.current.firstChild.focus()
        return
      }

      // If focus goes forward, go to target before interruption
      nextFocusTarget && nextFocusTarget.focus()
    },
    [nextFocusTarget, targetRef, handleTooltipContentKeyDown],
  )

  const onTargetKeyDown = useCallback((event: React.KeyboardEvent) => {
    handleTargetKeyDown && handleTargetKeyDown(event)

    if (event.key !== 'Tab') return

    // Forward or backward
    setFocusFlow(!event.shiftKey)
  }, [handleTargetKeyDown])

  const onTargetBlur = useCallback(
    (event: React.FocusEvent) => {
      handleTargetBlur && handleTargetBlur(event)

      // If button is visible and focus goes forward, interrupt normal flow
      if (tooltipContentRef.current && focusFlow && event.nativeEvent.relatedTarget) {
        event.preventDefault()
        event.stopPropagation()

        setNextFocusTarget(event.nativeEvent.relatedTarget as HTMLElement)
        tooltipContentRef.current.focus()
      }
    },
    [focusFlow, tooltipContentRef, handleTargetBlur],
  )

  return { onTargetBlur, onTargetKeyDown, onTooltipContentKeyDown }
}
