import { Children, cloneElement, Dispatch, isValidElement, ReactNode, SetStateAction } from 'react'
import { v4 as uuid } from 'uuid'
import { CollisionDetection, Collision, ClientRect } from '@dnd-kit/core'
import { Announcements } from '@dnd-kit/core/dist/components/Accessibility/types'
import { AlertColor } from '@mui/material'
import {
  ChangeCountingVariantsType,
  CountingVarinat,
  CountingVarinatsState,
  DropContainerScoring,
  DropContainerState,
  IClickMovingState,
  SetContainerDropElementType,
} from '@app/types'
import { AssessmentItem } from '@app/models'
import { DomNodes, FREE_VARIANTS_CONTAINER_ID } from '@app/constants'

/**
 * Converting min and max choices values to number.
 * @param minChoices minimum choices.
 * @param maxChoices maximum choices.
 * @returns Array of number equivalents.
 */
export const transformChoicesConstraints = (minChoices: string, maxChoices: string): [number, number] => {
  // If min/max choices are not defined, use 0.
  return [minChoices ? Number(minChoices) : 0, maxChoices ? Number(maxChoices) : 0]
}

/**
 * Function to inject props to specific elements from the children.
 * - Support for letter indexes included.
 * @param { DomNodes } node child node injection target.
 * @param { JSX.Element[] } children
 * @param props
 * @param withLetterIndex
 * @returns
 */
export const getChildrenWithInjectedProps = (
  node: DomNodes,
  children: ReactNode[],
  props: any,
  options?: {
    withLetterIndex?: boolean
    startLetter: string
  },
): ReactNode[] => {
  let index = 0

  if (!children) {
    return null
  }

  return Children.map(children, child => {
    if (!isValidElement(child)) {
      return child
    }

    if (child.props?.componentNode && child.props?.componentNode === node) {
      const { withLetterIndex, startLetter = 'A' } = options ?? {}
      const startLetterIndex = startLetter.charCodeAt(0)
      let charCode: number

      if (startLetterIndex + index === 105) {
        charCode = startLetterIndex + ++index
        ++index
      } else {
        charCode = startLetterIndex + index++
      }

      return cloneElement(child, {
        ...props,
        ...(withLetterIndex && {
          letterIndex: String.fromCharCode(charCode),
        }),
      })
    }

    if (child.props?.children) {
      return cloneElement(child, {
        ...child.props,
        children: getChildrenWithInjectedProps(node, child.props?.children, props, options),
      })
    }

    return child
  })
}

export const getResponseDeclarationById = (item: AssessmentItem, id: string) => {
  return item.responseDeclaration.find(declaration => declaration.id === id)
}

export const cutUniqPartId = (id: string) => id?.split('/')[0]
export const addUniqPartId = (id: string) => `${id}/${uuid()}`

/**
 * Get storage key for selected test items by test ID.
 * @param testId Test ID.
 * @returns Storage key.
 */
// Based on assumption that test can be completed only once within a session.
export const getSelectedItemsKey = (testId: string) => `selection_${testId}`

const getIntersectionRatio = (rect: ClientRect, collisionRect: ClientRect): number => {
  const top = Math.max(collisionRect.top, rect.top)
  const left = Math.max(collisionRect.left, rect.left)
  const right = Math.min(collisionRect.right, rect.right)
  const bottom = Math.min(collisionRect.bottom, rect.bottom)
  const width = right - left
  const height = bottom - top

  if (left < right && top < bottom) {
    const targetArea = collisionRect.width * collisionRect.height
    const entryArea = rect.width * rect.height
    const intersectionArea = width * height
    const ratio = intersectionArea / (targetArea + entryArea - intersectionArea)
    return ratio
  }

  return 0
}

export const sortCollisionsDesc = (collisionA: Collision, collisionB: Collision): number => {
  const {
    data: { value: a },
  } = collisionA
  const {
    data: { value: b },
  } = collisionB
  return b - a
}

export const viewportRectIntersection: CollisionDetection = ({
  droppableContainers,
  droppableRects,
  collisionRect,
}) => {
  const collisions: Collision[] = []

  for (const droppableContainer of droppableContainers) {
    const { id } = droppableContainer
    const rect = droppableRects.get(id)

    if (rect) {
      const node = droppableContainer.node.current
      const nodeRect = node.getBoundingClientRect()
      const intersectionRatio = getIntersectionRatio(nodeRect, collisionRect)

      if (intersectionRatio > 0) {
        collisions.push({
          id,
          data: { droppableContainer, value: intersectionRatio },
        })
      }
    }
  }

  return collisions.sort(sortCollisionsDesc)
}

export const shuffleArray = <T>(shuffle: boolean, array: T[]) => {
  return shuffle ? array.sort(() => Math.random() - 0.5) : array
}

export const createArray = (number: number) => Array.from(Array(number).keys())

export const stringToBoolean = (value: string): boolean => {
  switch (value?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true
    case 'false':
    case 'no':
    case '0':
      return false
    default:
      return false
  }
}

export const getAccessibilityAttributes = (attributes: any) => {
  const accessibilityArray = [
    'role',
    'aria-controls',
    'aria-describedby',
    'aria-flowto',
    'aria-label',
    'aria-labelledby',
    'aria-level',
    'aria-orientation',
    'aria-owns',
  ]

  return Object.entries(attributes).reduce((props, [key, value]) => {
    if (accessibilityArray.includes(key)) {
      props.accessibilityAttr
        ? (props.accessibilityAttr[key] = value)
        : (props.accessibilityAttr = { [key]: value })
    } else {
      props[key] = value
    }

    return props
  }, {} as { [key: string]: any }) as any
}

export const getStylesObjectFromString = (attributes: any) => {
  if (!attributes?.hasOwnProperty('style')) return {}

  const stylesObject = attributes.style.split(';').reduce((res: Record<string, string>, item: string) => {
    const [styleProp, styleValue] = item.split(':')

    if (!styleProp || !styleValue) return res

    return {
      ...res,
      [styleProp]: styleValue,
    }
  }, {})

  return { style: { ...stylesObject } }
}

export const calculateSVGScale = (svg: SVGSVGElement) => {
  if (!svg) return []

  const viewboxWidth = svg.viewBox.animVal.width
  const viewboxHeight = svg.viewBox.animVal.height

  if (!viewboxWidth && !viewboxHeight) {
    // No viewBox
    return [1, 1]
  }

  const computedStyle = getComputedStyle(svg)
  const svgWidth = parseFloat(computedStyle.width)
  const svgHeight = parseFloat(computedStyle.height)
  const preserveAspectRatio = svg.preserveAspectRatio.animVal

  if (preserveAspectRatio.align === SVGPreserveAspectRatio.SVG_PRESERVEASPECTRATIO_NONE) {
    return [svgWidth / viewboxWidth, svgHeight / viewboxHeight]
  }

  const meet = preserveAspectRatio.meetOrSlice === SVGPreserveAspectRatio.SVG_MEETORSLICE_MEET
  const calculatedMeet = viewboxWidth / viewboxHeight > svgWidth / svgHeight
  const ratio = calculatedMeet !== meet ? svgHeight / viewboxHeight : svgWidth / viewboxWidth

  return [ratio, ratio]
}

export const getSVGScaleTransform = (svgScale: number[]) => `scale(${1 / svgScale[0]}, ${1 / svgScale[1]})`

export const getResponseSingleItem = (response: string[]) => response[0] || ''

export const getInitialCountingVariants = (variants: JSX.Element[], response: string[]) => {
  return variants.reduce((res: CountingVarinatsState, v) => {
    const id: string = v.props.identifier

    return {
      ...res,
      [id]: response.reduce(
        (acc: CountingVarinat, item) => {
          return item?.includes(id)
            ? {
                ...acc,
                matchCount: acc.matchMax && acc.matchCount - 1,
              }
            : acc
        },
        {
          matchCount: v?.props?.matchmax ? Number(v.props.matchmax) : 0,
          matchMax: v?.props?.matchmax ? Number(v.props.matchmax) : 0,
          matchMin: v?.props?.matchmin ? Number(v.props.matchmin) : 0,
        },
      ),
    }
  }, {})
}

export const getInitialContainers = (dropContainers: string[], itemResponse: string[]) => {
  return dropContainers.reduce((res: DropContainerState, dc: string) => {
    const response = itemResponse?.find(item => item.includes(dc))?.split(' ')[1]
    return {
      ...res,
      [dc]: response || null,
    }
  }, {})
}

export const getScoredContainers = (
  containers: DropContainerState,
  groupMode: boolean,
  correctResponse: string[] = [],
): DropContainerScoring => {
  return correctResponse.reduce((acc, response) => {
    const [gap, choice] = response.split(' ')

    if (groupMode ? true : containers[gap]) {
      return {
        ...acc,
        [gap]: {
          correct: groupMode ? undefined : containers[gap] === choice,
          correctAnswer: choice,
        },
      }
    }

    return acc
  }, {})
}

export const generateAttrWithValue = (testId: string, elementId: string, value: string | number) => ({
  [testId]: elementId + value,
})

export const checkValidityString = (patternMask: string | undefined, value: string): boolean => {
  if (!patternMask) return true

  const rgx = new RegExp(patternMask)
  return rgx.test(value)
}

export const logicTransferingVariantToContainer = (
  dropConatainerId: string,
  draggbleVariantId: string,
  startContainer: string,
  containers: { [x: string]: string },
  changeCountingVariants: (type: ChangeCountingVariantsType) => (variantId: string[] | null) => void,
  setContainerDropElement: (
    type: SetContainerDropElementType,
  ) => (containerId: string | null, variantId?: string | null) => void,
  setClickMovingState: Dispatch<SetStateAction<IClickMovingState>>,
) => {
  if (dropConatainerId && draggbleVariantId) {
    if (dropConatainerId === FREE_VARIANTS_CONTAINER_ID && startContainer !== FREE_VARIANTS_CONTAINER_ID) {
      changeCountingVariants(ChangeCountingVariantsType.add)([draggbleVariantId])
      setContainerDropElement(SetContainerDropElementType.reset)(startContainer)
    }

    if (startContainer === FREE_VARIANTS_CONTAINER_ID && dropConatainerId !== FREE_VARIANTS_CONTAINER_ID) {
      const dropContainerLastElement = containers[dropConatainerId]

      if (dropContainerLastElement)
        changeCountingVariants(ChangeCountingVariantsType.add)([dropContainerLastElement])
      changeCountingVariants(ChangeCountingVariantsType.sub)([draggbleVariantId])
      setContainerDropElement(SetContainerDropElementType.add)(dropConatainerId, draggbleVariantId)
      setClickMovingState({
        activeGapId: dropConatainerId,
        activeGapBlockId: draggbleVariantId,
        activeBlockId: null,
      })
    }

    if (startContainer !== FREE_VARIANTS_CONTAINER_ID && dropConatainerId !== FREE_VARIANTS_CONTAINER_ID) {
      const dropContainerLastElement = containers[dropConatainerId]

      setContainerDropElement(SetContainerDropElementType.add)(dropConatainerId, draggbleVariantId)
      setContainerDropElement(SetContainerDropElementType.add)(startContainer, dropContainerLastElement)
      setClickMovingState({
        activeGapId: dropConatainerId,
        activeGapBlockId: draggbleVariantId,
        activeBlockId: null,
      })
    }
  }
}

export const generateAlertContentAndColor = (
  length: number,
  min: number,
  max: number,
): [string, AlertColor] => {
  if (!min && max) {
    if (max === length) return [`A maximum of ${max} choices selected.`, 'success']
    return [`${max} choice${max > 1 ? 's' : ''} maximum.`, 'info']
  }

  if (min && !max) {
    if (length >= min) return [`A minimum of ${min} choices selected.`, 'success']
    return [`A minimum of ${min} choices required.`, 'warning']
  }

  if (!min && !max) {
    return [`No choice limit. ${length} selected.`, 'info']
  }

  if (length >= min && length !== max) {
    return [`A minimum of ${min} choices selected. ${max} choice${max > 1 ? 's' : ''} maximum.`, 'success']
  }
  if (length <= min && length !== max) {
    return [`A minimum of ${min} choices required. ${max} choice${max > 1 ? 's' : ''} maximum.`, 'warning']
  }
  if (length === max) {
    return [`A maximum of ${length} choice${max > 1 ? 's' : ''} selected.`, 'success']
  }
}

/**
 * Extract HTML from specific tag.
 * @param { String } content HTML content in string format.
 * @param { String } rootTag HTML content root tag.
 * @returns { String } extracted HTML content.
 */
export const extractHtmlContentFromTag = (content: string, rootTag: string): string => {
  const startTag = `<${rootTag}>`
  const endTag = `</${rootTag}>`

  return content.slice(content.search(startTag) + startTag.length, content.search(endTag))
}

export const getInteractionAndInfoControl = (itemBody: string, tagName: string): [string, string] => {
  const startTag = new RegExp(`<${tagName}.*?>`)
  const endTag = `</${tagName}>`
  const cut = itemBody.slice(itemBody.search(startTag), itemBody.search(endTag) + endTag.length)
  const rest = itemBody.split(cut).join('')

  return [cut, rest]
}

/**
 * Generate an element ID with a human-readable value from the aria-label
 * @param elements
 * return Object where key === identifier, value === aria-label
 */
export const generateHumanReadableIds = (elements: JSX.Element[]): { [key: string]: string } | false => {
  const resultObject = elements.reduce((acc, el) => {
    const attributes = el.props.accessibilityAttr
    if (attributes && attributes['aria-label']) {
      return { ...acc, [el.props.identifier]: attributes['aria-label'] }
    }
    return acc
  }, {})

  return Boolean(Object.keys(resultObject).length) ? resultObject : false
}

/**
 * Generate announcement for dnd-kit accessibility
 * @param activeBlocks Draggable blocks
 * @param overBlocks Hotspot areas
 * @param ordering if we use indexes for draggable boxes
 * @param specificNaming array with key/value strings [[id, humanReadableNaming]]
 */
export const generateAnnouncement = (
  activeBlocks: { [key: string]: string } | false,
  overBlocks: { [key: string]: string } | false,
  ordering = false,
  specificNaming?: Array<[string, string]>,
): Announcements => {
  const textForDrag = (id: string, blocks: { [key: string]: string } | false, ordering = false) => {
    if (ordering && id) return Number(id) + 1
    if (specificNaming?.length) {
      const nameArray = specificNaming.find(item => item[0] === id)
      if (nameArray) {
        return nameArray[1]
      }
    }
    const elementId = cutUniqPartId(id)
    if (blocks) {
      return blocks[elementId] ? blocks[elementId] : elementId
    }
    return elementId
  }

  return {
    onDragStart({ active }) {
      return `Picked up draggable item ${textForDrag(active.id.toString(), activeBlocks, ordering)}.`
    },
    onDragOver({ active, over }) {
      if (over) {
        return `Draggable item ${textForDrag(
          active.id.toString(),
          activeBlocks,
          ordering,
        )} was moved over droppable area ${textForDrag(over['id'].toString(), overBlocks)}.`
      }

      return `Draggable item ${textForDrag(
        active.id.toString(),
        activeBlocks,
        ordering,
      )} is no longer over a droppable area.`
    },
    onDragEnd({ active, over }) {
      if (over) {
        return `Draggable item ${textForDrag(
          active.id.toString(),
          activeBlocks,
          ordering,
        )} was dropped over droppable area ${textForDrag(over['id'].toString(), overBlocks)}`
      }

      return `Draggable item ${textForDrag(active.id.toString(), activeBlocks, ordering)} was dropped.`
    },
    onDragCancel({ active }) {
      return `Dragging was cancelled. Draggable item ${textForDrag(
        active.id.toString(),
        activeBlocks,
        ordering,
      )} was dropped.`
    },
  }
}

export const composeRefs = (refs: any[]) => (element: Element) => {
  refs.forEach(ref => {
    if (typeof ref === 'function') {
      ref(element)
    } else if (ref) {
      ref.current = element
    }
  })
}

export const isEqualArray = (first: any[], second: any[]): boolean => {
  if (first === second) return true
  if (first === null || second === null) return false
  if (first.length !== second.length) return false

  return first.every((val, index) => val === second[index])
}

export const getHighlighterKey = (
  scope: string,
  userId: string,
  testId: string,
  entityId: string,
): string => {
  return ['hl', scope, userId, testId, entityId].join('_')
}

/**
 * Get random integer between two integer values (inclusive).
 * @param {number} min
 * @param {number} max
 * @returns {number} Random integer within boundaries.
 */
export const getRandomInteger = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
