import { IconProp } from '@fortawesome/fontawesome-svg-core'

export enum ControlsCommands {
  moveToNextChar = 'moveToNextChar',
  moveToPreviousChar = 'moveToPreviousChar',
  deleteBackward = 'deleteBackward',
  redo = 'redo',
  undo = 'undo',
}

export enum EquationOperatorsTypes {
  numbers = 'numbers',
  symbols = 'symbols',
  actions = 'actions',
}

export interface EquationSectionType {
  operators: string[]
  type: EquationOperatorsTypes
}

export interface IMathFieldControls {
  icon: IconProp
  command: () => void
  label: string
}

export enum SectionType {
  numbersSection = 'numbersSection',
  actionsSections = 'actionsSections',
}

export type CurrentButtonPosition = {
  ref: HTMLButtonElement
  row: number
  column: number
  label: string
}
