import { badgeClasses, Box, styled, TableCell, TableContainer, TableRow } from '@mui/material'
import { scrollBarMixin } from '@app/constants'
import { TooltipFeedbackGroup } from '@app/components/feedback/TooltipFeedbackGroup'
import { focusStyle } from '@app/theme'

export const StyledTooltipFeedbackGroup = styled(TooltipFeedbackGroup)(() => ({
  [`& .${badgeClasses.badge}`]: {
    bottom: 0,
    right: '50%',
  },
}))

export const StyledTableContainer = styled(TableContainer)(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(3.5)};
  margin: ${theme.spacing(0, 0, 2)};
  width: 100%;
  overflow-x: auto;
  ${scrollBarMixin(theme, 'horizontal')};
`,
)

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.grey[50],
  },
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  width: '20%',
  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.grey[100]}`,
  },
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  color: theme.palette.grey[800],
}))

export const HeaderTableCell = styled(StyledTableCell)(({ theme }) => ({
  padding: theme.spacing(3),
  borderBottom: `2px solid ${theme.palette.grey[100]}`,
}))

export const BodyTableCell = styled(StyledTableCell)(({ theme }) => ({
  padding: theme.spacing(3),
}))

interface CheckboxWrapperProps {
  disabled: boolean
  checked: boolean
}

export const CheckboxWrapper = styled(Box)<CheckboxWrapperProps>(({ theme, disabled }) => ({
  padding: theme.spacing(3),
  border: `1px solid transparent`,
  borderRadius: theme.shape.borderRadius * 1.5,
  outline: `3px solid transparent`,
  transition: theme.transitions.create(['background-color', 'border-color']),

  ':focus-within': {
    ...focusStyle(theme).border,
  },
  ':hover': {
    backgroundColor: !disabled && theme.palette.blue[50],
    borderColor: !disabled && theme.palette.blue[50],
  },
}))
