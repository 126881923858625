import { FREE_VARIANTS_CONTAINER_ID } from '@app/constants'
import { useDroppable } from '@dnd-kit/core'
import { Box, styled } from '@mui/material'
import { FC } from 'react'

interface VariantsContainerComponentProps {
  children?: JSX.Element | JSX.Element[]
}

export const VariantsContainerComponent: FC<VariantsContainerComponentProps> = ({ children }) => {
  const { setNodeRef } = useDroppable({ id: FREE_VARIANTS_CONTAINER_ID })
  return (
    <StyledVariantsContainer
      ref={setNodeRef}
      data-variants={FREE_VARIANTS_CONTAINER_ID}
      aria-label='Variants container'
      role='list'
    >
      {children}
    </StyledVariantsContainer>
  )
}

const StyledVariantsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `2px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  alignItems: 'center',
  marginBottom: theme.spacing(6),

  '& > div': {
    margin: 0,
    '&:not(:last-child)': {
      margin: 0,
    },
  },

  [theme.breakpoints.down('mobile')]: {
    padding: theme.spacing(1),
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(1),
    marginBottom: theme.spacing(5.5),
  },
}))
