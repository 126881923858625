import { forwardRef } from 'react'
import { DomNodes } from '@app/constants'
import { PackagedImage, PackagedImageProps } from '@app/components/common/PackagedImage'

export interface ObjectComponentProps extends PackagedImageProps {
  itemId: string
  resourceId: string
  componentNode: DomNodes
  type: string
  width: string
  height: string
  data: string
  children?: JSX.Element | JSX.Element[]
  className?: string
  accessibilityAttr?: { [key: string]: string }
}

export const ObjectComponent = forwardRef<any, ObjectComponentProps>((props, ref) => {
  const { type, data, children, resourceId, className, ...rest } = props

  if (type.includes('image')) {
    return (
      <PackagedImage
        ref={ref}
        src={data}
        alt={String(children)}
        className={className}
        resourceId={resourceId}
        allowZoom={false}
        {...rest}
      />
    )
  }

  return (
    <div ref={ref} data-src={data} className={className}>
      {children}
    </div>
  )
})
