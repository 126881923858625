import dayjs from 'dayjs'
import dayjsDurationPlugin from 'dayjs/plugin/duration'

dayjs.extend(dayjsDurationPlugin)

export const formatDuration = (duration: number): string => {
  return (
    dayjs
      .duration(duration, 's')
      .format('m [minutes] s [seconds]')
      // "dayjs" cannot format conditionally, so we need to cut out "0" values.
      .replace(/\b0 (minutes|seconds)/g, '')
      .trim()
  )
}
