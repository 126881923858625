import React, { useState } from 'react'
import useId from '@mui/material/utils/useId'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH'
import { faPennant } from '@fortawesome/pro-solid-svg-icons/faPennant'
import { faHighlighterLine } from '@fortawesome/pro-solid-svg-icons/faHighlighterLine'
import { faArrowLeftRotate } from '@fortawesome/pro-solid-svg-icons/faArrowLeftRotate'
import { DropdownMenu } from '@app/components/ui/DropdownMenu'
import { useSnapshot } from 'valtio'
import { CurrentTestState } from '@app/storage'

type Props = {
  hasHighlights?: boolean
  markedItems?: string[]
  checkHighlightedElement: () => boolean
  onMarkForReview?: () => void
  onStickyNotes?: () => void
  onHighlightSelection?: () => void
  onStrikethrough?: () => void
  onResetHighlight?: () => void
  onRemoveHighlight: () => void
}

const QuestionToolsMenu: React.FC<Props> = ({
  hasHighlights = false,
  markedItems,
  onMarkForReview,
  // onStickyNotes,
  onHighlightSelection,
  // onStrikethrough,
  onResetHighlight,
  checkHighlightedElement,
  onRemoveHighlight,
}) => {
  const id = useId()

  const {
    currentItemResponse: { itemId },
  } = useSnapshot(CurrentTestState)

  const [selectedText, setSelectedText] = useState('')
  const [isHighlightedElement, setIsHighlightedElement] = useState(false)

  const onMenuOpen = () => {
    const selection = window.getSelection().toString()
    if (!selection) {
      selectedText && setSelectedText('')
      return
    }

    setIsHighlightedElement(checkHighlightedElement)
    setSelectedText(selection)
  }

  const options = [
    {
      label: `${markedItems.includes(itemId) ? 'Unmark' : 'Mark'} for Review`,
      icon: <FontAwesomeIcon icon={faPennant} />,
      action: onMarkForReview,
    },
    // {
    //   label: "Sticky Notes",
    //   icon: <FontAwesomeIcon icon={faNoteSticky} />,
    //   action: onStickyNotes
    // },
    ...(selectedText
      ? [
          {
            label: `${isHighlightedElement ? 'Unhighlight' : 'Highlight'} Selection`,
            icon: <FontAwesomeIcon icon={faHighlighterLine} />,
            action: isHighlightedElement ? onRemoveHighlight : onHighlightSelection,
          },
        ]
      : []),
    // {
    //   label: "Strikethrough",
    //   icon: <FontAwesomeIcon icon={faStrikethrough} />,
    //   action: onStrikethrough
    // }
  ]

  if (hasHighlights) {
    options.push({
      label: 'Reset Highlight',
      icon: <FontAwesomeIcon icon={faArrowLeftRotate} />,
      action: onResetHighlight,
    })
  }

  return (
    <DropdownMenu
      id={id}
      onClick={onMenuOpen}
      anchor={
        <IconButton size='small'>
          <FontAwesomeIcon icon={faEllipsisH} />
        </IconButton>
      }
      options={options}
    />
  )
}

export default QuestionToolsMenu
