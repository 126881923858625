import React from 'react'
import { CurrentTestState } from '@app/storage'
import { Box, Typography, styled, Chip } from '@mui/material'
import { useSnapshot } from 'valtio'
import { NUMBER_OF_QUESTIONS_ATTRIBUTES } from '@app/constants'
import QuestionToolsMenu from './QuestionToolsMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPennant } from '@fortawesome/pro-solid-svg-icons/faPennant'
import { useContext } from 'react'
import { QuestionContextMenuContext } from '@app/components/common'

type Props = {
  reviewMode?: boolean
  hasHighlights?: boolean
  checkHighlightedElement?: () => boolean
  onHighlightSelection?: () => void
  onResetHighlight?: () => void
  onRemoveHighlight?: () => void
}

const InteractionLayoutHeader: React.FC<Props> = ({
  reviewMode = false,
  hasHighlights = false,
  checkHighlightedElement,
  onHighlightSelection,
  onResetHighlight,
  onRemoveHighlight,
}) => {
  const { currentItemResponse, itemsResponses } = useSnapshot(CurrentTestState)
  const currentItemIndex = itemsResponses.findIndex(item => item.id === currentItemResponse.id)

  const { markedItems, setMarkForReview } = useContext(QuestionContextMenuContext)

  return (
    <HeaderWrapper reviewMode={reviewMode}>
      <HeaderTitle {...NUMBER_OF_QUESTIONS_ATTRIBUTES} component='h2' variant='h5'>
        Question {currentItemIndex + 1} of {itemsResponses.length}
      </HeaderTitle>

      {!reviewMode && markedItems.includes(currentItemResponse.itemId) && (
        <Box ml={2}>
          <StyledReviewChip size='small' icon={<FontAwesomeIcon icon={faPennant} />} label='Review' />
        </Box>
      )}

      {!reviewMode && (
        <Box ml='auto'>
          <QuestionToolsMenu
            hasHighlights={hasHighlights}
            checkHighlightedElement={checkHighlightedElement}
            onHighlightSelection={onHighlightSelection}
            onResetHighlight={onResetHighlight}
            onRemoveHighlight={onRemoveHighlight}
            onMarkForReview={setMarkForReview}
            markedItems={markedItems}
          />
        </Box>
      )}
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled(Box)<{ reviewMode?: boolean }>(({ theme, reviewMode = false }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(reviewMode ? 4.5 : 3, 4),
  borderBottom: `1px solid ${theme.palette.grey[100]}`,

  [theme.breakpoints.down('mobile')]: {
    padding: theme.spacing(2, 3),
  },
}))

const HeaderTitle = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.grey[800]};
`,
) as typeof Typography

const StyledReviewChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.purple[50],
  color: theme.palette.purple['A700'],
  padding: theme.spacing(1, 2),

  '& .MuiChip-icon': {
    fontSize: '0.6875rem',
    color: theme.palette.purple['A700'],
  },
}))

export default InteractionLayoutHeader
