import { createContext } from 'react'

type HotTextScoring = {
  correctResponses: string[]
  groupMode?: boolean
  // groupStatistics?: Record<string, number>
}

export const HotTextScoringContext = createContext<HotTextScoring>({
  correctResponses: [],
  groupMode: false,
  // groupStatistics: {},
})
