import { useMemo } from 'react'
import { useSnapshot } from 'valtio'
import sum from 'lodash/sum'
import { ScoringState } from '@app/storage'
import {
  FeedbackPercentages,
  PercentagesByCorrect,
  PercentagesByInteraction,
  PracticeTestAssessmentKeyItem,
  ResponseByPracticeTestPointValue,
  ResponseByPracticeTestResponseValue,
  ResponseByPracticeTestSeqResponseValue,
  ResponseByPracticeTestValue,
  ResponseByPracticeTestValueType,
  ResponsesByPracticeTestItemResponseDeclaration,
} from '@app/types'
import { getCountResponsePercentage, getSequenceResponsePercentage } from './itemResponsesScoring'

export const getResponsePercentage = (
  response: ResponseByPracticeTestValue,
  responseType: ResponseByPracticeTestValueType,
  correctAnswerResponse: any,
  totalStudentsCount: number,
) => {
  if (responseType === ResponseByPracticeTestValueType.POINT_VALUE) {
    const isCorrect = (response as ResponseByPracticeTestPointValue).points === correctAnswerResponse.possible
    const percentage = getCountResponsePercentage(
      response as ResponseByPracticeTestPointValue,
      totalStudentsCount,
    )

    return { isCorrect, percentage }
  }

  if (responseType === ResponseByPracticeTestValueType.SEQ_RESPONSE_VALUE) {
    const isCorrect = correctAnswerResponse.response.includes(
      (response as ResponseByPracticeTestSeqResponseValue).response,
    )
    const percentage = getSequenceResponsePercentage(
      response as ResponseByPracticeTestSeqResponseValue,
      totalStudentsCount,
    )

    return { isCorrect, percentage }
  }

  if (responseType === ResponseByPracticeTestValueType.RESPONSE_VALUE) {
    const isCorrect = correctAnswerResponse.response.includes(
      (response as ResponseByPracticeTestResponseValue).response,
    )
    const percentage = getCountResponsePercentage(
      response as ResponseByPracticeTestResponseValue,
      totalStudentsCount,
    )

    return { isCorrect, percentage }
  }
}

export const getPercentagesByResponseDeclaration = (
  responseDeclaration: ResponsesByPracticeTestItemResponseDeclaration,
  correctAnswerItem: PracticeTestAssessmentKeyItem,
  totalStudentsCount: number,
) => {
  const correctAnswerResponse = correctAnswerItem.responses.find(
    response => response.responseDeclarationId === responseDeclaration.responseDeclarationId,
  )

  return responseDeclaration.responses.reduce<PercentagesByInteraction>(
    (acc, response) => {
      const responsePercentage = getResponsePercentage(
        response as ResponseByPracticeTestValue,
        responseDeclaration.responseType,
        correctAnswerResponse,
        totalStudentsCount,
      )

      if (!responsePercentage) return acc

      if (responsePercentage.isCorrect) {
        return {
          ...acc,
          correct: [...acc.correct, responsePercentage.percentage],
        }
      }

      return {
        ...acc,
        incorrect: [...acc.incorrect, responsePercentage.percentage],
      }
    },
    { correct: [], incorrect: [] },
  )
}

export const getFeedbackPercentages = (
  percentagesByCorrect: PercentagesByInteraction | PercentagesByCorrect,
): FeedbackPercentages => {
  const [correct, incorrect] = [percentagesByCorrect.correct, percentagesByCorrect.incorrect].map(
    percentages => (percentages.length ? sum(percentages.flat()) / percentages.length : 0),
  )
  const noAnswer = 1 - correct - incorrect

  return { correct, incorrect, noAnswer }
}

export const useItemResponsePercentages = (itemId: string) => {
  const { groupTestScoring, groupCorrectAnswers } = useSnapshot(ScoringState)

  const responsePercentages = useMemo(() => {
    if (!groupTestScoring || !groupCorrectAnswers) {
      return null
    }

    const scoringItem = groupTestScoring.items.find(item => item.itemId === itemId)
    const correctAnswerItem = groupCorrectAnswers.find(answerItem => answerItem.itemId === itemId)

    if (!correctAnswerItem) {
      return
    }

    if (!scoringItem) {
      // If there is no answer on current question, display statistic feedback
      return { correct: 0, incorrect: 0, noAnswer: 1 }
    }

    const percentagesByInteraction = scoringItem.responseDeclarations.map(responseDeclaration =>
      getPercentagesByResponseDeclaration(
        responseDeclaration as ResponsesByPracticeTestItemResponseDeclaration,
        correctAnswerItem as PracticeTestAssessmentKeyItem,
        groupTestScoring.studentCount,
      ),
    )

    const percentagesByCorrect = percentagesByInteraction.reduce<PercentagesByCorrect>(
      (acc, interactionPercentages) => ({
        correct: [...acc.correct, interactionPercentages.correct],
        incorrect: [...acc.incorrect, interactionPercentages.incorrect],
      }),
      { correct: [], incorrect: [] },
    )

    return getFeedbackPercentages(percentagesByCorrect)
  }, [groupCorrectAnswers, groupTestScoring, itemId])

  return responsePercentages
}

export const useResponsePercentages = (itemId: string, responseDeclarationId: string) => {
  const { groupTestScoring, groupCorrectAnswers } = useSnapshot(ScoringState)

  const responsePercentages = useMemo(() => {
    if (!groupTestScoring || !groupCorrectAnswers) {
      return null
    }

    const scoringResponseDeclaration = groupTestScoring.items
      .find(item => item.itemId === itemId)
      ?.responseDeclarations?.find(
        responseDeclaration => responseDeclaration.responseDeclarationId === responseDeclarationId,
      )
    const correctAnswerItem = groupCorrectAnswers.find(answerItem => answerItem.itemId === itemId)

    if (!correctAnswerItem) {
      return
    }

    // If there is no answer on current question, display statistic feedback
    if (!scoringResponseDeclaration) {
      return { correct: 0, incorrect: 0, noAnswer: 1 }
    }

    const percentagesByCorrect = getPercentagesByResponseDeclaration(
      scoringResponseDeclaration as ResponsesByPracticeTestItemResponseDeclaration,
      correctAnswerItem as PracticeTestAssessmentKeyItem,
      groupTestScoring.studentCount,
    )

    return getFeedbackPercentages(percentagesByCorrect)
  }, [groupCorrectAnswers, groupTestScoring, itemId, responseDeclarationId])

  return responsePercentages
}
