import { FC, SVGProps } from 'react'
import { DEFAULT_MARKER_HEIGHT, DEFAULT_MARKER_WIDTH } from '../constants'

export interface MarkerProps extends SVGProps<SVGMarkerElement> {
  id: string
  width?: number
  height?: number
  children: JSX.Element | JSX.Element[]
}

export const Marker: FC<MarkerProps> = props => {
  const { id, width = DEFAULT_MARKER_WIDTH, height = DEFAULT_MARKER_HEIGHT, children, ...rest } = props
  const refX = width / 2
  const refY = height / 2

  return (
    <marker
      id={id}
      markerWidth={width}
      markerHeight={height}
      refX={refX}
      refY={refY}
      orient='auto'
      markerUnits='strokeWidth'
      vectorEffect='non-scaling-stroke'
      {...rest}
    >
      {children}
    </marker>
  )
}
