import { Announcements, DndContextProps } from '@dnd-kit/core'
import { DraggableData, DraggableElement } from '../types'

const UNKNOWN_POSITION_LABEL = 'unknown position'

export const getPointLabel = (data: DraggableData | DOMPoint): string => {
  if (!data) return UNKNOWN_POSITION_LABEL

  if (data instanceof DOMPoint) return `(${data.x}, ${data.y})`

  return `(${data._x}, ${data._y})`
}

export const getAsymptoteLabel = (data: DraggableData): string => {
  if (!data) return UNKNOWN_POSITION_LABEL

  return `(${data._y.toString()})`
}

export const getDraggablePosition = (data: DraggableData): string => {
  if (!data) return UNKNOWN_POSITION_LABEL

  if (data.type === DraggableElement.POINT) {
    return getPointLabel(data)
  }

  if (data.type === DraggableElement.ASYMPTOTE) {
    return getAsymptoteLabel(data)
  }

  return UNKNOWN_POSITION_LABEL
}

export const accessibility: DndContextProps['accessibility'] = {
  screenReaderInstructions: {
    draggable: `To pick up a point or asymptote, press 'Space' or 'Enter'. Use arrow keys to move the point or asymptote over the coordinate plane. To drop the point or asymptote, press 'Space' or 'Enter' again. To cancel dragging, press 'Escape'.`,
  },
  // Disable default announcements.
  announcements: {
    onDragStart: () => '',
    onDragMove: () => '',
    onDragOver: () => '',
    onDragEnd: () => '',
    onDragCancel: () => '',
  },
}

export const announcements: Partial<Announcements> = {
  onDragStart: ({ active }) => {
    return `Picked up ${active.data.current?.type} at ${getDraggablePosition(
      active.data.current as DraggableData,
    )}.`
  },
  onDragEnd: ({ active }): any => {
    return `Dropped ${active.data.current?.type} at ${getDraggablePosition(
      active.data.current as DraggableData,
    )}.`
  },
  onDragCancel: ({ active }) => {
    return `Dragging was cancelled. ${active.data.current?.type} returned at ${getDraggablePosition(
      active.data.current as DraggableData,
    )}.`
  },
}
