import { getElements } from '../../GraphingInteraction/helpers'
import { MIN, MAX, INTERVAL } from '../constants'
import { NumberLineProps, AxisProps, IntervalTypeProps, IntervalType, IntervalDirection } from '../types'

export const parseChildrenToProps = (
  children: JSX.Element | JSX.Element[],
): Omit<NumberLineProps, 'width' | 'height'> => {
  const [axisElement] = getElements(children, 'axis')
  const axis: AxisProps = {
    min: axisElement?.props?.min ? parseInt(axisElement.props.min, 10) : MIN,
    max: axisElement?.props?.max ? parseInt(axisElement.props.max, 10) : MAX,
    increment: axisElement?.props?.increment ? parseFloat(axisElement.props.increment) : INTERVAL,
  }

  const intervalsElements = getElements(children, 'intervaltype')
  const intervalTypes: IntervalTypeProps[] = intervalsElements.map(intervalElement => ({
    type: intervalElement.props.type as IntervalType,
    direction: intervalElement.props.direction as IntervalDirection,
  }))

  return { axis, intervalTypes }
}
