import { PracticeTestAssessmentKeyItem, Program, RefScope, ResponsesByPracticeTest } from '@app/types'
import { executeQuerySideload } from '../graphql/apolloClient'
import { GET_ANSWER_KEY_ITEMS_BY_PRACTICE_TEST, GET_RESPONSES_BY_PRACTICE_TEST } from './groupReviewQueries'

export async function getResponsesByPracticeTest(
  program: Program,
  subject: string,
  refScope: RefScope,
  testSubjectContentId: string,
): Promise<ResponsesByPracticeTest> {
  const response = await executeQuerySideload(GET_RESPONSES_BY_PRACTICE_TEST, {
    program,
    subject,
    refScope,
    testSubjectContentId,
  })
  return response.getResponsesByPracticeTest
}

export async function getAnswerKeyItemsByPracticeTest(
  program: Program,
  subject: string,
  testSubjectContentId: string,
): Promise<PracticeTestAssessmentKeyItem[]> {
  const response = await executeQuerySideload(GET_ANSWER_KEY_ITEMS_BY_PRACTICE_TEST, {
    program,
    subject,
    testSubjectContentId,
  })
  return response.getAnswerKeyItemsByPracticeTest
}
