import {
  getResponseSingleItem,
  useAssessmentItemResponseScoring,
  useItemResponse,
  useResponseDeclaration,
} from '@app/helpers'
import { CurrentTestState, setItemResponse } from '@app/storage'
import { FC, useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { useUndoRedoHistoryInput } from './hooks'
import { InteractionType } from '@app/types'
import { EquationEditor } from './EquationEditor'

interface EquationEditorComponentProps {
  itemId: string
  responseidentifier: string
  children?: JSX.Element | JSX.Element[]
}

export const EquationEditorComponent: FC<EquationEditorComponentProps> = ({
  itemId,
  children,
  responseidentifier: responseIdentifier,
}) => {
  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)
  const itemResponse = useItemResponse(currentItemResponse?.id, responseIdentifier)
  const scoring = useAssessmentItemResponseScoring(currentItemResponse?.itemId, responseIdentifier)

  const {
    undo,
    redo,
    currentState: latex,
    setCurrentState: setLatex,
    resetState,
  } = useUndoRedoHistoryInput(getResponseSingleItem(itemResponse), 15)
  const [id, setId] = useState('')

  const responseDeclaration = useResponseDeclaration(id, responseIdentifier)

  useEffect(() => {
    if (!currentItemResponse?.id) {
      return
    }

    setId(CurrentTestState.currentItemResponse.id)
  }, [currentItemResponse?.id, currentItemResponse])

  useEffect(() => {
    if (!currentItemResponse?.id || !responseDeclaration) return
    if (currentTestPart?.isFinished) return

    setItemResponse(
      currentItemResponse.id,
      responseIdentifier,
      [latex],
      true,
      InteractionType.equationEditor,
      responseDeclaration.cardinality,
    )
  }, [latex, responseIdentifier, currentItemResponse?.id, currentTestPart?.isFinished, responseDeclaration])

  useEffect(() => {
    if (currentItemResponse?.id) {
      resetState(getResponseSingleItem(itemResponse))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemResponse?.id])

  return (
    <EquationEditor
      key={itemId}
      value={scoring.groupMode ? scoring.correctResponse?.[0] ?? '' : latex}
      onChange={setLatex}
      onUndo={undo}
      onRedo={redo}
      disabled={currentTestPart?.isFinished}
    >
      {children}
    </EquationEditor>
  )
}
