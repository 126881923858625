import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useUpdatingRef } from '@app/helpers'
import { IntervalType, IntervalDirection, Interval, UseIntervals } from '../types'
import { UniqueDOMPoint } from '../../GraphingInteraction/types'
import { getIntervalLabel, getPointLabel } from '../helpers'

export const useIntervals = (disabled?: boolean): UseIntervals => {
  const [intervals, setIntervals] = useState<Interval[]>([])
  const [current, setCurrent] = useState<string>('')
  const [announcement, setAnnouncement] = useState<string>('')

  const intervalsRef = useUpdatingRef(intervals)

  useEffect(() => {
    setCurrent(intervalsRef.current[intervals.length - 1]?.id || '')
  }, [intervals.length, intervalsRef])

  const onAddInterval = (type: IntervalType, direction: IntervalDirection) => {
    if (disabled) return
    setIntervals(current => {
      const point = new UniqueDOMPoint(0, 0)
      const newInterval = {
        type,
        direction,
        id: uuid(),
        point,
      }
      setAnnouncement(
        `Added new ${getIntervalLabel(type, direction)} interval with origin at ${getPointLabel(point)}.`,
      )
      return [...current, newInterval]
    })
  }

  const onRemoveInterval = (id: string) => {
    if (disabled) return
    setIntervals(current => {
      const intervalToRemove = current.find(interval => interval.id === id)

      if (!intervalToRemove) {
        return current
      }

      setAnnouncement(
        `Removed ${getIntervalLabel(
          intervalToRemove.type,
          intervalToRemove.direction,
        )} interval with origin at ${getPointLabel(intervalToRemove.point)}.`,
      )

      return current.filter(interval => interval.id !== id)
    })
  }

  const onSelectInterval = (id: string) => {
    if (disabled) return
    setCurrent(id)
  }

  const onMovePoint = (newPosition: DOMPoint) => {
    if (disabled) return

    setIntervals(intervals => {
      const currentIndex = intervals.findIndex(interval => interval.id === current)
      intervals[currentIndex].point.x = newPosition.x
      intervals[currentIndex].point.y = newPosition.y
      return [...intervals]
    })
  }

  return {
    intervals,
    current,
    onAddInterval,
    onRemoveInterval,
    onSelectInterval,
    onMovePoint,
    setIntervals,
    setCurrent,
    announcement,
  }
}
