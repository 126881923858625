import { FC, useCallback, useMemo, useState } from 'react'
import { Announcements, DndMonitorListener, useDndMonitor } from '@dnd-kit/core'
import { useUniqueId } from '@dnd-kit/utilities'
import { ScreenReaderInfo } from '@app/components'

export interface DndCustomMonitorProps {
  announcements: Partial<Announcements>
}

export const DndCustomMonitor: FC<DndCustomMonitorProps> = ({ announcements }) => {
  const [monitorInfo, setMonitorInfo] = useState('')

  const setMonitorInfoAsync = useCallback((event: any, callback: any) => {
    if (!callback) return

    setTimeout(() => {
      const value = callback(event)
      if (value) setMonitorInfo(value)
    })
  }, [])

  const listeners = useMemo<DndMonitorListener>(() => {
    const listeners: DndMonitorListener = {
      onDragStart: event => setMonitorInfoAsync(event, announcements?.onDragStart),
      onDragCancel: event => setMonitorInfoAsync(event, announcements?.onDragCancel),
      onDragEnd: event => setMonitorInfoAsync(event, announcements?.onDragEnd),
    }

    return listeners
  }, [announcements, setMonitorInfoAsync])

  useDndMonitor(listeners)
  const id = useUniqueId('Dnd-CustomMonitor')

  return (
    <ScreenReaderInfo id={id} ariaAtomic ariaLive='assertive' role='status'>
      {monitorInfo}
    </ScreenReaderInfo>
  )
}
