import { Box, badgeClasses, styled } from '@mui/material'
import { GapImgProps } from './types'
import { ReviewBadge } from '@app/components/ui/badges'
import { focusStyle } from '@app/theme'

const STYLED_PROPS = [
  'invalid',
  'disabled',
  'selected',
  'dragging',
  'overlay',
  'placed',
  'placedOverlay',
  'correct',
]

export const StyledGapImg = styled(Box, {
  shouldForwardProp: prop => !STYLED_PROPS.includes(prop as string),
})<GapImgProps>(({ theme, invalid, disabled, dragging, selected, overlay, placed, placedOverlay }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.blue[400]}`,
  borderRadius: `${theme.shape.borderRadius * 1.5}px`,
  boxShadow: theme.shadows[2],
  overflow: 'hidden',
  position: 'relative',
  cursor: 'pointer',

  ...(!overlay
    ? {
        '&:hover': {
          [theme.breakpoints.up('tablet')]: {
            backgroundColor: theme.palette.blue[50],

            '& img': {
              opacity: 0.8,
            },
          },
        },
      }
    : { cursor: 'grab' }),

  '&:focus-visible': {
    ...focusStyle(theme).border,

    '&::before': {
      content: '',
      width: '100%',
      height: '100%',
      display: 'block',
      position: 'absolute',
      borderRadius: `${theme.shape.borderRadius}px`,
      border: `1px solid ${theme.palette.grey[50]}`,
    },
  },

  ...(invalid && { borderColor: theme.palette.error.main }),

  ...((disabled || dragging) && {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[50]}`,
    boxShadow: 'none',
  }),

  ...(disabled && { opacity: 0.5, pointerEvents: 'none' }),
  ...(dragging && { opacity: 0.7, pointerEvents: 'none' }),

  ...((overlay || (selected && !placed)) && {
    backgroundColor: theme.palette.blue[100],
    boxShadow: selected ? theme.shadows[2] : theme.shadows[6],
    borderWidth: '2px',
  }),

  ...(placed && {
    borderColor: theme.palette.blue[600],
    boxShadow: 'none',
    '&:hover': {
      [theme.breakpoints.up('tablet')]: {
        backgroundColor: theme.palette.blue[500],
      },
    },
    '&:focus': {
      [theme.breakpoints.up('tablet')]: {
        '&::before': {
          border: 'none',
        },
      },
    },
  }),

  ...(((placed && selected) || placedOverlay) && {
    border: `2px solid ${theme.palette.blue[200]}`,
    boxShadow: placedOverlay ? theme.shadows[6] : theme.shadows[2],
  }),
}))

export const StyledReviewBadge = styled(ReviewBadge)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',

  [`& .${badgeClasses.badge}`]: {
    zIndex: 1,
  },
}))
