import { FC } from 'react'
import { useSnapshot } from 'valtio'
import { DomNodes } from '@app/constants'
import { CurrentTestState } from '@app/storage'
import {
  useSelectableChoice,
  transformChoicesConstraints,
  useFindNodes,
  useAssessmentItemResponseScoring,
  // getElementFeedbackItems,
} from '@app/helpers'
import { ConstraintsAlertContainer, HotspotArea } from '@app/components'
import { InteractionType } from '@app/types'

interface HotspotInteractionProps {
  itemId: string
  responseidentifier: string
  minchoices?: string
  maxchoices?: string
  children: JSX.Element
  className?: string
  accessibilityAttr?: { [key: string]: string }
}

export const HotspotInteraction: FC<HotspotInteractionProps> = props => {
  const { children, className, minchoices, maxchoices, responseidentifier, accessibilityAttr } = props
  const [minChoices, maxChoices] = transformChoicesConstraints(minchoices, maxchoices)

  const [[prompt], [image], hotspotChoices] = useFindNodes(children, [
    DomNodes.prompt,
    DomNodes.object,
    DomNodes.hotspotChoice,
  ])

  const { selected, onSelect: onToggle } = useSelectableChoice(responseidentifier, InteractionType.hotspot, {
    minChoices,
    maxChoices,
  })
  const { currentItemResponse } = useSnapshot(CurrentTestState)
  const { scoring, groupMode, correctResponse } = useAssessmentItemResponseScoring(
    currentItemResponse?.itemId,
    responseidentifier,
  )

  const maxSelected = maxChoices > 1 && selected.length === maxChoices

  return (
    <div className={className} {...accessibilityAttr}>
      {prompt}
      <ConstraintsAlertContainer
        minChoices={minChoices}
        maxChoices={maxChoices}
        selectedCount={selected.length}
      />
      <HotspotArea image={image}>
        {hotspotChoices.map(hotspotChoice => {
          const isSelected = selected.includes(hotspotChoice.props.identifier)
          // const feedbackItems = groupMode
          //   ? getElementFeedbackItems(hotspotChoice, groupStatistics, correctResponse)
          //   : undefined

          return (
            <hotspotChoice.type
              key={hotspotChoice.props.identifier}
              {...hotspotChoice.props}
              selected={groupMode ? correctResponse?.includes(hotspotChoice.props.identifier) : isSelected}
              disabled={!isSelected && maxSelected}
              correct={scoring?.response?.includes(hotspotChoice.props.identifier)}
              groupMode={groupMode}
              // feedbackItems={feedbackItems}
              onToggle={() => onToggle(hotspotChoice.props.identifier)}
              role='checkbox'
            />
          )
        })}
      </HotspotArea>
    </div>
  )
}
