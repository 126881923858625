import { useDroppable } from '@dnd-kit/core'
import { Box, styled } from '@mui/material'
import { FC } from 'react'
import { GAP_ATTRIBUTES, TEST_ATTRIBUTE_ID, TEST_ELEMENT_IDS } from '@app/constants'
import { generateAttrWithValue } from '@app/helpers'

interface DropContainerComponentProps {
  id: string
  disabled?: boolean
  assigned?: boolean
  children?: JSX.Element | JSX.Element[]
  containerIndex?: number
}

export const DropContainerComponent: FC<DropContainerComponentProps> = ({
  id,
  disabled,
  children,
  containerIndex,
  ...props
}) => {
  const { setNodeRef, isOver } = useDroppable({ id, disabled })
  const testAttributes =
    containerIndex || containerIndex === 0
      ? generateAttrWithValue(TEST_ATTRIBUTE_ID, TEST_ELEMENT_IDS.gapArea, containerIndex)
      : GAP_ATTRIBUTES

  return (
    <StyledDropContainer
      {...testAttributes}
      component='span'
      ref={setNodeRef}
      isOver={isOver}
      disabled={disabled}
      {...props}
      data-gap={id}
    >
      {!isOver && children}
    </StyledDropContainer>
  )
}

export interface StyledDropContainerProps {
  disabled?: boolean
  isOver?: boolean
  assigned?: boolean
}

export const StyledDropContainer = styled(Box)<StyledDropContainerProps>(
  ({ theme, disabled, isOver, assigned }) => ({
    display: 'inline-block',
    verticalAlign: 'middle',
    border: assigned && !isOver ? 'none' : `2px dashed`,
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    minWidth: assigned ? undefined : '150px',
    minHeight: '38px',
    backgroundClip: 'padding-box',
    color: disabled ? theme.palette.grey[400] : theme.palette.grey[800],
    borderColor: disabled ? theme.palette.grey[100] : theme.palette.additionalColors.bg_500,
    backgroundColor:
      (assigned && isOver) || isOver
        ? theme.palette.blue[50]
        : disabled
        ? theme.palette.grey[50]
        : assigned
        ? 'transparent'
        : 'transparent',

    '& > span': {
      width: '100%',
      height: '100%',
    },

    [theme.breakpoints.down('mobile')]: {
      minHeight: '36px',
    },
  }),
)
