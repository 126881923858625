import React from 'react'

interface InlineChoiceProps {
  itemId: string
  identifier: string
}

export const InlineChoiceComponent: React.FC<React.PropsWithChildren<InlineChoiceProps>> = ({ children }) => {
  return <>{children}</>
}
