import { InputComponent } from '@app/components/ui'
import {
  checkValidityString,
  getResponseSingleItem,
  useAssessmentItemResponseScoring,
  useDebouncing,
  useItemResponse,
  useResponseDeclaration,
  useStateReset,
} from '@app/helpers'
import { CurrentTestState, setItemResponse } from '@app/storage'
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useSnapshot } from 'valtio'
import { TEXT_INPUT_ATTRIBUTES } from '@app/constants'
import { InputComponentProps } from '@app/components/ui/inputs/InputComponent'
import { InteractionType } from '@app/types'

export type TextEntryProps = InputComponentProps & { labelId?: string }
export const TextEntry: FC<TextEntryProps> = props => (
  <label id={props.labelId} aria-label='Input field'>
    <InputComponent inline {...props} />
  </label>
)

export interface TextEntryInteractionProps {
  itemId: string
  responseidentifier?: string
  expectedlength?: number
  base?: number
  stringidentifier?: string
  patternmask?: string
  placeholdertext?: string
  format?: string
  accessibilityAttr?: { [key: string]: string }
  disabled?: boolean
}

export const TextEntryInteraction: FC<TextEntryInteractionProps> = props => {
  const {
    responseidentifier: responseIdentifier,
    placeholdertext: placeholder,
    expectedlength: expectedLength,
    patternmask: patternMask,
    accessibilityAttr,
    disabled,
  } = props
  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)

  const itemResponse = useItemResponse(currentItemResponse?.id, responseIdentifier)
  const { scoring, groupMode, groupScoring, correctResponse } = useAssessmentItemResponseScoring(
    currentItemResponse?.itemId,
    responseIdentifier,
  )
  const isReviewMode = !!scoring || !!groupScoring

  const [value, setValue] = useState(getResponseSingleItem(itemResponse))
  const [id, setId] = useState('')
  const debouncedValue = useDebouncing(value)

  const responseDeclaration = useResponseDeclaration(id, responseIdentifier)

  useEffect(() => {
    if (!currentItemResponse?.id) {
      return
    }

    setId(CurrentTestState.currentItemResponse.id)
  }, [currentItemResponse?.id, currentItemResponse])

  const isValid = useMemo(() => checkValidityString(patternMask, value), [value, patternMask])

  useEffect(() => {
    if (!currentItemResponse?.id || !responseDeclaration) return
    if (currentTestPart?.isFinished) return

    setItemResponse(
      currentItemResponse.id,
      responseIdentifier,
      debouncedValue.trim() ? [debouncedValue] : [],
      isValid,
      InteractionType.textEntry,
      responseDeclaration.cardinality,
    )
  }, [
    debouncedValue,
    currentItemResponse?.id,
    currentTestPart?.isFinished,
    responseIdentifier,
    isValid,
    responseDeclaration,
  ])

  useStateReset(currentItemResponse?.id, responseIdentifier, setValue, getResponseSingleItem)

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (currentTestPart.isFinished) {
      return
    }

    setValue(event.target.value)
  }

  return (
    <>
      {currentItemResponse && (
        <TextEntry
          value={isReviewMode && groupMode ? correctResponse?.[0] : value}
          onChange={handleChangeValue}
          aria-labelledby={responseIdentifier}
          placeholder={placeholder}
          expectedLength={expectedLength}
          labelId={responseIdentifier}
          disabled={disabled || isReviewMode}
          {...TEXT_INPUT_ATTRIBUTES}
          {...accessibilityAttr}
        />
      )}
    </>
  )
}
