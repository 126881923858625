import { useEffect, useState } from 'react'
import { debounce } from 'lodash'
/**
 * Debounces any property that you pass in it to the defined delay.
 * @param prop Desired property
 * @param dealy Delay for debounce effect. Defaults to 300ms.
 * @param initialValue initialValue for property
 * @returns Debounced on change property.
 */

export const useDebouncedProperty = (property: any, delay = 300, initialValue: any = null) => {
  const [debouncedProperty, setDebouncedProperty] = useState<any>(initialValue)

  const updateDebouncedProp = debounce((input: string) => {
    setDebouncedProperty(input)
  }, delay)

  useEffect(() => {
    updateDebouncedProp(property)
    return () => updateDebouncedProp.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property])

  return [debouncedProperty]
}
