import { FC } from 'react'
import { styled } from '@mui/material'
import { onKeyboardSubmit } from '@app/helpers'
import { DroppableHotspot, DroppableHotspotProps } from '../DroppableHotspot'
import { OrderItem } from '../GraphicOrderInteraction/components'

export interface OrderedHotspotChoiceProps extends DroppableHotspotProps {}

export const OrderedHotspotChoice: FC<OrderedHotspotChoiceProps> = props => {
  const { id, selected, onToggle, children, ...rest } = props

  const listeners = selected
    ? {}
    : {
        onClick: onToggle,
        onKeyDown: onKeyboardSubmit(onToggle),
      }

  return (
    <StyledOrderedHotspot
      key={id}
      id={id}
      selected={selected}
      wrapperProps={listeners}
      focusable={!selected}
      {...rest}
    >
      {children}
    </StyledOrderedHotspot>
  )
}

const StyledOrderedHotspot = styled(DroppableHotspot)(({ theme }) => ({
  ':hover': {
    [`${OrderItem}`]: {
      backgroundColor: theme.palette.blue[500],
    },
  },
}))
