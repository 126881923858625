import { StorageKeys } from "@app/constants"

export const onKeyboardSubmit = (onSubmit?: (event: any) => void) => (event: any) => {
  if (event.code === 'Space' || event.code === 'Enter') {
    event.preventDefault()
    onSubmit && onSubmit(event)
  }
}

export const combine =
  (...fns: Function[]) =>
  (value: any) =>
    fns.forEach(fn => fn && fn(value))

export const combineListeners = (
  listeners: Record<string, Function>,
  props: Record<string, any>,
): Record<string, any> => {
  let combinedListeners: Record<string, any> = {}

  for (const listener in listeners) {
    if (Object.prototype.hasOwnProperty.call(listeners, listener)) {
      combinedListeners[listener] = combine(listeners[listener], props[listener])
    }
  }

  return combinedListeners
}

export const resetCalculatorData = () => sessionStorage.setItem(StorageKeys.Calculator, null)