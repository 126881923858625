import { FC, useMemo, useRef, useState } from 'react'
import {
  ClickAwayListener,
  PopperProps,
  styled,
  tooltipClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { StyledButtonTooltip, StyledButton, StyledTooltip } from '@app/components/ui'
import { getSVGScaleTransform, useSVGScale } from '@app/helpers'
import { AssociationLine, AssociationLineProps, StyledLine, StyledMarker } from './AssociationLine'
import { ForeignObjectPopper } from './ForeignObjectPopper'
import { usePopperOriginPoint } from './hooks'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface RemovableAssociationLineProps extends AssociationLineProps {
  id: string
  removable?: boolean
  onRemove?: () => void
}

const POPPER_PROPS: Partial<PopperProps> = {
  disablePortal: true,
  keepMounted: true,
  anchorEl: {
    getBoundingClientRect: () => new DOMRect(0, 0, 0, 0),
  },
  modifiers: [
    {
      name: 'computeStyles',
      enabled: true,
      fn: ({ state }) => state,
    },
  ],
}

export const RemovableAssociationLine: FC<RemovableAssociationLineProps> = ({
  id,
  jointSize = 5,
  removable,
  onRemove,
  ...props
}) => {
  const sourceRef = useRef<SVGGElement>()
  const buttonRef = useRef<HTMLButtonElement>()

  const [open, setOpen] = useState<boolean>(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const popperPoint = usePopperOriginPoint(sourceRef, buttonRef)

  const svgScale = useSVGScale(sourceRef)
  const transform = getSVGScaleTransform(svgScale)
  const buttonStyle = useMemo(() => ({ transform }), [transform])
  const components = useMemo(
    () => ({
      Popper: ForeignObjectPopper,
    }),
    [],
  )
  const componentsProps = useMemo(
    () => ({
      popper: { ...popperPoint, style: buttonStyle, onOpen } as Partial<PopperProps>,
    }),
    [popperPoint, buttonStyle],
  )

  const theme = useTheme()
  const isTouchDevice = useMediaQuery(`(max-width: ${theme.breakpoints.values.mobile}px), (hover: none)`)

  if (removable) {
    const tooltipTouchProps = isTouchDevice ? { open, onClose } : undefined
    const lineTouchProps = isTouchDevice ? { onClick: onOpen } : undefined

    const lineGroupElement = (
      <StyledLineGroup id={id}>
        <AssociationLineTooltip
          {...tooltipTouchProps}
          placement='right-start'
          PopperProps={POPPER_PROPS}
          components={components}
          componentsProps={componentsProps}
          disableTouchListener
          disableHoverListener={isTouchDevice}
          title={
            <StyledButtonTooltip placement='bottom' title='Remove'>
              <AssociationLineButton
                ref={buttonRef}
                disableRipple
                color='primary'
                aria-label={`Remove "${id}" association`}
                onClick={onRemove}
              >
                <TimesCircleIcon icon={faTimesCircle} />
              </AssociationLineButton>
            </StyledButtonTooltip>
          }
        >
          <AssociationLine ref={sourceRef} {...lineTouchProps} jointSize={jointSize} {...props} />
        </AssociationLineTooltip>
      </StyledLineGroup>
    )

    if (isTouchDevice) {
      return <ClickAwayListener onClickAway={onClose}>{lineGroupElement}</ClickAwayListener>
    }

    return lineGroupElement
  }

  return <AssociationLine ref={sourceRef} jointSize={jointSize} {...props} />
}

const StyledLineGroup = styled('g')(({ theme }) => ({
  ':hover, :focus-visible, :focus-within': {
    outline: 'none',

    [`${StyledLine}`]: {
      stroke: theme.palette.blue[700],
    },
    [`${StyledMarker}`]: {
      fill: theme.palette.blue[700],
    },
  },
}))

export const AssociationLineButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.blue[50],
}))

export const TimesCircleIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}))

export const AssociationLineTooltip = styled(StyledTooltip)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex !important',
  justifyContent: 'center',
  alignItems: 'center',

  [`.${tooltipClasses.tooltip}`]: {
    visibility: 'visible !important',
  },
}))
