import { AlertProps, AlertTitle } from '@mui/material'
import { FC } from 'react'
import { ALERT_INFO_ATTRIBUTES, ALERT_SUCCESS_ATTRIBUTES } from '@app/constants'
import {
  StyledAlert,
  StyledFontAwesome,
} from './components'
import { getIcon } from './helpers'

export interface AlertContainerComponentProps extends AlertProps {
  title?: string
  size?: 'sm'|'md'
}

export const AlertContainerComponent: FC<AlertContainerComponentProps> = props => {
  const { title, severity = 'success', size = 'md', children, icon, ...rest } = props

  return (
    <StyledAlert
      severity={severity}
      size={size}
      icon={icon ?? <StyledFontAwesome icon={getIcon(severity)} />}
      {...(severity === 'success' ? ALERT_SUCCESS_ATTRIBUTES : ALERT_INFO_ATTRIBUTES)}
      role={'presentation'}
      {...rest}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </StyledAlert>
  )
}
