import { FC, useMemo } from 'react'
import { DragOverlay } from '@dnd-kit/core'
import { Box, styled } from '@mui/material'
import { useSnapshot } from 'valtio'
import { CurrentTestState } from '@app/storage'
import { transformChoicesConstraints, useFindNodes, useResponseValidityUpdate } from '@app/helpers'
import { DomNodes } from '@app/constants'
import { DraggableObject, PositionObject } from '../PositionObjectStage/components'
import { useRelativeWidth } from '../PositionObjectStage/hooks'
import { ARIA_LABELS, DRAGGABLE_ARRTIBUTES, DRAGGABLE_ID } from '../PositionObjectStage/constants'

export interface PositionObjectInteractionProps {
  itemId: string
  responseidentifier?: string
  className?: string
  children: JSX.Element | JSX.Element[]
  pointsCount?: number
  minchoices?: string
  maxchoices?: string
  isDragging?: boolean
  imageRef?: React.MutableRefObject<HTMLImageElement>
  accessibilityAttr?: { [key: string]: string }
}

export const PositionObjectInteraction: FC<PositionObjectInteractionProps> = props => {
  const {
    imageRef,
    isDragging,
    itemId,
    children,
    className,
    pointsCount,
    minchoices,
    maxchoices,
    accessibilityAttr,
  } = props

  const [[positionObject]] = useFindNodes(children, [DomNodes.object])
  const { currentItemResponse } = useSnapshot(CurrentTestState)

  const [minChoices, maxChoices] = transformChoicesConstraints(minchoices, maxchoices)
  const isValid = useResponseValidityUpdate(currentItemResponse?.id, pointsCount, minChoices, maxChoices)
  const maxSelected = maxChoices && pointsCount === maxChoices

  // Proportionally djust size of objects according to image.
  const relativeObjectWidth = useRelativeWidth(
    imageRef,
    imageRef.current ? Number(imageRef.current?.getAttribute('width')) : 0,
    Number(positionObject.props.width),
  )
  const styleProps = useMemo(
    () => ({
      fullWidth: false,
      style: {
        width: relativeObjectWidth.toFixed(2) + 'px',
        height: 'auto',
      },
    }),
    [relativeObjectWidth],
  )

  return (
    <div aria-label={ARIA_LABELS.INTERACTION} {...accessibilityAttr}>
      <DragOverlay dropAnimation={null}>
        {isDragging && (
          <PositionObject itemId={itemId} isDragging {...positionObject.props} {...styleProps} />
        )}
      </DragOverlay>

      <FlexRow className={className}>
        <RowWrapper>
          {isValid && !maxSelected ? (
            <DraggableObject itemId={itemId} id={DRAGGABLE_ID} {...positionObject.props} {...styleProps} />
          ) : (
            <PositionObject
              itemId={itemId}
              disabled
              {...positionObject.props}
              {...styleProps}
              accessibilityAttr={DRAGGABLE_ARRTIBUTES}
            />
          )}
        </RowWrapper>
      </FlexRow>
    </div>
  )
}

const FlexRow = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}))

const RowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: theme.spacing(-1),

  [`& ${PositionObject}`]: {
    margin: theme.spacing(),
  },
}))
