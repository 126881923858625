import { AssessmentStimulus } from "@app/models";
import { BuilderOptions } from "xml2js";
import { XMLTools } from "..";
import { parseStyleSheet } from "./genericParsers";

/**
 * Stimulus parser.
 * @param { Object } data stimulus raw data.
 * @returns { Array<AssessmentStimulus> } parsed stimulus data.
 */
export const parseAssessmentStimulus = (data: any): AssessmentStimulus[] => {
  const stimulus = data.assessmentStimulus
  const builderOptions: BuilderOptions = {
    headless: true,
    rootName: 'div'
  }

  if (Array.isArray(stimulus)) {
    return data.map((el: any) => ({
      id: el.attributes.identifier,
      ...(el.attributes.title && { title: el.attributes.title }),
      ...(el.attributes.label && { label: el.attributes.label }),
      ...(el.attributes.language && { language: el.attributes.language }),
      ...(el.stimulusBody && { stimulusBody: XMLTools.build(el.stimulusBody, builderOptions) }),
      ...(el.stylesheet && { stylesheet: parseStyleSheet(el.stylesheet) })
    }))
  }

  return [{
    id: stimulus.attributes.identifier,
    ...(stimulus.attributes.title && { title: stimulus.attributes.title }),
    ...(stimulus.attributes.label && { label: stimulus.attributes.label }),
    ...(stimulus.attributes.language && { language: stimulus.attributes.language }),
    ...(stimulus.stimulusBody && { stimulusBody: XMLTools.build(stimulus.stimulusBody, builderOptions) }),
    ...(stimulus.stylesheet && { stylesheet: parseStyleSheet(stimulus.stylesheet) })
  }]
}