export const WIDTH = 400
export const HEIGHT = 400
export const POINT_SIZE = 6
export const MIN_POINTS = 0
export const MAX_POINTS = 2

export const AXIS_STYLE_PLACEHOLDER = {
  // Need to specify some mock styles for grid to render it.
  grid: {
    stroke: 'black',
    strokeWidth: 1,
  },
}

export const STATIC_DATA_STYLE = {
  points: {
    data: { fill: 'black' },
  },
  line: {
    data: { stroke: 'black' },
  },
}

export const POINT_DRAG_DETECT_DISTANCE = 8
export const POINT_DRAG_OUT_THRESHOLD = 0.65

export const COORDINATES_SEPARATOR = ','
export const POINTS_SEPARATOR = ' '
export const ITEMS_SEPARATOR = ';'

export const DEFAULT_MARKER_WIDTH = 10
export const DEFAULT_MARKER_HEIGHT = 7
