import { useCallback, useEffect, useState } from 'react'
import { ExperienceRating, ExperienceRatingWeights } from '@app/components/common/ExperienceRatingScale/types'
import { FeedbackPrompt, SaveFeedbackPrompt } from '@app/types'
import { saveFeedbackResponse } from '@app/services/feedback'
import { useDebouncedProperty } from './debouncedProperty'

export const useFeedbackSubmit = (feedbackPrompt: FeedbackPrompt) => {
  const [selectedRate, setSelectedRate] = useState<ExperienceRating>(null)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [debouncedSelectedRate] = useDebouncedProperty(selectedRate, 500)

  const onChangeFeedbackRating = (value: ExperienceRating) => setSelectedRate(value)

  const resetFeedbackRating = () => setSelectedRate(null)

  const submitFeedback = useCallback(
    async (experienceRate: ExperienceRating) => {
      if (!feedbackPrompt?.responseId) {
        console.warn('[useFeedbackSubmit]: responseId is undefined')
        resetFeedbackRating()
        return
      }

      const feedbackResponseData: SaveFeedbackPrompt = {
        promptResponse: String(ExperienceRatingWeights[experienceRate]) || '',
        responseId: feedbackPrompt?.responseId,
      }

      try {
        await saveFeedbackResponse(feedbackResponseData)
      } catch (error) {
        console.warn('[useFeedbackSubmit]: api call error - ', error)

        resetFeedbackRating()
      }

      setSubmitted(true)
    },
    [feedbackPrompt?.responseId],
  )

  useEffect(() => {
    if (!debouncedSelectedRate) return

    submitFeedback(debouncedSelectedRate as ExperienceRating)
  }, [debouncedSelectedRate, submitFeedback])

  return { selectedRate, onChangeFeedbackRating, submitted }
}
