import { useMemo, useCallback } from 'react'
import { useSnapshot } from 'valtio'
import { useTestPartFinishedCheck, useResponseUpdate, useStateReset } from '@app/helpers'
import { CurrentTestState } from '@app/storage'
import {
  getSelectedAreaPoints,
  getInitialGraphs,
  getAreaFromPoints,
  transformStateToResponse,
  transformResponseToState,
  mergedGraphsStates,
} from '../helpers'
import { GraphProps, GraphingState, GraphType } from '../types'
import { UseGraphs, useGraphs } from './graphs'
import { SelectableAreaState, UseSelectableAreas, useSelectableAreas } from './selectableAreas'
import { InteractionType } from '@app/types'

export type UseGraphing = {
  graphs: UseGraphs
  selectableAreas: UseSelectableAreas
  disabled: boolean
}

export const useGraphing = (
  responseId: string,
  width: number,
  height: number,
  graphsData: Partial<GraphProps>[],
  solutionSetEnabled: boolean,
): UseGraphing => {
  const graphsCount = graphsData.length

  const { currentItemResponse } = useSnapshot(CurrentTestState)
  const isFinished = useTestPartFinishedCheck()

  const graphs = useGraphs(graphsData, solutionSetEnabled, isFinished)
  const selectableAreas = useSelectableAreas(graphs.graphs, width, height, isFinished || !solutionSetEnabled)

  const selected: GraphingState = useMemo(() => {
    return {
      graphType: graphsCount ? undefined : graphs.activeGraphType,
      asymptote: graphs.asymptote,
      graphs: graphs.graphs,
      solutionAreas: selectableAreas.areas
        .filter(area => area.selected)
        .map(area => getSelectedAreaPoints(area.d)),
      solutionSetEnabled,
    }
  }, [
    graphsCount,
    graphs.activeGraphType,
    graphs.asymptote,
    graphs.graphs,
    selectableAreas.areas,
    solutionSetEnabled,
  ])

  const setState = useCallback(
    (state: GraphingState) => {
      // If graphs count > 1 or solution set mode is present, none should be selected by default.
      graphs.setActiveGraph(graphsCount > 1 || solutionSetEnabled ? null : 0)
      graphs.setActiveGraphType(graphsCount ? state.graphType ?? GraphType.LINEAR : state.graphType)
      // Merge initial graphs and response data.
      graphs.setGraphs(mergedGraphsStates(getInitialGraphs(graphsData, state.graphType), state.graphs))
      graphs.setAsymptote(state.asymptote ?? 0)
      graphs.setSolutionSetMode(false)

      const selectedAreas: SelectableAreaState[] = state.solutionAreas
        ? state.solutionAreas.map(points => ({
            d: getAreaFromPoints(points),
            selected: true,
          }))
        : []
      selectableAreas.setAreas(selectedAreas)
    },
    // Don't need to watch contexts.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphsCount],
  )

  useResponseUpdate(
    currentItemResponse?.id,
    responseId,
    selected,
    InteractionType.graphing,
    transformStateToResponse,
  )
  useStateReset(currentItemResponse?.id, responseId, setState, transformResponseToState)

  return { graphs, selectableAreas, disabled: isFinished }
}
