import { FC, useMemo } from 'react'
import { Box, styled } from '@mui/material'
import { DragContainerComponent } from '@app/components/ui/containers/DragContainer'
import { DropContainerComponent } from '@app/components/ui/containers/DropContainer'
import { addUniqPartId } from '@app/helpers'
import { ShowHide } from '@app/types'
import { useGapContextValue } from '../GapMatchInteraction'

interface GapComponentProps {
  identifier: string
  templateidentifier: string
  showhide: ShowHide
  matchgroup: string
  required: string
  fluid?: boolean
}

export const GapComponent: FC<GapComponentProps> = ({ identifier, fluid = false, ...props }) => {
  const {
    fluidGaps,
    containers,
    scoredContainers,
    variants,
    isFinished,
    isMaxMatches,
    handleRemove,
    clickMovingState: { activeGapBlockId, activeGapId },
    containersArray,
    variantsArray,
    groupMode,
  } = useGapContextValue()

  const dropVariant = useMemo(
    () =>
      containers &&
      variants &&
      variants.find(
        variant =>
          variant.props.identifier ===
          (groupMode ? scoredContainers[identifier]?.correctAnswer : containers[identifier]),
      ),
    [containers, variants, identifier, groupMode, scoredContainers],
  )

  const containerIndex = useMemo(
    () => containersArray.length && containersArray.indexOf(identifier),
    [containersArray, identifier],
  )

  const variantIndex = useMemo(
    () => variantsArray.length && dropVariant && variantsArray.indexOf(dropVariant.props.identifier),
    [dropVariant, variantsArray],
  )

  const correctAnswer = useMemo(
    () =>
      variants.find(variant => variant.props.identifier === scoredContainers[identifier]?.correctAnswer)
        ?.props?.children,
    [variants, scoredContainers, identifier],
  )

  return (
    <StyledGap data-gap={identifier}>
      <StyledDropContainer
        id={identifier}
        containerIndex={containerIndex}
        assigned={!!dropVariant}
        disabled={isFinished || (isMaxMatches && !!!dropVariant)}
      />
      {dropVariant && (
        <DragContainerComponent
          id={addUniqPartId(dropVariant.props.identifier)}
          containerId={identifier}
          assigned={!!dropVariant}
          disabled={isFinished}
          handleRemove={handleRemove(dropVariant.props.identifier, identifier)}
          selected={activeGapBlockId === dropVariant.props.identifier && activeGapId === identifier}
          variantIndex={variantIndex}
          fluid={fluidGaps}
          correct={scoredContainers[identifier]?.correct}
          correctAnswer={correctAnswer}
          groupMode={groupMode}
        >
          {dropVariant}
        </DragContainerComponent>
      )}
    </StyledGap>
  )
}

const StyledGap = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  verticalAlign: 'middle',
  margin: theme.spacing(1),
}))

const StyledDropContainer = styled(DropContainerComponent)(({ assigned }) => ({
  ...(assigned && {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
  }),
}))
