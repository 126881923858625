import { FC, memo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSnapshot } from 'valtio'
import { Loader, RubricBlockModal, StartTestModal } from '@app/components/ui'
import { UserAssessment } from '@app/firebase/api'
import {
  useAnsweredItems,
  useRubricModal,
  useTestCallback,
  useUserAssessentPauseCallback,
  useUserAssessmentStart,
} from '@app/helpers'
import { TestPart, View } from '@app/models'
import { CurrentTestState, CurrentUserAssessmentState, HeaderTitleState } from '@app/storage'
import TestPartComponent from '../testPart/TestPart'

export interface AssessmentComponentProps {
  userAssessment: UserAssessment
}

export const AssessmentComponent: FC<AssessmentComponentProps> = memo(({ userAssessment }) => {
  const { currentTest, itemsResponses, rubricBlock, isStarted } = useSnapshot(CurrentTestState)
  const { status, assessmentSettings, passedTime } = useSnapshot(CurrentUserAssessmentState)
  const { title } = useSnapshot(HeaderTitleState)
  const { open: rubricModalOpened, onClose, onStart } = useRubricModal()
  const pauseUserAssessment = useUserAssessentPauseCallback()
  const handleTestCallback = useTestCallback()
  const answeredItems = useAnsweredItems()
  const loading = useUserAssessmentStart(userAssessment)
  const [searchParams] = useSearchParams()

  const skipDirections = searchParams.get('skipDirections') === 'true'
  const hasRubric = rubricBlock && rubricBlock.view === View.CANDIDATE

  const onBack = () => {
    pauseUserAssessment()
    handleTestCallback()
  }

  return (
    <>
      {loading ? <Loader /> : <TestPartComponent testPart={currentTest.testPart[0] as TestPart} />}
      {currentTest && hasRubric && !skipDirections && !isStarted && (
        <RubricBlockModal
          open={rubricModalOpened}
          onClose={onClose}
          onStart={onStart}
          onCancel={onBack}
          title={title}
          content={rubricBlock.content}
          status={status}
          questionsAnswered={answeredItems.length}
          questionsTotal={itemsResponses.length}
        />
      )}
      {currentTest && (!hasRubric || skipDirections) && !isStarted && (
        <StartTestModal
          open={rubricModalOpened}
          onClose={onBack}
          onSubmit={onStart}
          title={title}
          itemsCount={itemsResponses.length}
          duration={assessmentSettings.timeLimits - passedTime}
          status={status}
        />
      )}
    </>
  )
})
