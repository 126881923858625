import { Color, Theme } from '@mui/material'
import { COORDINATES_SEPARATOR, POINTS_SEPARATOR, ITEMS_SEPARATOR } from '../constants'
import {
  GraphState,
  LineStyle,
  GraphType,
  GraphingState,
  ChartDomain,
  GraphData,
  UniqueDOMPoint,
} from '../types'

export const omitSideTicks = (tick: number, i: number, arr: number[]): number =>
  i === 0 || i === arr.length - 1 ? null : tick

export const omitOutOfRangeData = (data: GraphData[], domain: ChartDomain): any[] => {
  const isAscendingFn = data[0]._y < data[1]._y

  const outOfLeftYDomainIndex = data.findLastIndex((datum: any) =>
    isAscendingFn ? datum._y < domain.y[0] : datum._y > domain.y[1],
  )
  const outOfRightYDomainIndex = data.findIndex((datum: any) =>
    isAscendingFn ? datum._y > domain.y[1] : datum._y < domain.y[0],
  )

  return data.slice(
    outOfLeftYDomainIndex >= 0 ? outOfLeftYDomainIndex : 0,
    outOfRightYDomainIndex >= 0 ? outOfRightYDomainIndex + 1 : undefined,
  )
}

export const getGraphColor = (theme: Theme, hue: keyof Color, colorIndex: number = 0): string => {
  if (!theme) return 'black'

  const colors = [
    theme.palette.teal[hue],
    theme.palette.indigo[hue],
    theme.palette.blue[hue],
    theme.palette.purple[hue],
    theme.palette.orange[hue],
    theme.palette.green[hue],
    theme.palette.grey[hue],
  ]

  return colors.at(colorIndex % colors.length)
}

export const joinPoints = (points: DOMPoint[]): string =>
  points.length
    ? points.map(point => [point.x, point.y].join(COORDINATES_SEPARATOR)).join(POINTS_SEPARATOR)
    : ''

export const splitPoints = (pointsData: string): DOMPoint[] =>
  pointsData.split(POINTS_SEPARATOR).map(pointData => {
    const [x, y] = pointData.split(COORDINATES_SEPARATOR)
    return new DOMPoint(parseInt(x), parseInt(y))
  })

export const transformGraphsToResponse = (graphs: GraphState[], includeLineStyle?: boolean): string => {
  return graphs
    .map(graph => {
      const graphsResponse = [graph.id, joinPoints(graph.points)]

      // if "solution set" mode enabled, need to keep line style.
      if (includeLineStyle) {
        graphsResponse.push(graph.lineStyle)
      }

      return graphsResponse.filter(responseItem => responseItem).join(POINTS_SEPARATOR)
    })
    .filter(item => item)
    .join(ITEMS_SEPARATOR)
}

export const transformAreasToResponse = (areas: DOMPoint[][]): string =>
  areas.map(area => joinPoints(area)).join(ITEMS_SEPARATOR)

export const transformStateToResponse = (
  state: Partial<GraphingState & { solutionSetEnabled: boolean }>,
): string[] => {
  const { graphType, graphs = [], asymptote, solutionSetEnabled, solutionAreas = [] } = state

  return (
    [
      graphs.length > 1 ? undefined : graphType,
      transformGraphsToResponse(graphs, solutionSetEnabled),
      graphType === GraphType.EXPONENTIAL ? asymptote.toString() : undefined,
      transformAreasToResponse(solutionAreas),
    ]
      // remove empty items
      .filter(item => item)
  )
}

export const transformResponseToGraphs = (value: string): GraphState[] => {
  if (!value) return

  return value.split(ITEMS_SEPARATOR).map<GraphState>(graphData => {
    const rawGraphData = graphData.split(POINTS_SEPARATOR)

    let id: string
    // check whether first value is point or ID
    if (!rawGraphData[0].includes(COORDINATES_SEPARATOR)) {
      id = rawGraphData.shift()
    }

    const pointsData = [...rawGraphData]
    const isLineStyle = Object.values(LineStyle).includes(pointsData[pointsData.length - 1] as LineStyle)
    const lineStyle = isLineStyle ? pointsData.pop() : undefined

    return {
      id,
      isValid: true,
      points: pointsData
        .map(pointData => {
          if (!pointData) return null
          const [x, y] = pointData.split(COORDINATES_SEPARATOR)
          return new UniqueDOMPoint(parseInt(x), parseInt(y))
        })
        .filter(item => item),
      lineStyle: lineStyle as LineStyle,
    }
  })
}

export const transformResponseToAreas = (value: string): DOMPoint[][] =>
  value ? value.split(ITEMS_SEPARATOR).map(areaData => splitPoints(areaData)) : undefined

export const transformResponseToState = (response: string[]): GraphingState => {
  let graphType: GraphType
  let graphsData: string
  let asymptoteData: string
  let solutionAreasData: string

  const isGraphType = Object.values(GraphType).includes(response[0] as GraphType)

  if (isGraphType) {
    // it's "pick" mode (with or without asymptote).
    graphType = response[0] as GraphType
    graphsData = response[1]
    asymptoteData = response[2]
  } else {
    // it's some build mode (with or without solution areas).
    graphsData = response[0]
    solutionAreasData = response[1]
  }

  const asymptote = asymptoteData ? parseInt(asymptoteData) : undefined
  const graphs: GraphState[] = transformResponseToGraphs(graphsData)
  const solutionAreas: DOMPoint[][] = transformResponseToAreas(solutionAreasData)

  return {
    graphType,
    asymptote,
    graphs,
    solutionAreas,
  }
}
