import { DragEndEvent, Modifier } from '@dnd-kit/core'
import { Coordinates, getEventCoordinates } from '@dnd-kit/utilities'

export const getScrollOffset = (element: Element, direction: 'top' | 'bottom' = 'bottom'): Coordinates => {
  if (!element) {
    return { x: 0, y: 0 }
  }

  if (direction === 'top') {
    return {
      x: element.scrollLeft,
      y: element.scrollTop,
    }
  }

  return {
    x: element.scrollWidth - element.clientWidth - element.scrollLeft,
    y: element.scrollHeight - element.clientHeight - element.scrollTop,
  }
}

export const getScrollParent = (node: HTMLElement): HTMLElement | null => {
  if (!node) {
    return null
  }

  if (node.scrollHeight > node.clientHeight || node.scrollWidth > node.clientWidth) {
    return node
  } else {
    return getScrollParent(node.parentElement)
  }
}

export const snapCenterToCursor =
  (callback: (x: number, y: number) => void): Modifier =>
  ({ activatorEvent, draggingNodeRect, transform }) => {
    if (draggingNodeRect && activatorEvent) {
      const activatorCoordinates = getEventCoordinates(activatorEvent)

      if (!activatorCoordinates) {
        return transform
      }

      const offsetX = activatorCoordinates.x - draggingNodeRect.left - draggingNodeRect.width / 2
      const offsetY = activatorCoordinates.y - draggingNodeRect.top - draggingNodeRect.height / 2
      callback && callback(offsetX, offsetY)

      return { ...transform, x: transform.x + offsetX, y: transform.y + offsetY }
    }

    return transform
  }

export const getDropPosition = (
  containerRef: React.MutableRefObject<HTMLElement>,
  event: DragEndEvent,
  initialScrollOffset?: Coordinates,
  initialCursorOffset?: Coordinates,
): DOMPoint => {
  const domEvent = event.activatorEvent as MouseEvent

  if (!domEvent.clientX || !domEvent.clientY) {
    // Drop via keyboard.
    const dragImgRect = (domEvent.target as HTMLElement).getBoundingClientRect()
    // Drop point is element center + delta offset
    const domPoint = new DOMPoint(
      dragImgRect.x + dragImgRect.width / 2 + event.delta.x,
      dragImgRect.y + dragImgRect.height / 2 + event.delta.y,
    )

    return domPoint
  }

  // Find first scrollable parent to calculate scroll offset.
  const scrollable = getScrollParent(containerRef.current)
  // Drop point is client point + delta offset + scroll bottom offset
  const { x: scrollXOffset, y: scrollYOffset } = getScrollOffset(scrollable, 'bottom')
  const { x: imageScrollXOffset, y: imageScrollYOffset } = getScrollOffset(
    containerRef.current?.firstElementChild,
    'top',
  )
  const domPoint = new DOMPoint(
    domEvent.clientX +
      event.delta.x +
      scrollXOffset -
      imageScrollXOffset -
      (initialScrollOffset?.x || 0) -
      (initialCursorOffset?.x || 0),
    domEvent.clientY +
      event.delta.y +
      scrollYOffset -
      imageScrollYOffset -
      (initialScrollOffset?.y || 0) -
      (initialCursorOffset?.y || 0),
  )

  return domPoint
}
