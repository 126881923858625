import { FC } from 'react'
import { Box, ButtonBase, styled } from '@mui/material'
import { IntervalIcon } from './IntervalIcon'
import { IntervalDirection, IntervalType, IntervalTypeProps } from '../types'
import { getIntervalLabel } from '../helpers'
import { focusStyle } from '@app/theme'

export interface IntervalTypeSelectProps {
  options: IntervalTypeProps[]
  onSelect: (type: IntervalType, direction: IntervalDirection) => void
  disabled?: boolean
  className?: string
}

export const IntervalTypeSelect: FC<IntervalTypeSelectProps> = props => {
  const { options, onSelect, disabled, className } = props

  return (
    <StyledSelectWrapper className={className} role='group' aria-label='Add interval'>
      {options.map(option => (
        <IntervalTypeItem
          key={`${option.type} ${option.direction}`}
          type={option.type}
          direction={option.direction}
          disabled={disabled}
          onSelect={onSelect}
        />
      ))}
    </StyledSelectWrapper>
  )
}

export interface IntervalTypeItemProps extends IntervalTypeProps {
  disabled?: boolean
  onSelect: (type: IntervalType, direction: IntervalDirection) => void
}

export const IntervalTypeItem: FC<IntervalTypeItemProps> = props => {
  const { type, direction, onSelect, disabled } = props

  return (
    <StyledItem
      className={`${type} ${direction}`}
      onClick={() => onSelect(type, direction)}
      disabled={disabled}
      aria-label={getIntervalLabel(type, direction)}
    >
      <IntervalIcon type={type} direction={direction} />
    </StyledItem>
  )
}

const StyledSelectWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr',
  gap: theme.spacing(2),
}))

const StyledItem = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3.5, 4.5),
  borderRadius: theme.shape.borderRadius * 1.5,
  border: `1px solid ${theme.palette.blue[400]}`,

  ':disabled': {
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.grey[50],
  },

  // Use hover for mobile-up and hover devices.
  [`${theme.breakpoints.up('mobile')} and (hover: hover)`]: {
    ':hover': {
      backgroundColor: theme.palette.blue[50],
    },
  },

  ':focus': {
    ...focusStyle(theme).border,
  },

  [theme.breakpoints.up('mobile')]: {
    padding: theme.spacing(3.5, 5.5),
  },
}))
