import { doc, getDoc } from "firebase/firestore"
import { db } from "@app/firebase"
import { ScoringByItem } from "@app/models/scoringByItem"
import { FirestoreCollections } from "../types"

export const getUserAssessmentScoring = async (userAssessmentId: string) => {
  try {
    const docRef = doc(db, FirestoreCollections.scoring, userAssessmentId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists) {
      return docSnap.data() as ScoringByItem
    }

    return null
  } catch (e) {
    console.error('Cannot get firestore data: ', e)
  }
}
