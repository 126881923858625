import { FC } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  DialogTitleProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material'
import { AssessmentStatus } from '@app/firebase/api'
import { scrollBarMixinObj } from '@app/constants'
import { AlertContainerComponent } from '@app/components/ui/containers/AlertContainer'
import { ContentRenderer, PackagedImage } from '@app/components'

export interface RubricBlockModalProps {
  open?: boolean
  onClose?: () => void
  onStart?: () => void
  onCancel?: () => void
  title?: string
  content?: string
  status?: AssessmentStatus
  questionsAnswered?: number | string
  questionsTotal?: number | string
}

export const RubricBlockModal: FC<RubricBlockModalProps> = props => {
  const {
    open,
    onClose,
    onStart,
    onCancel,
    title,
    content,
    status = AssessmentStatus.new,
    questionsAnswered = 0,
    questionsTotal = 0,
  } = props

  const handleClose = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason) {
      return
    }

    onClose && onClose()
  }

  return (
    <StyledDialog open={open} onClose={handleClose} scroll='paper'>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <StyledContent>
        <ContentRenderer content={content} />
        {status !== AssessmentStatus.completed && status !== AssessmentStatus.new && (
          <StyledAlert severity='info'>
            You started this test but have not completed it. You have answered {questionsAnswered} of{' '}
            {questionsTotal} questions.
          </StyledAlert>
        )}
      </StyledContent>
      <StyledActions>
        <StyledButton color='secondary' variant='contained' onClick={onStart}>
          {status === AssessmentStatus.new ? 'Begin' : 'Continue'}
        </StyledButton>
        <StyledButton color='grey' variant='text' size='small' onClick={onCancel}>
          Cancel
        </StyledButton>
      </StyledActions>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`.${dialogClasses.container}`]: {
    [`& > .${dialogClasses.paper}`]: {
      borderRadius: theme.shape.borderRadius * 1.5,
      width: '100%',
      padding: theme.spacing(12, 4, 8),
      overflow: 'hidden',

      [theme.breakpoints.down('tablet')]: {
        margin: theme.spacing(0),
      },
    },
  },
}))

const TitleTypography: FC<TypographyProps> = props => <Typography variant='h1' {...props} />
const DialogTitleTypography: FC<DialogTitleProps> = props => (
  <DialogTitle component={TitleTypography} {...props} />
)
const StyledDialogTitle = styled(DialogTitleTypography)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  padding: 0,
  textAlign: 'center',
}))

const StyledContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0, 4),
  ...scrollBarMixinObj(theme, 'vertical'),

  [`${PackagedImage}`]: {
    width: 'auto',
    maxWidth: '100%',
  },
}))

const StyledActions = styled(DialogActions)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(12),
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing(4),
}))

const StyledButton = styled(Button)(() => ({
  textTransform: 'none',
  margin: 0,

  ':not(:first-of-type)': { margin: 0 },
}))

const StyledAlert = styled(AlertContainerComponent)(() => ({
  marginBottom: 0,
}))
