import { useCallback, useMemo, useState } from 'react'
import { useSnapshot } from 'valtio'
import { DragEndEvent } from '@dnd-kit/core'
import { Coordinates } from '@dnd-kit/utilities'
import { CurrentTestState } from '@app/storage'
import { InteractionType, SelectableChoiceConstraints } from '@app/types'
import { useSelectableChoice } from '@app/helpers'
import { useDragDrop } from '../PositionObjectStage/hooks'
import { getDropPosition } from '../PositionObjectStage/utils'

export const POINT_SEPARATOR = ' '

export interface SelectPoint {
  id: string
  coords: DOMPoint
}

export type UseSelectPoint = {
  points: string[]
  onSetPoint: (pointId: string) => void
  onRemovePoint: (pointId: string) => () => void
  announcement: string
}

export const useSelectPoints = (
  responseIdentifier?: string,
  constraints: SelectableChoiceConstraints = {},
): UseSelectPoint => {
  const { minChoices, maxChoices } = constraints

  const { currentTestPart } = useSnapshot(CurrentTestState)

  const { selected: points, onSelect } = useSelectableChoice(
    responseIdentifier,
    InteractionType.selectPoint,
    { minChoices, maxChoices },
    false,
  )

  const [announcement, setAnnouncement] = useState<string>('')

  const onSetPoint = (id: string) => {
    if (currentTestPart?.isFinished) {
      return
    }

    setAnnouncement('Point selected.')
    onSelect(id)
  }

  const onRemovePoint = (pointId: string) => () => {
    if (currentTestPart?.isFinished) {
      return
    }

    setAnnouncement('Point removed.')
    onSelect(pointId)
  }

  return { points, onSetPoint, onRemovePoint, announcement }
}

export const useDropPoint = (
  containerRef: React.MutableRefObject<HTMLElement>,
  svgRef: React.MutableRefObject<SVGSVGElement>,
  droppableId: string,
  onSetPoint: (id: string) => void,
) => {
  const { currentTestPart } = useSnapshot(CurrentTestState)

  const onDropPoint = useCallback(
    (event: DragEndEvent, initialScrollOffset?: Coordinates, initialCursorOffset?: Coordinates) => {
      if (currentTestPart?.isFinished) {
        return
      }

      if (event.over?.id !== droppableId) {
        return
      }

      const domPoint = getDropPosition(containerRef, event, initialScrollOffset, initialCursorOffset)
      const svgPoint = domPoint.matrixTransform(svgRef.current?.getScreenCTM().inverse())
      const point = [svgPoint.x.toFixed(0), svgPoint.y.toFixed(0)].join(POINT_SEPARATOR)

      onSetPoint(point)
    },
    [currentTestPart?.isFinished, droppableId, containerRef, svgRef, onSetPoint],
  )

  const dragDrop = useDragDrop(containerRef, onDropPoint)

  return dragDrop
}

export const DEFAULT_POINT_SIZE = 16

export const usePointSize = (responseIdentifier: string) => {
  const { currentItemResponse, testPartItems } = useSnapshot(CurrentTestState)

  const pointSize = useMemo(() => {
    if (!currentItemResponse) return DEFAULT_POINT_SIZE
    const currentItem = testPartItems.find(item => item.id === currentItemResponse.itemId)
    const responseDeclaration = currentItem.responseDeclaration.find(decl => decl.id === responseIdentifier)
    const coords = responseDeclaration?.areaMapping ? responseDeclaration.areaMapping[0]?.coords : ''
    return coords ? coords.split(',').pop() : DEFAULT_POINT_SIZE
  }, [currentItemResponse, testPartItems, responseIdentifier])

  return pointSize
}
