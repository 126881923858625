import { Announcements, DndContextProps } from '@dnd-kit/core'
import { DraggableIntervalData } from '../types'
import { getIntervalLabel, getPointLabel } from './formatters'

export const accessibility: DndContextProps['accessibility'] = {
  screenReaderInstructions: {
    draggable: `To pick up a point, press 'Space' or 'Enter'. Use arrow keys to move the point over the coordinate ray. To drop the point, press 'Space' or 'Enter' again. To cancel dragging, press 'Escape'.`,
  },
  // Disable default announcements.
  announcements: {
    onDragStart: () => '',
    onDragMove: () => '',
    onDragOver: () => '',
    onDragEnd: () => '',
    onDragCancel: () => '',
  },
}

export const announcements: Partial<Announcements> = {
  onDragStart: ({ active }) => {
    const pointData = active.data.current as DraggableIntervalData
    return `Picked up point of ${getIntervalLabel(
      pointData.interval.type,
      pointData.interval.direction,
    )} interval at ${getPointLabel(pointData)}.`
  },
  onDragEnd: ({ active }): any => {
    const pointData = active.data.current as DraggableIntervalData
    return `Dropped point of ${getIntervalLabel(
      pointData.interval.type,
      pointData.interval.direction,
    )} interval at ${getPointLabel(pointData)}.`
  },
  onDragCancel: ({ active }) => {
    const pointData = active.data.current as DraggableIntervalData
    return `Dragging was cancelled. Point of ${getIntervalLabel(
      pointData.interval.type,
      pointData.interval.direction,
    )} interval returned at ${getPointLabel(pointData)}.`
  },
}
