import { parseAreaMapping, parseFeedback, parseMapping, parseStyleSheet } from './genericParsers'
import { AssessmentItem, ResponseDeclaration } from '@app/models'

import { AssessmentItemValue, FeedbackTypes } from '@app/types'

export const parseAssessmentItem = (
  data: any,
  resourceId: string,
  path: string,
  itemBody?: string
): AssessmentItem => {
  const item = data.assessmentItem

  return {
    id: item.attributes.identifier,
    path,
    resourceId,
    title: item.attributes.title,
    itemBody: itemBody,
    timeDependent: item.attributes.timeDependent,
    ...(item.responseDeclaration && {
      responseDeclaration: parseResponseDeclaration(item.responseDeclaration),
    }),
    ...(item.modalFeedback && { modalFeedback: parseFeedback(item.modalFeedback, FeedbackTypes.modal) }),
    ...(item.stylesheet && { stylesheetRef: parseStyleSheet(item.stylesheet) }),
  }
}

/**
 * Assessment item response reclaration parser.
 * @param { Object } responseDeclaration raw response declaration object.
 * @returns { ResponseDeclaration[] } parsed response declaration.
 */
export const parseResponseDeclaration = (responseDeclaration: any): ResponseDeclaration[] => {
  if (Array.isArray(responseDeclaration)) {
    return responseDeclaration.map(el => ({
      id: el.attributes.identifier,
      cardinality: el.attributes.cardinality,
      ...(el.attributes.baseType && { type: el.attributes.baseType }),
      ...(el.correctResponse && { correctResponse: parseValue(el.correctResponse) }),
      ...(el.defaultValue && { defaultValue: parseValue(el.defaultValue.value) }),
      ...(el.mapping && { mapping: parseMapping(el.mapping) }),
      areaMapping: parseAreaMapping(el.areaMapping),
    }))
  }
  return [
    {
      id: responseDeclaration.attributes.identifier,
      cardinality: responseDeclaration.attributes?.cardinality,
      ...(responseDeclaration.attributes.baseType && {
        type: responseDeclaration.attributes.baseType,
      }),
      ...(responseDeclaration.correctResponse && {
        correctResponse: parseValue(responseDeclaration.correctResponse),
      }),
      ...(responseDeclaration.defaultValue && {
        defaultValue: parseValue(responseDeclaration.defaultValue.value),
      }),
      ...(responseDeclaration.mapping && { mapping: parseMapping(responseDeclaration.mapping) }),
      areaMapping: parseAreaMapping(responseDeclaration.areaMapping),
    },
  ]
}

export const parseValue = (data: any): AssessmentItemValue[] => {
  if (Array.isArray(data.value)) {
    return data.value.map((value: any) => ({
      value: value,
    }))
  }

  return [
    {
      value: data.value,
    },
  ]
}
