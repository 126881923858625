import React from 'react'
import { Stack, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { BasicModal } from '../BasicModal'
import { CANCEL_BTN_ATTRIBUTES, SUBMIT_MODAL_ATTRIBUTES, SUBMIT_TEST_BTN_ATTRIBUTES } from '@app/constants'
import { formatDuration } from './helpers'
import { AssessmentStatus } from '@app/firebase/api'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  title: string
  itemsCount: number
  duration: number
  status: AssessmentStatus
}

export const StartTestModal: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  title,
  itemsCount,
  duration = 0,
  status,
}) => {
  const startTest = status === AssessmentStatus.new ? true : false
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      size='sm'
      title={`You are about to ${startTest ? 'start' : 'continue'} the ${title}`}
      submitText={startTest ? 'Start Test' : 'Continue Test'}
      cancelText='Return to Study Plan'
      modalProps={SUBMIT_MODAL_ATTRIBUTES}
      submitButtonProps={{
        ...SUBMIT_TEST_BTN_ATTRIBUTES,
        endIcon: <FontAwesomeIcon size='sm' icon={faArrowRight} />,
      }}
      cancelButtonProps={CANCEL_BTN_ATTRIBUTES}
    >
      <Stack gap={6}>
        <Typography variant='variation_subtitle1'>
          You are going to answer{' '}
          <Typography variant='variation_subtitle1' fontWeight={500} component='span'>
            {itemsCount}
          </Typography>{' '}
          questions.
        </Typography>
        <Typography variant='variation_subtitle1'>
          {duration === 0 ? 'This test is ' : 'You will have '}
          <Typography variant='variation_subtitle1' fontWeight={500} component='span'>
            {duration === 0 ? 'untimed' : formatDuration(duration)}
          </Typography>
          {duration ? ' to complete the test' : ''}. You can leave the test at any time. Your progress will be
          saved.
        </Typography>
      </Stack>
    </BasicModal>
  )
}
