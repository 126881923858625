import React from 'react'
import { Badge, badgeClasses, styled } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPennant } from '@fortawesome/pro-solid-svg-icons/faPennant'

export const ReviewMarkBadge: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <StyledBadge
      badgeContent={<BadgeIcon icon={faPennant} />}
      overlap='circular'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      {children}
    </StyledBadge>
  )
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    borderRadius: `${theme.shape.borderRadius * 5}px`,
    minWidth: 'unset',
    width: '16px',
    height: '16px',
    zIndex: 0,
    backgroundColor: theme.palette.purple.A200,
    color: theme.palette.common.white,
  },

}))

const BadgeIcon = styled(FontAwesomeIcon)({
  fontSize: '0.5rem',
})
