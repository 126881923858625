import {
  AreaMapping,
  AssessmentStylesheetRef,
  DataRef,
  Feedback,
  FeedbackTypes,
  InfoControl,
  MapEntry,
  Mapping,
  TimeLimits,
} from '@app/types'
import { BuilderOptions } from 'xml2js'
import { buildHtml } from '..'

/**
 * Assessment stylesheets parser.
 * @param { Array<Object> | Object } stylesheet raw stylesheet object.
 * @returns { AssessmentStyleSheet[] } array of parsed stylesheets.
 */
export const parseStyleSheet = (stylesheet: any): AssessmentStylesheetRef[] => {
  if (Array.isArray(stylesheet)) {
    stylesheet.map(el => ({
      url: el.attributes.href || '',
      media: el.attributes.media || '',
      title: el.attributes.title || '',
      type: el.attributes.type || '',
    }))
  }

  return [
    {
      url: stylesheet.attributes.href || '',
      media: stylesheet.attributes.media || '',
      title: stylesheet.attributes.title || '',
      type: stylesheet.attributes.type || '',
    },
  ]
}

/**
 * Time limits object parser.
 * @param { Object } timeLimits raw time limits data object.
 * @returns { TimeLimits } object with time limits data.
 */
export const parseTimeLimits = (timeLimits: any): TimeLimits => {
  return {
    ...(timeLimits?.attributes?.minTime && { minTime: Number(timeLimits.attributes.minTime) }),
    ...(timeLimits?.attributes?.maxTime && { maxTime: Number(timeLimits.attributes.maxTime) }),
    allowLateSubmission: timeLimits?.attributes.allowLateSubmission === 'true',
  }
}

/**
 * Assessment references parser.
 * @param { Array<Object> | Object } ref raw references object.
 * @returns { DataRef[] | null } array of parsed references.
 */
export const parseDataRef = (ref: any): DataRef[] => {
  if (Array.isArray(ref)) {
    return ref.map(item => ({
      id: item.attributes.identifier,
      url: item.attributes.href,
      required: item.attributes.required === 'true',
    }))
  }

  return [
    {
      id: ref.attributes.identifier,
      url: ref.attributes.href,
      required: ref.attributes.required === 'true',
    },
  ]
}

/**
 * Assessment test feedback parser.
 * @param { Object | Array<Object> } feedback raw test feedback data.
 * @returns { Feedback } parsed test feedback.
 */
export const parseFeedback = (feedback: any, type: FeedbackTypes): Feedback[] => {
  const builderOptions: BuilderOptions = {
    headless: true,
    rootName: 'feedback',
    renderOpts: {
      pretty: true
    }
  }

  if (Array.isArray(feedback)) {
    return feedback.map(el => {
      const { attributes, stylesheet, ...htmlData } = el
      return {
        id: attributes?.identifier,
        type: type,
        showHide: attributes?.showHide,
        ...(attributes?.access && { access: attributes.access }),
        ...(attributes?.outcomeIdentifier && { outcomeId: attributes.outcomeIdentifier }),
        ...(attributes?.title && { title: attributes.title }),
        ...(stylesheet && { stylesheet: parseStyleSheet(stylesheet) }),
        ...(htmlData && { data: buildHtml(htmlData, builderOptions) }),
      }
    })
  }

  const { attributes, stylesheet, ...htmlData } = feedback

  return [
    {
      id: attributes.identifier,
      type: type,
      showHide: attributes.showHide,
      ...(attributes?.access && { access: attributes.access }),
      ...(attributes?.outcomeIdentifier && { outcomeId: attributes.outcomeIdentifier }),
      ...(attributes?.title && { title: attributes.title }),
      ...(stylesheet && { stylesheet: parseStyleSheet(feedback.stylesheet) }),
      ...(htmlData && { data: buildHtml(htmlData, builderOptions) }),
    },
  ]
}

export const parseMapping = (mapping: any): Mapping => {
  return {
    ...(mapping.attributes?.upperBound && { upperBound: Number(mapping.attributes.upperBound) }),
    ...(mapping.attributes?.lowerBound && { lowerBound: Number(mapping.attributes.lowerBound) }),
    ...(mapping.attributes?.defaultValue && { defaultValue: Number(mapping.attributes.defaultValue) }),
    entries: parseMapEntries(mapping.mapEntry),
  }
}

export const parseMapEntries = (entries: any): MapEntry[] => {
  if (Array.isArray(entries)) {
    return entries.map(entry => ({
      key: entry.attributes.mapKey,
      value: entry.attributes.mappedValue,
      ...(entry.attributes.caseSensitive && { caseSensitive: entry.attributes.caseSensitive === 'true' }),
    }))
  }

  return [
    {
      key: entries.attributes.mapKey,
      value: entries.attributes.mappedValue,
      ...(entries.attributes.caseSensitive && { caseSensitive: entries.attributes.caseSensitive }),
    },
  ]
}

export const parseAreaMapping = (mapping: any): AreaMapping[] | null => {
  if (!mapping) return null

  return Array.isArray(mapping)
    ? mapping?.map(item => ({ ...item.attributes, ...(item.areaMapEntry?.attributes || {}) }))
    : [{ ...mapping.attributes, ...(mapping.areaMapEntry?.attributes || {}) }]
}

export const parseInfoControl = (infoControl: any): InfoControl => {
  return {
    ...(infoControl.attributes.title && { title: infoControl.attributes.title }),
    ...(infoControl.data && { data: buildHtml(infoControl.data) }),
  }
}
