import { forwardRef, useState } from 'react'
import { FullscreenImageModal } from './components/FullscreenImageModal'
import { ZoomImageContainer } from '@app/components/common/imageMagnifier/components/ZoomImageContainer'
import { Box } from '@mui/material'

type ImageMagnifierProps = {
  src?: string
  alt?: string
  className?: string
  tabIndex?: number
  children?: string | JSX.Element | JSX.Element[]
}

export const ImageMagnifier = forwardRef<any, ImageMagnifierProps>((props, ref) => {
  const [openFullImage, setOpenFullImage] = useState(false)

  return (
    <Box onClick={e => e.stopPropagation()}>
      <ZoomImageContainer isFullscreen={false} setOpenFullImage={setOpenFullImage} {...props} ref={ref} />

      <FullscreenImageModal opened={openFullImage} onClose={() => setOpenFullImage(false)}>
        <Box p={2}>
          <ZoomImageContainer isFullscreen={true} setOpenFullImage={setOpenFullImage} {...props} ref={ref} />
        </Box>
      </FullscreenImageModal>
    </Box>
  )
})
