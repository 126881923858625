import { AssessmentStatus } from '@app/firebase/api'
import {
  CurrentQtiPackageState,
  CurrentTestState,
  CurrentUserAssessmentState,
  setCurrentTest,
  setCurrentTestPart,
  setHeaderTitle,
  setSyncIntervalStep,
  startSyncInterval,
  SyncIntervalState,
} from '@app/storage'
import { TimerStatus } from '@app/types'
import { useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { useSetCurrentPackage } from './setCurrentPackage'

/**
 * Hook for current assessment setup.
 * @param { Boolean } loadingState inital loading state.
 * @returns { Boolean } package extraction loading state
 */
export const useCurrentAssessmentSetup = (loadingState: boolean): boolean => {
  const { syncIntervalStatus } = useSnapshot(SyncIntervalState)
  const { status } = useSnapshot(CurrentUserAssessmentState)
  const { currentTest, currentTestPart, isStarted } = useSnapshot(CurrentTestState)
  const { tests, assessmentSettings } = useSnapshot(CurrentQtiPackageState)

  useEffect(() => {
    if (!tests || !tests.length) {
      return
    }
    const testFromQtiPackage = CurrentQtiPackageState.tests[0]

    setCurrentTest(testFromQtiPackage)
    setHeaderTitle(testFromQtiPackage.title)
  }, [tests, tests.length])

  useEffect(() => {
    if (!currentTest || loadingState) {
      return
    }

    setCurrentTestPart(CurrentTestState.currentTest.testPart[0])
  }, [currentTest, loadingState])

  useEffect(() => {
    if (status !== AssessmentStatus.completed || !currentTestPart) {
      return
    }

    CurrentTestState.currentTest.isFinished = true
    CurrentTestState.currentTestPart.isFinished = true
  }, [currentTestPart, status])

  useEffect(() => {
    if (
      isStarted &&
      syncIntervalStatus === TimerStatus.notStarted &&
      CurrentQtiPackageState.assessmentSettings &&
      CurrentQtiPackageState.assessmentSettings?.syncInterval
    ) {
      setSyncIntervalStep(CurrentQtiPackageState.assessmentSettings.syncInterval)
      startSyncInterval()
    }
    // eslint-disable-next-line
  }, [syncIntervalStatus, assessmentSettings?.syncInterval, assessmentSettings, isStarted])

  const loading = useSetCurrentPackage()

  return loading
}
