import { Theme } from '@mui/material'

export const generateAdaptiveTypography = (theme: Theme) => {
  theme.typography.h1[theme.breakpoints.down('mobile')] = {
    fontSize: '1rem', // 16px
    lineHeight: '1.25rem', // 20px
  }

  theme.typography.h2[theme.breakpoints.down('mobile')] = {
    fontSize: '0.875rem', // 14px
    lineHeight: '1.125rem', // 18px
  }

  theme.typography.h3[theme.breakpoints.down('mobile')] = {
    fontSize: '1.125rem', // 18px
    lineHeight: '1.438rem', // 23px
  }

  theme.typography.h4[theme.breakpoints.down('mobile')] = {
    fontSize: '0.875rem', // 14px
    lineHeight: '1.125rem', // 18px
  }

  theme.typography.h5[theme.breakpoints.down('mobile')] = {
    fontSize: '0.813rem', // 13px
    lineHeight: '1rem', // 16px
  }

  theme.typography.h6[theme.breakpoints.down('mobile')] = {
    fontSize: '0.813rem', // 13px
    lineHeight: '1rem', // 16px
  }

  theme.typography.body1[theme.breakpoints.down('mobile')] = {
    fontSize: '0.875rem', // 14px
    lineHeight: '1.125rem', // 18px
  }

  theme.typography.body2[theme.breakpoints.down('mobile')] = {
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
  }

  theme.typography.subtitle1[theme.breakpoints.down('mobile')] = {
    fontSize: '0.75rem', // 12px
    lineHeight: '0.875rem', // 14px
  }

  theme.typography.subtitle2[theme.breakpoints.down('mobile')] = {
    fontSize: '0.688rem', // 11px
    lineHeight: '0.875rem', // 14px
  }

  theme.typography.caption[theme.breakpoints.down('mobile')] = {
    fontSize: '0.688rem', // 11px
    lineHeight: '0.875rem', // 14px
  }
}
