import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  dialogContentClasses,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import { SpecialCharacters } from './SpecialCharacters.json'
import { SpecialCharacterBubble } from '../../bubbles/SpecialCharacterBubble'
import { scrollBarMixinObj } from '@app/constants'
import { onKeyboardSubmit } from '@app/helpers'

interface SpecialCharactersModalProps {
  open: boolean
  onClose: () => void
  insertSpecialCharacter: (character: string) => void
  title?: string
}

interface ISelectedCharacter {
  hex: string
  char: string
}

export const SpecialCharactersModal: FC<SpecialCharactersModalProps> = props => {
  const { open, onClose, title = 'Select Special Character', insertSpecialCharacter } = props

  const [selectedCharacter, setSelectedCharacter] = useState<ISelectedCharacter>(null)

  const selectCharacter = (hex: string, char: string) => () => {
    selectedCharacter?.hex === hex ? setSelectedCharacter(null) : setSelectedCharacter({ hex, char })
  }

  const closeModal = () => {
    setSelectedCharacter(null)
    onClose()
  }

  const confirmSelection = () => {
    insertSpecialCharacter(selectedCharacter?.char || '')
    closeModal()
  }

  return (
    <StyledDialog open={open} onClose={closeModal} scroll='paper'>
      <ModalHeader>
        <StyledIconButton onClick={closeModal} aria-label='Close Modal Window'>
          <StyledFontAwesomeIcon icon={faTimes} />
        </StyledIconButton>
        <ModalTitle variant='h3'>{title}</ModalTitle>
      </ModalHeader>
      <ContentWrapper>
        {SpecialCharacters.map(item => (
          <SpecialCharacterBubble
            key={item.hex}
            character={item.char}
            selected={selectedCharacter?.hex === item.hex}
            onClick={selectCharacter(item.hex, item.char)}
            onKeyDown={onKeyboardSubmit(selectCharacter(item.hex, item.char))}
          />
        ))}
      </ContentWrapper>
      <ModalFooter>
        <StyledButton variant='text' onClick={closeModal}>
          Cancel
        </StyledButton>
        <StyledButton variant='text' onClick={confirmSelection}>
          Select
        </StyledButton>
      </ModalFooter>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`.${dialogContentClasses.root}`]: {
    borderRadius: theme.shape.borderRadius * 1.5,
    width: '500px',
    height: '640px',
    scrollBehavior: 'auto',
    ...scrollBarMixinObj(theme),

    '::-webkit-scrollbar-track': {
      background: 'transparent',
      margin: theme.spacing(3),
      padding: '5px',
    },

    [theme.breakpoints.down('tablet')]: {
      height: '100vh',
      width: 'auto',
      margin: theme.spacing(0),
    },
  },
}))

const ModalHeader = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(3, 3),
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
}))

const ContentWrapper = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
}))

const ModalFooter = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(4, 5),
}))

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: '1.125rem',
}))

const ModalTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(5),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '50px',
  height: '50px',
  color: theme.palette.grey[500],
}))

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontSize: theme.typography.body1.fontSize,
  lineHeight: '1.063rem',
  padding: theme.spacing(3, 5),
}))
