import { developmentEnv } from "./dev.environment"

export type AppEnvironment = {
  REACT_APP_API_KEY: string,
  REACT_APP_AUTH_DOMAIN: string,
  REACT_APP_PROJECT_ID: string,
  REACT_APP_BUCKET_URL: string,
  REACT_APP_MESSAGING_SENDER_ID: string,
  REACT_APP_APP_ID: string,
  REACT_APP_MEASUREMENTID: string
}

let environment: Partial<AppEnvironment> = {}
if (process.env.NODE_ENV === 'development') {
  environment = developmentEnv
} else {
  environment = {
    REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
    REACT_APP_AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
    REACT_APP_PROJECT_ID: process.env.REACT_APP_PROJECT_ID,
    REACT_APP_BUCKET_URL: process.env.REACT_APP_BUCKET_URL,
    REACT_APP_MESSAGING_SENDER_ID: process.env.REACT_APP_MESSAGING_SENDER_ID,
    REACT_APP_APP_ID: process.env.REACT_APP_APP_ID,
    REACT_APP_MEASUREMENTID: process.env.REACT_APP_MEASUREMENTID
  }
}

export default environment
