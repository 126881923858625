import { FC } from 'react'
import { NumberLine } from './NumberLine'
import { WIDTH } from './constants'
import { IntervalsContext } from './context'
import { parseChildrenToProps } from './helpers'
import { useNumberLine } from './hooks'
import { NumberLineComponentProps } from './types'

export const NumberLineComponent: FC<NumberLineComponentProps> = props => {
  const { itemId, responseidentifier: responseId, children } = props

  const width = props.width ? parseInt(props.width, 10) : WIDTH
  const height = props.height ? parseInt(props.height, 10) : width * 0.3
  const { axis, intervalTypes } = parseChildrenToProps(children)

  const { intervals, disabled } = useNumberLine(responseId)

  return (
    <IntervalsContext.Provider value={intervals}>
      <NumberLine
        key={itemId}
        width={width}
        height={height}
        axis={axis}
        intervalTypes={intervalTypes}
        disabled={disabled}
      />
    </IntervalsContext.Provider>
  )
}
