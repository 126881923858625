import { AssessmentStimulus } from "@app/models";
import { FC } from "react";
import parse from 'html-react-parser';
import styled from "styled-components";

interface StimulusProps {
  stimulusData: AssessmentStimulus;
}

const AssessmentStimulusComponent:FC<StimulusProps> = ({ stimulusData }) => {

  return (
    <Container>
      <h3>ID: </h3>
      <p>{ stimulusData.id }</p>
      <h3>Title: </h3>
      <p>{ stimulusData.title }</p>
      <h3>Stimulus body: </h3>
      { parse(stimulusData.stimulusBody) }
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 0;
  }
`

export default AssessmentStimulusComponent