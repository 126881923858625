import { FC, useContext } from 'react'
import { BoxProps } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHighlighterLine } from '@fortawesome/pro-solid-svg-icons/faHighlighterLine'
import { faArrowLeftRotate } from '@fortawesome/pro-solid-svg-icons/faArrowLeftRotate'
import { faPennant } from '@fortawesome/pro-solid-svg-icons/faPennant'
import { faStrikethrough } from '@fortawesome/pro-solid-svg-icons/faStrikethrough'
import { QuestionContextMenuContext, CustomContextMenu } from '@app/components/common'
import InteractionLayoutHeader from './InteractionLayoutHeader'
import { STAAR_MULTIPLE_CHOICE_ANSWER } from '@app/constants'
import { useScopedHighlighter } from '@app/helpers'

type Props = BoxProps & {
  itemId: string
}

const TestTakingLayout: FC<Props> = ({ itemId, children, ...props }) => {
  const { markedItems, stikethroughAnswers, setMarkForReview, setStikethrough } =
  useContext(QuestionContextMenuContext)

  const { hasHighlights, onHighlightSelection, onResetHighlight, onRemoveHighlight, isSelectionHighlighted } =
    useScopedHighlighter('q', itemId)

  const getContextMenuOptions = (event: React.MouseEvent, selectedText: string) => {
    const answerId = (event.target as HTMLElement)
      .closest(`.${STAAR_MULTIPLE_CHOICE_ANSWER}`)
      ?.getAttribute('id')

    const isItemMarked = markedItems.includes(itemId)
    const isStrikethrough = stikethroughAnswers.includes(answerId)

    const isHighlightedElement = isSelectionHighlighted()

    const options = [
      {
        label: `${isItemMarked ? 'Unmark' : 'Mark'} for Review`,
        icon: <FontAwesomeIcon icon={faPennant} />,
        action: setMarkForReview,
      },
      ...(selectedText && [
        {
          label: `${isHighlightedElement ? 'Unhighlight' : 'Highlight'} Selection`,
          icon: <FontAwesomeIcon icon={faHighlighterLine} />,
          action: isHighlightedElement ? onRemoveHighlight : onHighlightSelection,
        },
      ]),
      {
        label: `${isStrikethrough ? 'Remove ' : ''}Strikethrough`,
        targetClassName: STAAR_MULTIPLE_CHOICE_ANSWER,
        icon: <FontAwesomeIcon icon={faStrikethrough} />,
        action: answerId && setStikethrough(answerId),
      },
    ]

    if (hasHighlights) {
      options.push({
        label: 'Reset Highlight',
        icon: <FontAwesomeIcon icon={faArrowLeftRotate} />,
        action: onResetHighlight,
      })
    }

    return options
  }

  return (
    <CustomContextMenu getContextMenuOptions={getContextMenuOptions} {...props}>
      <InteractionLayoutHeader
        hasHighlights={hasHighlights}
        checkHighlightedElement={isSelectionHighlighted}
        onHighlightSelection={onHighlightSelection}
        onResetHighlight={onResetHighlight}
        onRemoveHighlight={onRemoveHighlight}
      />
      {children}
    </CustomContextMenu>
  )
}

export default TestTakingLayout
