import { useState, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { CurrentTestState } from '@app/storage'
import { useResponseUpdate, useResponseValidityUpdate, useStateReset } from '@app/helpers'
import { AssociationConstraints, InteractionType } from '@app/types'
import { getElementCenter } from './utils'

export const ASSOCIATION_SEPARATOR = ' '

type UseAssociations = {
  associations: string[]
  onToggleAssociation: (association: string) => void
  onClearAssociation: (association: string) => () => void
  setAssociations: React.Dispatch<React.SetStateAction<string[]>>
  announcement: string
}

export const useAssociations = (
  responseIdentifier: string,
  constraints: AssociationConstraints = {},
): UseAssociations => {
  const { minAssociations, maxAssociations } = constraints

  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)
  const [associations, setAssociations] = useState<string[]>([])
  const [announcement, setAnnouncement] = useState<string>('')

  const onToggleAssociation = (association: string) => {
    // If test is finished, don't change selection.
    if (currentTestPart?.isFinished) {
      return
    }

    setAssociations(current => {
      if (current.includes(association)) {
        setAnnouncement(`Removed association ${association}.`)
        return current.filter(item => item !== association)
      }

      setAnnouncement(`Created association ${association}.`)
      return [...current, association]
    })
  }

  const onClearAssociation = (association: string) => () => {
    if (currentTestPart?.isFinished) {
      return
    }

    setAssociations(current => current.filter(item => item !== association))
    setAnnouncement(`Removed association ${association}.`)
  }

  // Set response on associations change.
  useResponseUpdate(
    currentItemResponse?.id,
    responseIdentifier,
    associations,
    InteractionType.graphicAssociate,
  )
  useResponseValidityUpdate(currentItemResponse?.id, associations.length, minAssociations, maxAssociations)

  // Reset selected items when quiestion changes
  useStateReset(currentItemResponse?.id, responseIdentifier, setAssociations)

  return { associations, onToggleAssociation, onClearAssociation, setAssociations, announcement }
}

type UseCurrentHotspot = [
  string,
  (hotspotId: string) => (event: any) => void,
  React.Dispatch<React.SetStateAction<string>>,
]

export const useCurrentHotspot = (
  hotspotSelector: string,
  handleAssociation: (association: string) => void,
): UseCurrentHotspot => {
  const [currentHotspot, setCurrentHotspot] = useState<string>('')
  const { currentTestPart } = useSnapshot(CurrentTestState)

  const onClickHotspot = (hotspotId: string) => (event: any) => {
    if (currentTestPart?.isFinished) {
      return
    }

    // Check first hotspot, if not checked.
    if (!currentHotspot) {
      setCurrentHotspot(hotspotId)
      return
    }

    // If first hotspot is the same as clicked, clear first hotspot..
    if (currentHotspot === hotspotId) {
      setCurrentHotspot('')
      return
    }

    // If target is associable hotspot, handle association.
    if (event?.target?.matches(hotspotSelector)) {
      // Order of association doesn't matter.
      const association = [currentHotspot, hotspotId].sort().join(ASSOCIATION_SEPARATOR)
      handleAssociation(association)
    }

    // Clear first hotspot.
    setCurrentHotspot('')
  }

  return [currentHotspot, onClickHotspot, setCurrentHotspot]
}

interface AssociationLineData {
  key: string
  x1: number
  y1: number
  x2: number
  y2: number
}

type UseAssociationLines = [
  AssociationLineData[],
  React.Dispatch<React.SetStateAction<AssociationLineData[]>>,
]

export const useAssociationLines = (associations: string[]): UseAssociationLines => {
  const [associationLines, setAssociationLines] = useState<AssociationLineData[]>([])

  useEffect(() => {
    setAssociationLines(
      associations.map(association => {
        const [sourceId, targetId] = association.split(ASSOCIATION_SEPARATOR)
        const { x: x1, y: y1 } = getElementCenter(sourceId)
        const { x: x2, y: y2 } = getElementCenter(targetId)
        return { key: association, x1, y1, x2, y2 }
      }),
    )
  }, [associations])

  return [associationLines, setAssociationLines]
}
