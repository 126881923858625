import { DragEndEvent } from '@dnd-kit/core'
import { Coordinates } from '@dnd-kit/utilities'
import { isWithinDomain } from '../../GraphingInteraction/helpers'
import { ChartDomain, DraggableElement } from '../../GraphingInteraction/types'
import { Interval } from '../types'

export const useDraggableIntervals = (
  current: string,
  intervals: Interval[],
  gridSize: Coordinates,
  increment: number,
  chartDomain: ChartDomain,
  onDragPoint: (position: DOMPoint) => void,
): {
  onDragEnd: (event: DragEndEvent) => void
} => {
  const onDragEnd = (event: DragEndEvent) => {
    const activeData = event.active.data

    if (activeData.current?.type === DraggableElement.POINT) {
      const deltaX = event.delta.x / gridSize.x
      const point = intervals.find(interval => interval.id === current).point

      const isSamePoint = point.x === activeData.current._x && point.y === activeData.current._y

      if (!isSamePoint) return

      // Round values to snap point to grid on placement.
      const newPosition = new DOMPoint(point.x + Math.round(deltaX) * increment, 0)

      if (!isWithinDomain(newPosition, chartDomain)) {
        return
      }

      onDragPoint(newPosition)
    }
  }

  return { onDragEnd }
}
