import { FC } from 'react'
import { Box, BoxProps } from '@mui/material'
import InteractionLayoutHeader from './InteractionLayoutHeader'

const ReviewModeLayout: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box {...props}>
      <InteractionLayoutHeader reviewMode />
      {children}
    </Box>
  )
}

export default ReviewModeLayout
