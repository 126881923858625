import { useEffect, useState } from 'react'
import { setItemResponse, CurrentTestState } from '@app/storage'
import { useSnapshot } from 'valtio'
import {
  useAssessmentItemResponseScoring,
  useCountingVariants,
  useItemResponse,
  useResponseDeclaration,
  useStateReset,
} from '@app/helpers'
import { ChangeCountingVariantsType, CountingVarinatsState, InteractionType } from '@app/types'

type IUseMatchStatesControl = [
  (id: string, checked: boolean) => void,
  (matchId: string) => boolean,
  (rowId: string, columnId: string) => boolean,
  boolean,
  string[],
  () => void,
]

export const useMatchStatesControl = (
  itemId: string,
  responseIdentifier: string,
  initialCountingVariants: CountingVarinatsState,
  maxAssociations: number,
  minAssociations: number,
): IUseMatchStatesControl => {
  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)
  const itemResponse = useItemResponse(currentItemResponse?.id, responseIdentifier)
  const { groupMode, correctResponse } = useAssessmentItemResponseScoring(
    currentItemResponse?.itemId,
    responseIdentifier,
  )

  const [selectMatches, setSelectMatches] = useState<string[]>(itemResponse?.length ? [...itemResponse] : [])
  const [id, setId] = useState('')
  const [countingVariants, changeCountingVariants] = useCountingVariants(
    currentItemResponse?.id,
    initialCountingVariants,
  )

  const responseDeclaration = useResponseDeclaration(id, responseIdentifier)

  const changeMatch = (id: string, checked: boolean) => {
    if (currentTestPart?.isFinished) return

    const rowId = id.split(' ')[0]
    const columnId = id.split(' ')[1]
    let newSelectMatch: string[]

    if (!checked) {
      changeCountingVariants(ChangeCountingVariantsType.add)([rowId, columnId])
      newSelectMatch = selectMatches.filter(s => s !== id)
    } else {
      changeCountingVariants(ChangeCountingVariantsType.sub)([rowId, columnId])
      newSelectMatch = [...selectMatches, id]
    }

    setSelectMatches(newSelectMatch)
  }

  useEffect(() => {
    if (!currentItemResponse?.id) {
      return
    }

    setId(CurrentTestState.currentItemResponse.id)
  }, [currentItemResponse?.id, currentItemResponse])

  useEffect(() => {
    if (!currentItemResponse?.id || !responseDeclaration) return
    if (currentTestPart?.isFinished) return
    setItemResponse(
      currentItemResponse.id,
      responseIdentifier,
      selectMatches,
      selectMatches.length >= minAssociations,
      InteractionType.match,
      responseDeclaration.cardinality,
    )
  }, [
    currentItemResponse?.id,
    currentTestPart?.isFinished,
    selectMatches,
    responseIdentifier,
    minAssociations,
    responseDeclaration,
  ])

  // Reset state on response ID change.
  useStateReset(currentItemResponse?.id, responseIdentifier, setSelectMatches)

  const resetAll = () => {
    selectMatches.forEach(id => {
      const rowId = id.split(' ')[0]
      const columnId = id.split(' ')[1]

      changeCountingVariants(ChangeCountingVariantsType.add)([rowId, columnId])
    })
    setSelectMatches([])
  }

  const isChecked = (matchId: string) => {
    if (groupMode) return correctResponse?.includes(matchId) ?? false
    return Boolean(selectMatches.find(sm => sm === matchId))
  }

  const isDisabled = (rowId: string, columnId: string) => {
    if (maxAssociations === selectMatches.length) {
      return !isChecked(`${rowId} ${columnId}`)
    }
    if (maxAssociations !== 0) {
      if (countingVariants[rowId].matchMax !== 0 || countingVariants[columnId].matchMax !== 0) {
        return (
          (countingVariants[rowId].matchCount === 0 &&
            !isChecked(`${rowId} ${columnId}`) &&
            countingVariants[rowId].matchMax !== 0) ||
          (countingVariants[columnId].matchCount === 0 &&
            !isChecked(`${rowId} ${columnId}`) &&
            countingVariants[columnId].matchMax !== 0)
        )
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return [changeMatch, isChecked, isDisabled, currentTestPart.isFinished, selectMatches, resetAll]
}
