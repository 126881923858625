import { DropContainerState } from '@app/types'

export const containersToResponse = (containers: DropContainerState): string[] => {
  if (!containers) return []

  const valuesArray = Object.values(containers)
  const firstPositionsInPair = valuesArray.slice(valuesArray.length / 2, valuesArray.length)
  const secondPositionsInPair = valuesArray.slice(0, valuesArray.length / 2)

  const response = firstPositionsInPair.map((item, i) => {
    if (item || secondPositionsInPair[i]) {
      return `${item || ''} ${secondPositionsInPair[i] || ''}`
    }
    return null
  })

  return response.every(res => !res) ? [] : response
}

export const responseToContainers = (response: string[]) => {
  if (!response) return []
  return response.reduce((acc: string[], item, i) => {
    if (item) {
      const itemArr = item.split(' ')
      const firstId = itemArr[0].trim()
      const secondId = itemArr[1].trim()
      return [...acc, ...(firstId && [`0${i} ${firstId}`]), ...(secondId && [`1${i} ${secondId}`])]
    }
    return acc
  }, [])
}
