import { forwardRef, KeyboardEvent, MouseEvent, useRef } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { alpha, styled } from '@mui/material'
import { Point, PointProps } from 'victory'
import { composeRefs, useSVGScale } from '@app/helpers'
import { ColorIndexGroup, ColorOptions } from './ColorIndexGroup'
import { DraggableData, DraggableElement, DraggableItem } from '../types'
import { getGraphColor, getPointLabel } from '../helpers'
import { focusStyle } from '@app/theme'

const DEFAULT_HALO_SIZE = 25

export interface DraggablePointProps extends PointProps {
  haloSize?: number
  colorIndex?: number
  disabled?: boolean
  selected?: boolean
  className?: string
  keyDownHandler?: (e: KeyboardEvent<SVGGElement>, pointId?: string) => void
  onSelect?: (id: string) => void
  onKeyDown?: (e: KeyboardEvent<SVGGElement>) => void
  onBlur?: (event: any) => void
  activeDraggable?: DraggableItem
  labelFormat?: (data: DraggableData) => string
  draggableData?: Record<string, any>
  colorOptions?: ColorOptions
}

export const DraggablePoint = forwardRef<any, DraggablePointProps>((props, ref) => {
  const {
    haloSize = DEFAULT_HALO_SIZE,
    colorIndex = 0,
    selected,
    disabled,
    className,
    keyDownHandler,
    onSelect,
    onKeyDown,
    onBlur,
    activeDraggable,
    labelFormat = getPointLabel,
    draggableData,
    colorOptions,
    ...rest
  } = props

  const draggableId = props.id instanceof Function ? props.id() : props.id

  const haloRef = useRef<any>()
  const svgScale = useSVGScale(haloRef)

  const data = { ...props.datum, type: DraggableElement.POINT, ...draggableData }
  const { setNodeRef, listeners, attributes, transform, isDragging } = useDraggable({
    id: draggableId,
    disabled,
    data: { index: props.index, ...data },
    attributes: {
      tabIndex: disabled ? -1 : 0,
      roleDescription: 'draggable point',
    },
  })

  const setSvgNodeRef = (el: any) => setNodeRef(el)

  const wrapperStyle = transform
    ? {
        transform: `translate(${transform.x / svgScale[0]}px, ${transform.y / svgScale[1]}px)`,
      }
    : undefined

  const pointId = props.datum?.id
  const customListeners = {
    ...listeners,
    ...((onKeyDown || keyDownHandler) && {
      onKeyDown: (e: KeyboardEvent<SVGGElement>) => {
        keyDownHandler && keyDownHandler(e, pointId)
        onKeyDown && onKeyDown(e)
        listeners?.onKeyDown && listeners.onKeyDown(e)
      },
      ...(onSelect && {
        onClick: (event: MouseEvent<SVGGElement>) => {
          onSelect(pointId)
          listeners?.onClick && listeners.onClick(event)
        },
      }),
      ...(onBlur && {
        onBlur: (event: any) => {
          onBlur(event)
          listeners?.onBlur && listeners.onBlur(event)
        },
      }),
    }),
  }

  return (
    <StyledPointWrapper
      ref={composeRefs([haloRef, setSvgNodeRef, ref])}
      className={`point ${className ?? ''}`}
      colorIndex={colorIndex}
      colorOptions={colorOptions}
      selected={selected || isDragging || activeDraggable?.id === pointId}
      disabled={disabled}
      {...attributes}
      {...customListeners}
      style={wrapperStyle}
      data-point-id={pointId}
      aria-label={labelFormat(data)}
    >
      <StyledPointHalo cx={props.x} cy={props.y} r={haloSize} role='presentation' />
      <StyledPoint {...rest} disableInlineStyles />
    </StyledPointWrapper>
  )
})

const StyledPointWrapper = styled(ColorIndexGroup)(
  ({
    theme,
    colorIndex = 0,
    selected,
    disabled,
    colorOptions: { hueValue = 500, focusFill = false, hoverOpacity = 0.4 } = {},
  }) => ({
    touchAction: 'none',
    cursor: disabled ? 'auto' : 'pointer',

    ':focus': !selected
      ? {
          outline: focusStyle(theme).border.border,
          '& > circle': {
            outline: focusStyle(theme).border.outline,
            stroke: 'none !important',
          },
        }
      : { outline: 'none' },

    [`${StyledPoint}`]: {
      fill: getGraphColor(theme, 'A700', colorIndex),
      stroke: getGraphColor(theme, 900, colorIndex),
    },
    [`${StyledPointHalo}`]: selected
      ? {
          fill: alpha(getGraphColor(theme, 500, colorIndex), 0.4),
          stroke: theme.palette.grey[600],
          strokeWidth: 2,
          strokeDasharray: '4 2',
        }
      : {
          fill: alpha(getGraphColor(theme, 500, colorIndex), 0.2),
        },

    [`${theme.breakpoints.up('mobile')} and (hover: hover)`]: {
      [`:hover ${StyledPointHalo}`]: disabled
        ? undefined
        : {
            fill: alpha(getGraphColor(theme, hueValue, colorIndex), hoverOpacity),
          },
      [`:focus ${StyledPointHalo}`]: disabled
        ? undefined
        : {
            stroke: theme.palette.grey[600],
            strokeWidth: 2,
            strokeDasharray: '4 2',
            ...(focusFill ? { fill: alpha(getGraphColor(theme, hueValue, colorIndex), 0.2) } : {}),
          },

      [`:focus:hover ${StyledPointHalo}`]:
        disabled && !focusFill
          ? undefined
          : {
              fill: alpha(getGraphColor(theme, hueValue, colorIndex), hoverOpacity),
            },
    },
  }),
)

export const StyledPointHalo = styled('circle')(() => ({}))

export const StyledPoint = styled(Point)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}))
