const addZero = (duration: number) => {
  return duration >= 10 ? duration : `0${duration}`
}

export const timeConverter = (time: number) => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time - hours * 3600) / 60)
  const seconds = Math.floor(time - hours * 3600 - minutes * 60)

  return hours ? `${hours}:${addZero(minutes)}:${addZero(seconds)}` : `${minutes}:${addZero(seconds)}`
}
