import { forwardRef, KeyboardEvent, MutableRefObject } from 'react'
import { EquationEditorControlButton } from './EquationEditorControlButton'
import { IMathFieldControls } from '../types'
import { Box } from '@mui/material'

interface EquationEditorControlsProps {
  mathFieldControls: IMathFieldControls[]
  disabled?: boolean
  currentControl: number
  onKeyDown: (e: KeyboardEvent) => void
  selectControl: (index: number) => void
}

export const EquationEditorControls = forwardRef<HTMLButtonElement[], EquationEditorControlsProps>(
  ({ mathFieldControls, disabled = false, currentControl, onKeyDown, selectControl }, controlsRef) => {
    return (
      <>
        <Box id='eqEditorControlsNavigationDescription' sx={{ display: 'none' }}>
          To move through the controls of Equation Editor, use the keyboard arrows
        </Box>

        <Box aria-label='Math Field Controls Section'>
          {mathFieldControls.map(({ icon, command, label }, index) => (
            <EquationEditorControlButton
              ref={ref => ((controlsRef as MutableRefObject<HTMLButtonElement[]>).current[index] = ref)}
              key={label}
              icon={icon}
              onClick={() => {
                command()
                selectControl(index)
              }}
              ariaLabel={label}
              disabled={disabled}
              onKeyDown={onKeyDown}
              tabIndex={currentControl === index ? 0 : -1}
              aria-describedby='eqEditorControlsNavigationDescription'
            />
          ))}
        </Box>
      </>
    )
  },
)
