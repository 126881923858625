import { useEffect, useState } from 'react'
import { DragStartEvent, DragEndEvent } from '@dnd-kit/core'
import { Coordinates } from '@dnd-kit/utilities'
import { isAsymptoteWithinDomain, isWithinDomain } from '../helpers'
import { GraphState, ChartDomain, DraggableElement, DraggableItem } from '../types'

export type UseDraggableGraphs = {
  activeDraggable: DraggableItem
  onSelectDraggable: (item: DraggableItem) => void
  onDragStart: (event: DragStartEvent) => void
  onDragEnd: (event: DragEndEvent) => void
}

export const useDraggableGraphs = (
  activeGraph: number,
  graphs: GraphState[],
  asymptote: number,
  gridSize: Coordinates,
  chartDomain: ChartDomain,
  increment: Coordinates,
  onDragPoint: (id: string, position: DOMPoint) => void,
  onDragAsymptote: (asymptote: number) => void,
): UseDraggableGraphs => {
  const [activeDraggable, setActiveDraggable] = useState<DraggableItem>(null)

  const onSelectDraggable = (item: DraggableItem) => setActiveDraggable(item)

  useEffect(() => {
    setActiveDraggable(null)
  }, [activeGraph])

  const onDragStart = (event: DragStartEvent) => {
    // Detect that dragging point belongs to the current graph.
    const activeData = event.active.data

    if (!activeData.current) return

    if (activeData.current.index !== activeGraph) return

    setActiveDraggable({
      id: activeData.current.id,
      type: activeData.current.type,
    })
  }

  const onDragEnd = (event: DragEndEvent) => {
    setActiveDraggable(null)

    const activeData = event.active.data
    const draggableId = activeData.current?.id
    const draggableElement = activeData.current?.type

    if (draggableElement === DraggableElement.ASYMPTOTE) {
      const deltaY = (event.delta.y / gridSize.y) * -1
      // Round value to snap asymptote to grid on placement.
      const newAsymptote = asymptote + Math.round(deltaY) * increment.y

      if (!isAsymptoteWithinDomain(newAsymptote, chartDomain)) {
        return
      }

      return onDragAsymptote(newAsymptote)
    }

    if (draggableElement === DraggableElement.POINT) {
      const delta = {
        x: event.delta.x / gridSize.x,
        y: (event.delta.y / gridSize.y) * -1,
      }
      const points = graphs[activeGraph].points
      const pointIndex = points.findIndex(item => item.id === draggableId)

      if (pointIndex < 0) return

      // Round values to snap point to grid on placement.
      const newPosition = new DOMPoint(
        Math.round(points[pointIndex].x + Math.round(delta.x) * increment.x),
        Math.round(points[pointIndex].y + Math.round(delta.y) * increment.y),
      )

      if (!isWithinDomain(newPosition, chartDomain)) {
        return
      }

      return onDragPoint(draggableId, newPosition)
    }
  }

  return {
    activeDraggable,
    onSelectDraggable,
    onDragStart,
    onDragEnd,
  }
}
