import { DomNodes } from '@app/constants'
import { createArray, useFindNodes } from '@app/helpers'
import { IGridInSymbols, IRowSymbols, ISymbolsWithIndex } from './types'

export const getSymbolsStructure = (gridInSymbols: JSX.Element[]): IGridInSymbols => {
  return gridInSymbols.reduce((res: IGridInSymbols, item) => {
    const columnSequenceIndex = item.props.columnsequenceindex
    const rowSequenceIndex = item.props.rowsequenceindex
    const [symbols] = useFindNodes(item.props.children, [DomNodes.gridInSymbol])
    return {
      ...res,
      [columnSequenceIndex]: {
        ...res[columnSequenceIndex],
        [rowSequenceIndex]: symbols.map(symbol => symbol.props.children),
      },
    }
  }, {})
}

export const getSymbolsOfColumn = (columnIndex: number, symbolsStructure: IGridInSymbols): string[] =>
  Object.values(symbolsStructure[columnIndex]).reduce((res: string[], row) => [...res, ...row], [])

export const getSymbols = (columnCount: number, symbolsStructure: IGridInSymbols) =>
  createArray(columnCount).map(columnIndex => getSymbolsOfColumn(columnIndex + 1, symbolsStructure))

export const getSymbolsWithIndex = (symbols: string[][], maxSymbols: number): ISymbolsWithIndex[][] => {
  return symbols
    .map((columnSymbols, columnIndex) =>
      columnSymbols.map((symbol, rowIndex, arr) => ({
        symbol: symbol,
        index: `${maxSymbols - arr.length + rowIndex + 1}/${columnIndex}`,
        ref: null,
      })),
    )
    .reduce(
      (acc: any, columnSymbols, columnIndex) => [
        ...acc,
        [{ symbol: 'input', index: createCellIndex(0, columnIndex), ref: null }, ...columnSymbols],
      ],
      [],
    )
}

export const getUniqSymbols = (symbolsStructure: IGridInSymbols) => {
  const allSymbols = Object.values(symbolsStructure).reduce((res: IRowSymbols, rowSymbols) => {
    if (Object.keys(res).length === 0) {
      return {
        ...rowSymbols,
      }
    }

    return Object.entries(rowSymbols).reduce((acc: any, symbols) => {
      if (res.hasOwnProperty(symbols[0])) {
        return {
          ...acc,
          [symbols[0]]: res[symbols[0]].concat(symbols[1]),
        }
      } else {
        return {
          [symbols[0]]: symbols[1],
        }
      }
    }, {})
  }, {})

  return Object.entries(allSymbols).reduce((res: any, s) => {
    return {
      ...res,
      [s[0]]: Array.from(new Set(s[1] as [])).sort(),
    }
  }, {})
}

export const setInitialValue = (columnCount: number): string[] => createArray(columnCount).map(() => '')

export const selectBubble = (
  columnIndex: number,
  symbol: string,
  inputValues: string[],
  setColumnInputValue: (columnIndex: number, value: string) => void,
) => {
  if (inputValues[columnIndex] !== symbol) {
    setColumnInputValue(columnIndex, symbol)
  } else {
    setColumnInputValue(columnIndex, '')
  }
}

export const getMaxSymbolsCount = (symbols: string[][]) => Math.max(...symbols.map(s => s.length))

export const createCellIndex = (rowIndex: number, columnIndex: number) => `${rowIndex}/${columnIndex}`
