import syncIntervalWorkerScript from '@app/helpers/web-workers/syncIntervalWorker'
import { SyncIntervalActions, TimerStatus } from '@app/types'
import { proxy } from 'valtio'

export type AssessmentSyncIntervalType = {
  syncIntervalStatus: TimerStatus
  emittedSyncEvents: number
}

const initialState: AssessmentSyncIntervalType = {
  syncIntervalStatus: TimerStatus.notStarted,
  emittedSyncEvents: 0
}

const syncIntervalWorker = new Worker(syncIntervalWorkerScript)

export const SyncIntervalState = proxy<AssessmentSyncIntervalType>(initialState)

export const startSyncInterval = () => {
  syncIntervalWorker.postMessage({
    type: SyncIntervalActions.start
  })
  SyncIntervalState.syncIntervalStatus = TimerStatus.ongoing
  syncIntervalWorker.onmessage = (event) => {
    if (event.data === SyncIntervalActions.sync) {
      console.log('ASSESSMENT SYNC EVENT DETECTED')
      SyncIntervalState.emittedSyncEvents++
    }
  }
}

export const setSyncIntervalStep = (value: number) => {
  syncIntervalWorker.postMessage({
    type: SyncIntervalActions.changeIntervalStep,
    value
  })
}

export const resumeSyncInterval = () => {
  SyncIntervalState.syncIntervalStatus = TimerStatus.ongoing
  syncIntervalWorker.postMessage({
    type: SyncIntervalActions.start
  })
}

export const stopSyncInterval = () => {
  SyncIntervalState.syncIntervalStatus = TimerStatus.paused
  syncIntervalWorker.postMessage({
    type: SyncIntervalActions.reset
  })
}

export const resetSyncIntervalState = () => {
  stopSyncInterval()
  SyncIntervalState.syncIntervalStatus = initialState.syncIntervalStatus
  SyncIntervalState.emittedSyncEvents = initialState.emittedSyncEvents
}