import { ExtractedImage } from '@app/types'
import { proxy } from 'valtio/vanilla'

type ExtractedImagesType = {
  images: ExtractedImage[]
}

const initialState: ExtractedImagesType = {
  images: [],
}

export const ExtractedImagesState = proxy<ExtractedImagesType>(initialState)

export const setExtractedImages = (extractedImages: ExtractedImage[]) => {
  ExtractedImagesState.images = extractedImages
}

export const resetExtractedImage = () => {
  ExtractedImagesState.images = initialState.images
}
