import { NavigationBubble } from '@app/components/ui/bubbles'
import { CurrentTestState, setCurrentPassage } from '@app/storage'
import { Box, Typography, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { useKeyboardNavigation } from '@app/helpers'
import { PASSAGE_DESCRIBEDBY_ID } from '@app/constants'

const PassageHeader = () => {
  const { passages, currentPassage, currentItemResponse } = useSnapshot(CurrentTestState)
  const [selectedPassageIndex, setSelectedPassageIndex] = useState<number>(null)

  useEffect(() => {
    if (currentItemResponse.passageId) {
      setCurrentPassage(currentItemResponse.passageId, false)
      setSelectedPassageIndex(
        CurrentTestState.passages.findIndex(passage => passage === currentItemResponse.passageId),
      )
    }
  }, [currentItemResponse.passageId])

  const { arrowKeysNavigation, currentIndex, bubblesRef } = useKeyboardNavigation(
    CurrentTestState.passages,
    selectedPassageIndex,
  )

  return (
    <PassageHeaderWrapper component='nav' aria-label='Questions passages'>
      <div id={PASSAGE_DESCRIBEDBY_ID} style={{ display: 'none' }}>
        Use the right and left arrows to move between passages
      </div>
      <Typography component='h2' variant='h5'>
        Passages
      </Typography>
      <PassageItemsWrapper>
        {passages.length &&
          currentPassage &&
          passages.map((passageId, index) => (
            <NavigationBubble
              arrowKeysNavigation={arrowKeysNavigation}
              focused={currentIndex === index}
              aria-label={'Passage ' + (index + 1)}
              aria-describedby={PASSAGE_DESCRIBEDBY_ID}
              ref={ref => (bubblesRef.current[index] = ref)}
              key={passageId}
              number={index + 1}
              onClick={() => setCurrentPassage(passageId)}
              selected={passageId === currentPassage}
            />
          ))}
      </PassageItemsWrapper>
    </PassageHeaderWrapper>
  )
}

const PassageHeaderWrapper = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(3, 4)};
  border-bottom: 1px solid ${theme.palette.grey[100]};
  display: flex;
  align-items: center;
  gap: ${theme.spacing(6)};

  ${[theme.breakpoints.down('mobile')]} {
    padding: ${theme.spacing(2, 3)};
    gap: ${theme.spacing(4)};
  }
`,
)

const PassageItemsWrapper = styled('ul')(
  ({ theme }) => `
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};

    & > li span {
      padding: 0;
    }

    ${[theme.breakpoints.down('mobile')]} {
      gap: ${theme.spacing(2)};
    }
  `,
)

export default PassageHeader
