import 'katex/dist/katex.min.css'

import { FC, isValidElement, ReactElement, useCallback, useEffect, useState } from 'react'
import { getChildDeep } from 'react-nanny'
import { Box, styled } from '@mui/material'
import { renderToStaticMarkup } from 'react-dom/server'
import { MathComponent } from 'mathjax-react'
import { InlineMath } from 'react-katex'

interface MathMLProps {
  itemId: string
  children: JSX.Element
}

enum Encodings {
  katex = 'application/x-tex',
}

export const MathMLComponent: FC<MathMLProps> = ({ itemId, children }) => {
  const [katexFormula, setKatexFormula] = useState('')

  useEffect(() => {
    checkKatex()
    // eslint-disable-next-line
  }, [itemId])

  const getAnnotationNode = useCallback((): ReactElement => {
    return getChildDeep(children, child => {
      if (!isValidElement(child)) {
        return false
      }

      return child.type === 'annotation'
    }) as ReactElement
    // eslint-disable-next-line
  }, [itemId])

  const checkKatex = () => {
    const annotation = getAnnotationNode()

    if (!annotation || annotation?.props?.encoding !== Encodings.katex) {
      return
    }

    if (Array.isArray(annotation?.props?.children) && annotation?.props?.children?.length === 1) {
      setKatexFormula(annotation.props?.children[0])
      return
    }

    if (typeof annotation.props?.children !== 'string') {
      return
    }

    setKatexFormula(annotation.props?.children)
  }

  return (
    <>
      {katexFormula ? (
        <InlineMath math={katexFormula} />
      ) : (
        <MathMLContainer>
          <MathComponent mathml={`<math>${renderToStaticMarkup(children)}<math/>`} />
        </MathMLContainer>
      )}
    </>
  )
}

const MathMLContainer = styled(Box)(() => ({
  display: 'inline',
  '& div': {
    display: 'inherit',
  },
}))
