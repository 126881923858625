import {
  shuffleArray,
  useResponseValidityUpdate,
  useReactiveItemResponse,
  useResponseUpdate,
} from '@app/helpers'
import { CurrentTestState } from '@app/storage'
import {
  Collision,
  CollisionDetection,
  DragOverEvent,
  DragStartEvent,
  DragMoveEvent,
  KeyboardSensor,
  MouseSensor,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'
import {
  ContainersId,
  DragEvents,
  DraggableListChoices,
  MoveChoiceEnum,
  MoveChoiceType,
  UseContainersStateControlType,
  UseDragDropChoiceControlType,
} from './types'
import { getChoiceIndex, getListChoicesIds } from './utils'
import { InteractionType } from '@app/types'

const getInitialChoices = (response: string[], simpleChoices: JSX.Element[], shuffle: string) =>
  shuffleArray(shuffle === 'true', simpleChoices)
    .filter(choice => !response.includes(choice.props.identifier))
    .map(choice => ({
      id: choice.props.identifier,
      element: choice,
    }))

const getInitialSortableChoices = (response: string[], simpleChoices: JSX.Element[]) =>
  response.map(id => ({
    id,
    element: simpleChoices.find(choice => choice.props.identifier === id),
  }))

const transformOrderChoices = (choices: DraggableListChoices[]) => choices.map(choice => choice.id)

export const useContainersStateControl = (
  responseIdentifier: string,
  simpleChoices: JSX.Element[],
  shuffle: string,
  minChoices: number,
  maxChoices: number,
): UseContainersStateControlType => {
  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)
  const itemResponse = useReactiveItemResponse(responseIdentifier)

  const [initialChoices, setInitialChoices] = useState<DraggableListChoices[]>(null)
  const [sortableChoices, setSortableChoices] = useState<DraggableListChoices[]>(null)

  const isValid = useResponseValidityUpdate(
    currentItemResponse?.id,
    sortableChoices?.length || 0,
    minChoices,
    maxChoices,
  )

  useResponseUpdate(
    currentItemResponse?.id,
    responseIdentifier,
    sortableChoices,
    InteractionType.order,
    transformOrderChoices,
    isValid,
  )

  useEffect(() => {
    if (!itemResponse) return

    setInitialChoices(getInitialChoices(itemResponse, simpleChoices, shuffle))
    setSortableChoices(getInitialSortableChoices(itemResponse, simpleChoices))
    // eslint-disable-next-line
  }, [itemResponse, shuffle])

  const moveChoice = (type: MoveChoiceType) => (choiceId: string) => {
    if (currentTestPart?.isFinished) return

    switch (type) {
      case MoveChoiceEnum.toInit: {
        setInitialChoices(state => [...state, sortableChoices.find(choice => choice.id === choiceId)])
        setSortableChoices(state => state.filter(choice => choice.id !== choiceId))
        break
      }
      case MoveChoiceEnum.fromInit: {
        setSortableChoices(state => [...state, initialChoices.find(choice => choice.id === choiceId)])
        setInitialChoices(state => state.filter(choice => choice.id !== choiceId))
        break
      }
    }
  }

  // Reset initial state when response ID changes.
  // const currentResponseRef = useUpdatingRef(itemResponse)
  // const simpleChoicesRef = useUpdatingRef(simpleChoices)

  // useEffect(() => {
  //   setInitialChoices(getInitialChoices(currentResponseRef.current, simpleChoicesRef.current, shuffle))
  //   setSortableChoices(getInitialSortableChoices(currentResponseRef.current, simpleChoicesRef.current))
  // }, [currentItemResponse?.id, currentResponseRef, simpleChoicesRef, shuffle])

  return [initialChoices, sortableChoices, setSortableChoices, moveChoice]
}

export const useDragDropChoiceControl = (
  initialChoices: DraggableListChoices[],
  sortableChoices: DraggableListChoices[],
  setSortableChoices: Dispatch<SetStateAction<DraggableListChoices[]>>,
  moveChoice: (type: MoveChoiceType) => (choiceId: string) => void,
): UseDragDropChoiceControlType => {
  const [activeId, setActiveId] = useState<string | null>()
  const [startContainer, setStartContainer] = useState<string | null>(null)

  const sortingChoices = (dragChoiceId: string, dropId: string) => {
    const sortableChoicesIds = getListChoicesIds(sortableChoices)

    const dragChoiceIndex = getChoiceIndex(sortableChoicesIds, dragChoiceId)
    const dropChoiceIndex = getChoiceIndex(sortableChoicesIds, dropId)

    dragChoiceIndex !== dropChoiceIndex &&
      setSortableChoices(state => arrayMove(state, dragChoiceIndex, dropChoiceIndex))
  }

  const handleDragStart = (e: DragStartEvent) => {
    setActiveId(`${e.active.id}`)
  }

  const handleDragEnd = useCallback(() => {
    setActiveId(null)
    setStartContainer(null)
  }, [])

  const handleCancelDrag = () => {
    const initialChoicesIds = getListChoicesIds(initialChoices)
    const sortableChoicesIds = getListChoicesIds(sortableChoices)

    if (
      !(startContainer === ContainersId.INITIAL_DROP && initialChoicesIds.includes(activeId)) &&
      !(sortableChoicesIds.includes(startContainer) && sortableChoicesIds.includes(activeId))
    ) {
      startContainer === ContainersId.INITIAL_DROP
        ? moveChoice('toInit')(activeId)
        : moveChoice('fromInit')(activeId)
    }

    handleDragEnd()
  }

  const handleDragOver = (e: DragOverEvent) => {
    const dragChoiceId = `${e.active.id}`
    const dropId = `${e.over?.id}`
    const sortableChoicesIds = getListChoicesIds(sortableChoices)
    const initialChoicesIds = getListChoicesIds(initialChoices)
    setStartContainer(state => state || `${e.over.id}`)

    if (!dropId) return

    // Sortable container --> Initial container
    if (dropId === ContainersId.INITIAL_DROP && !initialChoicesIds.includes(dragChoiceId)) {
      moveChoice(MoveChoiceEnum.toInit)(dragChoiceId)
      return
    }

    // Initial container --> Sortable container
    if (
      (dropId === ContainersId.SORTABLE_DROP || sortableChoicesIds.includes(dropId)) &&
      !sortableChoicesIds.includes(dragChoiceId)
    ) {
      moveChoice(MoveChoiceEnum.fromInit)(dragChoiceId)
      sortingChoices(dragChoiceId, dropId)
      return
    }

    // Sorting
    if (
      (startContainer === dragChoiceId || startContainer === ContainersId.INITIAL_DROP) &&
      dropId !== ContainersId.INITIAL_DROP
    ) {
      sortingChoices(dragChoiceId, dropId)
    }
  }

  const activationConstraint = {
    distance: 15,
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor),
  )

  // Custom collision detection algorithm
  const initialDragContainer = useRef<Collision[]>([])
  const started = useRef(false)
  const currentDragEvent = useRef<DragEvents | null>(null)

  useEffect(() => {
    if (!activeId) {
      started.current = false
      initialDragContainer.current = []
    }
  }, [activeId])

  const handleDragMove = (e: DragMoveEvent) => {
    currentDragEvent.current = e.activatorEvent.type as DragEvents
  }

  const customCollisionDetectionAlgorithm: CollisionDetection = args => {
    const pointerCollisions =
      currentDragEvent.current === DragEvents.MOUSE_DOWN ? pointerWithin(args) : rectIntersection(args)
    if (!started.current) {
      initialDragContainer.current = pointerCollisions.filter(({ id }) => id !== activeId)
      started.current = true
    }
    if (pointerCollisions.length > 0) {
      return pointerCollisions
    }
    return initialDragContainer.current
  }
  // Custom collision detection algorithm

  return [
    activeId,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    sortingChoices,
    sensors,
    customCollisionDetectionAlgorithm,
    handleDragMove,
    handleCancelDrag,
  ]
}
