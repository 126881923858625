import { Stack } from '@mui/material'
import { FC, useState } from 'react'
import { VideoModal, VideoThumbnail } from './components'

interface Props {
  videoSrc: string
}

const VideoFeedback: FC<Props> = ({ videoSrc }) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false)

  if (!videoSrc) return null

  return (
    <Stack alignItems='center'>
      <VideoThumbnail
        videoSrc={videoSrc}
        isVideoModalOpen={isVideoModalOpen}
        onClick={() => setIsVideoModalOpen(true)}
      />

      <VideoModal videoSrc={videoSrc} open={isVideoModalOpen} onClose={() => setIsVideoModalOpen(false)} />
    </Stack>
  )
}

export default VideoFeedback
