import { ResultsReport } from '@app/models'
import { BaseType } from '@app/types'
import { BuilderOptions } from 'xml2js'
import { build } from '../xmlTools'

const checkCorrectTypeResponse = (responseValue: string, baseType: BaseType): boolean => {
  switch (baseType) {
    case BaseType.directedPair:
    case BaseType.pair: {
      return responseValue.split(' ').filter(value => value.trim()).length === 2
    }
    default:
      return true
  }
}

const getCorrectResponse = (response: string | string[], baseType: BaseType) => {
  if (Array.isArray(response)) {
    return response.filter(item => item && checkCorrectTypeResponse(item, baseType))
  }

  return response
}

export const parseResultsReport = (resultsReport: ResultsReport): string | null => {
  const options: BuilderOptions = {
    cdata: true,
  }

  return build(
    {
      assessmentResult: {
        context: {
          attributes: {
            sourcedId: resultsReport.context.sourcedId,
          },
          sessionIdentifier: {
            attributes: {
              sourceId: resultsReport.context.sessionIdentifier.sourceId,
              identifier: resultsReport.context.sessionIdentifier.identifier,
            },
          },
        },
        itemResult: resultsReport.itemResult
          .filter(item =>
            item.responseVariable.every(
              res => getCorrectResponse(res.candidateResponse, res.baseType).length > 0,
            ),
          )
          .map(item => ({
            attributes: {
              identifier: item.identifier,
              sequenceIndex: item.sequenceIndex,
              datestamp: item.datestamp,
              sessionStatus: item.sessionStatus,
            },
            responseVariable: item.responseVariable?.map(res => ({
              attributes: {
                identifier: res.identifier,
                cardinality: res.cardinality,
                baseType: res.baseType,
              },
              candidateResponse: {
                value: getCorrectResponse(res.candidateResponse, res.baseType),
              },
            })),
          })),
      },
    },
    options,
  )
}
