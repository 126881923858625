import { FC } from 'react'

interface SimpleAssociableChoiceComponentProps {
  itemId: string
  parentNodeName: string
  identifier: string
  fixed: string
  templateidentifier: string
  showhide: 'show' | 'hide'
  matchmax: number
  matchmin: number
  children: JSX.Element | JSX.Element[]
  accessibilityAttr?: { [key: string]: string }
}

export const SimpleAssociableChoiceComponent: FC<SimpleAssociableChoiceComponentProps> = ({
  parentNodeName,
  children,
  accessibilityAttr,
  ...props
}) => {
  return <span {...accessibilityAttr}>{children}</span>
}
