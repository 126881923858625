import { GraphState, GraphType } from '../types'
import { getFunction } from './utils'

export const validateGraphs = (
  activeGraph: number,
  activeGraphType: GraphType,
  graphs: GraphState[],
  asymptote: number,
): GraphState[] => {
  const y = getFunction(activeGraphType, graphs[activeGraph].points, asymptote)({ x: 0, y: 0 })

  return graphs.map((graph, i) => {
    if (i === activeGraph) {
      return { ...graph, isValid: !isNaN(y) }
    }

    return graph
  })
}
