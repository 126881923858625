import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from '@mui/material'
import { FACE_ICONS, FACE_NAMES } from './constants'
import { ExperienceRating } from './types'
import { getIconColor } from './helpers'

export interface Props {
  selectedRate: ExperienceRating
  onChange: (value: ExperienceRating) => void
  disabled?: boolean
}

const ExperienceRatingScale: React.FC<Props> = ({ selectedRate, onChange, disabled = false }) => {
  const [hovered, setHovered] = useState(false)

  const onFaceClick = (value: ExperienceRating) => {
    if (selectedRate === value) {
      return null
    }

    return value
  }

  return (
    <>
      {FACE_NAMES.map(faceType => (
        <FaceIcon
          key={faceType}
          disabled={disabled}
          hovered={hovered}
          iconName={faceType}
          icon={FACE_ICONS[faceType]}
          isSelected={selectedRate === faceType}
          onClick={() => onChange(onFaceClick(faceType))}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        />
      ))}
    </>
  )
}

const FaceIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: prop => !['hovered', 'isSelected', 'iconName'].includes(prop as string),
})<{
  hovered: boolean
  isSelected: boolean
  iconName: ExperienceRating
  disabled: boolean
}>(({ theme, isSelected, hovered, iconName, disabled = false }) => ({
  fontSize: '28px',
  color: getIconColor(isSelected, iconName, theme.palette),
  transition: 'color 0.5s ease-in-out',
  cursor: 'pointer',
  ...(disabled && { pointerEvents: 'none' }),

  ...(hovered
    ? {
        color: theme.palette.blue[200],

        '&:hover': {
          color: theme.palette.blue[500],
        },
      }
    : {}),
}))

export default ExperienceRatingScale
