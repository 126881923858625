import React from 'react'
import { alertClasses } from '@mui/material'
import { AlertGroupItem, AlertGroupProps } from '@app/components/ui/containers/AlertGroup'
import ReviewIcon from '@app/components/ui/icons/ReviewIcon'
import { ContentLabel, PercentageLabel, StyledAlertGroup, StyledAlertIcon } from './styles'
import { percentageFormatter } from '@app/helpers'
import { FeedbackItem, FeedbackItemMessage } from '@app/types'

export type ItemFeedbackGroupProps = {
  size?: AlertGroupProps['size']
  items?: FeedbackItem[]
  className?: string
  percentageFormatter?: (value: number) => string
}

type ItemFeedbackGroupMessageProps = FeedbackItemMessage & {
  percentageFormatter: (value: number) => string
}

const defaultPercentageFormatter = (percentage: number) => percentageFormatter.format(percentage)

const ItemFeedbackGroupMessage: React.FC<ItemFeedbackGroupMessageProps> = ({
  percentage,
  content,
  percentageFormatter,
}) => {
  return (
    <>
      {percentage !== undefined && (
        <PercentageLabel component='span'>{percentageFormatter(percentage)}</PercentageLabel>
      )}
      <ContentLabel component='span'>{content}</ContentLabel>
    </>
  )
}

export const ItemFeedbackGroup: React.FC<ItemFeedbackGroupProps> = ({
  size,
  items = [],
  className,
  percentageFormatter = defaultPercentageFormatter,
}) => {
  const alertItems: AlertGroupItem[] = items.map(item => ({
    key: item.key,
    severity: item.correct ? 'success' : 'warning',
    icon: false,
    message: item.messages.map((message, index) => (
      <React.Fragment key={message.key}>
        {index === 0 && (
          <StyledAlertIcon className={alertClasses.icon} messagesCount={item.messages?.length}>
            <ReviewIcon correct={item.correct} />
          </StyledAlertIcon>
        )}
        <ItemFeedbackGroupMessage {...message} percentageFormatter={percentageFormatter} />
      </React.Fragment>
    )),
  }))

  return (
    <StyledAlertGroup
      severity='info'
      variant='outlined'
      size={size}
      items={alertItems}
      className={className}
    />
  )
}
