import { focusStyle } from '@app/theme'
import { Box, styled, Theme, Typography } from '@mui/material'
import { FC, KeyboardEventHandler } from 'react'

interface SpecialCharacterBubbleProps {
  character: string
  selected: boolean
  onClick: () => void
  onKeyDown: KeyboardEventHandler
}

export const SpecialCharacterBubble: FC<SpecialCharacterBubbleProps> = ({
  character,
  selected,
  onClick,
  onKeyDown,
}) => {
  return (
    <SpecialCharacterBubbleWrapper tabIndex={0} selected={selected} onClick={onClick} onKeyDown={onKeyDown}>
      <StyledTypography variant='body2'>{character}</StyledTypography>
    </SpecialCharacterBubbleWrapper>
  )
}

interface SpecialCharacterBubbleWrapperProps {
  selected: boolean
}

const selectedStyle = (theme: Theme) => ({
  borderColor: theme.palette.blue[400],
  backgroundColor: theme.palette.blue[100],
  outline: `1px solid ${theme.palette.blue[400]}`,
})

const SpecialCharacterBubbleWrapper = styled(Box)<SpecialCharacterBubbleWrapperProps>(
  ({ theme, selected }) => ({
    width: '34px',
    height: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0.5),
    border: `1px solid transparent`,
    borderRadius: theme.shape.borderRadius * 1.5,
    cursor: 'pointer',

    ':hover': {
      borderColor: theme.palette.blue[400],
      backgroundColor: theme.palette.blue[50],
    },

    ':focus': {
      ...focusStyle(theme, selected ? 2 : 1).border,
    },

    ...(selected ? selectedStyle(theme) : {}),
  }),
)

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}))
