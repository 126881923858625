import { AssessmentItemResponse, ResponseDeclaration } from '@app/models'
import { CurrentTestState } from '@app/storage'
import { ChangeCountingVariantsType, CountingVarinatsState, IUseCountingVariants } from '@app/types'
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'
import { calculateSVGScale } from '../utils'

/**
 * Hook to retreive current item response declaration.
 * @param { String } id current item response id.
 * @param { String } responseIdentifier id of response declaration.
 * @returns { ResponseDeclaration } response declaration.
 */
export const useResponseDeclaration = (id: string, responseIdentifier: string): ResponseDeclaration => {
  const { testPartItems, itemsResponses } = useSnapshot(CurrentTestState)
  const [responseDeclaration, setResponseDeclaration] = useState<ResponseDeclaration>(null)

  useEffect(() => {
    if (!testPartItems.length || !itemsResponses.length || !id || !responseIdentifier) {
      return
    }

    const itemResponse = CurrentTestState.itemsResponses.find(item => item.id === id)
    const currentItem = CurrentTestState.testPartItems.find(item => item.id === itemResponse?.itemId)
    const declaration = currentItem?.responseDeclaration.find(decl => decl.id === responseIdentifier)

    setResponseDeclaration(declaration)
  }, [testPartItems.length, itemsResponses.length, id, responseIdentifier])

  return responseDeclaration
}

/**
 * Hook to create a ref that updates it's value.
 * @param value Value to watch.
 * @returns React ref.
 */
export const useUpdatingRef = <T = any>(value: T): MutableRefObject<T> => {
  const valueRef = useRef(value)

  useEffect(() => {
    valueRef.current = value
  }, [value])

  return valueRef
}

/**
 * Hook to delay value definition.
 * @param value Value that need to delay.
 * @param delay Time delaying (default timing 250 ms).
 * @returns string value.
 */
export const useDebouncing = (value: string, delay: number = 250): string => {
  const [debouncedValue, setDebouncedValue] = useState<string>(value)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [value, delay])
  return debouncedValue
}

/**
 * Bubbles keyboard navigation hook
 * @param navigationItems List of items.
 * @param navigationArea Navigation area for current index calculations.
 * @param isOpenModal Opened modal flag.
 * @param isQuestionsList Flag for turning current response index dependency.
 * @returns key handler, current bubble index, bubble ref.
 */
export const useKeyboardNavigation = (
  navigationItems: AssessmentItemResponse[] | string[],
  selectedItemIndex: number,
  isOpenModal?: boolean,
) => {
  const [currentIndex, setCurrentIndex] = useState<number>(null)
  const bubblesRef = useRef(navigationItems.map(_ => null))

  useEffect(() => {
    if (selectedItemIndex === null) return
    setCurrentIndex(selectedItemIndex)
  }, [selectedItemIndex])

  useEffect(() => {
    const currentRef: HTMLElement = bubblesRef.current[currentIndex]

    if (currentRef && (bubblesRef.current.some(ref => document.activeElement === ref) || isOpenModal)) {
      currentRef.focus()
    }
  }, [currentIndex, isOpenModal])

  const arrowKeysNavigation = (e: React.KeyboardEvent) => {
    if (e.code === 'ArrowRight') {
      setCurrentIndex(prevIndex => {
        if (!prevIndex) return prevIndex + 1
        return prevIndex + 1 > navigationItems.length - 1 ? prevIndex : prevIndex + 1
      })
    }

    if (e.code === 'ArrowLeft') {
      setCurrentIndex(prevIndex => {
        if (!prevIndex) return 0
        return prevIndex - 1 < 0 ? 0 : prevIndex - 1
      })
    }

    if (e.code === 'Tab' && isOpenModal) {
      e.preventDefault()
    }
  }
  return { arrowKeysNavigation, currentIndex, bubblesRef }
}

/**
 * Handle clicks outside of target element.
 * @param selector Selector of the target element.
 * @param callback Callback for outside clicks.
 */
export const useOutsideClick = (selector: string, callback: () => any) => {
  const handleOutsideClick = useCallback(
    (event: any) => {
      if (!event.target.closest(selector)) {
        callback()
      }
    },
    [selector, callback],
  )

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)

    return () => document.removeEventListener('click', handleOutsideClick)
  }, [handleOutsideClick])
}

/**
 * Hook for counting quantity of uses variants.
 * @param variants JSX.Element array with answer variants.
 * @returns array with controls of counting quantity of uses variants.
 */
export const useCountingVariants = (
  id: string,
  initialCountingVariants: CountingVarinatsState,
): IUseCountingVariants => {
  const [countingVariants, setCountingVariants] = useState<CountingVarinatsState>(initialCountingVariants)

  useEffect(() => {
    if (!initialCountingVariants) return
    setCountingVariants(initialCountingVariants)
  }, [id, initialCountingVariants])

  const changeCountingVariants = (type: ChangeCountingVariantsType) => (variantId: string[] | null) => {
    setCountingVariants(state =>
      variantId && state
        ? variantId.reduce(
            (res: CountingVarinatsState, id) => ({
              ...res,
              [id]: {
                ...state[id],
                matchCount:
                  state[id]?.matchMax &&
                  (type === ChangeCountingVariantsType.add
                    ? state[id].matchCount + 1
                    : state[id].matchCount - 1),
              },
            }),
            state,
          )
        : state,
    )
  }

  return [countingVariants, changeCountingVariants]
}

export const useTestPartFinishedCheck = (): boolean => {
  const { currentTestPart } = useSnapshot(CurrentTestState)

  return currentTestPart?.isFinished
}

export const useSVGScale = (childRef: MutableRefObject<Element>) => {
  const [svgScale, setSvgScale] = useState([1, 1])

  useEffect(() => {
    if (!childRef.current) {
      return
    }

    setSvgScale(calculateSVGScale(childRef.current.closest('svg')))
  }, [childRef])

  return svgScale
}
