import { forwardRef, HTMLAttributes } from 'react'
import { styled, useMediaQuery, useTheme } from '@mui/material'
import { scrollBarMixinObj } from '@app/constants'

const StyledTouchWrapper = styled<any>('div')(({ theme, width, height }) => ({
  [`${theme.breakpoints.down('mobile')}, (hover: none)`]: {
    overflowX: 'auto',
    overflowY: 'hidden',
    ...scrollBarMixinObj(theme, 'horizontal'),
  },

  '& .svg-container': {
    [`${theme.breakpoints.down('mobile')}, (hover: none)`]: {
      touchAction: 'initial !important',
      minWidth: width,
      minHeight: height,
    },
  },
}))

export const TouchWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & { width?: number; height?: number }
>((props, ref) => {
  const theme = useTheme()
  const isTouchDevice = useMediaQuery(`(max-width: ${theme.breakpoints.values.mobile}px), (hover: none)`)

  if (isTouchDevice) {
    return <StyledTouchWrapper ref={ref} {...props} />
  }

  return <>{props.children}</>
})
