import React from 'react'
import { Tooltip } from '@mui/material'
import { ReviewBadgeProps } from '@app/components/ui/badges/ReviewBadge'
import { StyledReviewBadge, StyledFocusableReviewIcon, StyledTooltipBase } from './styles'
import { ItemFeedback } from '../ItemFeedback'

export type TooltipFeedbackProps = ReviewBadgeProps & {
  correctAnswer?: React.ReactNode
}

const POPPER_PROPS = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [8, -8],
      },
    },
  ],
}

const SLOTS = { popper: StyledTooltipBase }

export const TooltipFeedback: React.FC<React.PropsWithChildren<TooltipFeedbackProps>> = ({
  children,
  correct,
  correctAnswer,
  ...rest
}) => {
  if (correct === undefined) {
    return <>{children}</>
  }

  if (correct) {
    return <StyledReviewBadge correct={correct} {...rest}>{children}</StyledReviewBadge>
  }

  return (
    <StyledReviewBadge
      correct={correct}
      badgeContent={(
        <Tooltip
          title={(
            <ItemFeedback
              size="sm"
              correct={correct}
              correctAnswer={correctAnswer}
              showScoreLabel={false}
              showIcon={false}
            />
          )}
          slots={SLOTS}
          placement='right-end'
          PopperProps={POPPER_PROPS}
        >
          <StyledFocusableReviewIcon tabIndex={0} correct={correct} />
        </Tooltip>
      )}
      {...rest}
    >
      {children}
    </StyledReviewBadge>
  )
}
