import { FC } from 'react'
import { styled } from '@mui/material'
import { RemoveButton } from '@app/components/ui'
import { DND_ELEMENT_ATTRIBUTES } from '@app/constants'
import { OrderItemProps } from './OrderItem'
import { DraggableOrderItem } from './DraggableOrderItem'

export type GraphicOrderItemProps = OrderItemProps & {
  removable?: boolean
  onRemove?: () => void
}

export const GraphicOrderItem: FC<GraphicOrderItemProps> = props => {
  const { id, removable, onRemove, ...rest } = props

  const element = <StyledDraggableOrderItem id={id} {...DND_ELEMENT_ATTRIBUTES} {...rest} />

  if (removable && !props.dragging) {
    return (
      <RemoveButton onRemove={onRemove} wrapper={RemovableWrapper} selected={props.selected}>
        {element}
      </RemoveButton>
    )
  }

  return element
}

const StyledDraggableOrderItem = styled(DraggableOrderItem)(({ placed }) => ({
  ...(placed && {
    zIndex: 1,
    position: 'relative',
  }),
}))

const RemovableWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
}))
