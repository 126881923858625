import { AssessmentSettings, AssessmentStatus } from '@app/firebase/api'
import { AssessmentItem, AssessmentTest } from '@app/models'
import { AssessmentStylesheet, ExtractedImage } from '@app/types'
import { openDB } from 'idb'

export type QtiPackageDBType = {
  version: number
  id: string
  tests: AssessmentTest[]
  items: AssessmentItem[]
  stylesheets: AssessmentStylesheet[]
  images: ExtractedImage[],
  passedTime: number,
  settings: AssessmentSettings,
  status: AssessmentStatus
}

export type QtiPackageDBActions = {
  add: (id: string, data: QtiPackageDBType) => Promise<void>
  put: (id: string, data: QtiPackageDBType) => Promise<IDBValidKey>
  get: (id: string) => Promise<QtiPackageDBType>
  patch: (id: string, data: Partial<QtiPackageDBType>) => Promise<IDBValidKey>
  getAllPackages: () => Promise<QtiPackageDBType[]>
  deletePackage: (id: string) => Promise<unknown>
}

const DB_NAME = 'QTI_Packages_DB'
const OBJECT_STORE_NAME = 'Packages'

export const initQTIPackagesDB = async (): Promise<QtiPackageDBActions> => {
  const QTIPackagesDB = await openDB(DB_NAME, 1, {
    upgrade(database) {
      database.createObjectStore(OBJECT_STORE_NAME)
    },
  })

  const add = async (id: string, data: QtiPackageDBType) => {
    try {
      await QTIPackagesDB.add(OBJECT_STORE_NAME, data, id)
    } catch (err) {
      console.log('ADD IndexedDB Error', err)
    }
  }

  const put = async (id: string, data: QtiPackageDBType) => {
    try {
      return await QTIPackagesDB.put(OBJECT_STORE_NAME, data, id)
    } catch (err) {
      console.log('PUT IndexedDB Error', err)
    }
  }

  const patch = async (id: string, data: Partial<QtiPackageDBType>) => {
    try {
      const qtiPackage = await get(id)
      return await QTIPackagesDB.put(OBJECT_STORE_NAME, {
        ...qtiPackage,
        ...data
      }, id)
    } catch (err) {
      console.log('PATCH IndexedDB Error', err)
    }
  }

  const get = async (id: string): Promise<QtiPackageDBType> => {
    try {
      return await QTIPackagesDB.get(OBJECT_STORE_NAME, id)
    } catch (err) {
      console.log('GET IndexedDB Error', err)
    }
  }

  const getAllPackages = async (): Promise<QtiPackageDBType[]> => {
    try {
      return await QTIPackagesDB.getAll(OBJECT_STORE_NAME)
    } catch (err) {
      console.log('GET_ALL IndexedDB Error', err)
    }
  }

  const deletePackage = async (id: string) => {
    try {
      return await QTIPackagesDB.delete(OBJECT_STORE_NAME, id)
    } catch (err) {
      console.log('GET_ALL IndexedDB Error', err)
    }
  }

  return {
    add,
    put,
    get,
    patch,
    getAllPackages,
    deletePackage,
  }
}
