import { createContext, FC, useContext, useMemo, KeyboardEvent } from 'react'
import { DndContext, DragOverlay, KeyboardSensor, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { Box } from '@mui/material'
import { VariantsContainerComponent } from '@app/components/ui/containers'
import {
  BasicDragContainerComponent,
  DragContainerComponent,
} from '@app/components/ui/containers/DragContainer'
import {
  generateAnnouncement,
  generateHumanReadableIds,
  getRestChildren,
  useDragDropMatch,
  useFindNodes as findNodes,
} from '@app/helpers'
import { DropContainerScoring, DropContainerState, IClickMovingState, InteractionType } from '@app/types'
import { DomNodes, FREE_VARIANTS_CONTAINER_ID } from '@app/constants'
import { addUniqPartId, cutUniqPartId } from '@app/helpers/utils'

interface GapMatchInteractionComponentProps {
  itemId: string
  shuffle: string
  minassociations: string
  maxassociations: string
  responseidentifier: string
  children: JSX.Element | JSX.Element[]
  accessibilityAttr?: { [key: string]: string }
}

interface IGapContext {
  fluidGaps: boolean
  containers: DropContainerState
  scoredContainers: DropContainerScoring
  variants: JSX.Element[]
  isFinished: boolean
  isMaxMatches: boolean
  handleRemove: (itemId: string, containerId: string) => () => void
  clickMovingState: IClickMovingState
  containersArray: string[]
  variantsArray: string[]
  groupMode: boolean
  groupStatistics?: Record<string, number>
}

const GapContext = createContext<IGapContext>({
  fluidGaps: false,
  containers: {},
  scoredContainers: {},
  variants: [],
  isFinished: false,
  isMaxMatches: false,
  handleRemove: (itemId: string, containerId: string) => () => {},
  clickMovingState: { activeGapId: null, activeGapBlockId: null, activeBlockId: null },
  containersArray: [],
  variantsArray: [],
  groupMode: false,
})

const transformToResponse = (value: any) => {
  return value
    ? Object.entries(value)
        .filter(item => item[1])
        .map(item => `${item[0]} ${item[1]}`)
    : []
}

export const GapMatchInteractionComponent: FC<GapMatchInteractionComponentProps> = props => {
  const {
    minassociations: minAssociations = '0',
    maxassociations: maxAssociations = '0',
    responseidentifier: responseIdentifier,
    children,
    accessibilityAttr,
  } = props

  const [[prompt, gapText, gap], restNodes] = useMemo(
    () => [
      [...findNodes(children, [DomNodes.prompt, DomNodes.gapText, DomNodes.gap])],
      getRestChildren(children, [DomNodes.prompt, DomNodes.gapText, DomNodes.gapImg]),
    ],
    [children],
  )
  const variants = useMemo(() => gapText, [gapText])
  const dropContainers = useMemo(() => gap.map(g => g.props.identifier as string), [gap])

  const activationConstraint = {
    distance: 15,
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor),
  )

  const {
    activeId,
    containers,
    scoredContainers,
    isFinished,
    isMaxMatches,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleRemove,
    isDisabledVariant,
    clickMovingState,
    resetDrag,
    groupMode,
    groupStatistics,
  } = useDragDropMatch(
    responseIdentifier,
    variants,
    dropContainers,
    Number(minAssociations),
    Number(maxAssociations),
    InteractionType.gapMatch,
    transformToResponse,
  )

  const gapContextValue = useMemo(
    () => ({
      fluidGaps: true,
      containers,
      scoredContainers,
      variants,
      isFinished,
      isMaxMatches,
      handleRemove,
      clickMovingState,
      containersArray: dropContainers,
      variantsArray: Object.values(variants).map(item => item.props.identifier),
      groupMode,
      groupStatistics,
    }),
    [
      containers,
      scoredContainers,
      variants,
      isFinished,
      isMaxMatches,
      handleRemove,
      clickMovingState,
      dropContainers,
      groupMode,
      groupStatistics,
    ],
  )

  const announcements = generateAnnouncement(
    generateHumanReadableIds(variants),
    generateHumanReadableIds(gap),
    false,
    [[FREE_VARIANTS_CONTAINER_ID, 'Variants container']],
  )

  const dragContainerKeyDownHandler = (event: KeyboardEvent<HTMLDivElement>) =>
    event.key === 'Escape' && resetDrag()

  return (
    <div {...accessibilityAttr}>
      {prompt}
      <GapContext.Provider value={gapContextValue}>
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          accessibility={{ announcements }}
        >
          <VariantsContainerComponent>
            {variants.map((variant, idx) => {
              const id = variant.props.identifier

              return (
                <Box onKeyDown={dragContainerKeyDownHandler}>
                  <DragContainerComponent
                    id={addUniqPartId(id)}
                    containerId={FREE_VARIANTS_CONTAINER_ID}
                    drag={id === cutUniqPartId(activeId)}
                    disabled={isDisabledVariant(id)}
                    selected={clickMovingState.activeBlockId === id && !clickMovingState.activeGapId}
                    variantIndex={idx}
                    roleAttr='listitem'
                    fluid
                  >
                    {variant.props.children}
                  </DragContainerComponent>
                </Box>
              )
            })}
          </VariantsContainerComponent>
          {restNodes}
          <DragOverlay dropAnimation={null}>
            {activeId && (
              <BasicDragContainerComponent placehold fluid>
                <>{variants.find(v => v.props.identifier === cutUniqPartId(activeId))}</>
              </BasicDragContainerComponent>
            )}
          </DragOverlay>
        </DndContext>
      </GapContext.Provider>
    </div>
  )
}

export const useGapContextValue = () => useContext(GapContext)
