import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { getInteractionAndInfoControl } from '@app/helpers'
import { AssessmentItem } from '@app/models'
import { CurrentTestState } from '@app/storage'

export const useAssessmentItem = () => {
  const { currentItemResponse, testPartItems } = useSnapshot(CurrentTestState)
  const [currentItem, setCurrentItem] = useState<AssessmentItem>(null)
  const [interaction, setInteraction] = useState('')
  const [infoControl, setInfoControl] = useState('')
  const [hasInlineInteractions, setHasInlineInteractions] = useState<boolean>(false)

  useEffect(() => {
    if (!testPartItems.length || !currentItemResponse.itemId) {
      return
    }

    const item = CurrentTestState.testPartItems.find(
      item => item.id === CurrentTestState.currentItemResponse?.itemId,
    )
    const [infoControl, interaction] = getInteractionAndInfoControl(item.itemBody, 'infoControl')

    const hasInlineInteractions = interaction.search(
      new RegExp(/(textentryinteraction|inlinechoiceinteraction)/i)
    )
    setHasInlineInteractions(hasInlineInteractions > -1)

    setCurrentItem(item)
    setInfoControl(infoControl)
    setInteraction(interaction)
  }, [testPartItems.length, currentItemResponse?.itemId])

  return { currentItem, interaction, infoControl, hasInlineInteractions }
}
