import { useCallback, useEffect, useRef, useState } from 'react'
import rangy from 'rangy'
import 'rangy/lib/rangy-classapplier'
import 'rangy/lib/rangy-textrange'
import 'rangy/lib/rangy-highlighter'
import './highlighter.d'

export const createHighlighter = (className: string, tagName = 'mark'): Highlighter => {
  const highlighter = rangy.createHighlighter()

  highlighter.addClassApplier(
    rangy.createClassApplier(className, {
      ignoreWhiteSpace: true,
      elementTagName: tagName,
      tagNames: [tagName],
    }),
  )

  return highlighter
}

export const useHighlighter = (className: string, key: string, containerId: string) => {
  const highlighterRef = useRef<Highlighter>(createHighlighter(className))
  const [hasHighlights, setHasHighlights] = useState<boolean>(false)

  const onRestoreHighlights = useCallback(() => {
    const highlighter = highlighterRef.current

    highlighter.removeAllHighlights()

    const serializedHighlight = localStorage.getItem(key)
    setHasHighlights(!!serializedHighlight)

    if (!serializedHighlight) return

    setTimeout(() => highlighter.deserialize(serializedHighlight))
  }, [key])

  useHighlightsRestore(containerId, onRestoreHighlights)

  const onHighlightSelection = useCallback(() => {
    const selection = rangy.getSelection()

    // Collapsed selection is empty.
    if (selection.isCollapsed) return

    // If selection is not within specified container, it's not created.
    if (!selection.containsNode(document.getElementById(containerId), true)) {
      return
    }

    const highlighter = highlighterRef.current

    highlighter.highlightSelection(className, {
      containerElementId: containerId,
    })

    setHasHighlights(true)

    const serialized = highlighter.serialize()
    localStorage.setItem(key, serialized)
    selection.removeAllRanges()
  }, [key, className, containerId])

  const onRemoveHighlight = useCallback(() => {
    const selection = rangy.getSelection()
    const highlighter = highlighterRef.current

    highlighter.removeHighlightsFromSelection(containerId)

    const serialized = highlighter.serialize()
    localStorage.setItem(key, serialized)
    selection.removeAllRanges()
  }, [containerId, key])

  const onResetHighlight = useCallback(() => {
    highlighterRef.current.removeAllHighlights()
    setHasHighlights(false)
    localStorage.removeItem(key)
  }, [key])

  const isSelectionHighlighted = useCallback(() => {
    if (!window.getSelection().anchorNode) return false

    const highlight = highlighterRef.current.getHighlightForElement(
      window.getSelection().anchorNode.parentElement,
    )

    return !!highlight
  }, [])

  return {
    hasHighlights,
    onHighlightSelection,
    onResetHighlight,
    onRemoveHighlight,
    isSelectionHighlighted,
  }
}

export const useHighlightsRestore = (containerId: string, onRestore: () => void) => {
  useEffect(() => {
    const container = document.getElementById(containerId)

    onRestore()

    const observer = new MutationObserver((_mutations, observer) => {
      observer.disconnect()
      onRestore()
    })
    observer.observe(container, { attributes: false, childList: true, subtree: true })

    return () => observer.disconnect()
  }, [containerId, onRestore])
}
