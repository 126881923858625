import { FC } from 'react'
import { styled, Typography } from '@mui/material'

interface BlockQuoteComponentProps {
  cite: string
  children: JSX.Element | JSX.Element[]
  accessibilityAttr: { [key: string]: string }
}

export const BlockQuoteComponent: FC<BlockQuoteComponentProps> = ({
  children,
  accessibilityAttr,
  ...props
}) => {
  return (
    <StyledBlockQuote {...accessibilityAttr}>
      <Typography variant={'body1'} component={'p'}>
        {children}
      </Typography>
    </StyledBlockQuote>
  )
}

const StyledBlockQuote = styled('blockquote')(
  ({ theme }) => `
    padding: ${theme.spacing(4)};
    margin: ${theme.spacing(6, 0)};
    border-radius: ${theme.shape.borderRadius * 1.5}px;
    border: 1px solid ${theme.palette.grey[200]};
    
    ${[theme.breakpoints.down('mobile')]} {
      padding: ${theme.spacing(3)};
      margin: ${theme.spacing(4, 0)};
    }
  `,
)
