import { FC } from 'react'

interface FeedbackInlineProps {
  identifier?: string
  children?: JSX.Element
  accessibilityAttr?: { [key: string]: string }
}

export const FeedbackInline: FC<FeedbackInlineProps> = props => {
  return <></>
}
