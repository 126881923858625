import { styled } from '@mui/material'
import { FC, SVGProps } from 'react'
import { Marker } from '../../GraphingInteraction/components'
import { IntervalTypeProps, IntervalDirection, IntervalType } from '../types'

export interface IntervalIconProps extends IntervalTypeProps {
  width?: number
  height?: number
  strokeWidth?: number
  className?: string
}

// TODO: check if can use "WithMarkers" component.
export const IntervalIcon: FC<IntervalIconProps> = props => {
  const { width = 54, height = 14, strokeWidth = 2, type, direction, className } = props

  const cHeight = height / 2
  const markerWidth = height
  const markerHeight = height
  const pointRadius = cHeight
  const startArrowPoints = `${markerWidth} 0, ${markerWidth} ${markerHeight}, 0 ${cHeight}`
  const endArrowPoints = `0 0, ${markerWidth} ${cHeight}, 0 ${markerHeight}`

  return (
    <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height} className={className}>
      <defs>
        {direction === IntervalDirection.LEFT && (
          <Marker
            id='intervalStartArrow'
            width={markerWidth}
            height={markerHeight}
            markerUnits='userSpaceOnUse'
          >
            <IntervalDirectionPointer points={startArrowPoints} />
          </Marker>
        )}
        {direction === IntervalDirection.RIGHT && (
          <Marker
            id='intervalEndArrow'
            width={markerWidth}
            height={markerHeight}
            markerUnits='userSpaceOnUse'
          >
            <IntervalDirectionPointer points={endArrowPoints} />
          </Marker>
        )}
      </defs>

      <IntervalPoint
        type={type}
        r={pointRadius - strokeWidth / 2}
        cx={direction === IntervalDirection.LEFT ? width - pointRadius : pointRadius}
        cy={cHeight}
        strokeWidth={strokeWidth}
        vectorEffect='non-scaling-stroke'
      />
      <IntervalDirectionLine
        x1={direction === IntervalDirection.LEFT ? pointRadius : markerWidth}
        y1={cHeight}
        x2={direction === IntervalDirection.LEFT ? width - markerWidth : width - pointRadius}
        y2={cHeight}
        strokeWidth={strokeWidth}
        vectorEffect='non-scaling-stroke'
        markerStart={direction === IntervalDirection.LEFT ? 'url(#intervalStartArrow)' : undefined}
        markerEnd={direction === IntervalDirection.RIGHT ? 'url(#intervalEndArrow)' : undefined}
      />
    </svg>
  )
}

const IntervalPoint = styled(({ type, ...props }: SVGProps<SVGCircleElement> & { type: IntervalType }) => (
  <circle {...props} />
))(({ theme, type, strokeWidth }) => ({
  fill: type === IntervalType.CLOSED ? theme.palette.grey[400] : 'none',
  stroke: theme.palette.grey[900],
  strokeWidth,
}))
const IntervalDirectionLine = styled('line')(({ theme, strokeWidth }) => ({
  stroke: theme.palette.grey[400],
  strokeWidth,
}))
const IntervalDirectionPointer = styled('polygon')(({ theme }) => ({
  fill: theme.palette.grey[400],
  stroke: 'none',
}))
