import { useCallback, useEffect, useRef, useState } from 'react'
import { DEFAULT_FONT_SIZE_CONTROL_OPTIONS } from './constants'
import { FontSizeControlOptions, UseFontSizeControl } from './types'

export const useFontSizeControl = (options?: FontSizeControlOptions): UseFontSizeControl => {
  const {
    minValue = DEFAULT_FONT_SIZE_CONTROL_OPTIONS.minValue,
    maxValue = DEFAULT_FONT_SIZE_CONTROL_OPTIONS.maxValue,
    step = DEFAULT_FONT_SIZE_CONTROL_OPTIONS.step,
    defaultValue = DEFAULT_FONT_SIZE_CONTROL_OPTIONS.defaultValue,
  } = options ?? {}

  const [value, setValue] = useState<number>(defaultValue)

  useEffect(() => {
    document.documentElement.setAttribute('style', `font-size: ${value}px;`)

    return () => document.documentElement.setAttribute('style', `font-size: ${defaultValue}px;`)
  }, [value, defaultValue])

  const onDecrease = () => {
    const nextValue = value - step
    minValue ? nextValue >= minValue && setValue(nextValue) : setValue(nextValue)
  }
  const onIncrease = () => {
    const nextValue = value + step
    maxValue ? nextValue <= maxValue && setValue(nextValue) : setValue(nextValue)
  }

  return { value, onDecrease, onIncrease, options }
}

enum AnnouncementTypes {
  increase = 'increase',
  decrease = 'decrease',
  max = 'max',
  min = 'min'
}

export const useFontChangeAnnouncement = (currSize: number, options: FontSizeControlOptions) => {
  const [announcement, setAnnouncement] = useState('')
  const prevValue = useRef<number>(null)

  const generateAnnouncement = (type: AnnouncementTypes) => {
    switch (type) {
      case AnnouncementTypes.max: {
        return `You have reached maximum font size.`
      }
      case AnnouncementTypes.min: {
        return `You have reached minimum font size.`
      }
      case AnnouncementTypes.increase: {
        return 'Font size increased.'
      }
      case AnnouncementTypes.decrease: {
        return 'Font size decreased'
      }
    }
  }

  const changedValueComparator = useCallback((curr: number, prev: number) => {
    switch(curr) {
      case options?.maxValue: {
        setAnnouncement(generateAnnouncement(AnnouncementTypes.max))
        return
      }
      case options?.minValue: {
        setAnnouncement(generateAnnouncement(AnnouncementTypes.min))
        return
      }
      default: {
        if (curr > prev) {
          setAnnouncement(generateAnnouncement(AnnouncementTypes.increase))
        } else if (curr < prev) {
          setAnnouncement(generateAnnouncement(AnnouncementTypes.decrease))
        }
        return
      }
    }
  }, [options?.minValue, options?.maxValue])


  useEffect(() => {
    if (!currSize || !options?.defaultValue) {
      return
    }

    if (currSize === options?.defaultValue && !prevValue.current) {
      prevValue.current = options?.defaultValue
      return
    }

    changedValueComparator(currSize, prevValue.current)
    prevValue.current = currSize

  }, [currSize, options?.defaultValue, changedValueComparator])

  return announcement
}
