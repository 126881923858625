import { ExperienceRating } from "./types"
import { faFacePouting } from '@fortawesome/pro-solid-svg-icons/faFacePouting'
import { faFaceFrown } from '@fortawesome/pro-solid-svg-icons/faFaceFrown'
import { faFaceMeh } from '@fortawesome/pro-solid-svg-icons/faFaceMeh'
import { faFaceSmile } from '@fortawesome/pro-solid-svg-icons/faFaceSmile'
import { faFaceGrin } from '@fortawesome/pro-solid-svg-icons/faFaceGrin'

export const FACE_NAMES: ExperienceRating[] = [
  "pouting",
  "frown",
  "meh",
  "smile",
  "grin"
]

export const FACE_ICONS = {
  "pouting": faFacePouting,
  "frown": faFaceFrown,
  "meh": faFaceMeh,
  "smile": faFaceSmile,
  "grin": faFaceGrin
}
