import { Box, ButtonGroup, IconButton, styled } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  DraggableListChoices,
  MoveChoiceEnum,
  MoveChoiceType,
  OrientationEnum,
  OrientationType,
} from '../types'
import { getChoiceIndex, getListChoicesIds } from '@app/components/interactions/OrderInteraction/utils'
import {
  DATA_CLICK_AWAY_PROPS,
  MOVE_DOWN_BTN_ATTRIBUTES,
  MOVE_FROM_INIT_ATTRIBUTES,
  MOVE_TO_INIT_ATTRIBUTES,
  MOVE_UP_BTN_ATTRIBUTES,
} from '@app/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp'

interface SortingButtonsProps {
  disabled: boolean
  orientation?: OrientationType
  clickHandlerUp: () => void
  clickHandlerDown: () => void
  sortableChoices: DraggableListChoices[]
  selectedChoiceId: string
}

interface TransferButtonProps {
  disabled: boolean
  direction: MoveChoiceType
  orientation?: OrientationType
  clickHandler: () => void
}

export const SortingButtons: FC<SortingButtonsProps> = props => {
  const {
    disabled,
    orientation = OrientationEnum.vertical,
    clickHandlerUp,
    clickHandlerDown,
    sortableChoices,
    selectedChoiceId,
  } = props

  const [dragIndex, setDragIndex] = useState<number>(null)

  useEffect(() => {
    if (!sortableChoices || !selectedChoiceId) return
    setDragIndex(getChoiceIndex(getListChoicesIds(sortableChoices), selectedChoiceId))
  }, [sortableChoices, selectedChoiceId])

  return (
    <StyledButtonGroup customOrientation={orientation} orientation={orientation} {...DATA_CLICK_AWAY_PROPS}>
      <IconButton
        disabled={disabled || !dragIndex}
        onClick={clickHandlerUp}
        {...MOVE_UP_BTN_ATTRIBUTES}
        aria-label='Move up'
      >
        {orientation === OrientationEnum.horizontal ? (
          <ArrowIcon icon={faArrowLeft} />
        ) : (
          <ArrowIcon icon={faArrowUp} />
        )}
      </IconButton>
      <IconButton
        disabled={disabled || dragIndex === sortableChoices.length - 1}
        onClick={clickHandlerDown}
        {...MOVE_DOWN_BTN_ATTRIBUTES}
        aria-label='Move down'
      >
        {orientation === OrientationEnum.horizontal ? (
          <ArrowIcon icon={faArrowRight} />
        ) : (
          <ArrowIcon icon={faArrowDown} />
        )}
      </IconButton>
    </StyledButtonGroup>
  )
}

export const TransferButton: FC<TransferButtonProps> = props => {
  const { disabled, direction, clickHandler, orientation = OrientationEnum.vertical } = props
  return (
    <TransferButtonWrapper {...DATA_CLICK_AWAY_PROPS}>
      <IconButton
        disabled={disabled}
        onClick={clickHandler}
        {...(direction === MoveChoiceEnum.toInit ? MOVE_TO_INIT_ATTRIBUTES : MOVE_FROM_INIT_ATTRIBUTES)}
        aria-label={
          direction === MoveChoiceEnum.toInit ? 'Move to initial container' : 'Move to sortable container'
        }
      >
        {orientation === OrientationEnum.vertical ? (
          <>
            {direction === MoveChoiceEnum.toInit ? (
              <ArrowIcon icon={faArrowLeft} />
            ) : (
              <ArrowIcon icon={faArrowRight} />
            )}
          </>
        ) : (
          <>
            {direction === MoveChoiceEnum.toInit ? (
              <ArrowIcon icon={faArrowUp} />
            ) : (
              <ArrowIcon icon={faArrowDown} />
            )}
          </>
        )}
      </IconButton>
    </TransferButtonWrapper>
  )
}

interface StyledButtonGroupProps {
  customOrientation: OrientationType
}

const StyledButtonGroup = styled(ButtonGroup)<StyledButtonGroupProps>(
  ({ theme, orientation }) => `
  align-self: center;
  ${
    orientation === OrientationEnum.vertical
      ? `margin-left: ${theme.spacing(2)}`
      : `margin-top: ${theme.spacing(2)}`
  }
`,
)

const TransferButtonWrapper = styled(Box)(
  ({ theme }) => `
  margin: ${theme.spacing(2)};
  align-self: center;
`,
)

const ArrowIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}))
