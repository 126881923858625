export const DROPPABLE_ID = 'position_object_stage'
export const DRAGGABLE_ID = 'position_object'

export const DRAGGABLE_ARRTIBUTES = {
  role: 'button',
  'aria-roledescription': 'draggable',
  'aria-disabled': 'true',
}

export const ARIA_LABELS = {
  STAGE: 'droppable area',
  INTERACTION: 'position object interaction',
  POSITION_OBJECT: 'position object',
  POSITIONED_OBJECT: 'positioned object',
}
