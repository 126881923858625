import { FC } from 'react'
import { Grid, gridClasses, GridProps, styled } from '@mui/material'
import { PROMPT_CONTAINER_ID, scrollBarMixinObj } from '@app/constants'
// import { FeedbackBlockWrapper } from '@app/components/feedback'

export type GridLayoutProps = Omit<GridProps, 'container' | 'item'> & { horizontal?: boolean }

export const GridLayout: FC<GridLayoutProps> = ({ children, horizontal, ...rest }) => {
  const responsiveProps = horizontal ? { tablet: 6 } : {}

  return (
    <StyledGridContainer spacing={4} horizontal={horizontal} {...rest}>
      {horizontal && <ShrinkingGridItem horizontal mobile={12} tablet={6} id={PROMPT_CONTAINER_ID} />}
      <GrowingGridItem horizontal={horizontal} mobile={12} {...responsiveProps}>
        {children}
      </GrowingGridItem>
    </StyledGridContainer>
  )
}

export const GridContainer: FC<GridLayoutProps> = ({ horizontal, ...props }) => <Grid container {...props} />

const StyledGridContainer = styled(GridContainer)(({ theme, horizontal, spacing }) => ({
  [theme.breakpoints.up('tablet')]: {
    ...(horizontal && { marginTop: 0, height: '100%' }),
  },
}))

export const GridItem: FC<GridLayoutProps> = ({ horizontal, ...props }) => <Grid item {...props} />

const StyledGridItem = styled(GridItem)(({ theme, horizontal }) => ({
  // Remove bottom margin for last feedback.
  // TODO: Remove that.
  // [`& > *:last-child ${FeedbackBlockWrapper}:last-child, & > ${FeedbackBlockWrapper}:last-child`]: {
  //   marginBottom: 0,
  // },

  [theme.breakpoints.up('tablet')]: {
    [`&.${gridClasses.item}`]: {
      ...(horizontal && {
        paddingTop: 0,
        height: '100%',
        overflow: 'auto',
        ...scrollBarMixinObj(theme, 'vertical'),
      }),
    },
  },

  [theme.breakpoints.down('tablet')]: {
    flexBasis: '100%',
    maxWidth: '100%',
  },
}))

const ShrinkingGridItem = styled(StyledGridItem)(() => ({
  ':empty, & > *:empty': {
    display: 'none',

    [`+ ${GrowingGridItem}`]: {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
}))

const GrowingGridItem = styled(StyledGridItem)(() => ({}))
