import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getUserAssessmentId } from '@app/firebase/api'
import { setUserAssessmentId, setUserId } from '@app/storage'
import { GROUP_REVIEW_USER } from '@app/constants'

export const useUserAssessmentIdSetup = () => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (!searchParams) {
      return
    }

    const userId = searchParams.get('userId') ?? GROUP_REVIEW_USER
    const assessmentId = searchParams.get('id')
    const schoolYear = searchParams.get('schoolYear')
    const attempt = Number(searchParams.get('attempt'))

    if (!!(assessmentId && schoolYear && attempt)) {
      setUserId(userId)
      setUserAssessmentId(getUserAssessmentId(userId, assessmentId, schoolYear, attempt))
    }
  }, [searchParams])
}
