/**
 * Split one-dimensional array into array of pairs.
 * @param source Array to split into pairs
 * @returns Array of pairs
 */
export const splitToPairs = <T = any>(source: T[]): T[][] => {
  return source.reduce((result, value, index, array) => {
    if (index % 2 === 0) {
      result.push(array.slice(index, index + 2))
    }
    return result
  }, [])
}
