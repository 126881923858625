import { Box, styled } from '@mui/material'

export const AssociatePairsWrapper = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  maxWidth: '360px',
  width: '100%',
  marginBottom: theme.spacing(6),

  [theme.breakpoints.down('mobile')]: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(5.5),
  },
}))
