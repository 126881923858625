import { FC } from 'react'

interface RubricBlockProps {
  children?: string;
  accessibilityAttr?: { [key: string]: string }
}

export const RubricBlockComponent: FC<RubricBlockProps> = (props) => {
  const { children, accessibilityAttr } = props

  return (
    <div {...accessibilityAttr}>
      {children}
    </div>
  )
}
