import { HeaderTitleState } from '@app/storage/headerTitle'
import { AppBar, styled, Toolbar, Typography } from '@mui/material'
import { FC, useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'
import { useNavigate } from 'react-router-dom'
import TimeLimitsContainer from '@app/components/ui/containers/TimeLimitsContainer'
import { FontSizeControlContainer } from '@app/components/ui/containers/FontSizeControlContainer'
import { AssessmentTest } from '@app/models'
import { useDefineTimeLimit, useModal } from '@app/helpers'
import { ROUTES, RETURN_TO_DASHBOARD_BTN_ATTRIBUTES } from '@app/constants'
import { BasicModal } from '@app/components'
import { CurrentTestState, CurrentUserAssessmentState } from '@app/storage'
import { TestToolsMenu } from './TestToolsMenu'
import { AssessmentStatus } from '@app/firebase/api'
import { CalculatorDraggableModal } from './CalculatorDraggableModal'
import { focusStyle } from '@app/theme'

interface ScreenHeaderProps {
  icon?: JSX.Element
  test?: AssessmentTest
}

const ScreenHeader: FC<ScreenHeaderProps> = ({ icon, test }) => {
  const { title } = useSnapshot(HeaderTitleState)
  const { assessmentId, status } = useSnapshot(CurrentUserAssessmentState)
  const { isStarted } = useSnapshot(CurrentTestState)
  const maxTime = useDefineTimeLimit()
  const headerRef = useRef(null)

  const navigate = useNavigate()

  const [showTimeoutModal, setShowTimeoutModal] = useState<boolean>(false)

  const onTimeOut = useCallback(() => {
    setShowTimeoutModal(true)
  }, [])

  const { open: showCalculator, onOpen: onShowCalculator, onClose: onHideCalculator } = useModal(false)

  useLayoutEffect(() => {
    headerRef.current.focus()
  }, [])

  const isCompleted = status === AssessmentStatus.completed

  return (
    <StyledAppBar position='static' tabIndex={-1} ref={headerRef}>
      <StyledToolbar>
        {icon || null}
        <TestTitle component='h1' variant='h3' aria-label={title} title={title}>
          {title}
        </TestTitle>
        {assessmentId && (
          <RightPartContainer>
            <FontSizeControlContainer />
            {isStarted && Boolean(test) && !test.isFinished && !!maxTime && (
              <TimeLimitsContainer
                maxTime={maxTime}
                resourceId={test.resourceId}
                allowLateSubmission={test.timeLimits?.allowLateSubmission}
                onTimeOut={onTimeOut}
              />
            )}
            {!isCompleted && (
              <TestToolsMenu onCalculator={showCalculator ? onHideCalculator : onShowCalculator} />
            )}
          </RightPartContainer>
        )}
      </StyledToolbar>

      <BasicModal
        open={showTimeoutModal}
        title='Time is out'
        backdropDisabled
        onClose={() => setShowTimeoutModal(false)}
        onSubmit={() => navigate(ROUTES.home)}
        noCancel
        submitText={'Return to dashboard'}
        // modalProps={SUBMIT_MODAL_ATTRIBUTES}
        submitButtonProps={RETURN_TO_DASHBOARD_BTN_ATTRIBUTES}
      >
        <Typography variant='variation_subtitle1'>
          Your test was submitted automatically.
          {/* TODO: Add content of the modal. */}
        </Typography>
      </BasicModal>

      {!isCompleted && <CalculatorDraggableModal open={showCalculator} onClose={onHideCalculator} />}
    </StyledAppBar>
  )
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: theme.shadows[6],
  height: theme.spacing(16.5),

  '&:focus-visible': {
    ...focusStyle(theme).border,
  },

  [theme.breakpoints.down('mobile')]: {
    height: theme.spacing(14.5),
  },
}))

const StyledToolbar = styled(Toolbar)(
  ({ theme }) => `
    background: ${theme.palette.grey[800]};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: ${theme.spacing(0, 5)};

    ${[theme.breakpoints.down('mobile')]} {
      padding: ${theme.spacing(0, 2)};
    }
  `,
)

const TestTitle = styled(Typography)(
  ({ theme }) => `
    font-size: 1.25rem;
    line-height: 1.375rem;
    margin-bottom: 0;
    color: ${theme.palette.common.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 ${theme.spacing(2)} 0 ${theme.spacing(6)};

    ${[theme.breakpoints.down('mobile')]} {
      margin-left: ${theme.spacing(2)};
    }
  `,
) as typeof Typography

const RightPartContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(7.5),
  marginLeft: 'auto',

  [theme.breakpoints.down('mobile')]: {
    gap: theme.spacing(3),
  },
}))

export default ScreenHeader
