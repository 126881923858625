/* eslint-disable no-restricted-globals */
const syncIntervalWorker = () => {
  let interval: any = null
  let intervalStep: number = 60
  self.onmessage = (event: MessageEvent<{ type: string, value: any }> ) => {
    switch (event.data.type) {
      case 'changeStep': {
        if (event.data.value) {
          console.log('INTERVAL STEP CHANGED TO ', event.data.value * 60)
          intervalStep = event.data.value * 60
        }
        break
      }
      case 'start': {
        console.log('SYNC INTERVAL STARTED')
        interval = setInterval(() => {
          self.postMessage('emitSyncEvent')
        }, intervalStep * 1000)
        break
      }
      case 'reset': {
        console.log('SYNC INTERVAL STOPPED')
        clearInterval(interval)
        break
      }
    }
  }
}


let code = syncIntervalWorker.toString()
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))

const blob = new Blob([code], { type: "application/javascript" })
const syncIntervalWorkerScript = URL.createObjectURL(blob)

export default syncIntervalWorkerScript
