import { Stack, StackProps, alertClasses, styled } from '@mui/material'
import { AlertContainerComponent, AlertContainerComponentProps } from '../AlertContainer'
import { colorPalette, getBorderColor } from '../AlertContainer/helpers'

export const StyledAlertGroup = styled(Stack)<StackProps & Pick<
  AlertContainerComponentProps, 'severity'|'size'|'variant'>
>(
  ({ theme, severity, variant }) => ({
    borderRadius: theme.shape.borderRadius * 1.5,
    ...colorPalette(theme, severity),
    
    ...(variant === 'outlined' && {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${getBorderColor(theme, severity)}`,
    }),
  })
)

export const StyledAlert = styled(AlertContainerComponent)(({ theme }) => ({
  borderWidth: 0,
  marginBottom: 0,
  backgroundColor: 'transparent',

  '&:not(&:first-child)': {
    paddingTop: 0,
  },

  [`& .${alertClasses.icon}`]: {
    marginRight: theme.spacing(2),
  }
}))
