import React, { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { SelectChangeEvent } from '@mui/material'
import { InlineChoice } from './InlineChoice'
import { CurrentTestState } from '@app/storage'
import {
  getFeedbackItems,
  getResponseSingleItem,
  useAssessmentItemResponseScoring,
  useReactiveItemResponse,
  useResponseUpdate,
  useStateReset,
} from '@app/helpers'
import { FIELD_SELECT_ATTRIBUTES } from '@app/constants'
import { InteractionType } from '@app/types'

interface InlineChoiceInteractionProps {
  itemId: string
  responseidentifier: string
  shuffle?: string
  required?: string
  children?: JSX.Element
  accessibilityAttr?: { [key: string]: string }
}

export const InlineChoiceInteractionComponent: React.FC<InlineChoiceInteractionProps> = props => {
  const { children, responseidentifier: responseIdentifier, accessibilityAttr } = props
  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)
  const itemResponse = useReactiveItemResponse(responseIdentifier)
  const { correct, scoring, groupMode, groupStatistics, correctResponse } = useAssessmentItemResponseScoring(
    currentItemResponse?.itemId,
    responseIdentifier,
  )

  const feedbackItems = getFeedbackItems(
    React.Children.toArray(children).filter((child: any) => child.props) as JSX.Element[],
    groupStatistics,
    correctResponse,
  )

  const [value, setValue] = useState<string>(null)

  useEffect(() => {
    if (groupMode ? !correctResponse : !itemResponse) return
    setValue(getResponseSingleItem(groupMode ? correctResponse : itemResponse))
  }, [itemResponse, groupMode, correctResponse])

  useResponseUpdate(
    currentItemResponse?.id,
    responseIdentifier,
    value,
    InteractionType.inlineChoice,
    transformToArray,
  )

  const handleChange = (value: string, event?: SelectChangeEvent<any>) => {
    if (currentTestPart?.isFinished) return

    setValue(value)
  }

  // Reset state on response ID change.
  useStateReset(currentItemResponse?.id, responseIdentifier, setValue, getResponseSingleItem)

  const { 'aria-label': ariaLabel = null, ...restAccessibilityAttr } = accessibilityAttr || {}

  return (
    <InlineChoice
      value={value}
      onChange={handleChange}
      correct={correct}
      correctAnswer={scoring?.response?.[0]}
      groupMode={groupMode}
      feedbackItems={feedbackItems}
      {...(value ? {} : { 'aria-label': ariaLabel || 'Collapsed menu' })}
      SelectDisplayProps={{
        ...FIELD_SELECT_ATTRIBUTES,
        ...restAccessibilityAttr,
      }}
    >
      {children}
    </InlineChoice>
  )
}

const transformToArray = (value: string) => [value]
