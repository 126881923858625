import { DraggableData } from '../GraphingInteraction/types'

export enum IntervalType {
  UNBOUNDED = 'unbounded',
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum IntervalDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface IntervalTypeProps {
  type: IntervalType
  direction: IntervalDirection
}

export interface AxisProps {
  min: number
  max: number
  increment: number
}

export interface NumberLineComponentProps {
  itemId: string
  responseidentifier: string
  width?: string
  height?: string
  children: JSX.Element | JSX.Element[]
}

export interface NumberLineProps {
  width: number
  height: number
  axis: AxisProps
  intervalTypes: IntervalTypeProps[]
  disabled?: boolean
}

export interface Interval {
  id: string
  point: DOMPoint
  type: IntervalType
  direction: IntervalDirection
}

export type UseIntervals = {
  intervals: Interval[]
  current: string
  onAddInterval: (type: IntervalType, direction: IntervalDirection) => void
  onRemoveInterval: (id: string) => void
  onSelectInterval: (id: string) => void
  onMovePoint: (newPosition: DOMPoint) => void
  setIntervals: React.Dispatch<React.SetStateAction<Interval[]>>
  setCurrent: React.Dispatch<React.SetStateAction<string>>
  announcement: string
}

export interface UseNumberLine {
  intervals: UseIntervals
  disabled: boolean
}

export interface DraggableIntervalData extends DraggableData {
  interval: Pick<Interval, 'type' | 'direction'>
}
