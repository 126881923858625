export const TEST_ATTRIBUTE_ID = 'data-test-id'
export const TEST_ELEMENT_IDS = {
  loginBtn: 'loginBtn',
  loginEmailInput: 'loginEmailInput',
  loginPasswordInput: 'loginPasswordInput',
  logoutBtn: 'logoutBtn',
  checkbox: 'chxChoice',
  nextButton: 'btnNextQuestion',
  prevButton: 'btnPrevQuestion',
  uploadPackage: 'btnFileUpload',
  homePage: 'btnHomePage',
  startTest: 'btnStartTest',
  generateLink: 'btnGenerateLink',
  showAllQuestions: 'btnShowAllQuestions',
  scrollLeft: 'btnScrollLeft',
  scrollRight: 'btnScrollRight',
  questionBtn: 'btnQuestion',
  initialDropContainer: 'fldInitialDropContainer',
  sortableDropContainer: 'fldSortableDropContainer',
  dragAndDropElement: 'dndElement',
  fieldChoice: 'fldChoice',
  checkedSpan: 'Checked',
  textEditor: 'fldTextEditor',
  fieldInlineChoice: 'fldInlineChoice',
  selectItem: 'fldSelectItem',
  inputText: 'fldTextInput',
  alertSuccess: 'fldResultOfCheckboxesSuccess',
  alertInfo: 'fldResultOfCheckboxes',
  finishTestBtn: 'btnFinishTest',
  backToOverviewBtn: 'btnBackToOverviewBtn',
  submitTestBtn: 'btnSubmitTest',
  cancelBtn: 'btnCancel',
  submitTestModal: 'lblSubmitTestPopUp',
  finalFeedbackModal: 'lblFinalFeedbackPopUp',
  returnToDashboard: 'btnReturnToDashboard',
  removeBtn: 'btnRemoveItem',
  moveUp: 'btnMoveUp',
  moveDown: 'btnMoveDown',
  moveToInitContainer: 'btnMoveToInitContainer',
  moveFromInitContainer: 'btnMoveFromInitContainer',
  gapArea: 'fldDragAndDropArea',
  btnChoice: 'btnChoice',
  fldState: 'fldState',
  numberOfQuestions: 'fldNumberOfQuestion',
  answeredQuestion: 'answeredQuestion',
  currentQuestion: 'currentQuestion',
  allQuestionsModal: 'fldModalAllQuestions',
  gridInField: 'fldWithResult',
  idleDetectorModal: 'lblIdleDetectorPopUp',
  resetIdleStateBtn: 'btnResetIdleState',
}

export const LOGIN_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.loginBtn }
export const LOGIN_EMAIL_INPUT_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.loginEmailInput }
export const LOGIN_PASSWORD_INPUT_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.loginPasswordInput }
export const LOGOUT_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.logoutBtn }
export const UPLOAD_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.uploadPackage }
export const PREVIOUS_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.prevButton }
export const NEXT_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.nextButton }
export const HOME_PAGE_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.homePage }
export const GENERATE_LINK_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.generateLink }
export const START_TEST_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.startTest }
export const SHOW_QUESTIONS_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.showAllQuestions }
export const SCROLL_RIGHT_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.scrollRight }
export const SCROLL_LEFT_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.scrollLeft }
export const INITIAL_DROP_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.initialDropContainer }
export const SORTABLE_DROP_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.sortableDropContainer }
export const DND_ELEMENT_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.dragAndDropElement }
export const FIELD_CHOICE_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.fieldChoice }
export const FIELD_CHOICE_CHECKED_ATTRIBUTES = { 'data-checked': TEST_ELEMENT_IDS.checkedSpan }
export const TEXT_EDITOR_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.textEditor }
export const FIELD_SELECT_ATTRIBUTES = { [TEST_ATTRIBUTE_ID as string]: TEST_ELEMENT_IDS.fieldInlineChoice }
export const SELECT_ITEM_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.selectItem }
export const TEXT_INPUT_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.inputText }
export const CHECKBOX_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.checkbox }
export const ALERT_SUCCESS_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.alertSuccess }
export const ALERT_INFO_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.alertInfo }
export const FINISH_TEST_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.finishTestBtn }
export const BACK_TO_OVERVIEW_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.backToOverviewBtn }
export const SUBMIT_TEST_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.submitTestBtn }
export const RETURN_TO_DASHBOARD_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.returnToDashboard }
export const CANCEL_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.cancelBtn }
export const SUBMIT_MODAL_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.submitTestModal }
export const IDLE_DETECTOR_MODAL_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.idleDetectorModal }
export const RESET_IDLE_STATE_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.resetIdleStateBtn }
export const FINAL_FEEDBACK_MODAL_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.finalFeedbackModal }
export const REMOVE_ITEM_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.removeBtn }
export const MOVE_UP_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.moveUp }
export const MOVE_DOWN_BTN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.moveDown }
export const MOVE_TO_INIT_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.moveToInitContainer }
export const MOVE_FROM_INIT_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.moveFromInitContainer }
export const GAP_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.gapArea }
export const BTN_CHOICE_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.btnChoice }
export const FIELD_STATE_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.fldState }
export const NUMBER_OF_QUESTIONS_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.numberOfQuestions }
export const ALL_QUESTIONS_MODAL_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.allQuestionsModal }
export const ANSWERED_QUESTION_ATTRIBUTES = { 'data-checked': TEST_ELEMENT_IDS.answeredQuestion }
export const CURRENT_QUESTION_ATTRIBUTES = { 'data-current': TEST_ELEMENT_IDS.currentQuestion }
export const GRID_IN_ATTRIBUTES = { [TEST_ATTRIBUTE_ID]: TEST_ELEMENT_IDS.gridInField }
