import { styled } from '@mui/material'
import { FC, SVGProps } from 'react'
import {
  LineSegment,
  LineSegmentProps,
  TSpan,
  VictoryCommonPrimitiveProps,
  VictoryLabel,
  VictoryLabelProps,
} from 'victory'
import { DEFAULT_MARKER_WIDTH, DEFAULT_MARKER_HEIGHT } from '../constants'
import { LineWithMarkers } from './LineWithMarkers'

export const Axis: FC<LineSegmentProps> = props => {
  return <LineSegment {...props} disableInlineStyles lineComponent={<AxisLine />} />
}

export const AxisLine: FC<any> = props => {
  const { width = DEFAULT_MARKER_WIDTH, height = DEFAULT_MARKER_HEIGHT, ...rest } = props

  return (
    <LineWithMarkers
      width={width}
      height={height}
      id='numberLineAxis'
      useStartMarker
      useEndMarker
      Pointer={StyledAxisPointer}
    >
      <StyledAxisLine {...rest} vectorEffect='non-scaling-stroke' />
    </LineWithMarkers>
  )
}

export const Grid: FC<any> = () => null

enum TickSize {
  SMALL = 12,
  REGULAR = 24,
}

export const Tick: FC<any> = props => {
  if (props.index === 0 || props.index === props.ticks.length - 1) {
    return null
  }

  const isInteger = !(props.datum % 1)
  const size = isInteger ? TickSize.REGULAR : TickSize.SMALL

  return <LineSegment {...props} lineComponent={<StyledTickLine size={size} />} disableInlineStyles />
}

export const TickLabel: FC<VictoryLabelProps> = props => {
  return <VictoryLabel {...props} disableInlineStyles tspanComponent={<TickLabelSpan />} />
}

export const TickLabelSpan: FC<VictoryCommonPrimitiveProps> = ({ style, ...props }) => (
  <StyledTickLabelSpan {...props} />
)

const StyledTickLine = styled(({ size, ...props }: SVGProps<SVGLineElement> & { size?: number }) => (
  <line {...props} y2={Number(props.y1) + size} vectorEffect='non-scaling-stroke' />
))(({ theme, size }) => ({
  stroke: theme.palette.grey[800],
  strokeWidth: size === TickSize.SMALL ? 2 : 3,
  transformOrigin: 'center',
  transform: `translate(0, -${size / 2}px)`,
}))

const StyledAxisPointer = styled('polygon')(({ theme }) => ({
  fill: theme.palette.grey[800],
}))

const StyledAxisLine = styled('line')(({ theme }) => ({
  stroke: theme.palette.grey[800],
  strokeWidth: 2,
}))

const StyledTickLabelSpan = styled(TSpan)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  lineHeight: theme.typography.body2.lineHeight,
}))
