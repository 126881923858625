import { FontSizeControlOptions, ScreenReaderInfo } from '@app/components'
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faTextSize } from '@fortawesome/pro-solid-svg-icons/faTextSize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  ClickAwayListener,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  styled,
} from '@mui/material'
import { forwardRef, useState } from 'react'
import { useFontChangeAnnouncement } from './hooks'

export interface FontSizeControlProps {
  className?: string
  style?: React.CSSProperties
  id?: string
  value: number
  options?: FontSizeControlOptions
  onDecrease: () => void
  onIncrease: () => void
}

export const FontSizeControl = forwardRef<HTMLElement, FontSizeControlProps>((props, ref) => {
  const { className, style, id, value, options, onDecrease, onIncrease } = props

  const [open, setOpen] = useState<boolean>(false)
  const announcement = useFontChangeAnnouncement(value, options)
  const onClose = () => setOpen(false)
  const onToggle = () => setOpen(current => !current)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  const actions = (
    <StyledButtonsGroup>
      <StyledIconButton aria-label='Decrease text size' onClick={onDecrease}>
        <StyledIcon icon={faMinus} />
      </StyledIconButton>
      <StyledIconButton aria-label='Increase text size' onClick={onIncrease}>
        <StyledIcon icon={faPlus} />
      </StyledIconButton>
    </StyledButtonsGroup>
  )

  return (
    <StyledFontSizeControlWrapper ref={ref} id={id} className={className} style={style}>
      <StyledFontSizeLabel>Text Size</StyledFontSizeLabel>
      {/* hidden div with current value for screen reader */}
      <ScreenReaderInfo ariaLive={'assertive'} ariaAtomic ariaBusy={!announcement}>
        {announcement}
      </ScreenReaderInfo>
      {isMobile ? (
        <ClickAwayListener onClickAway={onClose}>
          <div style={{ position: 'relative', display: 'flex' }}>
            <StyledIconButton
              onClick={onToggle}
              aria-label='Decrease or Increase font size'
              aria-expanded={open}
            >
              <StyledIcon icon={faTextSize} aria-label='show font size popup' />
            </StyledIconButton>
            <CustomTextSizeContainer open={open} role='tooltip'>
              {actions}
            </CustomTextSizeContainer>
          </div>
        </ClickAwayListener>
      ) : (
        actions
      )}
    </StyledFontSizeControlWrapper>
  )
})

const StyledFontSizeControlWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  alignItems: 'center',
}))

const CustomTextSizeContainer = styled(Box)<{ open: boolean }>(({ theme, open }) => ({
  position: 'absolute',
  top: 'calc(100% + 24px)',
  left: '50%',
  zIndex: 2,
  padding: theme.spacing(2, 4),
  transform: 'translate(-50%, 0)',
  backgroundColor: 'rgba(69, 90, 100, 0.92)',
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.875rem',
  display: open ? 'block' : 'none',
}))

const StyledFontSizeLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '0.875rem',
  lineHeight: '1.375rem',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('mobile')]: {
    display: 'none',
  },
}))

const StyledButtonsGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(7.5),
  height: theme.spacing(7.5),
  backgroundColor: theme.palette.grey[700],

  [`${StyledIcon}`]: {
    color: theme.palette.grey[100],
  },
}))

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}))
