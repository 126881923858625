import { DragOverlay, DropAnimation } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { FC } from 'react'
import { createPortal } from 'react-dom'

interface DragOverlayContainerComponentProps {
  container?: Element | DocumentFragment | HTMLElement
  dropAnimation?: DropAnimation
  children: JSX.Element | JSX.Element[]
}

export const DragOverlayContainerComponent: FC<DragOverlayContainerComponentProps> = props => {
  const { container = document.body, children } = props
  return (
    <>
      {createPortal(
        <DragOverlay modifiers={[restrictToWindowEdges]} {...props}>
          {children}
        </DragOverlay>,
        container,
      )}
    </>
  )
}
