export const MIN = -10
export const MAX = 10
export const INTERVAL = 1
export const WIDTH = 650

export const DEFAULT_MARKER_WIDTH = 14
export const DEFAULT_MARKER_HEIGHT = 14

export const MOCK_AXIS_STYLE = {
  ticks: {
    fill: 'transparent',
    size: 12,
    stroke: 'black',
  },
}

export const RESPONSE_SEPARATOR = ' '

export const UNKNOWN_POSITION_LABEL = 'unknown position'
export const UNKNOWN_INTERVAL_LABEL = 'unknown interval'
