import { useState, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { CurrentTestState, setItemResponse } from '@app/storage'
import { AssociationConstraints, InteractionType } from '@app/types'
import {
  useItemResponse,
  useResponseDeclaration,
  useResponseValidityUpdate,
  useUpdatingRef,
} from '@app/helpers'

export interface GapMatch {
  gap: string
  match?: string
}

export const MATCH_SEPARATOR = ' '

export type UseGapMatches = {
  gapMatches: GapMatch[]
  onMatch: (gap: string, match: string) => void
  setGapMatches: React.Dispatch<React.SetStateAction<GapMatch[]>>
  announcement: string
}

export const transformResponseToGapMatches = (response: string[]): GapMatch[] =>
  response.map(responseItem => {
    const [match, gap] = responseItem.split(MATCH_SEPARATOR)
    return { gap, match }
  })

export const transformGapMatchesToResponse = (gapMatches: GapMatch[]): string[] =>
  gapMatches.map(({ gap, match }) => [match, gap].join(MATCH_SEPARATOR))

export const useGapMatches = (
  responseIdentifier: string,
  constraints: AssociationConstraints = {},
): UseGapMatches => {
  const { minAssociations, maxAssociations } = constraints
  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)
  const response = useItemResponse(currentItemResponse?.id, responseIdentifier)

  const idRef = useUpdatingRef(currentItemResponse?.id)
  const responseIdRef = useUpdatingRef(responseIdentifier)
  const currentResponseRef = useUpdatingRef(response)

  const [gapMatches, setGapMatches] = useState<GapMatch[]>([])
  const [announcement, setAnnouncement] = useState<string>('')
  const [id, setId] = useState('')
  const responseDeclaration = useResponseDeclaration(id, responseIdentifier)

  const onMatch = (gap: string, match: string) => {
    if (currentTestPart?.isFinished) {
      return
    }

    setGapMatches(current => {
      const matchIndex = current.findIndex(item => item.match === match)

      // If match exists and new gap is empty, remove pair.
      if (matchIndex >= 0 && !gap) {
        setAnnouncement(`Removed item ${match} from ${current[matchIndex].gap}.`)
        return current.filter(item => item.match !== match)
      }

      // If no match and new gap is empty, do nothing.
      if (!gap) {
        return current
      }

      // If new pair is the same as current, do nothing.
      if (current[matchIndex]?.gap === gap) {
        return current
      }

      if (matchIndex >= 0) {
        // If match exists, remove old pair.
        current = current.filter(item => item.match !== match)
      }

      const gapIndex = current.findIndex(item => item.gap === gap)

      // If gap exists, replace match for that gap.
      if (gapIndex >= 0) {
        setAnnouncement(`Replaced item ${current[gapIndex].match} at ${current[gapIndex].gap} with ${match}.`)
        current[gapIndex].match = match
        return [...current]
      }

      // Add new pair.
      setAnnouncement(`Placed item ${match} at ${gap}.`)
      return [...current, { gap, match }]
    })
  }

  useEffect(() => {
    if (!currentItemResponse?.id) {
      return
    }

    setId(CurrentTestState.currentItemResponse.id)
  }, [currentItemResponse?.id, currentItemResponse])

  useEffect(() => {
    if (currentTestPart?.isFinished || !responseDeclaration) return

    setItemResponse(
      idRef.current,
      responseIdRef.current,
      transformGapMatchesToResponse(gapMatches),
      true,
      InteractionType.graphicGapMatch,
      responseDeclaration.cardinality,
    )
  }, [currentTestPart?.isFinished, idRef, responseIdRef, gapMatches, responseDeclaration])

  // Set response validity depending on constraints.
  useResponseValidityUpdate(currentItemResponse?.id, gapMatches.length, minAssociations, maxAssociations)

  // Reset selected items when quiestion changes
  useEffect(() => {
    setGapMatches(
      currentResponseRef.current.length
        ? transformResponseToGapMatches(currentResponseRef.current as string[])
        : [],
    )
  }, [currentResponseRef, currentItemResponse?.id])

  return { gapMatches, onMatch, setGapMatches, announcement }
}
