import React, { forwardRef, ReactElement, useState } from 'react'
import {
  Box,
  BoxProps,
  Menu,
  menuClasses,
  MenuItem,
  paperClasses,
  Stack,
  styled,
  Typography,
} from '@mui/material'

export interface ContextMenuOptions {
  label: string
  targetId?: string
  targetClassName?: string
  icon?: ReactElement
  action: () => void
}

interface Props extends BoxProps {
  id?: string
  className?: string
  getContextMenuOptions: (event: React.MouseEvent, selectedText: string) => ContextMenuOptions[]
}

const CustomContextMenu = forwardRef<any, Props>(
  ({ id, className, getContextMenuOptions, children, ...rest }, ref) => {
    const [contextMenuPosition, setContextMenuPosition] =
      useState<{
        mouseX: number
        mouseY: number
      } | null>(null)
    const [options, setOptions] = useState<ContextMenuOptions[]>([])

    const handleContextMenu = (event: React.MouseEvent) => {
      event.preventDefault()

      const selectedText = window.getSelection().toString()
      const initialOptions = getContextMenuOptions(event, selectedText)

      setOptions(
        initialOptions.filter(({ targetId, targetClassName }) =>
          targetId || targetClassName
            ? (event.target as HTMLElement).closest(targetId ? `#${targetId}` : `.${targetClassName}`)
            : true,
        ),
      )

      setContextMenuPosition(
        contextMenuPosition === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : null,
      )
    }

    const handleClose = (action?: () => void) => () => {
      action?.()
      setContextMenuPosition(null)
    }

    return (
      <Box ref={ref} id={id} className={className} onContextMenu={handleContextMenu} {...rest}>
        {children}
        {!!options.length && (
          <StyledMenu
            open={contextMenuPosition !== null}
            onClose={handleClose()}
            anchorReference='anchorPosition'
            anchorPosition={
              contextMenuPosition !== null
                ? {
                    top: contextMenuPosition.mouseY,
                    left: contextMenuPosition.mouseX,
                  }
                : undefined
            }
          >
            {options.map(({ label, icon, action }) => (
              <StyledMenuItem key={label} onClick={handleClose(action)}>
                <Stack spacing={3} direction='row' alignItems='center'>
                  {icon}
                  <Typography variant='body2'>{label}</Typography>
                </Stack>
              </StyledMenuItem>
            ))}
          </StyledMenu>
        )}
      </Box>
    )
  },
)

const StyledMenu = styled(Menu)(({ theme: { shadows, spacing } }) => ({
  [`& .${paperClasses.root}`]: {
    minWidth: '160px',
    borderRadius: '2px',
    boxShadow: shadows[6],
  },
  [`& .${menuClasses.list}`]: {
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
}))

const StyledMenuItem = styled(MenuItem)(({ theme: { spacing, palette } }) => ({
  padding: spacing(2, 4),

  '&:hover': {
    backgroundColor: palette.grey[50],
  },

  '& .material-icons': {
    color: palette.grey[500],
  },
}))

export default CustomContextMenu
