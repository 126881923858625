import { FC, useCallback, useRef, useState } from 'react'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { Box } from '@mui/material'
import { DATA_CLICK_AWAY_ID, DATA_CLICK_AWAY_PROPS, DomNodes } from '@app/constants'
import {
  HotspotArea,
  OrderedHotspotChoice,
  ScreenReaderInfo,
  StyledHotspotAreaOverlay,
} from '@app/components'
import {
  generateAnnouncement,
  generateHumanReadableIds,
  transformChoicesConstraints,
  useFindNodes,
  useOutsideClick,
  useTestPartFinishedCheck,
  viewportRectIntersection,
} from '@app/helpers'
import { useChoiceOrder } from './hooks'
import { OrderItem, OrderItemsRow, DraggableOrderItem, GraphicOrderItem } from './components'

interface GraphicOrderInteractionProps {
  itemId: string
  responseidentifier: string
  minchoices?: string
  maxchoices?: string
  children: JSX.Element
  className?: string
  accessibilityAttr?: { [key: string]: string }
}

export const GraphicOrderInteraction: FC<GraphicOrderInteractionProps> = props => {
  const { responseidentifier, minchoices, maxchoices, children, className, accessibilityAttr } = props
  const [minChoices, maxChoices] = transformChoicesConstraints(minchoices, maxchoices)
  const [activeElement, setActiveElement] = useState<{ id: string; placed: boolean }>({
    id: null,
    placed: false,
  })

  const [[prompt], [image], hotspotChoices] = useFindNodes(children, [
    DomNodes.prompt,
    DomNodes.object,
    DomNodes.hotspotChoice,
  ])

  // If minChoices and maxChoices both specified, they should be valid.
  if (minChoices && maxChoices) {
    if (minChoices < 1 || maxChoices > hotspotChoices.length || maxChoices < minChoices) {
      throw new Error('Constraints error.')
    }
  }

  const { choiceOrder, onToggle, announcement } = useChoiceOrder(responseidentifier, hotspotChoices.length, {
    minChoices,
    maxChoices,
  })

  const handleClickOnElement = useCallback(
    (id: string, placed = false) =>
      () =>
        setActiveElement({ id, placed }),
    [],
  )
  const handleClickOnGap = useCallback(
    (id: string) => () => {
      if (id && activeElement.id && !activeElement.placed) {
        onToggle(id, Number(activeElement.id))
      } else {
        onToggle(id)
      }
      setActiveElement({ id: null, placed: false })
    },
    [activeElement.id, activeElement.placed, onToggle],
  )

  const isFinished = useTestPartFinishedCheck()

  const [dragging, setDragging] = useState<string>('')

  const onDragStart = (event: DragStartEvent) => {
    setActiveElement({ id: event.active.id.toString(), placed: false })
    setDragging(event.active.id.toString())
  }

  const onDragEnd = (event: DragEndEvent) => {
    if (event.over?.id) {
      onToggle(event.over.id.toString(), Number(event.active.id))
    }
    setActiveElement({ id: null, placed: false })
    setDragging('')
  }

  const svgRef = useRef<SVGSVGElement>(null)

  const announcements = generateAnnouncement(false, generateHumanReadableIds(hotspotChoices), true)

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 15 } }),
    useSensor(KeyboardSensor),
  )

  useOutsideClick(`[${DATA_CLICK_AWAY_ID}]`, handleClickOnElement(null, false))

  return (
    <Box className={className} {...accessibilityAttr}>
      {prompt}

      <DndContext
        collisionDetection={viewportRectIntersection}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        sensors={sensors}
        accessibility={{ announcements }}
      >
        <HotspotArea
          svgRef={svgRef}
          image={image}
          overlay={
            <StyledHotspotAreaOverlay>
              {hotspotChoices.map(hotspotChoice => {
                const hotspotId = hotspotChoice.props.identifier
                const order = choiceOrder.findIndex(orderItem => orderItem === hotspotId)
                const orderItemId = order.toString()

                return (
                  <OrderedHotspotChoice
                    key={hotspotId}
                    id={hotspotId}
                    selected={choiceOrder.includes(hotspotId)}
                    onToggle={handleClickOnGap(hotspotId)}
                    {...hotspotChoice.props}
                    orderItemId={orderItemId}
                    portalContainer={svgRef.current}
                  >
                    {order >= 0 && (
                      <GraphicOrderItem
                        id={orderItemId}
                        placed
                        removable={!isFinished}
                        onRemove={() => onToggle(hotspotId)}
                        onClick={handleClickOnElement(orderItemId, true)}
                        selected={orderItemId === activeElement.id}
                        dragging={orderItemId === dragging}
                        {...DATA_CLICK_AWAY_PROPS}
                      >
                        {order + 1}
                      </GraphicOrderItem>
                    )}
                  </OrderedHotspotChoice>
                )
              })}
            </StyledHotspotAreaOverlay>
          }
        />

        <DragOverlay>{dragging && <OrderItem overlay>{Number(dragging) + 1}</OrderItem>}</DragOverlay>

        <OrderItemsRow>
          {hotspotChoices.map((hotspotChoice, index) =>
            choiceOrder[index] ? (
              <OrderItem key={hotspotChoice.props.identifier} disabled>
                {index + 1}
              </OrderItem>
            ) : (
              <DraggableOrderItem
                key={hotspotChoice.props.identifier}
                id={index.toString()}
                disabled={!!choiceOrder[index]}
                onClick={handleClickOnElement(index.toString())}
                selected={index.toString() === activeElement.id}
                {...DATA_CLICK_AWAY_PROPS}
              >
                {index + 1}
              </DraggableOrderItem>
            ),
          )}
        </OrderItemsRow>
      </DndContext>

      <ScreenReaderInfo ariaAtomic ariaLive='polite'>
        {announcement}
      </ScreenReaderInfo>
    </Box>
  )
}
