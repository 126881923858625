import { useContext, useRef } from 'react'
import { EventPropTypeInterface } from 'victory-core'
import { GraphingContext } from '../context'
import { isAsymptoteWithinDomain, isWithinDomain } from '../helpers'
import { DraggableElement, DraggableItem } from '../types'
import { onKeyboardSubmit } from '@app/helpers'

export type ChartEventsOptions = { allowAdd: boolean; allowRemove: boolean; allowMove: boolean }

export type UseChartEvents = {
  setRef: React.MutableRefObject<any>
  events: EventPropTypeInterface<string, string[] | number[] | string | number>[]
}

export const useChartEvents = (
  disabled: boolean,
  activeDraggable: DraggableItem,
  onSetDraggable: (item: DraggableItem) => void,
  options?: Partial<ChartEventsOptions>,
): UseChartEvents => {
  const { allowAdd, allowRemove, allowMove } = options ?? {}

  const chartContainerRef = useRef<any>()

  const { onAddPoint, onRemovePoint, onMovePoint, onMoveAsymptote } = useContext(GraphingContext)

  const onChartClick = (event: any, props: any): any => {
    const { width, height, domain } = props
    const {
      x: [, xMax],
      y: [, yMax],
    } = domain
    if (disabled) return

    let boardPoint: DOMPoint = new DOMPoint(0, 0)

    // If click was made with assertive technologies, it has no coordinates.
    if (event.clientX && event.clientY) {
      const svg = chartContainerRef.current.containerRef.firstChild
      const domPoint = new DOMPoint(event.clientX, event.clientY)
      const svgPoint = domPoint.matrixTransform(svg.getScreenCTM().inverse())
      boardPoint = new DOMPoint(
        Math.round((((svgPoint.x - width / 2) * xMax) / width) * 2),
        Math.round((((svgPoint.y - height / 2) * yMax) / height) * 2 * -1),
      )
    }

    // Move active draggable (point or asymptote).
    if (activeDraggable && allowMove) {
      if (activeDraggable.type === DraggableElement.ASYMPTOTE) {
        isAsymptoteWithinDomain(boardPoint.y, domain) && onMoveAsymptote(boardPoint.y)
      }

      if (activeDraggable.type === DraggableElement.POINT) {
        isWithinDomain(boardPoint, domain) && onMovePoint(activeDraggable.id, boardPoint)
      }

      onSetDraggable(null)

      return
    }

    // If point clicked, remove it (if removable).
    const pointElement = event.target.closest('.point') as SVGElement

    if (pointElement) {
      const pointId = pointElement.dataset['pointId']
      pointId && allowRemove && onRemovePoint(pointId)
      return
    }

    allowAdd && isWithinDomain(boardPoint, domain) && onAddPoint(boardPoint)
  }

  const onKeyboardAddPoint = (event: any) => {
    if (event.target.id === 'coordinate-plane') {
      allowAdd && onAddPoint(new DOMPoint(0, 0))
    }
  }
  const onChartKeyDown = onKeyboardSubmit(onKeyboardAddPoint)

  const chartEvents = [
    {
      target: 'parent',
      eventKey: 'all',
      childName: 'all',
      eventHandlers: {
        onMouseDown: () => {},
        onMouseUp: () => {},
        onClick: onChartClick,
        onKeyDown: onChartKeyDown,
      },
    },
  ]

  return { setRef: chartContainerRef, events: chartEvents }
}
