import { createContext } from 'react'
import { UseGraphs, UseSelectableAreas } from './hooks'
import { GraphType, LineStyle } from './types'

export const INITIAL_GRAPHING_CONTEXT: UseGraphs = {
  graphs: [{ lineStyle: LineStyle.SOLID, points: [], isValid: true }],
  asymptote: 0,
  activeGraphType: GraphType.LINEAR,
  activeGraph: undefined,
  isSolutionSetMode: false,
  onSelectGraphType: () => {},
  onSelectGraph: () => {},
  onAddPoint: () => {},
  onRemovePoint: () => {},
  onMovePoint: () => {},
  onMoveAsymptote: () => {},
  onSelectLineStyle: () => {},
  onEnableSolutionMode: () => {},
  setActiveGraph: () => {},
  setActiveGraphType: () => {},
  setGraphs: () => {},
  setAsymptote: () => {},
  setSolutionSetMode: () => {},
  announcement: '',
}

export const INITIAL_SELECTABLE_AREAS_CONTEXT: UseSelectableAreas = {
  areas: [],
  onToggleArea: () => {},
  setAreas: () => {},
}

export const GraphingContext = createContext<UseGraphs>(null)
export const SelectableAreasContext = createContext<UseSelectableAreas>(null)
