import { focusStyle } from '@app/theme'
import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(6, 0),

  '& .ql-formats > button': {
    color: theme.palette.additionalColors['bg_500'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& .ql-toolbar.ql-snow': {
    padding: theme.spacing(0.375, 0.875),
    borderRadius: `${theme.shape.borderRadius * 1.5}px ${theme.shape.borderRadius * 1.5}px 0 0`,
    border: `1px solid ${theme.palette.grey[200]}`,
    minHeight: '35px',
  },

  '& .ql-container.ql-snow': {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderTop: 'none',
    maxHeight: '250px',
    overflow: 'scroll',
  },

  '& .ql-toolbar.ql-snow .ql-formats': {
    marginRight: theme.spacing(3),
  },

  '& .ql-snow.ql-toolbar button, & .ql-snow .ql-toolbar button': {
    height: '30px',
    width: '30px',
  },

  '& .ql-snow.ql-toolbar button svg, & .ql-snow .ql-toolbar button svg': {
    height: theme.typography.body2.fontSize,
  },

  '& .ql-editor': {
    fontWeight: 400,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[800],
    overflow: 'auto',
    minHeight: '175px',
  },

  [`& .ql-snow.ql-toolbar button:hover,
    & .ql-snow .ql-toolbar button:hover,
    & .ql-snow.ql-toolbar button:focus,
    & .ql-snow .ql-toolbar button:focus,
    & .ql-snow.ql-toolbar button.ql-active,
    & .ql-snow .ql-toolbar button.ql-active,
    & .ql-snow.ql-toolbar .ql-picker-label:hover,
    & .ql-snow .ql-toolbar .ql-picker-label:hover,
    & .ql-snow.ql-toolbar .ql-picker-label.ql-active,
    & .ql-snow .ql-toolbar .ql-picker-label.ql-active,
    & .ql-snow.ql-toolbar .ql-picker-item:hover,
    & .ql-snow .ql-toolbar .ql-picker-item:hover,
    & .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
    & .ql-snow .ql-toolbar .ql-picker-item.ql-selected`]: {
    color: theme.palette.blue[300],
  },

  '& .ql-snow.ql-toolbar button:focus, & .ql-snow .ql-toolbar button:focus': {
    ...focusStyle(theme).shadow,
  },
}))
