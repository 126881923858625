import { IconButton, styled, Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator } from '@fortawesome/pro-solid-svg-icons/faCalculator'

type Props = {
  onCalculator: () => void
}

export const TestToolsMenu: React.FC<Props> = ({ onCalculator }) => {
  return (
    <Tooltip title='Calculator'>
      <StyledIconButton aria-label='Calculator' onClick={onCalculator}>
        <StyledCalculatorIcon icon={faCalculator} />
      </StyledIconButton>
    </Tooltip>
  )
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),

  [`${StyledCalculatorIcon}`]: {
    color: theme.palette.grey[100],
  },

  ':hover, :focus-visible': {
    backgroundColor: theme.palette.grey[700],
  },
}))

const StyledCalculatorIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
}))
