import { createArray } from '@app/helpers'
import { CurrentTestState } from '@app/storage'
import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSnapshot } from 'valtio'
import { ICurrentIndex, ISymbolsWithIndex } from './types'
import { createCellIndex, getMaxSymbolsCount, getSymbolsWithIndex, setInitialValue } from './utils'

/**
 * Function to control input values state.
 * @param symbols Symbols for validate inputs.
 * @param columnCount Number of columns ~ number of inputs.
 * @param initialValue Initial values of inputs.
 * @returns Array with input values state, method for changing state.
 */
export const useInputsValues = (
  symbols: string[][],
  columnCount: number,
  initialValue?: string[],
): [string[], (columnIndex: number, value: string) => void, Dispatch<SetStateAction<string[]>>] => {
  const [inputValues, setInputValues] = useState(
    initialValue.length === 0 ? setInitialValue(columnCount) : initialValue,
  )

  const setColumnInputValue = useCallback((columnIndex: number, newSymbol: string) => {
    if (
      (symbols[columnIndex].includes(newSymbol) && newSymbol !== inputValues[columnIndex]) ||
      newSymbol === ''
    ) {
      setInputValues((state: string[]) => {
        const newState = [...state]
        newState[columnIndex] = newSymbol
        return newState
      })
    }
  }, [symbols, inputValues])

  return [inputValues, setColumnInputValue, setInputValues]
}

type UseInputsRefType = {
  inputsRef: MutableRefObject<HTMLInputElement[]>
  onChange: (inputIndex: number) => (e: ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (inputIndex: number) => (e: KeyboardEvent<HTMLInputElement>) => void
  changeFocus: (inputIndex: number, selectSymbol?: string) => void
}
/**
 * Function to control input focus.
 * @param inputsCount Number of inputs.
 * @param symbols Symbols for validate inputs.
 * @param setInputValue Method for changing inputs state.
 * @param onInputAction Action on input change.
 * @param onBackspace Action on backspace key pressed.
 * @returns Array with inputs ref, input onChange method, input keyDown method for Backspace.
 */
export const useInputsRef = (
  inputsCount: number,
  symbols: string[][],
  inputValues: string[],
  setInputValue: (inputIndex: number, value: string) => void,
  onInputAction?: () => void,
  onBackspace?: (index?: number) => void
): UseInputsRefType => {
  const inputsRef = useRef<HTMLInputElement[]>(new Array(inputsCount))

  const changeFocus = (inputIndex: number, selectSymbol?: string) => {
    const inputs = inputsRef.current
    const newSymbol = selectSymbol || ''

    if (!symbols[inputIndex].includes(newSymbol)) return

    if (onInputAction) {
      onInputAction()
      return
    }

    if (inputIndex < inputs.length - 1) {
      inputs[inputIndex + 1].focus()
    }
  }

  const onChange = (inputIndex: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newSymbol = e.target.value.replace(inputValues[inputIndex], '').trim()
    newSymbol && setInputValue(inputIndex, newSymbol)
    changeFocus(inputIndex, newSymbol)
  }

  const onKeyDown = (inputIndex: number) => (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.key !== 'Backspace') return

    const inputs = inputsRef.current

    if (inputIndex <= inputs.length - 1 && inputIndex >= 0) {
      if (inputs[inputIndex].value) {
        setInputValue(inputIndex, '')
      } else {
        const filledInputIndex = inputs.findLastIndex(input => input.value)

        if (filledInputIndex === -1) {
          return
        }

        setInputValue(filledInputIndex, '')
        onBackspace ? onBackspace(filledInputIndex) : inputs[filledInputIndex].focus()
      }
    }
  }

  return { inputsRef, onChange, onKeyDown, changeFocus }
}

type GridInKeyboardNavigation = {
  currentIndex: ICurrentIndex,
  symbolsRef: MutableRefObject<ISymbolsWithIndex[][]>
  onKeyDown: (e: KeyboardEvent<HTMLTableElement>) => void
  selectCell: (columnIndex: number, rowIndex: number) => void
  onBackspace: (index: number) => void
  onAction: () => void
}

export const useGridInKeyboardNavigation = (
  columnCount: number,
  rowCount: number,
  symbols: string[][],
): GridInKeyboardNavigation => {
  const { currentItemResponse } = useSnapshot(CurrentTestState)

  const [currentRow, setCurrentRow] = useState<number>(0)
  const [currentColumn, setCurrentColumn] = useState<number>(0)
  const symbolsRef = useRef(getSymbolsWithIndex(symbols, getMaxSymbolsCount(symbols)))

  useEffect(() => {
    if (currentItemResponse?.id) {
      setCurrentRow(0)
      setCurrentColumn(0)
      symbolsRef.current = getSymbolsWithIndex(symbols, getMaxSymbolsCount(symbols))
    }
  }, [currentItemResponse?.id, symbolsRef, symbols])

  useEffect(() => {
    const currentIndex = createCellIndex(currentRow, currentColumn)
    const rowIndex = symbolsRef.current[currentColumn].findIndex(s => s.index === currentIndex)
    symbolsRef.current[currentColumn][rowIndex].ref?.focus()
  }, [currentRow, currentColumn])

  const cellArr = createArray(columnCount).map((column, columnIndex) =>
    createArray(rowCount + 1).map((row, rowIndex) => {
      const index = createCellIndex(rowIndex, columnIndex)
      return symbolsRef.current[columnIndex]?.some(item => item.index === index) || rowIndex === 0
        ? index
        : null
    }),
  )

  const arrayNavControl = (e: KeyboardEvent<HTMLTableElement>) => {
    if (e.key === 'ArrowRight') {
      if (currentColumn + 1 > columnCount - 1) return
      if (cellArr[currentColumn + 1][currentRow]) {
        setCurrentColumn(state => state + 1)
        return
      }
      if (!cellArr[currentColumn + 1][currentRow]) {
        const firstIndex = cellArr[currentColumn + 1].findIndex((item, i) => {
          if (i <= currentRow) return false
          if (item) return true
          return false
        })
        if (firstIndex > 0) {
          setCurrentColumn(state => state + 1)
          setCurrentRow(firstIndex)
        }
        return
      }
    }

    if (e.key === 'ArrowLeft') {
      if (currentColumn - 1 < 0) return
      if (cellArr[currentColumn - 1][currentRow]) {
        setCurrentColumn(state => state - 1)
        return
      }
      if (!cellArr[currentColumn - 1][currentRow]) {
        const firstIndex = cellArr[currentColumn - 1].findIndex((item, i) => {
          if (i <= currentRow) return false
          if (item) return true
          return false
        })
        if (firstIndex > 0) {
          setCurrentColumn(state => state - 1)
          setCurrentRow(firstIndex)
        }
        return
      }
    }

    if (e.key === 'ArrowUp') {
      if (currentRow - 1 < 0) return
      if (cellArr[currentColumn][currentRow - 1]) {
        setCurrentRow(state => state - 1)
        return
      }
      if (!cellArr[currentColumn][currentRow - 1]) {
        const firstIndex = cellArr[currentColumn]
          .slice(0, currentRow - 1)
          .reverse()
          .find((item, i) => item)
        firstIndex && setCurrentRow(Number(firstIndex[0]))
        return
      }
    }

    if (e.key === 'ArrowDown') {
      if (currentRow + 1 > rowCount) return
      if (cellArr[currentColumn][currentRow + 1]) {
        setCurrentRow(state => state + 1)
        return
      }
      if (!cellArr[currentColumn][currentRow + 1]) {
        const firstIndex = cellArr[currentColumn].findIndex((item, i) => {
          if (i <= currentRow) return false
          if (item) return true
          return false
        })
        firstIndex > 0 && setCurrentRow(firstIndex)
        return
      }
    }
  }

  const inputAction = useCallback(() => {
    if (currentColumn + 1 > columnCount - 1) return
    setCurrentColumn(state => state + 1)
  }, [columnCount, currentColumn])

  const backspaceAction = useCallback((index: number) => {
    if (currentColumn - 1 < 0) return
    setCurrentColumn(state => index ?? state - 1)
  }, [currentColumn])

  const selectCell = (columnIndex: number, rowIndex: number) => {
    setCurrentColumn(columnIndex)
    setCurrentRow(rowIndex)
  }

  return {
    currentIndex: { rowIndex: currentRow, columnIndex: currentColumn },
    onKeyDown: arrayNavControl,
    symbolsRef,
    selectCell,
    onBackspace: backspaceAction,
    onAction: inputAction
  }
}

export const useGridInAnnouncement = (inputValue: string, index: number) => {
  const [announcement, setAnnouncement] = useState('')
  const prevValue = useRef('')

  useEffect(() => {
    if (inputValue === prevValue.current) {
      return
    }

    if (inputValue && !prevValue.current) {
      prevValue.current = inputValue
      return
    }

    if (!inputValue && prevValue.current) {
      console.log(`Column ${index + 1} symbol is empty`)
      setAnnouncement(`Column ${index + 1} symbol is empty`)
      prevValue.current = inputValue
      return
    }

    if (inputValue) {
      console.log(`Column ${index + 1} symbol changed to ${inputValue === '.' ? 'point' : inputValue}`)
      setAnnouncement(`Column ${index + 1} symbol changed to ${inputValue === '.' ? 'point' : inputValue}`)
      prevValue.current = inputValue
    }
  }, [inputValue, index])

  return announcement
}