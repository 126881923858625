import { FC } from 'react'
import { styled, Box, BoxProps, alpha } from '@mui/material'
import { onKeyboardSubmit } from '@app/helpers'
import { GraphProps, LineStyle } from '../types'
import { getGraphColor } from '../helpers'
import { LineStyleSelect } from './LineStyleSelect'
import { focusStyle } from '@app/theme'

export interface GraphSelectProps {
  data: Partial<GraphProps>[]
  active: number
  onSelect: (index: number) => void
  onSelectLineStyle: (index: number, lineStyle: LineStyle) => void
  onSelectSolution?: () => void
  solutionSetEnabled?: boolean
  solutionMode?: boolean
  disabled?: boolean
  className?: string
}

export const GraphSelect: FC<GraphSelectProps> = props => {
  const {
    data,
    active,
    onSelect,
    onSelectLineStyle,
    onSelectSolution,
    solutionSetEnabled,
    solutionMode,
    disabled = false,
    className,
  } = props

  const handleLineStyleSelect = (i: number) => (value: LineStyle) => {
    onSelectLineStyle(i, value)
  }

  return (
    <Select className={className} aria-label='Graph select' aria-disabled={disabled}>
      {data.map((item, i) => {
        const selected = i === active

        return (
          <ColoredSelectItem
            key={i}
            onClick={() => onSelect(i)}
            selected={selected}
            disabled={disabled}
            colorIndex={i}
            aria-label={`Graph ${i + 1} - ${item.lineStyle}`}
          >
            <SelectItemTitle selected={selected}>Graph {i + 1}</SelectItemTitle>
            {item.lineStyleToggle && (
              <StyledLineStyleSelect
                value={item.lineStyle || ''}
                onChange={handleLineStyleSelect(i)}
                labelId={item.lineStyle}
                readOnly={disabled}
              />
            )}
          </ColoredSelectItem>
        )
      })}
      {solutionSetEnabled && (
        <SelectItem onClick={onSelectSolution} selected={solutionMode} aria-label='Solution Set'>
          <SelectItemTitle>Solution Set</SelectItemTitle>
        </SelectItem>
      )}
    </Select>
  )
}

const Select = styled((props: BoxProps) => <Box {...props} role='radiogroup' />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('mobile')]: {
    width: theme.spacing(37.5),
    flexShrink: 0,
  },
}))

const SelectItemBase = ({
  selected,
  disabled,
  onClick,
  colorIndex,
  ...props
}: BoxProps & { selected?: boolean; disabled?: boolean; colorIndex?: number }) => {
  return (
    <Box
      {...props}
      onClick={onClick}
      onKeyDown={onKeyboardSubmit(onClick)}
      tabIndex={0}
      role='radio'
      aria-checked={selected}
      aria-disabled={disabled}
    />
  )
}

const SelectItem = styled(SelectItemBase)(({ theme, selected, disabled }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  padding: theme.spacing(3),
  border: `${selected ? '2px' : '1px'} solid ${theme.palette.blue['A700']}`,
  borderRadius: theme.shape.borderRadius * 1.5,
  backgroundColor: selected ? alpha(theme.palette.blue[500], 0.4) : undefined,
  margin: theme.spacing(1, 0),

  ':first-child': {
    marginTop: 0,
  },
  ':last-child': {
    marginBottom: 0,
  },

  ':hover': {
    ...(!disabled && {
      backgroundColor: alpha(theme.palette.blue[500], 0.2),
    }),
  },

  ':focus': {
    ...focusStyle(theme, selected ? 2 : 1).border,
  },

  ...(disabled && {
    cursor: 'default',
  }),
}))

const ColoredSelectItem = styled(SelectItem)(({ theme, selected, disabled, colorIndex = 0 }) => ({
  padding: theme.spacing(3),
  borderColor: getGraphColor(theme, 'A700', colorIndex),
  backgroundColor: selected ? alpha(getGraphColor(theme, 500, colorIndex), 0.4) : undefined,

  ':hover': {
    ...(!disabled && {
      backgroundColor: alpha(getGraphColor(theme, 500, colorIndex), 0.2),
    }),
  },
}))

const SelectItemTitle = styled('span')<{
  selected?: boolean
}>(({ theme, selected }) => ({
  color: selected ? theme.palette.blue[900] : theme.palette.grey[700],
  lineHeight: '1.063rem',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 500,
  textAlign: 'center',

  [theme.breakpoints.up('mobile')]: {
    fontSize: theme.typography.body1.fontSize,
  },
}))

const StyledLineStyleSelect = styled(LineStyleSelect)(({ theme }) => ({
  margin: theme.spacing(-2),
  marginTop: theme.spacing(2),

  [theme.breakpoints.up('mobile')]: {
    marginTop: theme.spacing(3),
  },
}))
