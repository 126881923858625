import { FC } from 'react'
import { alpha, Box, Dialog, IconButton, paperClasses, styled } from '@mui/material'
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'

interface Props {
  videoSrc: string
  open: boolean
  onClose: () => void
}

const VideoModal: FC<Props> = ({ videoSrc, open, onClose }) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledCloseButton disableRipple size='large' onClick={onClose}>
        <CloseIcon icon={faTimes} />
      </StyledCloseButton>
      <ReactPlayerContainer>
        <ReactPlayer controls playing url={videoSrc} />
      </ReactPlayerContainer>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)(() => ({
  [`& .${paperClasses.root}`]: {
    position: 'relative',
    width: '70%',
    height: '60%',
    overflow: 'visible',
  },
}))

const ReactPlayerContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',

  '& div': {
    width: '100% !important',
    height: '100% !important',
  },

  '& video': {
    objectFit: 'cover',
  },
}))

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  width: '40px',
  height: '40px',
  zIndex: 1000,
  top: '0px',
  right: '-45px',
  border: '1px solid transparent',

  '&:hover': {
    backgroundColor: alpha(theme.palette.grey[100], 0.3),
  },
}))

const CloseIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: '1.25rem',
  color: theme.palette.common.white,
  transition: theme.transitions.create(['color']),
}))

export default VideoModal
