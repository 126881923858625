import { useEffect, useMemo, useState } from 'react'
import { useSnapshot } from 'valtio'
import { CurrentTestState, setItemResponseValidity } from '@app/storage'
import { InteractionType, SelectableChoiceConstraints } from '@app/types'
import { useUpdatingRef, useResponseUpdate, useStateReset } from '@app/helpers'

export type ChoiceOrderCallback = (id: string, index?: number) => void
export type UseChoiceOrder = {
  choiceOrder: string[]
  onToggle: ChoiceOrderCallback
  announcement: string
}

export const useResponseValidityUpdate = (
  id: string,
  length: number,
  choiceOrder: string[],
  constraints: SelectableChoiceConstraints = {},
): void => {
  const { minChoices, maxChoices } = constraints
  const selected = useMemo(() => choiceOrder.filter(item => item), [choiceOrder])
  const { currentTestPart } = useSnapshot(CurrentTestState)

  // We should watch itemId, but don't trigger response update when it changes.
  const idRef = useUpdatingRef(id)

  useEffect(() => {
    if (!idRef.current) return
    if (currentTestPart?.isFinished) return

    // If minChoices and maxChoices are defined,
    // response is valid when selected items count is within constraints.
    // Otherwise response is valid when all items are selected.
    const isResponseValid =
      minChoices && maxChoices
        ? selected.length >= minChoices && selected.length <= maxChoices
        : selected.length === length
    setItemResponseValidity(idRef.current, isResponseValid)
  }, [idRef, currentTestPart?.isFinished, selected.length, length, minChoices, maxChoices])
}

export const useChoiceOrder = (
  responseIdentifier: string,
  length: number,
  constraints: SelectableChoiceConstraints = {},
): UseChoiceOrder => {
  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)

  const [choiceOrder, setChoiceOrder] = useState<string[]>([])
  const [announcement, setAnnouncement] = useState<string>('')

  const onToggle = (id: string, index?: number) => {
    // If test is finished, don't change selection.
    if (currentTestPart?.isFinished) {
      return
    }

    setChoiceOrder(current => {
      const itemIndex = current.findIndex(item => item === id)
      const newChoiceOrder = [...current]

      // Handle drag-n-drop setup.
      if (index !== undefined) {
        newChoiceOrder[index] = id
        setAnnouncement(`Set item ${id} to position ${index + 1}`)

        if (itemIndex >= 0) {
          newChoiceOrder[itemIndex] = null
          setAnnouncement(`Moved item ${id} from position ${itemIndex + 1} to position ${index + 1}`)
          return newChoiceOrder
        }

        return newChoiceOrder
      }

      // Handle click setup.
      // If item is not in array, add it.
      if (itemIndex < 0) {
        // Set to first empty space, if any.
        const emptyIndex = newChoiceOrder.findIndex(item => !item)

        if (emptyIndex >= 0) {
          newChoiceOrder[emptyIndex] = id
          setAnnouncement(`Set item ${id} to position ${emptyIndex + 1}`)
          return newChoiceOrder
        }

        // If no empty space, push to the end
        setAnnouncement(`Set item ${id} to position ${newChoiceOrder.length + 1}`)
        return [...newChoiceOrder, id]
      }

      // If item is in array, replace it with null.
      newChoiceOrder[itemIndex] = null
      setAnnouncement(`Removed item ${id} from position ${itemIndex + 1}`)

      // Remove trailing nulls.
      while (newChoiceOrder[newChoiceOrder.length - 1] === null) {
        newChoiceOrder.pop()
      }

      return newChoiceOrder
    })
  }

  useResponseUpdate(currentItemResponse?.id, responseIdentifier, choiceOrder, InteractionType.graphicOrder)
  useResponseValidityUpdate(currentItemResponse?.id, length, choiceOrder, constraints)
  useStateReset(currentItemResponse?.id, responseIdentifier, setChoiceOrder)

  return { choiceOrder, onToggle, announcement }
}
