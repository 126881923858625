import { Box, IconButton, styled, Typography } from '@mui/material'
import { GRIP_BUTTON_SIZE } from '@app/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const QuestionsListWrapper = styled('ul')(
  ({ theme }) => `
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: ${theme.spacing(2, 0)};
    margin: 0;
    max-width: 495px;
    width: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    list-style-type: none;

    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: ${theme.palette.grey[100]};
      border-radius: ${theme.shape.borderRadius * 1.5}px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.palette.grey[300]};
    }

    ${[theme.breakpoints.down('mobile')]} {
      border: 1px solid ${theme.palette.grey[100]};
      border-left: none;
      max-width: 100%;
      padding: ${theme.spacing(2, 0)};
      overflow-x: scroll;
      ::-webkit-scrollbar {
        height: 0;
      }
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
    }

    ${[theme.breakpoints.down('mobile')]} {
      padding: ${theme.spacing(1, 0)};
      max-width: 100%;
    }
  `,
)

export const NavigationWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    width: 100%;
    overflow: auto;

    ${[theme.breakpoints.down('mobile')]} {
      & button {
        display: none;
      }
    }
  `,
)

export const QuestionTitleContainer = styled('div')(
  ({ theme }) => `
    ${[theme.breakpoints.down('mobile')]}  {
      display: none;
    }
  `,
)

export const QuestionsCarouselWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;

    ${[theme.breakpoints.down('mobile')]} {
      max-width: 100%;
      width: 100%;
    }

    ${[theme.breakpoints.down('mobile')]} {
      align-items: stretch;
    }
  `,
)

export const ButtonsWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    max-width: 35%;
    justify-self: flex-end;
    align-items: center;
    gap: ${theme.spacing(4)};

    ${[theme.breakpoints.down('mobile')]} {
      max-width: 100%;
      width: 100%;
      justify-content: flex-end;
    }

    ${[theme.breakpoints.down('mobile')]} {
      padding: ${theme.spacing(3, 4)};
      justify-content: center;
    }
`,
)

export const FooterWrapper = styled('div')(
  ({ theme }) => `
    display: flex;
    align-items: center;
    gap: ${theme.spacing(20)};
    justify-content: space-between;

    ${[theme.breakpoints.down('mobile')]} {
      flex-direction: column;
      gap: ${theme.spacing(0)};
    }
  `,
)

export const BubbleNavigationWrapper = styled(Box)(
  ({ theme }) => `
    max-width: 65%;

    ${[theme.breakpoints.down('mobile')]} {
      max-width: 100%;
      width: 100%;
    }
  `,
)

export const TypographyTitle = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.typography.h5.fontSize};
  margin-right: ${theme.spacing(2.5)};
  line-height: ${theme.typography.h5.lineHeight};
  color: ${theme.palette.grey[800]};
`,
) as typeof Typography

export const StyledFontAwesome = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}))

export const ScrollIcon = styled(StyledFontAwesome)(
  ({ theme }) => `
      color: ${theme.palette.additionalColors['bg_500']};
    `,
)

export const GripHorizontalIcon = styled(StyledFontAwesome)(
  ({ theme }) => `
    color: ${theme.palette.additionalColors['bg_500']};
    cursor: pointer;
  `,
)

export const ArrowIconButton = styled(IconButton)(
  ({ theme }) => `
     height: 30px;
     width: 30px;
  `,
)

export const GripIconButton = styled(IconButton)<{ opened: boolean }>(
  ({ theme, opened }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${GRIP_BUTTON_SIZE}px;
    height: ${GRIP_BUTTON_SIZE}px;
    ${opened ? `background-color: ${theme.palette.grey[100]} !important` : ''};
  `,
)

export const GripButtonWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('mobile')]: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRight: 'none',
    marginLeft: '-1px',
  },
}))
