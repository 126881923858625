import { FC } from 'react'
import { FormControl, SelectChangeEvent, SelectProps, styled } from '@mui/material'
import {
  SelectComponent,
  SelectComponentItem,
  SelectComponentProps,
} from '@app/components/ui/inputs/SelectComponent'
import { upperFirst } from '@app/helpers'
import { LineStyle } from '../types'

export interface LineStyleSelectProps extends Omit<SelectProps, 'onChange'> {
  onChange: (value: LineStyle, event?: SelectChangeEvent<any>) => void
}

export const LineStyleSelect: FC<LineStyleSelectProps> = props => {
  const { className, onChange, ...rest } = props

  const handleChange = (event: SelectChangeEvent<any>) => {
    onChange(event.target.value as LineStyle, event)
  }

  const handleItemKeyDown = (value: LineStyle) => () => {
    onChange(value)
  }

  return (
    <FormControl className={className}>
      <StyledSelect label='Line Type' size='small' onChange={handleChange} {...rest}>
        {Object.values(LineStyle).map(lineStyle => (
          <SelectComponentItem key={lineStyle} value={lineStyle} onKeyDown={handleItemKeyDown(lineStyle)}>
            {upperFirst(lineStyle)}
          </SelectComponentItem>
        ))}
      </StyledSelect>
    </FormControl>
  )
}

// Otherwise there are problems with styled engine.
const StyledSelect = styled((props: SelectComponentProps) => <SelectComponent {...props} />)(({ theme }) => ({
  minWidth: 'auto',
  height: 'auto',
  margin: 0,
  '& label': {
    color: theme.palette.additionalColors.bg_500,
  },

  [theme.breakpoints.down('mobile')]: {
    '& > .MuiInputBase-root': {
      height: 'auto',
    },
  },
}))
