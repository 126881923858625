import { FC } from 'react'
import { Button, Toolbar, Typography, styled } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBellOn } from '@fortawesome/pro-solid-svg-icons/faBellOn'

export type Props = {
  onUpdate?: () => void
}

const AppUpdateAlert: FC<Props> = ({ onUpdate }) => {
  return (
    <StyledToolbar aria-live='polite' role='alertdialog'>
      <FontAwesomeIcon icon={faBellOn} />
      <StyledTypography>
        Update now for a better test experience. Your progress will be unaffected.
      </StyledTypography>
      <Button color='inherit' size='small' variant='text' onClick={onUpdate}>
        UPDATE
      </Button>
    </StyledToolbar>
  )
}

const StyledToolbar = styled(Toolbar)(({ theme: { palette, spacing } }) => ({
  backgroundColor: palette.blue[600],
  color: palette.common.white,
  padding: spacing(3, 3, 3, 5),
  minHeight: 'auto',
}))

const StyledTypography = styled(Typography)(({ theme: { palette, spacing } }) => ({
  color: palette.common.white,
  flexGrow: 1,
  marginLeft: spacing(5),
}))

export default AppUpdateAlert
