import { FC, useEffect } from 'react'
import { Container, styled } from '@mui/material'
import { ScreenComponent, VersionLabel } from '@app/components/common'
import UserGreeting from './UserGreeting'
import LoginForm from './LoginForm'
import SettingsForm from './SettingsForm'
import { resetHeaderTitleState } from '@app/storage'
import { useLogin } from './hooks'

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const { user, onLogin, onLogout, loading, error, onResetError } = useLogin()

  useEffect(() => {
    resetHeaderTitleState()
  }, [])

  return (
    <StyledScreen>
      <StyledContainer>
        {user ? (
          <UserGreeting user={user} onLogout={onLogout} />
        ) : (
          <LoginForm onLogin={onLogin} loading={loading} error={error} onResetError={onResetError} />
        )}

        {(user?.role === 'Super Administrator' || user?.role === 'Course Author') && <SettingsForm />}

        <StyledVersionLabel />
      </StyledContainer>
    </StyledScreen>
  )
}

const StyledScreen = styled(ScreenComponent)`
  background: var(--bluegrey-200);
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;

  // button {
  //   margin-top: 1rem;
  // }
`

const StyledVersionLabel = styled(VersionLabel)(() => ({
  position: 'absolute',
  bottom: 0,
}))

export default Home
