import { FC, PropsWithChildren } from 'react'
import { Box, styled, typographyClasses } from '@mui/material'
import { ReviewModeLayout, TestTakingLayout, InteractionLayoutFooter } from './components'
import { GridLayout } from '../GridLayout'
import { scrollBarMixin, HIGHLIGHT_CONTAINER_QUESTION } from '@app/constants'

interface InteractionLayoutProps {
  reviewMode?: boolean
  itemId?: string
  large?: boolean
}

const InteractionLayout: FC<PropsWithChildren<InteractionLayoutProps>> = ({
  children,
  itemId,
  large,
  reviewMode = false,
}) => {
  return (
    <InteractionLayoutWrapper
      aria-label='Question block'
      large={large}
      // Disable test tools in review mode (Mark for Review, Highlight, Strikethrough).
      as={reviewMode ? ReviewModeLayout : TestTakingLayout}
      itemId={!reviewMode && itemId}
    >
      <InteractionWrapper key={itemId}>
        <GridLayout horizontal={large} id={HIGHLIGHT_CONTAINER_QUESTION}>
          {children}
        </GridLayout>
      </InteractionWrapper>
      <InteractionLayoutFooter />
    </InteractionLayoutWrapper>
  )
}

// TODO: Should we stick to absolute value for interaction max-width
// or use relative?
const InteractionLayoutWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'large',
})<{ large?: boolean; itemId?: string }>(
  ({ theme, large }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${theme.shape.borderRadius * 1.5}px;
  box-shadow: ${theme.shadows[2]};
  flex: 1 1 50%;
  overflow: auto;
  max-width: ${large ? '840px' : '570px'};
  background-color: ${theme.palette.common.white};
`,
)

const InteractionWrapper = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(8, 4)};
  margin: ${theme.spacing(0, 2)};
  flex-grow: 1;
  overflow: auto;

  ${scrollBarMixin(theme)};
  ${[theme.breakpoints.down('mobile')]} {
    padding: ${theme.spacing(4, 2)};
  }

  & p.${typographyClasses.root} {
    margin-bottom: ${theme.spacing(4)};
    ${[theme.breakpoints.down('mobile')]} {
      margin-bottom: ${theme.spacing(3)};
    }
  }
`,
)

export default InteractionLayout
