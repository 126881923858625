import React, { useEffect } from 'react'
import { Box, styled } from '@mui/material'
import { CalculatorContainer } from './CalculatorContainer'
import DraggableDialog from '@app/components/ui/modals/DraggableDialog'
import { useSnapshot } from 'valtio'
import { CurrentTestState } from '@app/storage'
import { resetCalculatorData } from '@app/helpers'

type Props = {
  open: boolean
  onClose: () => void
}

const DRAGGABLE_CALCULATOR_ID = 'DRAGGABLE_CALCULATOR_ID'

export const CalculatorDraggableModal: React.FC<Props> = ({ open, onClose }) => {
  const { currentItemResponse } = useSnapshot(CurrentTestState)

  useEffect(() => {
    resetCalculatorData()
  }, [currentItemResponse.itemId])

  return (
    <DraggableDialog id={DRAGGABLE_CALCULATOR_ID} open={open} title='Calculator' onClose={onClose}>
      <CalculatorWrapper>
        <CalculatorContainer itemId={currentItemResponse.itemId} />
      </CalculatorWrapper>
    </DraggableDialog>
  )
}

const CalculatorWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    width: '600px',
    height: '600px',
  },
  [theme.breakpoints.down('mobile')]: {
    width: '350px',
    height: '350px',
  },
}))
