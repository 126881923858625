export const BUBBLE_SIZES = {
  small: 'small',
  medium: 'medium',
}

export const PROMPT_CONTAINER_ID = 'prompt'
export const ZOOM_STEP = 0.1

export const HIGHLIGHT_CLASSNAME = 'highlight'
export const HIGHLIGHT_CONTAINER_QUESTION = 'highlight-question'
export const HIGHLIGHT_CONTAINER_PASSAGE = 'highlight-passage'