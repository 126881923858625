import { DomNodes } from '@app/constants'
import {
  checkValidityString,
  getRestChildren,
  useFindNodes,
  useItemResponse,
  useResponseDeclaration,
  useStateReset,
} from '@app/helpers'
import { CurrentTestState, setItemResponse } from '@app/storage'
import { Box, styled } from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSnapshot } from 'valtio'
import { GridInDesktop, GridInMobile } from './components'
import { useInputsValues } from './hooks'
import { getSymbols, getSymbolsStructure, setInitialValue } from './utils'
import { InteractionType } from '@app/types'

interface GridInInteractionProps {
  responseidentifier: string
  patternmask: string
  columncount: string
  rowcount: string
  children?: JSX.Element[]
}

export const GridInInteractionComponent: FC<GridInInteractionProps> = ({
  responseidentifier: responseIdentifier,
  patternmask: patternMask,
  columncount: columnCount,
  rowcount: rowCount,
  children,
}) => {
  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)
  const itemResponse = useItemResponse(currentItemResponse?.id, responseIdentifier)
  const [[gridInSymbols], restNodes] = [
    [...useFindNodes(children, [DomNodes.gridInSymbols])],
    getRestChildren(children, [DomNodes.gridInSymbols]),
  ]

  const [symbolsStructure, setSymbolsStructure] = useState(getSymbolsStructure(gridInSymbols))
  const [id, setId] = useState('')
  const responseDeclaration = useResponseDeclaration(id, responseIdentifier)

  const symbols = useMemo(
    () => getSymbols(Number(columnCount), symbolsStructure),
    [columnCount, symbolsStructure],
  )

  useEffect(() => {
    if (currentItemResponse?.id) {
      setSymbolsStructure(getSymbolsStructure(gridInSymbols))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemResponse?.id])

  const [inputValues, setColumnInputValue, setInputValues] = useInputsValues(
    symbols,
    Number(columnCount),
    itemResponse,
  )

  const isValid = patternMask ? checkValidityString(patternMask, inputValues.join('')) : true

  useEffect(() => {
    if (!currentItemResponse?.id) {
      return
    }

    setId(CurrentTestState.currentItemResponse.id)
  }, [currentItemResponse?.id, currentItemResponse])

  useEffect(() => {
    if (!currentItemResponse?.id || !responseDeclaration) return
    if (currentTestPart?.isFinished) return

    const response = inputValues.filter(res => res).length > 0 ? inputValues : []

    setItemResponse(
      currentItemResponse.id,
      responseIdentifier,
      response,
      isValid,
      InteractionType.gridIn,
      responseDeclaration.cardinality,
    )
  }, [
    inputValues,
    isValid,
    responseIdentifier,
    currentItemResponse?.id,
    currentTestPart?.isFinished,
    responseDeclaration,
  ])

  const transformRes = useCallback(
    (res: string[]) => (res.length > 0 ? res : setInitialValue(Number(columnCount))),
    [columnCount],
  )

  useStateReset(currentItemResponse?.id, responseIdentifier, setInputValues, transformRes)

  return (
    <GridInWrapper>
      {restNodes.length !== 0 && <GridInInfoWrapper>{restNodes}</GridInInfoWrapper>}
      <GridInDesktop
        columnCount={Number(columnCount)}
        rowCount={Number(rowCount)}
        symbols={symbols}
        symbolsStructure={symbolsStructure}
        inputValues={inputValues}
        setColumnInputValue={setColumnInputValue}
      />
      <GridInMobile
        columnCount={Number(columnCount)}
        symbols={symbols}
        symbolsStructure={symbolsStructure}
        inputValues={inputValues}
        setColumnInputValue={setColumnInputValue}
      />
    </GridInWrapper>
  )
}

const GridInWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  '& hr': {
    display: 'none',
  },

  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}))

const GridInInfoWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '260px',

  [theme.breakpoints.down('mobile')]: {
    maxWidth: '100%',
  },
}))
