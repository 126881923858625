import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'
import { DndContext, KeyboardSensor, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { Box, styled } from '@mui/material'
import { DATA_CLICK_AWAY_ID, DATA_CLICK_AWAY_PROPS, DomNodes } from '@app/constants'
import {
  transformChoicesConstraints,
  useDragDropAnnouncement,
  useFindNodes,
  useOutsideClick,
  useResponseUpdate,
  useStateReset,
} from '@app/helpers'
import { ConstraintsAlertContainer, ScreenReaderInfo } from '@app/components'
import { InteractionType, Shape } from '@app/types'
import { CurrentTestState } from '@app/storage'
import { DroppableArea, PositionedHotspot, PositionObject } from './components'
import { HotspotArea, StyledHotspotAreaOverlay } from '../HotspotArea'
import { PositionObjectInteraction } from '../PositionObjectInteraction'
import { useDropPoints, useDragDrop, useParentStyle, POINT_SEPARATOR } from './hooks'
import { DROPPABLE_ID, DRAGGABLE_ID, ARIA_LABELS } from './constants'

export interface PositionObjectStageProps {
  itemId: string
  children: JSX.Element | JSX.Element[]
}

export const PositionObjectStage: FC<PositionObjectStageProps> = props => {
  const { itemId, children } = props
  const [activeElement, setActiveElement] = useState<string>(null)

  const { currentItemResponse, currentTestPart } = useSnapshot(CurrentTestState)
  const [[prompt], [image], [interaction]] = useFindNodes(children, [
    DomNodes.prompt,
    DomNodes.object,
    DomNodes.positionObjectInteraction,
  ])
  const [[positionObject]] = useFindNodes(interaction.props.children, [DomNodes.object])
  const responseIdentifier = interaction.props.responseidentifier

  const containerRef = useRef<HTMLDivElement>(null)
  useParentStyle(containerRef, 'display:flex; flex-direction:column;')

  const imageRef = useRef<HTMLImageElement>(null)
  const svgRef = useRef<any>(null)

  const { points, onAddPoint, onSetPoint, onRemovePoint, setPoints, announcement } = useDropPoints(
    containerRef,
    svgRef,
    DROPPABLE_ID,
  )
  const { dragging, snapModifier, onDragStart, onDragEnd, onDragCancel } = useDragDrop(
    containerRef,
    onSetPoint,
  )

  useResponseUpdate(currentItemResponse?.id, responseIdentifier, points, InteractionType.positionObject)
  useStateReset(currentItemResponse?.id, responseIdentifier, setPoints)

  const [minChoices, maxChoices] = transformChoicesConstraints(
    interaction.props.minchoices,
    interaction.props.maxchoices,
  )

  const maxSelected = useMemo(() => maxChoices && points.length === maxChoices, [maxChoices, points.length])

  const handleClickOnSVGImage = useCallback(
    (event: React.MouseEvent) => {
      if (currentTestPart.isFinished) return
      if (maxSelected) return
      if (dragging) return

      const target = event.target as SVGGraphicsElement
      if (!target) return
      if (target.closest(`[${DATA_CLICK_AWAY_ID}]`)) return

      const domPoint = new DOMPoint(event.clientX, event.clientY)
      const coords = domPoint.matrixTransform(target.getScreenCTM().inverse())
      const point = [coords.x.toFixed(0), coords.y.toFixed(0)].join(POINT_SEPARATOR)

      onAddPoint(point)
    },
    [currentTestPart.isFinished, maxSelected, dragging, onAddPoint],
  )

  const handleClickOnPositionedElement = useCallback(
    (point: string) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setActiveElement(point)
    },
    [],
  )

  useOutsideClick(`[${DATA_CLICK_AWAY_ID}]`, () => setActiveElement(null))

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 15 } }),
    useSensor(KeyboardSensor),
  )

  const announcements = useDragDropAnnouncement({
    activeBlocks: {
      [DRAGGABLE_ID]: positionObject?.props?.accessibilityAttr
        ? positionObject.props.accessibilityAttr['aria-label']
        : ARIA_LABELS.POSITION_OBJECT,
    },
    overBlocks: {
      [DROPPABLE_ID]: image?.props?.accessibilityAttr
        ? image.props.accessibilityAttr['aria-label']
        : ARIA_LABELS.STAGE,
    },
    maxDrops: maxChoices,
    minDrops: minChoices,
    currentDrops: points.length,
  })

  return (
    <DndContext
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
      modifiers={[snapModifier]}
      sensors={sensors}
      accessibility={{ announcements }}
    >
      {prompt}

      <ConstraintsAlertContainer
        minChoices={minChoices}
        maxChoices={maxChoices}
        selectedCount={points.length}
        noAnnouncement
      />

      <Container ref={containerRef}>
        <StyledHotspotArea
          svgRef={svgRef}
          onClick={handleClickOnSVGImage}
          image={<image.type ref={imageRef} {...image.props} />}
          overlay={
            <StyledHotspotAreaOverlay>
              <DroppableArea id={DROPPABLE_ID} />
            </StyledHotspotAreaOverlay>
          }
        >
          {points.map(point => {
            return (
              <PositionedHotspot
                {...DATA_CLICK_AWAY_PROPS}
                key={point}
                itemId={itemId}
                identifier={point}
                shape={Shape.rect}
                point={point}
                focusable={false}
              >
                <PositionObject
                  itemId={itemId}
                  removable
                  onRemove={() => onRemovePoint(point)}
                  {...positionObject.props}
                  onClick={handleClickOnPositionedElement(point)}
                  selected={activeElement === point}
                  //aria-label={ARIA_LABELS.POSITIONED_OBJECT}
                />
              </PositionedHotspot>
            )
          })}
        </StyledHotspotArea>

        <StyledPositionObjectInteraction
          imageRef={imageRef}
          isDragging={dragging}
          pointsCount={points.length}
          {...interaction.props}
        />
      </Container>

      <ScreenReaderInfo ariaAtomic ariaLive='polite'>
        {announcement}
      </ScreenReaderInfo>
    </DndContext>
  )
}

const Container = styled(Box)(() => ({
  flexGrow: 1,
  position: 'relative',
}))

const StyledHotspotArea = styled(HotspotArea)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const StyledPositionObjectInteraction = styled(PositionObjectInteraction)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))
