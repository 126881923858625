import { useRef, useEffect, useState } from 'react'
import { styled } from '@mui/material'
import { StorageKeys } from '@app/constants'

interface Props {
  itemId: string
}

export const CalculatorContainer: React.FC<Props> = ({ itemId }) => {
  const calculatorElementRef = useRef<HTMLDivElement>(null)

  const [calculatorValue, setCalculatorValue] = useState<Desmos.Calculator>()
  const currentItemId = useRef(itemId)

  useEffect(() => {
    const calculator = Desmos.GraphingCalculator(calculatorElementRef.current)

    if (!calculatorElementRef.current) return

    const serializedState: string = sessionStorage.getItem(StorageKeys.Calculator)

    setCalculatorValue(calculator)

    if (serializedState) {
      try {
        calculator.setState(JSON.parse(serializedState))
      } catch (error) {
        console.warn('[CalculatorContainer]', error)
      }
    }

    return () => {
      try {
        sessionStorage.setItem(StorageKeys.Calculator, JSON.stringify(calculator.getState()))
      } catch (error) {
        console.warn('[CalculatorContainer]', error)
      }
    }
  }, [])

  useEffect(() => {
    if (!calculatorValue || currentItemId.current === itemId) return

    currentItemId.current = itemId

    try {
      calculatorValue.setBlank()
    } catch (error) {
      console.warn('[CalculatorContainer]', error)
    }
  }, [itemId, calculatorValue])

  return <StyledCalculatorContainer ref={calculatorElementRef} id='calculator' />
}

const StyledCalculatorContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
}))
