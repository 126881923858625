import { FC } from 'react'
import { DEFAULT_FONT_SIZE_CONTROL_OPTIONS } from './constants'
import { FontSizeControlContext } from './context'
import { useFontSizeControl } from './hooks'
import { FontSizeControlOptions } from './types'

export const FontSizeControlProvider: FC<React.PropsWithChildren & { options?: FontSizeControlOptions }> = ({
  children,
  options = DEFAULT_FONT_SIZE_CONTROL_OPTIONS,
}) => {
  const fontSizeControl = useFontSizeControl(options)

  return <FontSizeControlContext.Provider value={fontSizeControl}>{children}</FontSizeControlContext.Provider>
}
