import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faLessThan } from '@fortawesome/pro-solid-svg-icons/faLessThan'
import { faLessThanEqual } from '@fortawesome/pro-solid-svg-icons/faLessThanEqual'
import { faEquals } from '@fortawesome/pro-solid-svg-icons/faEquals'
import { faGreaterThanEqual } from '@fortawesome/pro-solid-svg-icons/faGreaterThanEqual'
import { faGreaterThan } from '@fortawesome/pro-solid-svg-icons/faGreaterThan'
import { faPi } from '@fortawesome/pro-solid-svg-icons/faPi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from '@mui/material'

export const MathFracIcon = () => {
  return (
    <StyledSvg
      width='12px'
      height='21px'
      viewBox='0 0 12 21'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>2CA257A9-06CC-4E9F-8741-A4F9C3E5F714</title>
      <g id='---STAAR' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Equation-Editor-Symbols'
          transform='translate(-28.000000, -36.000000)'
          fill='#546e7a'
          fillRule='nonzero'
        >
          <path
            d='M36.3333333,36.5 L36.3333333,44.2777778 L30.7777778,44.2777778 L30.7777778,36.5 L36.3333333,36.5 Z M35.2222222,37.6111111 L31.8888889,37.6111111 L31.8888889,43.1666667 L35.2222222,43.1666667 L35.2222222,37.6111111 Z M36.3333333,48.7222222 L36.3333333,56.5 L30.7777778,56.5 L30.7777778,48.7222222 L36.3333333,48.7222222 Z M35.2222222,49.8333333 L31.8888889,49.8333333 L31.8888889,55.3888889 L35.2222222,55.3888889 L35.2222222,49.8333333 Z M28,45.9444444 L39.1111111,45.9444444 L39.1111111,47.0555556 L28,47.0555556 Z'
            id='1'
          ></path>
        </g>
      </g>
    </StyledSvg>
  )
}

export const MathSqrtIcon = () => {
  return (
    <StyledSvg
      width='25px'
      height='21px'
      viewBox='0 0 25 21'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>757D3A93-4FF8-4DA1-A36E-43E22241E394</title>
      <g id='---STAAR' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Equation-Editor-Symbols'
          transform='translate(-252.000000, -35.000000)'
          fill='#546e7a'
          fillRule='nonzero'
        >
          <path
            d='M276.832262,35.8500977 L276.832262,36.9396567 L263.896102,36.9396567 L258.698771,55.8500977 L257.138414,55.8500977 L254.755374,46.5686686 L252,46.5686686 L252,45.653977 L256.734874,45.1428259 L258.698771,52.2020925 L263.102472,35.8500977 L276.832262,35.8500977 Z M275.177387,37.6681816 L275.177387,55.2991305 L265.259979,55.2991305 L265.259979,37.6681816 L275.177387,37.6681816 Z M274.075453,38.770116 L266.361913,38.770116 L266.361913,54.1971962 L274.075453,54.1971962 L274.075453,38.770116 Z'
            id='4'
          ></path>
        </g>
      </g>
    </StyledSvg>
  )
}

export const MathPowerIcon = () => {
  return (
    <StyledSvg
      width='19px'
      height='21px'
      viewBox='0 0 19 21'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>C3C51595-2E00-40FF-81FF-990317961892</title>
      <g id='---STAAR' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Equation-Editor-Symbols'
          transform='translate(-98.000000, -36.000000)'
          fill='#546e7a'
          fillRule='nonzero'
        >
          <path
            d='M116.245614,36.5 L116.245614,45.9736842 L109.578947,45.9736842 L109.578947,36.5 L116.245614,36.5 Z M115.192632,37.5526316 L110.631579,37.5526316 L110.631579,44.9210526 L115.192632,44.9210526 L115.192632,37.5526316 Z M107.473684,40.7105263 L107.473684,56.5 L98,56.5 L98,40.7105263 L107.473684,40.7105263 Z M106.421053,41.7631579 L99.0526316,41.7631579 L99.0526316,55.4473684 L106.421053,55.4473684 L106.421053,41.7631579 Z'
            id='2'
          ></path>
        </g>
      </g>
    </StyledSvg>
  )
}

export const MathIndexIcon = () => {
  return (
    <StyledSvg
      width='19px'
      height='21px'
      viewBox='0 0 19 21'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>A11BB766-A93B-401E-8958-320FD373056B</title>
      <g id='---STAAR' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Equation-Editor-Symbols'
          transform='translate(-175.000000, -36.000000)'
          fill='#546e7a'
          fillRule='nonzero'
        >
          <path
            d='M193.245614,47.0263158 L193.245614,56.5 L186.578947,56.5 L186.578947,47.0263158 L193.245614,47.0263158 Z M192.192632,48.0789474 L187.631579,48.0789474 L187.631579,55.4473684 L192.192632,55.4473684 L192.192632,48.0789474 Z M184.473684,36.5 L184.473684,52.2894737 L175,52.2894737 L175,36.5 L184.473684,36.5 Z M183.421053,37.5526316 L176.052632,37.5526316 L176.052632,51.2368421 L183.421053,51.2368421 L183.421053,37.5526316 Z'
            id='3'
          ></path>
        </g>
      </g>
    </StyledSvg>
  )
}

const StyledSvg = styled('svg')(({ theme }) => ({
  fill: theme.palette.grey[600],
}))

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
}))

interface IOperatorIconsValue {
  icon: JSX.Element | string
  label: string
}

export const operatorIcons = new Map<string, IOperatorIconsValue>([
  ['+', { icon: <StyledFontAwesomeIcon icon={faPlus} />, label: 'Plus' }],
  ['-', { icon: <StyledFontAwesomeIcon icon={faMinus} />, label: 'Minus' }],
  ['*', { icon: <StyledFontAwesomeIcon icon={faTimes} />, label: 'Multiplication' }],
  ['/', { icon: <MathFracIcon />, label: 'Division' }],
  ['<', { icon: <StyledFontAwesomeIcon icon={faLessThan} />, label: 'Less than' }],
  ['\\le', { icon: <StyledFontAwesomeIcon icon={faLessThanEqual} />, label: 'Less than equal' }],
  ['=', { icon: <StyledFontAwesomeIcon icon={faEquals} />, label: 'Equal' }],
  ['\\ge', { icon: <StyledFontAwesomeIcon icon={faGreaterThanEqual} />, label: 'Greater than equal' }],
  ['>', { icon: <StyledFontAwesomeIcon icon={faGreaterThan} />, label: 'Greater than' }],
  ['_', { icon: <MathIndexIcon />, label: 'Index' }],
  ['^', { icon: <MathPowerIcon />, label: 'Power' }],
  ['\\sqrt', { icon: <MathSqrtIcon />, label: 'Square root' }],
  ['\\pi', { icon: <StyledFontAwesomeIcon icon={faPi} />, label: 'PI' }],
  ['(', { icon: '( )', label: 'Brackets' }],
])

export const getOperatorIcon = (operator: string): [JSX.Element | string, string] =>
  operatorIcons.get(operator)
    ? [operatorIcons.get(operator).icon, operatorIcons.get(operator).label]
    : [operator, operator]
