import { useSnapshot } from 'valtio'
import { useTestPartFinishedCheck, useResponseUpdate, useStateReset } from '@app/helpers'
import { CurrentTestState } from '@app/storage'
import { transformStateToResponse, transformResponseToState } from '../helpers'
import { UseNumberLine } from '../types'
import { useIntervals } from './intervals'
import { InteractionType } from '@app/types'

export const useNumberLine = (responseId: string): UseNumberLine => {
  const { currentItemResponse } = useSnapshot(CurrentTestState)
  const isFinished = useTestPartFinishedCheck()

  const intervals = useIntervals(isFinished)

  useResponseUpdate(
    currentItemResponse?.id,
    responseId,
    intervals.intervals,
    InteractionType.numberLine,
    transformStateToResponse,
  )
  useStateReset(currentItemResponse?.id, responseId, intervals.setIntervals, transformResponseToState)

  return { intervals, disabled: isFinished }
}
