import { proxy } from 'valtio'
import { AssessmentStatus, UserAssessment, UserAssessmentSettings } from '@app/firebase/api'
import { devtools } from 'valtio/utils'
import { Timestamp } from 'firebase/firestore'

type CurrentUserAssessmentType = {
  userId: string
  userAssessmentId: string
  startedTimestamp: Timestamp
  assessmentId: string
  status: AssessmentStatus
  assessmentSettings: UserAssessmentSettings
  passedTime: number
  addedTime: number
  version: number
  hideInfoBoxes: boolean
  program: string
  subject: string
}

const initialState: CurrentUserAssessmentType = {
  userId: '',
  userAssessmentId: '',
  startedTimestamp: null,
  assessmentId: '',
  status: AssessmentStatus.new,
  assessmentSettings: null,
  passedTime: null,
  addedTime: null,
  version: null,
  hideInfoBoxes: false,
  program: '',
  subject: '',
}

export const CurrentUserAssessmentState = proxy<CurrentUserAssessmentType>(initialState)

export const resetUserAssessmentState = (): void => {
  CurrentUserAssessmentState.userId = initialState.userId
  CurrentUserAssessmentState.status = initialState.status
  CurrentUserAssessmentState.assessmentId = initialState.assessmentId
  CurrentUserAssessmentState.userAssessmentId = initialState.userAssessmentId
  CurrentUserAssessmentState.assessmentSettings = initialState.assessmentSettings
  CurrentUserAssessmentState.addedTime = initialState.addedTime
  CurrentUserAssessmentState.passedTime = initialState.passedTime
  CurrentUserAssessmentState.startedTimestamp = initialState.startedTimestamp
  CurrentUserAssessmentState.hideInfoBoxes = initialState.hideInfoBoxes
}

export const setUserId = (id: string) => {
  CurrentUserAssessmentState.userId = id
}

export const setUserAssessmentId = (id: string) => {
  CurrentUserAssessmentState.userAssessmentId = id
}

export const setUserAssessmentStatus = (status: AssessmentStatus) => {
  CurrentUserAssessmentState.status = status
}

export const setStartedTimestamp = (timestamp: Timestamp) => {
  CurrentUserAssessmentState.startedTimestamp = timestamp
}

export const setUserAssessmentSettings = (settings: UserAssessmentSettings) => {
  CurrentUserAssessmentState.assessmentSettings = settings
}

export const setAssessmentId = (id: string) => {
  CurrentUserAssessmentState.assessmentId = id
}

export const setUserAssessmentVersion = (version: number) => {
  CurrentUserAssessmentState.version = version
}

export const setUserAssessmentData = (userAssessment: UserAssessment) => {
  CurrentUserAssessmentState.status = userAssessment.status
  CurrentUserAssessmentState.assessmentId = userAssessment.assessmentId
  CurrentUserAssessmentState.assessmentSettings = userAssessment.assessmentSettings
  CurrentUserAssessmentState.addedTime = userAssessment.addedTime
  CurrentUserAssessmentState.passedTime = userAssessment.passedTime
  CurrentUserAssessmentState.version = userAssessment.version
  CurrentUserAssessmentState.startedTimestamp = userAssessment.startedTimestamp
  CurrentUserAssessmentState.hideInfoBoxes = userAssessment.hideInfoBoxes
}

export const setUserAssessmentPassedTime = (time: number) => {
  CurrentUserAssessmentState.passedTime = time
}

export const setUserAssessmentAddedTime = (time: number) => {
  CurrentUserAssessmentState.addedTime = time
}

export const setHideConstraints = (value: boolean) => {
  CurrentUserAssessmentState.hideInfoBoxes = value
}

export const setProgram = (program: string) => {
  CurrentUserAssessmentState.program = program
}

export const setSubject = (subject: string) => {
  CurrentUserAssessmentState.subject = subject
}

process.env.NODE_ENV === 'development' &&
  devtools(CurrentUserAssessmentState, { enabled: true, name: 'CurrentUserAssessmentState' })
