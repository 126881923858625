import { FC } from 'react'
import { alpha, Box, Button, styled } from '@mui/material'
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactPlayer from 'react-player'

interface Props {
  videoSrc: string
  isVideoModalOpen: boolean
  onClick: () => void
}

const VideoThumbnail: FC<Props> = ({ videoSrc, isVideoModalOpen, onClick }) => (
  <VideoThumbnailContainer>
    <StyledReactPlayerContainer>
      <ReactPlayer loop playing={!isVideoModalOpen} volume={0} url={videoSrc} />
    </StyledReactPlayerContainer>

    <VideoThumbnailOverlay onClick={onClick}>
      <WatchExplanationButton disableFocusRipple disableRipple>
        <WatchExplanationIcon icon={faPlay} />
        <WatchExplanationText>Watch Explanation</WatchExplanationText>
      </WatchExplanationButton>
    </VideoThumbnailOverlay>
  </VideoThumbnailContainer>
)

const VideoThumbnailContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: '400px',
  width: '100%',
  maxHeight: '225px',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '3px',
  border: `1px solid ${theme.palette.grey[100]}`,
}))

const VideoThumbnailOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.grey[50], 0.7),
  transition: theme.transitions.create(['background-color']),
  cursor: 'pointer',

  '&:hover, &:focus-within': {
    backgroundColor: alpha(theme.palette.blue[100], 0.3),

    '& button': {
      backgroundColor: theme.palette.blue[500],
    },
    '& p, & svg': {
      color: theme.palette.common.white,
    },
  },
}))

const StyledReactPlayerContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',

  '& video': {
    objectFit: 'cover',
  },
}))

const WatchExplanationButton = styled(Button)(({ theme }) => ({
  maxWidth: '170px',
  width: '100%',
  height: '30px',
  padding: theme.spacing(2, 3),
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '50px',
  backgroundColor: theme.palette.blue[100],
  transition: theme.transitions.create(['background-color']),

  '&:hover': {
    backgroundColor: theme.palette.blue[500],
  },
}))

const WatchExplanationText = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: '1.0625rem',
  fontWeight: 500,
  color: theme.palette.blue[800],
  margin: theme.spacing(0),
  transition: theme.transitions.create(['color']),
}))

const WatchExplanationIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: '0.8125rem',
  color: theme.palette.blue[800],
  transition: theme.transitions.create(['color']),
}))

export default VideoThumbnail
