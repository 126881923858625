import { Parser, Builder, BuilderOptions } from 'xml2js'

/**
 * XML data parser.
 * @param { String } xmlData xml in string format.
 * @returns { Promise<any> } parsed data promise.
 */
export const parse = (xmlData: string): Promise<any> => {
  const parser = new Parser({
    explicitArray: false,
    attrkey: 'attributes',
    charkey: 'data',
    normalize: true,
    trim: true,
  })
  return parser.parseStringPromise(xmlData)
}

/**
 * XML structure in string format builder.
 * @param { Object } data object that need to be converted to XML.
 * @returns { String } builded XML data.
 */
export const build = (data: any, config?: BuilderOptions): string => {
  const builder = new Builder({
    attrkey: 'attributes',
    charkey: 'data',
    ...config,
  })

  return builder.buildObject(data)
}
