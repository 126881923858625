import { focusStyle } from '@app/theme'
import { alpha, styled } from '@mui/material'
import React, { FC } from 'react'

export interface InputComponentProps {
  placeholder?: string
  error?: boolean
  maxWidth?: string
  expectedLength?: number
  value: string | number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  id?: string
  disabled?: boolean
  inline?: boolean
}

const InputComponent: FC<InputComponentProps> = props => <StyledInput {...props} />

interface StyledInputProps {
  error?: boolean
  maxWidth?: string
  expectedLength?: number
  inline?: boolean
}

export const StyledInput = styled('input')<StyledInputProps>(
  ({ theme, error = false, maxWidth, expectedLength, inline }) => ({
    border: `1px solid ${error ? theme.palette.red[400] : theme.palette.grey[200]}`,
    outline: `3px solid ${error ? alpha(theme.palette.red[300], 0.2) : 'transparent'}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5, 3),
    margin: theme.spacing(1),
    color: theme.palette.grey[800],
    fontSize: '1rem',
    lineHeight: '1.5rem',
    transition: theme.transitions.create(['background-color', 'border-color', 'outline']),
    maxHeight: '37px',
    maxWidth: `${maxWidth || '150px'}`,
    width: `${expectedLength ? `${expectedLength / 2}em` : '100%'}`,
    ...(inline ? { margin: theme.spacing(0.5, 2) } : {}),

    ':hover': {
      backgroundColor: 'rgba(236,239,241,0.5)',
    },
    ':focus': {
      ...focusStyle(theme).border,
    },

    [theme.breakpoints.down('mobile')]: {
      fontSize: '0.875rem',
      lineHeight: '1rem',
      ...(inline ? { margin: theme.spacing(0.5, 1) } : {}),
    },
  }),
)

export default InputComponent
