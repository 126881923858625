import { DomNodes } from '@app/constants'
import parse, { Element as ParseElement, attributesToProps, domToReact } from 'html-react-parser'
import { styled } from '@mui/material'
import { forwardRef, isValidElement, useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { CurrentTestState, ExtractedImagesState } from '@app/storage'
import { ImageMagnifier } from './imageMagnifier'

export interface PackagedImageProps {
  itemId: string
  resourceId: string
  componentNode: DomNodes
  src: string
  alt?: string
  fullWidth?: boolean
  className?: string
  style?: React.CSSProperties
  tabIndex?: number
  allowZoom?: boolean
  accessibilityAttr?: { [key: string]: string }
}

const MIME_TYPES = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  svg: 'image/svg+xml',
}

const PackagedImageBase = forwardRef<any, PackagedImageProps>((props, ref) => {
  const {
    src,
    alt = '',
    itemId,
    resourceId,
    componentNode,
    fullWidth,
    allowZoom = true,
    accessibilityAttr,
    ...imageProps
  } = props
  const { images } = useSnapshot(ExtractedImagesState)
  const [svgData, setSvgData] = useState('')
  const [source, setSource] = useState('')

  useEffect(() => {
    if (src?.includes('http') || src?.includes('base64')) {
      setSource(src)
      return
    }

    const imageResourceId = resourceId || CurrentTestState.currentTest.resourceId

    if (images.length && src) {
      const rootSrc = src.includes('../') ? src.replaceAll('../', '') : src
      const image = ExtractedImagesState.images.find(
        image => image.resourceIds.some(resId => resId === imageResourceId) && image.path.includes(rootSrc),
      )

      if (!image) {
        return
      }
      const extension = src.split('.').pop()
      const mimeType = MIME_TYPES[extension as keyof typeof MIME_TYPES] || MIME_TYPES.jpeg
      if (mimeType === MIME_TYPES.svg) {
        setSvgData(image.imageData)
        return
      }
      setSource(`data:${mimeType};base64,${image.imageData}`)
    }
  }, [images, images.length, resourceId, src])

  const ImageComponent = allowZoom ? ImageMagnifier : 'img'

  const cleanSvgData = (svg: string) => {
    if (!svg) return

    const parsedSvg = parse(svg, {
      replace: domNode => {
        const element = domNode as ParseElement

        // Apply className and styles from image tag to parsed SVG.
        if (element.name === 'svg') {
          const props = attributesToProps(element.attribs)

          return (
            <svg
              {...props}
              style={{ ...props.style, ...imageProps.style }}
              className={`${imageProps.className ?? ''} ${props.className ?? ''}`}
            >
              {domToReact(element.children)}
            </svg>
          )
        }
      },
    })

    if (!Array.isArray(parsedSvg)) return

    return parsedSvg.filter(item => isValidElement(item))
  }

  return (
    <>
      {svgData ? (
        allowZoom ? (
          <ImageMagnifier ref={ref}>{cleanSvgData(svgData)}</ImageMagnifier>
        ) : (
          cleanSvgData(svgData)
        )
      ) : null}
      {source && <ImageComponent ref={ref} src={source} alt={alt} {...imageProps} />}
    </>
  )
})

export const PackagedImage = styled(PackagedImageBase)(({ fullWidth = true }) =>
  fullWidth ? { width: '100%', height: 'auto' } : {},
)
