import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useSnapshot } from 'valtio'
import { DragCancelEvent, DragEndEvent, DragStartEvent, Modifier } from '@dnd-kit/core'
import { Coordinates } from '@dnd-kit/utilities'
import { CurrentTestState } from '@app/storage'
import { getScrollParent, getScrollOffset, snapCenterToCursor, getDropPosition } from './utils'

export const useRelativeWidth = (
  sourceRef: React.MutableRefObject<HTMLElement>,
  sourceInitialWidth: number,
  targetInitialWidth: number,
) => {
  const [targetWidth, setTargetWidth] = useState(targetInitialWidth)

  useEffect(() => {
    if (!sourceRef.current) {
      return
    }

    const imageBoundaries = sourceRef.current.getBoundingClientRect()
    const ratio = imageBoundaries.width / sourceInitialWidth
    const relativeWidth = targetInitialWidth * ratio

    setTargetWidth(relativeWidth)
  }, [sourceRef, sourceInitialWidth, targetInitialWidth])

  return targetWidth
}

export const useParentStyle = (containerRef: React.MutableRefObject<HTMLElement>, style: string) => {
  useEffect(() => {
    if (!containerRef.current || !containerRef.current.parentElement) {
      return
    }

    containerRef.current.parentElement.setAttribute('style', style)
  }, [containerRef, style])
}

export const POINT_SEPARATOR = ' '

type SetPointCallback = (
  event: DragEndEvent,
  initialScrollOffset?: Coordinates,
  initialCursorOffset?: Coordinates,
) => void

export type UseDropPoints = {
  points: string[]
  onSetPoint: SetPointCallback
  onRemovePoint: (point: string) => void
  onAddPoint: (point: string) => void
  setPoints: React.Dispatch<React.SetStateAction<string[]>>
  announcement: string
}

export const useDropPoints = (
  containerRef: React.MutableRefObject<HTMLElement>,
  svgRef: React.MutableRefObject<SVGSVGElement>,
  droppableId: string,
): UseDropPoints => {
  const [points, setPoints] = useState<string[]>([])
  const [announcement, setAnnouncement] = useState<string>('')
  const { currentTestPart } = useSnapshot(CurrentTestState)

  const onAddPoint = useCallback(
    (point: string) => {
      if (currentTestPart?.isFinished) {
        return
      }

      setPoints(current => [...current, point])
      setAnnouncement('Positioned object.')
    },
    [currentTestPart?.isFinished],
  )

  const onSetPoint = useCallback(
    (event: DragEndEvent, initialScrollOffset?: Coordinates, initialCursorOffset?: Coordinates) => {
      if (currentTestPart?.isFinished) {
        return
      }

      if (event.over?.id !== droppableId) {
        return
      }

      const domPoint = getDropPosition(containerRef, event, initialScrollOffset, initialCursorOffset)
      const svgPoint = domPoint.matrixTransform(svgRef.current?.getScreenCTM().inverse())
      const point = [svgPoint.x.toFixed(0), svgPoint.y.toFixed(0)].join(POINT_SEPARATOR)

      onAddPoint(point)
    },
    [currentTestPart?.isFinished, droppableId, containerRef, svgRef, onAddPoint],
  )

  const onRemovePoint = useCallback(
    (point: string) => {
      if (currentTestPart?.isFinished) {
        return
      }

      setPoints(current => current.filter(item => item !== point))
      setAnnouncement('Removed object.')
    },
    [currentTestPart?.isFinished],
  )

  return { points, onSetPoint, onRemovePoint, onAddPoint, setPoints, announcement }
}

type UseDragDrop = {
  dragging: boolean
  initialScrollOffset: Coordinates
  initialCursorOffset: Coordinates
  snapModifier: Modifier
  onDragStart: (event: DragStartEvent) => void
  onDragEnd: (event: DragEndEvent) => void
  onDragCancel: (event: DragCancelEvent) => void
  setDragging: React.Dispatch<React.SetStateAction<boolean>>
  setInitialScrollOffset: React.Dispatch<React.SetStateAction<Coordinates>>
  setInitialCursorOffset: React.Dispatch<React.SetStateAction<Coordinates>>
}

export const useDragDrop = (
  containerRef: React.MutableRefObject<HTMLElement>,
  onDrop: SetPointCallback,
): UseDragDrop => {
  const [dragging, setDragging] = useState<boolean>(false)

  // If container is scrolled when drag started, need to substract that value from the drop position.
  const [initialScrollOffset, setInitialScrollOffset] = useState<Coordinates>(null)
  // If center of draggable image snaps to the cursor, need to count that as well.
  const [initialCursorOffset, setInitialCursorOffset] = useState<Coordinates>(null)

  const onSnap = useCallback((offsetX: number, offsetY: number) => {
    // Set only when first grabbed.
    setInitialCursorOffset(current => current || { x: offsetX, y: offsetY })
  }, [])

  const snapModifier = useMemo(() => snapCenterToCursor(onSnap), [onSnap])

  const onDragStart = useCallback(
    (event: DragStartEvent) => {
      setInitialScrollOffset(getScrollOffset(getScrollParent(containerRef.current), 'bottom'))
      setDragging(true)
    },
    [containerRef],
  )

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      setDragging(false)
      setInitialScrollOffset(null)
      setInitialCursorOffset(null)
      onDrop(event, initialScrollOffset, initialCursorOffset)
    },
    [initialCursorOffset, initialScrollOffset, onDrop],
  )

  const onDragCancel = useCallback((event: DragCancelEvent) => {
    setDragging(false)
    setInitialScrollOffset(null)
    setInitialCursorOffset(null)
  }, [])

  return {
    dragging,
    initialScrollOffset,
    initialCursorOffset,
    snapModifier,
    onDragStart,
    onDragEnd,
    onDragCancel,
    setDragging,
    setInitialScrollOffset,
    setInitialCursorOffset,
  }
}

export const usePositionSetup = <T extends SVGGraphicsElement = any>() => {
  const sourceRef = useRef<T>(null)
  const targetRef = useRef<T>(null)

  useEffect(() => {
    if (sourceRef.current && targetRef.current) {
      const sourceBBox = sourceRef.current.getBBox()
      targetRef.current.setAttribute('x', sourceBBox.x.toString())
      targetRef.current.setAttribute('y', sourceBBox.y.toString())
      targetRef.current.setAttribute('width', sourceBBox.width.toString())
      targetRef.current.setAttribute('height', sourceBBox.height.toString())
    }
  })

  return [sourceRef, targetRef, sourceRef.current]
}
