import { FC } from 'react'
import ScreenHeader from './ScreenHeader'
import { AssessmentTest } from '@app/models'

export interface ScreenProps {
  headerIcon?: JSX.Element
  children?: React.ReactNode
  test?: AssessmentTest
}

const ScreenComponent: FC<ScreenProps> = ({ children, headerIcon, test }) => {
  return (
    <>
      <ScreenHeader icon={headerIcon} test={test} />
      {children}
    </>
  )
}

export default ScreenComponent
