import partition from 'lodash/partition'
import { FeedbackPercentages, FeedbackItem } from '@app/types'
import { DEFAULT_PAD_LENGTH, FULL_PAD_LENGTH } from '@app/constants'

export const upperFirst = (value: string): string => [value[0].toUpperCase(), value.slice(1)].join('')

export const percentageFormatter = Intl.NumberFormat('en-US', { style: 'percent' })

export const getFeedbackMessage =
  (groupStatistics: Record<string, number>, feedbackMessage?: string) => (child: any) => ({
    key: child.props.identifier,
    percentage: groupStatistics[child.props.identifier] ?? 0,
    content: feedbackMessage ?? child.props.children,
  })

const defaultIsCorrect = (correctResponse: string[], value: string) =>
  correctResponse?.includes(value) ?? false

export const getFeedbackItems = (
  elements: JSX.Element[],
  groupStatistics: Record<string, number>,
  correctResponse?: string[],
  isCorrect = defaultIsCorrect,
) => {
  const [correctOptions, incorrectOptions] = partition(
    elements,
    child => isCorrect?.(correctResponse, child.props.identifier) ?? false,
  )

  const feedbackItems: any[] = [
    {
      key: 'correct',
      correct: true,
      messages: correctOptions.map(getFeedbackMessage(groupStatistics)),
    },
    {
      key: 'incorrect',
      correct: false,
      messages: [
        ...incorrectOptions.map(getFeedbackMessage(groupStatistics)),
        {
          key: 'none',
          percentage: groupStatistics['none'],
          content: 'No Answer',
        },
      ],
    },
  ]

  return feedbackItems
}

export const getElementFeedbackItems = (
  element: JSX.Element,
  groupStatistics: Record<string, number>,
  correctResponse?: string[],
) => {
  if (correctResponse?.includes(element.props.identifier)) {
    return [
      {
        key: 'correct',
        correct: true,
        messages: [getFeedbackMessage(groupStatistics, 'Correct')(element)],
      },
    ]
  }

  return [
    {
      key: 'incorrect',
      correct: false,
      messages: [getFeedbackMessage(groupStatistics, 'Incorrect')(element)],
    },
  ]
}

export const getFeedbackItemsFromPercentages = (percentages: FeedbackPercentages) => {
  const feedbackItems: FeedbackItem[] = [
    {
      key: 'correct',
      correct: true,
      messages: [
        {
          key: 'correct',
          percentage: percentages.correct,
          content: 'Correct',
        },
      ],
    },
    {
      key: 'incorrect',
      correct: false,
      messages: [
        {
          key: 'incorrect',
          percentage: percentages.incorrect,
          content: 'Incorrect',
        },
        {
          key: 'no-answer',
          percentage: percentages.noAnswer,
          content: 'No Answer',
        },
      ],
    },
  ]

  return feedbackItems
}

export const getPercentagesPadLength = (percentages: FeedbackPercentages): number => {
  if (!percentages) {
    return DEFAULT_PAD_LENGTH
  }

  const maxPercentage = Math.max(percentages.correct, percentages.incorrect, percentages.noAnswer)

  return maxPercentage === 1 ? FULL_PAD_LENGTH : DEFAULT_PAD_LENGTH
}
