import { useMemo } from 'react'
import { useSnapshot } from 'valtio'
import sum from 'lodash/sum'
import { ScoringState } from '@app/storage'
import { AssessmentItemResponse, ScoringByItem } from '@app/models'
import {
  PracticeTestAssessmentKeyItem,
  ResponseByPracticeTestPointValue,
  ResponseByPracticeTestResponseValue,
  ResponseByPracticeTestSeqResponseValue,
  ResponseByPracticeTestValueType,
  ResponsesByPracticeTest,
  ResponsesByPracticeTestItemResponseDeclaration,
} from '@app/types'

export const isResponseCorrect = (scoring: ScoringByItem, itemId: string): boolean => {
  const scoringItem = scoring?.byItem[itemId]

  if (!scoringItem) return

  return scoringItem.possible === scoringItem.raw
}

export const getCountResponsePercentage = (
  response: ResponseByPracticeTestResponseValue | ResponseByPracticeTestPointValue,
  totalStudentsCount: number,
): number => response.count / totalStudentsCount

export const getChoiceCorrectPercentage = (
  responses: ResponseByPracticeTestResponseValue[],
  responseValue: string,
  totalStudentsCount: number,
): number => {
  const correctResponseData = responses.find(response => response.response === responseValue)

  if (!correctResponseData) return 0

  return getCountResponsePercentage(correctResponseData, totalStudentsCount)
}

export const getSequenceResponsePercentage = (
  response: ResponseByPracticeTestSeqResponseValue,
  totalStudentsCount: number,
): number => response.seqCounts[0].count / totalStudentsCount

export const getInlineCorrectPercentage = (
  responses: ResponseByPracticeTestSeqResponseValue[],
  responseValue: string,
  totalStudentsCount: number,
): number => {
  const correctResponseData = responses.find(response => response.response === responseValue)

  if (!correctResponseData) return 0

  // const correctPercentages = correctResponseData.seqCounts.map(
  //   seqCount => seqCount.count / totalStudentsCount,
  // )
  // const avgPercentage = sum(correctPercentages) / correctPercentages.length

  // return avgPercentage

  return getSequenceResponsePercentage(correctResponseData, totalStudentsCount)
}

export const getPointCorrectPercentage = (
  responses: ResponseByPracticeTestPointValue[],
  possibleScore: number,
  totalStudentsCount: number,
): number => {
  const correctResponseData = responses.find(response => response.points === possibleScore)

  if (!correctResponseData) return 0

  return getCountResponsePercentage(correctResponseData, totalStudentsCount)
}

export const getPercentagesForResponseDeclaration = (
  responseDeclaration: ResponsesByPracticeTestItemResponseDeclaration,
  correctAnswerItem: PracticeTestAssessmentKeyItem,
  totalStudentsCount: number,
): number[] => {
  const correctAnswerResponseDeclaration = correctAnswerItem.responses.find(
    response => response.responseDeclarationId === responseDeclaration.responseDeclarationId,
  )
  const correctPercentages = correctAnswerResponseDeclaration.response.map((responseValue: string) => {
    // For choice-based interaction.
    if (responseDeclaration.responseType === ResponseByPracticeTestValueType.RESPONSE_VALUE) {
      return getChoiceCorrectPercentage(
        responseDeclaration.responses as ResponseByPracticeTestResponseValue[],
        responseValue,
        totalStudentsCount,
      )
    }

    // For sequence-based interaction.
    if (responseDeclaration.responseType === ResponseByPracticeTestValueType.SEQ_RESPONSE_VALUE) {
      return getInlineCorrectPercentage(
        responseDeclaration.responses as ResponseByPracticeTestSeqResponseValue[],
        responseValue,
        totalStudentsCount,
      )
    }

    // Otherwise it's point-based interaction.
    return getPointCorrectPercentage(
      responseDeclaration.responses as ResponseByPracticeTestPointValue[],
      correctAnswerResponseDeclaration.possible,
      totalStudentsCount,
    )
  })

  return correctPercentages
}

export const isGroupResponseCorrect = (
  groupTestScoring: ResponsesByPracticeTest,
  groupCorrectAnswers: PracticeTestAssessmentKeyItem[],
  itemId: string,
  successThreshold: number,
) => {
  const scoringItem = groupTestScoring.items.find(item => item.itemId === itemId)
  const correctAnswerItem = groupCorrectAnswers.find(answerItem => answerItem.itemId === itemId)

  if (!scoringItem || !correctAnswerItem) return undefined

  const correctPercentages = scoringItem.responseDeclarations
    .map(responseDeclaration =>
      getPercentagesForResponseDeclaration(
        responseDeclaration,
        correctAnswerItem,
        groupTestScoring.studentCount,
      ),
    )
    .flat()

  const avgPercentage = sum(correctPercentages) / correctPercentages.length

  return avgPercentage > successThreshold
}

export const useItemResponsesScoring = (
  itemsResponses: AssessmentItemResponse[],
  successThreshold: number,
) => {
  const { testScoring, groupMode, groupTestScoring, groupCorrectAnswers } = useSnapshot(ScoringState)

  const responsesScoring = useMemo(() => {
    if (!groupMode) {
      return itemsResponses.map(itemResponse =>
        isResponseCorrect(testScoring as ScoringByItem, itemResponse.itemId),
      )
    }

    if (!groupTestScoring) return []

    return itemsResponses.map(itemResponse =>
      isGroupResponseCorrect(
        groupTestScoring as ResponsesByPracticeTest,
        groupCorrectAnswers as PracticeTestAssessmentKeyItem[],
        itemResponse.itemId,
        successThreshold,
      ),
    )
  }, [groupCorrectAnswers, groupMode, groupTestScoring, itemsResponses, successThreshold, testScoring])

  return responsesScoring
}
