import { FC, useRef, MouseEvent } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { alpha, styled } from '@mui/material'
import { useSVGScale } from '@app/helpers'
import { SafeCurve, SafeCurveProps } from './SafeCurve'
import { ColorIndexGroup } from './ColorIndexGroup'
import { DraggableElement } from '../types'
import { getAsymptoteLabel, getGraphColor } from '../helpers'
import { focusStyle } from '@app/theme'

const DEFAULT_HALO_SIZE = 16

export interface DraggableAsymptoteProps extends SafeCurveProps {
  haloSize?: number
  colorIndex?: number
  selected?: boolean
  disabled?: boolean
  onSelect?: (id: string) => void
}

export const DraggableAsymptote: FC<DraggableAsymptoteProps> = props => {
  const { haloSize = DEFAULT_HALO_SIZE, colorIndex = 0, selected, disabled, onSelect, ...rest } = props

  // REVIEW: Do we really need to use "scale" here?
  const x = props.scale.x(props.data[0]._x)
  const y = props.scale.y(props.data[0]._y) - haloSize / 2
  const width = props.scale.x(props.data[1].x)
  const draggableId = props.id instanceof Function ? props.id() : props.id

  const haloRef = useRef<any>()
  const svgScale = useSVGScale(haloRef)

  const { setNodeRef, listeners, attributes, transform, isDragging } = useDraggable({
    id: draggableId,
    disabled,
    data: { type: DraggableElement.ASYMPTOTE, _y: props.data[0]._y },
    attributes: {
      tabIndex: disabled ? -1 : 0,
      roleDescription: 'draggable asymptote',
    },
  })
  const ref = (el: any) => setNodeRef(el)

  const wrapperStyle = transform
    ? {
        transform: `translate(${transform.x / svgScale[0]}px, ${transform.y / svgScale[1]}px)`,
      }
    : undefined

  const customListeners = {
    ...listeners,
    ...(onSelect && {
      onClick: (event: MouseEvent<SVGGElement>) => {
        onSelect(draggableId)
        listeners?.onClick && listeners.onClick(event)
      },
    }),
  }

  return (
    <StyledAsymptoteWrapper
      ref={ref}
      className='asymptote'
      colorIndex={colorIndex}
      selected={selected || isDragging}
      disabled={disabled}
      {...customListeners}
      {...attributes}
      style={wrapperStyle}
      aria-label={getAsymptoteLabel(props.data[0])}
    >
      <StyledAsymptoteHalo ref={haloRef} x={x} y={y} width={width} height={haloSize} role='presentation' />
      <StyledAsymptote {...rest} />
    </StyledAsymptoteWrapper>
  )
}

const StyledAsymptoteWrapper = styled(ColorIndexGroup)(({ theme, colorIndex = 0, selected, disabled }) => ({
  touchAction: 'none',
  cursor: disabled ? 'auto' : 'pointer',

  ':focus': !selected
    ? {
        outline: focusStyle(theme).border.border,
        '& > rect': {
          outline: focusStyle(theme).border.outline,
          stroke: 'none !important',
        },
      }
    : { outline: 'none' },

  [`${StyledAsymptoteHalo}`]: selected
    ? {
        fill: alpha(getGraphColor(theme, 500, colorIndex), 0.4),
        stroke: theme.palette.grey[600],
        strokeWidth: 2,
        strokeDasharray: '4 2',
      }
    : {
        fill: alpha(getGraphColor(theme, 500, colorIndex), 0.2),
      },
  [`:hover ${StyledAsymptoteHalo}`]: disabled
    ? undefined
    : {
        fill: alpha(getGraphColor(theme, 500, colorIndex), 0.4),
      },
  [`:focus ${StyledAsymptoteHalo}`]: disabled
    ? undefined
    : {
        stroke: theme.palette.grey[600],
        strokeWidth: 2,
        strokeDasharray: '4 2',
      },
}))

const StyledAsymptoteHalo = styled('rect')(() => ({}))

const StyledAsymptote = styled(SafeCurve)(() => ({
  strokeDasharray: '9, 5',
}))
