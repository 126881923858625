import { FC } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { styled } from '@mui/material'
import { PackagedImage } from '@app/components'
import { combineListeners } from '@app/helpers'
import { GapImgComponent, GapImgComponentProps } from '../GapImg'

export interface DraggableGapImgProps extends GapImgComponentProps {
  id: string
  onClick?: () => void
}

export const DraggableGapImg: FC<DraggableGapImgProps> = props => {
  const { id, ...gapImgProps } = props

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id,
    data: { ...gapImgProps.accessibilityAttr },
  })

  return (
    <StyledGapImg
      ref={setNodeRef}
      tabIndex='0'
      dragging={isDragging}
      {...listeners}
      {...attributes}
      {...gapImgProps}
      {...combineListeners(listeners, gapImgProps)}
    />
  )
}

const StyledGapImg = styled(GapImgComponent)(() => ({
  width: '100%',
  height: '100%',

  [`${PackagedImage}`]: {
    width: '100%',
    height: '100%',
  },
}))
