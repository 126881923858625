import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import Delta from 'quill-delta'

const POSSIBLE_ACTIVE_ELEMENTS = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

export const editorFormats = ['bold', 'italic', 'underline', 'list', 'bullet', 'indent']

export const selectionText = (editor: any): [number, number] => {
  const range = editor.getSelection()
  return [range.index, range.length]
}

export const convertDeltaToHtml = (content: any) => {
  const converter = new QuillDeltaToHtmlConverter(content.ops, {})
  return converter.convert()
}

export const cutText = async (editor: any) => {
  const [index, length] = selectionText(editor)

  if (length !== 0) {
    const contentDelta = editor.getContents(index, length)
    const html = convertDeltaToHtml(contentDelta)

    await navigator.clipboard.writeText(html)
    editor?.deleteText(index, length, 'user')
  }
}

export const copyText = async (editor: any) => {
  const [index, length] = selectionText(editor)
  const contentLength = editor.getLength()

  const contentDelta = length === 0 ? editor.getContents(0, contentLength) : editor.getContents(index, length)
  const html = convertDeltaToHtml(contentDelta)

  await navigator.clipboard.writeText(html)
}

export const pasteText = async (editor: any) => {
  const [index, length] = selectionText(editor)
  const html = await navigator.clipboard.readText()
  const contentLength = editor.getLength()
  const contentDelta = editor.clipboard.convert(html)

  if (length === 0 && contentLength === index + 1) {
    editor.updateContents(new Delta().retain(index).concat(contentDelta)).delete(index)
  } else {
    editor?.deleteText(index, length)
    editor.updateContents(new Delta().retain(index).concat(contentDelta)).delete(index)
  }
}

export const unDo = (editor: any) => editor?.history?.undo()

export const reDo = (editor: any) => editor?.history?.redo()

export   const moveFocusToNextElement = (editor: any) => {
  const activeElement = document.activeElement
  const quillEditorContainer = (editor as any).container

  if (activeElement === quillEditorContainer || quillEditorContainer.contains(activeElement)) {
    const focusableElements =
      Array.from(
        document.querySelectorAll(
          POSSIBLE_ACTIVE_ELEMENTS,
        ),
      )?.filter(element => !(element as any).disabled) ?? []

    if (!focusableElements?.length) return

    const currentIndex = focusableElements.findLastIndex(element => quillEditorContainer.contains(element))

    if (currentIndex < 0) return

    const nextIndex = currentIndex + 1
    if (nextIndex < focusableElements.length) {
      ;(focusableElements[nextIndex] as any).focus()
    }
  }
}
