import { FC, useEffect, useState, useRef, useMemo } from 'react'
import { useSnapshot } from 'valtio'
import { Button, Tooltip, Typography } from '@mui/material'
import { CurrentTestState, completeTest } from '@app/storage'
import { Feedback, NavigationMode, TestFeedbackAccess } from '@app/types'
import { AssessmentItemResponse } from '@app/models'
import {
  BasicModal,
  ButtonsWrapper,
  FooterWrapper,
  ItemsListModal,
  SubmitTestModal,
  TestFeedbackComponent,
  ItemList,
  BubbleNavigationWrapper,
} from '@app/components'
import { TestReviewNavigator } from './TestReviewNavigator'
import { TestTakingNavigator } from './TestTakingNavigator'
import {
  FINISH_TEST_BTN_ATTRIBUTES,
  RETURN_TO_DASHBOARD_BTN_ATTRIBUTES,
  FINAL_FEEDBACK_MODAL_ATTRIBUTES,
  BACK_TO_OVERVIEW_BTN_ATTRIBUTES,
  SUCCESS_THRESHOLD,
} from '@app/constants'
import { FeedbackEvent, useFindUnAnsweredItems, useItemResponsesScoring, useTestCallback } from '@app/helpers'
import StudentFeedbackModalContent from '../StudentFeedbackModalContent'

interface ItemsCarouselProps {
  onChange: (id: string) => void
}

export const ItemsNavigator: FC<ItemsCarouselProps> = ({ onChange }) => {
  const { currentTestPart, itemsResponses, currentTest } = useSnapshot(CurrentTestState)

  const [openModal, setOpenModal] = useState(false)
  const [openSubmitModal, setOpenSubmitModal] = useState(false)
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false)
  const [testFeedback, setTestFeedback] = useState<Feedback[]>([])
  const unAnsweredItems = useFindUnAnsweredItems()
  const navItemsScoring = useItemResponsesScoring(
    itemsResponses as AssessmentItemResponse[],
    SUCCESS_THRESHOLD,
  )
  const modalButtonRef = useRef(null)

  const isLinearMode = useMemo(
    () => currentTestPart && currentTestPart.navigationMode === NavigationMode.linear,
    [currentTestPart],
  )

  useEffect(() => {
    if (currentTest && currentTest.testFeedback) {
      setTestFeedback(currentTest.testFeedback as Feedback[])
    }
  }, [currentTest, currentTest.testFeedback])

  const openQuestionsModal = () => {
    setOpenModal(current => !current)
  }

  const closeQuestionsModal = () => {
    setOpenModal(false)
  }

  const closeUnansweredModal = () => {
    setOpenSubmitModal(false)
  }

  const submitTestPart = () => {
    if (openSubmitModal) {
      closeUnansweredModal()
    }

    completeTest()

    setOpenFeedbackModal(true)
  }

  const selectItem = (id: string) => () => {
    if (openSubmitModal) {
      closeUnansweredModal()
    }

    onChange(id)
  }

  const handleFinishTestPart = () => {
    setOpenSubmitModal(true)
  }

  const onTestCallback = useTestCallback()

  return (
    <FooterWrapper>
      <BubbleNavigationWrapper component='nav'>
        {currentTest.isFinished ? (
          <TestReviewNavigator
            modalButtonRef={modalButtonRef}
            openQuestionsModal={openModal}
            onShowQuestionsModal={openQuestionsModal}
            openSubmitModal={openSubmitModal}
            isLinearMode={isLinearMode}
            itemsScoring={navItemsScoring}
            onSelect={selectItem}
          />
        ) : (
          <TestTakingNavigator modalButtonRef={modalButtonRef} onShowQuestionsModal={openQuestionsModal} />
        )}
      </BubbleNavigationWrapper>
      <ButtonsWrapper>
        {currentTest.isFinished ? (
          <Button
            variant='contained'
            color='grey'
            size='small'
            aria-label='Leave Review'
            role='button'
            onClick={onTestCallback}
            {...BACK_TO_OVERVIEW_BTN_ATTRIBUTES}
          >
            Leave Review
          </Button>
        ) : (
          <Tooltip title={unAnsweredItems.length > 0 ? 'You have unanswered questions' : ''} arrow={true}>
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              aria-label='Submit Test'
              role='button'
              onClick={handleFinishTestPart}
              {...FINISH_TEST_BTN_ATTRIBUTES}
            >
              {'Submit Test'}
            </Button>
          </Tooltip>
        )}
      </ButtonsWrapper>

      <SubmitTestModal
        open={openSubmitModal}
        onClose={closeUnansweredModal}
        onSubmit={submitTestPart}
        isLinearMode={isLinearMode}
        hasUnansweredItems={!!unAnsweredItems.length}
        onSelectItem={selectItem}
      />

      <BasicModal
        open={openFeedbackModal}
        size='sm'
        backdropDisabled
        onClose={() => setOpenFeedbackModal(false)}
        onSubmit={onTestCallback}
        title={!testFeedback.length && `Congrats! You submitted ${currentTest.title}`}
        noCancel
        submitText={'Return to Study Plan'}
        modalProps={FINAL_FEEDBACK_MODAL_ATTRIBUTES}
        submitButtonProps={RETURN_TO_DASHBOARD_BTN_ATTRIBUTES}
        footer={<StudentFeedbackModalContent event={FeedbackEvent.COMPLETE_PRACTICE_TEST} />}
      >
        {testFeedback.length ? (
          testFeedback
            .filter(feedback => feedback.access === TestFeedbackAccess.atEnd)
            .map(feedback => <TestFeedbackComponent feedback={feedback} key={feedback.id} />)
        ) : (
          <Typography variant='body1'>Go back to your Study Plan to see your test score.</Typography>
        )}
      </BasicModal>

      <ItemsListModal
        open={openModal}
        onClose={closeQuestionsModal}
        anchorEl={modalButtonRef.current}
        isReviewMode={currentTest.isFinished}
      >
        <ItemList
          onClick={selectItem}
          openSubmitModal={openSubmitModal}
          isLinearMode={isLinearMode}
          isOpenModal={openModal}
          closeModal={closeQuestionsModal}
          itemsScoring={navItemsScoring}
        ></ItemList>
      </ItemsListModal>
    </FooterWrapper>
  )
}
