import { StorageKeys } from '@app/constants'
import {
  AssessmentSettings,
  createAssessment,
  createUserAssessment,
  getAssessmentById,
  uploadPackageToCloudStorage,
} from '@app/firebase/api'
import { generateFirestoreAssessmentPayload, generateInitialUserAssessmentPayload } from '@app/firebase/utils'
import { LocalStorageService } from '@app/services'
import { InitialUserAssessmentPayload, UserAuthData } from '@app/types'
import { uuidv4 } from '@firebase/util'
import { useEffect, useState } from 'react'

export const useAssessmentSetup = (file: File, settings: AssessmentSettings) => {
  const [id, setId] = useState('')
  const [link, setLink] = useState('')
  const [loading, setLoading] = useState<boolean>(false)

  const getPathFromCloudStorage = async (file: File) => {
    const uploadedFile = await uploadPackageToCloudStorage(file)

    return uploadedFile.metadata.fullPath
  }

  const saveAssessmentDataToFirestore = async (path: string) => {
    const assessment = await getAssessmentById(id)
    if (assessment) {
      console.log('EXISTING ASSESSMENT FOUND: ', assessment)
      return
    }

    const payload = generateFirestoreAssessmentPayload({
      id,
      path,
      settings,
    })

    await createAssessment(id, payload)
  }

  const saveUserAssessmentDataToFirestore = async (initialData: InitialUserAssessmentPayload) => {
    const payload = generateInitialUserAssessmentPayload(initialData)

    await createUserAssessment(payload)
  }

  const runFirebaseSetup = async () => {
    const authData = LocalStorageService.getValue<UserAuthData>(StorageKeys.AuthData)
    if (!file || !authData) {
      return
    }

    setLoading(true)

    const path = await getPathFromCloudStorage(file)

    await saveAssessmentDataToFirestore(path)
    await saveUserAssessmentDataToFirestore({
      schoolYear: '2023',
      userId: authData.user.uid,
      attempt: 1,
      assessmentId: id,
      hideInfoBoxes: false,
      assessmentSettings: {
        timeLimits: settings.timeLimit,
        isResumable: settings.isResumable,
      },
    })
    setLink(`/test?userId=${authData.user.uid}&id=${id}&schoolYear=2023&attempt=1`)
    setLoading(false)
  }

  useEffect(() => {
    if (file) {
      setId(uuidv4())
      return
    }
  }, [file])

  return { link, runFirebaseSetup, loading }
}
