import { proxy } from 'valtio';

interface AppUpdateType {
  updateFound: boolean;
}

const initialState: AppUpdateType = {
  updateFound: false
};

export const AppUpdateState = proxy<AppUpdateType>(initialState);

export const setAppUpdateStatus = (status: boolean) => {
  AppUpdateState.updateFound = status
}

export const resetAppUpdateState = () => {
  AppUpdateState.updateFound = initialState.updateFound
}