import { Box, styled } from '@mui/material'
import { useRef, useEffect, forwardRef } from 'react'
import { Bubble, BubbleProps, BubbleSize } from '../Bubble'
import NavigationBubbleBadge from './NavigationBubbleBadge'
import {
  ANSWERED_QUESTION_ATTRIBUTES,
  CURRENT_QUESTION_ATTRIBUTES,
  PAGINATION,
  TEST_ATTRIBUTE_ID,
  TEST_ELEMENT_IDS,
} from '@app/constants'
import { generateAttrWithValue } from '@app/helpers'

export interface NavigationBubbleProps extends Omit<BubbleProps, 'children'> {
  number: number
  size?: BubbleSize
  selected?: boolean
  answered?: boolean
  viewed?: boolean
  correct?: boolean
  markedForReview?: boolean
  disabled?: boolean
  focused?: boolean
  dense?: boolean
  groupMode?: boolean
  arrowKeysNavigation?: (e: React.KeyboardEvent) => void
  onFocus?: () => void
  onClick: () => void
  closeModal?: () => void
}

const NavigationBubble = forwardRef<HTMLDivElement, NavigationBubbleProps>(
  (
    {
      number,
      correct,
      markedForReview,
      onClick,
      focused,
      dense = false,
      groupMode = false,
      arrowKeysNavigation,
      closeModal,
      ...props
    },
    bubbleRef,
  ) => {
    const badgeRef = useRef(null)

    useEffect(() => {
      if (props.selected) {
        const { id } = badgeRef.current.parentElement.dataset
        if (!id || id !== PAGINATION) return
        badgeRef.current.scrollIntoView({ inline: 'start', block: 'nearest', behavior: 'smooth' })
      }
    }, [props.selected])

    const onKeyDown = (e: React.KeyboardEvent) => {
      if (e.code === 'Space' || e.code === 'Enter') {
        e.preventDefault()
        onClick && onClick()
        closeModal && closeModal()
      }
      arrowKeysNavigation && arrowKeysNavigation(e)
    }

    return (
      <ListItem ref={badgeRef}>
        <BadgeContainer dense={dense}>
          <NavigationBubbleBadge
            correct={correct}
            markedForReview={markedForReview}
            groupMode={groupMode}
          >
            <StyledBubble
              onClick={onClick}
              onKeyDown={onKeyDown}
              ref={bubbleRef}
              focused={focused}
              tabIndex={focused ? 0 : -1}
              role='button'
              correct={correct}
              groupMode={groupMode}
              {...props}
              {...generateAttrWithValue(TEST_ATTRIBUTE_ID, TEST_ELEMENT_IDS.questionBtn, number)}
              {...(props.selected ? CURRENT_QUESTION_ATTRIBUTES : {})}
              {...(props.answered ? ANSWERED_QUESTION_ATTRIBUTES : {})}
            >
              {number}
            </StyledBubble>
          </NavigationBubbleBadge>
        </BadgeContainer>
      </ListItem>
    )
  },
)

const StyledBubble = styled(Bubble, {
  shouldForwardProp: prop => prop !== 'groupMode',
})<{ correct?: boolean, groupMode?: boolean }>(
  ({ theme, correct, groupMode = false, selected = false }) => ({
    ...(correct !== undefined && groupMode && !selected && {
      backgroundColor: theme.palette.common.white,
      borderColor: correct
        ? theme.palette.success.light
        : theme.palette.warning.light,
    }),
  }),
)

const BadgeContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'dense',
})<{ dense?: boolean }>(
  ({ theme, dense }) => ({
    cursor: 'pointer',

    ...(!dense && {
      padding: theme.spacing(1),

      [theme.breakpoints.down('mobile')]: {
        padding: theme.spacing(2),
      },
    }),
  })
)

const ListItem = styled('li')({
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  display: 'inline-block',
})

export default NavigationBubble
