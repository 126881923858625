import { SaveFeedbackPrompt } from '@app/types'
import { SAVE_FEEDBACK_RESPONSE } from './feedbackMutations'
import { executeMutationSideload, retry } from '../graphql/apolloClient'

export async function saveFeedbackResponse(data: SaveFeedbackPrompt): Promise<boolean> {
  const response = await retry(
    () =>
      executeMutationSideload(SAVE_FEEDBACK_RESPONSE, {
        data,
      }),
    3,
    1000,
  )

  return response?.feedback?.saveFeedbackResponse
}
