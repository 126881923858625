import { CurrentTestState } from '@app/storage'
import { useEffect, useMemo, useRef, useState, KeyboardEvent, MutableRefObject } from 'react'
import { useSnapshot } from 'valtio'
import { CurrentButtonPosition, EquationSectionType } from './types'

/**
 * Hook for history control in input.
 * @param initValue input initial value.
 * @returns Object {undo, redo, setCurrentState, currentState, currentIndex, resetState}.
 */
export const useUndoRedoHistoryInput = (initValue: string, historyLimit?: number) => {
  const [states, setStates] = useState([initValue])
  const [currentIndex, setCurrentIndex] = useState(0)
  const currentState = useMemo(() => states[currentIndex], [states, currentIndex])

  const setCurrentState = (value: string) => {
    if (value === currentState) return

    const copy = [...states]
    copy.push(value)

    if (historyLimit && copy.length > historyLimit) copy.shift()

    setStates(copy)
    setCurrentIndex(copy.length - 1)
  }

  const resetState = (init: string) => {
    setCurrentIndex(0)
    setStates([init])
  }

  const undo = (steps = 1) => {
    setCurrentIndex(Math.max(0, Number(currentIndex) - (Number(steps) || 1)))
  }

  const redo = (steps = 1) => {
    setCurrentIndex(Math.min(states.length - 1, Number(currentIndex) + (Number(steps) || 1)))
  }

  return {
    undo,
    redo,
    setCurrentState,
    currentState,
    currentIndex,
    resetState,
  }
}

export const useEquationEditorAnnouncement = (value: string) => {
  const [announcement, setAnnouncement] = useState('')
  const previousValue = useRef<string>('')

  useEffect(() => {
    if (!value && !previousValue.current) {
      return
    }

    previousValue.current = value

    if (!value) {
      setAnnouncement('Equation text field is empty.')
      return
    }

    setAnnouncement(`Equation text field value is ${value}. For removing symbols use Backspace key`)
  }, [value])

  return announcement
}

export const useOperatorsKeyboardNav = (actionButtons: EquationSectionType[]) => {
  const [currentRow, setCurrentRow] = useState<number>(0)
  const [currentColumn, setCurrentColumn] = useState<number>(0)
  const buttonsRefs = useRef<CurrentButtonPosition[][]>(
    actionButtons.map((subsection, rowIndex) =>
      subsection.operators.map((operator, colIndex) => ({
        ref: null,
        row: rowIndex,
        column: colIndex,
        label: operator,
      })),
    ),
  )
  const { currentItemResponse } = useSnapshot(CurrentTestState)

  useEffect(() => {
    if (currentItemResponse?.id) {
      setCurrentRow(0)
      setCurrentColumn(0)
      buttonsRefs.current = []
    }
  }, [currentItemResponse?.id, buttonsRefs, actionButtons])

  useEffect(() => {
    if (!actionButtons) {
      return
    }

    buttonsRefs.current = actionButtons.map((subsection, rowIndex) =>
      subsection.operators.map((operator, colIndex) => ({
        ref: null,
        row: rowIndex,
        column: colIndex,
        label: operator,
      })),
    )
  }, [actionButtons])

  useEffect(() => {
    if (!buttonsRefs.current || currentColumn === null || currentRow === null) {
      return
    }
    buttonsRefs.current[currentRow][currentColumn]?.ref?.focus()
  }, [currentRow, currentColumn])

  useEffect(() => {
    buttonsRefs.current[currentRow][currentColumn]?.ref?.blur()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFocus = (rowIndex: number, colIndex: number) => {
    setCurrentRow(rowIndex)
    setCurrentColumn(colIndex)
  }

  const onKeyDown = (e: KeyboardEvent, rowIndex: number) => {
    switch (e.code) {
      case 'ArrowLeft': {
        if (currentColumn >= 1 && buttonsRefs.current[rowIndex][currentColumn - 1].label) {
          setCurrentColumn(state => state - 1)
          return
        }
        if (rowIndex - 1 >= 0) {
          setCurrentColumn(buttonsRefs.current[rowIndex - 1].findLastIndex(button => button.label))
          setCurrentRow(state => state - 1)
        }
        return
      }
      case 'ArrowRight': {
        if (
          currentColumn + 1 < buttonsRefs.current[rowIndex].length &&
          buttonsRefs.current[rowIndex][currentColumn + 1].label
        ) {
          setCurrentColumn(state => state + 1)
          return
        }

        if (currentRow + 1 < buttonsRefs.current.length) {
          setCurrentColumn(buttonsRefs.current[currentRow + 1].findIndex(button => button.label))
          setCurrentRow(state => state + 1)
        }
        return
      }
      case 'ArrowDown': {
        if (rowIndex + 1 < buttonsRefs.current.length) {
          if (buttonsRefs.current[rowIndex + 1].length - 1 < currentColumn) {
            setCurrentColumn(buttonsRefs.current[rowIndex + 1].length - 1)
            setCurrentRow(state => state + 1)
            return
          }

          if (buttonsRefs.current[rowIndex + 1][currentColumn]?.label === null) {
            setCurrentRow(state => state + 2)
            return
          }
        }

        setCurrentRow(currState => (currState + 1 < buttonsRefs.current.length ? currState + 1 : currState))
        return
      }
      case 'ArrowUp': {
        if (rowIndex - 1 >= 0) {
          if (buttonsRefs.current[rowIndex - 1].length - 1 < currentColumn) {
            setCurrentColumn(buttonsRefs.current[rowIndex - 1].length - 1)
            setCurrentRow(state => state - 1)
            return
          }

          if (buttonsRefs.current[rowIndex - 1][currentColumn]?.label === null) {
            setCurrentRow(state => state - 2)
            return
          }
        }

        setCurrentRow(currState => (currState >= 1 ? currState - 1 : currState))
        return
      }
    }
  }

  return { onKeyDown, currentColumn, currentRow, buttonsRefs, onFocus }
}

export const useControlsKeyboardNav = (controlsRef: MutableRefObject<HTMLButtonElement[]>) => {
  const [currentControl, setCurrentControl] = useState<number>(0)

  useEffect(() => {
    controlsRef.current[currentControl]?.focus()
  }, [currentControl, controlsRef])

  useEffect(() => {
    controlsRef.current[currentControl]?.blur()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onKeyDown = (e: KeyboardEvent) => {
    switch (e.code) {
      case 'ArrowLeft': {
        setCurrentControl(state => (state > 0 ? state - 1 : state))
        return
      }
      case 'ArrowRight': {
        setCurrentControl(state => (state < controlsRef.current.length - 1 ? state + 1 : state))
        return
      }
    }
  }

  const selectControl = (index: number) => setCurrentControl(index)

  return { currentControl, onKeyDown, selectControl }
}
