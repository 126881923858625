import { forwardRef } from 'react'
import { useResponseValidityUpdate, transformChoicesConstraints } from '@app/helpers'
import { ShowHide } from '@app/types'
import { DomNodes } from '@app/constants'
import { useSnapshot } from 'valtio'
import { CurrentTestState } from '@app/storage'
import { GapImg } from './GapImg'
import { GapImgProps } from './types'

export interface GapImgComponentProps extends GapImgProps {
  itemId: string
  matchCount?: number
  identifier: string
  templateidentifier: string
  showhide: ShowHide
  matchgroup: string
  matchmax?: string
  matchmin?: string
  objectlabel: string
  top: string
  left: string
  className?: string
  style?: React.CSSProperties
  children: JSX.Element | JSX.Element[]
  componentNode: DomNodes
  accessibilityAttr?: { [key: string]: string }
  selected?: boolean
}

export const GapImgComponent = forwardRef((props: GapImgComponentProps, ref) => {
  const { itemId, matchCount, className, children, componentNode, accessibilityAttr, ...rest } = props
  const { currentItemResponse } = useSnapshot(CurrentTestState)

  const [matchMin, matchMax] = transformChoicesConstraints(props.matchmin, props.matchmax)
  const isValid = useResponseValidityUpdate(currentItemResponse?.id, matchCount, matchMin, matchMax)
  const hasMaxMatches = matchMax && matchMax === matchCount

  return (
    <GapImg
      ref={ref}
      invalid={!isValid}
      disabled={hasMaxMatches}
      className={className}
      aria-label={props.identifier}
      {...rest}
      {...accessibilityAttr}
    >
      {children}
    </GapImg>
  )
})
