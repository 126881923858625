import { CircularProgress, styled } from '@mui/material'
import { FC } from 'react'

type LoaderProps = {}

const Loader:FC<LoaderProps> = (props) => {

  return (
    <StyledCircularProgress color='primary' disableShrink/>
  )
}

const StyledCircularProgress = styled(CircularProgress)(
  ({ theme }) => `
    position: absolute;
    top: 50%;
    right: 50%;
  `
)

export default Loader