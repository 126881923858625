import { Badge, badgeClasses, styled } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StyledBadge = styled(Badge)(
  ({ theme }) => ({
    [`& .${badgeClasses.badge}`]: {
      borderRadius: `${theme.shape.borderRadius * 5}px`,
      minWidth: 'unset',
      width: '16px',
      height: '16px',
      zIndex: 0,
    },

    [`& .${badgeClasses.anchorOriginTopLeftRectangular}`]: {
      left: '4px',
      top: '4px',
    },

    [`& .${badgeClasses.anchorOriginBottomRightRectangular}`]: {
      right: '4px',
      bottom: '4px',
    },

    [`& .${badgeClasses.colorSuccess}`]: {
      backgroundColor: theme.palette.green[700],
    },
    [`& .${badgeClasses.colorWarning}`]: {
      backgroundColor: theme.palette.orange[700],
    },
    [`& .${badgeClasses.colorInfo}`]: {
      backgroundColor: theme.palette.blue[600],
    },
  })
)

export const BadgeIcon = styled(FontAwesomeIcon)({
  fontSize: '0.5rem',
})
