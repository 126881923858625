import {
  Typography,
  styled,
  IconButton,
  useMediaQuery,
  useTheme,
  Popover,
  Dialog,
  popoverClasses,
  PopoverOrigin,
} from '@mui/material'
import { FC } from 'react'
import { ALL_QUESTIONS_MODAL_ATTRIBUTES, ITEMS_MODAL_WIDTH } from '@app/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'

interface ItemsListModalProps {
  open: boolean
  onClose: () => void
  children: JSX.Element | JSX.Element[]
  anchorEl?: HTMLElement
  isReviewMode?: boolean
}

const ANCHOR_REVIEW_ORIGIN: PopoverOrigin = { vertical: 'top', horizontal: 'right' }
const TRANSFORM_REVIEW_ORIGIN: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' }
const ANCHOR_TESTING_ORIGIN: PopoverOrigin = { vertical: 'top', horizontal: 'left' }
const TRANSFORM_TESTING_ORIGIN: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' }

export const ItemsListModal: FC<ItemsListModalProps> = ({
  open,
  onClose,
  anchorEl,
  isReviewMode = false,
  children,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  if (isMobile) {
    return (
      <Dialog fullScreen open={open} onClose={onClose} {...ALL_QUESTIONS_MODAL_ATTRIBUTES}>
        <TitleContainer>
          <StyledIconButton onClick={onClose}>
            <StyledFaTimesIcon icon={faTimes} />
          </StyledIconButton>
          <StyledTypography variant='h3'>Questions</StyledTypography>
        </TitleContainer>
        <ItemsList>{children}</ItemsList>
      </Dialog>
    )
  }

  return (
    <ItemsListModalWrapper
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={isReviewMode ? ANCHOR_REVIEW_ORIGIN : ANCHOR_TESTING_ORIGIN}
      transformOrigin={isReviewMode ? TRANSFORM_REVIEW_ORIGIN : TRANSFORM_TESTING_ORIGIN}
      {...ALL_QUESTIONS_MODAL_ATTRIBUTES}
    >
      <ItemsList>{children}</ItemsList>
    </ItemsListModalWrapper>
  )
}

const ItemsListModalWrapper = styled(Popover)(({ theme }) => ({
  [`& .${popoverClasses.paper}`]: {
    maxWidth: 'calc(100% - 32px)',
    width: `${ITEMS_MODAL_WIDTH}px`,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[6],
    borderRadius: `${theme.shape.borderRadius}px`,
  },
}))

const TitleContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(4)};      
`,
)

const ItemsList = styled('ul')(
  ({ theme }) => `
    padding: 0;
    margin: 0;
    background-color: transparent;
    display: flex;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
    
    & > li > * {
      padding: 0;
    }
    
    ${[theme.breakpoints.down('mobile')]} {
      gap: ${theme.spacing(4.5)};
      padding: ${theme.spacing(6.25)};
    }
  `,
)

const StyledTypography = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.grey[800]};
    font-size: 1.125rem;
    line-height: 1.438rem;
    margin: 0;
  `,
)

const StyledIconButton = styled(IconButton)`
  width: 50px;
  height: 50px;
`

const StyledFaTimesIcon = styled(FontAwesomeIcon)(
  ({ theme }) => `
    color: ${theme.palette.grey[800]};
    font-size: 1.125rem;
  `,
)
