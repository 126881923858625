import { Box, styled, Table } from '@mui/material'
import { FC, memo } from 'react'
import { useGridInKeyboardNavigation } from '../hooks'
import { IGridInSymbols } from '../types'
import { getMaxSymbolsCount } from '../utils'
import { GridInDesktopBody } from './GridInDesktopBody'
import { GridInDesktopHeader } from './GridInDesktopHeader'

interface GridInDesktopProps {
  columnCount: number
  rowCount: number
  symbols: string[][]
  symbolsStructure: IGridInSymbols
  inputValues: string[]
  setColumnInputValue: (columnIndex: number, value: string) => void
}

export const GridInDesktop: FC<GridInDesktopProps> = memo(({
  columnCount,
  rowCount,
  symbols,
  symbolsStructure,
  inputValues,
  setColumnInputValue,
}) => {
  const { currentIndex, onKeyDown, symbolsRef, selectCell, onBackspace, onAction } = useGridInKeyboardNavigation(
    Number(columnCount),
    getMaxSymbolsCount(symbols),
    symbols
  )

  const ariaRowCount = symbolsStructure ? Object.keys(symbolsStructure).reduce((acc, val) => {
    const [numbers, operators] = Object.values(symbolsStructure[val]);
    const currentRowCount = (operators ? [...operators, ...numbers] : numbers).length;
    return currentRowCount > acc ? currentRowCount : acc
  }, 0) : 0;

  const ariaAttributes = {
    'aria-rowcount': ariaRowCount,
    'aria-colcount': columnCount
  }

  return (
    <>
      <Box id='gridInNavigationDescription' sx={{ display: 'none' }}>
        To move through the elements, use the keyboard arrows
      </Box>
      <GridInDesktopWrapper {...ariaAttributes} onKeyDown={onKeyDown}>
        <GridInDesktopHeader
          ref={symbolsRef}
          columnCount={columnCount}
          symbols={symbols}
          inputValues={inputValues}
          setColumnInputValue={setColumnInputValue}
          currentIndex={currentIndex}
          chooseCell={selectCell}
          onBackspace={onBackspace}
          onInputAction={onAction}
        />
        <GridInDesktopBody
          ref={symbolsRef}
          rowCount={rowCount}
          columnCount={columnCount}
          symbols={symbolsStructure}
          inputValues={inputValues}
          setColumnInputValue={setColumnInputValue}
          currentIndex={currentIndex}
          chooseCell={selectCell}
        />
      </GridInDesktopWrapper>
    </>
  )
})

const GridInDesktopWrapper = styled(Table)(({ theme }) => ({
  width: '0',

  [theme.breakpoints.down('mobile')]: {
    display: 'none',
  },
}))
