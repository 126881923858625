import { FC } from 'react'
import { styled } from '@mui/material'
import { version } from '../../../package.json'

export interface VersionLabelProps {
  className?: string
}

export const VersionLabel: FC<VersionLabelProps> = ({ className }) => (
  <StyledVersionLabel className={className}>v{version}</StyledVersionLabel>
)

const StyledVersionLabel = styled('span')(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.grey[500],
}))
