import { useEffect, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'
import {
  CurrentUserAssessmentState,
  CurrentTimerState,
  setRemainedTime,
  startTimer,
  resumeTimer,
  IdleDetectorState,
  endTimer,
  CurrentTestState,
  CurrentQtiPackageState,
  setIdleStatus,
} from '@app/storage'
import { TimerStatus } from '@app/types'
import { AssessmentStatus } from '@app/firebase/api'
import { Idle } from 'idlejs'

export const useDefineTimeLimit = (): number | null => {
  const { currentTest } = useSnapshot(CurrentTestState)
  const { assessmentId, userAssessmentId } = useSnapshot(CurrentUserAssessmentState)
  const [timeLimit, setTimeLimit] = useState<number>(null)

  useEffect(() => {
    if (!assessmentId && !userAssessmentId) {
      return
    }

    const customTimeLimit = CurrentUserAssessmentState.assessmentSettings?.timeLimits

    if (customTimeLimit) {
      setTimeLimit(customTimeLimit)
      return
    }

    if (currentTest?.timeLimits?.maxTime) {
      setTimeLimit(currentTest?.timeLimits?.maxTime)
    }
  }, [assessmentId, userAssessmentId, currentTest])

  return timeLimit
}

export const useTimerSetup = (): void => {
  const maxTime = useDefineTimeLimit()
  const {
    passedTime,
    addedTime,
    assessmentId,
    status,
    assessmentSettings,
    startedTimestamp
  } = useSnapshot(CurrentUserAssessmentState)
  const { remainedTime, timerStatus,  } = useSnapshot(CurrentTimerState)
  const { isIdle } = useSnapshot(IdleDetectorState)
  const [initialRemainedTime, setInitialRemainedTime] = useState<number>(null)

  useEffect(() => {
    if (!assessmentSettings || !maxTime || !assessmentId || passedTime === null || !startedTimestamp) {
      return
    }

    const currentDate = new Date()
    const addedTimeNumber = CurrentUserAssessmentState?.addedTime ?? 0
    const passedTimeNumber = CurrentUserAssessmentState.assessmentSettings.isResumable
      ? CurrentUserAssessmentState.passedTime ?? 0
      : Math.round((currentDate.getTime() - CurrentUserAssessmentState.startedTimestamp.toDate().getTime())/1000)

    setInitialRemainedTime(maxTime + addedTimeNumber - passedTimeNumber)
  }, [maxTime, assessmentId, passedTime, addedTime, assessmentSettings, startedTimestamp])

  useEffect(() => {
    if (status !== AssessmentStatus.completed) {
      return
    }

    endTimer()
  }, [status])

  useEffect(() => {
    if (!initialRemainedTime || timerStatus === TimerStatus.ended) {
      return
    }
    // Update remained time value if timer is not started or paused.
    if (!remainedTime) {
      console.log('[useInitialRemainedTimeSetup] setting initial remained time...', initialRemainedTime)
      setRemainedTime(initialRemainedTime)
    }

    if (isIdle) return

    if (timerStatus === TimerStatus.notStarted) {
      startTimer()
      return
    }

    if (timerStatus === TimerStatus.paused) {
      resumeTimer()
      return
    }
  }, [initialRemainedTime, remainedTime, timerStatus, isIdle])
}

export const useTimeoutAction = (allowLateSubmission: boolean, onTimeOut: () => void) => {
  const { remainedTime } = useSnapshot(CurrentTimerState)

  useEffect(() => {
    if (remainedTime === null) {
      return
    }

    if (remainedTime <= 0) {
      console.log('[useTimeoutAction] time is out...')
      endTimer()
      !allowLateSubmission && onTimeOut()
    }
  }, [remainedTime, allowLateSubmission, onTimeOut])
}

export const useIdleDetection = () => {
  const { assessmentSettings } = useSnapshot(CurrentQtiPackageState)
  const { isIdle, modalOpened } = useSnapshot(IdleDetectorState)
  const idleDetector = useRef<Idle>(null)

  const onIdleDetection = () => {
    if (isIdle) {
      return
    }

    setIdleStatus(true)
  }

  useEffect(() => {
    if (!assessmentSettings || !assessmentSettings?.maxIdletime || !assessmentSettings.isResumable) {
      return
    }

    if (!idleDetector.current) {
      idleDetector.current = new Idle()
        .whenNotInteractive()
        .within(CurrentQtiPackageState.assessmentSettings.maxIdletime)
        .do(() => onIdleDetection())
        .start()

      return
    }

    if (isIdle && modalOpened) {
      idleDetector.current.stop()
      return
    }

    if (idleDetector.current && !isIdle && !modalOpened) {
      idleDetector.current.start()
    }
    // eslint-disable-next-line
  }, [isIdle, modalOpened, assessmentSettings, assessmentSettings?.maxIdletime])
}
