import { styled } from '@mui/material'
import { DraggablePointProps, DraggablePoint, StyledPoint } from '../../GraphingInteraction/components'
import { getGraphColor } from '../../GraphingInteraction/helpers'
import { getPointLabel } from '../helpers'

export const DraggableIntervalPoint = styled(
  ({ filled, ...props }: DraggablePointProps & { filled?: boolean }) => (
    <DraggablePoint {...props} labelFormat={getPointLabel} />
  ),
)(({ theme, filled, colorIndex }) => ({
  [`${StyledPoint}`]: {
    fill: filled ? getGraphColor(theme, 'A700', colorIndex) : 'transparent',
    stroke: getGraphColor(theme, 900, colorIndex),
    strokeWidth: 2,
  },
}))
