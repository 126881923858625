import { AnswerBubble } from '@app/components/ui'
import { createArray } from '@app/helpers'
import { Box, styled, TableBody, TableCell, TableRow } from '@mui/material'
import { forwardRef, KeyboardEvent, MutableRefObject } from 'react'
import { ICurrentIndex, IGridInSymbols, ISymbolsWithIndex } from '../types'
import { createCellIndex, selectBubble } from '../utils'
import { FIELD_CHOICE_CHECKED_ATTRIBUTES } from '@app/constants'
import { useSnapshot } from 'valtio'
import { CurrentTestState } from '@app/storage'

interface GridInDesktopBodyProps {
  rowCount: number
  columnCount: number
  symbols: IGridInSymbols
  inputValues: string[]
  setColumnInputValue: (columnIndex: number, value: string) => void
  currentIndex: ICurrentIndex
  chooseCell: (columnIndex: number, rowIndex: number) => void
}

export const GridInDesktopBody = forwardRef<ISymbolsWithIndex[][], GridInDesktopBodyProps>((
  props,
  symbolsRef,
) => {
  const { rowCount, columnCount, symbols, inputValues, setColumnInputValue, currentIndex, chooseCell } = props
  const { currentTestPart } = useSnapshot(CurrentTestState)

  const getColumnSizes = (sym: IGridInSymbols) => {
    const maxColumnSizeSet = [];
    for (const columnIdx in sym) {
      const row = sym[columnIdx];
      for (const childRow in row) {
        if (!maxColumnSizeSet[Number(childRow) - 1]) {
          maxColumnSizeSet[Number(childRow) - 1] = row[childRow].length;
        } else {
          if (maxColumnSizeSet[Number(childRow) - 1] < row[childRow].length) {
            maxColumnSizeSet[Number(childRow) - 1] = row[childRow].length;
          }
        }
      }
    }
    return maxColumnSizeSet;
  }

  const findRowIndex = (columnIndex: number, symbol: string) =>
    (symbolsRef as MutableRefObject<ISymbolsWithIndex[][]>).current[columnIndex].findIndex(
      s => s.symbol === symbol,
    )

  const findCellIndex = (columnIndex: number, symbol: string) =>
    (symbolsRef as MutableRefObject<ISymbolsWithIndex[][]>).current[columnIndex].find(
      s => s.symbol === symbol,
    )?.index

  const isFocusBubble = (columnIndex: number, symbol: string) =>
    findCellIndex(columnIndex, symbol) === createCellIndex(currentIndex.rowIndex, currentIndex.columnIndex)

  const clickHandler = (columnIndex: number, symbol: string) => () => {
    selectBubble(columnIndex, symbol, inputValues, setColumnInputValue)
    const cellIndex = findCellIndex(columnIndex, symbol) || '1'
    const rowIndex = Number(cellIndex.split('/')[0])
    chooseCell(columnIndex, rowIndex)
  }

  const keyDownHandler = (columnIndex: number, symbol: string) => (e: KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      selectBubble(columnIndex, symbol, inputValues, setColumnInputValue)
    }
  }

  const maxColumnSizeSet = getColumnSizes(symbols)

  return (
    <TableBody>
      {createArray(rowCount).map(rowIndex => (
        <StyledTableRow key={rowIndex}>
          {createArray(columnCount).map(columnIndex => (
            <StyledTableCell key={columnIndex}>
              <GridInSymbolsWrapper>
                {symbols[columnIndex + 1][rowIndex + 1]?.map((item, idx) => {
                  const currentColumSize = maxColumnSizeSet[rowIndex];
                  const currentDataSize = symbols[columnIndex + 1][rowIndex + 1].length;
                  const previousColumnsSize = maxColumnSizeSet.reduce((acc, val, idx) => idx < rowIndex ? acc + val : acc, 0);
                  const skip = currentColumSize - currentDataSize + previousColumnsSize;
                  const currentRow = idx + 1 + skip;
                  return (
                    <AnswerBubble
                      ref={ref => {
                        const _symbolsRef = symbolsRef as MutableRefObject<ISymbolsWithIndex[][]>
                        const symbol = _symbolsRef.current[columnIndex][findRowIndex(columnIndex, item)]
                        if (symbol) {
                          symbol.ref = ref
                        }
                      }}
                      key={`${columnIndex + 1}${item}`}
                      symbol={item}
                      size={'medium'}
                      disabled={currentTestPart.isFinished}
                      selected={inputValues[columnIndex] === item}
                      {...(inputValues[columnIndex] === item ? FIELD_CHOICE_CHECKED_ATTRIBUTES : {})}
                      onClick={clickHandler(columnIndex, item)}
                      onKeyDown={keyDownHandler(columnIndex, item)}
                      ariaLabel={`Symbol is ${item === '.' ? 'point' : item}, column is ${columnIndex + 1}, row is ${currentRow}`}
                      ariaDescribedby={'gridInNavigationDescription'}
                      tabIndex={isFocusBubble(columnIndex, item) ? 0 : -1}
                      focusable
                    />
                  )
                })}
              </GridInSymbolsWrapper>
            </StyledTableCell>
          ))}
        </StyledTableRow>
      ))}
    </TableBody>
  )
})

const GridInSymbolsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  verticalAlign: 'bottom',

  '& > td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  ':last-child > td': {
    borderBottom: 'none',
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  borderRight: `1px solid ${theme.palette.grey[100]}`,

  ':first-child': {
    paddingLeft: theme.spacing(0),
  },
  ':last-child': {
    paddingRight: theme.spacing(0),
    borderRight: 'none',
  },
}))
