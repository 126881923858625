import { gql } from '@apollo/client'

export const GET_RESPONSES_BY_PRACTICE_TEST = gql`
  query getResponsesByPracticeTest(
    $program: Program!
    $subject: String!
    $refScope: refScope!
    $testSubjectContentId: String!
  ) {
    getResponsesByPracticeTest(
      data: {
        program: $program
        subject: $subject
        refScope: $refScope
        testSubjectContentId: $testSubjectContentId
      }
    ) {
      studentCount
      items {
        itemId
        interactionType
        responseDeclarations {
          responseDeclarationId
          responseType
          responses {
            ... on ResponseByPracticeTestResponseValue {
              response
              count
            }
            ... on ResponseByPracticeTestSeqResponseValue {
              response
              seqCounts {
                sequence
                count
              }
            }
            ... on ResponseByPracticeTestPointValue {
              points
              count
            }
          }
        }
      }
    }
  }
`

export const GET_ANSWER_KEY_ITEMS_BY_PRACTICE_TEST = gql`
  query getAnswerKeyItemsByPracticeTest(
    $program: Program!
    $subject: String!
    $testSubjectContentId: String!
  ) {
    getAnswerKeyItemsByPracticeTest(
      data: { program: $program, subject: $subject, testSubjectContentId: $testSubjectContentId }
    ) {
      index
      itemId
      nodeId
      passageId
      responses
    }
  }
`
